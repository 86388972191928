<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：工序选择组件
	开始时间：2021-01-15
	开发人员：刘巍骏,万攀
	最后修改：2021-05-07
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="List">
    <el-dialog title="生产工序" :visible.sync="show_technologyBox" top="10vh" :before-close="cancel_technology" width="65%"
      append-to-body>
      <!-- 列表总框 -->
      <div class="billing_dialog_table" id="wrapper_technologyTable">
        <!-- 左边分类 -->
        <div class="list_left">
          <!-- 分类标题 -->
          <div class="classfi_title" @click="getAllTechnologyData">
            <i class="el-icon-menu"></i>
            <span>全部分类</span>
          </div>
          <!-- 分类列表 -->
          <div class="classfi_list">
            <el-tree :data="ProductionBomType" :props="defaultProps" @node-click="getSelClassify"
              :expand-on-click-node="false" default-expand-all>
            </el-tree>
          </div>
        </div>

        <!-- 可拉伸框 -->
        <div class="list_handel"></div>

        <!-- 右边数据 -->
        <div class="list_right">
          <!-- 头部搜索框 -->
          <div class="right_top">
            <!-- 搜索 -->
            <div class="search">
              <input type="text" placeholder="请输入查询内容">
              <i class="el-icon-search"></i>
            </div>
          </div>
          <!-- 列表 -->
          <div class="right_list" id="wrapper_protable">
            <!-- 表格 -->
            <div class="right_list_table billing_table">
              <el-table :data="processData" @select-all="getAllSelected" @select="getSelected" ref="technologyTableRef"
                height="100%" border style="width: 100%">
                <!-- 索引 -->
                <el-table-column type="index" fixed width="50"></el-table-column>
                <!-- 全选 -->
                <el-table-column type="selection" :selectable="disableDefaultRow" fixed width="45"></el-table-column>
                <!-- 数据内容 -->
                <el-table-column prop="name" label="工序名称" sortable width="150"></el-table-column>
                <el-table-column prop="manufactureTypeName" label="工序类别" width="120"></el-table-column>
                <el-table-column label="负责部门" width="150" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span class="vible">{{scope.row.dept_name}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="负责人员" width="150" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span class="vible">{{scope.row.user_name}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="supplierName" label="供应商" width="240"></el-table-column>
                <el-table-column prop="cost_type_name" label="计费方式" width="120"></el-table-column>
                <el-table-column prop="remark" label="备注" min-width="120"></el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <div class="right_list_page">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page.sync="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="currentPageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="commit_technology">确认</el-button>
        <el-button size="mini" @click="cancel_technology">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  //请求相关
  import api from '@/network/production/production'; //生产相关接口

  export default {
    name: 'list_technology',
    props: {
      // 默认选中的数据(工序id数组)
      defaultSelected: {
        type: Array,
        default () {
          return []
        }
      },
      //默认选中的数据显示类型(0:可选 1:不可选)
      defaultSelectType: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        //工序列表相关数据
        currentClassfiyId: {}, //当前选择的工序分类
        processData: [], //工序列表
        selectedData: [], //当前已经选中数据
        selectedIdArr: [], //当前选中的工序id数组

        //辅助数据
        defaultProps: { //匹配服务器字段
          children: 'children',
          label: 'name'
        },

        //分页信息
        currentPage: 1, //当前页
        currentPageSize: 10, //页面大小
        total: 0, //总页数
      }
    },
    computed: {
      ...mapState({
        ProductionBomType: state => state.system.ProductionBomType, //BOM分类
        show_technologyBox: state => state.commComponent.show_technologyBox, //控制显示工序选择弹框组件
      }),
    },
    watch: {
      //监听工序弹框显示
      show_technologyBox(newVal) {
        if (newVal) {//显示
          //获取默认选中的id数组
          this.selectedIdArr = JSON.parse(JSON.stringify(this.defaultSelected));
          //获取工序列表数据
          this.getTechnologyId();
        }else{//关闭
          //初始化data中的数据
          Object.assign(this.$data, this.$options.data());
        }
      },
    },
    created() {
      //初始化vuex数据
      this.initVuexData();
    },
    mounted() {
      //默认获取所有工序列表
      this.getTechnologyId()
    },
    methods: {
      ...mapMutations([
        'SHOW_TECHNOLOGYBOX', //控制工序选择弹框是否显示
      ]),
      ...mapActions([
        'getAllManufactureType', //生产类型
        'getAllBomType',
      ]),

      /* 初始化vuex数据 */
      initVuexData() {
        // 获取bom分类
        if (!this.ProductionBomType.length > 0) {
          this.getAllBomType();
        }
      },

      /* 获取全部分类数据 */
      getAllTechnologyData() {
        //清空分类信息
        this.currentClassfiyId = {};
        //获取工序列表数据
        this.getTechnologyId();
      },

      /* 获取当前选择的分类 */
      getSelClassify(data) {
        //获取所选分类数据
        this.currentClassfiyId = data;
        //获取工序列表数据
        this.getTechnologyId();
      },

      /* 获取工序列表数据 */
      getTechnologyId() {
        //定义公共数据
        let data = {
          pageIndex: this.currentPage, //当前页
          pageSize: this.currentPageSize, //页面大小
        }
        //分类查询
        if (JSON.stringify(this.currentClassfiyId) != "{}") {
          data.process_type_id = this.currentClassfiyId.id;
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#wrapper_technologyTable", 4, '工序列表信息获取中,请稍候...');
        //发送请求
        api.searchProcessManageById(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取工序列表
            this.processData = res.data.rows;
            //获取总页数
            this.total = res.data.total;
            //获取默认选中的数据
            this.getDefaultSelData();
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 判断是否可修改复选框数据 */
      disableDefaultRow(row) {
        //只有当默认类型设置了不可选才有用
        if (this.defaultSelectType == 1) {
          return !this.defaultSelected.includes(row.id)
        } else if (this.defaultSelectType == 0) {
          return true;
        }
      },

      /* 获取默认选中的数据 */
      getDefaultSelData() {
        //默认选中已选数组
        this.$nextTick(() => {
          //获取默认选中的工序
          this.processData.forEach((item, index) => {
            if (this.selectedIdArr.includes(item.id)) {
              this.$refs.technologyTableRef.toggleRowSelection(item, true);
            }
          })
        })
      },

      /* 页面数量发生变化 */
      handleSizeChange(val) {
        //获取页面大小
        this.pagesize = val;
        //获取页面数据
        this.getTechnologyId();
      },

      /* 页码发生变化 */
      handleCurrentChange(val) {
        //获取当前页
        this.currentPage = val;
        //获取页面数据
        this.getTechnologyId();
      },

      /* 全选当前页工序*/
      getAllSelected(selection) {
        //获取当前界面所有数据id数组(去掉禁用的)
        let curSelArr = [];//id数组
        let curSel = [];//数据数组
        this.processData.forEach((item, index) => {
          //判断有无无法更改的数据
          if (this.defaultSelectType == 1) {//有禁用数据
            if (!this.defaultSelected.includes(item.id)) {
              curSelArr.push(item.id);
              curSel.push(item);
            }
          } else {
            curSelArr.push(item.id);
            curSel.push(item);
          }
        })
        //获取当前界面不可操作数据id数组
        let curDisableIdArr = [];
        selection.forEach((item,index)=>{
          //判断操作类型(只有需要回显的数据禁用的才使用)
          if (this.defaultSelectType == 1) {
            if(!this.defaultSelected.includes(item.id)){
              curDisableIdArr.push(item)
            }
          }else{
            curDisableIdArr.push(item);
          }
        })
        //判断是全选还是取消
        if(curSel.length == curDisableIdArr.length){//全选
          let selted = JSON.parse(JSON.stringify(this.selectedIdArr))
          curDisableIdArr.forEach((item,index)=>{
            if(!selted.includes(item.id)){
              this.selectedData.push(item);
              this.selectedIdArr.push(item.id);
            }
          })
        }else{
          //过滤掉当前页未勾选的
          this.selectedData = this.selectedData.filter(item=>!curSelArr.includes(item.id));
        }
      },

      /* 获取当前选择的工序 */
      getSelected(selection, row) {
        let hasRowId = -1;
        selection.forEach((item,index)=>{
          if(item.id == row.id){
            hasRowId = row.id;
          }
        })
        // 判断是选中还是取消
        if(hasRowId == -1){//取消
          this.selectedData = this.selectedData.filter(item=>item.id!=row.id);
          this.selectedIdArr = this.selectedIdArr.filter(item=>item!=row.id);
        }else{//选中
          this.selectedData.push(row);
          this.selectedIdArr.push(row.id);
        }
      },

      /* 提交 */
      commit_technology() {
        //将数据传输到父组件
        this.$emit("SelectedData", this.selectedData);
        this.$refs.technologyTableRef.clearSelection();
        //关闭弹框
        this.SHOW_TECHNOLOGYBOX(false);
      },

      /* 取消 */
      cancel_technology() {
        //清空已选值
        this.$refs.technologyTableRef.clearSelection();
        this.SHOW_TECHNOLOGYBOX(false);
      }
    }
  }
</script>

<style>
  .vible {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
