<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：货品详情组件
	开始时间：2021-12-16
	开发人员：刘巍骏
	最后修改：2021-12-16
	备注说明：如需修改请联系开发人员
-->
<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="80%" append-to-body :before-close="closeDialog">
      <!-- dialog头部自定义 -->
      <div slot="title" class="dialogtitle">
        {{productInfo.name}}货品交易记录
      </div>
      <!-- 详情内容 -->
      <el-tabs tab-position="left" v-model="baseType" style="height: 50vh;">
        <el-tab-pane label="基本信息" name="1" id="offerTableBox">
          <div class="baseInfoWapper scollStyle">
            <table class="baseInfoTable">
              <tr>
                <td class="title">货品编码</td>
                <td class="value" v-if="!!this.productInfo.product_code">{{productInfo.product_code}}</td>
                <td class="value" v-if="!(!!this.productInfo.product_code)">暂无数据</td>
              </tr>
              <!-- <tr>
                <td class="title">货品名称</td>
                <td class="value">{{productInfo.name}}</td>
              </tr> -->
              <tr>
                <td class="title">货品规格</td>
                <td class="value" v-if="!!this.productInfo.specifications">{{productInfo.specifications}}</td>
                <td class="value" v-if="!(!!this.productInfo.specifications)">暂无数据</td>
              </tr>
              <tr>
                <td class="title">货品分类</td>
                <td class="value" v-if="!!this.productInfo.product_type_name">{{productInfo.product_type_name}}</td>
                <td class="value" v-if="!(!!this.productInfo.product_type_name)">暂无数据</td>
              </tr>
              <!-- <tr>
                <td class="title">销售单价</td>
                <td class="value">2</td>
              </tr>
              <tr>
                <td class="title">成本单价</td>
                <td class="value">1</td>
              </tr> -->
              <tr>
                <td class="title">计量单位</td>
                <td class="value">{{productInfo.measurement}}</td>
              </tr>
              <tr>
                <td class="title">成本单价</td>
                <td class="value">{{productInfo.purchasePrice}}</td>
              </tr>
              <tr>
                <td class="title">销售单价</td>
                <td class="value">{{productInfo.salePrice}}</td>
              </tr>
              <tr>
                <td class="title">总库存</td>
                <td class="value">{{productInfo.warehouse_number}}</td>
              </tr>
              <tr>
                <td class="title">可用库存</td>
                <td class="value">{{productInfo.usable_number}}</td>
              </tr>
              <tr>
                <td class="title">订单占用库存</td>
                <td class="value">{{productInfo.occupy_number}}</td>
              </tr>
              <tr>
                <td class="title">采购在途库存</td>
                <td class="value">{{productInfo.onway_number}}</td>
              </tr>
              <tr>
                <td class="title">生产中库存</td>
                <td class="value">{{productInfo.product_number}}</td>
              </tr>
              <tr>
                <td class="title">仓储位置</td>
                <td class="value" v-if="!!this.productInfo.storageLocation">{{productInfo.storageLocation}}</td>
                <td class="value" v-if="!(!!this.productInfo.storageLocation)">暂无数据</td>
              </tr>
              <tr>
                <td class="title">货品简介</td>
                <td class="value" v-if="!!this.productInfo.desciption">{{productInfo.desciption}}</td>
                <td class="value" v-if="!(!!this.productInfo.desciption)">暂无数据</td>
              </tr>
            </table>
          </div>
        </el-tab-pane>
        <!-- 交易记录 -->
        <el-tab-pane label="交易记录" name="2">
          <el-tabs type="border-card" v-model="transactionIndex" id="transactiontableBox">
            <el-tab-pane label="报价" name="1">
              <!-- 单据内容 -->
              <div class="proBillTableWapper scollStyle">
                <div class="billing_table">
                  <el-table :data="offerTableData" :summary-method="getoffer" show-summary border style="width: 100%;" height="100%">
                    <el-table-column prop="dataBillingName" label="时间(单据类型)" width="180">
                      <template slot="header" slot-scope="scope">                         <el-popover placement="right" v-model="show_offerBox"   trigger="click">
                          <el-date-picker v-model="offerDate" type="daterange"  size="mini" range-separator="至"                             start-placeholder="开始日期" end-placeholder="结束日期">                           </el-date-picker>

                           <!--按钮-->
                           <div class="datebtn">
                             <el-button type="primary" size="mini" @click="getSaleBillsData(0)">查询</el-button>
                           </div>
                          <span slot="reference" >时间(单据类型)</span>
                         </el-popover>
                      </template>
                    </el-table-column>
                    <el-table-column prop="bills_no" label="单据号" width="180">
                      <template slot-scope="scope">
                        <span class="span_pointer span_color_blue" :title="scope.row.bills_no"
                          @click="updateofferBillData(scope.row,2)">{{scope.row.bills_no}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="numberType" label="数量"   width="150"></el-table-column>
                    <el-table-column prop="priceType" label="单价" width="150"></el-table-column>
                    <el-table-column prop="money" label="金额" width="150"></el-table-column>
                    <el-table-column prop="customer_name"  label="客户名" width="240">
                      <template slot="header" slot-scope="scope">
                        <span slot="reference" @click="getSaleBillsData(0)">客户名</span>
                      </template>
                      <template slot-scope="scope">
                        <span class="span_pointer span_color_blue" :title="scope.row.customer_name"
                          @click="getSaleBillsData(0,scope.row.customer_name)">{{scope.row.customer_name}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="create_user_name" label="制单人" min-width="120"></el-table-column>
                  </el-table>
                </div>
              </div>
              <!-- 分页信息 -->
              <div class="proBillPage">
                <el-pagination @size-change="offerSizeChange" @current-change="offerCurrentChange"
                  :current-page="offerPage.currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="offerPage.pageSize"
                  layout="total, sizes, prev, pager, next, jumper" :total="offerPage.totalPage">
                </el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="销售" name="2" >
              <!-- 单据内容 -->
              <div class="proBillTableWapper scollStyle">
                <div class="billing_table">
                  <el-table :data="orderTableData" :summary-method="getorder" ref="table-01" show-summary border style="width: 100%;" height="100%">
                    <el-table-column prop="dataBillingName" label="时间(单据类型)" width="180">
                      <template slot="header" slot-scope="scope">
                         <el-popover placement="right" v-model="show_orderBox"  trigger="click">
                          <el-date-picker v-model="orderDate" type="daterange"  size="mini" range-separator="至"
                             start-placeholder="开始日期" end-placeholder="结束日期">
                           </el-date-picker>
                           <!--按钮-->
                           <div class="datebtn">
                             <el-button type="primary" size="mini" @click="getSaleBillsData(1)">查询</el-button>
                           </div>
                          <span slot="reference">时间(单据类型)</span>
                         </el-popover>
                      </template>
                    </el-table-column>
                    <el-table-column prop="bills_no" label="单据号" width="180">
                      <template slot-scope="scope">
                        <span class="span_pointer span_color_blue" :title="scope.row.bills_no"
                          @click="updateorderBillData(scope.row,2)">{{scope.row.bills_no}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="numberType" label="数量" width="150"></el-table-column>
                    <el-table-column prop="priceType" label="单价" width="150"></el-table-column>
                    <el-table-column prop="money" label="金额" width="150"></el-table-column>
                    <el-table-column prop="customer_name" label="客户名" width="240">
                      <template slot="header" slot-scope="scope">
                        <span slot="reference" @click="getSaleBillsData(1)">客户名</span>
                      </template>
                      <template slot-scope="scope">
                        <span class="span_pointer span_color_blue" :title="scope.row.customer_name"
                          @click="getSaleBillsData(1,scope.row.customer_name)">{{scope.row.customer_name}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="create_user_name" label="制单人" min-width="120"></el-table-column>
                  </el-table>
                </div>
              </div>
              <!-- 分页信息 -->
              <div class="proBillPage">
                <el-pagination @size-change="orderSizeChange" @current-change="orderCurrentChange"
                  :current-page="orderPage.currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="orderPage.pageSize"
                  layout="total, sizes, prev, pager, next, jumper" :total="orderPage.totalPage">
                </el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="出库" name="3">
              <!-- 单据内容 -->
              <div class="proBillTableWapper scollStyle">
                <div class="billing_table">
                  <el-table :data="depotIssue" :summary-method="getdepot" ref="table-02" show-summary border style="width: 100%;" height="100%">
                    <el-table-column prop="dataBillingName" label="时间(单据类型)" width="190">
                      <template slot="header" slot-scope="scope">
                         <el-popover placement="right" v-model="show_depotBox"  trigger="click">
                          <el-date-picker v-model="depotDate" type="daterange"  size="mini" range-separator="至"
                             start-placeholder="开始日期" end-placeholder="结束日期">
                           </el-date-picker>
                           <!--按钮-->
                           <div class="datebtn">
                             <el-button type="primary" size="mini" @click="getDepotBillsData(1)">查询</el-button>
                           </div>
                          <span slot="reference">时间(单据类型)</span>
                         </el-popover>
                      </template>
                    </el-table-column>
                    <el-table-column prop="bills_no" label="单据号" width="180">
                      <template slot-scope="scope">
                        <span class="span_pointer span_color_blue" :title="scope.row.bills_no"
                          @click="updatedepotBillData(scope.row,2)">{{scope.row.bills_no}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="numberType" label="出库数量" width="150"></el-table-column>
                    <el-table-column prop="delivery_date" label="送货日期" width="120"></el-table-column>
                    <el-table-column prop="houseName" label="出库仓库" width="100"></el-table-column>
                    <el-table-column prop="customer_name" label="客户名" width="240">
                      <template slot="header" slot-scope="scope">
                        <span slot="reference" @click="getDepotBillsData(1)">客户名</span>
                      </template>
                      <template slot-scope="scope">
                        <span class="span_pointer span_color_blue" :title="scope.row.customer_name"
                          @click="getDepotBillsData(1,scope.row.customer_id)">{{scope.row.customer_name}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="create_user_name" label="制单人" min-width="120"></el-table-column>
                  </el-table>
                </div>
              </div>
              <!-- 分页信息 -->
              <div class="proBillPage">
                <el-pagination @size-change="depotSizeChange" @current-change="depotCurrentChange"
                  :current-page="depotPage.currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="depotPage.pageSize"
                  layout="total, sizes, prev, pager, next, jumper" :total="depotPage.totalPage">
                </el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="入库" name="4">
              <!-- 单据内容 -->
              <div class="proBillTableWapper scollStyle">
                <div class="billing_table">
                  <el-table :data="depotInIssue" :summary-method="getdepotIn" ref="table-03" show-summary border style="width: 100%;" height="100%">
                    <el-table-column prop="dataBillingName" label="时间(单据类型)" width="190">
                        <template slot="header" slot-scope="scope">
                           <el-popover placement="right" v-model="show_depotInBox"  trigger="click">
                            <el-date-picker v-model="depotInDate" type="daterange"  size="mini" range-separator="至"
                               start-placeholder="开始日期" end-placeholder="结束日期">
                             </el-date-picker>
                             <!--按钮-->
                             <div class="datebtn">
                               <el-button type="primary" size="mini" @click="getDepotBillsData(0)">查询</el-button>
                             </div>
                            <span slot="reference">时间(单据类型)</span>
                           </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column prop="bills_no" label="单据号" width="180">
                      <template slot-scope="scope">
                        <span class="span_pointer span_color_blue" :title="scope.row.bills_no"
                          @click="updatedepotInBillData(scope.row,2)">{{scope.row.bills_no}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="numberType" label="入库数量" width="150"></el-table-column>
                    <el-table-column prop="delivery_date" label="送货日期" width="120"></el-table-column>
                    <el-table-column prop="houseName" label="入库仓库" width="120"></el-table-column>
                    <el-table-column prop="supplier_name" label="供应商名" width="240">
                      <template slot="header" slot-scope="scope">
                        <span slot="reference" @click="getDepotBillsData(0)">供应商名</span>
                      </template>
                      <template slot-scope="scope">
                        <span class="span_pointer span_color_blue" :title="scope.row.supplier_name"
                          @click="getDepotBillsData(0,scope.row.supplier_id)">{{scope.row.supplier_name}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="create_user_name" label="制单人" min-width="120"></el-table-column>
                  </el-table>
                </div>
              </div>
              <!-- 分页信息 -->
              <div class="proBillPage">
                <el-pagination @size-change="depotInSizeChange" @current-change="depotInCurrentChange"
                  :current-page="depotInPage.currentPage" :page-sizes="[10, 20, 30, 40]"
                  :page-size="depotInPage.pageSize" layout="total, sizes, prev, pager, next, jumper"
                  :total="depotInPage.totalPage">
                </el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="采购" name="5">
              <!-- 单据内容 -->
              <div class="proBillTableWapper scollStyle">
                <div class="billing_table">
                  <el-table :data="purchaseData" :summary-method="getpurchase" ref="table-04" show-summary border style="width: 100%;" height="100%">
                    <el-table-column prop="dataBillingName" label="时间(单据类型)" width="190">
                      <template slot="header" slot-scope="scope">
                         <el-popover placement="right" v-model="show_purchaseBox"  trigger="click">
                          <el-date-picker v-model="purchaseDate" type="daterange"  size="mini" range-separator="至"
                             start-placeholder="开始日期" end-placeholder="结束日期">
                           </el-date-picker>
                           <!--按钮-->
                           <div class="datebtn">
                             <el-button type="primary" size="mini" @click="getPurchaseBillsData()">查询</el-button>
                           </div>
                          <span slot="reference">时间(单据类型)</span>
                         </el-popover>
                      </template>
                    </el-table-column>
                    <el-table-column prop="bills_no" label="单据号" width="180">
                      <template slot-scope="scope">
                        <span class="span_pointer span_color_blue" :title="scope.row.bills_no"
                          @click="updatepurchaseBillData(scope.row,2)">{{scope.row.bills_no}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="numberType" label="采购数量" width="150"></el-table-column>
                    <el-table-column prop="purchase_type_name" label="采购类型" width="150"></el-table-column>
                    <el-table-column prop="depot_state" label="入库状态" width="80">
                      <template slot-scope="scope">
                        <el-button v-for="(elem,index) in depotStateIn" :type="elem.class"
                          :style="{background:elem.color,border:'none'}" size="mini"
                          v-if="scope.row.depot_state == elem.value">
                          {{elem.name}}
                        </el-button>
                      </template>
                    </el-table-column>
                    <el-table-column prop="supplier_name" label="供应商名称" width="240">
                      <template slot="header" slot-scope="scope">
                        <span slot="reference" @click="getPurchaseBillsData()">供应商名</span>
                      </template>
                      <template slot-scope="scope">
                        <span class="span_pointer span_color_blue" :title="scope.row.supplier_name"
                          @click="getPurchaseBillsData(scope.row.supplier_name)">{{scope.row.supplier_name}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="create_user_name" label="制单人" min-width="120"></el-table-column>
                  </el-table>
                </div>
              </div>
              <!-- 分页信息 -->
              <div class="proBillPage">
                <el-pagination @size-change="purchaseSizeChange" @current-change="purchaseCurrentChange"
                  :current-page="purchasePage.currentPage" :page-sizes="[10, 20, 30, 40]"
                  :page-size="purchasePage.pageSize" layout="total, sizes, prev, pager, next, jumper"
                  :total="purchasePage.totalPage">
                </el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="生产" name="6">
              <!-- 单据内容 -->
              <div class="proBillTableWapper scollStyle">
                <div class="billing_table">
                  <el-table :data="mesData" :summary-method="getmes" ref="table-05" show-summary border style="width: 100%;" height="100%">
                    <el-table-column prop="dataBillingName" label="时间(单据类型)" width="190">
                      <template slot="header" slot-scope="scope">
                         <el-popover placement="right" v-model="show_mesBox"  trigger="click">
                          <el-date-picker v-model="mesDate" type="daterange"  size="mini" range-separator="至"
                             start-placeholder="开始日期" end-placeholder="结束日期">
                           </el-date-picker>
                           <!--按钮-->
                           <div class="datebtn">
                             <el-button type="primary" size="mini" @click="getMesBillsData()">查询</el-button>
                           </div>
                          <span slot="reference">时间(单据类型)</span>
                         </el-popover>
                      </template>
                    </el-table-column>
                    <el-table-column prop="bills_no" label="单据号" width="180">
                      <template slot-scope="scope">
                        <span class="span_pointer span_color_blue" :title="scope.row.bills_no"
                          @click="updatemesBillData(scope.row,2)">{{scope.row.bills_no}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="numberType" label="生产数量" width="150"></el-table-column>
                    <el-table-column prop="depot_state" label="完工状态" width="80">
                      <template slot-scope="scope">
                        <el-button v-for="(elem,index) in depotStateIn" :type="elem.class"
                          :style="{background:elem.color,border:'none'}" size="mini"
                          v-if="scope.row.depot_state == elem.value">
                          {{elem.name}}
                        </el-button>
                      </template>
                    </el-table-column>
                    <el-table-column prop="successType" label="完工数量" width="150"></el-table-column>
                    <el-table-column prop="complete_date" label="完工时间" width="180"></el-table-column>
                    <el-table-column prop="create_user_name" label="制单人" min-width="120"></el-table-column>
                  </el-table>
                </div>
              </div>
              <!-- 分页信息 -->
              <div class="proBillPage">
                <el-pagination @size-change="mesSizeChange" @current-change="mesCurrentChange"
                  :current-page="mesPage.currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="mesPage.pageSize"
                  layout="total, sizes, prev, pager, next, jumper" :total="mesPage.totalPage">
                </el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <!-- 导出数据 -->
        <el-tab-pane label="导出数据" name="3" >
          <div class="baseInfoWapper scollStyle" >
              <div class="exportTitle">选择打印时间区间</div>
              <el-row>
                <el-col :span="10">
                  <el-date-picker type="date" placeholder="选择日期" v-model="exportStartTime"
                    style="width: 100%;" size="mini"></el-date-picker>
                </el-col>
                <el-col class="line" :span="3" :push="1"> - </el-col>
                <el-col :span="10">
                  <el-date-picker type="date" placeholder="选择日期" v-model="exportEndTime"
                    style="width: 100%;" size="mini"></el-date-picker>
                </el-col>
              </el-row>
              <div class="exportTitle">选择类型(不选则默认为全部)</div>
              <el-checkbox-group v-model="checkList">
                  <el-checkbox label="报价"></el-checkbox>
                  <el-checkbox label="销售"></el-checkbox>
                  <el-checkbox label="出库"></el-checkbox>
                  <el-checkbox label="入库"></el-checkbox>
                  <el-checkbox label="采购"></el-checkbox>
                  <el-checkbox label="生产"></el-checkbox>
              </el-checkbox-group>
          </div>
        </el-tab-pane>
      </el-tabs>


      <!-- 确认按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="productExport" v-if="baseType=='3'">导 出</el-button>
        <el-button type="primary" size="mini" @click="closeDialog">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 新增单据组件 -->
    <nodeBills :nodeTypeCode="nodeTypeCode" :billsClassify="billsClassify" :billsTypeSign="billsTypeSign"
      :updateDefaultData="updateDefaultData" :operateType="operateType" :generateBillsId="generateBillsId"
      :generateBillsSign="generateBillsSign" :generateBillsOriginData="generateBillsOriginData"
      :billsTypeDefaultCode="billsTypeDefaultCode" :isGenerateOperateType="isGenerateOperateType"
      :dictionariesDefaultData="dictionariesDefaultData" v-if="show_nodeBills"></nodeBills>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapState,
  } from 'vuex'
  //导入后端接口
  import productApi from '@/network/datum/product.js'; //货品模块
  import saleApi from '@/network/sell/bills.js'; //销售模块
  import depotApi from '@/network/stock/stock.js'; //库存模块
  import purchaseApi from '@/network/stock/purchase.js'; //采购模块
  import mesApi from '@/network/production/production.js'; //货品模块
  import nodeBills from '@/components/commComponent/billing/nodeBills'; //新建单据公共组件

  export default {
    name:'product_detail',
    props: {
      // 货品id
      productId: {
        type: Number,
        default: -1
      },
      // 货品相关单据详情tab页
      proDetailBillTab: {
        type: String,
        default: ""
      },
      // 采购类型
      classify: {
        type: String,
        default:""
      },
      //是否只加载已入库 1为值加载已入库 2为加载其它状态
      mesDepotState:{
        type: String,
        default:""
      },
    },
    data() {
      return {
        // 当前货品详情
        productInfo: {}, //当前货品信息

        // 交易记录详情单据
        offerTableData: [], //报价单据
        orderTableData: [], //销售单据
        depotIssue: [], //出库单据
        depotInIssue: [], //入库单据
        purchaseData: [], //采购单据
        mesData: [], //计划单单据
        baseType:"1",//左侧导航栏下标
        transactionIndex: "1", //交易记录tab页当前下标

        // 控制开关
        dialogVisible: true, //控制显示弹框
        show_offerBox:false,//报价显示时间控件
        show_orderBox:false,//销售显示时间控件
        show_depotBox:false,//出库显示时间控件
        show_depotInBox:false,//入库显示时间控件
        show_purchaseBox:false,//采购显示时间控件
        show_mesBox:false,//生产显示时间控件
        show_nodeBills: false, //控制显示公共单据组件弹框
        //查询条件相关
        offerDate: '', //报价单时间查询条件
        orderDate:'',//销售单时间查询条件
        depotDate:'',//出库单时间查询条件
        depotInDate:'',//入库单时间查询条件
        purchaseDate:'',//采购单时间查询条件
        mesDate:'',//计划单时间查询条件

        //传入子组件的数据
        nodeTypeCode: 'SYS-NODE-TYPE-001', //单据分类code
        operateType: 2, //操作类型(0:新增 1:修改  2:查看)
        billsTypeSign: 0, //单据分类标签(0代表销售)
        billsClassify: -1, //单据分类
        billsTypeDefaultCode: '', //默认单据类型
        isGenerateOperateType: -1, //是否为生成类型操作
        generateBillsId: -1, //需要生成其他单据的id
        generateBillsSign: -1, //源单分类标识
        generateBillsOriginData: {}, //需要生成其他单据的数据源
        dictionariesDefaultData: {}, //数据字典默认数据
        updateDefaultData: { //修改时默认流程和节点等信息
          nodeId: -1, //节点id
          processId: -1, //流程id
        },
        billType: '', //单据权期分类

        //导出数据相关
        exportStartTime:'',
        exportEndTime:'',
        checkList:[],
        operate: "", //当前操作类型
        //分页相关数据
        offerPage: {
          pageSize: 20, //分页大小
          currentPage: 1, //当前页
          totalPage: 0, //总页数
        },
        orderPage: {
          pageSize: 20, //分页大小
          currentPage: 1, //当前页
          totalPage: 0, //总页数
        },
        depotPage: {
          pageSize: 20, //分页大小
          currentPage: 1, //当前页
          totalPage: 0, //总页数
        },
        depotInPage: {
          pageSize: 20, //分页大小
          currentPage: 1, //当前页
          totalPage: 0, //总页数
        },
        purchasePage: {
          pageSize: 20, //分页大小
          currentPage: 1, //当前页
          totalPage: 0, //总页数
        },
        mesPage: {
          pageSize: 20, //分页大小
          currentPage: 1, //当前页
          totalPage: 0, //总页数
        },
      }
    },
    mounted() {
      //获取默认交易记录tab页
      setTimeout(() => {
        this.getDefaultBillTab();
      },1000)
      //获取货品基本信息
       this.findBaseProductByProductId(this.productId);

    },
    components: {
      nodeBills
    },
    watch: {

      //监听交易记录tab页刷新改变
      transactionIndex: {
        handler(newVal) {
          this.getBillsData(newVal);
        },
        immediate: true
      }
    },
    computed: {
      ...mapState({
        mesState: state => state.bill_state.mesState, //生产状态
        depotStateIn: state => state.bill_state.depotStateIn, //采购状态
        purchaseMesState: state => state.bill_state.purchaseMesState, //请购状态
        UserInfo: state => state.user.UserInfo, //当前登录用户信息

        //权限相关
        companyparamlist: state => state.companyparamlist, //获取企业级参数
      })
    },
    updated () {
    　　this.$nextTick(() => {
    　　　　this.$refs['table-01'].doLayout();
    this.$refs['table-02'].doLayout();
    this.$refs['table-03'].doLayout();
    this.$refs['table-04'].doLayout();
    this.$refs['table-05'].doLayout();
    　　})
    },
    methods: {
      /* 获取默认交易记录详情页 */
      getDefaultBillTab() {
        if (this.proDetailBillTab != "") {
          this.transactionIndex = this.proDetailBillTab;

          this.baseType='2';
        }
      },

      /* ******************导出数据相关****************** */

      /* 下载文件或打开pdf文件 */
      downloadFile(href, name) {
        if (this.operate == 2) { //为pdf文件时
          let filePreViewData = {
            file_path: href,
            name
          }
          this.showFilePreView(filePreViewData)
        } else if (this.operate == 1) { //为其他文件时
          const link = document.createElement("a");
          link.style.display = 'none';
          link.href = baseUrl + href;
          link.download = name;
          document.body.appendChild(link);
          link.click();
        }
      },

      productExport(){
        if(this.companyparamlist.param371==0){
          let jurisdiction=this.companyparamlist.param372.split(",")
          if(!jurisdiction.includes(this.UserInfo.user_id.toString())){
            this.$message({
              type: 'warning',
              message: '你没有导出权限!',
              duration: this.elDuration,
            })
            return
          }
        }
        // this.checkList.forEach(())
        if(!!this.exportStartTime){
          this.exportStartTime=this.commonJsExtend.getDateTime(this.exportStartTime,0)
        }
        if(!!this.exportEndTime){
          this.exportEndTime=this.commonJsExtend.getDateTime(this.exportEndTime,0)
        }
        let data={
          startTime:this.exportStartTime,
          endTime:this.exportEndTime,
          productId: this.productId,
          sign:'0,1,2,3,4,5',
        }
        if(!!this.checkList&&this.checkList.length>0){
          let sign=[]
          this.checkList.forEach((item,index)=>{
            if(item=="报价"){
              sign.push(0)
            }
            else if(item=="销售"){
              sign.push(1)
            }
            else if(item=="出库"){
              sign.push(2)
            }
            else if(item=="入库"){
              sign.push(3)
            }
            else if(item=="采购"){
              sign.push(4)
            }
            else if(item=="生产"){
              sign.push(5)
            }
          })
            data.sign=sign.join(",")

        }
        console.log(data)
        this.loading = this.commonJsExtend.customLoading("#productExportTableBox", 4, '货品相关数据导出中,请稍候...');
        productApi.exportProduct(data).then(res=>{
              if (res.code == 200 && res.data) {
                this.loading.close()
                let filePathArr = [];
                let name = '';
                filePathArr = res.data.split('//')
                name = decodeURI(filePathArr[filePathArr.length - 1])
                //设置操作类型为导出excle
                this.operate = 1;
                //下载文件
                this.downloadFile(res.data, name, 1)
                //关闭导出弹框
                this.closeExportListBox()
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
                this.loading.close()
              }
        })
      },
      /* ******************预览单据相关****************** */
     /* 预览报价订单*/
      updateofferBillData(data, type, operateOrigin, nodeTypeCode) {

        //将操作设置为对应type
        this.operateType = type;
        //设置单据类型标签(设置为销售模块)
        this.billsTypeSign = 0;
        //获取需要回显的单据id
        this.generateBillsId = data.id;
        //设置单据分类类型
        this.billsClassify = 0;
        //判断是否为预览界面的修改
        if (nodeTypeCode) {
          this.nodeTypeCode = nodeTypeCode;
        } else {
          //设置单据分类code为销售
          this.nodeTypeCode = 'SYS-NODE-TYPE-001';
        }
        //设置默认相关数据
        this.updateDefaultData = {
          nodeId: data.status_id, //节点id
          processId: data.process_id, //流程id
        }
        //设置默认单据类型
        if (!operateOrigin) {
          this.billsTypeDefaultCode = data.code;
        }
        //显示操作弹框
        this.show_nodeBills = true;
      },
      //预览销售订单
      updateorderBillData(data, type, operateOrigin, nodeTypeCode) {

        //将操作设置为对应type
        this.operateType = type;
        //设置单据类型标签(设置为销售模块)
        this.billsTypeSign = 0;
        //获取需要回显的单据id
        this.generateBillsId = data.id;
        //设置单据分类类型
        this.billsClassify = 1;
        //判断是否为预览界面的修改
        if (nodeTypeCode) {
          this.nodeTypeCode = nodeTypeCode;
        } else {
          //设置单据分类code为销售
          this.nodeTypeCode = 'SYS-NODE-TYPE-001';
        }
        //设置默认相关数据
        this.updateDefaultData = {
          nodeId: data.status_id, //节点id
          processId: data.process_id, //流程id
        }
        //设置默认单据类型
        if (!operateOrigin) {
          this.billsTypeDefaultCode = data.code;
        }
        //显示操作弹框
        this.show_nodeBills = true;
      },

      //预览出库订单
      updatedepotBillData(data, type, operateOrigin, nodeTypeCode) {

        //将操作设置为对应type
        this.operateType = type;
        //设置单据类型标签(设置为库存模块)
        this.billsTypeSign = 2;
        //获取需要回显的单据id
        this.generateBillsId = data.id;
        //设置单据分类类型
        this.billsClassify = 1;
        //判断是否为预览界面的修改
        if (nodeTypeCode) {
          this.nodeTypeCode = nodeTypeCode;
        } else {
          //设置单据分类code为销售
          this.nodeTypeCode = 'SYS-NODE-TYPE-004';
        }
        //设置默认相关数据
        this.updateDefaultData = {
          nodeId: data.status_id, //节点id
          processId: data.process_id, //流程id
        }
        //设置默认单据类型
        if (!operateOrigin) {
          this.billsTypeDefaultCode = data.code;
        }
        //显示操作弹框
        this.show_nodeBills = true;
      },

      //预览入库订单
      updatedepotInBillData(data, type, operateOrigin, nodeTypeCode) {

        //将操作设置为对应type
        this.operateType = type;
        //设置单据类型标签(设置为销售模块)
        this.billsTypeSign = 2;
        //获取需要回显的单据id
        this.generateBillsId = data.id;
        //设置单据分类类型
        this.billsClassify = 0;
        //判断是否为预览界面的修改
        if (nodeTypeCode) {
          this.nodeTypeCode = nodeTypeCode;
        } else {
          //设置单据分类code为销售
          this.nodeTypeCode = 'SYS-NODE-TYPE-004';
        }
        //设置默认相关数据
        this.updateDefaultData = {
          nodeId: data.status_id, //节点id
          processId: data.process_id, //流程id
        }
        //设置默认单据类型
        if (!operateOrigin) {
          this.billsTypeDefaultCode = data.code;
        }
        //显示操作弹框
        this.show_nodeBills = true;
      },

      //预览采购订单
      updatepurchaseBillData(data, type, operateOrigin, nodeTypeCode) {

        //将操作设置为对应type
        this.operateType = type;
        //设置单据类型标签(设置为采购模块)
        this.billsTypeSign = 3;
        //获取需要回显的单据id
        this.generateBillsId = data.id;
        //设置单据分类类型
        if(data.code=="DOC-P-001"){
          this.billsClassify = 0;
        }else{
          this.billsClassify = 1;
        }

        //判断是否为预览界面的修改
        if (nodeTypeCode) {
          this.nodeTypeCode = nodeTypeCode;
        } else {
          //设置单据分类code为销售
          this.nodeTypeCode = 'SYS-NODE-TYPE-002';
        }
        //设置默认相关数据
        this.updateDefaultData = {
          nodeId: data.status_id, //节点id
          processId: data.process_id, //流程id
        }
        //设置默认单据类型
        if (!operateOrigin) {
          this.billsTypeDefaultCode = data.code;
        }
        //显示操作弹框
        this.show_nodeBills = true;
      },

      //预览生产订单
      updatemesBillData(data, type, operateOrigin, nodeTypeCode) {

        //将操作设置为对应type
        this.operateType = type;
        //设置单据类型标签(设置为生产模块)
        this.billsTypeSign = 1;
        //获取需要回显的单据id
        this.generateBillsId = data.id;
        //设置单据分类类型
        this.billsClassify = 0;
        //判断是否为预览界面的修改
        if (nodeTypeCode) {
          this.nodeTypeCode = nodeTypeCode;
        } else {
          //设置单据分类code为销售
          this.nodeTypeCode = 'SYS-NODE-TYPE-003';
        }
        //设置默认相关数据
        this.updateDefaultData = {
          nodeId: data.status_id, //节点id
          processId: data.process_id, //流程id
        }
        //设置默认单据类型
        if (!operateOrigin) {
          this.billsTypeDefaultCode = data.code;
        }
        //显示操作弹框
        this.show_nodeBills = true;
      },
      /* 关闭单据公共弹框 */
      closeNodeBills() {
        this.show_nodeBills = false;
      },
      /* ******************基本信息相关****************** */
      findBaseProductByProductId(productId) {
        //加载loading框

        this.loading = this.commonJsExtend.customLoading("#offerTableBox", 4, '货品基本信息加载中,请稍候...');
        var data = {
          productId: productId,
          isOrder: 0, //是否显示销售单位
        }
        if (this.proDetailBillTab == 1 || this.proDetailBillTab == 2) {
          data.isOrder = 1;
        }
        console.log(data.isOrder)
        productApi.findBaseProductByProductId(data).then(res => {
          this.loading.close() //关闭loading框
          if (res.code == 200) {
            this.productInfo = res.data

          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },
      /* ******************时间控件显示相关****************** */
      // //报价显示时间控件框
      // changOfferDateBox(){
      //   this.show_offerBox=!this.show_offerBox
      // },





      /* ******************单据请求相关****************** */
      /*分发单据tab页请求*/
      getBillsData(data) {

        if (data == "1") { //当为报价的时候
          this.getSaleBillsData(0)
        } else if (data == "2") { //当为销售的时候
          this.getSaleBillsData(1)
        } else if (data == "3") { //当为出库的时候
          this.getDepotBillsData(1)
        } else if (data == "4") { //当为入库的时候
          this.getDepotBillsData(0)
        } else if (data == "5") { //当为采购单的时候
          this.getPurchaseBillsData()
        } else if (data == "6") { //当为计划单的时候
          this.getMesBillsData()
        }
      },
      /*获取销售或报价单相关信息*/
      getSaleBillsData(isOrder,customerName) {
        let startTime=null
        let endTime=null
        if(isOrder==0){
          this.show_offerBox=false
          if(!!this.offerDate){
            startTime=this.commonJsExtend.getDateTime(this.offerDate[0], 0);
            endTime=this.commonJsExtend.getDateTime(this.offerDate[1], 0);
          }
        }
        else if (isOrder==1) {
          this.show_orderBox=false
          if(!!this.orderDate){
            startTime=this.commonJsExtend.getDateTime(this.orderDate[0], 0);
            endTime=this.commonJsExtend.getDateTime(this.orderDate[1], 0);
          }
        }

        //定义请求数据
        let data = {
          pageIndex: this.offerPage.currentPage,
          pageSize: this.offerPage.pageSize,
          productId: this.productId,
          isOrder: isOrder,
          startTime:startTime,
          endTime:endTime,
          customerName:customerName,
        }
        if (isOrder == 1) {
          data.pageIndex = this.orderPage.currentPage
          data.pageSize = this.orderPage.pageSize
        }
        this.loading = this.commonJsExtend.customLoading("#transactiontableBox", 4, '数据更新中,请稍候...');
        saleApi.offerSaleOrderMain(data).then(res => {
          this.loading.close()
          if (res.code == 200) {
            if (isOrder == 0) {
              this.offerTableData = res.data.rows
              this.offerPage.totalPage = res.data.total
            } else if (isOrder == 1) {
              this.orderTableData = res.data.rows
              this.orderPage.totalPage = res.data.total
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },
      /*获取出入库单相关信息*/
      getDepotBillsData(depotClassify,nameId) {
        let startTime=null
        let endTime=null
        //定义请求数据
        let data = {
          pageIndex: this.depotPage.currentPage,
          pageSize: this.depotPage.pageSize,
          productId: this.productId,
          depotClassify: depotClassify,
          startTime:startTime,
          endTime:endTime,
          nameId:nameId,
        }
        if (depotClassify == 0) {
          this.show_depotInBox=false
          data.pageIndex = this.depotInPage.currentPage
          data.pageSize = this.depotInPage.pageSize
          if(!!this.depotInDate){
            data.startTime=this.commonJsExtend.getDateTime(this.depotInDate[0], 0);
            data.endTime=this.commonJsExtend.getDateTime(this.depotInDate[1], 0);
          }
        }else if(depotClassify==1){
          this.show_depotBox=false
          if(!!this.depotDate){
            data.startTime=this.commonJsExtend.getDateTime(this.depotDate[0], 0);
            data.endTime=this.commonJsExtend.getDateTime(this.depotDate[1], 0);
          }

        }
        this.loading = this.commonJsExtend.customLoading("#transactiontableBox", 4, '数据更新中,请稍候...');
        depotApi.findDepotProByproductId(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            if (depotClassify == 0) {
              this.depotInIssue = res.data.rows
              this.depotInPage.totalPage = res.data.total
            } else if (depotClassify == 1) {
              this.depotIssue = res.data.rows
              this.depotPage.totalPage = res.data.total
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },
      /*获取采购单相关信息*/
      getPurchaseBillsData(supplierNames) {
        this.show_purchaseBox=false
        let startTime=null
        let endTime=null
        let classify=null
        let supplierName=null
        if(!!this.classify){
          classify=this.classify
        }
        if(!!supplierNames){
          supplierName=supplierNames
        }
        //定义请求数据
        let data = {
          pageIndex: this.purchasePage.currentPage,
          pageSize: this.purchasePage.pageSize,
          productId: this.productId,
          startTime:startTime,
          endTime:endTime,
          supplierName:supplierName,
          classify:classify,
        }
        if(!!this.purchaseDate){
          data.startTime=this.commonJsExtend.getDateTime(this.purchaseDate[0], 0);
          data.endTime=this.commonJsExtend.getDateTime(this.purchaseDate[1], 0);
        }
        this.loading = this.commonJsExtend.customLoading("#transactiontableBox", 4, '数据更新中,请稍候...');
        purchaseApi.offerPurchaseMain(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.purchaseData = res.data.rows
            this.purchasePage.totalPage = res.data.total
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /*获取计划单相关信息*/
      getMesBillsData() {
        this.show_mesBox=false
        let startTime=null
        let endTime=null
        let mesDepotState=null
        if(!!this.mesDepotState){
          mesDepotState=this.mesDepotState
        }
        //定义请求数据
        let data = {
          pageIndex: this.mesPage.currentPage,
          pageSize: this.mesPage.pageSize,
          productId: this.productId,
          startTime:startTime,
          endTime:endTime,
          mesDepotState:mesDepotState,
        }
        if(!!this.mesDate){
          data.startTime=this.commonJsExtend.getDateTime(this.mesDate[0], 0);
          data.endTime=this.commonJsExtend.getDateTime(this.mesDate[1], 0);
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#transactiontableBox", 4, '数据更新中,请稍候...');
        console.log($("#transactiontableBox"))
        mesApi.offerMesMain(data).then(res => {
          //关闭loading框
          this.loading.close();
          if (res.code == 200) {
            this.mesData = res.data.rows
            this.mesPage.totalPage = res.data.total
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },




      /* ******************分页信息改变相关****************** */
      /* 报价分页大小改变 */
      offerSizeChange(val) {
        //获取页面大小
        this.offerPage.pageSize = val;
        //获取数据
        this.getSaleBillsData(0)
      },

      /* 报价当前页改变 */
      offerCurrentChange(val) {
        //获取当前页
        this.offerPage.currentPage = val;
        //获取数据
        this.getSaleBillsData(0)
      },
      /* 销售分页大小改变 */
      orderSizeChange(val) {
        //获取页面大小
        this.orderPage.pageSize = val;
        //获取数据
        this.getSaleBillsData(1)
      },

      /* 销售当前页改变 */
      orderCurrentChange(val) {
        //获取当前页
        this.orderPage.currentPage = val;
        //获取数据
        this.getSaleBillsData(1)
      },
      /* 出库分页大小改变 */
      depotSizeChange(val) {
        //获取页面大小
        this.depotPage.pageSize = val;
        //获取数据
        this.getDepotBillsData(1)

      },

      /* 出库当前页改变 */
      depotCurrentChange(val) {
        //获取当前页
        this.depotPage.currentPage = val;
        //获取数据
        this.getDepotBillsData(1)
      },
      /* 入库分页大小改变 */
      depotInSizeChange(val) {
        //获取页面大小
        this.depotInPage.pageSize = val;
        //获取数据
        this.getDepotBillsData(0)
      },

      /* 入库当前页改变 */
      depotInCurrentChange(val) {
        //获取当前页
        this.depotInPage.currentPage = val;
        //获取数据
        this.getDepotBillsData(0)
      },
      /* 采购分页大小改变 */
      purchaseSizeChange(val) {
        //获取页面大小
        this.purchasePage.pageSize = val;
        //获取数据
        this.getPurchaseBillsData()
      },

      /* 采购当前页改变 */
      purchaseCurrentChange(val) {
        //获取当前页
        this.purchasePage.currentPage = val;
        //获取数据
        this.getPurchaseBillsData()
      },
      /* 计划单分页大小改变 */
      mesSizeChange(val) {
        //获取页面大小
        this.mesPage.pageSize = val;
        //获取数据
        this.getMesBillsData()
      },

      /* 计划单当前页改变 */
      mesCurrentChange(val) {
        //获取当前页
        this.mesPage.currentPage = val;
        //获取数据
        this.getMesBillsData()
      },
      //获得报价下框合计信息
      getoffer(param){
         const sums = [];
         let num=0;
         let priceCount=0;
         if(this.offerTableData.length>=1){
           this.offerTableData.forEach(item=>{
             num=num+item.number
             priceCount=priceCount+item.money
           })
         }
         num=this.commonJsExtend.getDecimalsLen(num,3)
         priceCount=this.commonJsExtend.getDecimalsLen(priceCount,3)
         sums[2]=num
         sums[4]=priceCount
          return sums
      },
      //获取销售下框合计信息
      getorder(param){
        const sums = [];
        let num=0;
        let priceCount=0;
        if(this.orderTableData.length>=1){
          this.orderTableData.forEach(item=>{
            num=num+item.number
            priceCount=priceCount+item.money
          })
        }
        num=this.commonJsExtend.getDecimalsLen(num,3)
        priceCount=this.commonJsExtend.getDecimalsLen(priceCount,3)
        sums[2]=num
        sums[4]=priceCount

        return sums
      },
      //获得出库下框合计信息
      getdepot(param){
        const sums = [];
        let num=0;
        let priceCount=0;
        if(this.depotIssue.length>=1){
          this.depotIssue.forEach(item=>{
            num=num+item.number
            priceCount=priceCount+item.money
          })
        }
        num=this.commonJsExtend.getDecimalsLen(num,3)
        sums[2]=num
        return sums
      },
      //获得入库下框合计信息
      getdepotIn(param){
        const sums = [];
        let num=0;
        let priceCount=0;
        if(this.depotInIssue.length>=1){
          this.depotInIssue.forEach(item=>{
            num=num+item.number
            priceCount=priceCount+item.money
          })
        }
        num=this.commonJsExtend.getDecimalsLen(num,3)
        sums[2]=num
        return sums
      },
      //获得采购下框合计信息
      getpurchase(param){
        const sums = [];
        let num=0;
        let priceCount=0;
        if(this.purchaseData.length>=1){
          this.purchaseData.forEach(item=>{
            num=num+item.number
          })
        }
        num=this.commonJsExtend.getDecimalsLen(num,3)
        sums[2]=num

        return sums
      },
      //获得计划单下框合计信息
      getmes(param){
        const sums = [];
        let num=0;
        let priceCount=0;
        if(this.mesData.length>=1){
          this.mesData.forEach(item=>{
            num=num+item.plan_num
            priceCount=priceCount+item.success_number
          })
        }
        num=this.commonJsExtend.getDecimalsLen(num,3)
        priceCount=this.commonJsExtend.getDecimalsLen(priceCount,3)
        sums[2]=num
        sums[4]=priceCount
        return sums
      },


      /* 关闭弹框 */
      closeDialog() {
        this.$parent.closeProDetail();
      },
    },
  }
</script>
<style>
  /* elementui */
  .el-dialog__body {
    padding: 10px 20px;
  }
</style>
<style lang="less">
  //头部标题
  .dialogtitle {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    // border: 1px solid red;
  }
//单元格显示小手样式
    .span_pointer {
      cursor: pointer;
    }

    //蓝色文字
    .span_color_blue {
      color: @theme;
    }
  /* 货品基本信息弹框 */
  .baseInfoWapper {
    height: 49.5vh;
    width: 98%;
    overflow: auto;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);

    // border: 1px solid black;
    .baseInfoTable {
      width: 100%;
      border-collapse: collapse;
      border: 1px solid #ccc;

      td {
        padding: 10px 20px;
        font-size: 12px;
        border: 1px solid #ccc;
      }

      .title {
        width: 100px;
        font-weight: bold;
        color: black;
      }
    }
    .exportTitle {
      width: 200px;
      color: black;
      font-weight: bold;
      padding: 10px 0;
      // margin:20px 10px;
      // border: 1px solid black;
    }

  }

  /* 货品外框 */
  .proBillTableWapper {
    height: calc(42vh - 45px);
    overflow: auto;
    // border: 1px solid black;

    .billing_table {
      height: 100%;
    }
  }

  //按钮样式
  .datebtn{
    padding: 10px;
    text-align: right;
    // border: 1px solid black;
  }

  .proBillPage {
    height: 40px;
    margin-top: 5px;
    line-height: 40px;
    text-align: left;
    border: 1px solid #EBEEF5;
  }

</style>
