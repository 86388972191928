<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：单据相关费用组件
	开始时间：2021-06-29
	开发人员：刘巍骏
	最后修改：2021-06-29
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="nodeFormItemBox">
    <div class="itemHead">
      <el-button size="mini" type="primary" @click="showAddExpense">新增</el-button>
    </div>

    <!-- 费用明细 -->
    <div class="billing_table" style="height: 200px;">
      <el-table :data="expenseData" height="100%" border style="width: 100%">
        <!-- 索引 -->
        <el-table-column type="index" fixed width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="showUpdateExpense(scope.row,scope.$index)" title="修改"></i>
            <i class="el-icon-delete icon" @click="deleteExpense(scope.row,scope.$index)" title="删除"></i>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="cost_type_name" label="费用分类" sortable width="180"></el-table-column>
        <el-table-column prop="amount" label="费用金额" width="100"></el-table-column>
        <!-- <el-table-column prop="export_type" label="付款账号" width="80"></el-table-column> -->
        <el-table-column prop="date" label="日期" width="120"></el-table-column>
        <el-table-column prop="file_path" label="附件" width="250">
          <template slot-scope="scope">
            <ul>
              <li v-for="item in scope.row.file_pathData">
                {{item.file_name}}
              </li>
            </ul>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" min-width="160"></el-table-column>
      </el-table>
    </div>

    <div class="billingcreate_total">
      <!-- 资金模块表格数据(报销) -->
      <ul>
        <li>
          <span class="title">总金额:</span>
          <span class="number">￥{{expenceTotalAmount}}</span>
        </li>
      </ul>
    </div>

    <!-- 弹框 -->
    <el-dialog title="费用明细" :visible.sync="show_expenseCost" width="400px" append-to-body :before-close="cancleExpence">
      <el-form :model="expenseCostObj" :rules="expenseRule" ref="expense_ruleform" label-width="100px" size="mini">
        <el-form-item label="费用分类:" prop="costTypeName">
          <el-input v-model="expenseCostObj.costTypeName" placeholder="请选择费用分类" @click.native="showCostTypeBox">
          </el-input>
        </el-form-item>
        <el-form-item label="费用金额:" prop="costAmount">
          <el-input v-model="expenseCostObj.costAmount" placeholder="请输入费用金额"></el-input>
        </el-form-item>
        <el-form-item label="发生日期:" prop="costDate">
          <el-date-picker v-model="expenseCostObj.costDate" type="date" @input="getSelData" placeholder="请选择日期">
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="账号:">
          <el-input v-model="expenseCostObj.costNum" placeholder="请选择付款账号"></el-input>
        </el-form-item> -->
        <el-form-item label="备注:" prop="costRemark">
          <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="expenseCostObj.costRemark"></el-input>
        </el-form-item>
        <el-form-item label="附件:">
          <el-upload class="upload-demo" :action="fileUrl" :headers="headers" :data="uploadData"
            :on-success="uploadFile" :file-list="fileList">
            <el-button size="mini" type="primary">附件上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传2M以内的文件</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleExpence">取 消</el-button>
        <el-button size="small" type="primary" @click="commitExpenceData">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 费用分类弹框 -->
    <costTypeTree @SelectedData="getCostTypeData"></costTypeTree>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入组件
  import costTypeTree from '@/components/commComponent/tree/tree_costType.vue'; //费用分类组件

  //导入接口
  import costApi from '@/network/cost/account.js'; //资金相关接口

  export default {
    name: 'nodeform_expense',
    props: {
      // 节点基本信息
      baseNodeInfo: {
        type: Object,
        default () {
          return {}
        }
      },
      //是否提交当前填写的表单信息
      isCommitFormData: {
        type: Boolean,
        default: true
      },
      //是否清空当前已填写表单数据
      isClearFormData: {
        type: Boolean,
        default: true
      },
      //是否触发保存单据新增的相关信息
      saveNewBillsData: {
        type: Boolean,
        default: false
      },
      // 操作类型(0:新增 1:修改  2:查看)
      operateType: {
        type: Number,
        default: -1
      },
      //数据校验步骤(根据步骤校验数据合法性给出提示信息)
      commitFormStep:{
        type: Number,
        default: -1
      },
      //回显单据数据
      originalBillData: {
        type: Object,
        default () {
          return {}
        }
      },
      //单据修改时关联信息的操作类型(0:新增 2:删除)
      operateReselveType: {
        type: Number,
        default: -1
      },
      //生成单据原id(-1:新增 其他:生成)
      generateBillsId: {
        type: Number,
        default: -1
      },
      //节点分类标志(0:销售 1:生产 2:库存  3:采购)
      billsTypeSign: {
        type: Number,
        default: -1
      },
    },
    data() {
      return {
        // 其他费用数据
        expenseData: [], //表格明细数据
        expenseRule: {}, //表单验证规则
        operateExpenxeType: 0, //判断弹框状态(0:新增  1:修改)
        expenseBillsType: [], //单据类型数据
        expenseOrigin:{},//当前操作的数据源(修改界面是使用)

        //其他费用明细数据对象
        expenseCostObj: {
          costTypeName: '', //费用分类名称
          costTypeId: '', //费用分类id
          costAmount: '', //费用金额
          costSign: '',//费用收支标识
          costNum: '', //付款账号(未用)
          costDate: '', //日期
          costFile: '', //附件
          costRemark: '', //备注
        },
        expenseCostOldObj: {}, //当前操作的旧数据对象

        //控制开关
        show_expenseCost: false, //控制显示新增其他费用弹框

        //文件上传相关
        fileUrl: '', //附件上传路径
        headers: { //请求头参数
          token: '',
        },
        uploadData: { //请求数据
          sign: '', //接口处理标识
        },
        fileList: [], //已上传文件路径
        fileData: [], //文件列表数据
      }
    },
    watch: {
      // 监听是否提交当前组件填写信息
      isCommitFormData(newVal) {
        this.commitExpenseData();
        // if(this.commitFormStep == 5){
        //   this.commitExpenseData();
        // }
      },
      //监听是否清空当前子组件数据
      isClearFormData() {
        //调用清除信息方法
        this.clearNodeFormMsg();
      }
    },
    computed: {
      ...mapState({
        SysCostBankInfo: state => state.system.SysCostBankInfo, //收款账号数据
        billTemplateData: state => state.system.billTemplateData, //所有单据类型数据
        bills_base_data: state => state.commComponent.bills_base_data, //表单填写的基本信息数据
      }),
      // 费用总金额
      expenceTotalAmount() {
        return this.getTotalAmount();
      }
    },
    created() {
      //初始化附件上传数据
      this.initFileData();
      //初始化表单验证规则
      this.initFormRule();
      //初始化费用分类数据
      this.getDefaultExpenseData();
    },
    methods: {
      ...mapMutations([
        'SHOW_COSTTYPEBOX', //控制费用分类选择弹框是否显示
        'BILLS_EXPENSE_DATA',//存储其它费用数据
      ]),
      ...mapActions([
        'getAllPaymentType', //收款类型
        'getSysCostBankInfo', //收款账号
      ]),

      /* 获取组件默认回显数据(修改,预览时用) */
      getDefaultExpenseData() {
        //判断当前组件操作类型
        if (this.operateType == 0) { //新增界面
          return
        }
        //判断是否有数据
        if (this.originalBillData) {
          //循环前清空数据
          this.expenseData = [];
          //获取表格数据
          let expenseData = this.originalBillData.expenseSubitem;
          //处理驼峰数据
          expenseData.forEach((item, index) => {
            let itemObj = {};
            for (let i in item) {
              let itemLine = this.commonJsExtend.toLine(i);
              itemObj[itemLine] = item[i];
            }
            this.expenseData.push(itemObj);
          })
        }
      },

      /* 初始化上传附件信息*/
      initFileData() {
        //设置请求路径
        this.fileUrl = baseUrl + '/orderCommon/uploadAccessory';
        //设置请求头
        this.headers.token = getCookie('token');
        //设置请求内容
        this.uploadData.sign = 6; //6标识费用报销单
      },

      /* 显示新增费用弹框(不使用vue初始化的原因是方便赋默认值) */
      showAddExpense() {
        //初始化数据
        this.expenseCostObj = {
          costTypeName: '', //费用分类名称
          costTypeId: '', //费用分类id
          costAmount: '', //费用金额
          costSign: '',//费用收支标识
          costNum: '', //付款账号(未用)
          costDate: this.commonJsExtend.getDateTime(new Date(), 0), //日期
          costFile: '', //附件
          costRemark: '', //备注
        }
        //初始化附件数据
        this.fileData = [];
        //设置弹框分类为新增
        this.operateExpenxeType = 0;
        //显示弹框
        this.show_expenseCost = true;
      },

      // 显示修改弹框
      showUpdateExpense(data, index) {
        //存储一份数据源
        this.expenseOrigin = data;
        //初始化数据
        this.expenseCostObj = {
          costIndex: index, //修改的数据下标
          costTypeName: data.cost_type_name, //费用分类名称
          costTypeId: data.cost_type_id, //费用分类id
          costAmount: data.amount, //费用金额
          costSign: data.web_amount_sign,//费用收支标识
          costNum: '', //付款账号(未用)
          costDate: data.date, //日期
          costFile: data.file_path, //附件
          costRemark: data.remark, //备注
        }
        // 判断当前界面状态
        if(this.operateType == 1){//修改界面
          this.expenseCostObj.id = data.id;
        }
        //设置弹框分类为修改
        this.operateExpenxeType = 1;
        //显示弹框
        this.show_expenseCost = true;
        //触发存储旧数据方法
        this.getCurOperateData(this.expenseCostObj);
      },

      /* 删除其他费用数据 */
      deleteExpense(data, index) {
        //判断当前操作界面
        if (this.operateType == 0) { //新增界面
          this.expenseData.splice()
        } else if (this.operateType == 1) { //修改界面
          //触发旧数据存储方法
          this.getCurOperateData(data);
          //触发处理方法
          this.getChangeData(2);
        }
      },

      /* 处理数据修改后的业务 type(0:新增 1:修改 2:删除)  data:修改时的新对象数据*/
      getChangeData(type,data) {
        //定义一个日志参数
        let updateProductData = []; //定义需要修改的信息
        let logContentData = []; //定义一个日志内容字段
        let costBillsExpenseSubitem = [];
        // 判断操作类型
        if (type == 0) { //新增
          //获取日志信息
          let logContent = '';
          logContent = "新增了费用分类为【"+this.expenseCostObj.cost_type_name+"】的费用信息;" //日志内容
          logContentData.push(logContent);
          //获取添加的数据信息
          costBillsExpenseSubitem.push(this.expenseCostObj);
        } else if (type == 1) { //修改
          let logContent = '';
          //获取日志信息
          logContent = '';
          logContentData.push(logContent);
          //获取需要处理的数据
          costBillsExpenseSubitem.push(data);
        } else if (type == 2) { //删除
          let logContent = '';
          // 获取日志信息
          logContent = "删除了费用分类为【"+this.expenseCostOldObj.cost_type_name+"】的费用信息;" //日志内容
          logContentData.push(logContent);
          //获取需要处理的数据信息
          let deleteData = {
            id:this.expenseCostObj.id
          }
          costBillsExpenseSubitem.push(deleteData);
        }
        console.log(this.expenseOrigin);
        let updateData = {
          billsId:this.expenseOrigin.expense_id,//单据主表id
          costBillsExpenseSubitem:JSON.stringify(costBillsExpenseSubitem),//报销单明细信息实体
          logContent:JSON.stringify(logContentData),//日志内容
          statusId:46,//	节点id
          type:type,//修改类型 0.添加 1.修改 2.删除
        }
        costApi.updateBillsExpenseSubItem(updateData).then(res => {
          if (res.code == 200) {
            console.log("其他费用修改成功!");
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取选择的日期,处理国际标准日期 */
      getSelData() {
        this.expenseCostObj.costDate = this.commonJsExtend.getDateTime(this.expenseCostObj.costDate, 0);
      },

      /* 附件上传后的回调 */
      uploadFile(response, file, fileList) {
        if (response.code == 200) {
          this.$message({
            type: 'success',
            message: '附件上传成功',
            duration: this.elDuration
          })
          let data = {
            file_name: file.name, //附件名称
            file_path: response.data //附件路径
          }
          //将数据存入data
          this.fileData.push(data)
        } else {
          this.$message({
            type: 'error',
            message: res.message,
            duration: this.elDuration
          })
        }
      },

      /* 获取当前操作的数据(修改时使用的旧数据) */
      getCurOperateData(data) {
        //存一份旧数据
        this.expenseCostOldObj = JSON.parse(JSON.stringify(data))
      },

      /* 获取费用总金额数据 */
      getTotalAmount() {
        let result = 0;
        // 判断是否有添加数据
        this.expenseData.forEach((item, index) => {
          result = result + parseFloat(item.amount);
        })
        //保留三位小数
        result = this.commonJsExtend.getDecimalsLen(result, 3);
        // 判断异常时显示0
        if (!result) {
          result = 0;
        }
        return result;
      },

      /* 提交当前填写的其他费用数据 */
      commitExpenceData() {
        //验证数据是否合法
        this.$refs['expense_ruleform'].validate(valid => {
          if (valid) {
            let filePathArr = []; //附件字符串数组
            this.fileData.forEach((item, index) => {
              //获取附件字符串
              let filePath = item.file_name + '@@@' + item.file_path;
              filePathArr.push(filePath);
            })
            //定义参数
            let expenseObj = {
              id:this.expenseCostObj.id,//需要处理的数据id(修改界面才有)
              date: this.expenseCostObj.costDate, //发生日期
              amount: this.expenseCostObj.costAmount, //费用金额
              web_amount_sign: this.expenseCostObj.costSign,//费用收支标识
              cost_type_id: this.expenseCostObj.costTypeId, //费用分类id
              cost_type_name: this.expenseCostObj.costTypeName, //费用分类名称
              file_path: filePathArr.join('###'), //附件信息字符串
              file_pathData: this.fileData, //附件对象
              remark: this.expenseCostObj.costRemark, //备注
            }

            //判断当前操作类型
            if (this.operateExpenxeType == 0) { //新增弹框
              //判断当前操作界面
              if (this.operateType == 0) { //新增界面
                //将数据存入表格
                this.expenseData.push(expenseObj);
              } else if (this.operateType == 1) { //修改界面
                //调用修改数据接口
                this.getChangeData(0);
              }
            } else if (this.operateExpenxeType == 1) { //修改弹框
              //判断当前操作界面
              if (this.operateType == 0) { //新增界面
                //将数据存入表格
                this.expenseData.splice(this.expenseCostObj.costIndex, 1, expenseObj);
              } else if (this.operateType == 1) { //修改界面
                //调用修改数据接口
                this.getChangeData(1,expenseObj);
              }
            }

            //将数据存到vuex
            this.BILLS_EXPENSE_DATA(this.expenseData);

            //关闭弹框
            this.show_expenseCost = false;
          }
        })
      },

      /* 取消添加其他费用数据 */
      cancleExpence() {
        //移除表单验证规则
        this.$refs['expense_ruleform'].resetFields();
        //关闭弹框
        this.show_expenseCost = false;
      },

      /* 显示费用分类弹框 */
      showCostTypeBox() {
        //显示费用分类弹框
        this.SHOW_COSTTYPEBOX(true);
      },

      /* 获取费用分类弹框数据 */
      getCostTypeData(data) {
        //判断选择的分类数据是否合法
        if (data.length > 1) {
          this.$message({
            type: 'warning',
            message: '一次只能选择一个费用分类!',
            duration: this.elDuration
          })
          return
        }
        //获取费用分类数据
        this.expenseCostObj.costTypeName = data[0].name; //分类名称
        this.expenseCostObj.costTypeId = data[0].id; //分类id
        this.expenseCostObj.costSign = data[0].outin_sign;//收支标识(0:收  1:支)
      },

      /* 初始化表单验证规则 */
      initFormRule() {
        this.expenseRule = {
          costTypeName: [ //费用分类验证规则
            {
              required: true, //是否必填
              message: '请选择费用分类',
              trigger: 'change'
            }
          ],
          costAmount: [ //费用金额验证规则
            {
              required: true, //是否必填
              message: '请输入费用金额',
              trigger: 'change'
            }
          ]
        };
      },

      /* 清除当前组件信息 */
      clearNodeFormMsg() {
        // 循环清空对象
      },

      /* 提交费用信息到父组件 */
      commitExpenseData() {
        //验证数据合法性

        //获取报销单主表信息
        let expenseBaseData = {
          cost_classify: 2, //报销单据分类
          bills_date: this.commonJsExtend.getDateTime(new Date(), 0), //单据日期
          status_id: 80, //节点id
          process_id: 273, //流程id
          billing_type_id: 119, //单据类型id
        }

        //判断基本信息有无客户
        if (this.bills_base_data.hasOwnProperty('customer_id')) {
          expenseBaseData.customer_id = this.bills_base_data.customer_id;
        }
        //判断基本信息有无部门
        if (this.bills_base_data.hasOwnProperty('dept_id')) {
          expenseBaseData.dept_id = this.bills_base_data.dept_id;
        }
        //判断基本信息有无指定业务员
        if (this.bills_base_data.hasOwnProperty('user_id')) {
          expenseBaseData.user_id = this.bills_base_data.user_id;
        }

        //定义传入父组件的数据
        let data = {
          expenseBaseData: expenseBaseData, //报销单主表信息
          tableData: this.expenseData, //明细
          totalAmount: this.expenceTotalAmount, //总金额
        }

        //发送数据到父组件
        this.$emit("getNodeFormData", data)
      },
    },
    filters: {
      // 获取附件名称
      getFilePathName() {},
    },
    components: {
      costTypeTree,
    },
  }
</script>

<style lang="less" scoped="scoped">
  // .itemHead{
  //   margin-bottom: 10px;
  //   border: 1px solid black;
  // }
</style>
