<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：单据备注信息组件
	开始时间：2021-04-27
	开发人员：刘巍骏
	最后修改：2021-04-27
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="nodeFormItemBox">
    <div class="remarkBox">
      <!-- <span>单据备注:</span> -->
      <el-col :span="24">
        <el-input type="textarea" :rows="3" :span="12" @focus="getCurOperateData" @change="getChangeData"
          placeholder="请输入内容" v-model="nodeRemark"  :autosize="{ minRows: 3, maxRows: 20}"></el-input>
      </el-col>
    </div>
  </div>
</template>

<script>
  // 导入api
  import sellApi from '@/network/sell/bills.js'; //销售模块(包含公共接口)

  export default {
    name: 'nodeForm_remark',
    props: {
      //是否提交当前填写的表单信息
      isCommitFormData: {
        type: Boolean,
        default: true
      },
      //是否清空当前已填写表单数据
      isClearFormData: {
        type: Boolean,
        default: true
      },
      // 操作类型(0:新增 1:修改  2:查看)
      operateType: {
        type: Number,
        default: -1
      },
      //数据校验步骤(根据步骤校验数据合法性给出提示信息)
      commitFormStep:{
        type: Number,
        default: -1
      },
      //回显单据原id(-1:新增 其他:生成)
      generateBillsId: {
        type: Number,
        default: -1
      },
      //节点分类标志(0:销售 1:生产 2:库存  3:采购)
      billsTypeSign: {
        type: Number,
        default: -1
      },
      //回显单据数据
      originalBillData: {
        type: Object,
        default () {
          return {}
        }
      }
    },
    data() {
      return {
        //当前组件数据
        nodeRemark: '', //节点备注信息
        is_edit:true,//控制备注信息是否可编辑

        //修改时用
        nodeOldRemark: '', //节点旧备注信息
        nodeOldRemarkId: '', //节点旧备注信息id
      }
    },
    watch: {
      //监听是否提交当前组件所填信息
      isCommitFormData(newVal) {
        //调用提交信息方法
        this.commitNodeFormMsg();
        // if(this.commitFormStep == 7){
        //   //调用提交信息方法
        //   this.commitNodeFormMsg();
        // }
      },
      //监听是否清空当前子组件数据
      isClearFormData() {
        //调用清除信息方法
        this.clearNodeFormMsg();
      },
      // 监听回显单据数据
      originalBillData(newVal) {
        this.getDefaultRemarkData(newVal);
      },
    },
    methods: {
      /* 清除当前组件信息 */
      clearNodeFormMsg() {
        // 循环清空对象
        this.nodeRemark = "";
      },

      /* 获取默认备注信息(回显) */
      getDefaultRemarkData(data) {
        //预览时不可编辑
        if(this.operateType == 2){
          this.is_edit = false;
        }
        //获取备注信息数据
        if(data.remark||data.relationRemark){
          if(this.billsTypeSign==1){
            this.nodeRemark = data.relationRemark.content;//内容
            this.nodeOldRemarkId = data.relationRemark.id;//id
          }else{
            this.nodeRemark = data.remark.content;//内容
            this.nodeOldRemarkId = data.remark.id;//id
          }

        }
      },

      /* 存一份旧数据 */
      getCurOperateData() {
        this.nodeOldRemark = JSON.parse(JSON.stringify(this.nodeRemark));
      },

      /* 修改数据处理 */
      getChangeData() {
        //判断是否为修改界面(其他界面不执行此方法)
        if (this.operateType == 0) {
          return
        }

        // 定义日志信息
        let logContent = "更改了【备注信息】,更改前:" + this.nodeOldRemark + "更改后:" + this.nodeRemark + ";"

        //定义后端接受参数
        let updataData = {
          logContent: logContent, //日志信息
          id: this.nodeOldRemarkId, //id
          content: this.nodeRemark, //备注内容
        }

        //发送请求
        sellApi.updateOrderRemark(updataData).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '备注修改成功!',
              duration: this.elDuration
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 提交当前组件信息*/
      commitNodeFormMsg() {
        let data = {
          content: this.nodeRemark, //备注信息
        }
        this.$emit("getNodeFormData", data);
      },
    }
  }
</script>

<style lang="less">
  .remarkBox {
    padding: 5px 0;
    display: flex;

    span {
      width: 80px;
    }
  }
</style>
