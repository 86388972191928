<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：生产工艺报价明细组件
	开始时间：2021-04-20
	开发人员：刘巍骏
	最后修改：2021-04-20
	备注说明：外部接受方式使用getNodeTableData
-->
<template>
  <div class="craftOfferBox">
    <!-- 有数据时显示 -->
    <div v-if="productTabs.length>0">
      <el-tabs v-model="productTabsValue" type="border-card">
        <el-tab-pane v-for="(item, index) in productTabs" :key="item.productInfo.id"
          :label="item.productInfo.product_code" :name="item.productInfo.product_code">
          <!-- 原材料费用清单 -->
          <div class="mesMaterialListBox">
            <!-- 头部标题 -->
            <div class="billingcreate_formType">
              <h5>原材料费用清单</h5>
              <!-- 控制不显示原材料费用清单 -->
              <el-checkbox class="titleCheckBox" v-model="showMaterialList"
                @change="getMaterialTotalAmount(item,item.noTreeBomList,bills_product_data,1)">不显示原材料费用清单</el-checkbox>
              <!-- 控制是否显示物料加工费用 -->
              <el-checkbox class="titleCheckBox" v-model="showMaterialProcess" v-if="!showMaterialList"
                @change="getMaterialTotalAmount(item,item.noTreeBomList,bills_product_data,1)">显示物料加工费用</el-checkbox>
            </div>
            <!-- 表格 -->
            <div class="materialList billing_table" v-if="!showMaterialList">
              <el-table :data="item.noTreeBomList" border height="100%" show-summary style="width: 100%">
                <!-- 数据内容 -->
                <el-table-column label="物料信息">
                  <el-table-column prop="product_info_code" label="物料编码" width="180" show-overflow-tooltip>
                  </el-table-column>
                  <el-table-column prop="product_info_name" label="物料名称" width="150" show-overflow-tooltip>
                  </el-table-column>
                  <el-table-column prop="specifications" label="规格型号" width="180" show-overflow-tooltip>
                  </el-table-column>
                  <el-table-column prop="calculate_name" label="计量单位" width="80" show-overflow-tooltip>
                  </el-table-column>
                  <el-table-column prop="need_num" label="单个数量" width="80" show-overflow-tooltip>
                  </el-table-column>
                  <el-table-column prop="consume_rate" label="报损率(‰)" width="80" show-overflow-tooltip>
                  </el-table-column>
                  <el-table-column prop="need_total_num" label="所需总量" width="80" show-overflow-tooltip>
                  </el-table-column>
                  <el-table-column prop="price" label="销售单价" width="80" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <el-input size="mini" v-model="scope.row.price"
                        @change="getMaterialTotalAmount(item,item.noTreeBomList,bills_product_data,1)"
                        placeholder="请输入内容">
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="material_total_amount" label="物料总价" width="80" show-overflow-tooltip>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="加工费用" v-if="showMaterialProcess">
                  <el-table-column prop="process_unit_name" label="加工单位" width="100" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <el-select class="inputSelection" v-model="scope.row.process_unit_name" placeholder="请选择"
                        @change="" @focus="" size="mini">
                        <el-option v-for="item in ProcessUnit" :key="item.id" :label="item.name" :value="item.name">
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column prop="valuation_num" label="计价数" width="100" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <el-input size="mini" v-model="scope.row.valuation_num"
                        @change="getMaterialTotalAmount(item,item.noTreeBomList,bills_product_data,1)"
                        placeholder="请输入内容">
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="machine_price" label="加工费单价" width="100" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <el-input size="mini" v-model="scope.row.machine_price"
                        @change="getMaterialTotalAmount(item,item.noTreeBomList,bills_product_data,1)"
                        placeholder="请输入内容">
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="tax_rate" label="税率" width="100" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <el-input size="mini" v-model="scope.row.tax_rate"
                        @change="getMaterialTotalAmount(item,item.noTreeBomList,bills_product_data,1)"
                        placeholder="请输入内容">
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="valuation_total_num" label="总价" width="100" show-overflow-tooltip>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="合计区">
                  <el-table-column prop="total_amount" label="合计总价" width="100" show-overflow-tooltip>
                  </el-table-column>
                  <el-table-column prop="" label="备注" min-width="100" show-overflow-tooltip>
                  </el-table-column>
                </el-table-column>
              </el-table>
            </div>
          </div>

          <!-- 生产费用清单 -->
          <div class="mesProcessListBox">
            <!-- 头部标题 -->
            <div class="billingcreate_formType">
              <h5>生产费用清单</h5>
              <!-- 控制不显示生产费用清单 -->
              <el-checkbox class="titleCheckBox" v-model="showProcessList">不显示生产费用清单</el-checkbox>
            </div>
            <!-- 表格 -->
            <div class="processList billing_table" v-if="!showProcessList">
              <el-table :data="item.mesMainProcessList" border show-summary height="100%" style="width: 100%">
                <!-- 数据内容 -->
                <el-table-column prop="encoding" label="工序编号" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="name" label="工序名称" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="manufactureTypeName" label="生产类型" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="cost_type_name" label="计费方式" width="120" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-select class="inputSelection" v-model="scope.row.cost_type_name" placeholder="请选择" @change=""
                      @focus="" size="mini">
                      <el-option v-for="item in CostType" :key="item.id" :label="item.name" :value="item.name">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="hours" label="预计用时" width="120" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-input size="mini" v-model="scope.row.hours" placeholder="请输入内容">
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="labour_cost" label="单价" width="80" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-input size="mini"
                      @change="getPocessTotalAmount(item,item.mesMainProcessList,bills_product_data)"
                      v-model="scope.row.labour_cost" placeholder="请输入内容">
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="tax_rage" label="税点" width="80" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-input size="mini" v-model="scope.row.tax_rage" placeholder="请输入内容">
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="total_labour_cost" label="总工价" width="100" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="min_work_amount" label="最低开工费" width="100" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-input size="mini" v-model="scope.row.min_work_amount" placeholder="请输入内容">
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="min_work_number" label="开工费配额" width="100" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-input size="mini" v-model="scope.row.min_work_number" placeholder="请输入内容">
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注说明" min-width="100" show-overflow-tooltip>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 无数据时显示 -->
    <div class="noDataBox" v-else>
      暂无数据
    </div>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入方法
  import productApi from '@/network/production/production.js'; //生产模块

  export default {
    name: 'nodeCraftOfferTableData',
    props: {
      //是否提交当前填写的表单信息
      isCommitFormData: {
        type: Boolean,
        default: true
      },
      // 操作类型(0:新增 1:修改  2:查看)
      operateType: {
        type: Number,
        default: -1
      },
      //是否清空当前已填写表单数据
      isClearFormData: {
        type: Boolean,
        default: true
      },
      //回显单据数据(具体数据)
      originalBillData: {
        type: Object,
        default () {
          return {}
        }
      },
    },
    data() {
      return {
        //组件数据
        productTabsValue: '', //tab页面绑定的数据
        productTabs: [], //成品tab页数据
        materialListAmount: 0, //物料总金额
        processListAmount: 0, //工序总金额

        //控制开关
        showMaterialList: false, //控制是否显示物料原材料清单
        showMaterialProcess: false, //控制是否显示物料加工费用
        showProcessList: false, //控制是否显示生产费用清单

        //loading框
        loading: '', //loading框
      }
    },
    computed: {
      ...mapState({
        // 表单相关
        bills_product_data: state => state.commComponent.bills_product_data, //成品表格选择的货品数据
        bills_product_type: state => state.commComponent.bills_product_type, //成品表格选择的货品操作方式
        //数据字典相关
        CostType: state => state.system.CostType, //计费方式
        ProcessUnit: state => state.system.ProcessUnit, //中的加工单位数据
      }),
    },
    mounted() {
      //初始化界面所需数据
      this.initVuexData();
      //获取回显的数据(修改/预览)
      this.getOriginViewData();
    },
    watch: {
      // 监听货品信息选择的货品
      bills_product_data(newVal) {
        //调用获取bom信息数据方法
        this.getProDefaultBomData(newVal);
      },
      //监听父组件是否需要提交当前信息
      isCommitFormData(newVal) {
        //验证节点信息规范性
        this.commitCraftTableMsg();
      },
      //监听是否清空当前子组件数据
      isClearFormData() {
        //调用清除信息方法
        this.clearNodeFormMsg();
      },
    },
    methods: {
      ...mapMutations([
        'BILLS_MES_OFFER_DATA', //将生产工艺报价明细信息存入vuex
      ]),
      ...mapActions([
        'getAllMesProcessUnit', //生产加工单位
        'getAllMesCostType', //计费方式
      ]),
      /* 初始化vuex数据 */
      initVuexData() {
        //加工单位数据
        if (this.ProcessUnit.length == 0) {
          this.getAllMesProcessUnit();
        }
        //计费方式数据
        if (this.CostType.length == 0) {
          this.getAllMesCostType();
        }
      },

      /* 获取货品默认版本bom信息数据 */
      getProDefaultBomData(prodata) {
        //判断选择的货品是否有值
        if (!prodata) {
          return
        }
        console.log(this.bills_product_type);
        //判断操作方式
        if (this.bills_product_type == 0) { //新增
          //获取货品id数组
          let proIdArr = [];
          prodata.forEach((item, index) => {
            proIdArr.push(item.product_id);
          })
          //定义后端请求数据
          let data = {
            product_ids: proIdArr.join(","), //货品ids
          }
          // 发送请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '货品工艺信息获取中,请稍候...');
          productApi.findDefaultBomVersionByProductIds(data).then(res => {
            if (res.code == 200) {
              //获取bom列表数据
              this.productTabs = res.data;
              //循环处理列表显示数据
              this.productTabs.forEach((item, index) => {
                // ***************bom非树形结构数据***************
                let noTreeBomList = this.commonJsExtend.toNoTree(item.mesMainBomList, []);
                //处理总计数据
                this.getMaterialTotalAmount(item, noTreeBomList, prodata, 0);
                //获取处理好的数据
                item.noTreeBomList = noTreeBomList;

                //***************工序数据***************
                this.getPocessTotalAmount(item, item.mesMainProcessList, prodata);
                // item.mesMainProcessList.forEach((itemJ, indexJ) => {
                //   prodata.forEach((itemK, indexK) => {
                //     if (item.productInfo.id == itemK.product_id) {
                //       //成品id
                //       this.$set(itemJ, "sale_product_id", itemK.product_id);
                //     }
                //   })
                // })
              })
              //默认选中第一个货品信息
              if (this.productTabs.length > 0) {
                this.productTabsValue = this.productTabs[0].productInfo.product_code;
              }
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
            //关闭loading框
            this.loading.close();
          })
        } else if (this.bills_product_type == 1) { //修改
          //循环处理列表显示数据
          this.productTabs.forEach((item, index) => {
            //处理物料总计数据
            this.getMaterialTotalAmount(item, item.noTreeBomList, this.bills_product_data, 1);
            //处理工序总计数据
            this.getPocessTotalAmount(item, item.mesMainProcessList, this.bills_product_data);
          })
        } else if (this.bills_product_type == 2) { //删除
          //获取货品id数组
          let proIdArr = [];
          prodata.forEach((item, index) => {
            proIdArr.push(item.product_id);
          })
          //判断不在成品id中的数据删除掉
          let indexNum = -1;
          this.productTabs.forEach((item, index) => {
            //根据货品id判断
            if (!proIdArr.includes(item.productInfo)) {
              indexNum = index
            }
          })
          this.productTabs.splice(indexNum, 1);
        }
      },

      /* 获取物料总金额信息 type(0:初次设置  1:界面修改设置)*/
      getMaterialTotalAmount(item, noTreeBomList, prodata, type) {
        //获取控制方式
        item.showMaterialList = this.showMaterialList;
        item.showMaterialProcess = this.showMaterialProcess;
        //清空总计信息先
        this.materialListAmount = 0;
        //处理总计数据
        noTreeBomList.forEach((itemJ, indexJ) => {
          prodata.forEach((itemK, indexK) => {
            if (item.productInfo.id == itemK.product_id) {
              //成品id
              this.$set(itemJ, "sale_product_id", itemK.product_id);
              //物料所需总量
              let need_total_num = (itemJ.need_num + Math.ceil((itemJ.need_num * itemJ.consume_rate) / 1000)) *
                itemK.number;
              this.$set(itemJ, "need_total_num", need_total_num);
              //销售单价
              if (type == 0) { //初次时才设值
                itemJ.productInfoPrice.forEach((itemZ, indexZ) => {
                  if (itemZ.is_base == 1) { //基本计量单位的价格
                    this.$set(itemJ, "price", itemZ.price);
                  }
                })
              }
              //物料总价
              let materialTotalAmount = itemJ.price * itemJ.need_total_num;
              this.$set(itemJ, "material_total_amount", materialTotalAmount);
              //加工计价数总数
              let processTotalNum = itemK.number * itemJ.valuation_num;
              this.$set(itemJ, "web_process_total_num", processTotalNum);
              //税率
              this.$set(itemJ, "tax_rate", 0);
              //加工费总价
              let processTotalAmount = itemJ.web_process_total_num * itemJ.machine_price;
              this.$set(itemJ, "valuation_total_num", processTotalAmount);
              //合计总价
              let totalAmount = 0;
              //判断是否选择显示物料加工费用
              if (this.showMaterialProcess) { //是
                totalAmount = itemJ.material_total_amount + itemJ.valuation_total_num;
              } else { //否
                totalAmount = itemJ.material_total_amount;
              }
              this.$set(itemJ, "total_amount", totalAmount);

              //获取总计信息
              this.materialListAmount = this.materialListAmount + totalAmount;
            }
          })
        })

        //将数据存储到Vuex
        this.BILLS_MES_OFFER_DATA(this.productTabs);
      },

      /* 获取工序总金额数据 */
      getPocessTotalAmount(item, data, prodata) {
        //获取控制方式
        item.showProcessList = this.showProcessList;
        //清空先
        this.processListAmount = 0;
        //处理总计数据
        data.forEach((itemJ, indexJ) => {
          prodata.forEach((itemK, indexK) => {
            if (item.productInfo.id == itemK.product_id) {
              //成品id
              this.$set(itemJ, "sale_product_id", itemK.product_id);
              //工序总价
              itemJ.total_labour_cost = itemJ.labour_cost * itemK.number;
            }
          })
        })
        
        //将数据存储到Vuex
        this.BILLS_MES_OFFER_DATA(this.productTabs);
      },

      /* 获取回显的数据(修改/预览) */
      getOriginViewData() {
        //判断当前状态
        if (this.operateType == 1 || this.operateType == 2) { //修改或预览
          console.log(this.originalBillData);
          //清空先
          this.productTabs = [];
          //获取回显的数据
          if (this.originalBillData.productInfos) {
            this.originalBillData.productInfos.forEach((item, index) => {
              //处理驼峰
              for (let i in item) {
                let curToLine = this.commonJsExtend.toLine(i);
                item[curToLine] = item[i];
              }
              //定义一个对象
              let itemObj = {
                productInfo: item, //货品数据
              };

              //循环获取物料明细
              let itemJArr = [];
              this.originalBillData.saleMaterialList.forEach((itemJ, indexJ) => {
                //根据销售货品明细id匹配数据
                if (item.id == itemJ.saleProductId) {
                  itemJArr.push(itemJ);
                }
                //处理驼峰
                itemJArr.forEach((itemZ, indexZ) => {
                  for (let i in itemZ) {
                    let curToLine = this.commonJsExtend.toLine(i);
                    itemZ[curToLine] = itemZ[i];
                  }
                })
              })
              //获取处理好的物料数据
              itemObj.noTreeBomList = itemJArr;

              //循环获取物料工序
              let itemKArr = [];
              this.originalBillData.saleProcessList.forEach((itemK, indexK) => {
                //根据销售货品明细id匹配数据
                if (item.id == itemK.saleProductId) {
                  itemKArr.push(itemK);
                }
                //处理驼峰
                itemKArr.forEach((itemZ, indexZ) => {
                  for (let i in itemZ) {
                    let curToLine = this.commonJsExtend.toLine(i);
                    itemZ[curToLine] = itemZ[i];
                  }
                })
              })
              //获取处理好的工序数据
              itemObj.mesMainProcessList = itemKArr;

              //将处理好的数据存入
              this.productTabs.push(itemObj);
              //默认选中第一个货品信息
              if (this.productTabs.length > 0) {
                this.productTabsValue = this.productTabs[0].productInfo.product_code;
              }
            })
          }
        }
      },

      /* 清空组件数据 */
      clearNodeFormMsg(){
        // 清空表格数据
        this.productTabs = [];
        //将数据存储到Vuex
        this.BILLS_MES_OFFER_DATA(this.productTabs);
      },

      /* 提交当前组件 */
      commitCraftTableMsg() {
        let saleMaterial = []; //物料明细数组
        let saleProcess = []; //工序明细数组
        //定义需要传入到父组件的数据
        this.productTabs.forEach((item, index) => {
          //获取物料明细信息
          item.noTreeBomList.forEach((itemJ, indexJ) => {
            saleMaterial.push(itemJ);
          })
          //获取工序明细信息
          item.mesMainProcessList.forEach((itemK, indexK) => {
            saleProcess.push(itemK);
          })
        })
        let data = {
          saleMaterial, //物料明细
          saleProcess, //工序明细
        }
        console.log(data);
        //将当前组件信息提交到父组件
        this.$emit("getCraftTableMsg", data);
      },
    },
  }
</script>

<style lang="less">
  // 生产工艺报价明细
  .craftOfferBox {

    // 原材料费用清单总框
    .mesMaterialListBox {

      //标题样式
      .billingcreate_formType {
        display: flex;

        //复选框
        .titleCheckBox {
          font-size: 12px;
          padding-left: 20px;
        }
      }

      // 物料表格
      .materialList {
        height: 30vh;
      }
    }

    // 生产费用清单
    .mesProcessListBox {

      //标题样式
      .billingcreate_formType {
        display: flex;

        //复选框
        .titleCheckBox {
          font-size: 12px;
          padding-left: 20px;
        }
      }

      // 工序表格
      .processList {
        height: 30vh;
      }
    }

    /* 暂无数据框 */
    .noDataBox {
      height: 100px;
      line-height: 100px;
      text-align: center;
    }
  }
</style>
