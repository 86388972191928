<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：退款明细信息组件
	开始时间：2021-04-27
	开发人员：刘巍骏
	最后修改：2021-04-27
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="nodeFormItemBox returnCostBox">
    <div class="returnCost_table billing_table">
      <el-table :data="returnCostBillData" row-key="id" height="100%" border show-summary style="width: 100%">
        <!-- 数据内容 -->
        <el-table-column prop="" label="单据号" sortable width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="" label="源单号" width="180"></el-table-column>
        <el-table-column prop="" label="退款日期" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="" label="源单日期" width="80" show-overflow-tooltip></el-table-column>
        <el-table-column prop="" label="退款金额" width="80" show-overflow-tooltip></el-table-column>
        <el-table-column prop="" label="退款账号" width="80" show-overflow-tooltip></el-table-column>
        <el-table-column prop="" label="备注" min-width="150" show-overflow-tooltip></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  export default{
    name:'nodeFormReturnCost',
    data(){
      return{
        //组件数据
        returnCostBillData:[],//退款单明细列表
      }
    },
    mounted() {

    },
    watch:{},
    methods:{

    }
  }
</script>

<style lang="less">
  // 明细总框
  .returnCostBox{
    // 明细内容
    .returnCost_table{
      height: 300px;
    }
  }
</style>
