<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：单据总金额信息组件
	开始时间：2022-03-29
	开发人员：刘巍骏
	最后修改：2022-03-29
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="totalAmountBox">
    <!-- 单据总金额 -->
    <div class="totalAmount">
      <span class="title">单据总金额:</span>
      <span class="value">￥{{totalAmount}}</span>
    </div>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  export default{
    name:'nodeFormTotalAmount',
    props:{
      //节点分类标志(0:销售 1:生产 2:库存  3:采购  4:bom  5.收付款  6:资金 7:生产工序单 8:发票)
      billsTypeSign: {
        type: Number,
        default: -1
      },
      //单据分类(根据nodeTypeCode区分)
      billsClassify: {
        type: Number,
        default: 0
      },
      //是否清空当前已填写表单数据
      isClearFormData: {
        type: Boolean,
        default: true
      },
    },
    data(){
      return{
        totalAmount:0,//总金额数据
      }
    },
    computed: {
      ...mapState({
        bills_base_data: state => state.commComponent.bills_base_data, //表单填写的基本信息数据
        bills_product_data: state => state.commComponent.bills_product_data, //成品表格选择的货品数据
        bills_expense_data: state => state.commComponent.bills_expense_data, //其它费用表格添加的费用数据
        bills_mes_offer_data: state => state.commComponent.bills_mes_offer_data, //成品工艺表格明细数据
      }),
    },
    watch:{
      // 监听基本信息数据发生变化
      bills_base_data:{
        handler(newVal){
          this.getTotalAmount();
        },
        deep:true,
      },
      //监听单据选择的成品发生变化
      bills_product_data:{
        handler(newVal){
          this.getTotalAmount();
        },
        deep:true
      },
      //监听其它费用信息发生变化
      bills_expense_data:{
        handler(newVal){
          this.getTotalAmount();
        },
        deep:true
      },
      //监听生产工艺明细发生变化
      bills_mes_offer_data:{
        handler(newVal){
          this.getTotalAmount();
        },
        deep:true
      },
      //监听是否清空当前子组件数据
      isClearFormData() {
        //调用清除信息方法
        this.clearNodeFormMsg();
      },
    },
    methods:{
      /* 获取总金额信息 */
      getTotalAmount(){
        //清空总金额先
        this.totalAmount = 0;
        //判断具体模块
        if(this.billsTypeSign == 0){//销售
          //判断具体分类
          if(this.billsClassify == 0){//报价
            //获取报价方式
            let offer_way_id = 0;//默认成品报价
            if(this.bills_base_data.hasOwnProperty("offer_way_id")){
              offer_way_id = this.bills_base_data.offer_way_id;
            }
            //判断是成品报价还是工艺报价
            if(offer_way_id == 0){//成品报价
              //判断是否阶梯报价
              if(this.bills_base_data.hasOwnProperty("is_ladder_offer") && this.bills_base_data.is_ladder_offer == 1){//是
                this.totalAmount = "区间价格+其它费用";
                return
              }
            }

            //获取成品总价
            let productTotalAmount = this.getProTotalAmount();

            //获取其它费用总金额
            let expenseAmount = 0;
            if(!!this.bills_expense_data){
              this.bills_expense_data.forEach((item,index)=>{
                //判断收支标识
                if(item.web_amount_sign == 0){
                  expenseAmount = expenseAmount + parseInt(item.amount);
                }else{
                  expenseAmount = expenseAmount - parseInt(item.amount);
                }
              })
            }

            //获取总金额
            this.totalAmount = productTotalAmount + expenseAmount;
            //处理小数
            this.totalAmount = this.commonJsExtend.getDecimalsLen(this.totalAmount, 5);
          }
        }
        // if(this.billsTypeSign == 0){//销售
        //   this.totalAmount = data.tatalAmount;
        // }else if(this.billsTypeSign == 2){//库存
        //   this.totalAmount = data.tatalAmount;
        // }else if(this.billsTypeSign == 3){//采购
        //   this.totalAmount = data.tatalAmount;
        // }else if(this.billsTypeSign == 5){//收付款
        //   this.totalAmount = data.tatalAmount;
        // }else if(this.billsTypeSign == 6){//报销
        //   this.totalAmount = data.tatalAmount;
        // }else if(this.billsTypeSign == 8){//发票
        //   this.totalAmount = data.tatalAmount;
        // }
      },

      /* 获取成品表格总价格 */
      getProTotalAmount(){
        let productTotalAmount = 0;
        //获取成品总金额
        if(!!this.bills_product_data){
          this.bills_product_data.forEach((item,index)=>{
            //判断是否以明细计算
            if(item.hasOwnProperty("is_detail") && item.is_detail == true){//是
              if(this.bills_mes_offer_data){
                //循环获取总金额
                this.bills_mes_offer_data.forEach((itemJ,indexJ)=>{
                  if(item.product_id == itemJ.productInfo.id){
                    //物料总计
                    itemJ.noTreeBomList.forEach((itemK,indexK)=>{
                      productTotalAmount = productTotalAmount + itemK.total_amount;
                    })
                    //工序总计
                    itemJ.mesMainProcessList.forEach((itemZ,indexZ)=>{
                      productTotalAmount = productTotalAmount + itemZ.total_labour_cost;
                    })
                  }
                })
              }
            }else{//否
              productTotalAmount = productTotalAmount + (item.price * item.number);
            }
          })
        }
        return productTotalAmount;
      },

      /* 清空组件数据 */
      clearNodeFormMsg(){
        //清楚总金额信息
        this.totalAmount = 0;
      },
    }
  }
</script>

<style lang="less">
  //总金额框
  .totalAmountBox{
    //总金额
    .totalAmount{
      .title{
        // color: red;
      }
      .value{
        color: red;
      }
    }
  }
</style>
