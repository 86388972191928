<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：生产单据物料工序组件
	开始时间：2021-04-20
	开发人员：刘巍骏
	最后修改：2021-04-20
	备注说明：外部接受方式使用getNodeTableData
-->
<template>
  <div class="mesTableBox" id="productMaterialBox">
    <el-tabs v-model="mesTabIndex" type="border-card">
      <el-tab-pane label="物料清单" name="0">
        <!-- 头部按钮 -->
        <div class="mes_head">
          <!-- 添加按钮 -->
          <div class="addBtn" v-if="is_edit">
            <el-button type="primary" size="small" @click="addMaterialData(-1)">添加物料</el-button>
          </div>
          <!-- 版本数据 -->
          <div class="versionBtn" v-show="curProductBomVersionData.length>1">
            <span class="title">版本:</span>
            <el-select class="selInput" size="mini" v-model="selBomVersion" placeholder="使用版本" style="width: 100px;"
              @change="getBomDataByVersion(selBomVersion)">
              <el-option v-for="elem in curProductBomVersionData" :key="elem.id" :value="elem.id" :label="elem.version">
              </el-option>
            </el-select>
          </div>
        </div>
        <!-- 表格内容 -->
        <div class="mes_table billing_table">
          <el-table :data="mesMaterial" :indent="18" :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            row-key="id" height="100%" border show-summary :default-expand-all="true" style="width: 100%">
            <!-- 操作 -->
            <el-table-column label="操作" width="100" v-if="is_edit">
              <template slot-scope="scope">
                <i class="el-icon-edit-outline icon" title="修改" @click="updateMaterial(scope.row)"></i>
                <i class="el-icon-delete icon" title="删除" @click="delectMaterialData(scope.row)"></i>
                <i class="el-icon-circle-plus-outline icon" title="添加子分类"
                  @click="addMaterialSubclassData(scope.row)"></i>
              </template>
            </el-table-column>
            <!-- 数据内容 -->
            <el-table-column prop="house_name" label="仓库" sortable width="120">
              <template slot-scope="scope">
                <el-input size="mini" v-model="scope.row.house_name" @click.native="showWarehouseBox(scope.row)">
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="product_info_code" label="物料编码" sortable width="200"></el-table-column>
            <el-table-column prop="product_old_code" label="旧物料编码" v-if="show_oldMaterialCode" width="200">
            </el-table-column>
            <el-table-column prop="product_info_name" label="物料名称" width="200"></el-table-column>
            <el-table-column prop="specifications" label="物料规格型号" width="200"></el-table-column>
            <el-table-column prop="calculateName" label="计量单位" width="80"></el-table-column>
            <!-- <el-table-column prop="price" label="成本单价" width="100"></el-table-column> -->
            <el-table-column prop="need_num" label="所需数量" width="80"></el-table-column>
            <el-table-column prop="need_total_num" label="所需总数量" width="80"></el-table-column>
            <el-table-column prop="consume_rate" label="报损率" width="80">
              <template slot-scope="scope">
                <span>{{scope.row.consume_rate}}‰</span>
              </template>
            </el-table-column>
            <el-table-column prop="predict_get_num" label="预计领料数量" width="100"></el-table-column>
            <!-- <el-table-column prop="total_amount" label="所需费用" width="100"></el-table-column>
            <el-table-column prop="total_all_amount" label="所需总费用" width="120"></el-table-column> -->
            <el-table-column prop="position_num" label="位号" width="80"></el-table-column>
            <el-table-column prop="replace_name" label="可替换物料" width="100">
              <template slot-scope="scope">
                <span @click="showReplaceBox(scope.row)">{{scope.row.replace_name}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="warehouseTotalNumber" label="库存数" width="80">
              <template slot-scope="scope">
                <!-- <span>{{scope.row.warehouseTotalNumber}}</span> -->
                <span>{{scope.row.warehouseTotalNumber | getPurchaseChangeNum(scope.row)}}</span>
                <span v-if="operateType == 0">{{scope.row.productInfoPrice | getPurchaseUnit}}</span>
                <span v-if="operateType == 1 || operateType == 2">{{scope.row.productPriceList | getPurchaseUnit}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" min-width="150"></el-table-column>
          </el-table>
        </div>
      </el-tab-pane>

      <!-- 生产工序 -->
      <el-tab-pane label="生产工序" name="1">
        <!-- 头部按钮 -->
        <div class="mes_head">
          <el-button type="primary" size="small" :disabled="!is_edit" @click="showTechnologyBox">添加工序</el-button>
        </div>
        <!-- 表格内容 -->
        <div class="mes_table  billing_table">
          <el-table :data="mesProcessData"  id="detailSort" row-key="id" height="100%" border show-summary style="width: 100%">
            <!-- 操作 -->
            <!-- <el-table-column label="操作" width="80" v-if="is_edit"> -->
            <!-- 拖拽图标 -->
            <el-table-column width="50">
              <template slot-scope="scope">
                <i class="el-icon-s-grid my-handle"></i>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <i class="el-icon-edit-outline icon" title="编辑" @click="updateProcess(scope.$index,scope.row)"></i>
                <i class="el-icon-delete icon" title="删除" @click="delectProcess(scope.$index,scope.row)"></i>
              </template>
            </el-table-column>
            <!-- 数据内容 -->
            <el-table-column prop="name" sortable label="编号" width="120"></el-table-column>
            <el-table-column prop="name" sortable label="名称" width="120"></el-table-column>
            <el-table-column prop="" label="物料设置" width="150">
              <template slot-scope="scope">
                <span class="processNoSet" v-if="scope.row.assignMaterialList.length == 0"
                  @click="showAssignBomBox(scope.row)">未设置物料</span>
                <span class="processYesSet" v-else
                  @click="showAssignBomBox(scope.row)">已设{{scope.row.assignMaterialList.length}}种物料</span>
              </template>
            </el-table-column>
            <el-table-column prop="manufactureTypeName" label="生产类型" width="80"></el-table-column>
            <el-table-column prop="userName" label="负责人" width="200"></el-table-column>
            <el-table-column prop="deptName" label="负责部门" width="200"></el-table-column>
            <el-table-column prop="supplierName" label="供应商名称" width="240"></el-table-column>
            <el-table-column prop="costTypeName" label="计费方式" width="120"></el-table-column>
            <el-table-column prop="hours" label="预计用时" width="80"></el-table-column>
            <!-- <el-table-column prop="totalLabourCost" label="初始单价" width="80"></el-table-column>
            <el-table-column prop="totalLabourCost" label="预计总工价" width="150"></el-table-column>
            <el-table-column prop="minWorkAmount" label="最低开工费用" width="120"></el-table-column>
            <el-table-column prop="minWorkNumber" label="最低开工费配额" width="120"></el-table-column> -->
            <el-table-column prop="fileName" label="工序图纸" width="150"></el-table-column>
            <el-table-column prop="remark" label="备注" min-width="150"></el-table-column>
          </el-table>
        </div>
      </el-tab-pane>

      <!-- 工艺标准 -->
      <!-- <el-tab-pane label="工艺标准" name="2"> -->
      <!-- 头部按钮 -->
      <!-- <div class="mes_head">
          <el-button type="primary" size="small" @click="showCraftBox">添加工艺</el-button>
        </div> -->
      <!-- 表格内容 -->
      <!-- <div class="mes_table billing_table">
          <el-table :data="mesProcessData" height="100%" border show-summary style="width: 100%">
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <i class="el-icon-delete icon" title="删除" @click="delectProcesstest(scope.$index,scope.row.id)"></i>
              </template>
            </el-table-column>
            <el-table-column prop="name" sortable label="名称" width="120"></el-table-column>
            <el-table-column prop="fileName" label="文件名" width="150"></el-table-column>
            <el-table-column prop="remark" label="备注" min-width="150"></el-table-column>
          </el-table>
        </div> -->
      <!-- </el-tab-pane> -->
    </el-tabs>

    <!-- 可替换物料列表数据 -->
    <el-dialog title="可替换物料" :visible.sync="show_replaceMaterial" width="60%" append-to-body>
      <!-- 可替换物料弹框 -->
      <div class="replaceBomBox billing_table">
        <el-table :data="replaceBomData" row-key="product_info_id" height="100%" border show-summary
          style="width: 100%">
          <!-- 单选 -->
          <el-table-column label="" fixed width="45">
            <template slot-scope="scope">
              <el-radio :label="scope.$index" v-model="selectedReplaceIndex"
                @change.native="getSelectedReplace(scope.$index,scope.row)">{{''}}</el-radio>
            </template>
          </el-table-column>
          <el-table-column prop="product_info_name" label="名称" sortable width="200"></el-table-column>
          <el-table-column prop="calculateName" label="计量单位" width="80"></el-table-column>
          <!-- <el-table-column prop="inventory_num" label="库存数" width="80"></el-table-column> -->
          <el-table-column prop="need_num" label="所需数量" width="80"></el-table-column>
          <el-table-column prop="price" label="成本价" width="100"></el-table-column>
          <el-table-column prop="total_amount" label="费用" width="100"></el-table-column>
          <el-table-column label="报损率" width="80">
            <template slot-scope="scope">
              <span>{{scope.row.consume_rate}}‰</span>
            </template>
          </el-table-column>
          <el-table-column prop="warehouseTotalNumber" label="库存数" width="80"></el-table-column>
          <el-table-column prop="remark" label="备注" min-width="150"></el-table-column>
        </el-table>
      </div>

      <!-- 添加修改 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="show_replaceMaterial = false">取 消</el-button>
        <el-button type="primary" @click="replaceMaterialData">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 工序设置指定物料弹框 -->
    <el-dialog :visible.sync="show_assignBom" class="padding_top_10" :fullscreen="true" append-to-body>
      <!-- dialog头部自定义 -->
      <div slot="title" class="processMaterialBoxHead">
        <!-- 标题 -->
        <div class="title">
          <span>正在为【{{currentProcess.name}}】指定物料</span>
        </div>
        <!-- 工序合成物料 -->
        <div class="set_material">
          <span>合成物料:</span>
          <el-select v-model="curProcessMaterialId" class="select_material" size="small" filterable placeholder="请选择">
            <el-option @click.native="getProcessMaterial('null')" key="-1" label="无" value="null">
            </el-option>
            <el-option v-for="item in materialNoTree" @click.native="getProcessMaterial(item)" :key="item.id"
              :label="item.product_info_name" :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 指定物料弹框内容 -->
      <div class="assignBomBox">
        <!-- 左边可指定物料 -->
        <div class="assignLeft">
          <!-- 头部提示信息 -->
          <div class="assignHead">
            <h5>可指定物料</h5>
          </div>
          <!-- 表格内容 -->
          <div class="assignBody billing_table">
            <el-table ref="supplyAssign" :data="materialNoTree" row-key="id" height="100%" border
              @select="getSelectedAssign" style="width: 100%">
              <!-- 索引 -->
              <el-table-column type="index" fixed width="50"></el-table-column>
              <!-- 全选 -->
              <el-table-column type="selection" :selectable="disableDefaultRow" fixed width="45"></el-table-column>
              <!-- 数据内容 -->
              <el-table-column prop="product_info_code" label="编码" sortable width="150" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="product_info_name" label="名称" width="150"></el-table-column>
              <el-table-column prop="need_num" label="所需数量" width="80"></el-table-column>
              <el-table-column prop="need_total_num" label="所需总量" width="80"></el-table-column>
              <el-table-column prop="not_allot_num" label="未分配数量" width="80"></el-table-column>
              <el-table-column prop="not_allot_all_num" label="未分配总量" width="80"></el-table-column>
              <el-table-column prop="calculate_name" label="计量单位" width="80"></el-table-column>
              <el-table-column prop="position_num" label="位号" width="80"></el-table-column>
              <el-table-column prop="specifications" label="物料规格" width="80"></el-table-column>
            </el-table>
          </div>
        </div>

        <!-- 右边已指定物料 -->
        <div class="assignRight">
          <!-- 头部提示信息 -->
          <div class="assignHead">
            <h5>已指定物料</h5>
          </div>
          <!-- 表格内容 -->
          <div class="assignBody billing_table">
            <el-table :data="currentAlreadyAssign" row-key="id" height="100%" border style="width: 100%">
              <!-- 索引 -->
              <el-table-column type="index" fixed width="50"></el-table-column>
              <!-- 数据内容 -->
              <el-table-column prop="product_info_code" label="编码" sortable width="150" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="product_info_name" label="名称" width="150"></el-table-column>
              <el-table-column prop="need_num" label="分配数量" width="80">
                <template slot-scope="scope">
                  <input class="inputNum" type="number" :disabled="!is_edit" v-model="scope.row.need_num"
                    oninput="this.value=this.value.replace(/[^0-9]/g,'')"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="need_all_num" label="分配总量" width="80"></el-table-column>
              <el-table-column prop="calculate_name" label="计量单位" width="80"></el-table-column>
              <el-table-column prop="specifications" label="物料规格" min-width="80"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!-- 添加修改 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="show_assignBom = false">取 消</el-button>
        <el-button type="primary" @click="getProcessAssignData">确 定</el-button>
      </span>
    </el-dialog>

    <!--新增物料组件 -->
    <materialAdd :materialAddBox="materialAddBox" :currentMaterial="currentMaterial" :materialInfo="currentMaterialEdit" :operatedType="1"
      :addMaterialType="addMaterialType" @getMaterData="getMaterData" v-if="materialAddBox"></materialAdd>

    <!-- 工序选择组件 -->
    <technologyList @SelectedData="getSelTechnologyData"></technologyList>

    <!-- 工序新增编辑弹框 -->
    <mesProcessEdit :operatedState="operatedState" :operatedType="1" :mesProcessData="mesProcessObj"
      @getMesProcessData="getMesProcessData" v-if="show_processform">
    </mesProcessEdit>

    <!-- 新增工艺组件 -->
    <craftAdd :show_craftAddBox="show_craftAddBox" @getSelCraftData="getSelCraftData"></craftAdd>

    <!-- 仓库选择弹框 -->
    <warehouseList @SelectedData="getWarehouseData" v-if="show_warehouseComponent"></warehouseList>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入相关接口
  import productApi from '@/network/production/production.js'; //生产相关接口
  import stockApi from '@/network/stock/stock.js'; //仓库相关接口


 // import
 //引入拖动排序
 import Sortable from "sortablejs";
  // 导入组件
  import materialAdd from '@/components/production/billing/productionPlan/detail_componentItem/item_compontent/material_add.vue'; //新增物料弹框
  import technologyList from '@/components/commComponent/list/list_technology.vue'; //工序选择组件
  import warehouseList from '@/components/commComponent/list/list_warehouse'; //仓库列表组件
  import craftAdd from '@/components/production/billing/productionPlan/detail_componentItem/item_compontent/craft_add.vue';
  import mesProcessEdit from "@/components/commComponent/dialog/mes_process_edit.vue"; //工序新增编辑组件

  export default {
    name: 'nodeTable_data',
    props: {
      // 节点分类code
      nodeTypeCode: {
        type: String,
        default: ''
      },
      // 系统表头字段数据
      sysTableData: {
        type: Array,
        default: []
      },
      //自定义表格字段
      cusTableData: {
        type: Array,
        default: []
      },
      //是否提交当前填写的表单信息
      isCommitFormData: {
        type: Boolean,
        default: true
      },
      //是否清空当前已填写表单数据
      isClearFormData: {
        type: Boolean,
        default: true
      },
      // 操作类型(0:新增 1:修改  2:查看)
      operateType: {
        type: Number,
        default: -1
      },
      //数据校验步骤(根据步骤校验数据合法性给出提示信息)
      commitFormStep: {
        type: Number,
        default: -1
      },
      //回显单据数据
      originalBillData: {
        type: Object,
        default () {
          return {}
        }
      },
      //回显单据原id(-1:新增 其他:生成)
      generateBillsId: {
        type: Number,
        default: -1
      },
    },
    data() {
      return {
        //当前组件数据
        mesTabIndex: '0', //选项卡操作下标
        mesMaterial: [], //物料表格数据
        mesMaterialIdStr: "", //当前单据物料id字符串(用于查询库存信息)
        replaceBomData: [], //可替换物料数据
        mesCraftData: [], //工艺表格数据
        mesCraftArr: [], //工艺id数组

        //修改时使用的数据
        curProductPlanNum: 0, //用于记录生产计划数量当前变化的次数(第一次为系统操作不修改物料数据)
        is_edit: false, //是否可编辑(预览时不可以)

        //控制开关
        show_addMaterial: false, //是否显示新增物料信息
        show_craftAddBox: false, //是否显示新增工艺组件
        show_replaceMaterial: false, //是否显示可替换物料弹框信息
        show_assignBom: false, //是否显示工序指定物料弹框
        show_oldMaterialCode: false, //是否显示原物料编码
        show_processform: false, //控制显示工序新增编辑弹框

        //物料清单相关
        selBomVersion: '', //当前选择的版本
        curProductBomVersionData: [], //当前货品bom版本列表数据

        //新增物料相关
        materialAddBox: false, //控制显示新增物料组件
        currentMaterialData: {}, //当前操作的物料数据
        currentMaterial: -1, //当前操作物料id(物料弹框用)(-1代表新增,其他代表修改)
        operateAddManageType: 0, //物料操作类型(界面用)(0:新增 1:修改)
        operateAddManageParent: -1, //新增物料父级id
        currentParentId: 0, //当前父级id(用于新增界面新增物料信息)
        currentMaterialChild: [], //当前操作的子级数据(新增界面新增子级物料时用物料)
        currentMaterialEdit: {}, //当前编辑的物料信息
        addMaterialType: -1, //生产单可添加物料的类型(生产单用)(0:只能添加指定属性的物料  1:可以添加所有属性物料)

        //可替换物料相关
        selectedReplaceIndex: '', //已选可替换物料下标(radio绑定值)
        selectedReplaceData: {}, //已选可替换物料数据

        //工序相关
        operatedState: 0, //工序操作状态
        mesProcessObj: {}, //当前操作的工序数据
        mesProcessData: [], //工序表格数据
        processIdArr: [], //工序id数组
        defaultTechnologyType: 0, //工序组件复选框操作类型
        materialNoTree: [], //可指定物料(非树形结构)
        currentAlreadyAssign: [], //当前工序指定物料列表
        currentProcess: {}, //当前操作的工序信息
        curProcessMaterialId: "", //当前工序指定的合成物料

        //辅助数据
        show_warehouseComponent: false, //控制加载仓库组件
        currentObj: {}, //当前操作的数据
        loading: '', //loading框
      }
    },
    mounted() {
      // 判断界面显示数据
      this.judgeShowData();
      //初始话拖拽功能
      this.initDrag(); //初始化排序
    },
    computed: {
      ...mapState({
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
        product_data: state => state.commComponent.product_data, //节点基本信息所选产品对象
        bills_base_data: state => state.commComponent.bills_base_data, //表单填写的基本信息数据
        WarehouseConfig: state => state.system.WarehouseConfig, //仓库数据
        //权限相关
        companyparamlist: state => state.companyparamlist, //获取企业级参数
      })
    },
    watch: {
      // 监听操作类型发生改变
      operateType: {
        handler(newVal) {
          if (newVal == 2) { //预览
            this.is_edit = false;
          } else { //新增修改
            this.is_edit = true;
          }
        }
      },
      //监听父组件是否需要提交当前信息
      isCommitFormData(newVal) {
        this.commitNodeTableMsg();
      },
      //监听是否清空当前子组件数据
      isClearFormData() {
        //调用清除信息方法
        this.clearNodeFormMsg();
      },
      //监听基本信息选择货品信息
      product_data(newVal) {
        //根据货品id查询bom相关信息
        this.getSetBomByProduct(newVal);
      },
      //监听所选工序是否发生改变
      // mesProcessData(newVal) {
      //   //获取工序相关信息
      //   this.getMesProcessMsg(newVal);
      // },
      //监听工艺数据是否发生变化
      mesCraftData(newVal) {
        // 获取工序相关信息
        this.getMesCraftMsg(newVal);
      },
      // 监听回显单据数据
      originalBillData(newVal) {
        this.getDefaultTableData(newVal);
      },
      //监听计划数量发生变化(生产计划单)
      'bills_base_data.plan_num'(newVal) {
        if (!!newVal) {
          this.getMaterialTotalMsg();
          //每变化一次增加一次记录(用于区分是否发送修改请求)
          this.curProductPlanNum++
        }
      },
    },
    methods: {
      ...mapMutations([
        'SHOW_TECHNOLOGYBOX', //控制工序选择弹框是否显示
        'SHOW_WAREHOUSEBOX', //控制仓库是否显示
        'TOTALCOSTPRICE',//存入vuex中bom总成本信息
      ]),

      /* 判断界面需要显示的权限数据 */
      judgeShowData() {
        //判断是否显示旧物料编码
        if (this.companyparamlist.param345 == "0") { //启用
          this.show_oldMaterialCode = true;
        }
      },

      /* 获取货品已设物料信息 */
      getSetBomByProduct(data) {
        if (data.length > 1) {
          this.$message({
            type: 'warning',
            message: "一次只能生产一个货品",
            duration: this.elDuration
          })
          return
        }
        //判断当前货品是否已设bom(已设bom不能操作当前bom界面)
        if (data[0].bom_is_set == 0) { //未设
          // 判断企业是否一起必须按照bom清单执行
          if (this.companyparamlist.param353 == "0") { //启用
            this.is_edit = false; //不可编辑
          } else if (this.companyparamlist.param353 == "1") { //未启用
            this.is_edit = true; //可编辑
            this.addMaterialType = 0; //设置只能添加指定属性物料
          }
          //清空数据
          this.mesMaterial = []; //清空物料
          this.mesProcessData = []; //清空工序
          this.mesCraftData = []; //清空工艺
        } else if (data[0].bom_is_set == 1) { //已设
          // 判断企业是否一起必须按照bom清单执行
          if (this.companyparamlist.param353 == "0") { //启用
            this.is_edit = false; //不可编辑
          } else if (this.companyparamlist.param353 == "1") { //未启用
            this.is_edit = true; //可编辑
            this.addMaterialType = 0; //设置只能添加指定属性物料
          }
          //获取当前货品bom版本列表
          let bomProData = {
            product_id: data[0].id,
          }
          // 发送请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '货品bom信息获取中,请稍候...');
          productApi.findBomVersionByProductId(bomProData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //获取版本列表
              this.curProductBomVersionData = res.data;
              //判断数据
              if (res.data.length == 0) {
                this.$message({
                  type: 'warning',
                  message: "该货品未设置bom信息,请确认!",
                  duration: this.elDuration
                })
              } else {
                //定义需要显示的bom版本信息
                let bomVersionData = {};
                //获取默认版本
                let defaultVersion = res.data.filter(item => item.isDefault == 1);
                //判断是否有默认版本
                if (defaultVersion.length == 0) { //有默认的取默认的
                  bomVersionData = defaultVersion[0];
                } else { //没有取第一条
                  bomVersionData = res.data[0];
                }
                //获取当前版本数据
                this.selBomVersion = bomVersionData.id;
                //根据版本获取bom信息
                this.getBomDataByVersion(bomVersionData.id);
              }
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 根据版本获取bom信息productId:货品id  versionId:版本id */
      getBomDataByVersion(versionId) {
        //根据版本获取bom信息
        let proData = {
          product_info_id: this.product_data[0].id, //货品id
          version_id: versionId, //版本id
        }
        //发送请求
        this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '货品bom信息获取中,请稍候...');
        productApi.findAllMesMainBom(proData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.TOTALCOSTPRICE(res.data.mesBomVersion.totalCostPrice)
            //获取已设物料数据(物料需处理层级关系)
            let bomList = res.data.mesMainBomList;
            let disposeBom = [];
            if (bomList) {
              //转换为非树形结构
              let noTreeBom = this.commonJsExtend.toNoTree(bomList, []);
              noTreeBom.forEach((item, index) => {
                //判断是bom主表的还是bom子表的
                if (!item.hasOwnProperty('mes_main_bom_id')) {
                  item.parentId = -1;
                } else {
                  if (item.mes_bom_parent_id == -1) {
                    item.parentId = item.mes_main_bom_id;
                  }
                }
                //获取生产物料表所需数据
                item.mes_bom_id = item.id; //原物料id
                //转换为驼峰
                for (let i in item) {
                  let toHump = this.commonJsExtend.toHump(i);
                  item[toHump] = item[i];
                }
              })
              //转换为树形结构
              this.mesMaterial = this.commonJsExtend.toTree(noTreeBom, 'parentId');

              //调用获取总数量方法获取总数量信息
              this.getMaterialTotalMsg();
              //获取库存信息
              this.getProWarehouseNumber(this.mesMaterialIdStr, this.bills_base_data.house_id, 0)
            }

            //获取已设工序数据(工序添加到新表需要处理相关字段)
            this.mesProcessData = res.data.mesMainProcessList;
            //处理工序驼峰数据
            this.dispostProcessListHump(0);

            //获取已设工艺数据
            this.mesCraftData = res.data.mesMainTechList;
          } else {
            this.$message({
              type: 'error',
              message: 'bom信息获取失败,请联系管理员!',
              duration: this.elDuration
            })
          }
        })
      },

      /* 处理工序驼峰数据(type:新增时的工序  1:预览时的工序) */
      dispostProcessListHump(type){
        //需要增加一个关联工序id
        this.mesProcessData.forEach((item, index) => {
          let itemObj = JSON.parse(JSON.stringify(item));
          //处理不同数据源操作
          if(type == 0){//手动选择bom
            //获取工序原id
            item.process_main_id = item.itemObj;
            //获取供应商
            if(itemObj.supplier_id){
              //获取供应商id数组
              let supplierIdArr = itemObj.supplier_id.split(",");
              let supplierNameArr = itemObj.supplier_name.split(",");
              //获取默认共供应商信息
              item.supplier_id = supplierIdArr[0];
              item.supplier_name = supplierNameArr[0];
              //获取源供应商信息
              item.supplier_ids = itemObj.supplier_id;
              item.supplier_names = itemObj.supplier_name;
            }
          }
          //将工序数据处理为驼峰数据
          for (let i in item) {
            let toHump = this.commonJsExtend.toHump(i);
            item[toHump] = item[i];
          }

          // 处理工序制定物料驼峰
          if(type == 0){//新增时的工序
            let assignMaterialList = [];
            if (item.assignMaterial) { //判空
              item.assignMaterial.forEach((itemJ, indexJ) => {
                // 定义接受新的非驼峰数据
                let assignMaterialObj = {};
                for (let i in itemJ) {
                  let to_line = this.commonJsExtend.toLine(i);
                  assignMaterialObj[to_line] = itemJ[i];
                }
                //关联原物料id
                assignMaterialObj.mes_material_id = assignMaterialObj.bom_id;
                //获取处理后的数据
                assignMaterialList.push(assignMaterialObj);
              })
            }
            //获取新的非驼峰数据
            item.assignMaterialList = assignMaterialList;
          }else if(type == 1){
            // 处理驼峰
            let assignMaterialList = [];
            if (item.assignMaterialList) { //判空
              item.assignMaterialList.forEach((itemJ, indexJ) => {
                // 定义接受新的非驼峰数据
                let assignMaterialObj = {};
                for (let i in itemJ) {
                  let to_line = this.commonJsExtend.toLine(i);
                  assignMaterialObj[to_line] = itemJ[i];
                }
                assignMaterialList.push(assignMaterialObj);
              })
            }
            //获取新的非驼峰数据
            item.assignMaterialList = assignMaterialList;
          }
        })
      },

      /* 获取物料总数量信息 */
      async getMaterialTotalMsg() {
        // 判断是新增界面还是修改界面(修改时第一次不执行该方法)
        if (this.operateType == 1 && this.curProductPlanNum == 0) { //修改
          return
        }
        //转换一次物料数据表
        let materialData = JSON.parse(JSON.stringify(this.mesMaterial));
        //转换为非树形结构
        let noTreeBom = this.commonJsExtend.toNoTree(materialData, []);
        //定义相关参数
        let materialId = []; //物料id数组
        let houseId = ""; //仓库id
        //处理总数量相关信息(物料)
        noTreeBom.forEach((item, index) => {
          //判断有无计划生产数量
          if (this.bills_base_data.hasOwnProperty('plan_num')) {
            //获取所需总数量(该处获取总数量未使用公共方法是因为不好获取,存在拷贝问题)
            item.need_total_num = eval(item.need_num * this.bills_base_data.plan_num); //所需总量
            item.allot_all_num = eval(item.allot_num * this.bills_base_data.plan_num); //已分配总量
            item.not_allot_all_num = eval(item.not_allot_num * this.bills_base_data.plan_num); //未分配总量
            //获取预计领料数量
            let breakageNum = Math.ceil(eval(item.need_total_num * item.consume_rate / 1000)); //计算损耗量
            item.consume_num = parseInt(breakageNum); //获取损耗量
            item.predict_get_num = eval((parseInt(item.need_total_num) + parseInt(breakageNum))); //获取预计领料量
            //获取总费用
            item.total_all_amount = this.commonJsExtend.getDecimalsLen(eval(item.total_amount * this
              .bills_base_data
              .plan_num), 5);
          }

          // 获取库存信息(默认选择基本信息选择的仓库)
          if (this.bills_base_data.hasOwnProperty('house_id') && this.bills_base_data.hasOwnProperty(
              'house_id') != "") {
            this.WarehouseConfig.forEach((itemI, indexI) => {
              if (itemI.id == this.bills_base_data.house_id) {
                //获取仓库id
                item.house_id = itemI.id;
                //获取仓库名称
                item.house_name = itemI.name;
              }
            })
          }

          //获取当前物料id字符串
          materialId.push(item.product_info_id);
          //将数组转换成字符串
          this.mesMaterialIdStr = materialId.join(",");
        })

        //处理总数量相关信息(工序指定物料)
        let mesProcessData = JSON.parse(JSON.stringify(this.mesProcessData));
        //循环获取工序指定物料总数量信息
        mesProcessData.forEach((itemI, indexI) => {
          //判断有无计划生产数量
          if (this.bills_base_data.hasOwnProperty('plan_num')) {
            //循环获取所需物料总数量
            itemI.assignMaterialList.forEach((itemJ, indexJ) => {
              itemJ.need_all_num = eval(itemJ.need_num * this.bills_base_data.plan_num); //所需物料总数
            })
            //获取工序总价
            itemI.total_labour_cost = this.commonJsExtend.getDecimalsLen(eval(itemI.labour_cost * this
              .bills_base_data.plan_num), 3);
          }
        })
        //将处理好的数据赋值回去
        this.mesProcessData = mesProcessData;

        //转换为树形结构
        if (this.operateType == 0) { //新增界面
          this.mesMaterial = this.commonJsExtend.toTree(noTreeBom, 'parentId');
        } else if (this.operateType == 1) { //修改界面
          //获取界面显示信息
          // this.mesMaterial = this.commonJsExtend.toTree(noTreeBom, 'mes_bom_parent_id');
          //修改数据库数据
          let updateData = {
            mesMaterial: JSON.stringify(noTreeBom),
            statusId: 1, //节点id
          }
          //发送请求
          await productApi.updateMesMaterialBatch(updateData).then(res => {
            if (res.code == 200) {
              // this.$message
              this.getMaterialData();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 获取物料总数量相关(单个) */
      getMaterialTotalMsgItem(data) {
        //获取总数量
        data.need_total_num = eval(data.need_num * this.originalBillData.plan_num); //所需总量
        data.allot_all_num = eval(data.allot_num * this.bills_base_data.plan_num); //已分配总量
        data.not_allot_all_num = eval(data.not_allot_num * this.bills_base_data.plan_num); //未分配总量
        //获取预计领料数量
        let breakageNum = Math.ceil(eval(data.need_total_num * data.consume_rate / 1000)); //计算损耗量
        data.consume_num = parseInt(breakageNum); //获取损耗量
        data.predict_get_num = eval((parseInt(data.need_total_num) + parseInt(breakageNum))); //获取预计领料量
        //获取总费用
        data.total_all_amount = this.commonJsExtend.getDecimalsLen(eval(data.total_amount * this.originalBillData
          .plan_num), 5);

        // 获取库存信息(默认选择基本信息选择仓库)
        if (this.bills_base_data.hasOwnProperty('house_id') && this.bills_base_data.hasOwnProperty('house_id') !=
          "") {
          this.WarehouseConfig.forEach((itemI, indexI) => {
            if (itemI.id == this.bills_base_data.house_id) {
              //获取仓库id
              data.house_id = itemI.id;
              //获取仓库名称
              data.house_name = itemI.name;
            }
          })
        }
        return data;
      },

      /* 初始化表格行拖动功能*/
      initDrag() {
        //初始化需要拖动的容器
        const el = document.querySelectorAll("#detailSort>.el-table__body-wrapper > table > tbody")[0];
        Sortable.create(el, {
          disabled: false, // 拖拽不可用? false启用
          // ghostClass: 'sortable-ghost', //拖拽样式
          // chosenClass: "chosen",
          // dragClass: "chosen",
          animation: 150, // 拖拽延时，效果更好看
          handle: ".my-handle",
          onEnd: (e) => { // 拖拽结束时的触发
            let data = {
              newIndex:this.mesProcessData[e.newIndex].id, //改变后的下标
              oldIndex:this.mesProcessData[e.oldIndex].id , //改变前的下标
              id: this.generateBillsId, //主键id
            }

            this.loading = this.commonJsExtend.customLoading("#detailSort", 1);
            productApi.updateSortNum(data).then(res => {
              this.loading.close();
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: "修改顺序成功",
                  duration: this.elDuration
                })
               this.mesProcessData=res.data
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          },
        });
      },

      /* 获取默认数据(回显时用) */
      getDefaultTableData(data) {
        //判断操作类型
        if (this.operateType == 2) { //预览
          this.is_edit = false;
        }
        // 获取生产计划单相关详情信息
        if (data.mesMaterial) { //物料信息
          let materialData = JSON.parse(JSON.stringify(data.mesMaterial));
          let materIalIdArr = [];
          //获取界面的其他数据
          materialData.forEach((item, index) => {
            if(!!item.productInfo){
              item.product_old_code = item.productInfo.product_old_code;

              materIalIdArr.push(item.product_info_id);
            }
          })
          //转换为树形结构
          let treeData = this.commonJsExtend.toTree(materialData, 'mes_bom_parent_id');
          //获取物料信息
          this.mesMaterial = treeData;

          //获取库存信息
          this.getProWarehouseNumber(materIalIdArr.join(","), this.bills_base_data.house_id, 0);
        }
        //工序信息
        if (data.mesProcessList) {
          this.mesProcessData = data.mesProcessList;
          //处理工序驼峰
          this.dispostProcessListHump(1);
        }
      },

      /* 显示仓库弹框 */
      showWarehouseBox(data) {
        this.currentObj = data;
        this.show_warehouseComponent = true; //加载组件
        this.SHOW_WAREHOUSEBOX(true); //显示组件
      },

      /* 获取仓库弹框选择数据 */
      getWarehouseData(data) {
        //关闭加载仓库组件
        this.show_warehouseComponent = false;
        //获取货品所在仓库数据
        this.currentObj.house_id = data.id;
        this.currentObj.house_name = data.name;
        //获取当前货品库存信息
        this.getProWarehouseNumber(this.currentObj.product_info_id, data.id, 0);
      },

      /* 获取产品库存信息proDataIdStr:产品id字符串数据  houseId:仓库数据id*/
      getProWarehouseNumber(proDataIdStr, houseId, type) {
        //定义接口接受参数
        let data = {
          ids: proDataIdStr, //货品id
          warehouseId: houseId, //仓库id
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#productMaterialBox", 4, '物料库存信息获取中,请稍候...');
        //发送请求获取库存信息
        stockApi.findProNumByHouseIdAndProId(data).then(res => {
          this.loading.close();
          //判断后端数据准确性
          if (res.code == 200) {
            if (res.data && res.data.length == 0 && proDataIdStr.length == 0) {
              this.$message({
                type: 'warning',
                message: '该仓库暂无该货品信息!',
                duration: this.elDuration
              })
              // return
            }
            if (type == 0) {
              //存储一份需要处理的物料数据,避免拷贝问题
              let mesMaterialNew = JSON.parse(JSON.stringify(this.mesMaterial));
              //转换为非树形结构
              let noTreeBom = this.commonJsExtend.toNoTree(mesMaterialNew, []);
              //获取库存
              noTreeBom.forEach((itemI, indexI) => {
                if (res.data) {
                  res.data.forEach((itemJ, indexJ) => {
                    if (itemI.product_info_id == itemJ.productInfoId) {
                      itemI.warehouseTotalNumber = itemJ.warehouseTotalNumber;
                    }
                  })
                }
              })
              //转换为树形结构
              // this.loading = this.commonJsExtend.customLoading("#productMaterialBox", 4, 'aaaa,请稍候...');
              this.mesMaterial = this.commonJsExtend.toTree(noTreeBom, 'parentId');
              // this.loading.close();
            } else if (type == 1) {
              this.replaceBomData.forEach((itemI, indexI) => {
                if (res.data) {
                  res.data.forEach((itemJ, indexJ) => {
                    if (itemI.product_info_id == itemJ.productInfoId) {
                      this.$set(itemI, 'warehouseTotalNumber', itemJ.warehouseTotalNumber)
                      // itemI.warehouseTotalNumber = itemJ.warehouseTotalNumber;
                    }
                  })
                }
              })
              this.$forceUpdate();
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取生产物料数据 */
      getMaterialData() {
        let data = {
          mes_main_id: this.generateBillsId
        }
        // 加载loading框
        this.loading = this.commonJsExtend.customLoading("#productMaterialBox", 4, '物料信息更新中,请稍候...');
        //发送请求
        productApi.findMesMaterialByBillId(data).then(res => {
          //关闭loading框
          this.loading.close()
          //判断接口返回结果
          if (res.code == 200) {
            this.mesMaterial = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取生产工序列表 */
      getMesProcessList() {
        let data = {
          mesMainId: this.generateBillsId
        }
        // 加载loading框
        this.loading = this.commonJsExtend.customLoading("#productMaterialBox", 4, '工序信息更新中,请稍候...');
        //发送请求
        productApi.findMesProcessListByMesMainId(data).then(res => {
          //关闭loading框
          this.loading.close()
          //判断接口返回结果
          if (res.code == 200) {
            this.mesProcessData = res.data;
            //处理工序驼峰数据
            this.dispostProcessListHump(1);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      // /* 获取工序id数组相关信息 */
      // getMesProcessMsg(data) {
      //   // 定义接受值
      //   this.processIdArr = [];
      //   // 循环获取id数组和状态数组
      //   data.forEach((item, index) => {
      //     this.processIdArr.push(item.id)
      //   })
      // },

      /* 获取工艺id数组相关信息 */
      getMesCraftMsg(data) {
        // 定义接受值
        this.mesCraftArr = [];
        // 循环获取id数组和状态数组
        data.forEach((item, index) => {
          this.mesCraftArr.push(item.id)
        })
      },

      /* 添加物料信息(顶级) parentid:父级id*/
      addMaterialData(parentid) {
        // 判断是否已选生产货品
        if (this.operateType == 0 && JSON.stringify(this.product_data) == "{}") {
          this.$message({
            type: 'warning',
            message: '请先选择需要生产的货品',
            duration: this.elDuration
          })
          return
        }
        //设置为新增
        this.operateAddManageType = 0;
        //获取父级id
        this.operateAddManageParent = parentid;
        //显示新增物料弹框
        this.materialAddBox = true;
        //设置弹框类型为新增
        this.currentMaterial = -1;
      },

      /* 添加物料信息(子级) data:父级数据*/
      addMaterialSubclassData(data) {
        //获取当前需要添加子级的数据
        this.currentMaterialChild = data;
        //获取父级id
        this.operateAddManageParent = data.id;
        //显示新增物料弹框
        this.materialAddBox = true;
        //设置为新增
        this.operateAddManageType = 0; //设置为新增
        //设置弹框类型为新增(物料公共弹框)
        this.currentMaterial = -1;
      },

      /* 编辑物料信息 */
      updateMaterial(data) {
        //获取当前编辑的物料对象
        this.currentMaterialEdit = data;
        //显示新增物料弹框
        this.materialAddBox = true;
        //设置为修改
        this.operateAddManageType = 1; //设置为修改
        //设置弹框类型为新增
        this.currentMaterial = data.id;
      },

      /* 获取物料弹框数据 */
      getMaterData(data) {
        //接受获取的物料数据
        let materialData = {
          is_init: 0, //是否为初次添加物料
          mes_bom_parent_id: this.operateAddManageParent, //父id
          mes_main_id: this.generateBillsId, //生产单id
          product_info_id: data.proId, //物料id
          product_info_code: data.proCode, //物料编号
          product_old_code: data.proOldCode, //旧物料编码
          product_info_name: data.name, //物料名称
          specifications: data.specifications, //规格型号
          calculate_id: data.unit, //计量单位id
          calculateName: data.unitName, //计量单位名称
          priceData: data.priceData, //价格多计量数据
          price: data.price, //成本价格
          machine_price: data.machine_price, //加工费单价
          process_unit_id: data.process_unit_id, //加工单位id
          process_unit_name: data.process_unit_name, //加工单位名称
          valuation_num: data.valuation_num, //计价数
          consume_rate: data.consume_rate, //报损率
          is_pour_flush: data.is_pour_flush == true ? 1 : 0, //是否为倒冲
          is_bom_merge: data.is_bom_merge == true ? 1 : 0, //是否为工序指定合成物料
          need_num: data.number, //所需数量
          allot_num: 0, //已被工序分配的数量(默认为0)
          not_allot_num: data.number, //未分配数量(默认等于所需数量,工序指定物料时用)
          total_amount: data.cost, //总费用
          remark: data.remark, //备注
        }
        // 判断是新增界面的操作还是修改界面的操作
        if (this.operateType == 0) { //新增界面
          //调用处理新增界面业务
          this.disposeAddData(materialData);
        } else if (this.operateType == 1) {
          //调用处理修改界面业务
          this.disposeUpdateData(materialData);
        }
      },

      /* 处理新增界面物料业务 */
      disposeAddData(materialData) {
        // 判断是新增还是编辑
        if (this.operateAddManageType == 0) { //新增
          //设置父级id
          materialData.parentId = this.operateAddManageParent; //设置父id
          materialData.id = this.currentParentId; //设置id
          materialData.children = []; //设置子级
          // 判断是新增顶级还是新增子级
          if (this.operateAddManageParent == -1) { //顶级
            this.mesMaterial.push(materialData)
          } else { //子级
            if (!this.currentMaterialChild.children) {
              this.currentMaterialChild.children = [];
            }
            this.currentMaterialChild.children.push(materialData)
          }
          this.currentParentId = this.currentParentId + 1;
        } else if (this.operateAddManageType == 1) { //修改
          //将数据转换为非树形结构
          let noTreeMaterial = this.commonJsExtend.toNoTree(this.mesMaterial, [])
          //获取当前处理的数据下标
          let operateIndex = -1;
          noTreeMaterial.forEach((item, index) => {
            if (item.id == this.currentMaterialEdit.id) {
              operateIndex = index;
            }
          })
          //设置父id
          materialData.parentId = this.currentMaterialEdit.parentId;
          //替换数据
          noTreeMaterial.splice(operateIndex, 1, materialData);
          //转换为树形结构
          this.mesMaterial = this.commonJsExtend.toTree(noTreeMaterial, 'parentId');
        }
        //获取总数量相关信息
        this.getMaterialTotalMsg();
      },

      /* 处理修改界面时的物料业务 */
      disposeUpdateData(materialData) {
        //重新定义一个数据接收,以免出现拷贝问题
        let materialAll = this.getMaterialTotalMsgItem(materialData);
        // 判断是新增还是修改
        if (this.operateAddManageType == 0) { //新增
          //发送请求
          productApi.addMesMaterial(materialAll).then(res => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "物料添加成功",
                duration: this.elDuration
              })
              // 获取物料列表数据
              this.getMaterialData();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.operateAddManageType == 1) { //修改
          materialAll.id = this.currentMaterial;
          //加载loading框
          // this.loading = this.commonJsExtend.customLoading("#proMaterialDetail", 4, '物料信息修改中,请稍候...');
          // 发送请求
          productApi.updateMesMaterial(materialAll).then(res => {
            // this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "物料修改成功",
                duration: this.elDuration
              })
              // 获取物料列表数据
              this.getMaterialData();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 删除已选物料 */
      delectMaterialData(data) {
        // 判断是新增界面删除还是修改界面删除
        if (this.operateType == 0) { //新增界面
          //将数据转换为非树形结构
          let noTreeMaterial = this.commonJsExtend.toNoTree(this.mesMaterial, [])
          //获取当前处理的数据下标
          let operateIndex = -1;
          noTreeMaterial.forEach((item, index) => {
            if (item.id == data.id) {
              operateIndex = index;
            }
          })
          //删除数据
          noTreeMaterial.splice(operateIndex, 1);
          //转换为树形结构
          this.mesMaterial = this.commonJsExtend.toTree(noTreeMaterial, 'parentId');
        } else if (this.operateType == 1) { //修改界面
          this.$confirm('删除后的数据不可恢复, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let deleteData = {
              id: data.id,
            }
            //加载loading框
            this.loading = this.commonJsExtend.customLoading("#productMaterialBox", 4, '物料信息删除中,请稍候...');
            //发送删除请求
            productApi.deleteMesMaterial(deleteData).then(res => {
              this.loading.close();
              //判断接口返回值
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: "物料数据删除成功",
                  duration: this.elDuration
                })
                // 获取物料列表数据
                this.getMaterialData();
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
              duration: this.elDuration
            });
          });
        }
      },

      /* 显示可替换物料弹框 */
      showReplaceBox(data) {
        //获取列表数据
        if (!data.hasOwnProperty('mes_main_bom_id')) {
          this.getReplaceBom(data.id, 0);
        } else {
          this.getReplaceBom(data.id, 1);
        }
        //获取当前操作的物料信息
        this.currentMaterialData = data;
        //显示弹框
        this.show_replaceMaterial = true;
      },

      /* 查询可替换物料信息列表 */
      getReplaceBom(id, type) {
        let data = {
          relevanceMainBomId: id,
          type: type,
        }
        //加载loading框
        //发送请求
        productApi.findReplaceBom(data).then(res => {
          //关闭loading框
          if (res.code == 200) {
            this.replaceBomData = res.data;
            //获取库存(未齐全)
            let proIdArr = [];
            res.data.forEach((item, index) => {
              proIdArr.push(item.product_info_id);
            })
            this.getProWarehouseNumber(proIdArr.join(","), this.bills_base_data.house_id, 1);
          } else {
            this.$message({
              type: 'warning',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取选择可替换物料的数据 */
      getSelectedReplace(index, selection) {
        // 获取radio绑定值
        this.selectedReplaceIndex = index;
        //获取已选客户数据
        this.selectedReplaceData = selection;
      },

      /* 使用可替换物料替换 */
      replaceMaterialData() {
        //循环对象替换数据
        for (let i in this.currentMaterialData) {
          //子级不替换
          if (i != "children") {
            this.currentMaterialData[i] = this.selectedReplaceData[i];
          }
        }
        this.getMaterialTotalMsg();
        // 关闭弹框
        this.show_replaceMaterial = false;
      },

      /* 显示工序选择组件 */
      showTechnologyBox(data) {
        // 判断是否已选生产货品
        if (JSON.stringify(this.product_data) == "{}") {
          this.$message({
            type: 'warning',
            message: '请先选择需要生产的货品',
            duration: this.elDuration
          })
          return
        }
        this.SHOW_TECHNOLOGYBOX(true);
      },

      /* 获取工序弹框选择数据 */
      getSelTechnologyData(data) {
        // 判断当前操作界面
        if (this.operateType == 0) { //新增
          data.forEach((item, index) => {
            //默认设置一个已指定物料数组
            this.$set(item, "assignMaterialList", []);
            //获取工序数据
            this.mesProcessData.push(item);
          })
        } else if (operateType == 1) { //修改

        }
      },

      /* 获取添加或编辑的工序数据 */
      getMesProcessData(type, data) {
        console.log(data)
        //判断组件操作方式
        if (type == 0) { //确定
          //判断当前生产类型为委外时才有供应商
          let supplier_id = ''; //供应商id
          let supplier_name = ''; //供应商名称
          if (data.typeData.code == 'DICT-SMM-002') {
            supplier_id = data.supplierId;
            supplier_name = data.supplier;
          }
          //获取公共数据
          var processData = {
            name: data.name, //工序名称
            encoding: data.encoding, //编码
            costTypeId: data.costId, //计费方式Id
            deptId: data.departmentId, //负责部门
            deptName: data.department, //负责部门
            hours: data.time, //标准工时
            totalLabourCost: data.total_labour_cost, //总工价
            minWorkAmount: data.min_work_amount, //最低开工费用
            minWorkNumber: data.min_work_number, //最低开工费配额
            imgUrl: data.ImgUrlArr.join(","), //工序图纸路径
            isEnable: data.is_enable ? 1 : 0, //是否启用
            labourCost: data.price, //工价
            taxRage: data.tax_rage, //税率
            manufactureTypeId: data.typeId, //生成类型Id
            processTypeId: data.classfiyId, //工序分类Id
            userId: data.personnelId, //负责人Id
            userName: data.personnel, //负责人Id
            supplierId: supplier_id, //供应商Id
            supplierName: supplier_name, //供应商名称
            manufacturingAllocationCost:data.manufacturing_allocation_cost+"@@@"+data.manufacturingType,//制造分摊费用
            manageCostAllocation:data.manage_cost_allocation+"@@@"+data.manageType,//管理分摊费用
            otherApportionedCosts:data.other_apportioned_costs+"@@@"+data.otherType,//其它分摊费用
            workNum: data.work_num, //执行人数
            remark: data.remark, //备注
          }
          //判断当前操作类型
          if (this.operatedState == 0) { //新增

          } else if (this.operatedState == 1) { //修改
            processData.id = data.id;
            processData.name = data.old_name;
            processData.new_name = data.name;
            //发送请求
            productApi.updateMesProcess(processData).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '数据修改成功',
                  duration: this.elDuration
                })
                //关闭组件
                this.show_processform = false;
                //获取新的列表数据
                this.getMesProcessList();
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }
        } else if (type == 1) { //取消
          this.show_processform = false;
        }
      },


      /* 获取工序指定合成物料 */
      getProcessMaterial() {

      },

      /* 修改工序数据 */
      updateProcess(index, data) {
        //设置操作方式为修改
        this.operatedState = 1;
        //获取需要修改的数据
        this.mesProcessObj = data;
        //显示弹框
        this.show_processform = true;
      },

      /* 删除已选工序 */
      delectProcess(index, data) {
        // 判断当前操作类型
        if (this.operateType == 0) { //新增
          this.mesProcessData.splice(index, 1);
        } else if (this.operateType == 1) { //修改
          this.$confirm('删除后的数据不可恢复, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let deleteData = {
              id: data.id,
            }
            //加载loading框
            this.loading = this.commonJsExtend.customLoading("#productMaterialBox", 4, '工序信息删除中,请稍候...');
            //发送删除请求
            productApi.deleteMesMainProcess(deleteData).then(res => {
              this.loading.close();
              //判断接口返回值
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: "工序数据删除成功",
                  duration: this.elDuration
                })
                // 获取工序列表数据
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
              duration: this.elDuration
            });
          });
        }
      },

      /* 工序设置指定物料左边供选择是否可选(已设bom时不可选) */
      disableDefaultRow(row) {
        return this.is_edit;
      },

      /* 显示为工序设置指定物料弹框 */
      showAssignBomBox(data) {
        if (this.mesMaterial.length == 0) {
          this.$message({
            type: 'warning',
            message: '请先设置物料!',
            duration: this.elDuration
          })
          return
        }
        //获取当前操作的工序
        this.currentProcess = data;
        //获取可指定物料数据(非树形结构)(左边数据)
        this.materialNoTree = this.commonJsExtend.toNoTree(this.mesMaterial, []);
        // 判断当前界面状态
        if (this.operateType == 0) { //新增界面
          //获取当前工序已指定物料数据(右边数据)
          this.currentAlreadyAssign = data.assignMaterialList;
        } else if (this.operateType == 1 || this.operateType == 2) { //修改/预览界面
          //获取当前工序已指定物料数据(右边数据)
          this.currentAlreadyAssign = data.assignMaterialList;
        }
        //设置默认选择的数据
        this.$nextTick(() => {
          // 获取默认选中的物料id数组
          let selIdArr = [];
          data.assignMaterialList.forEach((item, index) => {
            selIdArr.push(item.mes_material_id);
          })
          //根据默认选中的id默认勾选对应物料
          this.materialNoTree.forEach((item, index) => {
            if (selIdArr.includes(item.id)) {
              this.$refs.supplyAssign.toggleRowSelection(item, true);
            }
          })
        })
        //显示弹框
        this.show_assignBom = true;
      },

      /* 获取工序指定物料 */
      getProcessAssignData() {
        //判断分配的物料数量数否合法
        if (!this.judgeAllocationNum()) {
          return
        }
        //判断当前界面操作状态
        if (this.operateType == 0) { //新增
          // 将当前设置的工序物料信息添加到对应数据
          this.mesProcessData.forEach((item, index) => {
            if (item.id == this.currentProcess.id) {
              item.assignMaterialList = JSON.parse(JSON.stringify(this.currentAlreadyAssign));
            }
          })
        }
        //清空选中
        this.$refs.supplyAssign.clearSelection();
        //获取未分配物料数据(有树形结构的物料数据)
        this.getAllocationNum();
        //关闭弹框
        this.show_assignBom = false;
      },

      /* 判断指定物料数量是否已分配完 */
      judgeAllocationNum() {
        //判断数量设置不合法(为0或者为负数的)
        let numNoQualified = [];
        this.currentAlreadyAssign.forEach((item, index) => {
          if (parseInt(item.need_num) <= 0) {
            numNoQualified.push(item.product_info_code);
          }
        })
        if (numNoQualified.length > 0) {
          let mesStr = numNoQualified.join(",");
          this.$message({
            type: 'warning',
            message: "物料编号为【" + mesStr + "】的数量设置不能为0,请确认!",
            duration: this.elDuration
          })
          return false;
        }

        //判断当前分配数量超出可分配数量范围
        let exceedMaterialArr = []; //定义数量超出的(当前分配数量超出可分配数量范围)
        //循环所有物料
        this.mesMaterial.forEach((itemI, indexI) => {
          //定义一个接受已分配的物料数量
          let alredyNum = 0;
          this.mesProcessData.forEach((itemJ, indexJ) => {
            //有分配物料才执行
            if (itemJ.assignMaterialList) {
              itemJ.assignMaterialList.forEach((itemZ, indexZ) => {
                //根据id获取总的已分配数
                if (itemZ.mes_material_id == itemI.id) {
                  alredyNum = alredyNum + parseInt(itemZ.need_num);
                }
              })
            }
          })
          //判断所需数是否大于
          if (alredyNum > itemI.need_num) {
            exceedMaterialArr.push(itemI.product_info_code)
          }
        })
        //如果有超出的物料给出提示信息
        if (exceedMaterialArr.length > 0) {
          let mesStr = exceedMaterialArr.join(",");
          this.$message({
            type: 'warning',
            message: "物料编号为【" + mesStr + "】的数量超出未分配范围,请确认!",
            duration: this.elDuration
          })
          return false;
        }
        return true;
      },

      /* 获取未分配物料数据 */
      getAllocationNum() {
        //循环将所有工序的物料都获取
        let alreadyAllMaterial = [];
        this.mesProcessData.forEach((itemI, indexI) => {
          itemI.assignMaterialList.forEach((itemJ, indexJ) => {
            alreadyAllMaterial.push(itemJ);
          })
        })
        //获取物料未分配数量
        let noTreeBom = this.commonJsExtend.toNoTree(this.mesMaterial, []); //获取无树形结构的数据
        noTreeBom.forEach((itemI, indexI) => {
          let alreadyNum = 0;
          alreadyAllMaterial.forEach((itemJ, indexJ) => {
            if (itemI.id == itemJ.mes_material_id) {
              alreadyNum = alreadyNum + parseInt(itemJ.need_num);
            }
          })
          //获取分配数量
          itemI.allot_num = alreadyNum; //已分配数量
          itemI.not_allot_num = eval(itemI.need_num - alreadyNum); //未分配数量
        })
        //转换为树形结构
        this.mesMaterial = this.commonJsExtend.toTree(noTreeBom, 'parentid');
      },

      /* 工序指定物料选择弹框 */
      getSelectedAssign(selection, row) {
        //获取当前操作的物料信息
        let assighObj = {
          mes_process_id: this.currentProcess.id, //关联生产执行单的id
          need_num: 0, //所需数量
          mes_material_id: row.id, //关联的生产单物料表id
          mes_bom_parent_id: row.parentId, //当前bom的父id
          product_info_id: row.product_info_id, //物料id
          product_info_code: row.product_info_code, //物料编码
          product_old_code: row.product_old_code, //旧物料编码
          product_info_name: row.product_info_name, //物料名称
          specifications: row.specifications, //物料规格
          calculate_id: row.calculate_id, //计量单位id
          calculate_name: row.calculate_name, //计量单位名称
          price: row.price, //物料单价
        }
        // 判断当前操作界面
        if (this.operateType == 0) { //新增界面
          //判断是勾选还是取消
          if (selection.includes(row)) { //勾选
            //将处理好的数据添加当前工序
            let alreadyExit = [];
            this.currentAlreadyAssign.forEach((item, indexJ) => {
              alreadyExit.push(item.mes_material_id);
            })
            //已添加的数据不添加
            if (!alreadyExit.includes(assighObj.mes_material_id)) {
              this.currentAlreadyAssign.push(assighObj);
            }
          } else { //取消
            this.currentAlreadyAssign = this.currentAlreadyAssign.filter(item => item.mes_material_id != row.id);
          }
        } else if (this.operateType == 1) { //修改界面

        }
      },

      /* 显示工艺弹框组件 */
      showCraftBox() {
        // 判断是否已选生产货品
        if (JSON.stringify(this.product_data) == "{}") {
          this.$message({
            type: 'warning',
            message: '请先选择需要生产的货品',
            duration: this.elDuration
          })
          return
        }
        //显示新增工艺弹框
        this.show_craftAddBox = true;
      },

      /* 获取工艺选择弹框数据 */
      getSelCraftData(data) {
        // console.log(data);
      },

      /* 取消关闭工艺弹框 */
      closeCraftBox() {
        this.show_craftAddBox = false;
      },

      /* 清空当前组件数据 */
      clearNodeFormMsg() {},

      /* 提交当前组件已填写信息 */
      commitNodeTableMsg() {
        //判断数据合法性
        if (this.mesMaterial.length == 0) { //判断物料不为空
          this.$message({
            type: 'warning',
            message: '物料不能为空!',
            duration: this.elDuration
          })
          return
        }
        // if (this.mesProcessData.length == 0) { //判断工序不为空
        //   this.$message({
        //     type: 'warning',
        //     message: '工序不能为空!',
        //     duration: this.elDuration
        //   })
        //   return
        // }

        //处理工序数据
        this.mesProcessData.forEach((item, index) => {
          //处理工序数据
          item.create_user_id = this.UserInfo.user_id; //制单人id
          item.create_user_name = this.UserInfo.user_name; //制单人名称
          item.bills_date = this.commonJsExtend.getDateTime(new Date(), 0); // 获取当前日期
          // 处理工序指定物料
          item.assignMaterial = item.assignMaterialList;
        })
        //定义传入父组件的数据
        let data = {
          materialData: this.mesMaterial,
          mesProcessData: this.mesProcessData
        }
        this.$emit("getNodeTableData", data);
      },

      /* 关闭新增物料弹框 */
      closeMaterialBox() {
        this.materialAddBox = false;
      },
    },
    filters:{
      /* 获取采购计量单位 */
      getPurchaseUnit(value) {
        let result = "";
        //判断是否有值
        if (value && value.length > 0) {
          value.forEach((item, index) => {
            if (item.is_purchase_default == 1) {
              result = item.calculateName;
            }
          })
          //若没有基础计量单位则默认选中第一个
          if (result == "") {
            result = value[0].calculateName;
          }
        }
        return result;
      },
      /* 多计量换算 */
      getPurchaseChangeNum(number, row) {
        //定义返回结果
        let resnum = number;

        // 获取多计量数据
        let productPriceList = row.productInfoPrice;
        if(!productPriceList){
          productPriceList = row.productPriceList;
        }

        // 获取基础计量单位
        let curCalculate = null;
        let curCalculateArr = productPriceList.filter(item => item.is_base == 1);
        if (curCalculateArr.length > 0) {
          curCalculate = curCalculateArr[0].exchange_base;
        }

        //获取采购计量单位
        let purchaseCalculate = null;
        let purchaseCalculateArr = productPriceList.filter(item => item.is_purchase_default == 1);
        if (purchaseCalculateArr.length > 0) {
          purchaseCalculate = purchaseCalculateArr[0].exchange_base;
        }

        //转换计量单位
        if (curCalculate != null && purchaseCalculate != null) {
          let curcxchaangebase = 1;
          //判断转换关系
          if (curCalculate > purchaseCalculate) { //大转小
            //获取换算计量
            curcxchaangebase = (curCalculate / purchaseCalculate);
          } else if (curCalculate < purchaseCalculate) { //小转大
            //获取换算计量
            curcxchaangebase = (purchaseCalculate / curCalculate);
          }

          //计算数据
          resnum = resnum / curcxchaangebase;
        }

        return resnum;
      },
    },
    components: {
      materialAdd,
      technologyList,
      craftAdd,
      warehouseList,
      mesProcessEdit
    }
  }
</script>

<style lang="less">
  .mesTableBox {
    height: 50vh;
    padding: 10px 0;
    // border: 1px solid black;

    // 头部按钮框
    .mes_head {
      padding-bottom: 5px;
      display: flex;

      // border: 1px solid black;
      //版本框样式
      .versionBtn {
        margin-left: 20px;

        // border: 1px solid black;
        //标题
        .title {
          font-size: 12px;
          padding-right: 10px;
        }
      }
    }

    // 表格内容数据
    .mes_table {
      height: calc(50vh - 100px);
    }
  }

  //可替换物料弹框信息
  .replaceBomBox {
    height: 40vh;
  }
</style>
<style lang="less" src="@/less/production/process_assign.less"></style>
