<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：生产对账单明细组件
	开始时间：2021-04-20
	开发人员：刘巍骏
	最后修改：2021-04-20
	备注说明：外部接受方式使用getNodeTableData
-->
<template>
  <div class="accountCheckBox">
    <!-- 表格tab页 -->
    <div class="accountCheckTable">
      <el-tabs type="border-card">
        <!-- 母件明细 -->
        <el-tab-pane label="母件明细">
          <!-- 母件明细表格 -->
          <div class="accountCheck_table billing_table">
            <el-table :data="originBillsDataList" row-key="id" height="100%" border show-summary style="width: 100%">
              <!-- 数据内容 -->
              <el-table-column prop="" label="订单编号" sortable width="180" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="送货日期" width="180"></el-table-column>
              <el-table-column prop="" label="送货单号" width="180" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="货品编号" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="货品名称" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="货品规格" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="计量单位" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="税率" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="单价" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="含税金额" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="实际金额" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="生产数量" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="完成数量" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="开工日期" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="完工日期" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="生产工人" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="负责人" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="最低开工费" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="开工费配额" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="备注" min-width="150" show-overflow-tooltip></el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <!-- 加工明细 -->
        <el-tab-pane label="加工明细">
          <!--加工明细表格 -->
          <div class="accountCheck_table billing_table">
            <el-table :data="originBillsDataList" row-key="id" height="100%" border show-summary style="width: 100%">
              <!-- 数据内容 -->
              <el-table-column prop="" label="订单号" sortable width="200" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="物料编号" width="200"></el-table-column>
              <el-table-column prop="" label="物料名称" width="200" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="物料规格" width="200" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="单位" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="源单数量" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="使用数量" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="加工费单价" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="加工单位" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="计价数" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="含税金额" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="实际金额" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="备注" min-width="150" show-overflow-tooltip></el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <!-- 其它费用 -->
        <el-tab-pane label="其它费用">
          <!-- 费用明细表格 -->
          <div class="accountCheck_table billing_table"></div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 总计信息 -->
    <div class="accountCheckTotalBox">
      <ul class="totalUl">
        <li class="item_amount">
          <span class="date">2022-03-04</span>
          <span class="separator">--</span>
          <span class="date">2022-03-04</span>
          <span class="title">周期</span>
          <span class="title">应收/付金额:</span>
          <span class="amount">100</span>
        </li>
        <li class="item_amount">
          <span class="title">应收/付合计小写:</span>
          <span class="amount">10000</span>
        </li>
        <li class="item_amount">
          <span class="title">应收/付合计大写:</span>
          <span class="amount">一千</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default{
    name:'nodeCheckTableData',
    data(){
      return{
        //*********组件数据*********
        //源单相关
        originBillsDataList:[],//源单表格数据
      }
    }
  }
</script>

<style>
</style>
