<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：单据公共表格组件
	开始时间：2021-04-20
	开发人员：刘巍骏
	最后修改：2021-04-20
	备注说明：外部接受方式使用getNodeTableData
-->
<template>
  <div class="nodeTableItemBox">
    <!-- <el-button @click="">新增产品</el-button> -->
    <!-- 单据表格 -->
    <div class="billing_table" style="height: 450px;" id="nodeSysTableBox">
      <el-table :data="nodeSysTableVal" row-key="id" height="100%" border show-summary style="width: 100%">
        <!-- 索引 -->
        <el-table-column type="index" fixed width="50"></el-table-column>
        <!-- 全选 -->
        <!-- <el-table-column type="selection" fixed width="45"></el-table-column> -->
        <el-table-column label="操作" width="80" sortable v-if="is_edit">
          <template slot-scope="scope">
            <div class="operate">
              <i class="el-icon-circle-plus-outline operate_icon" @click="addDefaultRow(scope.$index)"></i>
              <i class="el-icon-remove-outline operate_icon" @click="removeDefaultRow(scope.$index)"></i>
            </div>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column v-for="(elem,colIndex) in nodeSysTableData" v-if="disposeShowFile(elem)" :key="colIndex"
          :width="elem.col_custom_width">
          <!-- 自定义表头内容 -->
          <template slot="header" slot-scope="scope">
            <span :class="{'is_importent_red':judgeIsImportFiled(elem.system.filed_name),
                  'is_importent_green':judgeIsImportGreenFiled(elem.system.filed_name),
                  'is_importent_warning':judgeIsImportWariningFiled(elem.system.filed_name)}">
              {{elem.show_name}}
            </span>
            <span class="tips" v-show="nodeMustFiledName.includes(elem.system.filed_name)">*</span>
          </template>
          <template slot-scope="scope">
            <!-- 判断显示的类型 -->
            <!-- 输入框(0) -->
            <el-input v-if="elem.system.type == 0" size="mini" v-model="scope.row[elem.system.filed_name]"
              @focus="getCurOperateRowData(scope.$index)" :disabled="!is_edit"
              @change="judgeChangeData(scope.$index,elem.system.filed_name,1)" placeholder="请输入内容">
            </el-input>

            <!-- 文本框(1) -->
            <div class="multiDiv" v-if="elem.system.type == 1">
              <!-- 计量单位(不分开存放的原因是有间距) -->
              <span v-if="showCalculateUnit.includes(elem.system.filed_name)">
                {{scope.row[elem.system.filed_name]}}
                {{scope.row.pricePurchaseData.calculateName}}
              </span>
              <!-- 重量单位 -->
              <span v-else-if="showWeightUnit.includes(elem.system.filed_name)">
                {{scope.row[elem.system.filed_name]}}
                {{scope.row.priceBaseData.weight_unit=0?'g':'kg'}}
              </span>
              <!-- 什么都没有的 -->
              <span v-else>{{scope.row[elem.system.filed_name]}}</span>
            </div>

            <!-- 日期框(2) -->
            <el-date-picker type="date" v-if="elem.system.type == 2" size="mini"
              @focus="getCurOperateRowData(scope.$index)"
              @input="getSelectDate($event,scope.$index,elem.system.filed_name)"
              @change="judgeChangeData(scope.$index,elem.system.filed_name,1)" :disabled="!is_edit"
              v-model="scope.row[elem.system.filed_name]" placeholder="选择日期" :prefix-icon="''" />

            <!-- 整数数量框(3) -->
            <input class="inputNum" v-if="elem.system.type == 3" type="number"
              @focus="getCurOperateRowData(scope.$index)" :disabled="!is_edit"
              @change="judgeNumberChange(scope.row,elem.system.filed_name,scope.$index)"
              :title="getPurchaseCalculate(scope.row,elem.system.filed_name)"
              v-model="scope.row[elem.system.filed_name]" oninput="this.value=this.value.replace(/[^0-9]/g,'')" />

            <!-- 小数框(4) -->
            <div class="multiDiv" v-if="elem.system.type == 4">
              <el-tooltip placement="top" effect="light" :disabled="judgeTooltip(scope.row,elem.system.filed_name)">
                <!-- 区间价内容 -->
                <div slot="content" class="priceRangeBox">
                  <div class="data_table">
                    <table>
                      <thead>
                        <tr>
                          <th class="sellrange_th">起订量-截止量</th>
                          <th class="row_head">
                            <div class="row_headBox">
                              <span v-if="scope.row.priceBaseData">价格({{scope.row.priceBaseData.currencyName}})</span>
                            </div>
                          </th>
                          <th class="operate">税费(%)</th>
                          <th class="operate">备注</th>
                          <th class="operate" v-if="operateType!=2">操作</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(rangI,index) in scope.row.priceRange">
                          <td class="input_range_td">
                            <div class="item">
                              <input class="input_range" type="text" v-model="rangI[0]"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'')" placeholder="起订数">
                              <span>-</span>
                              <input class="input_range" type="text" v-model="rangI[1]"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'')" placeholder="截止数">
                            </div>
                          </td>
                          <td>
                            <div class="tdbody">
                              <input class="input_price" v-model="rangI[2]" placeholder="对应价格"
                                oninput="this.value=this.value.replace(/[^0-9\.]/g,'')">
                              <span class="input_split">/</span>
                              <span class="input_currency">{{scope.row.priceBaseData.calculateName}}</span>
                            </div>
                          </td>
                          <td>
                            <div class="tdbody">
                              <input class="input_price" v-model="rangI[3]" placeholder="税费"
                                oninput="this.value=this.value.replace(/[^0-9\.]/g,'')">
                              <span class="input_split">%</span>
                            </div>
                          </td>
                          <td>
                            <input class="input_price" v-model="rangI[4]" placeholder="备注">
                          </td>
                          <td v-if="operateType!=2">
                            <i class="el-icon-delete" @click="remove_sellSection(scope.row.priceRange,index)"></i>
                          </td>
                        </tr>
                        <tr v-if="operateType!=2">
                          <td colspan="5">
                            <i class="el-icon-plus" @click="add_sellSection(scope.row.priceRange)"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- 区间价保存按钮 -->
                  <div class="priceRangeSave" v-if="operateType == 1">
                    <el-button type="primary" size="mini">保存</el-button>
                  </div>
                </div>
                <!-- 数字内容 -->
                <el-input size="mini" v-model="scope.row[elem.system.filed_name]"
                  @focus="getCurOperateRowData(scope.$index)" :disabled="!is_edit"
                  @input="getOnInputRowData(scope.$index,elem.system.filed_name)"
                  @change="judgeChangeData(scope.$index,elem.system.filed_name,1)" placeholder="小数"
                  oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"></el-input>
              </el-tooltip>
              <!-- 数字单位 -->
              <!-- <span v-if="showPriceUnit.includes(elem.system.filed_name)">
                {{scope.row.priceBaseData.currencyName}}</span> -->
            </div>

            <!-- 弹框(5) -->
            <el-input v-if="elem.system.type == 5" size="mini" v-model="scope.row[elem.system.filed_name]"
              :disabled="!is_edit" @click.native="judgeRelateData(elem,scope.$index)" placeholder="请选择"></el-input>

            <!-- 图片(6) -->
            <div class="imgBox" v-if="elem.system.type == 6">
              <!-- 图片 -->
              <div class="img" @click="previewFile(0,scope.row[elem.system.filed_name])">
                <img :src="scope.row[elem.system.filed_name] | getImgUrl">
              </div>
              <!-- 上传图标 -->
              <div>
                <el-upload class="upload-demo" :action="fileImgUrl" :headers="headers" :on-success="uploadFile"
                  :before-upload="beforeUplod" :show-file-list="false" :file-list="fileList" :disabled="!is_edit">
                  <i class="el-icon-upload2 img_icon" @click="getOperateRow(scope.$index,elem.system.filed_name)"></i>
                </el-upload>
              </div>
            </div>

            <!-- 下拉框(7) -->
            <div v-if="elem.system.type == 7">
              <el-select class="inputSelection" v-model="scope.row[elem.system.filed_name]" placeholder="请选择"
                @change="getSelChange(scope.row,elem.system.filed_name,scope.$index)" :disabled="!is_edit"
                @focus="getCurOperateRowData(scope.$index)" size="mini">
                <!-- 多计量下拉框 -->
                <el-option v-if="elem.system.type_val == 0" v-for="item in scope.row.priceAllData" :key="item.id"
                  :label="item.calculateName" :value="item.calculateName" :title="item.calculateRatio"
                  @click.native="getCurOperateRowData(scope.$index)"></el-option>
                <!-- 批次下拉框 -->
                <el-option v-if="elem.system.type_val == 1" v-for="item in scope.row.batchAllData" :key="item.id"
                  :label="item.productBatch" :value="item.productBatch"></el-option>
              </el-select>
            </div>

            <!-- 计算(8) -->
            <div class="singleDiv" v-if="elem.system.type == 8">
              <!-- 计量单位(不分开存放的原因是有间距) -->
              <span v-if="showCalculateUnit.includes(elem.system.filed_name)">
                {{elem.system.filed_name | getCalculate(elem,scope.row)}}
              </span>
              <!-- 重量单位 -->
              <span v-else-if="showWeightUnit.includes(elem.system.filed_name)">
                {{elem.system.filed_name | getCalculate(elem,scope.row)}}
                {{scope.row.weight_unit==0?'g':'kg'}}
              </span>
              <!-- 什么都没有的 -->
              <span v-else>{{elem.system.filed_name | getCalculate(elem,scope.row)}}</span>
            </div>

            <!-- 复选框(9) -->
            <el-checkbox v-if="elem.system.type == 9" :value="scope.row[elem.system.filed_name] == 1"
              @change="getCheckBoxChange(scope.row,elem.system.filed_name,scope.$index)"
              :disabled="judgeCheckBoxDisable(elem.system.filed_name)">
            </el-checkbox>

            <!-- 百分比(10) -->
            <el-input v-if="elem.system.type == 10" size="mini" v-model="scope.row[elem.system.filed_name]"
              @focus="getCurOperateRowData(scope.$index)" :disabled="!is_edit"
              @change="judgeChangeData(scope.$index,elem.system.filed_name,1)" placeholder="请输入内容"></el-input>

            <!-- 自定义点击显示 -->
            <div v-if="elem.system.type == 11">
              <!-- 批次号显示 -->
              <div v-if="elem.system.type_val == 0">
                <el-popover placement="bottom" width="500" v-model="scope.row.showBatchListBox" trigger="manual">
                  <!-- 表格外框 -->
                  <div class="billsBatchBox billing_table">
                    <el-table :data="scope.row.batchAllData" height="100%">
                      <!-- 勾选框 -->
                      <el-table-column label="" fixed width="45">
                        <template slot-scope="scopeJ">
                          <el-radio :label="scopeJ.$index" v-model="scope.row.selectedBatchIndex"
                            @change.native="getSelectedBatch(scopeJ.$index,scopeJ.row,scope.row)">{{''}}</el-radio>
                        </template>
                      </el-table-column>
                      <!-- 内容 -->
                      <el-table-column width="150" property="productBatch" sortable label="批次号"></el-table-column>
                      <el-table-column width="150" property="costPrice" label="成本价"></el-table-column>
                      <el-table-column width="100" property="warehouseNumber" label="库存量"></el-table-column>
                      <el-table-column width="120" property="onwayNumber" label="在途量"></el-table-column>
                      <el-table-column width="120" property="occupyNumber" label="占用量"></el-table-column>
                      <el-table-column width="120" property="productNumber" label="生产量"></el-table-column>
                    </el-table>
                  </div>
                  <!-- 选择按钮 -->
                  <div class="billsBatchBtn">
                    <el-button type="primary" @click="getCurrentBatchData(scope.row)" size="small">确定</el-button>
                  </div>
                  <el-input v-model="scope.row[elem.system.filed_name]"
                    @click.native="showCurrentBatchListBox(scope.row)" placeholder="请选择批次号" slot="reference" size="mini"
                    disabled></el-input>
                </el-popover>
              </div>
            </div>

            <!-- 文件框 -->
            <div class="fileBox" v-if="elem.system.type == 12">
              <!-- 附件列表 -->
              <div class="fileName">
                <el-tag :key="item.id" v-for="item in fileListData(scope.row[elem.system.filed_name])" closable
                  :disable-transitions="false"
                  @close="closeFileSpan(scope.row[elem.system.filed_name],item,scope.row,elem.system.filed_name,scope.$index)"
                  @click="previewFile(1,item)" size="small">
                  {{item.name}}
                </el-tag>
              </div>
              <!-- 上传图标 -->
              <div class="fileIcon">
                <el-upload class="upload-demo" :action="fileUrl" :file-list="fileListArr" :headers="headers"
                  :data="uploadData" :on-success="uploadFileSuccess" :before-upload="beforeUplodFile"
                  :show-file-list="false">
                  <el-button size="mini" @click="getOperateRow(scope.$index,elem.system.filed_name)" type="primary">附件
                  </el-button>
                </el-upload>
              </div>
            </div>
          </template>
        </el-table-column>

        <!-- 自定义信息 -->
        <!-- <el-table-column v-for="(elem,index) in nodeCusTableData" :label="elem.name" min-width="80">
          <template slot-scope="scope">
            <el-input v-if="elem.type == 0" size="mini" v-model="scope.row[elem.filed_name]"
              placeholder="请输入内容"></el-input>
          </template>
        </el-table-column> -->
      </el-table>
    </div>

    <!-- 单据总计 -->
    <div class="billingcreate_total">
      <!-- 销售模块总计功能 -->
      <ul v-if="nodeTypeCode=='SYS-NODE-TYPE-001'">
        <li v-show="show_totalAmount">
          <el-checkbox class="title" :disabled="!isSpecialCheckBox" @change="changeSpecialAmount" v-model="isSpecial">
            已申请特价,请输入获批金额:</el-checkbox>
          <el-input class="input" size="small" v-model="specialAmount" @focus="getOldSpaceAmount"
            @change="getChangeAmount" placeholder="请输入金额" :disabled="!isSpecialInput">
          </el-input>
        </li>
        <li v-show="show_totalAmount">
          <span class="title">{{totalAmountLabel.sellTotalLabel}}:</span>
          <!-- 新增修改时显示 -->
          <span class="number" v-if="operateType == 0 || operateType == 1">￥{{totalAmount}}</span>
          <!-- 预览时显示 -->
          <span class="number" v-else-if="operateType == 2">￥{{totalAmountData.totalAmount}}</span>
        </li>
        <!-- <li>
          <span class="title">其他费用:</span>
          <span @click="show_otherCost = true">+</span>
        </li> -->
      </ul>
      <!-- 采购模块表格数据 -->
      <ul v-if="nodeTypeCode=='SYS-NODE-TYPE-002'">
        <li v-show="show_purchaseTotalAmount">
          <span class="title">{{totalAmountLabel.purchaseTotalLabel}}:</span>
          <!-- 新增修改时显示 -->
          <span class="number" v-if="operateType == 0 || operateType == 1">￥{{purchaseTotalAmount}}</span>
          <!-- 预览时显示 -->
          <span class="number" v-else-if="operateType == 2">￥{{totalAmountData.purchaseTotalAmount}}</span>
        </li>
      </ul>
      <!-- 资金模块表格总计功能(收付款) -->
      <ul v-if="nodeTypeCode=='SYS-NODE-TYPE-005' && billsTypeSign == 5">
        <li v-show="show_shouldAmount">
          <span class="title">{{totalAmountLabel.costShouldTotalLabel}}:</span>
          <!-- 新增修改时显示 -->
          <span class="number" v-if="operateType == 0 || operateType == 1">￥{{shouldAmount}}</span>
          <!-- 预览时显示 -->
          <span class="number" v-else-if="operateType == 2">￥{{totalAmountData.shouldAmount}}</span>
        </li>
        <li v-show="show_realityAmount">
          <span class="title">{{totalAmountLabel.costRealityAmountLabel}}:</span>
          <!-- 新增修改时显示 -->
          <span class="number" v-if="operateType == 0 || operateType == 1">￥{{realityAmount}}</span>
          <!-- 预览时显示 -->
          <span class="number" v-else-if="operateType == 2">￥{{totalAmountData.realityAmount}}</span>
        </li>
        <li v-show="show_discountAmount">
          <span class="title">{{totalAmountLabel.costDiscountAmountLabel}}:</span>
          <!-- 新增修改时显示 -->
          <span class="number" v-if="operateType == 0 || operateType == 1">￥{{discountAmount}}</span>
          <!-- 预览时显示 -->
          <span class="number" v-else-if="operateType == 2">￥{{totalAmountData.discountAmount}}</span>
        </li>
      </ul>
      <!-- 资金模块表格数据(报销) -->
      <ul v-if="nodeTypeCode=='SYS-NODE-TYPE-005' && billsTypeSign == 6">
        <li v-show="show_expenceTotalAmount">
          <span class="title">{{totalAmountLabel.expenseTotalAmountLabel}}:</span>
          <!-- 新增修改时显示 -->
          <span class="number" v-if="operateType == 0 || operateType == 1">￥{{expenceTotalAmount}}</span>
          <!-- 预览时显示 -->
          <span class="number" v-else-if="operateType == 2">￥{{totalAmountData.expenceTotalAmount}}</span>
        </li>
      </ul>
      <!-- 资金模块表格数据(发票) -->
      <ul v-if="nodeTypeCode=='SYS-NODE-TYPE-005' && billsTypeSign == 8">
        <li v-show="show_invoiceTotalAmount">
          <span class="title">{{totalAmountLabel.invoiceTotalAmountLabel}}:</span>
          <!-- 新增修改时显示 -->
          <span class="number" v-if="operateType == 0 || operateType == 1">￥{{invoiceTotalAmount}}</span>
          <!-- 预览时显示 -->
          <span class="number" v-else-if="operateType == 2">￥{{totalAmountData.invoiceTotalAmount}}</span>
        </li>
      </ul>
      <!-- 库存模块表格数据 -->
      <ul v-if="nodeTypeCode=='SYS-NODE-TYPE-004'">
        <li v-show="show_inventoryTotalAmount">
          <span class="title">{{totalAmountLabel.depotCostTotalLabel}}:</span>
          <!-- 新增修改时显示 -->
          <span class="number" v-if="operateType == 0 || operateType == 1">￥{{inventoryTotalAmount}}</span>
          <!-- 预览时显示 -->
          <span class="number" v-else-if="operateType == 2">￥{{totalAmountData.inventoryTotalAmount}}</span>
        </li>
        <li v-show="show_inventoryTotalSaleAmount">
          <span class="title">{{totalAmountLabel.depotSellTotalLabel}}:</span>
          <!-- 新增修改时显示 -->
          <span class="number" v-if="operateType == 0 || operateType == 1">￥{{inventoryTotalSaleAmount}}</span>
          <!-- 预览时显示 -->
          <span class="number" v-else-if="operateType == 2">￥{{totalAmountData.inventoryTotalSaleAmount}}</span>
        </li>
      </ul>
    </div>

    <!-- 客户选择弹框 -->
    <!-- <customerList @SelectedData="getCustomerData" v-if="show_coustomerComponent"></customerList> -->

    <!-- 仓库选择弹框 -->
    <warehouseList @SelectedData="getWarehouseData" v-if="show_warehouseComponent"></warehouseList>

    <!-- 供应商选择弹框 -->
    <!-- <supplierList @SelectedData="getSupplierData" v-if="show_supplierComponent"></supplierList> -->

    <!-- 部门选择弹框 -->
    <!-- <departmentTree @SelectedData="getDepartmentData" v-if="show_departmentComponent"></departmentTree> -->

    <!-- 员工选择组件 -->
    <!-- <personnelList @SelectedData="getPersonnelData" v-if="show_personnelComponent"></personnelList> -->

    <!-- 货品选择弹框 -->
    <productList @SelectedData="getProductData" :isOrderFind="isOrderFind" :sellCondition="sellCondition"
      :isDepot="isDepot" :bomSetConditon="bomSetConditon" :proDetailBillTab="proDetailBillTab"
      v-if="show_productComponent"></productList>

    <!-- 单据选择弹框 -->
    <billingList @SelectedData="getBillingData" :conditionObj="conditionObj" :showSignArr="showSignArr"
      v-if="show_billsComponent"></billingList>

    <!-- 费用分类弹框 -->
    <costTypeTree @SelectedData="getCostTypeData" :showCostTypeArr="showCostTypeArr"
      :conditionObj="costTypeConditionObj" v-if="show_costTypeComponent">
    </costTypeTree>

    <!-- 附件预览组件 -->
    <filePreview @SelectedData="getPreviewData" :previewFilePath="previewFilePath" v-if="show_previewFileComponent">
    </filePreview>

    <!-- 其他费用 -->
    <el-dialog title="费用明细" :visible.sync="show_otherCost" width="30%" append-to-body>
      <el-form ref="form" :model="otherCostData" label-width="80px" size="mini">
        <el-form-item label="费用分类:">
          <el-input v-model="otherCostData.costType" @click.native="showCost"></el-input>
        </el-form-item>
        <el-form-item label="费用金额">
          <el-input v-model="otherCostData.costAmount"></el-input>
        </el-form-item>
        <el-form-item label="账号">
          <el-input v-model="otherCostData.costNum"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="otherCostData.costRemark"></el-input>
        </el-form-item>
        <el-form-item label="附件">
          <el-input v-model="otherCostData.costFile"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show_otherCost = false">取 消</el-button>
        <el-button type="primary" @click="show_otherCost = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex';

  //导入后端接口
  import api from '@/network/system/workflowSetup.js'; //工作流程相关接口请求
  import req from '@/network/datum/product.js'; //货品相关接口
  import sellApi from '@/network/sell/bills.js'; //销售模块
  import purchaseApi from '@/network/stock/purchase.js'; //采购模块
  import stockApi from '@/network/stock/stock.js'; //仓库相关接口
  import productApi from '@/network/production/production.js'; //生产模块
  import costApi from '@/network/cost/account.js'; //资金相关接口

  //导入组件
  import customerList from '@/components/commComponent/list/list_customer'; //客户列表组件
  import productList from '@/components/commComponent/list/list_product'; //产品列表组件
  import warehouseList from '@/components/commComponent/list/list_warehouse'; //仓库列表组件
  import billingList from '@/components/commComponent/list/list_billing'; //单据列表组件
  import supplierList from '@/components/commComponent/list/list_supplier'; //供应商列表组件
  import personnelList from '@/components/commComponent/list/list_personnel' //部门员工组件
  import departmentTree from '@/components/commComponent/tree/tree_department'; //部门选择组件
  import costTypeTree from '@/components/commComponent/tree/tree_costType.vue'; //费用分类组件
  import filePreview from '@/components/commComponent/dialog/file_preview.vue'; //附件预览组件

  var $this; //声明一个全局变量用于存储this
  export default {
    name: 'nodeTable_data',
    props: {
      // 操作类型(0:新增 1:修改  2:查看)
      operateType: {
        type: Number,
        default: -1
      },
      // 节点分类code
      nodeTypeCode: {
        type: String,
        default: ''
      },
      //单据分类(根据nodeTypeCode区分)
      billsClassify: {
        type: Number,
        default: 0
      },
      //节点分类标志(0:销售 1:生产 2:库存  3:采购  4:bom  5.收付款  6:资金 7:生产工序单 8:发票)
      billsTypeSign: {
        type: Number,
        default: -1
      },
      //数据校验步骤(根据步骤校验数据合法性给出提示信息)
      commitFormStep: {
        type: Number,
        default: -1
      },
      //基本信息中的总计信息
      baseToalData: {
        type: Array,
        default: []
      },
      // 系统表头字段数据
      sysTableData: {
        type: Array,
        default: []
      },
      //自定义表格字段
      cusTableData: {
        type: Array,
        default: []
      },
      //是否提交当前填写的表单信息
      isCommitFormData: {
        type: Boolean,
        default: true
      },
      //是否清空当前已填写表单数据
      isClearFormData: {
        type: Boolean,
        default: true
      },
      //是否触发保存单据新增的相关信息
      saveNewBillsData: {
        type: Boolean,
        default: false
      },
      //回显单据数据(具体数据)
      originalBillData: {
        type: Object,
        default () {
          return {}
        }
      },
      //回显单据原id(-1:新增 其他:生成)
      generateBillsId: {
        type: Number,
        default: -1
      },
      //批次号
      batchNumber: {
        type: String,
        default: ""
      }
    },
    data() {
      return {
        // 企业级表格数据
        nodeSysTableData: [], //节点系统表格表头数据
        nodeSysTableVal: [], //节点系统表格填写的数据
        nodeSysTableIdVal: [], //节点系统表格填写的数据(id格式)
        nodeMustFiledName: [], //节点必填字段数组
        nodeMustFiledData: [], //节点必填字段数据
        nodeLengthAstrict: [], //节点需要验证长度的字段信息数组
        currentTableFiledData: {}, //当前节点表格字段对象
        currentTableIndex: 0, //当前操作的表格数据下标

        //单据修改时用到的数据
        currentOldRowData: {}, //当前操作的旧数据
        specialOldAmount: '', //修改前的特惠金额

        //企业级自定义表格数据
        nodeCusTableData: [], //节点自定义表头数据
        nodeCusTableVal: [], //节点自定义表格填写数据
        currentTableCusFiledData: {}, //当前节点表格自定义字段对象

        //表格总数据
        isSpecial: false, //是否存在特批价格(控制复选框是否选中)
        isSpecialCheckBox: true, //特批价格复选宽是否可编辑
        isSpecialInput: true, //控制特批价格输入框是否可编辑
        specialAmount: '', //特批价格
        totalAmountData: { //总数据相关信息
          //销售
          totalAmount: 0, //销售单总金额
          //采购
          purchaseTotalAmount: 0, //采购单总金额
          //库存
          inventoryTotalAmount: 0, //库存单总金额
          inventoryTotalSaleAmount: 0, //库存单销售总金额
          //资金(收付款)
          shouldAmount: 0, //应收金额
          realityAmount: 0, //实收金额
          discountAmount: 0, //本次折扣
          //资金(报销)
          expenceTotalAmount: 0, //报销总金额
          //资金(发票)
          invoiceTotalAmount: 0, //发票总金额
        },
        totalAmountLabel: { //总数据标题信息
          //销售
          sellTotalLabel: '', //总价
          //采购
          purchaseTotalLabel: '', //总价
          //库存
          depotSellTotalLabel: '', //销售总价
          depotCostTotalLabel: '', //成本总价
          //资金(收付款)
          costShouldTotalLabel: '', //应收金额
          costRealityAmountLabel: '', //实收金额
          costDiscountAmountLabel: '', //本次折扣
          //资金(报销)
          expenseTotalAmountLabel: '', //报销总金额
          //资金(发票)
          invoiceTotalAmountLabel: '', //发票总金额
        },

        //弹框数据
        currentDialogFileName: '', //当前弹框操作的字段
        currentHourseData: {}, //弹框当前选择的仓库数据
        warehouseDataNoTree: [], //仓库数据(非树形结构)

        //辅助数据
        showPriceUnit: ['price'], //需要显示价格单位的字段
        showCalculateUnit: ['warehouseTotalNumber', 'onwayTotalNumber', 'productTotalNumber', 'occupyTotalNumber',
          'saleUsableNumber', 'purchaseUsableNumber', 'usableNumber', 'warehouseNumber', 'occupyNumber',
          'onwayNumber', 'productNumber'
        ], //需要显示(采购)计量单位的字段(货品库存相关数量)
        showWeightUnit: ['weight', 'total_weight'], //需要显示重量单位的字段

        //控制开关(用于控制子组件是否加载,若没有会和当前页面其他地方冲突)
        show_coustomerComponent: false, //控制加载客户组件
        show_warehouseComponent: false, //控制加载仓库组件
        show_supplierComponent: false, //控制加载供应商组件
        show_departmentComponent: false, //控制加载部门组件
        show_personnelComponent: false, //控制加载员工选择组件
        show_productComponent: false, //控制加载货品选择组件
        show_billsComponent: false, //控制加载单据选择弹框
        show_costTypeComponent: false, //控制加载费用分类弹框
        show_previewFileComponent: false, //控制加载附件预览弹框

        //控制开关(用于控制总价是否显示)
        show_totalAmount: false, //(销售)总价格
        show_purchaseTotalAmount: false, //(采购)//总价格
        show_inventoryTotalAmount: false, //(库存)成本总价
        show_inventoryTotalSaleAmount: false, //(库存)销售总价
        show_shouldAmount: false, //(资金(收付款))应收总价
        show_realityAmount: false, //(资金(收付款))实收总价
        show_discountAmount: false, //(资金(收付款))折扣总价
        show_expenceTotalAmount: false, //(资金(报销))报销总价
        show_invoiceTotalAmount: false, //(资金(发票))发票总价

        //控制开关(控制可编辑当前表格数据)
        is_edit: true, //控制表格字段是否可编辑

        //批次号相关
        selectedBatchData: {}, //当前选择批次号数据

        //图片上传相关
        fileImgUrl: '', //图片上传路径
        fileUrl: '', //单据附件上传路径
        headers: { //请求头参数
          token: '',
        },
        uploadData: { //请求数据
          sign: '',
        },
        fileList: [], //已上传图片
        currentImgFileName: '', //当前操作的图片字段
        fileListArr: [], //已上传文件路径(附件列表)

        //loading框
        loading: '', //控制显示loading框

        //传入子组件的数据
        // 单据选择
        conditionObj: {}, //单据查询条件,内部key值可不定义(资金模块用)
        showSignArr: [], //控制显示单据分类的sign
        //费用分类
        showCostTypeArr: [], //显示费用分类标识的数据
        //附件预览
        previewFilePath: '', //需要预览的附件路径
        //货品弹框
        isOrderFind: -1, //是否为销售模块查询
        sellCondition: -1, //是否销售报价可见
        isDepot: -1, //是否参与成本核算
        bomSetConditon: -1, //是否已设置bom
        proDetailBillTab: "", //货品详情弹框单据tab页下标

        //费用分类相关数据
        costTypeObj: {}, //当前选择费用分类数据
        costTypeConditionObj: {}, //费用分类查询条件

        //其他费用相关
        show_otherCost: false,
        otherCostData: { //其他费用明细
          costType: '',
          costAmount: '',
          costNum: '',
          costRemark: '',
          costFile: ''
        }
      }
    },
    beforeCreate() {
      $this = this;
    },
    created() {
      //初始化文件上传数据
      this.initFilePathData();
      //初始化所需的数据字典数据
      this.initDictData();
    },
    computed: {
      ...mapState({
        // 表单相关
        bills_base_data: state => state.commComponent.bills_base_data, //表单填写的基本信息数据
        bills_type_data: state => state.commComponent.bills_type_data, //当前单据类型数据
        is_load_checkbill_data: state => state.commComponent.is_load_checkbill_data, //是否加载对账单信息
        customer_data: state => state.commComponent.customer_data, //单据所选客户信息
        cost_type: state => state.commComponent.cost_type, //单据所选费用分类信息
        bills_sel_detail: state => state.commComponent.bills_sel_detail, //关联单据详情数据
        bills_relvence_sign: state => state.commComponent.bills_relvence_sign, //关联单据分类标识
        WarehouseConfig: state => state.system.WarehouseConfig, //仓库数据
        ProductUnit: state => state.system.ProductUnit, //计量单位
        //权限相关
        companyparamlist: state => state.companyparamlist, //获取企业级参数
      }),
      //总金额(销售模块)
      totalAmount() {
        return this.getClassifyAmount(0);
      },
      // 应收金额(资金模块)
      shouldAmount() {
        return this.getClassifyAmount(1);
      },
      //实收金额(资金模块)
      realityAmount() {
        return this.getClassifyAmount(2);
      },
      //折扣金额(资金模块)
      discountAmount() {
        return this.getClassifyAmount(3);
      },
      //费用报销总金额(资金模块)
      expenceTotalAmount() {
        return this.getClassifyAmount(4);
      },
      // 发票费用总金额(资金模块)
      invoiceTotalAmount() {
        return this.getClassifyAmount(7);
      },
      //采购模块总金额(采购模块)
      purchaseTotalAmount() {
        return this.getClassifyAmount(5);
      },
      //库存模块成本总金额(库存模块)
      inventoryTotalAmount() {
        return this.getClassifyAmount(6);
      },
      //库存模块成本总金额(库存模块)
      inventoryTotalSaleAmount() {
        return this.getClassifyAmount(8);
      },
      //附件列表
      fileListData() {
        return function(data) {
          return this.getFileArr(data);
        };
      },
    },
    watch: {
      // 监听操作类型发生改变
      operateType: {
        handler(newVal) {
          if (newVal == 2) { //预览
            this.is_edit = false;
          } else { //新增修改
            this.is_edit = true;
          }
        }
      },
      //监听父组件传入的系统表头字段数据
      sysTableData: {
        handler(newVal) {
          //初始化表格信息
          this.initSysTableData(newVal);
        },
        immediate: true
      },
      //监听父组件的自定义表格信息
      cusTableData: {
        handler(newVal) {
          //初始化表格信息
          this.initCusTableData(newVal);
        },
        immediate: true
      },
      //监听父组件是否需要提交当前信息
      isCommitFormData(newVal) {
        if (this.billsTypeSign == 5 || this.billsTypeSign == 8) {
          //判断是否有满足需要结束源单但未勾选的
          var needCheckeOriginNum = 0; //需要勾选源单的数据
          this.nodeSysTableVal.forEach((item, index) => {
            if (item.current_amount < item.not_amount && !item.is_finish_origin) {
              needCheckeOriginNum++;
            }
          })

          //判断是否存在需要提示
          if (needCheckeOriginNum > 0) {
            this.$confirm('当前单据明细中存在本次交易金额小于未结算金额且未勾选结束源单的明细数据, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              //验证节点信息规范性
              this.commitNodeTableMsg();
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消提交',
                duration: this.elDuration
              });
            });
          } else {
            //验证节点信息规范性
            this.commitNodeTableMsg();
          }
        } else {
          //验证节点信息规范性
          this.commitNodeTableMsg();
        }
      },
      //监听是否清空当前子组件数据
      isClearFormData() {
        //调用清除信息方法
        this.clearNodeFormMsg();
      },
      //监听基本数据发生变化
      bills_base_data: {
        handler(newVal) {
          //处理相关表格业务
          this.disposeTableData(newVal);
        },
        deep: true
      },
      // 监听回显单据数据(修改/预览)
      originalBillData(newVal) {
        this.getDefaultTableData(newVal, this.billsTypeSign, 0);
      },
      //监听单据详情数据发生变化(引用)
      bills_sel_detail(newVal) {
        this.getDefaultTableData(newVal, this.bills_relvence_sign, 1);
      },
      //监听是否触发修改单据新增信息方法
      saveNewBillsData(newVal) {
        //新增时只需要类型参数(-1和add都只是占位符,不起任何业务作用)
        this.judgeChangeData(-1, "add", 0)
      },
    },
    methods: {
      ...mapMutations([
        'SHOW_CUSTOMERBOX', //控制客户选择弹框是否显示
        'SHOW_WAREHOUSEBOX', //控制仓库是否显示
        'SHOW_BILLINGBOX', //控制单据选择弹框是否显示
        'SHOW_SUPPLIERBOX', //控制供应商弹框是否显示
        'SHOW_DEPARTMENTBOX', //控制部门弹框是否显示
        'SHOW_PERSONNELBOX', //控制部门员工弹框是否显示
        'SHOW_PRODUCTBOX', //控制产品选择弹框是否显示
        'SHOW_COSTTYPEBOX', //控制费用分类选择弹框是否显示
        'BILLS_RELVENCE_SIGN', //当前操作的关联单据标签
        'SHOW_FILEPREVIEW', //控制显示附件预览弹框
        'BILLS_PRODUCT_DATA', //存储单据货品列表选择的数据
        'BILLS_PRODUCT_TYPE', //存储单据货品列表操作方式
      ]),
      ...mapActions([
        'getWarehouseConfig', //获取仓库数据
        'getProductUnit', //获取计量单位
      ]),

      ...mapMutations({
        getCompanyParamLists: 'getCompanyParamLists',
      }),

      /* 初始化所需数据字典数据 */
      async initDictData() {
        //**********获取仓库数据**********
        if (this.WarehouseConfig.length == 0) {
          await this.getWarehouseConfig();
        }
        //获取仓库非树形结构数据
        this.warehouseDataNoTree = this.commonJsExtend.toNoTree(this.WarehouseConfig, []);

        //**********获取计量单位数据**********
        if (this.ProductUnit.length == 0) {
          await this.getProductUnit();
        }
      },

      /* 初始化文件上传数据 */
      initFilePathData() {
        //设置请求路径
        this.fileImgUrl = baseUrl + "/orderCommon/uploadBillingProImg"; //图片上传路径
        this.fileUrl = baseUrl + "/orderCommon/uploadAccessory"; //单据附件上传路径
        //设置请求头
        this.headers.token = getCookie('token');
        //设置请求体(单据上传附件时用)
        this.uploadData.sign = this.billsTypeSign;
      },

      /* 初始化表格信息*/
      initSysTableData(data) {
        // 赋值前需清空一次
        this.nodeSysTableData = []; //清空动态表头信息
        this.nodeSysTableVal = []; //清空表格值信息
        this.nodeMustFiledName = []; //清空必填项
        this.nodeMustFiledData = []; //清空必填项数据
        this.nodeLengthAstrict = []; //清空长度验证字段数组
        this.currentTableFiledData = {}; //清空当前表格填写默认值信息
        //将表体信息存入当前页
        this.nodeSysTableData = JSON.parse(JSON.stringify(data));
        //获取默认数据格式
        this.nodeSysTableData.forEach(item => {
          if (item.system.type == 0) { //输入框
            this.$set(this.currentTableFiledData, item.system.filed_name, '');
            this.nodeLengthAstrict.push(item.system.filed_name);
          } else if (item.system.type == 3) { //数量框
            this.$set(this.currentTableFiledData, item.system.filed_name, 1);
          } else if (item.system.type == 4) { //小数框
            this.$set(this.currentTableFiledData, item.system.filed_name, '');
          } else if (item.system.type == 8) { //计算
            this.$set(this.currentTableFiledData, item.system.filed_name, '');
          } else if (item.system.type == 9) { //复选框
            this.$set(this.currentTableFiledData, item.system.filed_name, false);
          } else if (item.system.type == 10) { //折扣
            this.$set(this.currentTableFiledData, item.system.filed_name, 100);
          } else { //其他
            this.$set(this.currentTableFiledData, item.system.filed_name, "");
          }

          //获取节点当前必填字段(显示是前提条件)
          if (item.is_must == 1 && item.is_enable == 1) {
            //获取字段数组
            this.nodeMustFiledName.push(item.system.filed_name);
            //获取数据数组
            this.nodeMustFiledData.push(item);
          }
        })

        //定义各模块所需特殊字段(下面方法若用到则需判断是否有该属性)
        let defaultFile = {}
        if (this.nodeTypeCode == "SYS-NODE-TYPE-001") { //销售模块定义默认数据
          // 定义一些产品默认数据
          defaultFile = {
            //货品相关
            product_id: 0, //货品id
            weight_unit: '', //重量单位
            order_limit_num: '', //最小订购量
            exchange_base: 1, //与基础计量单位换算比例
            //价格相关
            priceAllData: [], //价格数组(销售价用)
            priceBaseData: {}, //价格基础计量单位
            priceCurData: {}, //价格当前选择的单位
            pricePurchaseData: {}, //价格采购计量单位
            priceSaleData: {}, //价格销售计量单位
            priceRange: [], //价格区间
            subsection_price_rule: "", //区间价按数据
            //批次相关
            batchAllData: [], //批次数据(库存数据)
            selectedBatchIndex: '', //当前选择批次号下标
            showBatchListBox: false, //控制显示批次号列表
          }
        } else if (this.nodeTypeCode == "SYS-NODE-TYPE-002") { //采购模块定义默认数据
          // 定义一些产品默认数据
          defaultFile = {
            //货品相关
            product_id: 0, //货品id
            weight_unit: '', //重量单位
            order_limit_num: '', //最小订购量
            exchange_base: 1, //与基础计量单位换算比例
            priceAllData: [], //价格数组
            //价格相关
            priceBaseData: {}, //价格基础计量单位
            priceCurData: {}, //价格当前选择的单位
            pricePurchaseData: {}, //价格采购计量单位
            priceSaleData: {}, //价格销售计量单位
            //批次相关
            batchAllData: [], //批次数据(库存数据)
            selectedBatchIndex: '', //当前选择批次号下标
            showBatchListBox: false, //控制显示批次号列表
          }
        } else if (this.nodeTypeCode == "SYS-NODE-TYPE-004") { //库存模块定义默认数据
          defaultFile = {
            //货品相关
            product_id: 0, //货品id
            exchange_base: 1, //与基础计量单位换算比例
            //价格相关
            priceAllData: [], //价格数组
            priceBaseData: {}, //价格基础计量单位
            priceCurData: {}, //价格当前选择的单位
            pricePurchaseData: {}, //价格采购计量单位
            priceSaleData: {}, //价格销售计量单位
            //批次相关
            batchAllData: [], //批次数据(库存数据)
            productBatch: '', //批次号
            selectedBatchIndex: '', //当前选择批次号下标
            showBatchListBox: false, //控制显示批次号列表
          }
        } else if (this.nodeTypeCode == "SYS-NODE-TYPE-005") { //资金模块默认字段
          defaultFile = {
            sign: 0, //关联单据分类
            relevance_bills: 0, //关联单据id
            all_amount: 0, //单据总金额
          }
        }
        //将所需字段默认加入
        for (let i in defaultFile) {
          this.currentTableFiledData[i] = defaultFile[i]
        }

        // 默认添加一条数据
        this.nodeSysTableVal.push(this.currentTableFiledData);

        //处理基本信息控制的字段信息
        this.initDisBaseFormData();
      },

      /* 处理基本信息控制的字段信息 */
      initDisBaseFormData() {
        //循环获取是否显示总计信息
        this.baseToalData.forEach((item, index) => {
          //判断不同处理模块
          if (this.billsTypeSign == 0) { //销售
            //判断控制参数
            if (item.system.type == 0) { //特批总金额
              //和总金额公用一个
            } else if (item.system.type == 1) { //单据总金额
              this.totalAmountLabel.sellTotalLabel = item.show_name;
              this.show_totalAmount = true;
            }
          } else if (this.billsTypeSign == 1) { //生产

          } else if (this.billsTypeSign == 2) { //库存
            //判断控制参数
            if (item.system.type == 0) { //成本总金额
              this.totalAmountLabel.depotCostTotalLabel = item.show_name;
              this.show_inventoryTotalAmount = true;
            } else if (item.system.type == 1) { //销售总金额
              this.totalAmountLabel.depotSellTotalLabel = item.show_name;
              this.show_inventoryTotalSaleAmount = true;
            }
          } else if (this.billsTypeSign == 3) { //采购
            //判断控制参数
            if (item.system.type == 0) { //总金额
              this.totalAmountLabel.purchaseTotalLabel = item.show_name;
              this.show_purchaseTotalAmount = true;
            }
          } else if (this.billsTypeSign == 5) { //收付款
            //判断控制参数
            if (item.system.type == 0) { //应收总金额
              this.totalAmountLabel.costShouldTotalLabel = item.show_name;
              this.show_shouldAmount = true;
            } else if (item.system.type == 1) { //实收总金额
              this.totalAmountLabel.costRealityAmountLabel = item.show_name;
              this.show_realityAmount = true;
            } else if (item.system.type == 2) { //折扣总金额
              this.totalAmountLabel.costDiscountAmountLabel = item.show_name;
              this.show_discountAmount = true;
            }
          } else if (this.billsTypeSign == 6) { //报销
            //判断控制参数
            if (item.system.type == 3) { //总金额
              this.totalAmountLabel.expenseTotalAmountLabel = item.show_name;
              this.show_expenceTotalAmount = true;
            }
          } else if (this.billsTypeSign == 8) { //发票
            //判断控制参数
            if (item.system.type == 3) { //总金额
              this.totalAmountLabel.invoiceTotalAmountLabel = item.show_name;
              this.show_invoiceTotalAmount = true;
            }
          }
        })
      },

      /* 初始化表格自定义信息(未做) */
      initCusTableData(data) {
        return
        //循环前清空数组
        this.nodeCusTableData = []; //清空节点自定义表头信息
        this.nodeCusTableVal = []; //清空节点自定义表格内容
        this.currentTableCusFiledData = {}; //清空节点自定义表格字段信息
        //将自定义数据存入data
        this.nodeCusTableData = data;
        //判断数据类型
        if (data && data.length > 0) {
          //
          data.forEach((item, index) => {
            let key = "filed" + index.toString();
            if (item.type == 0) { //输入框
              this.currentTableCusFiledData[key] = "";
            }
          })
          //默认添加一条数据
          this.nodeCusTableVal.push(this.currentTableCusFiledData);
        }
        // // 判断是否有数据
        // if(data.length>0){
        //   data.forEach(item=>{
        //     //定义接收自定义值的对象
        //     let cusdata = {
        //       custom_data:item,
        //       table_id:item.id,
        //       table_value:''
        //     }
        //     this.nodeCusTableVal.push(cusdata)
        //   })
        // }
      },

      /* 获取默认表格数据(回显时用) type:0(修改,预览)  1(引用)*/
      async getDefaultTableData(data, sign, type) {
        //判断当前操作类型(预览下不可编辑)
        if (this.operateType == 2) {
          this.is_edit = false; //设置不可编辑
          this.isSpecialCheckBox = true;
        }
        //定义接受引入的货品信息
        let productInfoData = [];
        // 判断引入的是哪种单据
        if (sign == 0 || sign == 2 || sign == 3) { //销售,库存,采购
          productInfoData = data.productInfos;
        } else if (sign == 1) { //生产(生产模块需要处理字段)
          // 判断是引入成品还是引入物料
          if (this.bills_type_data.code == 'DOC-M-015') { //成品入库单(引入生产主表货品)
            productInfoData = this.disposeMesProduct(data);
          } else { //其他类型(引入生产物料)
            productInfoData = this.disposeMesMaterial(data, 0);
          }
        } else if (sign == 5) { //资金模块(收付款)
          productInfoData = data.subItems;
        } else if (sign == 6) { //资金模块(报销)
          productInfoData = data.costBillsExpenseSubitem;
        } else if (sign == 7) { //生产模块(工序执行单)
          productInfoData = this.disposeMesMaterial(data.mesProcessList, 1);
        } else if (sign == 8) { //发票单模块
          productInfoData = data.subItems;
        } else if (sign == 9) { //对账模块
          this.getBillingData(data.costAccountSubitems, 9);
          return
        }
        //清空原数组
        this.nodeSysTableVal = [];
        //定义一个仓库id数组
        let houseIdArr = [];
        //定义一个接受货品id的数组
        let productIdArr = []; //分仓库
        let productIdArrAll = []; //未分仓库(所有的)
        //获取临时仓数据
        let tempWarehouse = this.commonJsExtend.getDictMatchingDataByCode("DICT-SDF-003", this.warehouseDataNoTree);
        // 循环获取当前节点所需数据
        productInfoData.forEach((item, index) => {
          //定义一个新的对象接受信息,不然会出现拷贝问题,不能放循环体外面
          let itemObj = JSON.parse(JSON.stringify(this.currentTableFiledData));
          //处理当前货品数据非驼峰和驼峰字段(不可使用公共方法)
          let curItemObj = {}; //存放有驼峰和非驼峰字段的对象
          for (let i in item) {
            //获取驼峰格式
            let curToHump = this.commonJsExtend.toHump(i);
            curItemObj[curToHump] = item[i];
            //获取非驼峰格式
            let curHump = this.commonJsExtend.toLine(i);
            curItemObj[curHump] = item[i];
          }

          // 循环获取当前界面所需值
          for (let i in itemObj) {
            if (curItemObj.hasOwnProperty(i)) {
              itemObj[i] = curItemObj[i];
            }
          }

          //获取产品区间价
          if (itemObj.hasOwnProperty('priceRange')) {
            let rangeStr = itemObj.subsection_price_rule;
            let rangeArr = []
            if (!!rangeStr) {
              let rangeItem = rangeStr.split("#####");
              rangeItem.forEach((item, index) => {
                rangeArr.push(item.split("##"))
              })
            }
            itemObj.priceRange = rangeArr;
          }

          //处理批次号
          if (itemObj.hasOwnProperty('productBatch') && this.operateType == 1) { //判断是否有批次号字段
            //判断是否有批次号数据
            if (!curItemObj.productBatch) {
              // 判断循环的货品若没有则根据模块判断
              if (this.billsTypeSign == 3) { //采购模块(没有直接生成)
                itemObj.productBatch = this.batchNumber;
              }
            } else if (this.billsTypeSign == 2) { //库存模块(需根据分类判断)
              if (this.billsClassify == 1) { //入库单(没有直接生成)
                itemObj.productBatch = this.batchNumber;
              }
            }
          }

          //判断是否有源单数量字段(基本信息有源单才有值)
          if (itemObj.hasOwnProperty('origin_num') && this.bills_base_data.hasOwnProperty("relevance_bills") &&
            !!this.bills_base_data.relevance_bills) {
            // 根据类型处理源单数量
            if (type == 0) { //修改,预览
              itemObj.origin_num = itemObj.origin_num;
            } else if (type == 1) { //引用
              itemObj.origin_num = itemObj.number;
            }
          }

          //处理引入单据数量
          if (type == 1) { //引用单据时才计算数量
            if (this.nodeTypeCode == 'SYS-NODE-TYPE-001') { //销售模块(只能引入报价单)
              // 判断处理的字段
              if (itemObj.hasOwnProperty("number") && curItemObj.successOrder) { //数量字段
                itemObj.number = curItemObj.number - curItemObj.successOrder;
              }
            } else if (this.nodeTypeCode == 'SYS-NODE-TYPE-002') { //采购模块(可引入销售单,采购单,生产单)
              if (this.bills_relvence_sign == 0) { //引入销售单
                // 判断处理的字段
                if (itemObj.hasOwnProperty("number") && curItemObj.successPurchase) { //数量字段(数量=原数量-已采购数量)
                  itemObj.number = curItemObj.number - curItemObj.successPurchase;
                }
              } else if (this.bills_relvence_sign == 1) { //引入生产计划单
                // 判断处理的字段
                if (itemObj.hasOwnProperty("number") && curItemObj.already_req_num) { //数量字段(数量=原数量-已采购数量)
                  itemObj.number = curItemObj.number - curItemObj.already_req_num;
                }
              } else if (this.bills_relvence_sign == 3) { //引入采购单
                // 判断处理的字段
                if (itemObj.hasOwnProperty("number") && curItemObj.successPurchase) { //数量字段(数量=原数量-已采购数量)
                  itemObj.number = curItemObj.number - curItemObj.successPurchase;
                }
              }
            } else if (this.nodeTypeCode == 'SYS-NODE-TYPE-004') { //库存模块(可引入生产单,采购单,销售单,出入库单(质检,退货))
              if (this.billsClassify == 0) { //入库单
                //判断源单
                if (this.bills_relvence_sign == 1 || this.bills_relvence_sign == 3) { //采购
                  // 判断处理的字段
                  if (itemObj.hasOwnProperty("number") && curItemObj.successNumber) { //数量字段
                    itemObj.number = curItemObj.number - curItemObj.successNumber;
                  }
                } else if (this.bills_relvence_sign == 2) { //出入库

                }
              } else if (this.billsClassify == 1) { //出库单
                //判断源单
                if (this.bills_relvence_sign == 0) { //销售
                  // 判断处理的字段
                  if (itemObj.hasOwnProperty("number") && curItemObj.successNumber) { //数量字段
                    itemObj.number = curItemObj.number - curItemObj.successNumber;
                  }
                }
              } else if (this.billsClassify == 9) { //退货单
                if (itemObj.hasOwnProperty("number")) {
                  itemObj.number = 0;
                }
              }
            }
          }

          //引入时若引入单据未选择仓库则默认使用基本信息已选仓库
          if (this.billsTypeSign == 2 && this.billsClassify == 5) { //调拨单
            //未处理
          } else {
            if (!curItemObj.hasOwnProperty('house_id') || !curItemObj.house_id) {
              if (this.bills_base_data.hasOwnProperty('house_id')) {
                curItemObj.house_id = this.bills_base_data.house_id;
              }
            }
          }

          //当前循环的货品所在仓库在houseIdArr的下标(处理引入的货品库存信息)
          let houseIdArrIndex = 0;
          //判断获取仓库数据和所在houseIdArr下标
          if (curItemObj.hasOwnProperty('house_id')) {
            if (houseIdArr.includes(curItemObj.house_id)) {
              houseIdArr.forEach((itemJ, indexJ) => {
                if (itemJ == curItemObj.house_id) {
                  houseIdArrIndex = indexJ;
                }
              })
            } else {
              houseIdArr.push(curItemObj.house_id)
            }
          }
          //定义对应仓库的货品数组(第一次时默认设置为数组)
          if (!productIdArr[houseIdArrIndex]) {
            productIdArr[houseIdArrIndex] = [] //使用push需要初始化为数组
          }
          // 将对应仓库货品添加至对应数组(资金模块不需要)
          if (sign == 1 || sign == 7) { //引入生产单,该处用curItemObj不用itemObj
            productIdArr[houseIdArrIndex].push(curItemObj.product_info_id);
            productIdArrAll.push(curItemObj.product_info_id);
            //引入时默认添加关联id
            itemObj.import_product_id = curItemObj.id;
          } else if (sign == 0 || sign == 2 || sign == 3) { //销售,库存,采购
            productIdArr[houseIdArrIndex].push(curItemObj.productId);
            productIdArrAll.push(curItemObj.productId);
            //引入时默认添加关联id
            itemObj.import_product_id = curItemObj.id;
          }

          // 处理数据字典中存储的id数据(后端未返回)
          if (itemObj.hasOwnProperty('house_id')) { //处理仓库
            this.warehouseDataNoTree.forEach((itemJ, indexJ) => {
              if (itemJ.id == curItemObj.house_id) {
                itemObj.house_id = itemJ.name;
              }
            })
          }

          //修改/预览时获取关联信息id(主表关联表)
          if (this.operateType == 1 || this.operateType == 2) {
            itemObj.productInfoReserveId = curItemObj.id;
          }

          //*******库存相关*******
          //退货时处理实际退货数量
          if (type == 1) { //引用单据时
            if (itemObj.hasOwnProperty('realy_return_num')) {
              itemObj.realy_return_num = itemObj.number;
            }
          }

          //将数据存入
          this.nodeSysTableVal.push(itemObj);
        })

        //获取货品信息
        if (type == 1 && productIdArrAll.length > 0) { //(处理引入时无数据的字段)(引用时触发)
          await this.getProductInfoByIdStr(productIdArrAll.join(","), 1);
        } else if (type == 0) { //处理货品多计量信息(修改,预览)
          await this.getProductInfoByIdStr(productIdArrAll.join(","), 2);
        }

        //根据多仓库id获取对应货品库存信息
        let $this = this;
        await setTimeout(function() {
          houseIdArr.forEach((itemJ, indexJ) => {
            if (productIdArr[indexJ]) {
              //判断特殊单据类型处理
              if ($this.billsTypeSign == 2 && $this.billsClassify == 1 && [1, 7].includes($this
                  .bills_relvence_sign)) { //出库单且引入的是生产单(物料)
                $this.getProWarehouseNumber(productIdArr[indexJ].join(","), itemJ, 0);
              } else {
                $this.getProWarehouseNumber(productIdArr[indexJ].join(","), itemJ, 1);
              }
            }
          })
        }, 1000)

        //调用获取总金额数据
        if (this.operateType != 0) { //新增时不用调
          this.getOldTotalAmountData(data, sign, 0);
        }
        this.$forceUpdate();
      },

      /* 处理生产单成品字段对应库存单据字段 */
      disposeMesProduct(data) {
        let corresData = JSON.parse(JSON.stringify(data));
        //定义需要匹配的字段(两边字段名称不一样的)
        let mesCorresObj = {
          product_id: 'product_info_id', //货品id
          product_code: 'productInfo.product_code', //货品编号
          specifications: 'productInfo.specifications', //规格型号
          name: 'productInfo.name', //货品名称
          number: 'plan_num', //数量
          calculateName: 'calculate_name', //计量单位
          remarks: 'remark', //备注信息
        }
        //循环获取对应数据
        for (let i in mesCorresObj) {
          //判断当前界面有的数据
          if (this.currentTableFiledData.hasOwnProperty(i)) {
            let corresKey = mesCorresObj[i];
            // 判断是对象还是对象的包含
            if (corresKey.indexOf('.') != -1) {
              let corresKeyArr = corresKey.split('.');
              corresData[i] = corresData[corresKeyArr[0]][corresKeyArr[1]]
            } else {
              corresData[i] = corresData[corresKey]
            }
          }
        }
        return [corresData];
      },

      /* 处理生产单物料字段对应采购单关系 type(0:生产单所有物料 1:生产单工序指定物料)*/
      disposeMesMaterial(data, type) {
        // 判断类型
        if (type == 0) { //生产单所有物料
          let corresData = this.commonJsExtend.toNoTree(JSON.parse(JSON.stringify(data.mesMaterial)), []);
          //判断有无倒冲物料
          if (corresData.length > 0 && corresData[0].hasOwnProperty("is_pour_flush")) {
            corresData = corresData.filter(item => item.is_pour_flush == 0)
          }
          //定义需要匹配的数据字段
          let mesCorresObj = {
            product_id: 'product_info_id', //货品id
            name: 'product_info_name', //货品名称
            cost_price: 'price', //成本价
            number: 'predict_get_num', //数量(领料数量)
            calculateName: 'calculateName' //计量单位
          }
          //循环获取对应数据
          corresData.forEach((item, index) => {
            for (let i in mesCorresObj) {
              if (this.currentTableFiledData.hasOwnProperty(i)) {
                let corresKey = mesCorresObj[i];
                item[i] = item[corresKey]
              }
            }
          })
          return corresData;
        } else if (type == 1) { //生产工序指定物料
          let resultData = []; //定义返回数据
          let corresData = JSON.parse(JSON.stringify(data)); //转换一次数据
          corresData.forEach((itemI, indexI) => {
            //转换非树形结构
            let corresDataItem = this.commonJsExtend.toNoTree(JSON.parse(JSON.stringify(itemI.assignMaterialList)),
              []);
            let mesCorresObj = {};
            mesCorresObj = { //工序指定物料
              product_id: 'productInfoId', //货品id
              product_code: 'productInfoCode', //货品编码
              name: 'productInfoName', //货品名称
              cost_price: 'price', //成本价
              number: 'needNum', //数量(领料数量)
              calculateName: 'calculateName' //计量单位
            }

            //循环获取对应数据
            corresDataItem.forEach((itemJ, indexJ) => {
              for (let i in mesCorresObj) {
                if (this.currentTableFiledData.hasOwnProperty(i)) {
                  let corresKey = mesCorresObj[i];
                  itemJ[i] = itemJ[corresKey]
                }
              }
              console.log(itemI.workNum)
              console.log(itemJ.number)
              itemJ.number = itemJ.number * itemI.workNum
              //获取所需数据
              resultData.push(itemJ);
            })

          })
          return resultData;
        }
      },

      /* 获取旧的总金额数据(修改时用) type:(0:回显时使用 1:调用修改主表信息后使用)*/
      getOldTotalAmountData(data, sign, type) {
        if (sign == 0) { //销售
          // 判断获取旧数据的类型
          if (type == 0) {
            //获取总金额
            this.totalAmountData.totalAmount = data.saleOrderMain.money;
            // 判断是否启用特惠金额(只有在初次会显时用到)
            if (!!data.saleOrderMain.discount) { //启用特惠金额
              this.specialAmount = data.saleOrderMain.discount; //获取默认优惠价
              this.isSpecial = true; //默认勾选复选框
              //判断当前操作模式
              if (this.operateType == 1) { //修改
                this.isSpecialInput = true; //输入框可编辑
                this.isSpecialCheckBox = true; //复选框可选
              } else if (this.operateType == 2) { //预览
                this.isSpecialInput = false; //输入框可编辑
                this.isSpecialCheckBox = false; //复选框不可选
              }
            } else { //未启用特惠金额
              this.specialAmount = '';
              this.isSpecial = false; //默认勾选复选框
              //判断当前操作模式
              if (this.operateType == 1) { //修改
                this.isSpecialInput = true; //输入框可编辑
                this.isSpecialCheckBox = true; //复选框可选
              } else if (this.operateType == 2) { //预览
                this.isSpecialInput = false; //输入框不可编辑
                this.isSpecialCheckBox = false; //复选框不可选
              }
            }
          } else if (type == 1) {
            this.totalAmountData.totalAmount = this.totalAmount;
          }
        } else if (sign == 2) { //库存
          // 判断获取旧数据的类型
          if (type == 0) {
            this.totalAmountData.inventoryTotalAmount = data.depotMain.totalMoney; //成本总金额
            this.totalAmountData.inventoryTotalSaleAmount = data.depotMain.totalPriceMoney; //销售总金额
          } else if (type == 1) {
            this.totalAmountData.inventoryTotalAmount = this.inventoryTotalAmount; //成本总金额
            this.totalAmountData.inventoryTotalSaleAmount = this.inventoryTotalSaleAmount; //销售总金额
          }
        } else if (sign == 3) { //采购
          // 判断获取旧数据的类型
          if (type == 0) {
            this.totalAmountData.purchaseTotalAmount = data.purchaseOrderMain.money;
          } else if (type == 1) {
            this.totalAmountData.purchaseTotalAmount = this.purchaseTotalAmount;
          }
        } else if (sign == 5) { //收付款
          // 判断获取旧数据的类型
          if (type == 0) {
            this.totalAmountData.shouldAmount = data.costBillsMain.shouldAmount;
            this.totalAmountData.realityAmount = data.costBillsMain.realityAmount;
            this.totalAmountData.discountAmount = data.costBillsMain.discountAmount;
          } else if (type == 1) {
            this.totalAmountData.shouldAmount = this.shouldAmount;
            this.totalAmountData.realityAmount = this.realityAmount;
            this.totalAmountData.discountAmount = this.discountAmount;
          }
        } else if (sign == 6) { //报销
          // 判断获取旧数据的类型
          if (type == 0) {
            this.totalAmountData.expenceTotalAmount = data.costBillsExpense.totalAmount;
          } else if (type == 1) {
            this.totalAmountData.expenceTotalAmount = this.expenceTotalAmount;
          }
        } else if (sign == 8) { //发票
          // 判断获取旧数据的类型
          if (type == 0) {
            this.totalAmountData.invoiceTotalAmount = data.costBillsInvoice.totalAmount;
          } else if (type == 1) {
            this.totalAmountData.invoiceTotalAmount = this.expenceTotalAmount;
          }
        }
      },

      /* 获取当前操作的行数据(修改时用) type(0:普通类型修改  1:复选框类型修改) fileName(对应字段名)*/
      getCurOperateRowData(index, type, fileName) {
        //获取正在操作的旧数据
        this.currentOldRowData = JSON.parse(JSON.stringify(this.nodeSysTableVal[index]));
        //判断类型
        if (type == 1) {
          if (this.currentOldRowData[fileName] == 0) {
            this.currentOldRowData[fileName] = 1;
          } else if (this.currentOldRowData[fileName] == 1) {
            this.currentOldRowData[fileName] = 0;
          }
        }
      },

      /* 判断当前修改的数据(修改时用) type(0:新增 1:修改 2:删除)*/
      judgeChangeData(index, fileName, type) {

        // 将数据存储到vuex
        let tempData = JSON.parse(JSON.stringify(this.nodeSysTableVal))
        this.BILLS_PRODUCT_DATA(tempData);
        this.BILLS_PRODUCT_TYPE(1); //设置操作方式为修改

        //判断是否为修改界面(其他界面不执行此方法)
        if (this.operateType != 1) {
          return
        }

        let updateProductData = []; //定义需要修改的信息
        let logContentData = []; //定义一个日志内容字段

        // 判断是哪种类型
        if (type == 0) { //添加
          //验证数据必填项
          if (!this.judgeMustFiledIsFill()) {
            return;
          }
          //获取数据
          let logContent = "";
          this.nodeSysTableVal.forEach((item, index) => {
            if (!item.hasOwnProperty('productInfoReserveId')) {
              updateProductData.push(this.getDictionarIdMsg(item));
              //判断模块类型
              if ([0, 2, 3].includes(this.billsTypeSign)) { //销售,采购,库存
                logContent = logContent + "新增了货品编号为【" + item.product_code + "】的货品;"
              } else if ([5, 6, 8].includes(this.billsTypeSign)) { //收付款,报销,发票
                let typeBillsModel = "";
                //判断关联的单据类型
                if (item.sign == 0) {
                  typeBillsModel = "销售";
                } else if (item.sign == 1) {
                  typeBillsModel = "生产执行单";
                } else if (item.sign == 3) {
                  typeBillsModel = "采购";
                } else if (item.sign == 7) {
                  typeBillsModel = "生产工序单";
                }
                logContent = logContent + "新增了明细【" + typeBillsModel + "】模块单据编号为【" + item.bills_no + "】的单据;"
              }
            }
          })
          //判断单据总金额是否发生改变
          let totalLog = this.judgeTotalMoneyChange();
          if (totalLog != "") {
            logContent = logContent + "被影响的关联信息有:" + totalLog;
          }
          //获取日志信息
          logContentData.push(logContent)
          //判断没有新增信息的时候不发送请求
          if (updateProductData.length == 0) {
            //设置添加已处理
            this.$emit("getOperateTableData", 1);
            return
          }
        } else if (type == 1) { //修改
          //验证数据必填项
          if (!this.judgeMustFiledIsFill()) {
            return;
          }
          //定义当前操作且改变后的数据
          let currentRowData = this.nodeSysTableVal[index];
          //判断是否为新增的数据修改(新增数据修改不需要发请求)
          if (!currentRowData.productInfoReserveId) {
            return
          }
          //定义被改变字段影响的字段(不包括本身的字段)
          let differFileArr = [];
          //循环获取有区别的字段
          for (let i in currentRowData) {
            if (JSON.stringify(currentRowData[i]) != JSON.stringify(this.currentOldRowData[i]) && i != fileName) {
              differFileArr.push(i)
            }
          }
          //获取当前操作的列数据
          let curFileData = this.getNodeTableDataBuyFileName(fileName);
          let logContent = ""; //定义日志内容
          //判断列类型(有需要特殊处理的类型需特殊处理)
          if (curFileData.system.type == 12) { //附件框
            let newFileArr = this.getFileArr(currentRowData[fileName]); //新的附件数组
            let oldFileArr = this.getFileArr(this.currentOldRowData[fileName]); //旧的附件数组
            //判断是新增附件还是修改附件
            if (newFileArr.length > oldFileArr.length) { //新增
              //获取旧数组
              let oldFilePath = [];
              oldFileArr.forEach((item, index) => {
                oldFilePath.push(item.path);
              })
              //获取添加的附件数据
              let addFile = newFileArr.filter(item => !oldFilePath.includes(item.path))
              logContent = "新增了【明细" + (index + 1) + "】的附件,附件名:" + addFile[0].name;
            } else { //删除
              let newFilePath = [];
              newFileArr.forEach((item, index) => {
                newFilePath.push(item.path);
              })
              //获取删除的附件数据
              let deleteFile = oldFileArr.filter(item => !newFilePath.includes(item.path))
              logContent = "删除了【明细" + (index + 1) + "】的附件,附件名:" + deleteFile[0].name;
            }
          } else { //其他
            // 判断当前单据类型
            if (['SYS-NODE-TYPE-001', 'SYS-NODE-TYPE-002', 'SYS-NODE-TYPE-004'].includes(this
                .nodeTypeCode)) { //销售,采购,库存关联表为货品
              //获取日志相关信息
              logContent = "更改了货品编号【" + currentRowData.product_code + "】的【" + curFileData.show_name + "】信息,更改前:" +
                this.currentOldRowData[fileName] + ",更改后:" + currentRowData[fileName] + ";";
            } else if (this.nodeTypeCode == 'SYS-NODE-TYPE-005') { //收付款单关联表为单据
              //获取日志相关信息
              logContent = "更改了单据编号【" + currentRowData.bills_no + "】的【" + curFileData.show_name + "】信息,更改前:" +
                this.currentOldRowData[fileName] + ",更改后:" + currentRowData[fileName] + ";";
            }
          }
          //判断有无改变字段后被影响的字段
          if (differFileArr.length > 0) {
            logContent = logContent + "被影响的关联信息有:"
            differFileArr.forEach((item, index) => {
              let curItem = this.getNodeTableDataBuyFileName(item);
              logContent = logContent + "【" + curItem.show_name + "】,更改前:" + this.currentOldRowData[item] +
                ",更改后:" +
                currentRowData[item] + ";"
            })
          }
          // 判断总金额信息有无发生变化
          logContent = logContent + this.judgeTotalMoneyChange();
          //获取日志信息
          logContentData.push(logContent);
          //获取需要修改的相关数据
          let updateProductObj = {
            id: currentRowData.productInfoReserveId, //获取修改的数据id
          }
          //获取修改字段的信息
          updateProductObj[fileName] = currentRowData[fileName]; //获取修改的字段信息
          //获取被影响的字段信息
          if (differFileArr.length > 0) {
            differFileArr.forEach((item, index) => {
              updateProductObj[item] = currentRowData[item];
            })
          }

          // 处理数据字典相关信息
          if (fileName == "calculateName") { //计量单位
            let calculateArr = this.ProductUnit.filter(item => item.name == updateProductObj[fileName]);
            let calculateId = null;
            if (calculateArr.length > 0) {
              updateProductObj.calculate_type = calculateArr[0].id;
            }
          }

          updateProductData.push(updateProductObj);
        } else if (type == 2) { //删除
          let updateProductObj = {};
          //获取需要删除的数据id
          updateProductObj.id = this.currentOldRowData.productInfoReserveId; //获取修改的数据id
          updateProductData.push(updateProductObj);
          //定义日志内容
          let logContent = "";
          //判断模块类型
          if ([0, 2, 3].includes(this.billsTypeSign)) { //销售,采购,库存
            logContent = "删除了货品编号为【" + this.currentOldRowData.product_code + "】的货品;"
          } else if ([5, 6, 8].includes(this.billsTypeSign)) { //收付款,报销,发票
            let typeBillsModel = "";
            //判断关联的单据类型
            if (this.currentOldRowData.sign == 0) {
              typeBillsModel = "销售";
            } else if (this.currentOldRowData.sign == 1) {
              typeBillsModel = "生产执行单";
            } else if (this.currentOldRowData.sign == 3) {
              typeBillsModel = "采购";
            } else if (this.currentOldRowData.sign == 7) {
              typeBillsModel = "生产工序单";
            }
            logContent = logContent + "删除了明细【" + typeBillsModel + "】模块单据编号为【" + this.currentOldRowData.bills_no +
              "】的单据;"
          }
          //判断单据总金额是否发生改变
          let totalLog = this.judgeTotalMoneyChange();
          if (totalLog != "") {
            logContent = logContent + "被影响的关联信息有:" + totalLog;
          }
          //获取日志信息
          logContentData.push(logContent);
        }

        //定义请求后端数据的方法
        let updateData = {
          billsId: this.generateBillsId, //单据主id
          logContent: JSON.stringify(logContentData), //日志信息
          statusId: 46, //节点id
          type: type, //0.添加 1.修改 2.删除
        }

        // 判断是哪种单据
        if (this.nodeTypeCode == 'SYS-NODE-TYPE-001') { //销售模块单据
          //获取货品信息
          updateData.saleOrderProInfo = JSON.stringify(updateProductData);
          //发送请求
          sellApi.updateOrderProduct(updateData).then(res => {
            // this.loading.close();
            if (res.code == 200) {
              let succrssMsg = "";
              if (type == 0) { //新增(新增数据后关闭弹框,所以直接提示单据信息)
                succrssMsg = "单据信息更新成功!"
              } else if (type == 1) { //修改
                succrssMsg = '货品编号为【' + this.currentOldRowData.product_code + '】的信息修改成功!';
              } else if (type == 2) { //删除
                succrssMsg = '货品编号为【' + this.currentOldRowData.product_code + '】的信息删除成功!';
              }
              this.$message({
                type: 'success',
                message: succrssMsg,
                duration: this.elDuration
              })
              //设置添加已处理
              this.$emit("getOperateTableData", 1)
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.nodeTypeCode == 'SYS-NODE-TYPE-002') { //采购模块单据
          //获取货品信息
          updateData.productInfos = JSON.stringify(updateProductData);
          //发送请求
          purchaseApi.updateOrderProduct(updateData).then(res => {
            // this.loading.close();
            if (res.code == 200) {
              let succrssMsg = "";
              if (type == 0) { //新增(新增数据后关闭弹框,所以直接提示单据信息)
                succrssMsg = "单据信息更新成功!"
              } else if (type == 1) { //修改
                succrssMsg = '货品编号为【' + this.currentOldRowData.product_code + '】的信息修改成功!';
              } else if (type == 2) { //删除
                succrssMsg = '货品编号为【' + this.currentOldRowData.product_code + '】的信息删除成功!';
              }
              this.$message({
                type: 'success',
                message: succrssMsg,
                duration: this.elDuration
              })
              //设置添加已处理
              this.$emit("getOperateTableData", 1)
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.nodeTypeCode == 'SYS-NODE-TYPE-004') { //库存模块单据
          //获取货品信息
          updateData.depotMainProNum = JSON.stringify(updateProductData);
          //发送请求
          stockApi.updateDepotMainProNum(updateData).then(res => {
            // this.loading.close();
            if (res.code == 200) {
              let succrssMsg = "";
              if (type == 0) { //新增
                succrssMsg = "单据信息更新成功!"
              } else if (type == 1) { //修改
                succrssMsg = '货品编号为【' + this.currentOldRowData.product_code + '】的信息修改成功!';
              } else if (type == 2) { //删除
                succrssMsg = '货品编号为【' + this.currentOldRowData.product_code + '】的信息删除成功!';
              }
              this.$message({
                type: 'success',
                message: succrssMsg,
                duration: this.elDuration
              })
              //设置添加已处理
              this.$emit("getOperateTableData", 1)
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.nodeTypeCode == 'SYS-NODE-TYPE-005') { //资金模块单据
          if (this.billsTypeSign == 5) { //收付款模块
            //获取单据信息
            updateData.costBillsSubitem = JSON.stringify(updateProductData);
            costApi.updateCostBillsSubItem(updateData).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '单据信息修改成功!',
                  duration: this.elDuration
                })
                //设置添加已处理
                this.$emit("getOperateTableData", 1)
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          } else if (this.billsTypeSign == 6) { //报销模块
            //获取明细信息
            updateData.costBillsExpenseSubitem = JSON.stringify(updateProductData);
            costApi.updateBillsExpenseSubItem(updateData).then(res => {
              // this.loading.close();
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '单据信息修改成功!',
                  duration: this.elDuration
                })
                //设置添加已处理
                this.$emit("getOperateTableData", 1)
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          } else if (this.billsTypeSign == 8) { //发票模块
            //获取明细信息
            updateData.costBillsInvoiceSubitem = JSON.stringify(updateProductData);
            costApi.updateCostBillsInvoiceSubitem(updateData).then(res => {
              // this.loading.close();
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '单据信息修改成功!',
                  duration: this.elDuration
                })
                //设置添加已处理
                this.$emit("getOperateTableData", 1)
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }
        }
      },

      /* 处理明细数据发生变化时的关联数据 */
      getOnInputRowData(index, fileName) {
        //处理外币单价
        if (fileName == "foreign_price") {
          //处理明细数据(判断明细是否有价格字段,基本信息是否有汇率字段)
          if (this.nodeSysTableVal[index].hasOwnProperty("price") && this.bills_base_data.hasOwnProperty(
              "exchange_rage")) {
            //判断是否有汇率
            if (!!this.bills_base_data.exchange_rage) {
              //根据汇率计算出单价
              let price = this.nodeSysTableVal[index].foreign_price * this.bills_base_data.exchange_rage;
              //处理小数位数
              price = this.commonJsExtend.getDecimalsLen(price, 3);
              //将处理好的值赋值到数据
              this.nodeSysTableVal[index].price = price;
            }
          }
        }
      },

      /* 判断单据总金额是否发生改变(修改界面使用) */
      judgeTotalMoneyChange() {
        let logContent = "";
        //判断总金额相关数据是否受影响
        if (this.billsTypeSign == 0) { //销售
          if (this.totalAmountData.totalAmount != this.totalAmount) {
            logContent = logContent + "【单据总金额】,更改前:" + this.totalAmountData.totalAmount + ",更改后:" + this.totalAmount +
              ";"
            //调用修改单据主表信息方法
            this.updateBillsMainData();
            //调用获取总金额数据方法(data为占位符,不起任何业务作用)
            this.getOldTotalAmountData('data', this.billsTypeSign, 1);
          }
        } else if (this.billsTypeSign == 2) { //库存
          let num = 0;
          if (this.totalAmountData.inventoryTotalAmount != this.inventoryTotalAmount) {
            logContent = logContent + "【单据成本总金额】,更改前:" + this.totalAmountData.inventoryTotalAmount + ",更改后:" + this
              .inventoryTotalAmount + ";"
            num++
          }
          if (this.totalAmountData.inventoryTotalSaleAmount != this.inventoryTotalSaleAmount) {
            logContent = logContent + "【单据销售总金额】,更改前:" + this.totalAmountData.inventoryTotalSaleAmount + ",更改后:" + this
              .inventoryTotalSaleAmount + ";"
            num++
          }
          if (num != 0) {
            //调用修改单据主表信息方法
            this.updateBillsMainData();
            //调用获取总金额数据方法(data为占位符,不起任何业务作用)
            this.getOldTotalAmountData('data', this.billsTypeSign, 1);
          }
        } else if (this.billsTypeSign == 3) { //采购
          if (this.totalAmountData.purchaseTotalAmount != this.purchaseTotalAmount) {
            logContent = logContent + "【单据总金额】,更改前:" + this.totalAmountData.purchaseTotalAmount + ",更改后:" + this
              .purchaseTotalAmount + ";"
            //调用修改单据主表信息方法
            this.updateBillsMainData();
            //调用获取总金额数据方法(data为占位符,不起任何业务作用)
            this.getOldTotalAmountData('data', this.billsTypeSign, 1);
          }
        } else if (this.billsTypeSign == 5) { //收付款
          let num = 0;
          if (this.totalAmountData.shouldAmount != this.shouldAmount) {
            logContent = logContent + "【应收总金额】,更改前:" + this.totalAmountData.shouldAmount + ",更改后:" + this
              .shouldAmount + ";";
            num++
          }
          if (this.totalAmountData.realityAmount != this.realityAmount) {
            logContent = logContent + "【实收总金额】,更改前:" + this.totalAmountData.realityAmount + ",更改后:" + this
              .realityAmount + ";";
            num++
          }
          if (this.totalAmountData.discountAmount != this.discountAmount) {
            logContent = logContent + "【折扣总金额】,更改前:" + this.totalAmountData.discountAmount + ",更改后:" + this
              .discountAmount + ";";
            num++
          }
          if (num != 0) {
            //调用修改单据主表信息方法
            this.updateBillsMainData();
            //调用获取总金额数据方法(data为占位符,不起任何业务作用)
            this.getOldTotalAmountData('data', this.billsTypeSign, 1);
          }
        } else if (this.billsTypeSign == 6) { //报销
          if (this.totalAmountData.expenceTotalAmount != this.expenceTotalAmount) {
            logContent = logContent + "【单据总金额】,更改前:" + this.totalAmountData.expenceTotalAmount + ",更改后:" + this
              .expenceTotalAmount + ";";
            //调用修改单据主表信息方法
            this.updateBillsMainData();
            //调用获取总金额数据方法(data为占位符,不起任何业务作用)
            this.getOldTotalAmountData('data', this.billsTypeSign, 1);
          }
        } else if (this.billsTypeSign == 8) { //发票
          if (this.totalAmountData.invoiceTotalAmount != this.invoiceTotalAmount) {
            logContent = logContent + "【单据总金额】,更改前:" + this.totalAmountData.invoiceTotalAmount + ",更改后:" + this
              .invoiceTotalAmount + ";";
            //调用修改单据主表信息方法
            this.updateBillsMainData();
            //调用获取总金额数据方法(data为占位符,不起任何业务作用)
            this.getOldTotalAmountData('data', this.billsTypeSign, 1);
          }
        }
        return logContent;
      },

      /* 修改单据主表方法(日志信息已记录到货品修改中,该处不用做日志记录) */
      updateBillsMainData() {
        //定义传入后端的数据
        let updateData = {};
        //修改的主表信息
        let updateMain = {
          id: this.generateBillsId,
        }
        //判断是哪种单据
        if (this.nodeTypeCode == 'SYS-NODE-TYPE-001') { //销售模块单据
          //获取需要修改的信息
          updateMain.money = this.totalAmount; //总金额
          updateMain.discount = this.totalAmount; //特惠总金额
          //获取主表信息
          updateData.saleOrderMain = JSON.stringify(updateMain);
          //发送请求
          sellApi.updateSaleOrderMain(updateData).then(res => {
            // this.loading.close();
            if (res.code == 200) {} else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.nodeTypeCode == 'SYS-NODE-TYPE-002') { //采购模块单据
          //获取需要修改的信息
          updateMain.money = this.purchaseTotalAmount; //总金额
          //获取主表信息
          updateData.purchaseOrderMain = JSON.stringify(updateMain);
          //发送请求
          purchaseApi.updatePurchaseMain(updateData).then(res => {
            // this.loading.close();
            if (res.code == 200) {} else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.nodeTypeCode == 'SYS-NODE-TYPE-003') { //生产模块单据
          //获取主表信息
          updateData.mesMain = JSON.stringify(updateMain);
          //发送请求
          productApi.updateMesMain(updateData).then(res => {
            // this.loading.close();
            if (res.code == 200) {} else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.nodeTypeCode == 'SYS-NODE-TYPE-004') { //库存模块单据
          //获取需要修改的信息
          updateMain.total_money = this.inventoryTotalAmount; //成本总金额
          updateMain.total_price_money = this.inventoryTotalSaleAmount; //成本总金额
          //获取主表信息
          updateData.depotMain = JSON.stringify(updateMain);
          //发送请求
          stockApi.updateDepotMain(updateData).then(res => {
            if (res.code == 200) {} else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.nodeTypeCode == 'SYS-NODE-TYPE-005') { //资金模块单据
          if (this.billsTypeSign == 5) { //收付款模块
            //获取需要修改的信息
            updateMain.should_amount = this.shouldAmount; //应付金额
            updateMain.reality_amount = this.realityAmount; //实付金额
            updateMain.discount_amount = this.discountAmount; //折扣金额
            //获取主表信息
            updateData.costBillsMain = JSON.stringify(updateMain);
            //发送请求
            costApi.updateCostBillsMain(updateData).then(res => {
              // this.loading.close();
              if (res.code == 200) {} else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          } else if (this.billsTypeSign == 6) { //报销模块
            //获取需要修改的信息
            updateMain.total_amount = this.expenceTotalAmount; //总金额
            //获取主表信息
            updateData.costBillsExpense = JSON.stringify(updateMain);
            //发送请求
            costApi.updateBillsExpense(updateData).then(res => {
              // this.loading.close();
              if (res.code == 200) {} else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          } else if (this.billsTypeSign == 8) { //发票模块
            //获取需要修改的信息
            updateMain.total_amount = this.invoiceTotalAmount; //总金额
            //获取主表信息
            updateData.costBillsInvoice = JSON.stringify(updateMain);
            //发送请求
            costApi.updateCostBillsInvoice(updateData).then(res => {
              // this.loading.close();
              if (res.code == 200) {} else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }
        }
      },

      /* 根据feile_name获取节点列数据 */
      getNodeTableDataBuyFileName(fileName) {
        let result = {};
        // 循环获取数据
        this.nodeSysTableData.forEach((item, index) => {
          if (item.system.filed_name == fileName) {
            result = item;
          }
        })
        return result;
      },

      /* 判断当前单元格关联数据(弹框数据)*/
      judgeRelateData(data, index) {
        //判断当前操作类型(预览下不可编辑)
        if (this.operateType == 2) {
          return
        }
        //将现在操作的表格数据下标存入data
        this.currentTableIndex = index;
        //定义系统数据
        let systemData = data.system;
        //将当前操作的字段存入data
        this.currentDialogFileName = systemData.filed_name;
        //弹框类型
        if (systemData.type == 5) {
          if (systemData.type_val == 0) { //显示产品弹框
            //判断单据模块设置查询权限
            if (this.billsTypeSign == 0) { //销售模块
              this.sellCondition = 1; //销售报价可见
              this.isOrderFind = 1; //是否为销售查询
              this.isDepot = 0; //不参与成本核算
            } else {
              this.sellCondition = 0; //销售报价可见
              this.isOrderFind = 0; //是否为销售查询
            }
            //库存模块处理
            if (this.billsTypeSign == 2) {
              this.isDepot = 0; //不参与成本核算
              //判断入库单是否必须引入单据
              if (this.billsClassify == 0) { //入库单
                //判断入库单是否必须引入订单
                if (this.companyparamlist.param283 == "0") {
                  //判断是否仅指定采购入库单
                  if (this.companyparamlist.param344 == "0") {
                    //判断单据类型
                    if (this.bills_type_data.code == 'DOC-P-003') {
                      this.$message({
                        type: 'warning',
                        message: '管理员已设置采购入库单必须引入单据,如需修改,请联系管理员!',
                        duration: this.elDuration
                      })
                      return
                    }
                  } else {
                    this.$message({
                      type: 'warning',
                      message: '管理员已设置入库单必须引入单据,如需修改,请联系管理员!',
                      duration: this.elDuration
                    })
                    return
                  }
                }
              } else if (this.billsClassify == 1) { //出库单
                //判断出库单是否必须引入订单
                if (this.companyparamlist.param357 == "0") {
                  //判断是否仅指定销售出库单
                  if (this.companyparamlist.param358 == "0") {
                    //判断单据类型
                    if (this.bills_type_data.code == 'DOC-S-007') {
                      this.$message({
                        type: 'warning',
                        message: '管理员已设置销售出库单必须引入单据,如需修改,请联系管理员!',
                        duration: this.elDuration
                      })
                      return;
                    }
                  } else {
                    this.$message({
                      type: 'warning',
                      message: '管理员已设置出库单必须引入单据,如需修改,请联系管理员!',
                      duration: this.elDuration
                    })
                    return;
                  }
                }
              }
            }

            //判断单据所属模块
            if (this.billsTypeSign == 0) { //销售
              //判断是否有选择报价方式且报价方式为生产工艺报价
              if (this.bills_base_data.hasOwnProperty("offer_way_id") && this.bills_base_data.offer_way_id == 1) {
                //设置货品只查询已设置bom的
                this.bomSetConditon = 1;
              }
            }

            //获取默认显示的货品详情弹框tab页
            if (this.billsTypeSign == 0) { //销售
              if (this.billsClassify == 0) { //报价
                this.proDetailBillTab = "1";
              } else if (this.billsClassify == 1) { //订单
                this.proDetailBillTab = "2";
              }
            } else if (this.billsTypeSign == 3) { //采购
              this.proDetailBillTab = "5";
            } else if (this.billsTypeSign == 2) { //库存
              if (this.billsClassify == 0) { //入库
                this.proDetailBillTab = "3";
              } else if (this.billsClassify == 1) { //出库
                this.proDetailBillTab = "4";
              }
            }

            //显示弹框
            this.show_productComponent = true;
            this.SHOW_PRODUCTBOX(true);
          } else if (systemData.type_val == 1) { //显示单据弹框
            if (this.nodeTypeCode == "SYS-NODE-TYPE-005") { //资金模块
              if (this.billsTypeSign == 5) { //收付款单据
                //调用获取显示单据弹框数据
                let isShowBillsBox = this.judgeShowBillsBox();
                //判断是否满足显示弹框的条件
                if (!isShowBillsBox) {
                  return
                }
              } else if (this.billsTypeSign == 6) { //报销关联单据
                this.showSignArr = [0, 3, 1, 7]; //显示销售单,采购单,计划单,执行单
                //销售单相关
                this.conditionObj.is_order = 1; //只能查询销售订单
                this.conditionObj.in_pay_state = "0,1"; //只能查询未收款和收款中的订单
                this.conditionObj.is_sale_submit = "4"; //引入审核中销售单
                //采购单相关
                this.conditionObj.purchaseClassify = 1; //只能查询采购订单
                this.conditionObj.out_pay_state = "0,1"; //只能查询未付款和付款中的采购订单
                this.conditionObj.is_purchase_submit = "4"; //引入已审核采购单
                //计划单相关
                this.conditionObj.mes_is_submit = "4"; //已审核
                //工序单相关
                this.conditionObj.mes_process_is_submit = "4"; //已审核
              } else if (this.billsTypeSign == 8) { //发票关联单据
                //调用获取显示单据弹框数据
                let isShowBillsBox = this.judgeShowBillsBox();
                //判断是否满足显示弹框的条件
                if (!isShowBillsBox) {
                  return
                }
              }
            }
            this.show_billsComponent = true; //加载单据弹框组件
            this.SHOW_BILLINGBOX(true); //显示单据弹框组件
          } else if (systemData.type_val == 2) { //显示仓储弹框
            this.show_warehouseComponent = true; //加载组件
            this.SHOW_WAREHOUSEBOX(true); //显示组件
          } else if (systemData.type_val == 3) { //显示费用分类弹框
            // 判断基本信息是否有费用分类数据
            if (this.bills_base_data.hasOwnProperty('cost_type_id')) {
              //判断是否已选费用分类
              if (this.cost_type.length > 0) {
                this.showCostTypeArr = [2]; //默认有2
                //只可选和主表相应的费用分类
                this.showCostTypeArr.push(this.cost_type[0].outin_sign)
                //只加在对应的自己分类
                this.costTypeConditionObj.parentId = this.cost_type[0].id;
              } else {
                this.$message({
                  type: 'warning',
                  message: '请先选择基本信息费用分类',
                  duration: this.elDuration
                })
                return
              }
            }
            //判断基本信息是否有单据类型
            if (this.bills_base_data.hasOwnProperty('billing_type_id')) {
              //判断是否已选单据类型
              if (JSON.stringify(this.bills_type_data) == "{}") {
                this.$message({
                  type: 'warning',
                  message: '请先选择基本信息单据类型',
                  duration: this.elDuration
                })
                return
              } else {
                this.costTypeConditionObj.billsTypeId = this.bills_type_data.id;
              }
            }
            //显示弹框
            this.show_costTypeComponent = true; //加载组件
            this.SHOW_COSTTYPEBOX(true); //显示组件
          }
        }
      },

      /* 判断显示单据弹框条件(资金模块用到) */
      judgeShowBillsBox() {
        if (this.bills_base_data.hasOwnProperty('customer_id')) { // 判断是否已选关联客户
          if (this.bills_base_data.customer_id != "") {
            //将条件传入单据选择组件
            this.conditionObj.customer_id = this.bills_base_data.customer_id;
          }
        }
        //判断是否已选供应商
        if (this.bills_base_data.hasOwnProperty('supplier_id')) {
          if (this.bills_base_data.supplier_id != "") {
            //将条件传入单据选择组件
            this.conditionObj.supplierId = this.bills_base_data.supplier_id;
          }
        }
        // 判断是否已选结算方式
        if (this.bills_base_data.hasOwnProperty('setlement_id')) {
          if (this.bills_base_data.setlement_id != "") {
            //将条件传入单据选择组件
            this.conditionObj.setlement_id = this.bills_base_data.setlement_id;
          }
        }
        //判断是否已选交易币种
        if (this.bills_base_data.hasOwnProperty('currency_id')) {
          if (this.bills_base_data.currency_id == "") {
            //将条件传入单据选择组件
            this.conditionObj.currency_id = this.bills_base_data.currency_id;
          }
        }
        // 添加其他查询条件(判断收款单还是付款单)
        if (this.billsClassify == 0) { //收款单
          this.showSignArr = [0, 6]; //显示销售单,报销单
          //销售单相关
          this.conditionObj.is_order = 1; //只能查询销售订单
          this.conditionObj.in_pay_state = "0,1,2"; //未收款,收款中,结算周期
          this.conditionObj.is_sale_submit = "1,4"; //引入审核中,已审核和销售单
          // this.conditionObj.out_depot_state = "2"; //已出库
          //报销单相关
          this.conditionObj.expense_is_submit = "4"; //引入已审核和,处理中报销单
          this.conditionObj.expense_dispose_state = "0,1"; //只能查询为结算和结算中的报销单
        } else if (this.billsClassify == 1) { //付款单
          this.showSignArr = [3, 2, 1, 6, 7]; //显示采购单,出入库单(退货,借用),生产单计划单,报销单,生产工序单
          //采购单相关
          this.conditionObj.purchaseClassify = 1; //只能查询采购订单
          this.conditionObj.out_pay_state = "0,1,2"; //未付款,付款中,结算周期
          this.conditionObj.is_purchase_submit = "4"; //已审核和,处理中采购单
          // this.conditionObj.in_depot_state = "2"; //已入库
          // 出入库单相关
          this.conditionObj.depot_is_submit = "4"; //已审核的出入库单
          this.conditionObj.depot_classify = "9"; //只能查询退货单
          this.conditionObj.depot_pay_state = "0,1"; //只能查询未付款和付款中
          //报销单相关
          this.conditionObj.expense_is_submit = "4"; //引入已审核和,处理中报销单
          this.conditionObj.expense_dispose_state = "0,1"; //只能查询为结算和结算中的报销单
          //生产单计划单相关
          this.conditionObj.mes_is_submit = "4,"; //只能引入已审核,生产中,已完成的生产计划单
          //生产工序单相关
          this.conditionObj.pay_state = "0,1"; //只能查询未付款和付款中的工序执行单
          this.conditionObj.is_merge = 0; //只显示未被合并的
        } else if (this.billsTypeSign == 8) { //发票
          this.showSignArr = [0, 2, 3, 6]; //显示销售单,出入库单(退货),采购单,报销单
          //*********销售单相关*********
          this.conditionObj.is_order = 1; //只能查询销售订单
          this.conditionObj.out_receipt_state = "0,1"; //只能查询未开票和开票中的订单
          this.conditionObj.is_sale_submit = "4"; //引入审核中,已审核和,处理中销售单
          //判断销售开票权限
          if (this.companyparamlist.param326 == "0") { //出库后才能开票
            this.conditionObj.out_depot_state = "2"; //已出库
          }
          //*********出入库单相关(退货)*********
          this.conditionObj.depot_is_submit = "4"; //已审核的单据
          this.conditionObj.depot_classify = "9"; //退货单
          this.conditionObj.depot_receipt_state = "0,1"; //未开票和开票中的单据
          //*********采购单相关*********
          this.conditionObj.purchaseClassify = 1; //只能查询采购订单
          this.conditionObj.in_receipt_state = "0,1"; //只能查询未开票和开票中的订单
          this.conditionObj.is_purchase_submit = "4"; //引入审核中,已审核和,处理中采购单
          //判断采购开票权限
          if (this.companyparamlist.param325 == "0") { //入库后才能开票
            this.conditionObj.in_depot_state = "2"; //已入库
          }
          //*********报销单相关*********
          this.conditionObj.expense_receipt_state = "0,1"; //只能查询未开票和开票中的报销单
          this.conditionObj.expense_is_submit = "4"; //引入审核中,已审核和,处理中报销单
        }
        return true;
      },

      /* 获取已选产品信息 */
      getProductData(data) {
        // 获取已选货品id字符串
        let selPorIdArr = [];
        data.forEach((item, index) => {
          selPorIdArr.push(item.id)
        })
        // 根据弹框已选数据获取产品详情
        if (selPorIdArr.length > 0) { //选了才获取信息
          if (selPorIdArr) {
            this.getProductInfoByIdStr(selPorIdArr.join(","), 0);
          }
        }
        //注销货品弹框
        this.show_productComponent = false;
      },

      /* 根据产品id字符串获取产品详情(从弹框选择时使用) type(0:从货品弹框选择  1:单据引用时的货品 2:单据修改/预览)*/
      getProductInfoByIdStr(proIdStr, type) {
        //定义传入后端的请求参数
        let proIdData = {
          idsStr: proIdStr
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#nodeSysTableBox", 4, '产品信息获取中,请稍候...');
        // 发送请求获取产品相关信息
        req.getProductMsgById(proIdData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //本次添加的产品数据
            let currentProData = [];
            //本次添加的货品id数组
            let currentProIdArr = [];
            //当前循环处理的货品数据下标
            let curProIndex = 0;

            //处理返回结果的数据(处理单据添加相同货品数据情况)
            let resProData = [];
            let proIdArr = proIdStr.split(",");
            proIdArr.forEach((item, index) => {
              res.data.forEach((itemI, indexI) => {
                if (itemI.product.id == item) {
                  resProData.push(itemI);
                }
              })
            })

            let alreadyProId = [];
            // 循环获取产品信息
            resProData.forEach((item, index) => {
              //定义一个新的对象接受信息,不然会出现拷贝问题
              let itemObj = {};
              //判断类型
              if (type == 0) { //货品弹框添加
                itemObj = JSON.parse(JSON.stringify(this.currentTableFiledData));
              } else if (type == 1) { //单据引入
                for (let i = 0; i < this.nodeSysTableVal.length; i++) {
                  if (item.product.id == this.nodeSysTableVal[i].product_id && !alreadyProId.includes(this
                      .nodeSysTableVal[i].import_product_id)) {
                    //获取数据
                    itemObj = JSON.parse(JSON.stringify(this.nodeSysTableVal[i]));
                    //存储下标
                    curProIndex = i;
                    //将已处理的id加入
                    alreadyProId.push(this.nodeSysTableVal[i].import_product_id);
                    break;
                  }
                }
              } else if (type == 2) { //单据(预览/修改)
                for (let i = 0; i < this.nodeSysTableVal.length; i++) {
                  if (item.product.id == this.nodeSysTableVal[i].product_id && !alreadyProId.includes(this
                      .nodeSysTableVal[i].productInfoReserveId)) {
                    //获取数据
                    itemObj = JSON.parse(JSON.stringify(this.nodeSysTableVal[i]));
                    //存储下标
                    curProIndex = i;
                    //将已处理的id加入
                    alreadyProId.push(this.nodeSysTableVal[i].productInfoReserveId);
                    break;
                  }
                }
              }
              //判断是否存在产品信息(处理货品信息,无货品信息的数据自动补齐)
              if (!!resProData[index].product) {
                //处理驼峰数据
                let resultProObj = this.disposeHumpData(this.currentTableFiledData, resProData[index].product,
                  0, 0);
                //获取产品基本信息数据(数据库有定义)
                for (let i in resultProObj) {
                  //判断是哪种类型
                  if (type == 0) { //货品弹框选择
                    if (itemObj.hasOwnProperty(i)) {
                      itemObj[i] = resultProObj[i];
                    }
                  } else if (type == 1) { //单据引入
                    if (itemObj.hasOwnProperty(i) && itemObj[i] === "") {
                      itemObj[i] = resultProObj[i];
                    }
                  } else if (type == 2) { //预览
                    if (itemObj.hasOwnProperty(i) && itemObj[i] === "") {
                      itemObj[i] = resultProObj[i];
                    }
                  }
                }

                //获取产品id(取源数据货品id)
                if (type == 0 && itemObj.hasOwnProperty('product_id')) {
                  itemObj.product_id = resProData[index].product.id;
                }

                //设置默认数量为最小订购量(20220825废除,原因=>增加最小订购量字段,不需要默认赋值)
                if (type == 0 && itemObj.hasOwnProperty('number') && itemObj.hasOwnProperty(
                    'order_limit_num')) {
                  // itemObj.number = itemObj.order_limit_num;
                }

                //设置备注信息(默认为货品简介)
                if (type == 0 && itemObj.hasOwnProperty('remarks')) {
                  itemObj.remarks = resProData[index].product.desciption;
                }

                //获取产品区间价
                if (itemObj.hasOwnProperty('priceRange')) {
                  let rangeStr = resultProObj.subsection_price_rule;
                  let rangeArr = []
                  if (!!rangeStr) {
                    let rangeItem = rangeStr.split("#####");
                    rangeItem.forEach((item, index) => {
                      rangeArr.push(item.split("##"))
                    })
                  }
                  itemObj.priceRange = rangeArr;
                }
              }

              //判断是否存在产品价格信息
              if (resProData[index].prices && resProData[index].prices.length > 0) {
                //获取价格数组
                if (itemObj.hasOwnProperty('priceAllData')) {
                  itemObj.priceAllData = resProData[index].prices;
                }

                //获取基础计量单位
                if (itemObj.hasOwnProperty('priceBaseData')) {
                  resProData[index].prices.forEach((item, index) => {
                    // 获取默认计量单位价格数据
                    if (item.is_base == 1) {
                      //获取基础计量单位对象
                      itemObj.priceBaseData = item;
                      //循环获取默认基础计量单位value值
                      if (type == 0 || type == 1) {
                        for (let i in item) {
                          if (itemObj.hasOwnProperty(i) && itemObj[i] === "") {
                            itemObj[i] = item[i];
                          }
                        }
                      }
                    }
                  })
                }

                //获取采购计量单位(只获取数据,主要用于计算库存数量)
                if (itemObj.hasOwnProperty('pricePurchaseData')) {
                  resProData[index].prices.forEach((item, index) => {
                    // 获取采购计量单位价格数据
                    if (item.is_purchase_default == 1) {
                      //获取基础计量单位对象
                      itemObj.pricePurchaseData = item;
                    }

                    //判断未设置采购计量单位时已基础计量单位为采购单位
                    if (JSON.stringify(itemObj.pricePurchaseData) == "{}") {
                      if (item.is_base == 1) {
                        //获取基础计量单位对象
                        itemObj.pricePurchaseData = item;
                      }
                    }
                  })
                }

                //获取销售计量单位(只获取数据,主要用于计算库存数量)
                if (itemObj.hasOwnProperty('priceSaleData')) {
                  resProData[index].prices.forEach((item, index) => {
                    // 获取销售计量单位价格数据
                    if (item.is_order_default == 1) {
                      //获取基础计量单位对象
                      itemObj.priceSaleData = item;
                    }

                    //判断未设置销售计量单位时已基础计量单位为销售单位
                    if (JSON.stringify(itemObj.priceSaleData) == "{}") {
                      if (item.is_base == 1) {
                        //获取基础计量单位对象
                        itemObj.priceSaleData = item;
                      }
                    }
                  })
                }

                //根据不同模块加载指定计量单位
                if (itemObj.hasOwnProperty('priceCurData')) {
                  //判断具体模块
                  if (this.billsTypeSign == 0 || (this.billsTypeSign == 2 && this.billsClassify ==
                      1)) { //销售,出库单
                    //获取销售指定计量单位
                    resProData[index].prices.forEach((item, index) => {
                      // 获取销售指定计量单位价格数据
                      if (item.is_order_default == 1) {
                        //获取当前选择计量单位对象
                        itemObj.priceCurData = item;
                        //循环获取默认基础计量单位value值
                        for (let i in item) {
                          if (type == 0) { //从货品弹框选择的数据才初始值
                            if (itemObj.hasOwnProperty(i) && itemObj[i] === "") {
                              itemObj[i] = item[i];
                            }
                          }
                        }
                      }
                    })
                  } else if (this.billsTypeSign == 3 || (this.billsTypeSign == 2 && this.billsClassify ==
                      0)) { //采购,入库单
                    //获取采购指定计量单位
                    resProData[index].prices.forEach((item, index) => {
                      // 获取采购指定计量单位价格数据
                      if (item.is_purchase_default == 1) {
                        //获取当前选择计量单位对象
                        itemObj.priceCurData = item;
                        //循环获取默认基础计量单位value值
                        for (let i in item) {
                          if (type == 0) { //从货品弹框选择的数据才初始值
                            if (itemObj.hasOwnProperty(i) && itemObj[i] === "") {
                              itemObj[i] = item[i];
                            }
                          }
                        }
                      }
                    })
                  }

                  //获取默认计量单位(货品弹框选择)
                  if (itemObj.hasOwnProperty('calculateName') && type == 0) {
                    if (!!itemObj.priceCurData.calculateName) {
                      itemObj.calculateName = itemObj.priceCurData.calculateName;
                    } else if (!itemObj.calculateName) { //无计量单位时才赋值
                      itemObj.calculateName = itemObj.priceBaseData.calculateName;
                    }
                  }

                  //获取默认计量单位(单据引用时)
                  if (itemObj.hasOwnProperty('calculateName') && type == 1) {
                    //获取修改前的数据
                    this.getCurOperateRowData(index, 0);
                    //根据模块修改默认计量单位
                    if (this.billsTypeSign == 0) { //销售
                      itemObj.calculateName = itemObj.priceSaleData.calculateName;
                    } else if (this.billsTypeSign == 2) { //库存
                      itemObj.calculateName = itemObj.pricePurchaseData.calculateName;
                    } else if (this.billsTypeSign == 3) { //采购
                      itemObj.calculateName = itemObj.pricePurchaseData.calculateName;
                    }
                    //换算成指定计量单位
                    this.getSelChange(itemObj, 'calculateName', index);
                  }
                }

                //获取价格数组换算比例(与基础计量单位换算)
                if (itemObj.hasOwnProperty('priceAllData')) {
                  itemObj.priceAllData.forEach((item, index) => {
                    let ratioItem = "";
                    if (item.is_base != 1) {
                      ratioItem = "1" + item.calculateName + "=" + item.exchange_base + itemObj
                        .priceBaseData
                        .calculateName;
                    } else {
                      ratioItem = item.calculateName;
                    }
                    item.calculateRatio = ratioItem;
                  })
                }
              }

              //获取产品批次号(采购模块,仓库模块使用默认)
              if (type == 0 && itemObj.hasOwnProperty('productBatch')) { //判断有无批次号属性
                //(采购,出入库生成,销售使用)
                itemObj.productBatch = this.batchNumber;
              }

              //处理货品源单数量信息
              // if(type == 2 && itemObj.hasOwnProperty('origin_num') && !!itemObj.origin_num){
              //   //转换成当前计量单位数据
              //   itemObj.origin_num = this.commonJsExtend.get
              // }

              //将本次从货品弹框添加的数据存入
              currentProData.push(itemObj);
              //将本次从货品弹框添加的货品id存入
              currentProIdArr.push(itemObj.product_id);
              //判断类型
              if (type == 0) { //货品弹框选择
                //将处理好的数据存入当前表格
                this.nodeSysTableVal.push(itemObj);
              } else if (type == 1) { //单据引入
                this.nodeSysTableVal.splice(curProIndex, 1, itemObj)
              } else if (type == 2) { //单据预览/修改
                this.nodeSysTableVal.splice(curProIndex, 1, itemObj)
              }
            })

            //判断获取货品信息的方式
            if (type == 0) { //弹框获取(设置默认仓库)
              //定义当前货品列表涉及到的仓库id数组
              let hourseIdList = [];
              //定义当前货品列表仓库对应货品id数组
              let hourseIdToProIdList = [];
              //判断是否有默认仓库
              currentProData.forEach((item,index)=>{
                //判断没有仓库的情况
                if(!item.house_id){
                  //判断基本信息是否有选择仓库
                  if (this.bills_base_data.hasOwnProperty('house_id') && this.bills_base_data.house_id != "") {
                    //赋值仓库名称
                    let result = this.commonJsExtend.getDictMatchingData(this.bills_base_data.house_id, this.warehouseDataNoTree);
                    item.house_id = result.name;
                  }
                }

                //处理仓库id
                if(!hourseIdList.includes(item.house_id)){//已经存在
                  //新增id到数组
                  hourseIdList.push(item.house_id);
                }

                //找到对应仓库下标
                let curHouseId = 0;
                hourseIdList.forEach((itemL,indexL)=>{
                  if(itemL ==  item.house_id){
                    curHouseId = indexL;
                  }
                })
                //判断对应的数组是否为空
                if(!hourseIdToProIdList[curHouseId]){
                  hourseIdToProIdList[curHouseId] = [];
                }

                //将货品id加入
                hourseIdToProIdList[curHouseId].push(item.product_id);
              })

              //获取对应仓库数据
              hourseIdList.forEach((itemP,indexP)=>{
                //根据名称获取仓库id
                let resHourse = this.commonJsExtend.getDictMatchingDataByName(itemP, this.warehouseDataNoTree);
                this.getProWarehouseNumber(hourseIdToProIdList[indexP].join(","), resHourse.id, type);
              })

              //判断基本信息是否有选择仓库
              // if (this.bills_base_data.hasOwnProperty('house_id') && this.bills_base_data.house_id != "") {
              //   this.warehouseDataNoTree.forEach((itemI, indexI) => {
              //     // 获取默认仓库
              //     if (itemI.id == parseInt(this.bills_base_data.house_id)) {
              //       // 判断货品是否为空
              //       if (currentProIdArr.length > 0) {
              //         //获取默认仓库
              //         currentProData.forEach((itemJ, indexJ) => {
              //           if (itemJ.hasOwnProperty('house_id')) {
              //             itemJ.house_id = itemI.name;
              //           }
              //         })
              //         //获取货品库存数据
              //         if (currentProIdArr) {
              //           this.getProWarehouseNumber(currentProIdArr.join(","), itemI.id, type);
              //         }
              //       }
              //     }
              //   })
              // }
            }

            console.log(this.nodeSysTableVal);

            //货品弹框选择或者单据引入
            if (type == 0 || type == 1) {
              // 将空数据去除
              this.nodeSysTableVal.forEach((item, index) => {
                // 去除条件以产品名称去除
                if (item.name == "") {
                  this.nodeSysTableVal.splice(index, 1)
                }
              })

              // 将数据存储到vuex(我tm强转一次你还能监听到吗)
              let tempData = JSON.parse(JSON.stringify(this.nodeSysTableVal))
              this.BILLS_PRODUCT_DATA(tempData);
              this.BILLS_PRODUCT_TYPE(0); //设置操作方式为新增
              // 打印当前产品列表获取的值
              // console.log(this.nodeSysTableVal);
            }
          }
        })
      },

      /* 获取仓库弹框选择数据 */
      getWarehouseData(data) {
        //关闭加载仓库组件
        this.show_warehouseComponent = false;
        //获取当前货品库存信息
        this.getProWarehouseNumber(this.nodeSysTableVal[this.currentTableIndex].product_id, data.id, 0);
      },

      /* 获取产品库存信息proDataIdStr:产品id字符串数据  houseId:仓库数据id type:获取库存的类型(0:需要自动设置批次号  1:不需要自动设置批次号)*/
      getProWarehouseNumber(proDataIdStr, houseId, type) {
        //定义接口接受参数
        let data = {
          ids: proDataIdStr, //货品id
          warehouseId: houseId, //仓库id
        }
        //发送获取产品库存信息请求
        stockApi.findProNumByHouseIdAndProId(data).then(res => {
          //验证接口返回结果
          if (res.code == 200) {
            // 循环匹配获取的库存数量是哪个产品的
            this.nodeSysTableVal.forEach((itemI, indexI) => {
              //获取库存其他信息
              if (res.data && res.data.length > 0) {
                res.data.forEach((itemJ, indexJ) => {
                  // 用产品id获取库存信息
                  if (itemI.product_id == itemJ.productInfoId) {
                    //获取当前仓库名称(仓库名称要在请求成功后获取,不然多仓切换会有问题)
                    if (itemI.hasOwnProperty('house_id')) {
                      //根据传入的id获取仓库名称
                      this.warehouseDataNoTree.forEach((itemZ, indexZ) => {
                        if (itemZ.id == houseId) {
                          itemI.house_id = itemZ.name;
                        }
                      })
                    }

                    //获取批次号库存信息
                    if (itemI.hasOwnProperty('batchAllData')) {
                      //获取仓库批次列表信息
                      itemI.batchAllData = itemJ.depotStockNumber;
                      //定义一个接受的返回值的参数
                      let resItemI = {};
                      if (itemI.batchAllData.length > 0) {
                        let purchase_exchange_base = 1;
                        //获取采购计量单位换算关系
                        if (itemI.hasOwnProperty("pricePurchaseData")) {
                          purchase_exchange_base = itemI.pricePurchaseData.exchange_base;
                        }

                        //定义一个存储批次号数组的参数
                        let batchData = itemI.batchAllData;
                        //判断当前界面状态
                        if (type == 0) { //需要自动设置批次号
                          //判断根据不同模块加载不同批次数据
                          if (this.billsTypeSign == 0 || this.billsTypeSign == 2) { //销售和库存模块(加载最后一次)
                            resItemI = this.disposeHumpData(itemI, batchData[batchData.length - 1], type, 1,
                              purchase_exchange_base);
                          } else if (this.billsTypeSign == 3) { //采购模块(加载最新一次)
                            resItemI = this.disposeHumpData(itemI, batchData[0], type, 1,
                              purchase_exchange_base);
                          }
                        } else if (type == 1) { //不需要自定设置批次号
                          //获取对应批次数据
                          let cutBatchArr = batchData.filter(item => item.productBatch == item
                            .productBatch);
                          if (cutBatchArr.length > 0) {
                            resItemI = this.disposeHumpData(itemI, cutBatchArr[0], type, 1,
                              purchase_exchange_base);
                          }
                        }

                        //获取各数量显示总数量
                        for (let i in itemJ) {
                          //库存相关数据
                          if (this.showCalculateUnit.includes(i)) {
                            resItemI[i] = (itemJ[i] / purchase_exchange_base);
                          }
                        }
                      }

                      //判断采购申请单时数量显示(需求量 - 可用量)
                      if (this.billsTypeSign == 3) { //采购模块
                        if (this.billsClassify == 0 && this.operateType == 0) { //新增界面触发
                          if (resItemI.hasOwnProperty('number')) {
                            //采购可用量大于数量时显示0
                            if (itemJ.purchaseUsableNumber >= resItemI.number) {
                              resItemI.number = 0;
                            } else {
                              resItemI.number = resItemI.number - itemJ.purchaseUsableNumber;
                            }
                          }
                        }
                      }

                      //使用set赋值,不然无法显示在界面
                      this.$set(this.nodeSysTableVal, indexI, resItemI);
                    }
                  }
                })
              } else { //没有库存时清空库存相关信息
                //定义需要清空的库存信息字段
                let hourseFiledArr = ["occupyTotalNumber", "onwayTotalNumber", "productTotalNumber",
                  "productionUsableNumber", "purchaseUsableNumber", "saleUsableNumber",
                  "warehouseTotalNumber", "occupyNumber", "onwayNumber", "totalOccupyNumber",
                  "totalOnwayNumber", "totalProductNumber", "totalWarehouseNumber", "initNumber"
                ];

                //循环处理
                hourseFiledArr.forEach((item,index)=>{
                  if(this.nodeSysTableVal[this.currentTableIndex].hasOwnProperty(item)){
                    //库存相关信息都清空
                    this.nodeSysTableVal[this.currentTableIndex][item] = 0;
                  }
                })

                //赋值仓库名称
                let result = this.commonJsExtend.getDictMatchingData(houseId, this.warehouseDataNoTree);
                this.nodeSysTableVal[this.currentTableIndex].house_id = result.name;
              }
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 处理驼峰转换数据
      curObj:目标数据
      replaceData:需要取的数据源
      type(0:设置对应批次号所有数据  1:设置对应批次号为空的数据 2:不设置批次号数据)
      isBatch:是否为批次号计算(0:否 1:是)
      exchangeBase:当isBatch为1时使用
      */
      disposeHumpData(curObj, replaceData, type, isBatch, exchangeBase) {
        //定义一个返回值
        let resultData = JSON.parse(JSON.stringify(curObj));
        //将需要获取数据的数据源进行一次转换,以免发生浅拷贝问题
        let replaceDataNew = JSON.parse(JSON.stringify(replaceData));
        //定义一个接受含有驼峰和非驼峰都有的key值
        let disposeReplaceData = {};
        //循环获取两种形式的数据
        for (let i in replaceDataNew) {
          //获取驼峰格式
          let curToHump = this.commonJsExtend.toHump(i);
          disposeReplaceData[curToHump] = replaceDataNew[i];
          //获取非驼峰格式
          let curToLine = this.commonJsExtend.toLine(i);
          disposeReplaceData[curToLine] = replaceDataNew[i];
        }
        //循环获取当前界面所需值
        for (let i in resultData) {
          //判断类型
          if (type == 0) {
            if (disposeReplaceData.hasOwnProperty(i)) {
              //判断是否为批次号计算
              if (isBatch == 1) {
                //获取库存相关数据
                if (this.showCalculateUnit.includes(i)) {
                  resultData[i] = (disposeReplaceData[i] / exchangeBase);
                }
                //获取批次号
                if (i == "productBatch") {
                  resultData[i] = disposeReplaceData[i];
                }
              } else {
                resultData[i] = disposeReplaceData[i];
              }
            }
          } else if (type == 1) {
            if (disposeReplaceData.hasOwnProperty(i) && resultData[i] === "") {
              //判断是否为批次号计算
              if (isBatch == 1) {
                if (this.showCalculateUnit.includes(i)) {
                  resultData[i] = (disposeReplaceData[i] / exchangeBase);
                }
              } else {
                resultData[i] = disposeReplaceData[i];
              }
            }
          }
        }
        return resultData;
      },

      /* 获取单据弹框已选数据(资金模块用到)(需要加判断,销售时只能单选)  */
      getBillingData(data, sign) {
        //关闭加载单据选择组件
        this.show_billsComponent = false;
        //判断是否需要获取单据信息
        if (data.length > 0) {
          let idStr = ""; //id字符串
          let billsNoStr = ""; //单据编号字符串
          //对账单使用
          let checkBillArr = []; //单据数组
          data.forEach((item, index) => {
            if (sign == 0) { //销售
              idStr = idStr + "," + item.saleOrderMain.id;
              billsNoStr = billsNoStr + "," + item.saleOrderMain.billsNo;
            } else if (sign == 1) { //生产计划单
              idStr = idStr + "," + item.id;
              billsNoStr = billsNoStr + "," + item.bills_no;
            } else if (sign == 2) { //出入库单
              idStr = idStr + "," + item.depotMain.id;
              billsNoStr = billsNoStr + "," + item.depotMain.billsNo;
            } else if (sign == 3) { //采购
              idStr = idStr + "," + item.purchaseOrderMain.id;
              billsNoStr = billsNoStr + "," + item.purchaseOrderMain.billsNo;
            } else if (sign == 6) { //报销
              idStr = idStr + "," + item.costBillsExpense.id;
              billsNoStr = billsNoStr + "," + item.costBillsExpense.billsNo;
            } else if (sign == 7) { //生产执行单
              idStr = idStr + "," + item.id;
              billsNoStr = billsNoStr + "," + item.billsNo;
            } else if (sign == 9) { //对账单(通过基本信息选择的)
              //判断对账单明细数据是否为空
              if (checkBillArr.length == 0) {
                let itemObj = {
                  billSign: item.sign,
                  billIdArr: [item.relevanceBills]
                }
                checkBillArr.push(itemObj)
              } else {
                //获取已包含的分类
                let signArr = [];
                checkBillArr.forEach((itemJ, indexJ) => {
                  if (!signArr.includes(itemJ.billSign)) {
                    signArr.push(itemJ.billSign);
                  }
                })
                //判断当前单据是否在分类中
                if (signArr.includes(item.sign)) {
                  checkBillArr.forEach((itemJ, indexJ) => {
                    if (itemJ.billSign == item.sign) {
                      itemJ.billIdArr.push(item.relevanceBills);
                    }
                  })
                } else {
                  let itemObj = {
                    billSign: item.sign,
                    billIdArr: [item.relevanceBills]
                  }
                  checkBillArr.push(itemObj)
                }
              }
            }
          })
          //处理id字符串
          idStr = idStr.substring(1, idStr.length);
          billsNoStr = billsNoStr.substring(1, billsNoStr.length);
          // 判断当前的单据界面
          if (this.billsTypeSign == 5) { //收付款单
            console.log(this.companyparamlist.param327);
            //判断单据付款条件
            if (this.companyparamlist.param327 == "0") { //一次付款一张订单
              //判断是否是多张订单
              if (this.nodeSysTableVal.length >= 1 && this.nodeSysTableVal[0].bills_no != "" || idStr.indexOf(",") != -
                1) {
                this.$message({
                  type: 'warning',
                  message: '系统已设置一次只能付款一张订单,若需修改请联系管理员!',
                  duration: this.elDuration
                })
                return
              }
            }
            //获取单据付款信息
            if (sign == 9) { //对账单使用明细的分类标识
              checkBillArr.forEach((itemK, indexK) => {
                this.getBillsMessage(itemK.billIdArr.join(","), itemK.billSign, data);
              })
            } else { //其它单据正常使用
              this.getBillsMessage(idStr, sign);
            }
          } else if (this.billsTypeSign == 6) { //报销单(只需要单据编号)
            this.nodeSysTableVal[this.currentTableIndex][this.currentDialogFileName] = billsNoStr; //获取单据编号
            this.nodeSysTableVal[this.currentTableIndex]['sign'] = sign; //获取标识
            //获取关联单据id
            if (sign == 0) { //销售
              this.nodeSysTableVal[this.currentTableIndex]['relevance_bills'] = data[0].saleOrderMain.id;
            } else if (sign == 1) { //生产计划
              this.nodeSysTableVal[this.currentTableIndex]['relevance_bills'] = data[0].id;
            } else if (sign == 3) { //采购
              this.nodeSysTableVal[this.currentTableIndex]['relevance_bills'] = data[0].purchaseOrderMain.id;
            } else if (sign == 7) { //生产执行
              this.nodeSysTableVal[this.currentTableIndex]['relevance_bills'] = data[0].id;
            }
          } else if (this.billsTypeSign == 8) { //发票单
            //获取单据开票信息
            if (sign == 9) { //对账单使用明细的分类标识
              checkBillArr.forEach((itemK, indexK) => {
                this.getBillsInvoiceMessage(itemK.billIdArr.join(","), itemK.billSign, data);
              })
            } else { //其它单据正常使用
              //获取单据发票信息
              this.getBillsInvoiceMessage(idStr, sign);
            }
          }
        }
      },

      /* 获取单据付款信息(资金模块用到) checkSubitem(对账单用到)*/
      getBillsMessage(idstr, sign, checkSubitem) {
        //将单据sign存入vuex
        // this.BILLS_RELVENCE_SIGN(sign);
        //定义请求后端数据
        let data = {
          ids: idstr, //单据信息字符串
          sign: sign, //单据标识
        }
        costApi.findCostBillsAndSubItemByIds(data).then(res => {
          if (res.code == 200) {
            res.data.forEach((item, index) => {
              //定义接受当前组件字段
              let itemObj = JSON.parse(JSON.stringify(this.currentTableFiledData));
              //获取单据已付款信息
              if (item.costBillsSubItemVo1) { //已付款信息
                for (let i in item.costBillsSubItemVo1) {
                  if (itemObj.hasOwnProperty(i)) {
                    itemObj[i] = item.costBillsSubItemVo1[i];
                  }
                }
              }

              //获取单据本身信息
              if (item.costBillsVo1) {
                for (let i in item.costBillsVo1) {
                  if (itemObj.hasOwnProperty(i)) {
                    itemObj[i] = item.costBillsVo1[i];
                  }
                  //获取关联单据标识
                  itemObj.sign = sign;
                  //获取关联单据id
                  itemObj.relevance_bills = item.costBillsVo1.id;
                }
              }

              //判断对账单下获取对账单明细信息
              if (!!checkSubitem) {
                checkSubitem.forEach((itemJ, indexJ) => {
                  if (itemJ.sign == sign && itemJ.relevanceBills == item.costBillsVo1.id) {
                    itemObj.import_subitem_id = itemJ.id;
                  }
                })
              }

              //将处理好的数据存入当前表格
              //判断当前界面类型
              if (this.billsTypeSign == 5) { //收付款模块
                //判断加载的明细单据模块
                if (sign == 6) { //报销
                  if (this.billsClassify == 0) { //收款单(不加载支出的明细单据)
                    if (item.costBillsVo1.billing_outin_sign == 1) { //收
                      this.nodeSysTableVal.push(itemObj);
                    }
                  } else if (this.billsClassify == 1) { //付款单(不加载收入的明细单据)
                    if (item.costBillsVo1.billing_outin_sign == 0) { //付
                      this.nodeSysTableVal.push(itemObj);
                    }
                  }
                } else {
                  this.nodeSysTableVal.push(itemObj);
                }
              } else {
                this.nodeSysTableVal.push(itemObj);
              }
            })
            // 将空数据去除
            this.nodeSysTableVal.forEach((item, index) => {
              // 去除条件以单据编号去除
              if (item.bills_no == "") {
                this.nodeSysTableVal.splice(index, 1)
              }
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取单据发票信息(资金模块用到) checkSubitem(对账单用到)*/
      getBillsInvoiceMessage(idstr, sign, checkSubitem) {
        //将单据sign存入vuex
        // this.BILLS_RELVENCE_SIGN(sign);
        //定义请求后端数据
        let data = {
          ids: idstr, //单据信息字符串
          sign: sign, //单据标识
        }
        costApi.findInvoiceAndSubItemByIds(data).then(res => {
          if (res.code == 200) {
            res.data.forEach((item, index) => {
              //定义接受当前组件字段
              let itemObj = JSON.parse(JSON.stringify(this.currentTableFiledData));
              //获取单据已付款信息
              if (item.costBillsSubItemVo1) { //已付款信息
                for (let i in item.costBillsSubItemVo1) {
                  if (itemObj.hasOwnProperty(i)) {
                    itemObj[i] = item.costBillsSubItemVo1[i];
                  }
                }
              }

              //获取单据本身信息
              if (item.costBillsVo1) {
                for (let i in item.costBillsVo1) {
                  if (itemObj.hasOwnProperty(i)) {
                    itemObj[i] = item.costBillsVo1[i];
                  }
                  //获取关联单据标识
                  itemObj.sign = sign;
                  //获取关联单据id
                  itemObj.relevance_bills = item.costBillsVo1.id;
                }
              }

              //判断对账单下获取对账单明细信息
              if (!!checkSubitem) {
                checkSubitem.forEach((itemJ, indexJ) => {
                  if (itemJ.sign == sign && itemJ.relevanceBills == item.costBillsVo1.id) {
                    itemObj.import_subitem_id = itemJ.id;
                  }
                })
              }

              //将处理好的数据存入当前表格
              this.nodeSysTableVal.push(itemObj)
            })
            // 将空数据去除
            this.nodeSysTableVal.forEach((item, index) => {
              // 去除条件以单据编号去除
              if (item.bills_no == "") {
                this.nodeSysTableVal.splice(index, 1)
              }
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取费用分类弹框数据 */
      getCostTypeData(data) {
        if (data.length > 1) {
          this.$message({
            type: 'warning',
            message: '一次只能选择一个费用分类!',
            duration: this.elDuration
          })
          return
        }
        //关闭加载费用分类弹框数据
        this.show_costTypeComponent = false;
        //获取费用分类数据
        this.getCostTypeMessage(data[0]);
      },

      /* 获取费用分类数据 */
      getCostTypeMessage(data) {
        //将所选费用分类数据存入data
        this.costTypeObj = data;
        // 处理费用分类数据
        let itemObj = JSON.parse(JSON.stringify(this.currentTableFiledData));
        //获取费用分类数据
        itemObj.cost_type_id = data.id; //获取id
        itemObj.cost_type_name = data.name; //获取名称
        //将数据存入表格
        this.nodeSysTableVal.push(itemObj);
        // 将空数据去除
        this.nodeSysTableVal.forEach((item, index) => {
          // 去除条件以费用分类名称去除
          if (item.cost_type_name == "") {
            this.nodeSysTableVal.splice(index, 1)
          }
        })
      },

      /* 判断数量输入框发生变化时触发(获取区间价) */
      judgeNumberChange(data, filedName, index) {
        // 判断哪种模块
        if (this.nodeTypeCode == "SYS-NODE-TYPE-001") { //销售模块下进行
          // 当货品数量发生改变时获取区间价
          if (filedName == "number" && data.priceRange && data.priceRange.length > 0) {
            // 根据区间价加载单价
            if (parseInt(data.number) < parseInt(data.priceRange[0][0])) {
              data.price = data.priceBaseData.price;
            } else {
              data.priceRange.forEach((item, index) => {
                if (item[1] != "+∞") { //没有正无穷情况
                  if (parseInt(data.number) >= parseInt(item[0]) && parseInt(data.number) <= parseInt(item[1])) {
                    data.price = parseFloat(item[2])
                  }
                } else { //有正无穷情况
                  if (parseInt(data.number) >= parseInt(item[0])) {
                    data.price = parseFloat(item[2])
                  }
                }
              })
            }
          }
        }

        //调用处理修改后的方法
        this.judgeChangeData(index, filedName, 1);
      },

      /* 获取计量单位采购计量换算 */
      getPurchaseCalculate(data, filedName) {
        //定义返回结果
        let result = "";

        //判断数量才显示
        if (filedName == "number") {
          //定义返回结果
          let resnum = data.number;

          // 获取多计量数据
          let productPriceList = data.priceAllData;

          // 获取基础计量单位
          let curCalculate = null;
          let curCalculateArr = productPriceList.filter(item => item.is_base == 1);
          if (curCalculateArr.length > 0) {
            curCalculate = curCalculateArr[0].exchange_base;
          }

          //获取采购计量单位
          let purchaseCalculate = null;
          let purchaseCalculateName = "";
          let purchaseCalculateArr = productPriceList.filter(item => item.is_purchase_default == 1);
          if (purchaseCalculateArr.length > 0) {
            purchaseCalculate = purchaseCalculateArr[0].exchange_base;
            purchaseCalculateName = purchaseCalculateArr[0].calculateName;
          }

          //转换计量单位
          if (curCalculate != null && purchaseCalculate != null) {
            let curcxchaangebase = 1;
            //判断转换关系
            if (curCalculate > purchaseCalculate) { //大转小
              //获取换算计量
              curcxchaangebase = (curCalculate / purchaseCalculate);
            } else if (curCalculate < purchaseCalculate) { //小转大
              //获取换算计量
              curcxchaangebase = (purchaseCalculate / curCalculate);
            }

            //计算数据
            resnum = resnum / curcxchaangebase;

            //获取结果
            result = resnum + " " + purchaseCalculateName;
          }
        }

        return result;
      },

      /* 获取下拉框数据发生改变(该处可直接使用深拷贝原理获取数据) */
      getSelChange(data, filedName, index) {
        //定义接受处理的数据
        let currentData = [];
        // 判断是哪种下拉框
        if (filedName == "calculateName") { //计量单位下拉框
          //获取当前处理的数据
          currentData = data.priceAllData;
          //获取修改前的计量单位
          let oldCalculate = currentData.filter(item => item.calculateName == this.currentOldRowData.calculateName)[0];
          //获取当前计量单位
          let newCalculate = currentData.filter(item => item.calculateName == data.calculateName)[0];
          //当期换算
          let curExchangeBase = (newCalculate.exchange_base / oldCalculate.exchange_base);
          // //判断是大转小还是小转大
          curExchangeBase = (newCalculate.exchange_base / oldCalculate.exchange_base);

          //处理数量
          let numarr = ['number', 'origin_num']; //数量,源单数量
          numarr.forEach((item, index) => {
            if (data.hasOwnProperty(item) && this.currentOldRowData[item] > 0) {
              data[item] = (this.currentOldRowData[item] / curExchangeBase);
            }
          })

          //获取当前组件所需的数据
          currentData.forEach((item, index) => {
            //将计量信息匹配出来
            if (data[filedName] == item[filedName]) {
              for (let i in item) {
                if (data.hasOwnProperty(i)) {
                  data[i] = item[i];
                }
              }
            }
          })
        }

        //调用处理修改后的方法
        this.judgeChangeData(index, filedName, 1);
      },

      /* 获取复选框改变时的业务 */
      getCheckBoxChange(data, filedName, index) {
        data[filedName] == 0 ? data[filedName] = 1 : data[filedName] = 0;

        // 调用获取修改前的数据方法
        this.getCurOperateRowData(index, 1, filedName);

        //调用处理修改后的方法
        this.judgeChangeData(index, filedName, 1);
      },

      /* 判断复选框是否禁用 */
      judgeCheckBoxDisable(filedName) {
        let result = true;
        if (filedName == "is_rate") {
          result = true;
        } else {
          result = !this.is_edit;
        }
        return result;
      },

      /* 当特批金额复选框发生改变时触发 */
      changeSpecialAmount() {
        this.isSpecialInput = this.isSpecial;
      },

      /* 存一份旧的特惠金额(修改时用) */
      getOldSpaceAmount() {
        this.specialOldAmount = this.specialAmount;
      },

      /* 处理特惠金额改变后的业务(修改时用) */
      getChangeAmount() {
        // 判断操作类型(只有当修改界面才有用)
        if (!this.operateType == 1) {
          return
        }
        let updateData = {};
        //获取日志信息
        let logContent = "更改了【特惠金额】,修改前:" + this.specialOldAmount + ",修改后:" + this.specialAmount + ";";
        //定义单据修改数据
        let updateMain = {
          id: this.generateBillsId, //修改的数据id
          discount: this.specialAmount, //修改后的金额
          logContent: logContent, //日志信息
        }
        //获取后端请求数据
        updateData.saleOrderMain = JSON.stringify(updateMain);
        //发送请求
        sellApi.updateSaleOrderMain(updateData).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: "【特惠金额】修改成功!",
              duration: this.elDuration
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 判断是否启用价格区间价显示 */
      judgeTooltip(data, fileName) {
        let result = false;
        //根据基本信息是否有阶梯报价显示
        if (this.bills_base_data.hasOwnProperty("is_ladder_offer")) {
          //判断是否阶梯报价
          if (this.bills_base_data.is_ladder_offer == 0) { //否
            return true;
          }
        } else {
          return true;
        }
        //根据是否有区间价显示
        if (data.hasOwnProperty('priceRange') && data.priceRange && fileName == 'price') {
          return false;
        } else {
          return true;
        }
      },

      /* 移除区间价信息 */
      remove_sellSection(data, index) {
        data.splice(index, 1);
      },

      /* 新增区间价信息 */
      add_sellSection(data) {
        let item = ["", "", "", "", ""];
        data.push(item);
      },

      /* 获取日期格式 */
      getSelectDate(date, index, filed) {
        //将选择后的日期格式进行转换,不转换存的是国际标准时间
        let currentDate = this.commonJsExtend.getDateTime(date, 0);
        this.nodeSysTableVal[index][filed] = currentDate;
      },

      /* 控制显示当前行批次号列表 */
      showCurrentBatchListBox(data) {
        //判断当前操作类型
        if (this.operateType == 2) { //预览
          return
        }
        //判断是否有显示批次号字段
        if (data.hasOwnProperty('showBatchListBox')) {
          data.showBatchListBox = !data.showBatchListBox;
        }
      },

      /* 获取批次号列表单选按钮选中数据(index:批次号列表当前下标 selection:批次号列表当前选择数据  data:货品表格数据) */
      getSelectedBatch(index, selection, data) {
        //获取当前选择批次号下标
        if (data.hasOwnProperty('selectedBatchIndex')) {
          data.selectedBatchIndex = index;
        }
        //获取当前选择批次号数据
        this.selectedBatchData = selection;
      },

      /* 获取批次号选择后提交操作 */
      getCurrentBatchData(data) {
        if (JSON.stringify(this.selectedBatchData) != "{}") {
          // data = this.disposeHumpData(data,this.selectedBatchData,0)
          // 循环获取已选批次号数据信息
          for (let i in this.selectedBatchData) {
            if (data.hasOwnProperty(i)) {
              data[i] = this.selectedBatchData[i];
            }
          }
        }
        //关闭弹框
        if (data.hasOwnProperty('showBatchListBox')) {
          data.showBatchListBox = false;
        }
      },

      /* 处理当基本信息数据发生变化时的业务 */
      disposeTableData(data) {
        //明细表有数据时才处理
        if ([0, 2, 3].includes(this.billsTypeSign)) {
          //销售采购库存模块
          if (this.nodeSysTableVal.length == 1 && !this.nodeSysTableVal[0].product_code) {
            return
          }
        } else { //其它模块不执行
          return
        }

        //处理明细数据
        this.nodeSysTableVal.forEach((item, index) => {
          //处理外币单价
          if (item.hasOwnProperty("foreign_price")) {
            //调用处理方法(按照价格调用)
            this.getOnInputRowData(index, "foreign_price");
          }
        })
      },

      /* 处理显示字段 */
      disposeShowFile(data) {
        let result = true;
        //判断需要处理的特殊字段
        if (data.system.filed_name == 'cost_price') { //成本价显示(优先判断企业级权限)
          //判断企业级控制权限276
          if (this.companyparamlist.param276 == 1) { //不可见
            result = false;
          }
        } else { //其他字段显示
          //判断是否启用
          if (data.is_enable == 0) {
            result = false;
          }
        }
        return result;
      },

      /* 获取各种分类金额type(0:总金额 1:应收金额 2:实收金额  3:折扣金额) */
      getClassifyAmount(type) {
        let result = 0;
        if (!this.nodeSysTableVal.length > 0) {
          result = 0;
        } else {
          this.nodeSysTableVal.forEach((item, index) => {
            if (type == 0) { //销售模块总价格
              // 判断是否有赠品属性
              if (item.hasOwnProperty('is_gift')) {
                // 判断是否为赠品
                if (!item.is_gift) {
                  result = result + item.money;
                }
              } else {
                result = result + item.money;
              }
            } else if (type == 1) { //资金模块应收金额(本次收款总金额)
              result = result + parseFloat(item.current_amount);
            } else if (type == 2) { //资金模块实收金额(折扣前总金额)
              result = result + parseFloat(item.reality_amount);
            } else if (type == 3) { //资金模块折扣金额(折扣总金额)
              result = result + parseFloat(item.discount_amount);
            } else if (type == 4) { //资金模块报销总金额
              result = result + parseFloat(item.amount);
            } else if (type == 5) { //采购模块总金额
              result = result + parseFloat(item.money);
            } else if (type == 6) { //库存模块总金额
              result = result + parseFloat(item.money);
            } else if (type == 7) { //资金模块发票总金额
              result = result + parseFloat(item.current_amount);
            } else if (type == 8) { //库存模块销售总金额
              result = result + parseFloat(item.price_total);
            }
          })
          //判断价格是否含税
          if (this.bills_base_data.hasOwnProperty("is_rate") && this.bills_base_data.is_rate == 0) {
            //判断是否有税率
            if (this.bills_base_data.hasOwnProperty("tax_rage") && this.bills_base_data.tax_rage != "" && this
              .bills_base_data.tax_rage > 0) {
              result = result + (result * this.bills_base_data.tax_rage / 100);
            }
          }
          //保留三位小数
          result = this.commonJsExtend.getDecimalsLen(result, 3);
          // 判断异常时显示0
          if (!result) {
            result = 0;
          }
        }

        //根据界面状态处理
        if (this.operateType == 1) { //修改界面
          //处理特殊模块
          if (this.billsTypeSign == 0) { //销售模块
            this.specialAmount = result; //特惠金额默认等于单据金额
          }
        }

        return result;
      },

      /* 获取正在操作的row */
      getOperateRow(index, fileName) {
        //下标
        this.currentTableIndex = index;
        // 操作字段
        this.currentImgFileName = fileName;
        //获取当前操作数据
        this.getCurOperateRowData(index);
      },

      /* 图片文件上传之前的操作 */
      beforeUplod(file) {
        //加载loading框(成功后关闭)
        let img = this.commonJsExtend.beforeUpload(file, this.companyparamlists, 1);
        if (img) {
          this.loading = this.commonJsExtend.customLoading("#nodeSysTableBox", 4, '图片上传中,请稍候...');
        }
        return img
      },

      /* 显示附件预览弹框(type 0:图片预览 1:附件预览) */
      previewFile(type, filePath) {
        if (type == 0) { //图片预览
          //获取需要预览的路径
          this.previewFilePath = filePath;
        } else if (type == 1) { //其他附件预览
          this.previewFilePath = filePath.name + '@@@' + filePath.path;
        }
        //加载附件预览弹框
        this.show_previewFileComponent = true;
        this.$forceUpdate();
        //显示弹框
        this.SHOW_FILEPREVIEW(true);
      },

      /* 获取附件预览弹框传出的数据 */
      getPreviewData(data) {
        this.show_previewFileComponent = false;
      },

      /* 附件上传之前的操作 */
      beforeUplodFile(file) {
        //加载loading框(成功后关闭)
        this.loading = this.commonJsExtend.customLoading("#nodeSysTableBox", 4, '附件上传中,请稍候...');
        let img = this.commonJsExtend.beforeUpload(file, this.companyparamlists)
        return img
      },

      /* 图片上传后的操作 */
      uploadFile(res, file, fileList) {
        //关闭loading框
        this.loading.close();
        //判断返回值
        if (res.code == 200) {
          //获取图片路径
          this.nodeSysTableVal[this.currentTableIndex][this.currentImgFileName] = file.name + "@@@" + res.data;
          //调用更改后处理的业务
          this.judgeChangeData(this.currentTableIndex, this.currentImgFileName, 1);
        } else {
          this.$message({
            type: 'error',
            message: res.message,
            duration: this.elDuration
          })
        }
      },

      /* 附件上传后的操作 */
      uploadFileSuccess(res, file, fileList) {
        //关闭loading框
        this.loading.close();
        //判断返回值
        if (res.code == 200) {
          let curFileStr = this.nodeSysTableVal[this.currentTableIndex][this.currentImgFileName];
          let fileItem = file.name + '@@@' + res.data;
          if (curFileStr) { //有数据
            this.nodeSysTableVal[this.currentTableIndex][this.currentImgFileName] = curFileStr + "###" + fileItem;
          } else { //无数据
            this.nodeSysTableVal[this.currentTableIndex][this.currentImgFileName] = fileItem;
          }
          //调用更改后处理的业务
          this.judgeChangeData(this.currentTableIndex, this.currentImgFileName, 1);
        } else {
          this.$message({
            type: 'error',
            message: res.message,
            duration: this.elDuration
          })
        }
      },

      /* 获取附件数组 */
      getFileArr(data) {
        //定义返回值
        let result = [];
        if (data) {
          //分割多条数据
          let fileArr = data.split("###");
          //循环获取名称和路径
          fileArr.forEach((item, index) => {
            if (item) {
              let itemArr = item.split("@@@");
              let itemObj = {
                id: index, //id
                name: itemArr[0], //名称
                path: itemArr[1] //路径
              }
              result.push(itemObj)
            }
          })
        }
        return result;
      },

      /* 删除附件Filedata:附件数组 itemObj:当前操作的附件 data:当前操作的数据行  fileName:当前操作的字段名  */
      closeFileSpan(Filedata, itemObj, data, fileName, index) {
        //获取当前操作数据
        this.getCurOperateRowData(index);
        //判断是否有附件数组
        if (Filedata) {
          //分割多条数据
          let fileArr = Filedata.split("###");
          //循环获取名称和路径
          fileArr.forEach((item, index) => {
            if (item) {
              let itemArr = item.split("@@@");
              //根据路径删除
              if (itemArr[1] == itemObj.path) {
                fileArr.splice(index, 1)
              }
            }
          })
          //获取处理好的数据
          data[fileName] = fileArr.join("###");
        }
        // 判断当前操作类型(新增界面不需要做处理)
        if (this.operateType == 1) { //修改界面
          //调用修改
          this.judgeChangeData(index, this.currentImgFileName, 1)
        }
      },

      /* 增加表格行 */
      addDefaultRow(index) {
        // 判断当前单据类型
        if (this.billsTypeSign == 5) { //收付款单
          //判断企业级参数
          if (this.companyparamlist.param327 == "0") { //一次只能处理一张单据
            this.$message({
              type: 'warning',
              message: '系统已设置一次只能付款一张订单,若需修改请联系管理员!',
              duration: this.elDuration
            })
            return
          }
        }
        this.nodeSysTableVal.push(this.currentTableFiledData)
      },

      /* 删除表格行*/
      removeDefaultRow(index) {
        // 判断行数,最少需要保留一行
        if (this.nodeSysTableVal.length == 1) {
          this.$message({
            type: 'warning',
            message: '至少需要保留一行数据哦!',
            duration: this.elDuration
          })
        } else {
          // 判断当前操作界面
          if (this.operateType == 0) { //新增界面
            this.nodeSysTableVal.splice(index, 1);
            // 将数据存储到vuex
            let tempData = JSON.parse(JSON.stringify(this.nodeSysTableVal))
            this.BILLS_PRODUCT_DATA(tempData);
            this.BILLS_PRODUCT_TYPE(2); //设置操作方式为删除
          } else if (this.operateType == 1) { //修改界面
            //获取操作的数据
            this.getCurOperateRowData(index);
            //判断删除的是新增的删除还是本就有的删除
            if (this.currentOldRowData.productInfoReserveId) { //本就有的
              this.$confirm('确认删除该条数据吗,删除的数据不可恢复, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                // 移除当前行
                this.nodeSysTableVal.splice(index, 1);
                // 将数据存储到vuex(我强转一次你还能监听到吗)
                let tempData = JSON.parse(JSON.stringify(this.nodeSysTableVal))
                this.BILLS_PRODUCT_DATA(tempData);
                this.BILLS_PRODUCT_TYPE(2); //设置操作方式为删除
                // 调用处理数据修改后的方法(delete只是占位符,不起任何业务作用)
                this.judgeChangeData(index, 'delete', 2)
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消删除'
                });
              });
            } else { //新增的
              // 移除当前行
              this.nodeSysTableVal.splice(index, 1);
            }
          }
        }
      },

      /* 判断是否为重要信息显示字段(红色) */
      judgeIsImportFiled(filedName) {
        //定义字段数据
        let filedNameArr = [];
        //根据不同模块区分
        if (this.billsTypeSign == 0) { //销售
          filedNameArr = ['origin_num']; //源单数
        } else if (this.billsTypeSign == 2) { //出入库
          filedNameArr = ['success_number', 'origin_num']; //已出入库数量,源单数
        } else if (this.billsTypeSign == 3) { //采购
          filedNameArr = ['origin_num']; //源单数
        } else if (this.billsTypeSign == 5) { //收付款
          filedNameArr = ['reality_total_amount']; //实收/付金额(对账金额)
        } else if (this.billsTypeSign == 6) { //报销

        } else if (this.billsTypeSign == 8) { //发票
          filedNameArr = ['reality_total_amount']; //实收/付金额(对账金额)
        }
        //判断字段是否在重要显示字段内
        if (filedNameArr.includes(filedName)) {
          return true;
        } else {
          return false;
        }
      },

      /* 判断是否为重要信息显示字段 (绿色)*/
      judgeIsImportGreenFiled(filedName) {
        //定义字段数据
        let filedNameArr = [];
        //根据不同模块区分
        if (this.billsTypeSign == 0) { //销售
          filedNameArr = ['price', 'number']; //单价,本次数量
        } else if (this.billsTypeSign == 2) { //出入库
          filedNameArr = ['number']; //本次树龄
        } else if (this.billsTypeSign == 3) { //采购
          filedNameArr = ['cost_price', 'number']; //成本价,本次数量
        } else if (this.billsTypeSign == 5) { //收付款
          filedNameArr = ['current_amount']; //本次金额
        } else if (this.billsTypeSign == 6) { //报销

        } else if (this.billsTypeSign == 8) { //发票
          filedNameArr = ['current_amount']; //未结算金额
        }
        //判断字段是否在重要显示字段内
        if (filedNameArr.includes(filedName)) {
          return true;
        } else {
          return false;
        }
      },

      /* 判断是否为重要信息显示字段 (黄色)*/
      judgeIsImportWariningFiled(filedName) {
        //定义字段数据
        let filedNameArr = [];
        //根据不同模块区分
        if (this.billsTypeSign == 0) { //销售
        } else if (this.billsTypeSign == 2) { //出入库
        } else if (this.billsTypeSign == 3) { //采购
        } else if (this.billsTypeSign == 5) { //收付款
          filedNameArr = ['not_amount']; //未结算金额
        } else if (this.billsTypeSign == 6) { //报销
        } else if (this.billsTypeSign == 8) { //发票
          filedNameArr = ['not_amount']; //未结算金额
        }
        //判断字段是否在重要显示字段内
        if (filedNameArr.includes(filedName)) {
          return true;
        } else {
          return false;
        }
      },

      /* 清空当前组件数据 */
      clearNodeFormMsg() {
        // 清空表格数据
        this.nodeSysTableVal = [];
        //清空必填数组
        this.nodeMustFiledName = [];
        //清空字符长度验证
        this.nodeLengthAstrict = [];
        //初始话一条默认数据
        this.nodeSysTableVal.push(this.currentTableFiledData);
      },

      /* 获取数据字典id相关信息(数据库存id信息的) */
      getDictionarIdMsg(data) {
        //复制一份以免出现浅拷贝
        let disposeData = JSON.parse(JSON.stringify(data));
        //判断字段
        if (disposeData.hasOwnProperty('house_id')) { //处理仓库
          let result = this.commonJsExtend.getDictMatchingDataByName(disposeData.house_id, this.warehouseDataNoTree);
          disposeData.house_id = result.id;
        }
        if (disposeData.hasOwnProperty('calculateName')) { //处理计量单位
          let result = this.commonJsExtend.getDictMatchingDataByName(disposeData.calculateName, this.ProductUnit);
          disposeData.calculate_type = result.id;
        }
        return disposeData;
      },

      /* 取消弹框触发 */
      cancleDialogOperate() {
        this.show_coustomerComponent = false; //控制加载客户组件
        this.show_warehouseComponent = false; //控制加载仓库组件
        this.show_supplierComponent = false; //控制加载供应商组件
        this.show_departmentComponent = false; //控制加载部门组件
        this.show_personnelComponent = false; //控制加载员工选择组件
        this.show_productComponent = false; //控制加载货品选择组件
        this.show_billsComponent = false; //控制加载单据选择弹框组件
        this.show_costTypeComponent = false; //控制加载费用分类选择弹框组件
        this.show_previewFileComponent = false; //控制加载附件预览弹框
      },

      /* 验证内容信息长度是否合法*/
      judgeContentLengthAstrict() {
        let result = true;
        let len = 0;
        //循环明细记录
        this.nodeSysTableVal.forEach((item, index) => {
          for (let i in item) {
            if (this.nodeLengthAstrict.includes(i)) {
              if (!!item[i] && item[i].trim().length > 150) {
                len++;
              }
            }
          }
        })
        //判断是否有字符超出的内容字段
        if (len > 0) {
          let messageTitle = "";
          if ([0, 2, 3].includes(this.billsTypeSign)) {
            messageTitle = "货品信息"
          } else if ([5, 6, 8].includes(this.billsTypeSign)) {
            messageTitle = "明细信息"
          }
          this.$message({
            type: 'warning',
            message: messageTitle + '存在字符内容过长信息,只能输入0~150个字符,请确认!',
            duration: this.elDuration
          })
          result = false;
        }
        return result;
      },

      /* 验证数据必填项是否已填 */
      judgeMustFiledIsFill() {
        //定义返回值
        let result = true;
        //验证必填项
        let mustButNotVal = [];
        this.nodeMustFiledData.forEach((itemI, indexI) => {
          this.nodeSysTableVal.forEach((itemJ, indexJ) => {
            if (!itemJ[itemI.system.filed_name]) {
              mustButNotVal.push(itemI.show_name)
            }
          })
        })
        //判断是否有必填项未填
        if (mustButNotVal.length > 0) {
          this.$message({
            type: 'warning',
            message: '管理员已设置【' + mustButNotVal[0] + "】为必填项,请确认!",
            duration: this.elDuration
          })
          result = false;
        }
        return result;
      },

      /* 判断仓库是否有子级 */
      judgeWarehouseHasChildren(name) {
        //定义返回值
        let result = false;
        //定义仓库id
        let warehouseId = 0;
        //根据名称获取仓库id
        this.warehouseDataNoTree.forEach((item, index) => {
          if (item.name == name) {
            warehouseId = item.id;
          }
        })

        //判断该仓库是否有子级
        if (warehouseId != 0) {
          this.warehouseDataNoTree.forEach((item, index) => {
            if (item.parent_id == warehouseId) {
              result = true;
            }
          })
        }

        return result;
      },

      /* 提交当前组件填写信息 */
      commitNodeTableMsg() {
        //判断若有提示信息则不进行验证
        let messageLen = document.getElementsByClassName("el-message").length;
        if (messageLen >= 1) {
          return
        }

        // 判断当前单据操作类型
        if (this.billsTypeSign == 0 || this.billsTypeSign == 2 || this.billsTypeSign == 3) { //销售,库存,采购
          //判断当前货品列表是否有数据
          if (this.nodeSysTableVal[0].product_code == '') {
            this.$message({
              type: 'warning',
              message: '请至少选择一个货品!',
              duration: this.elDuration
            })
            return
          }

          //判断是否有同货品,同批次的数据
          let proList = []; //定义非重复数组
          let proRepetitionList = []; //定义重复数组
          let repetitionNum = 0; //定义重复数量
          this.nodeSysTableVal.forEach((item, index) => {
            if (!proList.includes(item.product_code)) {
              proList.push(item.product_code);
            } else {
              proRepetitionList.push(item.product_code)
            }
          })

          //判断有无重复的货品
          if (proRepetitionList.length > 0) {
            proRepetitionList.forEach((itemI, indexI) => {
              let itemList = [];
              this.nodeSysTableVal.forEach((itemJ, indexJ) => {
                if (itemI == itemJ.product_code) {
                  itemList.push(itemJ)
                }
              })
              //判断批次号是否重复
              let batchArr = [];
              itemList.forEach((itemK, indexK) => {
                if (!batchArr.includes(itemK.productBatch)) {
                  batchArr.push(itemK.productBatch)
                }
              })
              //判断批次号数量
              if (batchArr.length == 1) {
                repetitionNum++
              }
            })
          }
          //判断最终结果
          // if (repetitionNum > 0) {
          //   this.$message({
          //     type: 'warning',
          //     message: '货品列表存在相同批次的货品,请确认!',
          //     duration: this.elDuration
          //   })
          //   return
          // }

          //判断选择的仓库是否有子级
          // let isHouseIdChind = false;//定义默认值没有
          // this.nodeSysTableVal.forEach((item,index)=>{
          //   if(item.hasOwnProperty("house_id")){
          //     if(this.judgeWarehouseHasChildren(item.house_id)){
          //       isHouseIdChind = true;
          //     }
          //   }
          // })
          //判断当前选择的仓库是否有子仓
          // if(isHouseIdChind){
          //   this.$message({
          //     type: 'warning',
          //     message: "货品明细中选择的仓库存在子仓,请重新选择仓库!",
          //     duration: this.elDuration
          //   })
          //   return
          // }
        } else if (this.billsTypeSign == 5 || this.billsTypeSign == 8) { //发票
          //判断当前货品列表数据合法性
          if (this.nodeSysTableVal[0].bills_no == '') {
            this.$message({
              type: 'warning',
              message: '请至少选择一张单据!',
              duration: this.elDuration
            })
            return
          }
        } else if (this.billsTypeSign == 6) { //报销
          //判断当前货品列表数据合法性
          if (this.nodeSysTableVal[0].cost_type_name == '') {
            this.$message({
              type: 'warning',
              message: '请至少选择填写一条明细!',
              duration: this.elDuration
            })
            return
          }
        }

        //验证数据必填项
        if (!this.judgeMustFiledIsFill()) {
          return;
        }

        //验证数据字符长度
        if (!this.judgeContentLengthAstrict()) return;

        // 处理需要id格式的数据(数据字典相关数据)
        let nodeSysTableValArr = []; //定义一个新的数组接收
        this.nodeSysTableVal.forEach((item, index) => {
          //处理区间价信息
          if (item.hasOwnProperty("priceRange") && item.priceRange && item.hasOwnProperty(
              "subsection_price_rule") && item.subsection_price_rule) {
            let itemRule = [];
            item.priceRange.forEach((itemJ, indexJ) => {
              itemRule.push(itemJ.join("##"))
            })
            item.subsection_price_rule = itemRule.join("#####");
          }
          //处理数据字典文字类型
          nodeSysTableValArr.push(this.getDictionarIdMsg(item));
        })

        //根据不同模块下处理批次号
        if (this.nodeTypeCode == "SYS-NODE-TYPE-002") { //采购模块
          //采购模块下批次号默认根据成本价加
          nodeSysTableValArr.forEach((itemI, indexI) => {
            //定义一个存储有相同成本价的批次号数据
            let hasBatchObj = {};
            //判断批次号列表数据是否有值
            if (itemI.hasOwnProperty('batchAllData') && itemI.batchAllData.length > 0) {
              //根据成本价获取对应批次号数据
              itemI.batchAllData.forEach((itemJ, indexJ) => {
                if (itemJ.costPrice == itemI.cost_price) {
                  hasBatchObj = itemJ;
                }
              })
            }
            //判断是否有对应成本的批次号
            if (JSON.stringify(hasBatchObj) == "{}") { //没有对应的默认生成
              itemI.productBatch = this.batchNumber;
            } else { //有则合并
              itemI.productBatch = hasBatchObj.productBatch;
            }
          })
        }

        //根据不同模块获取总数据
        let data = {}
        if (this.nodeTypeCode == "SYS-NODE-TYPE-001") { //销售模块组件数据
          data = {
            tableData: nodeSysTableValArr, //表格数据
            totalData: this.totalAmount, //总价
          }
          //判断是否启用优惠价
          if (this.isSpecial) { //使用优惠价
            // 判断优惠价是否填写
            if (this.specialAmount.trim() == "") {
              this.$message({
                type: 'warning',
                message: '特惠金额已启用,请填写特惠金额!',
                duration: this.elDuration
              })
              return
            }
            data.specialAmount = this.specialAmount;
          } else { //不启用优惠价默认等于总金额
            data.specialAmount = this.totalAmount;
          }
        } else if (this.nodeTypeCode == "SYS-NODE-TYPE-002") { //采购模块组件数据
          data = {
            tableData: nodeSysTableValArr, //表格数据
            totalAmount: this.purchaseTotalAmount, //采购总价
          }
        } else if (this.nodeTypeCode == "SYS-NODE-TYPE-004") { //库存模块组件数据
          data = {
            tableData: nodeSysTableValArr, //表格数据
            totalAmount: this.inventoryTotalAmount, //入库总金额
            totalPriceAmount: this.inventoryTotalSaleAmount, //入库销售总金额
          }
        } else if (this.nodeTypeCode == "SYS-NODE-TYPE-005") { //资金模块组件数据
          if (this.billsTypeSign == 5) { //收付款
            data = {
              tableData: nodeSysTableValArr, //表格数据
              totalShould: this.shouldAmount, //本次应收金额
              totalReality: this.realityAmount, //本次实收金额
              totalDiscount: this.discountAmount, //本次折扣金额
            }
          } else if (this.billsTypeSign == 6) { //报销
            data = {
              tableData: nodeSysTableValArr, //表格数据
              totalAmount: this.expenceTotalAmount //报销总金额
            }
          } else if (this.billsTypeSign == 8) { //发票
            data = {
              tableData: nodeSysTableValArr, //表格数据
              totalAmount: this.invoiceTotalAmount //发票总金额
            }
          }
        }
        // console.log('表格数据:' + data);
        this.$emit("getNodeTableData", data);
      },
    },
    filters: {
      /* 获取需要计算的值 */
      getCalculate(curFileName, data, row) {
        let cal = row; //定义数据库存储表达式的属性
        let result = eval(data.system.type_val); //定义一个返回值
        //处理小数
        result = $this.commonJsExtend.getDecimalsLen(result, 5);
        //将数据存入当前行
        row[curFileName] = result;
        return result;
      },
      /* 获取图片地址 */
      getImgUrl(value) {
        let result = "";
        // 判断有无图片路径
        if (value == "") { //当无路径时返回
          result = "";
        } else { //当有路径时返回
          if (value && value.indexOf(",") != -1) { //多张图片默认显示第一张
            result = value.split(",")[0];
          } else { //一张图片
            result = value;
          }
        }
        // 判断是否有产品图片
        if (!result) {
          return ""; //返回默认图片
        } else {
          //获取图片路径
          result = result.split("@@@")[1];
          return baseUrl + result;
        }
      },
    },
    components: {
      customerList,
      productList,
      warehouseList,
      billingList,
      supplierList,
      departmentTree,
      personnelList,
      costTypeTree,
      filePreview
    }
  }
</script>


<style>
  /* 标签间距 */
  .nodeTableItemBox .el-tag+.el-tag {
    cursor: pointer;
    margin-left: 5px;
  }
</style>
<style lang="less" scoped>
  .nodeTableItemBox {
    margin-top: 10px;
  }

  /* 批次号自定义显示框 */
  .billsBatchBox {
    height: 200px;
  }

  /* 批次号自定义提交按钮 */
  .billsBatchBtn {
    width: 100%;
    padding: 10px 0 0 0;
    text-align: right;
  }

  /* 表头重要字段显示样式 */
  .is_importent_red {
    color: red;
  }

  .is_importent_green {
    color: green;
  }

  .is_importent_warning {
    color: #e6a23c;
  }

  .input_split {
    //分隔符
    font-size: 14px;
    text-align: center;
    padding: 0 5px;
    // border: 1px solid black;
  }

  // 区间价表格样式
  .data_table {
    // width: calc(100% - 80px);
    overflow: auto;

    // border: 1px solid black;

    table {
      border-collapse: collapse;

      thead {
        tr {
          th {
            width: 100px;
            height: 30px;
            text-align: center;
            background-color: @table_th_bgColor;
            border: 1px solid @borderColor;
          }

          // 范围th样式
          .sellrange_th {
            width: 200px;
          }

          // 价格区间
          .row_head {
            width: 125px;

            // border: 1px solid black;
            .row_headBox {
              display: flex;
              align-items: center;
              justify-content: space-between;

              // border: 1px solid red;
              span {
                width: 100%;
                // border: 1px solid black;
              }
            }
          }

          /* 操作按钮 */
          .operate {
            width: 50px;
          }
        }
      }

      tbody {
        tr {
          td {
            height: 30px;
            text-align: center;
            border: 1px solid @borderColor;

            .input_price {
              // width: 100%;
              // height: 100%;
              width: 100px;
              border: none;
              outline: none;
              text-align: center;
            }

            // 表格内部样式
            .tdbody {
              display: flex;
              align-items: center;

              .input_currency {
                width: 50px;
              }
            }
          }

          // 范围td样式
          .input_range_td {

            // border: 1px solid black;
            .item {
              width: 200px;
              display: flex;
              justify-content: space-between;

              .input_range {
                width: 45%;
                border: none;
                outline: none;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  // 区间价保存按钮
  .priceRangeSave {
    padding: 10px 10px 0 0;
    text-align: right;
    // border: 1px solid black;
  }

  /* .priceContent{
    display: flex;
    width: 100%;
  } */
</style>
