<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：新增物料组件组件
	开始时间：2021-05-17
	开发人员：刘巍骏
	最后修改：2021-05-17
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="materialAddBox" id="materialAdd">
    <el-dialog :visible.sync="materialAddBox" :before-close="closeBox" width="650px" top="10vh"
      :close-on-click-modal="false" append-to-body class="padding_top_10">
      <!--自定义头部内容 -->
      <div slot="title" class="materialHeadBox">
        <span>{{materialTitle}}</span>
      </div>
      <!-- 表单内容 -->
      <div class="billing_dialog_form">
        <el-form ref="form" :model="material" label-width="100px" size="small">
          <!-- 基本信息 -->
          <div>
            <el-row>
              <el-col :span="24">
                <span class="formTitle">基本信息</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="物料名称:">
                  <!-- <el-tooltip placement="bottom-start">
                    <div slot="content">
                      <ul>
                        <li>合成物料为本工序生产完成后的半成品名称,如果本半成品需要出入库或二次销售,</li>
                        <li>则必须事先设置好,如果只是临时显示,不需要库存,则可在此直接填写。</li>
                      </ul>
                    </div>
                  </el-tooltip> -->
                  <el-input v-model="material.name" placeholder="请选择物料" :disabled="!disableBase">
                    <i title="物料选择" slot="suffix" class="el-input__icon el-icon-more" v-if="disableBase" @click="showProductBox"></i>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="规格型号:">
                  <el-input v-model="material.specifications" placeholder="请先选择物料"
                    :disabled="!disableBase"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="所需数量:">
                  <el-input v-model="material.number" oninput="this.value=this.value.replace(/[^0-9\.]/g,'')" placeholder="请填写数量" :disabled="!disableBase">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="计量单位:">
                 <!-- <el-select  v-model="material.unit" placeholder="请先选择物料" style="width: 100%;"
                    :disabled="!disableBase"> -->
                    <el-select  v-model="material.unit" placeholder="请先选择物料" style="width: 100%;"
                      disabled>
                    <el-option  v-for="item in material.priceData" :key="item.id" :label="item.calculateName"
                      :value="item.calculate_type" @click.native="getChangePrice(item)">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="位置号:">
                  <el-input v-model="material.positionNum" placeholder="请填写位置号"
                    :disabled="!disableBase"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="报损率:">
                  <div class="formSignBox">
                    <el-input class="form_input" v-model="material.consume_rate" placeholder="百分之一填10; 千分之一填1;"
                      :disabled="!disableBase"></el-input>
                    <span class="form_sign">‰</span>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="倒冲:">
                  <el-checkbox v-model="material.is_pour_flush" :disabled="!disableBase"></el-checkbox>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
                <el-form-item label="合成:">
                  <el-checkbox v-model="material.is_bom_merge" :disabled="!disableBase"></el-checkbox>
                </el-form-item>
              </el-col> -->
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="备注:">
                  <el-input type="textarea" :rows="2" placeholder="请添加备注" v-model="material.remark"
                    :disabled="!disableBase"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!-- 物料成本 -->
          <div>
            <el-row>
              <el-col :span="24">
                <span class="formTitle">物料成本</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="成本单价:">
                  <el-input v-model="material.price" placeholder="请先选择物料" :disabled="!disableCost">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所需费用:">
                  <el-input v-model="material.cost" placeholder="请先选择物料" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="含税:">
                  <el-checkbox v-model="material.is_rate" :disabled="!disableCost"></el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!-- 加工成本 -->
          <div>
            <el-row>
              <el-col :span="24">
                <span class="formTitle">加工成本</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="加工费单价:">
                  <el-input v-model="material.machine_price" placeholder="请填写加工费单价"
                    :disabled="!disableCost"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="加工单位:">
                  <el-select v-model="material.process_unit_id" placeholder="请选择加工单位" style="width: 100%;"
                    :disabled="!disableCost">
                    <el-option v-for="item in ProcessUnit" :key="item.id" :label="item.name" :value="item.id"
                      @click.native="getChangeProcessUnit(item)">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="计价数:">
                  <el-input v-model="material.valuation_num" placeholder="请输入计价数"
                    :disabled="!disableCost"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!-- <el-form-item label="批次号:">
            <el-input v-model="material.batchNumber" placeholder="请选择单位"></el-input>
          </el-form-item> -->
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeBox">取 消</el-button>
        <el-button size="small" type="primary" @click="commitMaterialAddData">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 产品选择弹框 -->
    <productList @SelectedData="getProductData" v-if="is_productBox" :bomCondition="1" :isMultiple="isMultiple"
      :visibleProduct="visibleProduct"></productList>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入api接口
  import api from '@/network/production/production.js'; //生产相关接口

  // 导入组件
  import productList from '@/components/commComponent/list/list_product'; //产品列表组件

  export default {
    name: 'materialAdd',
    props: {
      //控制显示添加物料弹框
      materialAddBox: {
        type: Boolean,
        default: false
      },
      // 控制是新增还是修改 -1:新增 0: 修改(生产用到)
      currentMaterial: {
        type: Number,
        default: -1
      },
      //判断当前是bom新增还是修改 0:新增 1:修改
      operationState:{
        type: Number,
        default: 0
      },
      //bom回显信息(bom用到)
      materialInfo: {
        type: Object,
        default () {
          return {}
        }
      },
      //生产单可添加物料的类型(生产单用)(0:只能添加指定属性的物料  1:可以添加所有属性物料)
      addMaterialType: {
        type: Number,
        default: -1
      },
      // 操作界面(0:bom主界面  1:生产计划单界面)
      operatedType: {
        type: Number,
        default: 0
      },
    },
    data() {
      return {
        materialTitle: '添加物料', //

        //物料信息
        material: {
          proId: "", //物料id
          proCode: '', //物料编号(货品编号)
          proOldCode: '', //旧物料编号
          name: '', //物料名称
          specifications: '', //规格型号
          priceData: [], //价格数组(多计量价格)
          unit: '', //计量单位id
          unitName: '', //计量单位名称
          batchNumber: '', //批次号
          price: '', //成本价格
          machine_price: '', //加工费单价
          process_unit_id: '', //加工单位id
          process_unit_name: '', //加工单位名称
          valuation_num: '', //计价数
          number: '', //所需数量
          cost: '', //所需费用
          positionNum: '', //位置号
          consume_rate: '', //报损率
          is_pour_flush: false, //是否为倒冲
          is_bom_merge: false, //是否为工序指定合成物料
          is_rate: false, //是否含税
          remark: '' //备注
        },

        //控制开关
        is_productBox: false, //控制是否加载货品选择弹框
        isMultiple: 0, //控制货品弹框是单选还是多选

        //控制编辑
        disableBase: true, //编辑基本信息
        disableCost: true, //编辑费用信息

        //货品弹框子组件相关
        visibleProduct: -1, //是否根据生产自由添加属性设置

        // loading框
        loading: '',
      }
    },
    computed: {
      ...mapState({
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
        show_productBox: state => state.commComponent.show_productBox, //控制显示产品选择弹框组件
        ProcessUnit: state => state.system.ProcessUnit, //中的加工单位数据
        companyparamlist: state => state.companyparamlist, //企业级权限参数
      })
    },
    mounted() {
      // 初始化vuex数据
      this.initVuexData();
    },
    watch: {
      //监听当前物料状态(新增还是修改)
      currentMaterial: {
        handler(newVal) {
          // 初始化物料信息
          this.initMaterialData(newVal);
        },
        immediate: true
      },
      //监听填写物料信息发生变化
      material: {
        handler(newVal) {
          newVal.cost = this.commonJsExtend.getDecimalsLen(eval(newVal.number * newVal.price), 6)
        },
        deep: true
      },
      //监听货品组件是否显示
      show_productBox(newVal) {
        if (!newVal) {
          this.is_productBox = false;
        }
      },
    },
    methods: {
      ...mapMutations([
        'SHOW_PRODUCTBOX', //控制产品选择弹框是否显示
      ]),
      ...mapActions([
        'getAllMesProcessUnit', //生产加工单位
      ]),

      /* 初始化vuex数据 */
      initVuexData() {
        //加工单位数据
        if (this.ProcessUnit.length == 0) {
          this.getAllMesProcessUnit();
        }
        //处理界面控制权限
        this.disposeUserPower();
      },

      /* 处理企业控制权限 */
      disposeUserPower(){
        //判断操作界面
        if(this.operatedType == 0){//bom主界面
          //获取用户id数组
          let userIdArr = this.commonJsExtend.getPowerUserIdArr(this.companyparamlist.param359);
          //判断当前用户是否在里面
          if(userIdArr.includes(this.UserInfo.user_id)){//费用可编辑
            this.disableBase = true;
            //判断费用相关是否可设置
            if(this.companyparamlist.param362 == "0"){//不可设置
              this.disableCost = false;
            }else if(this.companyparamlist.param362 == "1"){//可设置
              this.disableCost = true;
              return
            }
          }else{
            this.disableBase = false;
          }

          //获取用户id数组
          let userIdArr1 = this.commonJsExtend.getPowerUserIdArr(this.companyparamlist.param360);
          //判断当前用户是否在里面
          if(userIdArr1.includes(this.UserInfo.user_id)){//费用可编辑
            this.disableCost = true;
          }else{
            this.disableCost = false;
          }
        }else if(this.operatedType == 1){//生产界面
          //基本信息权限
          //判断是否严格按照bom执行
          if (this.companyparamlist.param353 == "0") { //启用
            this.disableBase = false; //不可编辑
          } else if (this.companyparamlist.param353 == "1") { //未启用
            this.disableBase = true; //可编辑
          }

          //费用信息判断权限
          if(this.companyparamlist.param361){
            //获取用户id数组
            let userIdArr = this.commonJsExtend.getPowerUserIdArr(this.companyparamlist.param361);
            //判断当前用户是否在里面
            if(userIdArr.includes(this.UserInfo.user_id)){//费用可编辑
              this.disableCost = true;
            }else{
              this.disableCost = false;
            }
          }else{
            this.disableCost = false;
          }
        }
      },

      /* 控制显示货品弹框(需要用if控制) */
      showProductBox() {
        if(this.operationState==0){
          this.isMultiple=1
        }else{
          this.isMultiple=0
        }
        //加载货品选择组件
        this.is_productBox = true;
        //显示货品弹框
        this.SHOW_PRODUCTBOX(true)
      },

      /* 初始化物料信息 */
      initMaterialData(id) {
        //判断可添加物料的属性
        if (this.addMaterialType == 0) { //添加指定属性物料
          this.visibleProduct = 1;
        } else if (this.addMaterialType == 1) { //添加所有属性物料
          this.visibleProduct = 0;
        }
        //判断操作类型
        if (id == -1) { //新增
          //清空数据
          for (let i in this.material) {
            this.material[i] = "";
          }
          this.materialTitle = "添加物料";
        } else if (id == -2) { //同级
          this.materialTitle = "添加可替换物料";
          //暂未处理
        } else { //修改
          this.materialTitle = "修改物料";
          // 判断是哪种模块的
          if (JSON.stringify(this.materialInfo) == "{}") { //生产中的物料
            let data = {
              id: id
            }
            // 加载loading框
            this.loading = this.commonJsExtend.customLoading("#materialAdd", 4, '物料信息获取中,请稍后...');
            api.findMesMaterialById(data).then(res => {
              this.loading.close();
              if (res.code == 200) {
                this.material = {
                  proId: res.data.product_info_id, //物料id
                  proCode: res.data.product_info_code, //物料编码
                  proOldCode: res.data.product_old_code, //旧物料编码
                  name: res.data.product_info_name, //物料名称
                  specifications: res.data.specifications, //规格型号
                  unit: res.data.calculate_id, //计量单位id
                  unitName: res.data.calculateName, //计量单位名称
                  price: res.data.price, //成本价格
                  machine_price: res.data.machine_price, //加工费单价
                  process_unit_id: res.data.process_unit_id, //加工单位id
                  process_unit_name: res.data.process_unit_name, //加工单位名称
                  valuation_num: res.data.valuation_num, //计价数
                  number: res.data.need_num.toString().trim(), //所需数量
                  cost: res.data.total_amount, //所需费用
                  positionNum: res.data.position_num, //位置号
                  is_pour_flush: res.data.is_pour_flush == 1 ? true : false, //是否为倒冲
                  is_bom_merge: res.data.is_bom_merge == 1 ? true : false, //是否为工序指定合成物料
                  is_rate: res.data.is_rate == 1 ? true : false, //是否含税
                  consume_rate: res.data.consume_rate, //报损率
                  remark: res.data.remark //备注
                }
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          } else { //bom的物料
            this.material = {
              proId: this.materialInfo.product_info_id, //物料id
              proCode: this.materialInfo.product_info_code, //物料编码
              proOldCode: this.materialInfo.product_old_code, //旧物料编码
              name: this.materialInfo.product_info_name, //物料名称
              specifications: this.materialInfo.specifications, //规格型号
              unit: this.materialInfo.calculate_id, //计量单位id
              unitName: this.materialInfo.calculate_name, //计量单位名称
              price: this.materialInfo.price, //成本价格
              machine_price: this.materialInfo.machine_price, //加工费单价
              process_unit_id: this.materialInfo.process_unit_id, //加工单位id
              process_unit_name: this.materialInfo.process_unit_name, //加工单位名称
              valuation_num: this.materialInfo.valuation_num, //计价数
              number: this.materialInfo.need_num, //所需数量
              cost: this.materialInfo.total_amount, //所需费用
              positionNum: this.materialInfo.position_num, //位置号
              is_pour_flush: this.materialInfo.is_pour_flush == 1 ? true : false, //是否为倒冲
              is_bom_merge: this.materialInfo.is_bom_merge == 1 ? true : false, //是否为工序指定合成物料
              is_rate: this.materialInfo.is_rate == 1 ? true : false, //是否含税
              consume_rate: this.materialInfo.consume_rate, //报损率
              remark: this.materialInfo.remark //备注
            }
            // 判断是新增界面修改还是修改界面修改
            if (this.materialInfo.hasOwnProperty('priceData')) { //新增界面
              this.material.priceData = this.materialInfo.priceData;
            } else if (this.materialInfo.hasOwnProperty('productInfoPrice')) { //修改界面
              this.material.priceData = this.materialInfo.productInfoPrice;
            }
          }
        }
      },

      /* 获取产品已选数据 */
      getProductData(data) {
        if (data && data.length == 0) {
          return
        }
        //判断选择产品数
        if (data && data.length > 1) {
          let materials=[]
          data.forEach((itemI,indexI)=>{
            // 获取产品多计量价格
            let materialPriceData = itemI.productPrice;
            //定义默认单位数据
            let defaultPrice = {}
            //获取bom设置计量单位
            let bomSetPrice = {};
            materialPriceData.forEach((item, index) => {
              if (item.is_bom_default == 1) {
                bomSetPrice = item;
              }
            })
            //获取基础计量单位
            let basePrice = {};
            materialPriceData.forEach((item, index) => {
              if (item.is_base == 1) {
                basePrice = item;
              }
            })
            //判断如有设置bom指定单位则用它,没有使用基础计量单位,再没有就取第一个
            if (JSON.stringify(bomSetPrice) != "{}") { //bom指定
              defaultPrice = bomSetPrice;
            } else if (JSON.stringify(basePrice) != "{}") { //基础计量单位
              defaultPrice = basePrice;
            } else { //都未设置
              defaultPrice = materialPriceData[0];;
            }
            //改变物料成本单价 以采购单价去转换为bom单位（20221025 徐总 确认 如果要返回之前逻辑 注释以下代码即可）
            if(materialPriceData.length>1){//有设置多计量的情况 改变，没有就按原先的逻辑即可
            let bomExchage=''
            let purchaseExchage=''
              materialPriceData.forEach((item,index)=>{
               if (item.is_bom_default == 1) {
                 bomExchage=item.exchange_base
               }
               if(item.is_purchase_default==1){
                 purchaseExchage=item.exchange_base
               }
              })
              defaultPrice.cost_price=materialPriceData[0].cost_price*(bomExchage/purchaseExchage)
            }
            //获取物料默认信息(该处物料信息需和上面data中定义的一致)
            this.material = {
              proId: itemI.id, //物料id
              proCode: itemI.product_code, //物料编号
              proOldCode: itemI.product_old_code, //旧物料编号
              name: itemI.name, //物料名称
              specifications: itemI.specifications, //规格型号
              priceData: materialPriceData, //价格数组
              unit: defaultPrice.calculate_type, //计量单位id
              unitName: defaultPrice.calculateName, //计量单位名称
              price: defaultPrice.cost_price, //成本价格
              machine_price: 0, //加工费用单价
              process_unit_id: '', //加工单位id
              process_unit_name: '', //加工单位名称
              valuation_num: 0, //计价数
              consume_rate: 0, //报损率
              positionNum: '', //位置号
              is_pour_flush: false, //是否为倒冲
              is_bom_merge: false, //是否为工序指定合成物料
              is_rate: false, //是否含税
              number: 0, //所需数量
              cost: 0, //所需费用
              remark: '', //备注
            }

            //判断是否为工序合成物料
            if(itemI.proClassifyIsBomMerge == 1){//分类优先
              this.material.is_bom_merge = true;
            }else if(itemI.proClassifyIsBomMerge == 0){//分类未开启再判断货品
              if(itemI.is_bom_merge == 1){
                this.material.is_bom_merge = true;
              }
            }

            if(this.operationState==0){
              //将数据发送到父组件
              this.$emit("getMaterData", this.material);
            }
            materials.push(this.material)
          })
          //关闭弹框
          this.$parent.closeMaterialBox();
        }
        else{
          // 获取产品多计量价格
          let materialPriceData = data[0].productPrice;
          //定义默认单位数据
          let defaultPrice = {}
          //获取bom设置计量单位
          let bomSetPrice = {};
          materialPriceData.forEach((item, index) => {
            if (item.is_bom_default == 1) {
              bomSetPrice = item;
            }
          })
          //获取基础计量单位
          let basePrice = {};
          materialPriceData.forEach((item, index) => {
            if (item.is_base == 1) {
              basePrice = item;
            }
          })
          //判断如有设置bom指定单位则用它,没有使用基础计量单位,再没有就取第一个
          if (JSON.stringify(bomSetPrice) != "{}") { //bom指定
            defaultPrice = bomSetPrice;
          } else if (JSON.stringify(basePrice) != "{}") { //基础计量单位
            defaultPrice = basePrice;
          } else { //都未设置
            defaultPrice = materialPriceData[0];;
          }
          //改变物料成本单价 以采购单价去转换为bom单位（20221025 徐总 确认 如果要返回之前逻辑 注释以下代码即可）
          if(materialPriceData.length>1){//有设置多计量的情况 改变，没有就按原先的逻辑即可
          let bomExchage=''
          let purchaseExchage=''
            materialPriceData.forEach((item,index)=>{
             if (item.is_bom_default == 1) {
               bomExchage=item.exchange_base
             }
             if(item.is_purchase_default==1){
               purchaseExchage=item.exchange_base
             }
            })
            defaultPrice.cost_price=materialPriceData[0].cost_price*(bomExchage/purchaseExchage)
          }

          //获取物料默认信息(该处物料信息需和上面data中定义的一致)
          this.material = {
            proId: data[0].id, //物料id
            proCode: data[0].product_code, //物料编号
            proOldCode: data[0].product_old_code, //旧物料编号
            name: data[0].name, //物料名称
            specifications: data[0].specifications, //规格型号
            priceData: materialPriceData, //价格数组
            unit: defaultPrice.calculate_type, //计量单位id
            unitName: defaultPrice.calculateName, //计量单位名称
            price: defaultPrice.cost_price, //成本价格
            machine_price: 0, //加工费用单价
            process_unit_id: '', //加工单位id
            process_unit_name: '', //加工单位名称
            valuation_num: 0, //计价数
            consume_rate: 0, //报损率
            positionNum: '', //位置号
            is_pour_flush: false, //是否为倒冲
            is_bom_merge: false, //是否为工序指定合成物料
            is_rate: false, //是否含税
            number: 1, //所需数量
            cost: 0, //所需费用
            remark: '', //备注
          }

          //判断是否为工序合成物料
          if(data[0].proClassifyIsBomMerge == 1){//分类优先
            this.material.is_bom_merge = true;
          }else if(data[0].proClassifyIsBomMerge == 0){//分类未开启再判断货品
            if(data[0].is_bom_merge == 1){
              this.material.is_bom_merge = true;
            }
          }
        }

      },

      /* 物料切换计量单位时触发 */
      getChangePrice(data) {
        this.material.unit = data.calculate_type; //计量单位id
        this.material.unitName = data.calculateName; //计量单位名称
        this.material.price = data.cost_price; //成本价格
      },

      /* 切换加工单位时触发 */
      getChangeProcessUnit() {
        this.material.process_unit_id = data.id;
        this.material.process_unit_name = data.name;
      },

      /* 提交新增物料弹框 */
      commitMaterialAddData() {
        //将数据发送到父组件
        this.$emit("getMaterData", this.material);
        //关闭弹框
        this.$parent.closeMaterialBox();
      },

      /* 关闭弹框 */
      closeBox() {
        this.$parent.closeMaterialBox();
      },
    },
    components: {
      productList,
    }
  }
</script>

<style lang="less">
  .formSignBox {
    width: 100%;
    display: flex;
    justify-content: space-between;

    //输入框样式
    .form_input {
      width: calc(100% - 30px);
    }

    //符号样式
    .form_sign {
      width: 20px;
    }
  }

  //头部样式
  .materialHeadBox {
    font-size: 16px;
  }
</style>
