<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：收款明细信息组件
	开始时间：2021-04-27
	开发人员：刘巍骏
	最后修改：2021-04-27
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="nodeFormItemBox getCostBox">
    <!-- 有数据时显示 -->
    <div class="getCost_table billing_table" v-if="getCostBillData.length>0">
      <el-table :data="getCostBillData" row-key="id" height="100%" border style="width: 100%">
        <!-- 数据内容 -->
        <el-table-column prop="subitemData.saleOrderMain.billsNo" label="源单编号" sortable width="180" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.subitemData.sign == 0">
              {{scope.row.subitemData.saleOrderMain.billsNo}}
            </span>
            <span v-if="scope.row.subitemData.sign == 1">
              <!--  {{scope.row.subitemData.mesMain.billsNo}} -->
              {{scope.row.subitemData.mesMain.bills_no}}
            </span>
            <span v-if="scope.row.subitemData.sign == 2">
              {{scope.row.subitemData.depotMain.billsNo}}
            </span>
            <span v-if="scope.row.subitemData.sign == 3">
              {{scope.row.subitemData.purchaseOrderMain.billsNo}}
            </span>
            <span v-if="scope.row.subitemData.sign == 6">
              {{scope.row.subitemData.CostBillsExpense.billsNo}}
            </span>
            <span v-if="scope.row.subitemData.sign == 7">
              {{scope.row.subitemData.mesProcess.billsNo}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="源单日期" width="180">
          <template slot-scope="scope">
            <span v-if="scope.row.subitemData.sign == 0">
              {{scope.row.subitemData.saleOrderMain.billsDate}}
            </span>
            <span v-if="scope.row.subitemData.sign == 1">
              <!-- {{scope.row.subitemData.mesMain.billsDate}} -->
              {{scope.row.subitemData.mesMain.bills_date}}
            </span>
            <span v-if="scope.row.subitemData.sign == 2">
              {{scope.row.subitemData.depotMain.billsDate}}
            </span>
            <span v-if="scope.row.subitemData.sign == 3">
              {{scope.row.subitemData.purchaseOrderMain.billsDate}}
            </span>
            <span v-if="scope.row.subitemData.sign == 6">
              {{scope.row.subitemData.CostBillsExpense.billsDate}}
            </span>
            <span v-if="scope.row.subitemData.sign == 7">
              {{scope.row.subitemData.mesProcess.billsDate}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="billData.billsNo" label="收款单号" width="180" v-if="billsClassify==5" show-overflow-tooltip></el-table-column>
        <el-table-column prop="billData.billsNo" label="付款单号" width="180" v-if="billsClassify==6" show-overflow-tooltip></el-table-column>
        <el-table-column prop="billData.billsNo" label="付款单号" width="180" v-if="billsClassify==7" show-overflow-tooltip></el-table-column>
        <el-table-column prop="billData.billsDate" label="收款日期" width="180" v-if="billsClassify==5" show-overflow-tooltip></el-table-column>
        <el-table-column prop="billData.billsDate" label="付款日期" width="180" v-if="billsClassify==6" show-overflow-tooltip></el-table-column>
        <el-table-column prop="billData.billsDate" label="付款日期" width="180" v-if="billsClassify==7" show-overflow-tooltip></el-table-column>
        <el-table-column prop="" label="单据金额" width="80" show-overflow-tooltip>
          <template slot-scope="scope">
            <!-- 收支标识 -->
            <span class="color_success" v-if="scope.row.billData.costClassify == 0">+</span>
            <span class="color_danger"  v-if="scope.row.billData.costClassify == 1">-</span>
            <!-- 值显示 -->
            {{scope.row.subitemData.currentAmount}}
          </template>
        </el-table-column>
        <el-table-column prop="" label="银行帐号" width="280" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.billData.orderBankId | getOrderBankName(SysCostBankInfo)}}
          </template>
        </el-table-column>
        <el-table-column prop="" label="支付类型" width="80" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.billData.costClassify == 0 && scope.row.subitemData.sign != 2">
              收
            </span>
            <span v-if="scope.row.billData.costClassify == 1 && scope.row.subitemData.sign != 2">
              付
            </span>
            <span v-if="scope.row.subitemData.sign == 2">
              退
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="subitemData.remark" label="备注说明" min-width="150" show-overflow-tooltip></el-table-column>
      </el-table>
    </div>

    <!-- 无数据时显示 -->
    <div v-if="getCostBillData.length==0">
      <span class="noReturnTip">本周期暂无收付款</span>
    </div>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  import costApi from '@/network/cost/account.js'; //资金相关接口

  export default{
    name:'nodeFormGetCost',
    props:{
      // 操作类型(0:新增 1:修改  2:查看)
      operateType: {
        type: Number,
        default: -1
      },
      //单据分类(根据nodeTypeCode区分)
      billsClassify: {
        type: Number,
        default: 0
      },
      //节点分类标志
      billsTypeSign: {
        type: Number,
        default: -1
      },
      //是否显示周期内收付款单
      show_periodCostBills:{
        type: Boolean,
        default: false
      },
       
    },
    data(){
      return{
        //组件数据
        getCostBillData:[],//收款单明细列表

        //辅助数据
        loading:'',//loading框
      }
    },
    mounted() {
      //初始化vuex数据
      this.initVuexData();
    },
    computed:{
      ...mapState({
        bills_base_data: state => state.commComponent.bills_base_data, //表单填写的基本信息数据
        SysCostBankInfo: state => state.system.SysCostBankInfo, //收款账号数据
        relvance_billid_arr: state => state.commComponent.relvance_billid_arr, //对账单单据明细id数组
        relvance_billid_sign: state => state.commComponent.relvance_billid_sign, //对账单单据明细模块分类
      })
    },
    watch:{
      //监听是否勾选显示周期内收付款单明细
      show_periodCostBills(newVal){
        // 获取收款明细数据
        this.getCostGetData();
      },
    },
    methods:{
      ...mapActions([
        'getSysCostBankInfo', //收款账号
      ]),
      /* 初始化vuex数据 */
      async initVuexData(){
        //收款账号
        if(this.SysCostBankInfo.length == 0){
          await this.getSysCostBankInfo();
        }
        // 获取收款明细数据
        this.getCostGetData();
      },

      /* 获取收付款单 */
      getCostGetData(){
        //定义公共查询条件数据
        let conditionData = {
          pageIndex: 1, //当前页
          pageSize: 1000, //页面大小
          deleteFlag: 0, //删除标识
          isSubmit:4,//已审核的单据
        };
        
        //判断是否有客户条件
        if (this.operateType == 0 && this.bills_base_data.hasOwnProperty("customer_id")) {
          if(this.bills_base_data.customer_id == ""){
            this.$message({
              type:'warning',
              message:"请选择客户",
              duration:this.elDuration
            })
            return
          }
          conditionData.customerId = this.bills_base_data.customer_id;
        }
        //判断是否有供应商条件
        if (this.operateType == 0 && this.bills_base_data.hasOwnProperty("supplier_id")) {
          if(this.bills_base_data.supplier_id == ""){
            this.$message({
              type:'warning',
              message:"请选择供应商",
              duration:this.elDuration
            })
            return
          }
          conditionData.supplierId = this.bills_base_data.supplier_id;
        }
        //判断是否有周期开始时间
        if (this.bills_base_data.hasOwnProperty("period_start_time")) {
          conditionData.startTime = this.bills_base_data.period_start_time;
        }
        //判断是否有周期结束时间
        if (this.bills_base_data.hasOwnProperty("period_end_time")) {
          conditionData.endTime = this.bills_base_data.period_end_time;
        }
        //判断收付款结余类型
        if(this.bills_base_data.hasOwnProperty("cost_surplus_type")){
          //判断是否勾选周期内收付款单
          if(!this.show_periodCostBills){
            //判断收付款结余类型
            if(this.bills_base_data.cost_surplus_type == 1){//自动结余周期内关联订单收付款单
              //判断是否有源单
              if(this.relvance_billid_arr.length>0){
                conditionData.relevanceBillsId = this.relvance_billid_arr.join(",");
                conditionData.relevanceBillsSign = this.relvance_billid_sign;
              }else{
                return
              }
            }
          }
        }

        //判断是否为对账单模块下
        if (this.billsTypeSign == 9) {
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '收付款明细数据加载中,请稍候...');
          conditionData.isCostSubitem = 1;//设置需要明细信息
          conditionData.isSubitemOrigin = 1;//设置需要源单信息
          //根据不同对账分类查询信息
          if (this.billsClassify == 5) { //客户对账单
            conditionData.relevanceBillsSign = 0;
          } else if (this.billsClassify == 6) { //供应商对账单
            conditionData.relevanceBillsSign = 3;
          }else if(this.billsClassify == 7){//生产对账单
          if (this.bills_base_data.origin_bill_type == 0) { //计划单
          conditionData.relevanceBillsSign = 1;
          }else{
              conditionData.relevanceBillsSign = 7;
          }  
          }
          //条件查询收付款单
          costApi.findCostBillsMainByCondition(conditionData).then(res=>{
            this.loading.close();
            if(res.code == 200){
              //清空先
              this.getCostBillData = [];
              //循环获取界面显示数据
              res.data.rows.forEach((item,index)=>{
                item.costBillsSubitemList.forEach((itemJ,indexJ)=>{
                  let itemObj = {
                    subitemData:itemJ,//出库单货品明细
                    billData:item.costBillsMain,//出库单主表信息
                  }
                  this.getCostBillData.push(itemObj);
                })
              })
            }else{
              this.$message({
                type:'error',
                message:res.message,
                duration:this.elDuration
              })
            }
          })
        }
      },
    },
    filters:{
      /* 获取账号名称*/
      getOrderBankName(value,data){
        let result = "";
        //过滤当前账号
        let curOrderBankArr = data.filter(item => item.id == value);
        if(curOrderBankArr.length>0){
          result = curOrderBankArr[0].name;
        }
        return result;
      },
    }
  }
</script>

<style lang="less">
  // 明细总框
  .getCostBox{
    // 明细内容
    .getCost_table{
      height: 300px;
    }

    // 暂无退货提示信息
    .noReturnTip{
      width: 100%;
      display: block;
      font-size: 28px;
      font-weight: bold;
      color: @theme;
      padding: 10px 0;
      text-align: center;
      // border: 1px solid black;
    }
  }
</style>
