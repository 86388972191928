<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：单据公共表格(其它费用)组件
	开始时间：2021-04-20
	开发人员：刘巍骏
	最后修改：2021-04-20
	备注说明：外部接受方式使用getNodeTableData
-->
<template>
  <div class="accountCheck_table billing_table">
    <!-- 销售费用单 -->
    <el-table :data="originBillsExpenseList" row-key="id" height="100%" border show-summary style="width: 100%" v-if="billsClassify == 5">
      <!-- 数据内容 -->
      <el-table-column prop="subitemData.saleOrderMain.billsNo" label="订单编号" sortable width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="subitemData.saleOrderMain.billsDate" label="订单日期" width="200"></el-table-column>
      <el-table-column prop="subitemData.date" label="费用日期" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="billData.billsNo" label="费用单号" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="subitemData.amount" label="费用金额" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="subitemData.amount" label="应收费用" width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="color_danger" v-if="scope.row.subitemData.costTypeOutInSign == 1">-</span>
          <span class="color_success"  v-if="scope.row.subitemData.costTypeOutInSign == 0">+</span>
          {{scope.row.subitemData.amount}}
        </template>
      </el-table-column>
      <el-table-column prop="subitemData.reason" label="费用说明" min-width="150" show-overflow-tooltip></el-table-column>
    </el-table>

    <!-- 采购费用单 -->
    <el-table :data="originBillsExpenseList" row-key="id" height="100%" border show-summary style="width: 100%" v-if="billsClassify == 6">
      <!-- 数据内容 -->
      <el-table-column prop="subitemData.purchaseOrderMain.billsNo" label="订单编号" sortable width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="subitemData.purchaseOrderMain.billsDate" label="订单日期" width="200"></el-table-column>
      <el-table-column prop="subitemData.date" label="费用日期" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="billData.billsNo" label="费用单号" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="subitemData.amount" label="费用金额" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="subitemData.amount" label="应收费用" width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="color_danger" v-if="scope.row.subitemData.costTypeOutInSign == 1">-</span>
          <span class="color_success"  v-if="scope.row.subitemData.costTypeOutInSign == 0">+</span>
          {{scope.row.subitemData.amount}}
        </template>
      </el-table-column>
      <el-table-column prop="subitemData.reason" label="费用说明" min-width="150" show-overflow-tooltip></el-table-column>
    </el-table>
 
 
    <!-- 生产对账单其他费用单 -->
     <el-table :data="originBillsExpenseList" row-key="id" height="100%" border show-summary style="width: 100%" v-if="billsClassify == 7 && originBillType==0">
      <!-- 数据内容 -->
      <el-table-column prop="subitemData.mesMain.bills_no" label="订单编号" sortable width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="subitemData.mesMain.bills_date" label="订单日期" width="200"></el-table-column>
      <el-table-column prop="subitemData.date" label="费用日期" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="billData.billsNo" label="费用单号" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="subitemData.amount" label="费用金额" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="subitemData.amount" label="应收费用" width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="color_danger" v-if="scope.row.subitemData.costTypeOutInSign == 1">-</span>
          <span class="color_success"  v-if="scope.row.subitemData.costTypeOutInSign == 0">+</span>
          {{scope.row.subitemData.amount}}
        </template>
      </el-table-column>
      <el-table-column prop="subitemData.reason" label="费用说明" min-width="150" show-overflow-tooltip></el-table-column>
    </el-table>
    <el-table :data="originBillsExpenseList" row-key="id" height="100%" border show-summary style="width: 100%" v-if="billsClassify == 7 && originBillType==1">
      <!-- 数据内容 -->
      <el-table-column prop="subitemData.mesProcess.billsNo" label="订单编号" sortable width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="subitemData.mesProcess.billsDate" label="订单日期" width="200"></el-table-column>
      <el-table-column prop="subitemData.date" label="费用日期" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="billData.billsNo" label="费用单号" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="subitemData.amount" label="费用金额" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="subitemData.amount" label="应收费用" width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="color_danger" v-if="scope.row.subitemData.costTypeOutInSign == 1">-</span>
          <span class="color_success"  v-if="scope.row.subitemData.costTypeOutInSign == 0">+</span>
          {{scope.row.subitemData.amount}}
        </template>
      </el-table-column>
      <el-table-column prop="subitemData.reason" label="费用说明" min-width="150" show-overflow-tooltip></el-table-column>
    </el-table>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入接口
  import costApi from '@/network/cost/account.js'; //资金相关接口

  export default {
    name: 'nodeTableExpense',
    props: {
      // 操作类型(0:新增 1:修改  2:查看)
      operateType: {
        type: Number,
        default: -1
      },
      //单据分类(根据nodeTypeCode区分)
      billsClassify: {
        type: Number,
        default: 0
      },
      //节点分类标志
      billsTypeSign: {
        type: Number,
        default: -1
      },
      //回显单据数据
      originalBillData: {
        type: Object,
        default () {
          return {}
        }
      },
      //是否触发查询费用信息
      isTriggerFind: {
        type: Boolean,
        default: false
      },
      //区分执行单和计划单
      originBillType: {
        type: Number,
        default: 0
      },
    },
    data() {
      return {
        //组件数据
        originBillsExpenseList: [], //其它费用信息列表数据
      }
    },
    mounted() {

    },
    computed:{
      ...mapState({
        bills_base_data: state => state.commComponent.bills_base_data, //表单填写的基本信息数据
      })
    },
    watch: {
      // 监听触发获取数据方法
      isTriggerFind(newVal) {
        //判断当前界面状态
        if(this.operateType == 0){//新增
          if(this.originBillsExpenseList.length == 0){//无数据才请求
            //调用获取其它费用方法
            this.originBillsExpenseListFn();
          }
        }else if(this.operateType == 2||this.operateType=="2"){//预览
          //调用获取其他费用方法
          this.originBillsExpenseListById();
        }
      }
    },
    methods: {

      /* 获取其它费用信息列表 */
      originBillsExpenseListFn(){
        //定义公共查询条件数据
        let conditionData = {
          pageIndex: 1, //当前页
          pageSize: 1000, //页面大小
          deleteFlag: 0, //删除标识
          isSubmit:4,//已审核的单据
          disposeState:"0,1",//未结算和结算中的单据
        };

        //判断是否有客户条件
        if (this.bills_base_data.hasOwnProperty("customer_id")) {
          if(this.bills_base_data.customer_id == ""){
            this.$message({
              type:'warning',
              message:"请选择客户",
              duration:this.elDuration
            })
            return
          }
          conditionData.customerId = this.bills_base_data.customer_id;
        }
        //判断是否有供应商条件
        if (this.bills_base_data.hasOwnProperty("supplier_id")) {
          if(this.bills_base_data.supplier_id == ""){
            this.$message({
              type:'warning',
              message:"请选择供应商",
              duration:this.elDuration
            })
            return
          }
          conditionData.supplierId = this.bills_base_data.supplier_id;
        }
        //判断是否有周期开始时间
        if (this.bills_base_data.hasOwnProperty("period_start_time")) {
          conditionData.startTime = this.bills_base_data.period_start_time;
        }
        //判断是否有周期结束时间
        if (this.bills_base_data.hasOwnProperty("period_end_time")) {
          conditionData.endTime = this.bills_base_data.period_end_time;
        }
        
        //判断是否为对账单模块下
        if (this.billsTypeSign == 9) {
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '其它费用明细数据加载中,请稍候...');
          //设置查询条件
          conditionData.isCostSubitem = 1;//设置需要明细信息
          conditionData.isSubitemOrigin = 1;//设置需要源单信息
          //查询出计划单或者执行单的其他费用数据使用
          if(this.billsClassify==7&&this.originBillType==0){
            conditionData.relevanceBillsSign=1;
          }
          if(this.billsClassify==7&&this.originBillType==1){
            conditionData.relevanceBillsSign=7;
          }
          //条件查询费用单
          costApi.findBillsExpenseByCondition(conditionData).then(res=>{
            this.loading.close();
            if(res.code == 200){
              //清空先
              this.originBillsExpenseList = [];
              //循环获取界面显示数据
              res.data.rows.forEach((item,index)=>{
                item.costBillsExpenseSubitem.forEach((itemJ,indexJ)=>{
                  let itemObj = {
                    subitemData:itemJ,//费用单明细
                    billData:item.costBillsExpense,//费用单主表信息
                  }

                  //判断基本信息是否有收付款结余类型选项
                  if(this.bills_base_data.hasOwnProperty("cost_surplus_type")){
                    //判断收付款结余类型
                    if(this.bills_base_data.cost_surplus_type == 1){//自动结余周期内关联订单收付款单
                      //判断是否有源单
                      if(!!itemJ.relevanceBills){
                        this.originBillsExpenseList.push(itemObj);
                      }
                    }else if(this.bills_base_data.cost_surplus_type == 0){//自动结余周期内关联的所有收付款单
                      this.originBillsExpenseList.push(itemObj);
                    }
                  }else{
                    this.originBillsExpenseList.push(itemObj);
                  }
                })
              })
            }else{
              this.$message({
                type:'error',
                message:res.message,
                duration:this.elDuration
              })
            }
          })
        }
      },

      /* 获取其它费用信息列表(预览) */
      originBillsExpenseListById(){
        //定义公共查询条件数据
        let conditionData = {
          pageIndex: 1, //当前页
          pageSize: 1000, //页面大小
        };

        //判断是否为对账单模块下
        if (this.billsTypeSign == 9) {
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '收付款明细数据加载中,请稍候...');
          //设置查询条件
          conditionData.isCostSubitem = 1;//设置需要明细信息
          conditionData.isSubitemOrigin = 1;//设置需要源单信息
          //获取需要回显的源单明细信息
          if(this.originalBillData.costAccountSubitems){
            let expenseMainIdArr = [];
            this.originalBillData.costAccountSubitems.forEach((item,index)=>{
              if(item.sign == 6){
                expenseMainIdArr.push(item.relevanceBills);
              }
            })
            conditionData.id = expenseMainIdArr.join(",");
          }
          //条件查询费用单
          costApi.findBillsExpenseByCondition(conditionData).then(res=>{
            this.loading.close();
            if(res.code == 200){
              //清空先
              this.originBillsExpenseList = [];
              //循环获取界面显示数据
              res.data.rows.forEach((item,index)=>{
                item.costBillsExpenseSubitem.forEach((itemJ,indexJ)=>{
                  let itemObj = {
                    subitemData:itemJ,//出库单货品明细
                    billData:item.costBillsExpense,//出库单主表信息
                  }

                  //判断基本信息是否有收付款结余类型选项
                  if(this.bills_base_data.hasOwnProperty("cost_surplus_type")){
                    //判断收付款结余类型
                    if(this.bills_base_data.cost_surplus_type == 1){//自动结余周期内关联订单收付款单
                      //判断是否有源单
                      if(!!itemJ.relevanceBills){
                        this.originBillsExpenseList.push(itemObj);
                      }
                    }else if(this.bills_base_data.cost_surplus_type == 0){//自动结余周期内关联的所有收付款单
                      this.originBillsExpenseList.push(itemObj);
                    }
                  }else{
                    this.originBillsExpenseList.push(itemObj);
                  }
                })
              })
            }else{
              this.$message({
                type:'error',
                message:res.message,
                duration:this.elDuration
              })
            }
          })
        }
      },
    }
  }
</script>

<style>
</style>
