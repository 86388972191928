<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：对账单明细组件
	开始时间：2021-04-20
	开发人员：刘巍骏
	最后修改：2021-04-20
	备注说明：外部接收方式使用getNodeTableData
-->
<template>
  <div class="accountCheckBox">
    <!-- 表格tab页 -->
    <div class="accountCheckTable" id="checkTableBox">
      <!-- 销售,采购对账 -->
      <el-tabs v-model="checkTab" type="border-card" v-if="billsClassify == 5 || billsClassify == 6">
        <!-- 源单明细 -->
        <el-tab-pane label="源单明细" name="0">
          <!-- 源单明细表格(销售) -->
          <div class="accountCheck_table billing_table" v-if="billsClassify == 5">
            <el-table :data="originBillsDataList" :summary-method="getCustomerSummaries" row-key="id" height="100%"
              border style="width: 100%">
              <!-- 索引 -->
              <el-table-column type="index" fixed width="35">
                <template slot="header" slot-scope="scope">
                  <el-tooltip placement="bottom-start" effect="light">
                    <div slot="content">
                      <ul class="billsList_relvanceTips scollStyle">
                        <li v-for="elem in showListFiledData" v-if="elem.classify == -1">
                          <el-checkbox v-model="elem.is_show">{{elem.name}}</el-checkbox>
                        </li>
                      </ul>
                    </div>
                    <i class="el-icon-setting"></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="50" v-if="operateType == 0">
                <template slot-scope="scope">
                  <div class="operate">
                    <i class="el-icon-delete operate_icon" @click="deleteOriginData(scope.row)"></i>
                  </div>
                </template>
              </el-table-column>
              <!-- 数据内容 -->
              <el-table-column prop="originBillData.billsNo" label="销售单号" width="180"
                v-if="showListFiledArr.includes(0)" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="span_pointer span_color_blue" v-if="scope.row.originBillData"
                    @click="showNodeBillsBox(scope.row,0,1)">{{scope.row.originBillData.billsNo}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="originBillData.billsDate" label="订单日期" sortable v-if="showListFiledArr.includes(1)"
                width="150"></el-table-column>
              <el-table-column prop="originBillData.customerBills" label="客户订单号" width="180"
                v-if="showListFiledArr.includes(2)" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.originBillData.customerBills"
                    :disabled="scope.row.web_disableCustomerBills" placeholder="请输入内容">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="billData.billsNo" label="送货单号" sortable width="180"
                v-if="showListFiledArr.includes(3)" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="span_pointer span_color_blue" v-if="scope.row.billData"
                    @click="showNodeBillsBox(scope.row,2,1,scope.row.depotBillingTypeInfo)">{{scope.row.billData.billsNo}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="billData.billsDate" label="送货日期" sortable width="150"
                v-if="showListFiledArr.includes(4)" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="proData.productCode" label="物料编号" width="180" v-if="showListFiledArr.includes(5)"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="proData.partnerProductCode" label="客户物料编号" width="180"
                v-if="showListFiledArr.includes(6)" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.proData.partnerProductCode"
                    :disabled="scope.row.web_disablePartnerProductCode" placeholder="请输入内容">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="proData.name" label="货品名称" width="150" v-if="showListFiledArr.includes(17)"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="proData.specifications" label="规格名称" width="240"
                v-if="showListFiledArr.includes(7)" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="proData.calculateName" label="计量单位" width="80" v-if="showListFiledArr.includes(8)"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="proData.saleOrderProInfo.price" label="单价" width="80"
                v-if="showListFiledArr.includes(9)" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="proData.saleOrderProInfo.number" label="订单数量" width="80"
                v-if="showListFiledArr.includes(10)" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="proData.number" label="交易数量" width="80" v-if="showListFiledArr.includes(11)"
                show-overflow-tooltip></el-table-column>
              <el-table-column prop="proData.saleOrderProInfo.money" label="订单金额" width="120"
                v-if="showListFiledArr.includes(12)" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="proData.priceTotal" label="应收金额" width="120" v-if="showListFiledArr.includes(13)"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="web_reality_amount" label="实收金额" width="120" v-if="showListFiledArr.includes(18)"
                show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.web_reality_amount" :disabled="operateType == 2"
                    @change="getCostCheckTotalAmount(2)" placeholder="请输入内容">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="" label="含税金额" width="120" v-if="showListFiledArr.includes(14)"
                show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row | getTaxRageAmount(scope.row.originBillData,0)}}
                </template>
              </el-table-column>
              <el-table-column prop="" label="收款状态" width="120" v-if="showListFiledArr.includes(15)"
                show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-button v-for="(elem,index) in costInStateIn" :type="elem.class" size="mini"
                    v-if="scope.row.originBillData.payState == elem.value">{{elem.name}}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column prop="proData.remarks" label="备注" min-width="150" v-if="showListFiledArr.includes(16)"
                show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>

          <!-- 源单明细表格(采购) -->
          <div class="accountCheck_table billing_table" v-if="billsClassify == 6">
            <el-table :data="originBillsDataList" row-key="id" height="100%" border style="width: 100%">
              <!-- 索引 -->
              <el-table-column type="index" fixed width="35">
                <template slot="header" slot-scope="scope">
                  <el-tooltip placement="bottom-start" effect="light">
                    <div slot="content">
                      <ul class="billsList_relvanceTips scollStyle">
                        <li v-for="elem in showListFiledData" v-if="elem.classify == -1">
                          <el-checkbox v-model="elem.is_show">{{elem.name}}</el-checkbox>
                        </li>
                      </ul>
                    </div>
                    <i class="el-icon-setting"></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="50" v-if="operateType == 0">
                <template slot-scope="scope">
                  <div class="operate">
                    <i class="el-icon-delete operate_icon" @click="deleteOriginData(scope.row)"></i>
                  </div>
                </template>
              </el-table-column>
              <!-- 数据内容 -->
              <el-table-column prop="originBillData.billsNo" label="采购单号" sortable width="180"
                v-if="showListFiledArr.includes(0)" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="span_pointer span_color_blue" v-if="scope.row.originBillData"
                    @click="showNodeBillsBox(scope.row,3,1)">{{scope.row.originBillData.billsNo}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="originBillData.billsDate" label="订单日期" sortable width="150"
                v-if="showListFiledArr.includes(1)"></el-table-column>
              <el-table-column prop="billData.deliverNo" label="送货单号" width="180" v-if="showListFiledArr.includes(2)"
                show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.billData.deliverNo"
                    :disabled="scope.row.web_disableDeliverNo" placeholder="请输入内容">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="billData.deliveryDate" label="送货日期" sortable width="150"
                v-if="showListFiledArr.includes(3)" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="span_pointer span_color_blue" v-if="scope.row.billData"
                    @click="showNodeBillsBox(scope.row,2,0,scope.row.depotBillingTypeInfo)">{{scope.row.billData.deliveryDate}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="proData.productCode" label="物料编号" width="180" v-if="showListFiledArr.includes(4)"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="proData.partnerProductCode" label="供应商物料编号" v-if="showListFiledArr.includes(5)"
                width="180" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.proData.partnerProductCode"
                    :disabled="scope.row.web_disablePartnerProductCode" placeholder="请输入内容">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="proData.name" label="货品名称" width="150" v-if="showListFiledArr.includes(16)"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="proData.specifications" label="规格名称" v-if="showListFiledArr.includes(6)"
                width="240" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="proData.calculateName" label="计量单位" v-if="showListFiledArr.includes(7)" width="80"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="proData.purchaseOrderProinfo.costPrice" v-if="showListFiledArr.includes(8)"
                label="单价" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="proData.purchaseOrderProinfo.number" v-if="showListFiledArr.includes(9)"
                label="订单数量" width="80" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="proData.number" label="交易数量" width="80" v-if="showListFiledArr.includes(10)"
                show-overflow-tooltip></el-table-column>
              <el-table-column prop="proData.purchaseOrderProinfo.money" label="订单金额" width="120"
                v-if="showListFiledArr.includes(11)" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="proData.money" label="应付金额" width="120" v-if="showListFiledArr.includes(12)"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="web_reality_amount" label="实付金额" width="120" v-if="showListFiledArr.includes(17)"
                show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.web_reality_amount" :disabled="operateType == 2"
                    @change="getCostCheckTotalAmount(2)" placeholder="请输入内容">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="" label="含税金额" width="120" v-if="showListFiledArr.includes(13)"
                show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row | getTaxRageAmount(scope.row.originBillData,3)}}
                </template>
              </el-table-column>
              <el-table-column prop="" label="付款状态" width="120" v-if="showListFiledArr.includes(14)"
                show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-button v-for="(elem,index) in costInStateOut" :type="elem.class" size="mini"
                    v-if="scope.row.originBillData.payState == elem.value">{{elem.name}}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column prop="proData.remarks" label="备注" v-if="showListFiledArr.includes(15)" min-width="150"
                show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <!-- 其它费用 -->
        <el-tab-pane label="其它费用" name="2">
          <!-- 费用明细表格 -->
          <nodeTableExpense :billsTypeSign="billsTypeSign" :billsClassify="billsClassify" :isTriggerFind="isTriggerFind"
            :operateType="operateType" :originalBillData="originalBillData"></nodeTableExpense>
        </el-tab-pane>
      </el-tabs>

      <!-- *******************生产对账******************* -->
      <el-tabs v-model="mescheckTab" type="border-card" v-if="billsClassify == 7 && bills_base_data.origin_bill_type == 0">
        <!-- 母件明细 -->
        <el-tab-pane label="母件明细" name="0" v-if="mesOriginType == 0">
          <!-- 母件明细表格 -->
          <div class="accountCheck_table billing_table">
            <el-table :data="originBillsDataList" row-key="id" height="100%" border show-summary style="width: 100%">
              <!-- 索引 -->
              <el-table-column type="index" fixed width="35">
                <template slot="header" slot-scope="scope">
                  <el-tooltip placement="bottom-start" effect="light">
                    <div slot="content">
                      <ul class="billsList_relvanceTips scollStyle">
                        <li v-for="elem in showListFiledData" v-if="elem.classify == -1">
                          <el-checkbox v-model="elem.is_show">{{elem.name}}</el-checkbox>
                        </li>
                      </ul>
                    </div>
                    <i class="el-icon-setting"></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="50" v-if="operateType == 0">
                <template slot-scope="scope">
                  <div class="operate">
                    <i class="el-icon-delete operate_icon" @click="deleteOriginData(scope.row)"></i>
                  </div>
                </template>
              </el-table-column>
              <!-- 数据内容 -->
              <el-table-column prop="originBillData.relevance_bills_no" label="销售订单号" sortable width="180" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="originBillData.bills_no" label="生产计划单号" sortable width="180" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="" label="送货日期" width="180">
                <template slot-scope="scope">
                  <span class="span_pointer span_color_blue" v-if="scope.row.billData"
                    @click="showNodeBillsBox(scope.row,2,0,scope.row.depotBillingTypeInfo)">{{scope.row.billData.deliveryDate}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="" label="送货单号" width="180" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.billData.deliverNo"
                    :disabled="scope.row.web_disableDeliverNo" placeholder="请输入内容">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="proData.productCode" label="货品编号" width="180" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="proData.name" label="货品名称" width="180" show-overflow-tooltip></el-table-column>
              <el-table-column prop="proData.specifications" label="货品规格" width="240" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="proData.calculateName" label="计量单位" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="originBillData.tax_rage" label="税率" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="proData.costPrice" label="单价" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="originBillData.total_amount" label="应付金额" width="120" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="" label="实付金额" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.web_reality_amount" :disabled="operateType == 2"
                    @change="getCostCheckTotalAmount(2)" placeholder="请输入内容">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="" label="含税金额" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row | getTaxRageAmount(scope.row.originBillData,1)}}
                </template>
              </el-table-column>
              <el-table-column prop="originBillData.plan_num" label="生产数量" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="originBillData.success_number" label="完成数量" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="originBillData.bills_date" label="开工日期" width="100" show-overflow-tooltip></el-table-column>
              <el-table-column prop="originBillData.complete_date" label="完工日期" width="100" show-overflow-tooltip></el-table-column>
              <el-table-column prop="originBillData.principal_name" label="负责人" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="" label="最低开工费" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.mesProcessList | getProcessTotalMinWorkAmount}}
                </template>
              </el-table-column>
              <el-table-column prop="" label="开工费配额" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.mesProcessList | getProcessTotalMinWorkNumber}}
                </template>
              </el-table-column>
              <el-table-column prop="" label="备注说明" min-width="150" show-overflow-tooltip></el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <!-- 加工明细 -->
        <el-tab-pane label="加工明细" name="1" v-if="mesOriginType == 0">
          <!--加工明细表格 -->
          <div class="accountCheck_table billing_table">
            <el-table :data="machineData" row-key="id" height="100%" border show-summary style="width: 100%">
              <!-- 数据内容 -->
              <el-table-column prop="billData.relevance_bills_no" label="生产计划单号" sortable width="200"
                show-overflow-tooltip></el-table-column>
              <el-table-column prop="materialData.product_info_code" label="物料编号" width="200"></el-table-column>
              <el-table-column prop="materialData.product_info_name" label="物料名称" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="materialData.specifications" label="物料规格" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="materialData.calculateName" label="单位" width="80" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="billData.relevance_number" label="源单数量" width="80" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="materialData.need_total_num" label="使用数量" width="80" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="materialData.price" label="加工费单价" width="80" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="materialData.process_unit_name" label="加工单位" width="80" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="materialData.valuation_num" label="计价数" width="80" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="materialData" label="含税金额" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.materialData | getTaxRageAmount(1.1)}}
                </template>
              </el-table-column>
              <el-table-column prop="materialData.product_info_code" label="实际金额" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.materialData | getReceivableAmount(scope.row.billData,1.1)}}
                </template>
              </el-table-column>
              <el-table-column prop="materialData.remark" label="备注" min-width="150" show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <!-- 其它费用 -->
        <el-tab-pane label="其它费用" name="2">
          <!-- 费用明细表格 -->
          <nodeTableExpense  :billsTypeSign="billsTypeSign" :billsClassify="billsClassify" :billsArr="originBillsIdList" :relvancebillSign="relvancebillSign"
            :isTriggerFind="isTriggerFind" :operateType="operateType" :originalBillData="originalBillData" :originBillType="originBillType">
          </nodeTableExpense>
        </el-tab-pane>
      </el-tabs>
      <!-- ******************************工序对账****************************** -->
      <el-tabs v-model="mescheckTab" type="border-card" v-if="billsClassify == 7 && bills_base_data.origin_bill_type == 1">

        <!-- 母件明细 -->
        <el-tab-pane label="母件明细" name="0" v-if="mesOriginType == 1">
          <!-- 母件明细表格 -->
          <div class="accountCheck_table billing_table">
            <el-table :data="originBillsDataList" row-key="id" height="100%" border show-summary style="width: 100%">
              <!-- 数据内容 -->
              <el-table-column prop="originBillData.billsNo" label="订单编号" sortable width="180" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="" label="送货日期" width="180">
                 <template slot-scope="scope">
                  <span class="span_pointer span_color_blue" v-if="scope.row.billData"
                    @click="showNodeBillsBox(scope.row,2,0,scope.row.depotBillingTypeInfo)">{{scope.row.billData.deliveryDate}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="" label="送货单号" width="180" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.billData.deliverNo"
                    :disabled="scope.row.web_disableDeliverNo" placeholder="请输入内容">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="proData.productCode" label="货品编号" width="180" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="proData.name" label="货品名称" width="180" show-overflow-tooltip></el-table-column>
              <el-table-column prop="proData.specifications" label="货品规格" width="240" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="proData.calculateName" label="计量单位" width="80" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="originBillData.taxRage" label="税率" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="proData.costPrice" label="单价" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="originBillData.totalLabourCost" label="应付金额" width="120" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="" label="实付金额" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.web_reality_amount" :disabled="operateType == 2"
                    @change="getCostCheckTotalAmount(2)" placeholder="请输入内容">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="" label="含税金额" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row | getTaxRageAmount(scope.row.originBillData,1)}}
                </template>
              </el-table-column>
              <el-table-column prop="originBillData.mesMainNum" label="生产数量" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="originBillData.processMaterialSuccess" label="完成数量" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="originBillData.startTime" label="开工日期" width="100" show-overflow-tooltip></el-table-column>
              <el-table-column prop="originBillData.endTime" label="完工日期" width="100" show-overflow-tooltip></el-table-column>
              <el-table-column prop="originBillData.userName" label="负责人" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="originBillData.workNum" label="生产工人" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="originBillData.minWorkAmount" label="最低开工费" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="originBillData.minWorkNumber" label="开工费配额" width="80" show-overflow-tooltip></el-table-column>
              <el-table-column prop="originBillData.remark" label="备注说明" min-width="150" show-overflow-tooltip></el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <!-- 加工明细 -->
        <el-tab-pane label="加工明细" name="1" v-if="mesOriginType == 1">
          <!--加工明细表格 -->
          <div class="accountCheck_table billing_table">
            <el-table :data="machineData" row-key="id" height="100%" border show-summary style="width: 100%">
              <!-- 数据内容 -->
              <el-table-column prop="billData.billsNo" label="订单号" sortable width="200"
                show-overflow-tooltip></el-table-column>
              <el-table-column prop="materialData.productInfoCode" label="物料编号" width="200"></el-table-column>
              <el-table-column prop="materialData.productInfoName" label="物料名称" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="materialData.specifications" label="物料规格" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="materialData.calculateName" label="单位" width="80" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="materialData.needAllNum" label="源单数量" width="80" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="materialData.getNum" label="使用数量" width="80" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="materialData.price" label="加工费单价" width="80" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="materialData.processUnitName" label="加工单位" width="80" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="materialData.valuationNum" label="计价数" width="80" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="materialData" label="含税金额" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.materialData | getTaxRageAmount(7.1)}}
                </template>
              </el-table-column>
              <el-table-column prop="materialData.product_info_code" label="实际金额" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.materialData | getReceivableAmount(scope.row.billData,7.1)}}
                </template>
              </el-table-column>
              <el-table-column prop="materialData.remark" label="备注" min-width="150" show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>

        <!-- 其它费用 -->
        <el-tab-pane label="其它费用" name="2">
          <!-- 费用明细表格 -->
          <nodeTableExpense  :billsTypeSign="billsTypeSign" :billsClassify="billsClassify" :billsArr="originBillsIdList" :relvancebillSign="relvancebillSign"
            :isTriggerFind="isTriggerFind" :operateType="operateType" :originalBillData="originalBillData" :originBillType="originBillType">
          </nodeTableExpense>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 总计信息 -->
    <div class="accountCheckTotalBox">
      <ul class="totalUl">
        <li class="item_amount">
          <span class="date">{{bills_base_data.period_start_time}}</span>
          <span class="separator">--</span>
          <span class="date">{{bills_base_data.period_end_time}}</span>
          <span class="title">周期</span>
          <!-- <span class="title" v-if="billsClassify == 5">应收金额:</span>
          <span class="title" v-if="[6,7].includes(billsClassify)">应付金额:</span>
          <span class="amount">{{totalAmountData.totalAmount}}</span> -->
        </li>
        <li class="item_amount">
          <span class="title" v-if="billsClassify == 5">应收合计小写: ￥</span>
          <span class="title" v-if="[6,7].includes(billsClassify)">应付合计小写:</span>
          <span class="amount">{{totalAmountData.totalAmount}}</span>
        </li>
        <li class="item_amount">
          <span class="title" v-if="billsClassify == 5">应收合计小写(含税): ￥</span>
          <span class="title" v-if="[6,7].includes(billsClassify)">应付合计小写(含税):</span>
          <span class="amount">{{totalAmountData.totalTaxAmount}}</span>
        </li>
        <li class="item_amount">
          <span class="title" v-if="billsClassify == 5">应收合计大写:</span>
          <span class="title" v-if="[6,7].includes(billsClassify)">应付合计大写:</span>
          <span class="amount">{{totalAmountData.totalAmountZn}}</span>
        </li>
        <li class="item_amount">
          <span class="title" v-if="billsClassify == 5">应收合计大写(含税):</span>
          <span class="title" v-if="[6,7].includes(billsClassify)">应付合计大写(含税):</span>
          <span class="amount">{{totalAmountData.totalTaxAmountZn}}</span>
        </li>
      </ul>
    </div>

    <!-- 新增单据组件 -->
    <nodeBills :nodeTypeCode="nodeTypeCodeView" :billsClassify="billsClassifyView" :billsTypeSign="billsTypeSignView"
      :updateDefaultData="updateDefaultDataView" :operateType="operateTypeView" :generateBillsId="generateBillsIdView"
      :billsTypeDefaultCode="billsTypeDefaultCodeView" :loadNodeBillsType="1" :originBillType="originBillType" v-if="show_nodeBills"></nodeBills>

  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入方法
  import sellApi from '@/network/sell/bills.js'; //销售模块
  import purchaseApi from '@/network/stock/purchase.js'; //采购模块
  import stockApi from '@/network/stock/stock.js'; //仓库相关接口
  import productApi from '@/network/production/production.js'; //生产模块
  import costApi from '@/network/cost/account.js'; //资金相关接口
  import orderCommApi from '@/network/billsComm/billsComm.js'; //单据公共接口

  //导入组件
  import nodeTableExpense from './nodeTable_expense.vue'; //其它费用组件

  var $this; //声明一个全局变量用于存储this
  export default {
    name: 'nodeCheckTableData',
    props: {
      // 节点分类code
      nodeTypeCode: {
        type: String,
        default: ''
      },
      //单据分类(根据nodeTypeCode区分)
      billsClassify: {
        type: Number,
        default: 0
      },
      //是否提交当前填写的表单信息
      isCommitFormData: {
        type: Boolean,
        default: true
      },
      //是否清空当前已填写表单数据
      isClearFormData: {
        type: Boolean,
        default: true
      },
      // 操作类型(0:新增 1:修改  2:查看)
      operateType: {
        type: Number,
        default: -1
      },
      //是否为生成单据操作
      isGenerateOperateType: {
        type: Number,
        default: -1
      },
      //回显单据数据
      originalBillData: {
        type: Object,
        default () {
          return {}
        }
      },
      //节点分类标志(0:销售 1:生产 2:库存  3:采购)
      billsTypeSign: {
        type: Number,
        default: -1
      },
      //回显单据原id(-1:新增 其他:生成)
      generateBillsId: {
        type: Number,
        default: -1
      },
      //区分执行单和计划单
      originBillType: {
        type: Number,
        default: 0
      },
    },
    data() {
      return {
        //*********组件数据*********
        //源单相关
        originBillsDataList: [], //源单表格数据(计划单和工序单时为母件明细)
        originBillsIdList: [], //当前源单列表的单据id数组
        originBillsNoList: [], //当前源单列表的单据编号数组
        originDepotBillsIdList: [], //当前源单列表对应的出入库单数组
        originBillsAmountList: [], //当前源单列表实际金额数组
        originBillsProDataList: [], //源单货品明细数据
        machineData: [], //加工明细
        mesOriginType: 0, //生产模块源单分类

        //出入库单相关
        deleteBillIdArr: [], //手动删除的数据
        addBillIdArr: [], //手动添加的数据

        //总计相关
        totalAmountData: {}, //总计金额

        //公共数据
        checkTab: "0", //当前tab页选中(销售,采购)
        mescheckTab: "0", //当前生产tab页选中
        checkTabReq: false, //切换tab页时是否需要发送请求(立即加载点击后才能发送请求)
        relvancebillSign: -1, //关联单据分类标识

        //其它费用子组件
        isTriggerFind: false, //是否触发子组件查询方法

        //传入公共单据子组件的数据
        nodeTypeCodeView: "", //节点类型编码
        billsTypeSignView: 0, //单据分类标签(0代表销售)
        billsClassifyView: -1, //单据分类
        operateTypeView: 0, //操作类型(0:新增 1:修改  2:查看)
        generateBillsIdView: -1, //需要生成其他单据的id
        billsTypeDefaultCodeView: '', //默认单据类型
        updateDefaultDataView: { //修改时默认流程和节点等信息
          nodeId: -1, //节点id
          processId: -1, //流程id
        },
        show_nodeBills: false, //控制预览单据组件是否加载

        //辅助数据
        loading: '', //loading框
        showListFiledData: [], //列表可显示的字段
        showListFiledArr: [], //列表需要显示的
      }
    },
    beforeCreate() {
      $this = this;
    },
    mounted() {
      //初始化列表显示字段数据
      this.initListShowFiledData();
    },
    computed: {
      ...mapState({
        // 表单相关
        bills_base_data: state => state.commComponent.bills_base_data, //表单填写的基本信息数据
        bills_type_data: state => state.commComponent.bills_type_data, //当前单据类型数据
        customer_data: state => state.commComponent.customer_data, //单据所选客户信息
        bills_sel_detail: state => state.commComponent.bills_sel_detail, //关联单据详情数据
        bills_relvence_sign: state => state.commComponent.bills_relvence_sign, //关联单据分类标识
        is_load_condition_bills: state => state.commComponent.is_load_condition_bills, //是否立即加载指定条件单据(对账单使用)
        costInStateIn: state => state.bill_state.costInStateIn, //收款状态
        costInStateOut: state => state.bill_state.costInStateOut, //付款状态
        //权限相关
        companyparamlist: state => state.companyparamlist, //获取企业级参数
        //控制相关
        checkCustomerListFileData: state => state.bill_list_config.checkCustomerListFileData, //客户对账单列表可显示字段
        checkSupplierListFileData: state => state.bill_list_config.checkSupplierListFileData, //供应商对账单列表可显示字段
      })
    },
    watch: {
      // 监听是否立即加载组件数据
      is_load_condition_bills(newVal) {
        // 判断是新增界面预览界面
        if (this.operateType == 0) { //新增界面
          //调用获取对账单详情数据方法
          this.getCheckBillsDate();
        } else if (this.operateType == 2) { //预览界面
          //调用数据回显方法
          this.getPreviewData();
        }
      },
      // 监听tab页切换
      checkTab(newVal) {
        this.checkTabChange(newVal);
      },
      mescheckTab(newVal){
        this.checkTabChange(newVal);
      },
      //监听父组件是否需要提交当前信息
      isCommitFormData(newVal) {
        this.commitNodeTableMsg();
      },
      // 监听显示列表数据发生变化
      showListFiledData: {
        handler(newVal) {
          this.getCurShowFiledArr();
        },
        deep: true
      },
    },
    methods: {
      ...mapMutations([
        'RELVANCE_BILLID_ARR', //关联单据明细id数组
        'RELVANCE_BILLID_SIGN', //关联单据明细分类
        'DEPOT_SEL_ID_ARR', //当前对账单出入库单id数组
        'BILLS_MSG_BAK', //备份单据vuex数据
      ]),

      /* (销售,采购)tab页切换触发 */
      checkTabChange(type) {
        // 获取源单明细
        console.log(type)
        if (this.originBillsDataList.length == 0) { //无数据时才请求
          this.getCheckBillsDate();
        }

        if (type == 2) {

          //获取其它费用(根据对账单类型获取模块分类标识)
          if (this.billsClassify == 5) { //销售(客户)对账单
            this.relvancebillSign = 0;
          } else if (this.billsClassify == 6) { //采购(供应商)对账单
            this.relvancebillSign = 3;
          } else if (this.billsClassify == 7) { //生产对账单
            this.relvancebillSign = 1;
          }
          //触发子组件查询方法
          this.isTriggerFind = !this.isTriggerFind;
        }
      },

      /* 获取对账单详情(源单明细)数据 */
      getCheckBillsDate() {
        //清空手动修改的数据
        this.deleteBillIdArr = []; //手动删除的数据
        this.addBillIdArr = []; //手动新增的数据

        //定义公共查询条件数据
        let conditionData = {
          pageIndex: 1, //当前页
          pageSize: 1000, //页面大小
          deleteFlag: 0, //删除标识
          isSubmit: 4, //已审核的单据
        };
        //判断是否有选择源单
        if (this.bills_base_data.hasOwnProperty("relevance_bills") && !!this.bills_base_data.relevance_bills) { //有选择
          this.addBillIdArr = this.bills_base_data.relevance_bills.split(",");
          conditionData.addByHandDepotId = this.bills_base_data.relevance_bills;
        }

        //判断是否有客户条件
        if (this.bills_base_data.hasOwnProperty("customer_id")) {
          if (this.bills_base_data.customer_id == "") {
            this.$message({
              type: 'warning',
              message: "请选择客户",
              duration: this.elDuration
            })
            return
          }
          conditionData.customerId = this.bills_base_data.customer_id;
        }
        //判断是否有供应商条件
        if (this.bills_base_data.hasOwnProperty("supplier_id")) {
          if (this.bills_base_data.supplier_id == "") {
            this.$message({
              type: 'warning',
              message: "请选择供应商",
              duration: this.elDuration
            })
            return
          }
          conditionData.supplierId = this.bills_base_data.supplier_id;
        }
        //判断是否有周期开始时间
        if (this.bills_base_data.hasOwnProperty("period_start_time")) {
          conditionData.startTime = this.bills_base_data.period_start_time;
        }
        //判断是否有周期结束时间
        if (this.bills_base_data.hasOwnProperty("period_end_time")) {
          conditionData.endTime = this.bills_base_data.period_end_time;
        }

        //判断是否为对账单模块下
        if (this.billsTypeSign == 9) {
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '源单明细数据加载中,请稍候...');
          //判断对账单类型
          if (this.billsClassify == 5) { //销售(客户)对账单
            conditionData.isOriginBill = 1;//设置查询有源单的
            conditionData.isOriginSubItem = 1; //设置需要源单信息
            conditionData.isDepotSubitem = 1; //设置需要货品明细
            conditionData.depotClassify = 1; //设置查询出库单
            conditionData.relevanceBillsSign = 0;//设置源单分类标识为销售
            //判断是否加载已付款
            if (this.bills_base_data.hasOwnProperty("is_pay_success" && this.bills_base_data.is_pay_success == 1)) {
              conditionData.originSalePayState = "0,1,2,3,4"; //未付款 付款中 结算周期 已付款 对账中
            } else {
              conditionData.originSalePayState = "0,1,2,4"; //未付款 付款中 结算周期 对账中
            }
            //发送请求
            stockApi.findDepotMainByCondition(conditionData).then(res => {
              this.loading.close();
              if (res.code == 200) {
                //清空先
                this.originBillsDataList = [];
                this.originBillsIdList = [];
                this.originBillsNoList = [];
                this.originDepotBillsIdList = [];
                this.originBillsAmountList = [];
                //循环获取界面显示数据
                res.data.rows.forEach((item, index) => {
                  //循环处理数据
                  item.depotMainProNumList.forEach((itemJ, indexJ) => {
                    let itemObj = {
                      proData: itemJ, //出库单货品明细
                      billData: item.depotMain, //出库单主表信息
                      originBillData: item.saleOrderMain, //源销售单数据
                      depotBillingTypeInfo: item.billingTypeInfo, //出入库单单据类型数据
                      web_reality_amount: itemJ.priceTotal, //实收金额,默认等于货品销售金额
                    }
                    //判断是否有客户订单号
                    if (!!item.saleOrderMain.customerBills) { //有值禁用掉客户单据号
                      itemObj.web_disableCustomerBills = true;
                    } else {
                      itemObj.web_disableCustomerBills = false;
                    }

                    //判断是否有客户物料编号
                    if (!!itemJ.partnerProductCode) { //有值禁用掉物料编号
                      itemObj.web_disablePartnerProductCode = true;
                    } else {
                      itemObj.web_disablePartnerProductCode = false;
                    }

                    this.originBillsDataList.push(itemObj);
                  })
                  //获取订单相关数据
                  if (!this.originBillsIdList.includes(item.saleOrderMain.id)) {
                    this.originBillsIdList.push(item.saleOrderMain.id);
                    this.originBillsNoList.push(item.saleOrderMain.billsNo);
                    this.originBillsAmountList.push(item.saleOrderMain.practicalAmount);
                    //获取出入库单id数组
                    this.originDepotBillsIdList[this.originBillsIdList.length - 1] = [item.depotMain.id];
                  } else {
                    //获取出入库单id数组
                    this.originDepotBillsIdList[this.originBillsIdList.length - 1].push(item.depotMain.id)
                  }
                })
                //将订单id存入vuex
                this.RELVANCE_BILLID_ARR(this.originBillsIdList);
                this.RELVANCE_BILLID_SIGN(0);
                //将出入库单id存入vuex
                let depotIdArr = [];
                this.originDepotBillsIdList.forEach((item, index) => {
                  item.forEach((itemJ, indexJ) => {
                    if (!this.addBillIdArr.includes(itemJ)) {
                      depotIdArr.push(itemJ);
                    }
                  })
                })
                this.DEPOT_SEL_ID_ARR(depotIdArr);
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          } else if (this.billsClassify == 6) { //采购(供应商)对账单
            conditionData.isOriginBill = 1;//设置查询有源单的
            conditionData.isOriginSubItem = 1; //设置需要源单信息
            conditionData.isDepotSubitem = 1; //设置需要货品明细
            conditionData.depotClassify = 0; //设置查询入库单
            conditionData.relevanceBillsSign = 3;//设置源单分类标识为采购
            //判断是否加载已付款
            if (this.bills_base_data.hasOwnProperty("is_pay_success" && this.bills_base_data.is_pay_success == 1)) {
              conditionData.originPurchasePayState = "0,1,2,3,4";
            } else {
              conditionData.originPurchasePayState = "0,1,2,4";
            }
            //发送请求
            stockApi.findDepotMainByCondition(conditionData).then(res => {
              this.loading.close();
              if (res.code == 200) {
                //清空先
                this.originBillsDataList = [];
                //循环获取界面显示数据
                res.data.rows.forEach((item, index) => {
                  //循环处理数据
                  item.depotMainProNumList.forEach((itemJ, indexJ) => {
                    let itemObj = {
                      proData: itemJ, //出库单货品明细
                      billData: item.depotMain, //出库单主表信息
                      originBillData: item.purchaseOrderMain, //源采购单
                      depotBillingTypeInfo: item.billingTypeInfo, //出入库单单据类型数据
                      web_reality_amount: itemJ.money, //实付金额,默认等于单据金额
                    }

                    //判断是否有送货单号
                    if (!!item.depotMain.deliverNo) { //有值禁用掉送货单号
                      itemObj.web_disableDeliverNo = true;
                    } else {
                      itemObj.web_disableDeliverNo = false;
                    }
                    //判断是否有供应商物料编号
                    if (!!itemJ.partnerProductCode) { //有值禁用掉物料编号
                      itemObj.web_disablePartnerProductCode = true;
                    } else {
                      itemObj.web_disablePartnerProductCode = false;
                    }

                    this.originBillsDataList.push(itemObj);
                  })
                  //获取订单相关数据
                  if (!this.originBillsIdList.includes(item.purchaseOrderMain.id)) {
                    this.originBillsIdList.push(item.purchaseOrderMain.id);
                    this.originBillsNoList.push(item.purchaseOrderMain.billsNo);
                    this.originBillsAmountList.push(item.purchaseOrderMain.practicalAmount);
                    //获取出入库单id数组
                    this.originDepotBillsIdList[this.originBillsIdList.length - 1] = [item.depotMain.id];
                  } else {
                    //获取出入库单id数组
                    this.originDepotBillsIdList[this.originBillsIdList.length - 1].push(item.depotMain.id)
                  }
                })
                //将订单id存入vuex
                this.RELVANCE_BILLID_ARR(this.originBillsIdList);
                this.RELVANCE_BILLID_SIGN(3);
                //将出入库单id存入vuex
                let depotIdArr = [];
                this.originDepotBillsIdList.forEach((item, index) => {
                  item.forEach((itemJ, indexJ) => {
                    if (!this.addBillIdArr.includes(itemJ)) {
                      depotIdArr.push(itemJ);
                    }
                  })
                })
                this.DEPOT_SEL_ID_ARR(depotIdArr);
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          } else if (this.billsClassify == 7) { //生产对账单
            //判断是否有生产类型条件
            if (this.bills_base_data.hasOwnProperty("manufacture_type")) {
              conditionData.manufacture_type = this.bills_base_data.manufacture_type;
            }
            //判断是否有部门条件
            if (this.bills_base_data.hasOwnProperty("dept_id")) {
              conditionData.dept_id = this.bills_base_data.dept_id;
            }

            //判断是计划单和执行单
            if (this.bills_base_data.hasOwnProperty("origin_bill_type")) {
              //获取生产源单分类
              this.mesOriginType = this.bills_base_data.origin_bill_type;
              //判断源单类型
              if (this.bills_base_data.origin_bill_type == 0) { //计划单
              this.originBillType=0;
                conditionData.isOriginSubItem = 1; //设置需要源单信息
                conditionData.isDepotSubitem = 1; //设置需要货品明细
                conditionData.relevanceBillsSign = 1;//设置源单分类标识为生产计划
                //判断是否加载已付款
                if (this.bills_base_data.hasOwnProperty("is_pay_success" && this.bills_base_data.is_pay_success == 1)) {
                  conditionData.originMesPayState = "0,1,2,3,4";
                } else {
                  conditionData.originMesPayState = "0,1,2,4";
                }
                //发送请求
                stockApi.findDepotMainByCondition(conditionData).then(res =>{
                  this.loading.close();
                  if (res.code == 200) {
                    //清空先
                    this.originBillsDataList = [];
                   //清空加工明细
                    this.machineData = [];
                    //循环获取界面显示数据
                    res.data.rows.forEach((item, index) => {
                      //将源单数量多余的0去除
                      item.mesMain.relevance_number=Number(item.mesMain.relevance_number)
                       //获取加工明细
                        item.mesMain.mesMaterial.forEach((itemJ, indexJ) => {
                          let itemObj = {
                            materialData: itemJ, //物料数据
                            billData: item.mesMain, //工序数据
                          }
                          this.machineData.push(itemObj);
                        })

                      //循环处理数据
                      item.depotMainProNumList.forEach((itemJ, indexJ) => {
                        let itemObj = {
                          proData: itemJ, //出库单货品明细
                          billData: item.depotMain, //出库单主表信息
                          originBillData: item.mesMain, //源采购单
                          depotBillingTypeInfo: item.billingTypeInfo, //出入库单单据类型数据
                          web_reality_amount: itemJ.money, //实付金额,默认等于单据金额
                        }

                        //判断是否有送货单号
                        if (!!item.depotMain.deliverNo) { //有值禁用掉送货单号
                          itemObj.web_disableDeliverNo = true;
                        } else {
                          itemObj.web_disableDeliverNo = false;
                        }

                        this.originBillsDataList.push(itemObj);
                        console.log(this.originBillsDataList);
                      })
                      //获取订单相关数据
                      if (!this.originBillsIdList.includes(item.mesMain.id)) {
                        this.originBillsIdList.push(item.mesMain.id);
                        this.originBillsNoList.push(item.mesMain.bills_no);
                        this.originBillsAmountList.push(item.mesMain.total_amount);
                        //获取出入库单id数组
                        this.originDepotBillsIdList[this.originBillsIdList.length - 1] = [item.depotMain.id];
                      } else {
                        //获取出入库单id数组
                        this.originDepotBillsIdList[this.originBillsIdList.length - 1].push(item.depotMain.id)
                      }
                    })
                    //将订单id存入vuex
                    this.RELVANCE_BILLID_ARR(this.originBillsIdList);
                    this.RELVANCE_BILLID_SIGN(1);
                    //将出入库单id存入vuex
                    let depotIdArr = [];
                    this.originDepotBillsIdList.forEach((item, index) => {
                      item.forEach((itemJ, indexJ) => {
                        if (!this.addBillIdArr.includes(itemJ)) {
                          depotIdArr.push(itemJ);
                        }
                      })
                    })
                    this.DEPOT_SEL_ID_ARR(depotIdArr);
                  }else{
                    this.$message({
                      type: 'error',
                      message: res.message,
                      duration: this.elDuration
                    })
                  }
                })
              } else if (this.bills_base_data.origin_bill_type == 1) { //执行单
              this.originBillType=1;
               conditionData.isOriginSubItem = 1; //设置需要源单信息
                conditionData.isDepotSubitem = 1; //设置需要货品明细
                conditionData.relevanceBillsSign = 7;//设置源单分类标识为生产执行单的时候
                //判断是否加载已付款
                if (this.bills_base_data.hasOwnProperty("is_pay_success" && this.bills_base_data.is_pay_success == 1)) {
                  conditionData.originMesPayState = "0,1,2,3,4";
                } else {
                  conditionData.originMesPayState = "0,1,2,4";
                }
                //发送请求
                stockApi.findDepotMainByCondition(conditionData).then(res =>{
                  this.loading.close();
                  if (res.code == 200) {
                    //清空先
                    this.originBillsDataList = [];
                   //清空加工明细
                    this.machineData = [];
                    //循环获取界面显示数据
                    res.data.rows.forEach((item, index) => {
                      //将源单数量多余的0去除
                      item.mesProcess.relevance_number=Number(item.mesProcess.relevance_number)
                       //获取加工明细
                        item.mesProcess.mesProcessMaterials.forEach((itemJ, indexJ) => {
                          let itemObj = {
                            materialData: itemJ, //物料数据
                            billData: item.mesProcess, //工序数据
                          }
                          this.machineData.push(itemObj);
                        })

                      //循环处理数据
                      item.depotMainProNumList.forEach((itemJ, indexJ) => {
                        let itemObj = {
                          proData: itemJ, //出库单货品明细
                          billData: item.depotMain, //出库单主表信息
                          originBillData: item.mesProcess, //源采购单
                          depotBillingTypeInfo: item.billingTypeInfo, //出入库单单据类型数据
                          web_reality_amount: itemJ.money, //实付金额,默认等于单据金额
                        }

                        //判断是否有送货单号
                        if (!!item.depotMain.deliverNo) { //有值禁用掉送货单号
                          itemObj.web_disableDeliverNo = true;
                        } else {
                          itemObj.web_disableDeliverNo = false;
                        }

                        this.originBillsDataList.push(itemObj);
                        console.log(this.originBillsDataList);
                      })
                      //获取订单相关数据
                      if (!this.originBillsIdList.includes(item.mesProcess.id)) {
                        this.originBillsIdList.push(item.mesProcess.id);
                        this.originBillsNoList.push(item.mesProcess.billsNo);
                        this.originBillsAmountList.push(item.mesProcess.totalAmount);
                        //获取出入库单id数组
                        this.originDepotBillsIdList[this.originBillsIdList.length - 1] = [item.depotMain.id];
                      } else {
                        //获取出入库单id数组
                        this.originDepotBillsIdList[this.originBillsIdList.length - 1].push(item.depotMain.id)
                      }
                    })
                    //将订单id存入vuex
                    this.RELVANCE_BILLID_ARR(this.originBillsIdList);
                    this.RELVANCE_BILLID_SIGN(1);
                    //将出入库单id存入vuex
                    let depotIdArr = [];
                    this.originDepotBillsIdList.forEach((item, index) => {
                      item.forEach((itemJ, indexJ) => {
                        if (!this.addBillIdArr.includes(itemJ)) {
                          depotIdArr.push(itemJ);
                        }
                      })
                    })
                    this.DEPOT_SEL_ID_ARR(depotIdArr);
                  }else{
                    this.$message({
                      type: 'error',
                      message: res.message,
                      duration: this.elDuration
                    })
                  }
                })
              }
            }
          }
        }

        // 获取总计数据
        this.getCostCheckTotalAmount(0);
      },

      /* 获取总计数据type(0::默认获取   1:手动删除/新增后获取   2:修改实际金额后获取) */
      getCostCheckTotalAmount(type) {
        let costCheckConditon = {
          customerId: this.bills_base_data.customer_id, //客户id
          supplierId: this.bills_base_data.supplier_id, //供应商id
          costClassify: this.billsClassify, //对账分类
          startTime: this.bills_base_data.period_start_time, //周期开始时间
          endTime: this.bills_base_data.period_end_time, //周期结束时间
          costSurplusType: this.bills_base_data.cost_surplus_type, //收付款结余类型
          deleteByHandDepotId: this.deleteBillIdArr.join(","), //手动删除的出入库单id
          addByHandDepotId: this.addBillIdArr.join(","), //手动新增的出入库单id
        }

        //判断对账单类型
        if(this.billsClassify == 7){//生产对账单
          if(this.bills_base_data.hasOwnProperty("origin_bill_type")){
            costCheckConditon.originType = this.bills_base_data.origin_bill_type;
          }
        }

        //判断是否收订修改实际金额后获取
        if (type == 2) {
          let depotProNumId = []; //出入库明细id
          let realityAmount = []; //实际金额
          this.originBillsDataList.forEach((item, index) => {
            //获取id
            depotProNumId.push(item.proData.id);
            //获取金额
            realityAmount.push(parseFloat(item.web_reality_amount));
          })
          //设置请求数据
          costCheckConditon.depotProNumId = depotProNumId.join(","); //货品明细id
          costCheckConditon.realityAmount = realityAmount.join(","); //实际金额
        }
        //发送请求
        costApi.findCostCheckTotalAmount(costCheckConditon).then(res => {
          if (res.code == 200) {
            this.totalAmountData = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
          //判断获取类型
          if (type == 1) {
            //给出提示信息
            this.$message({
              type: 'success',
              message: '删除成功!',
              duration: this.elDuration
            });
            //关闭loading框
            this.loading.close();
          }
        })
      },

      /* 获取数据预览方法 */
      getPreviewData() {
        //定义公共查询条件数据
        let conditionData = {
          pageIndex: 1, //当前页
          pageSize: 1000, //页面大小
        };
        //判断是否有选择源单
        if (this.bills_base_data.hasOwnProperty("relevance_bills") && !!this.bills_base_data.relevance_bills) { //有选择
          conditionData.id = this.bills_base_data.relevance_bills;
        }

        //判断是否为对账单模块下
        if (this.billsTypeSign == 9) {
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '源单明细数据加载中,请稍候...');
          //判断对账单类型
          if (this.billsClassify == 5) { //销售(客户)对账单
            conditionData.isOriginSubItem = 1; //设置需要源单信息
            conditionData.isDepotSubitem = 1; //设置需要货品明细
            conditionData.isRealityAmount = 1;//设置需要获取对账实际金额信息
            conditionData.costCheckId = this.originalBillData.costAccountCheck.id;//设置对账单id
            //获取需要回显的源单明细信息
            if (this.originalBillData.costAccountSubitems) {
              //定义所需参数
              let depotMainIdArr = []; //出入库单数据id
              let orderIdArr = []; //订单id数组
              //循环获取数据
              this.originalBillData.costAccountSubitems.forEach((item, index) => {
                orderIdArr.push(item.relevanceBills);
                //获取出入库单id数组
                if (!!item.depotMainId) {
                  //分割出入库单多个的情况
                  let depotIdArr = item.depotMainId.split(",");
                  depotIdArr.forEach((itemJ, indexJ) => {
                    depotMainIdArr.push(itemJ);
                  })
                }
              })
              //设置查询条件
              conditionData.id = depotMainIdArr.join(",");
              //将订单id数组存入vuex
              this.RELVANCE_BILLID_ARR(orderIdArr);
              this.RELVANCE_BILLID_SIGN(0);
            }
            //发送请求
            stockApi.findDepotMainByCondition(conditionData).then(res => {
              if (res.code == 200) {
                //清空先
                this.originBillsDataList = [];
                //循环获取界面显示数据
                res.data.rows.forEach((item, index) => {
                  item.depotMainProNumList.forEach((itemJ, indexJ) => {
                    let itemObj = {
                      proData: itemJ, //出库单货品明细
                      billData: item.depotMain, //出库单主表信息
                      originBillData: item.saleOrderMain, //源销售单数据
                      web_reality_amount: itemJ.costAccountCheckAmount?itemJ.costAccountCheckAmount.costCheckAmount:0, //实际金额
                      web_disableCustomerBills: true, //客户单据号是否禁用
                      web_disablePartnerProductCode: true, //合作伙伴物料号是否禁用
                      depotBillingTypeInfo: item.billingTypeInfo, //出入库单单据类型数据
                    }
                    this.originBillsDataList.push(itemObj);
                  })
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
              this.loading.close();
            })
          } else if (this.billsClassify == 6) { //采购(供应商)对账单
            conditionData.isOriginSubItem = 1; //设置需要源单信息
            conditionData.isDepotSubitem = 1; //设置需要货品明细
            conditionData.isRealityAmount = 1;//设置需要获取对账实际金额信息
            conditionData.costCheckId = this.originalBillData.costAccountCheck.id;//设置对账单id
            //获取需要回显的源单明细信息
            if (this.originalBillData.costAccountSubitems) {
              //定义所需参数
              let depotMainIdArr = []; //出入库单数据id
              let orderIdArr = []; //订单id数组
              this.originalBillData.costAccountSubitems.forEach((item, index) => {
                orderIdArr.push(item.relevanceBills);
                //获取出入库单id数组
                if (!!item.depotMainId) {
                  //分割出入库单多个的情况
                  let depotIdArr = item.depotMainId.split(",");
                  depotIdArr.forEach((itemJ, indexJ) => {
                    depotMainIdArr.push(itemJ);
                  })
                }
              })
              //设置查询条件
              conditionData.id = depotMainIdArr.join(",");
              //将订单id数组存入vuex
              this.RELVANCE_BILLID_ARR(orderIdArr);
              this.RELVANCE_BILLID_SIGN(0);
            }
            //发送请求
            stockApi.findDepotMainByCondition(conditionData).then(res => {
              if (res.code == 200) {
                //清空先
                this.originBillsDataList = [];
                //循环获取界面显示数据
                res.data.rows.forEach((item, index) => {
                  item.depotMainProNumList.forEach((itemJ, indexJ) => {
                    let itemObj = {
                      proData: itemJ, //出库单货品明细
                      billData: item.depotMain, //出库单主表信息
                      originBillData: item.purchaseOrderMain, //源采购单
                      web_reality_amount: itemJ.costAccountCheckAmount?itemJ.costAccountCheckAmount.costCheckAmount:0, //实际金额
                      web_disableDeliverNo: true, //送货单号是否禁用
                      web_disablePartnerProductCode: true, //合作伙伴物料号是否禁用
                      depotBillingTypeInfo: item.billingTypeInfo, //出入库单单据类型数据
                    }
                    this.originBillsDataList.push(itemObj);
                  })
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
              this.loading.close();
            })
          }
        }

        // 获取总计数据
        let amountArr = [];
        amountArr.push(this.originalBillData.costAccountCheck.totalAmount); //单据金额
        amountArr.push(this.originalBillData.costAccountCheck.totalTaxAmount); //单据含税金额
        let textArrData = {
          numberArr: amountArr.join(",")
        }
        //转换数据
        orderCommApi.transitionNumberToText(textArrData).then(res => {
          if (res.code == 200) {
            this.totalAmountData = {
              totalAmount: this.originalBillData.costAccountCheck.totalAmount,
              totalAmountZn: res.data[0],
              totalTaxAmount: this.originalBillData.costAccountCheck.totalTaxAmount,
              totalTaxAmountZn: res.data[1]
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 显示单据公共组件 */
      showNodeBillsBox(data, sign, classify, depotBillingTypeInfo) {
        //判断是否有相应的权限
        if (this.commonJsExtend.judgeBillsSelPowerBySign(sign, classify)) {
          this.$message({
            type: 'warning',
            message: '管理员未给您开通相应查询权限,请确认!',
            duration: this.elDuration
          })
          return;
        }
        //公共数据
        //将操作设置为修改或预览
        this.operateTypeView = 2;

        //设置单据分类类型
        this.billsClassifyView = classify;
        //设置单据分类code
        this.nodeTypeCodeView = this.commonJsExtend.findNodeTypeCodeBySign(sign);
        //设置单据类型标签(设置为对应模块)
        this.billsTypeSignView = sign;

        //设置单据类型(有设depotBillingTypeInfo为出入库单据内容)
        if (!!depotBillingTypeInfo) {
          this.billsTypeDefaultCodeView = depotBillingTypeInfo.code;
        } else {
          this.billsTypeDefaultCodeView = data.originBillData.billingTypeInfo.code;
        }

        //特殊数据
        if (sign == 0) { //销售
          //获取需要回显的单据id
          this.generateBillsIdView = data.originBillData.id;
          //设置默认相关数据
          this.updateDefaultDataView = {
            nodeId: data.originBillData.statusId, //节点id
            processId: data.originBillData.processId, //流程id
          }
        } else if (sign == 1) { //生产计划
          //获取需要回显的单据id
          this.generateBillsIdView = data.id;
          //设置默认相关数据
          this.updateDefaultDataView = {
            nodeId: data.saleOrderMain.status_id, //节点id
            processId: data.saleOrderMain.process_id, //流程id
          }
        } else if (sign == 2) { //出入库
          //获取需要回显的单据id
          this.generateBillsIdView = data.billData.id;
          //设置默认相关数据
          this.updateDefaultDataView = {
            nodeId: data.billData.statusId, //节点id
            processId: data.billData.processId, //流程id
          }
        } else if (sign == 3) { //采购
          //获取需要回显的单据id
          this.generateBillsIdView = data.originBillData.id;
          //设置默认相关数据
          this.updateDefaultDataView = {
            nodeId: data.originBillData.statusId, //节点id
            processId: data.originBillData.processId, //流程id
          }
        }

        //备份单据vuex数据
        this.BILLS_MSG_BAK();

        //显示操作弹框
        this.show_nodeBills = true;
      },

      /* 删除源单数据行 */
      deleteOriginData(data) {
        console.log(data);
        this.$confirm('确认删除该条数据吗,删除时会将与该条数据相关的单据全部删除, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //定义一个重新接收的数组
          let newOriginBillsDataList = [];
          let newOriginBillsIdList = [];//新的源单id数组
          //循环当前表格数据
          this.originBillsDataList.forEach((item, index) => {
            //根据出入库单据id删除
            if (item.billData.id != data.billData.id) {
              newOriginBillsDataList.push(item);
              if(!newOriginBillsIdList.includes(item.originBillData.id)){
                newOriginBillsIdList.push(item.originBillData.id);
              }
            } else {
              //将手动删除的数据存入
              if (!this.deleteBillIdArr.includes(data.billData.id)) {
                //删除的出入库单据id
                this.deleteBillIdArr.push(data.billData.id);
              }

              //删除出入库单id
              let newOriginDepotBillsIdList = [];
              this.originDepotBillsIdList.forEach((itemI,indexI)=>{
                let newItemI = [];
                itemI.forEach((itemJ,indexJ)=>{
                  if(itemJ != item.billData.id){
                    newItemI.push(itemJ);
                  }
                })

                //获取新的出入库单据id
                newOriginDepotBillsIdList.push(newItemI);
              })
              //将处理好的数据赋值
              this.originDepotBillsIdList = newOriginDepotBillsIdList;
            }
          })

          //获取删除后的数据
          this.originBillsDataList = newOriginBillsDataList;

          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '源单明细数据删除中,请稍候...');
          //重新获取总计价格
          this.getCostCheckTotalAmount(1);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: this.elDuration
          });
        });
      },

      /* 关闭单据公共弹框 */
      closeNodeBills() {
        this.show_nodeBills = false;
      },

      /*初始化列表显示字段数据 */
      initListShowFiledData() {
        //判断模块分类
        if (this.billsClassify == 5) { //销售对账单
          //获取界面显示数组
          this.showListFiledArr = this.commonJsExtend.initListShowFiledData('checkCustomerListArr', this
              .checkCustomerListFileData)
            .showListFiledArr;
          //获取控制数组
          this.showListFiledData = this.commonJsExtend.initListShowFiledData('checkCustomerListArr', this
              .checkCustomerListFileData)
            .showListFiledData;
        } else if (this.billsClassify == 6) { //供应商对账单
          //获取界面显示数组
          this.showListFiledArr = this.commonJsExtend.initListShowFiledData('checkSupplierListArr', this
              .checkSupplierListFileData)
            .showListFiledArr;
          //获取控制数组
          this.showListFiledData = this.commonJsExtend.initListShowFiledData('checkSupplierListArr', this
              .checkSupplierListFileData)
            .showListFiledData;
        }
      },

      /* 获取当前需要显示的列表字段 */
      getCurShowFiledArr() {
        if (this.billsClassify == 5) { //销售对账单
          //将数据存入cookie
          this.showListFiledArr = this.commonJsExtend.getCurShowFiledArr('checkCustomerListArr', this
            .showListFiledData);
        } else if (this.billsClassify == 6) { //供应商对账单
          //将数据存入cookie
          this.showListFiledArr = this.commonJsExtend.getCurShowFiledArr('checkSupplierListArr', this
            .showListFiledData);
        }
      },

      /* 提交当前组件填写信息 */
      commitNodeTableMsg() {
        //获取对账明细数据
        let checkDataList = [];
        let updatePartnerCodeObj = {}; //修改的合作伙伴物料编号
        let realityAmountObj = {}; //修改的实际交易金额数据
        let costAccountCheckAmount = [];//实际金额数据
        this.originBillsIdList.forEach((item, index) => {
          //判断未删除的才添加
          if(!this.deleteBillIdArr.includes()){
            //定义后端接收参数
            let checkItem = {
              relevance_bills: item, //单据id
              relevance_bills_no: this.originBillsNoList[index], //单据编号
              depot_main_id: this.originDepotBillsIdList[index].join(","), //销售,采购关联的出入库单
              relevance_bills_amount: this.originBillsAmountList[index], //单据实际金额
            }

            //根据模块处理需要修改的数据
            if (this.billsClassify == 5) { //客户对账
              this.originBillsDataList.forEach((itemJ, indexJ) => {
                //根据id匹配客户单据号
                if (item == itemJ.originBillData.id) {
                  checkItem.originCustomerBills = itemJ.originBillData.customerBills;
                }
              })
            } else if (this.billsClassify == 6) { //供应商对账
              let depotDeliverNoArr = [];
              this.originBillsDataList.forEach((itemJ, indexJ) => {
                //根据id匹配客户单据号
                this.originDepotBillsIdList[index].forEach((itemK, indexK) => {
                  if (itemJ.billData.id == parseInt(itemK)) {
                    depotDeliverNoArr[indexK] = itemJ.billData.deliverNo;
                  }
                })
              })
              checkItem.depotMainDeliveNo = depotDeliverNoArr.join(",");
            }

            //处理需要修改的出入库明细数据
            let idArr = []; //货品id数组
            let partnerProCodeArr = []; //合作伙伴物料编号数组
            let realityMoney = []; //实际金额

            //清空(只需要一个该数据就行)
            costAccountCheckAmount = [];
            //循环获取数据
            this.originBillsDataList.forEach((itemK, indexK) => {
              idArr.push(itemK.proData.id);
              partnerProCodeArr.push(itemK.proData.partnerProductCode); //合作伙伴物料号(修改出入库单的合作伙伴物料编号)
              realityMoney.push(itemK.web_reality_amount); //实际金额

              //获取实际金额数据
              let itemObj = {
                depot_main_id:itemK.proData.outinRecordId,//出入库单id
                depot_pro_id:itemK.proData.id,//出入库单货品明细id
                cost_check_amount:itemK.web_reality_amount,//实际金额
              }
              costAccountCheckAmount.push(itemObj);
            })
            //定义后端处理数据(合作伙伴物料编号)
            updatePartnerCodeObj = {
              idStr: idArr.join(","), //需要修改的记录id字符串
              partnerProCodeStr: partnerProCodeArr.join(","), //需要修改的货品合作伙伴物料编号字符串
            };
            //定义后端处理数据(实际金额)
            realityAmountObj = {
              idStr: idArr.join(","), //需要修改的记录id字符串
              realityMoneyStr: realityMoney.join(","), //需要修改的货品实际金额字符串
            };

            //处理sign
            if (this.billsClassify == 5) { //客户对账
              checkItem.sign = 0;
            } else if (this.billsClassify == 6) { //供应商对账
              checkItem.sign = 3;
            } else if (this.billsClassify == 7) { //生产对账单
              if (this.bills_base_data.origin_bill_type == 0) { //计划单
                checkItem.sign = 1;
              } else if (this.bills_base_data.origin_bill_type == 1) { //工序单
                checkItem.sign = 7;
              }
            }

            checkDataList.push(checkItem);
          }
        })

        //定义传入父组件的数据
        let data = {
          checkSubItem: checkDataList, //对账明细
          costAccountCheckAmount:costAccountCheckAmount,//对账实际金额
          totalAmount: this.totalAmountData.totalAmount, //对账总金额
          totalTaxAmount: this.totalAmountData.totalTaxAmount, //对账含税总金额
          updatePartnerCodeObj: updatePartnerCodeObj, //修改的合作伙伴物料编号
          realityAmountObj: realityAmountObj, //修改的交易金额字符串
        }

        this.$emit("getNodeTableData", data);
      },

      /* 客户对账单源单明细总计(该方法有问题) */
      getCustomerSummaries(param) {
        const {
          columns,
          data
        } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '合计';
            return;
          }
          let property = '';
          let sumPropertys = []; //指定需要计算的字段(必须在saleOrderMain里)
          if (column.property) {
            property = column.property.split('.')[1];
          }
          const values = data.map(item => Number(item.saleOrderMain[property]));
          if (sumPropertys.indexOf(property) != -1) {
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] = this.commonJsExtend.getDecimalsLen(sums[index], 3);
            } else {
              sums[index] = '';
            }
          }
        });
        return sums;
      },
    },
    filters: {
      // 获取应收金额()
      getReceivableAmount(value, type) {
        let result = 0;
        if (!!value) {
          // 判断源单类型
          if (type == 0) { //销售
            result = eval(value.number * value.saleOrderProInfo.price);
          } else if (type == 3) { //采购
            result = eval(value.number * value.purchaseOrderProinfo.costPrice);
          } else if (type == 1) { //生产计划
            result = eval(value.success_number * value.cost_price);
          } else if (type == 1.1) { //生产计划子件明细
            result = eval(value.need_total_num * value.price);
          } else if (type == 7) { //生产执行
            if (value.generateMaterial) {
              result = eval(value.processMaterialNumber * value.generateMaterial.price);
            }
          } else if (type == 7.1) { //生产执行子件明细
            result = eval(value.processMaterialSuccess * value.price);
          }
        }
        return $this.commonJsExtend.getDecimalsLen(result, 5);
      },
      // 获取含税金额
      getTaxRageAmount(value, originData, type) {
        let result = 0;
        if (!!value && !!originData) {
          //判断类型
          if (type == 0) { //销售
            //获取实际金额
            let receivableAmount = value.web_reality_amount;
            //获取税额
            let taxAmount = 0;
            if (!!originData.taxRage && originData.isRate == 0) {
              taxAmount = eval(receivableAmount * (originData.taxRage / 100));
            }
            //+号是用于处理字符串数字相加
            result = eval(+receivableAmount + taxAmount);
          } else if (type == 3) { //采购
            //获取实际金额
            let receivableAmount = value.web_reality_amount;
            //获取税额
            let taxAmount = 0;
            if (!!originData.taxRage && originData.isRate == 0) {
              taxAmount = eval(receivableAmount * (originData.taxRage / 100));
            }
            //+号是用于处理字符串数字相加
            result = eval(+receivableAmount + taxAmount);
          } else if (type == 1) { //生产计划
            //获取实际金额
            let receivableAmount = value.web_reality_amount;
            //获取税额
            let taxAmount = 0;
            if (!!originData.tax_rage) {
              taxAmount = eval(receivableAmount * originData.tax_rage / 100);
            }
            result = eval(receivableAmount + taxAmount);
          } else if (type == 1.1) { //生产计划加工明细
            //获取实际金额
            let receivableAmount = eval(value.need_total_num * value.price);
            //获取税额
            let taxAmount = 0;
            if (!!originData.tax_rage) {
              taxAmount = eval(receivableAmount * originData.tax_rage / 100);
            }
            result = eval(receivableAmount + taxAmount);
          } else if (type == 7) { //生产执行
            //获取实际金额
            let receivableAmount = 0;
            if (value.generateMaterial) {
              receivableAmount = eval(value.processMaterialNumber * value.generateMaterial.price);
            }
            //获取税额
            let taxAmount = 0;
            if (!!originData.taxRage) {
              taxAmount = eval(receivableAmount * originData.taxRage / 100);
            }
            result = eval(receivableAmount + taxAmount);
          } else if (type == 7.1) { //生产执行加工明细
            //获取实际金额
            let receivableAmount = eval(value.need_total_num * value.price);
            //获取税额
            let taxAmount = 0;
            if (!!originData.tax_rage) {
              taxAmount = eval(receivableAmount * originData.tax_rage / 100);
            }
            result = eval(receivableAmount + taxAmount);
          }
        }
        return $this.commonJsExtend.getDecimalsLen(result, 5);
      },
      // 获取最低开工费用总额
      getProcessTotalMinWorkAmount(value) {
        let result = 0;
        if (value) {
          value.forEach((item, index) => {
            result = result + item.minWorkAmount;
          })
        }
        return result;
      },
      // 获取最低开工费配额总金额
      getProcessTotalMinWorkNumber(value) {
        let result = 0;
        if (value) {
          value.forEach((item, index) => {
            result = result + item.minWorkNumber;
          })
        }
        return result;
      },
      // 获取成品入库单信息
      getDepotMainMsg(value, type) {
        let result = [];
        if (value && value.length > 0) {
          value.forEach((item, index) => {
            if (type == 0) { //编号
              result.push(item.billsNo);
            } else if (type == 1) { //日期
              result.push(item.billsDate);
            }
          })
        }
        return result.join(",");
      },
    },
    components: {
      nodeBills: () => import('@/components/commComponent/billing/nodeBills'),
      nodeTableExpense
    }
  }
</script>

<style lang="less">
  // 对账单内容样式
  .accountCheckBox {

    // 对账单表格框样式
    .accountCheckTable {
      height: 50vh;
      padding: 10px 0;

      // border: 1px solid black;
      // 表格内容数据
      .accountCheck_table {
        height: calc(50vh - 100px);
      }
    }
  }
</style>
