<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：对账单应收应付详情信息组件
	开始时间：2021-04-27
	开发人员：刘巍骏
	最后修改：2021-04-27
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="nodeFormItemBox detailCostBox">
    <!-- 左边提示信息 -->
    <div class="detailLeft">
      本期金额
    </div>

    <!-- 右边内容 -->
    <div class="detailRight">
      <!-- 上部分小明细 -->
      <div class="rightTop">
        <ul class="topBox">
          <li class="topItem">
            <span class="title">订单:</span>
            <span class="value">942,812.00</span>
          </li>
          <li class="topItem">
            <span class="title">应收/付:</span>
            <span class="value">0.00</span>
          </li>
          <li class="topItem">
            <span class="title">已收/付:</span>
            <span class="value">0.00</span>
          </li>
          <li class="topItem">
            <span class="title">待收/付:</span>
            <span class="value">0.00</span>
          </li>
        </ul>
      </div>

      <!-- 下部分总计 -->
      <div class="rightBottom">
        <ul class="rightItemBox">
          <li class="rightItem">
            <span class="title">历史应收合计小写:</span>
            <span class="value">942,812.00</span>
          </li>
          <li class="rightItem">
            <span class="title">历史应收合计大写:</span>
            <span class="value">玫拾肆万贰佰壹拾贰元整</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  export default{
    name:'nodeFormDetailCost',
    data(){
      return{
        //组件数据
      }
    },
    mounted() {

    },
    watch:{},
    methods:{

    }
  }
</script>

<style lang="less">
  // 总框
  .detailCostBox{
    display: flex;
    align-items: center;
    border: 1px solid @borderColor;
    // 左边内容
    .detailLeft{
      width: 150px;
      color: black;
      font-weight: bold;
      text-align: center;
    }
    // 右边内容
    .detailRight{
      width: calc(100% - 148px);
      // border: 1px solid black;
      // 上部分
      .rightTop{
        height: 50%;
        // border: 1px solid black;
        // 外框
        .topBox{
          display: flex;
          justify-content: space-between;
          // 单个内容
          .topItem{
            height: 50px;
            width: 25%;
            display: flex;
            align-items: center;
            border: 1px solid @borderColor;
            // 标题
            .title{
              padding:0 10px;
              font-weight: bold;
            }
            // 数值
            .value{
              color: red;
            }
          }
        }
      }
      //下部分
      .rightBottom{
        height: 50%;
        padding: 10px 0;
        border-left: 1px solid @borderColor;
        // border: 1px solid black;
        .rightItem{
          padding: 5px;
          // 标题
          .title{
            padding:0 10px 0 0;
            font-weight: bold;
          }
          // 数值
          .value{
            color: red;
          }
        }
      }
    }
  }
</style>
