// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：货品相关请求接口
// 开始时间：2021-3-26
// 开发人员：万攀
// 最后修改：2021-3-26
// 备注说明：如需修改请联系开发人员

import {
  request
} from "../request.js";
import qs from 'qs';

export default {
  //货品相关
  addCommodity(data, supplier) { //添加或编辑货品
    return request({
      method: 'POST',
      url: '/datainfo/addOrEditProduct',
      data,
    })
  },
  deleteCommodity(data) { //删除货品
    return request({
      method: 'POST',
      url: '/datainfo/deleteBatchProduct',
      data,
    })
  },
  deleteBatchCommodity(data) { //多选删除货品
    return request({
      method: 'POST',
      url: '/system/dictionary/deleteBatchProduct',
      data,
    })
  },
  searchCommodityById(data) { //根据id查找货品
    return request({
      method: 'POST',
      url: '/system/dictionary/findXProductById',
      data,
    })
  },
  findProductByProductId(data) { //根据id查找货品(货品列表结构的单个货品)
    return request({
      method: 'POST',
      url: '/datainfo/findProductByProductId',
      data,
    })
  },
  searchOnlineStore(data) { //查询是否启用在线商城
    return request({
      method: 'POST',
      url: '/system/dictionary/checkIsOpenOnline',
      data,
    })
  },
  findBaseProductByProductId(data) { //根据货品id查询货品详细的基本信息
    return request({
      method: 'POST',
      url: '/datainfo/findBaseProductByProductId',
      data,
    })
  },
  searchCommodityByClassfiyId(data) { //根据分类id查找产品
    return request({
      method: 'POST',
      url: '/datainfo/findValueXProduct',
      data,
    })
  },
  getProductMsgById(data) { //根据货品id查询货品信息(多个以逗号分割)
    return request({
      method: 'POST',
      url: '/datainfo/findProductPriceStockByProductId',
      data,
    })
  },
  getProductClassfiyCode(data) { //根据货品分类获取编码规则
    return request({
      method: 'POST',
      url: '/system/findProductCodeRulesByClassfiyId',
      data,
    })
  },
  uploadPictures(data) { //上传图片
    return request({
      method: 'POST',
      url: '/datainfo/uploadImg',
      data,
    })
  },
  searchCommodityById(data) { //根据ID查找产品数据
    return request({
      method: 'POST',
      url: '/datainfo/findXProductById',
      data,
    })
  },
  searchSpecsByClassfiyId(data) { //根据分类Id查找规格
    return request({
      method: 'POST',
      url: '/system/dictionary/findSpecValue',
      data,
    })
  },
  getProductCode(data) { //获取货品编码
    return request({
      method: 'POST',
      url: '/datainfo/findProductCodeByCompany',
      data,
    })
  },
  updateProductPrice(data) { //修改货品多计量数据
    return request({
      method: 'POST',
      url: '/datainfo/updateProductPrice',
      data,
    })
  },
  getFollowupInfo(data) { //获取跟进数数据
    return request({
      method: 'POST',
      url: '/sales/findFollowupByProductId',
      data,
    })
  },
  getChanceInfo(data) { //获取报价数数据(已废)
    return request({
      method: 'POST',
      url: '/sales/getOrdersByProductId',
      data,
    })
  },
  getOrderInfo(data) { //获取订单数数据(已废)
    return request({
      method: 'POST',
      url: '/sales/findSaleByProductId',
      data,
    })
  },
  findProdSimpleBillsInfo(data) { //获取样品单数数据
    return request({
      method: 'POST',
      url: '/datainfo/findProdSimpleBillsInfo',
      data,
    })
  },
  findProdOrderBillsInfo(data) { //获取订单数数据
    return request({
      method: 'POST',
      url: '/datainfo/findProdOrderBillsInfo',
      data,
    })
  },
  findProdPurchaseBillsInfo(data) { //获取采购订单单数数据
    return request({
      method: 'POST',
      url: '/datainfo/findProdPurchaseBillsInfo',
      data,
    })
  },
  findProdProducedBillsInfo(data) { //获取生产单已生产数数据
    return request({
      method: 'POST',
      url: '/datainfo/findProdProducedBillsInfo',
      data,
    })
  },
  findProdProductionBillsInfo(data) { //获取生产单生产中数数据
    return request({
      method: 'POST',
      url: '/datainfo/findProdProductionBillsInfo',
      data,
    })
  },
  updateProductPlace(data) { //修改货品仓位号信息
    return request({
      method: 'POST',
      url: '/datainfo/updateProductPlace',
      data,
    })
  },
  updateProductStatusYes(data) { //货品审核通过
    return request({
      method: 'POST',
      url: '/datainfo/updateProductStatusYes',
      data,
    })
  },
  updateProductStatusNo(data) { //货品审核驳回
    return request({
      method: 'POST',
      url: '/datainfo/updateProductStatusNo',
      data,
    })
  },
  soldOutProduct(data) { //下架货品
    return request({
      method: 'POST',
      url: '/datainfo/soldOutProduct',
      data,
    })
  },
  putAwayProduct(data) { //重新上架货品
    return request({
      method: 'POST',
      url: '/datainfo/putAwayProduct',
      data,
    })
  },
  findProductIsBillsUsed(data){ //根据货品id查询货品是否已被单据使用
    return request({
      method: 'POST',
      url: '/datainfo/findProductIsBillsUsed',
      data,
    })
  },


  // 货品导入相关
  importProductByFile(data) { //导入货品
    return request({
      method: 'POST',
      url: '/datainfo/importProductByFile',
      data,
    })
  },
  findImportProductList(data) { //查看产品导入任务列表
     return request({
       method: 'POST',
       url: '/datainfo/findImportProductList',
       data,
     })
   },
   getImportProductResult(data) { //查看货品导入结果
      return request({
        method: 'POST',
        url: '/datainfo/getImportProductResult',
        data,
      })
    },
  findImportProductErrors(data) { //产品导入详情(失败信息列表)
    return request({
      method: 'POST',
      url: '/datainfo/findImportProductErrors',
      data,
    })
  },
  exportProductList(data) { //产品导入详情(失败信息列表)
    return request({
      method: 'POST',
      url: '/orderCommon/billsExport/exportProductList',
      data,
    })
  },
  exportProduct(data) { //货品详细信息导出接口
    return request({
      method: 'POST',
      url: '/orderCommon/ProductExport/exportProduct',
      data,
    })
  },
  exportBomList(data) { //bom货品相关信息导出
    return request({
      method: 'POST',
      url: '/orderCommon/billsExport/exportBomList',
      data,
    })
  },
  exportBomProcessList(data) { //bom货品相关信息导出
    return request({
      method: 'POST',
      url: '/orderCommon/billsExport/exportBomProcessList',
      data,
    })
  },
  
}
