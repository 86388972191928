<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：单据付款信息组件
	开始时间：2021-04-27
	开发人员：刘巍骏
	最后修改：2021-04-27
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="nodeFormItemBox">
    <ul class="form_ul">
      <li>
        <div class="form_title">
          付款类型:
        </div>
        <div class="form_date" @click="showPullMenu('show_paymentType')">
          <input type="text" v-model="paymentData.paymentType.name" placeholder="选择...">
          <span>
            <i class="el-icon-arrow-down"></i>
          </span>
          <ul class="pulldown_menu" v-show="show_pullMenu.show_paymentType">
            <li v-for="elem in paymentTypeData" :key="elem.id" @click="getSelPaymentData(0,elem)">{{elem.name}}</li>
          </ul>
        </div>
      </li>
      <li>
        <div class="form_title">
          付款金额:
        </div>
        <div class="form_date">
          <input type="text" v-model="paymentData.paymentAmount" placeholder="选择...">
        </div>
      </li>
      <li>
        <div class="form_title">
          付款日期:
        </div>
        <div class="form_date">
          <el-date-picker type="date" v-model="paymentData.paymentDate" placeholder="选择日期" :prefix-icon="''" />
        </div>
      </li>
      <li>
        <div class="form_title">
          付款方式:
        </div>
        <div class="form_date" @click="showPullMenu('show_paymentWay')">
          <input type="text" v-model="paymentData.paymentWay.name" placeholder="选择...">
          <span>
            <i class="el-icon-arrow-down"></i>
          </span>
          <ul class="pulldown_menu" v-show="show_pullMenu.show_paymentWay">
            <li v-for="elem in PaymentType" :key="elem.id" @click="getSelPaymentData(1,elem)">{{elem.name}}</li>
          </ul>
        </div>
      </li>
      <li>
        <div class="form_title">
          费用类型:
        </div>
        <div class="form_date" @click="SHOW_COSTTYPEBOX(true)">
          <input type="text" v-model="paymentData.paymentWay.name" placeholder="选择...">
          <span>
            <i class="el-icon-more"></i>
          </span>
        </div>
      </li>
      <li>
        <div class="form_title">
          收款账号:
        </div>
        <div class="form_date" @click="showPullMenu('show_accountNum')">
          <input type="text" v-model="paymentData.paymentAccount.name" placeholder="选择...">
          <span>
            <i class="el-icon-arrow-down"></i>
          </span>
          <ul class="pulldown_menu" v-show="show_pullMenu.show_accountNum">
            <li v-for="elem in SysCostBankInfo" :key="elem.id" @click="getSelPaymentData(2,elem)">{{elem.name}}</li>
          </ul>
        </div>
      </li>
      <li>
        <div class="form_title">
          备注:
        </div>
        <div class="form_date">
          <input type="text" placeholder="选择...">
        </div>
      </li>
    </ul>

    <!-- 附件上传 -->
    <div>
      <el-upload class="upload-demo" :action="fileUrl" :headers="headers" :on-success="uploadFile"
        :file-list="fileList">
        <el-button size="mini" type="primary">附件上传</el-button>
        <div slot="tip" class="el-upload__tip">只能上传2M以内的文件</div>
      </el-upload>
    </div>

    <!-- 弹框 -->
    <!-- 费用分类弹框 -->
    <costTypeTree @SelectedData="getCostTypeData"></costTypeTree>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入组件
  import costTypeTree from '@/components/commComponent/tree/tree_costType.vue';//费用分类组件

  export default {
    name: 'nodeform_payment',
    props: {
      //是否提交当前填写的表单信息
      isCommitFormData: {
        type: Boolean,
        default: true
      },
      //是否清空当前已填写表单数据
      isClearFormData: {
        type: Boolean,
        default: true
      },
      //回显单据数据
      originalBillData: {
        type: Object,
        default () {
          return {}
        }
      }
    },
    data() {
      return {
        // 付款数据
        paymentData: { //付款数据
          paymentType: {}, //付款类型
          paymentAmount: '', //付款金额
          paymentDate: '', //付款日期
          paymentWay: {}, //付款方式
          paymentAccount: {}, //收款账号
          paymentRemark: '', //备注
          paymentFileName: "", //附件名称
          paymentFilePath:"",//附件路径
        },
        paymentTypeData: [ //付款类型数据
          {
            id: 0,
            name: '全付款',
          },
          {
            id: 1,
            name: '预付款',
          }
        ],

        //控制开关
        show_pullMenu: { //控制显示下拉菜单
          show_paymentType: false, //控制显示付款类型
          show_paymentWay: false, //控制显示付款方式
          show_accountNum: false, //控制显示付款账号
        },

        // 附件上传数据
        fileUrl: '', //附件上传路径
        headers: { //请求头路径
          token: '',
        },
        uploadData: { //请求数据
          sign: '',
        },
        fileList: [], //已上传文件路径
      }
    },
    watch: {
      // 监听是否提交当前组件填写信息
      isCommitFormData(newVal) {
        this.commitPayMentData();
      },
      //监听是否清空当前子组件数据
      isClearFormData() {
        //调用清除信息方法
        this.clearNodeFormMsg();
      }
    },
    computed: {
      ...mapState({
        PaymentType: state => state.system.PaymentType, //收款类型数据
        SysCostBankInfo: state => state.system.SysCostBankInfo, //收款账号数据
      })
    },
    created() {
      // 初始化付款信息相关数据
      this.initPyament();
      //初始化付款信息
      this.initPaymentData();
    },
    methods: {
      ...mapMutations([
        'SHOW_COSTTYPEBOX', //控制费用分类选择弹框是否显示
      ]),
      ...mapActions([
        'getAllPaymentType', //收款类型
        'getSysCostBankInfo', //收款账号
      ]),
      /* 初始化付款信息相关数据*/
      initPyament() {
        // 判断付款方式数据是否存在
        if (this.PaymentType.length == 0) {
          this.getAllPaymentType();
        }
        console.log(this.SysCostBankInfo);
        if (this.SysCostBankInfo.length == 0) {
          this.getSysCostBankInfo();
        }
      },

      /* 初始化付款信息界面数据 */
      initPaymentData() {
        //设置请求路径
        this.fileUrl = baseUrl + '/orderCommon/uploadGatherRecorderAccessory';
        //设置请求头
        this.headers.token = getCookie('token');
      },

      /* 获取付款信息下拉框选择type(0:付款类型 1:付款方式  2:付款账号) */
      getSelPaymentData(type, data) {
        if (type == 0) { //付款类型
          this.paymentData.paymentType = data;
        } else if (type == 1) { //付款方式
          this.paymentData.paymentWay = data;
        } else if (type == 2) { //付款账号
          this.paymentData.paymentAccount = data;
        }
      },

      /* 显示下拉菜单key:控制显示下拉菜单对象的key值 */
      showPullMenu(key) {
        // 循环只显示当前点击的下拉框
        for (let i in this.show_pullMenu) {
          //当前传入的key值
          if (i == key) {
            this.show_pullMenu[i] = !this.show_pullMenu[i];
          } else {
            this.show_pullMenu[i] = false;
          }
        }
      },

      /* 获取费用分类弹框数据 */
      getCostTypeData(data){
        if(data.length>1){
          this.$message({
            type:'warning',
            message:'一次只能选择一个费用分类!',
            duration:this.elDuration
          })
          return
        }

      },

      /* 清除当前组件信息 */
      clearNodeFormMsg() {
        // 循环清空对象
        for (let i in this.paymentData) {
          this.paymentData[i] = "";
        }
      },

      /* 附件上传后的回调 */
      uploadFile(response, file, fileList) {
        if (response.code == 200) {
          this.$message({
            type: 'success',
            message: '附件上传成功',
            duration: this.elDuration
          })
          console.log(file);
          this.paymentData.paymentFile = response.data;
          this.paymentData.payment
        } else {
          this.$message({
            type: 'error',
            message: res.message,
            duration: this.elDuration
          })
        }
      },

      /* 提交付款信息到后端 */
      commitPayMentData() {
        //验证数据合法性
        // console.log(this.paymentData);
        //定义传入后端的数据
        let data = {
          pay_type: this.paymentData.paymentType.id, //付款类型id
          pay_type_name: this.paymentData.paymentType.name, //付款类型名称
          pay_money: this.paymentData.paymentAmount, //付款金额
          pay_date: this.commonJsExtend.getDateTime(this.paymentData.paymentDate, 0), //付款日期
          pay_way: this.paymentData.paymentWay.id, //付款方式id
          pay_way_name: this.paymentData.paymentWay.name, //付款方式名称
          pay_bank: this.paymentData.paymentAccount.id, //付款账号
          remark: this.paymentData.paymentRemark, //备注信息
          file_names: '', //附件名称
          file_paths: '', //附件路径
        }
        // console.log(data);
        //发送数据到父组件
        this.$emit("getNodeFormData", data)
      },
    },
    components:{
      costTypeTree,
    }
  }
</script>

<style>
</style>
