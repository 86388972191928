<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：客户选择组件
	开始时间：2021-01-15
	开发人员：刘巍骏
	最后修改：2021-01-15
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="List">
    <el-dialog title="客户选择" :visible.sync="show_customerBox" :before-close="cancel_customer" width="65%" top="8vh"
      append-to-body>
      <!-- 列表总框 -->
      <div class="billing_dialog_table" id="customerListBox">
        <!-- 左边分类 -->
        <div class="list_left">
          <!-- 分类标题 -->
          <div class="classfi_title" @click="getCustomerDataCondition(0)"
            :class="{'classfi_title_sel':queryData.selectedClassify==0}">
            <i class="el-icon-menu"></i>
            <span>全部分类</span>
          </div>
          <!-- 分类列表 -->
          <div class="classfi_list">
            <ul>
              <li :class="{'classfi_ItemSel':queryData.selectedClassify==elem.id}"
                v-for="(elem,index) in CustomerClassfiy" @click="getCustomerDataCondition(elem.id)">{{elem.name}}</li>
            </ul>
          </div>
        </div>

        <!-- 可拉伸框 -->
        <div class="list_handel"></div>

        <!-- 右边数据 -->
        <div class="list_right">
          <!-- 头部搜索框 -->
          <div class="right_top">
            <!-- 搜索 -->
            <div class="search">
              <input type="text" v-model="queryData.searchValue" placeholder="请输入查询内容">
              <i class="el-icon-search" @click="getCustomerDataCondition(queryData.selectedClassify)"></i>
            </div>
            <!-- 新增 -->
            <div class="operateSelect">
              <span class="title">所属员工:</span>
              <el-select class="selectInput" v-model="queryData.selectedUserName" size="small" placeholder="请选择">
                <el-option value="" key="-1" label="全部" v-if="judgeShowAll" @click.native="getSelUserData(-1)">
                </el-option>
                <el-option v-for="elem in personnelData" v-if="judgeShowUser(elem)" :key="elem.user_id"
                  :label="elem.user_name" :value="elem.user_name" @click.native="getSelUserData(elem)">
                </el-option>
              </el-select>
            </div>
            <!-- <div></div> -->
          </div>
          <!-- 列表 -->
          <div class="right_list scollStyle">
            <!-- 表格 -->
            <div class="right_list_table billing_table">
              <el-table :data="currentCustomerData" @select="getSelectedCustomer" height="100%" border
                style="width: 100%">
                <!-- 索引 -->
                <el-table-column type="index" fixed width="50"></el-table-column>
                <!-- 全选 -->
                <el-table-column label="" fixed width="45">
                  <template slot-scope="scope">
                    <el-radio :label="scope.$index" v-model="selectedCustomerIndex"
                      @change.native="getSelectedCustomer(scope.$index,scope.row)">{{''}}</el-radio>
                  </template>
                </el-table-column>
                <!-- 数据内容 -->
                <el-table-column prop="organization_code" label="客户编号" width="150" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span v-if="!scope.row.organization_code">无</span>
                    <span v-else>{{scope.row.organization_code}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="customer_name" label="客户名称" sortable width="280" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="country" label="所在国家" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="important_star" label="客户星级" width="80" show-overflow-tooltip></el-table-column>
                <el-table-column prop="enrol_time" label="入库时间" width="160" show-overflow-tooltip></el-table-column>
                <el-table-column prop="last_contact_date" label="最后联系时间" min-width="160" show-overflow-tooltip>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <div class="right_list_page">
              <el-pagination @size-change="getCustomerPageSize" @current-change="getCustomerCurrentPage"
                :current-page="pageData.currentPage" :page-sizes="[10, 20, 30, 40,50]" :page-size="pageData.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="pageData.totalPage">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="commit_customer">确认</el-button>
        <el-button size="mini" @click="cancel_customer">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入接口
  import sellApi from "@/network/sell/bills.js"

  export default {
    name: 'list_customer',
    props: {
      //节点信息id
      nodeInfoId: {
        type: Number,
        default: 0
      },
      // 默认选中的数据
      defaultSelected: {
        type: Array,
        default () {
          return []
        }
      }
    },
    data() {
      return {
        // 当前组件数据
        classifyPowerArr: [], //客户分类有权限显示的分类数组
        classifyPowerStr: '', //客户分类有权限显示的分类字符串
        currentCustomerData: [], //当前页面显示客户数据
        selectedCustomerIndex: '', //已选客户下标(radio绑定值)
        selectedCustomer: {}, //已选客户

        //弹框请求数据
        queryData: {
          selectedUserName: '', //已选用户名称
          selectedUserId: -1, //已选用户id
          selectedClassify: 0, //已选客户分类id
          searchValue: '', //查询输入框绑定值
        },

        //分页数据
        pageData: {
          currentPage: 1, //当前页
          totalPage: 0, //总页数
          pageSize: 20 //页面大小
        },

        //loading框
        loading: '', //loading框
      }
    },
    computed: {
      ...mapState({
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
        personnelData: state => state.hr.personnelData, //当前企业所有员工
        show_customerBox: state => state.commComponent.show_customerBox, //控制显示客户选择弹框组件
        CustomerClassfiy: state => state.system.CustomerClassfiy, //客户分类数据
      }),
    },
    created() {},
    mounted() {
      //判断vuex中的数据是否存在
      this.judgeVuex();
    },
    watch: {
      //监听弹框是否打开
      // show_customerBox(newVal){
      //   console.log(newVal);
      //   if(newVal){
      //     //默认获取全部客户
      //     this.getCustomerDataCondition(0);
      //   }
      // },
      // 监听默认选中的数据
      defaultSelected(newVal) {
        for (let i = 0; i < this.currentCustomerData.length; i++) {
          if (this.currentCustomerData[i] == newVal) {
            selectedCustomerIndex = i
          }
        }
      },
    },
    methods: {
      ...mapMutations([
        'SHOW_CUSTOMERBOX', //控制客户选择弹框是否显示
      ]),
      ...mapActions([
        'getCustomerClassify', //请求客户分类数据
        'getAllUserData', //请求企业用户数据
      ]),

      /* 判断vuex中的数据是否存在 */
      async judgeVuex() {
        //判断客户分类
        if (!this.CustomerClassfiy.length > 0) {
          await this.getCustomerClassify();
        }
        //判断企业用户
        if (!this.personnelData.length > 0) {
          await this.getAllUserData();
        }
        // 获取默认信息
        this.getDefaultData();
        //默认获取全部客户
        this.getCustomerDataCondition(0);
      },

      /* 判断是否显示客户所属员工 */
      judgeShowUser(data) {
        //根据当前登录用户等级显示
        if (this.UserInfo.user_role == 1) { //系统管理员
          return true;
        } else if (this.UserInfo.user_role == 2) { //部门管理员
          if (data.dept_id == this.UserInfo.dept_id) {
            return true;
          }
        } else if (this.UserInfo.user_role == 3) { //普通员工
          if (this.UserInfo.user_id == data.user_id) {
            return true;
          }
        }
      },

      /* 判断是否显示全部员工选项 */
      judgeShowAll() {
        if (this.UserInfo.user_role == 1) { //系统管理员
          return true;
        } else {
          return false;
        }
      },

      /* 获取默认信息*/
      getDefaultData() {
        this.queryData.selectedUserName = this.UserInfo.user_name; //获取默认员工姓名
        this.queryData.selectedUserId = this.UserInfo.user_id; //获取默认员工id
      },

      /* 获取单据客户选择权限*/
      getCustomerPowerData() {
        // 发送请求
        sellApi.getNodePowerData({
          id: 1
        }).then(res => {
          if (res.code == 200) {
            //获取有权限选择的客户分类
            this.classifyPowerStr = res.data.classify.classify_ids;
            let classifyIdArr = res.data.classify.classify_ids.split(",");
            this.classifyPowerArr = []; //循环前先清空数组
            for (let i = 0; i < classifyIdArr.length; i++) { //将字符串转换为int类型
              this.classifyPowerArr.push(parseInt(classifyIdArr[i]))
            }
            //获取有权限的全部客户
            this.getCustomerDataByid(res.data.classify.classify_ids);
          }
        })
      },

      /* 根据客户分类id查询客户数据type(0:全部  其他:分类)*/
      getCustomerDataCondition(type) {
        //将当前查询的分类条件存入
        this.queryData.selectedClassify = type;

        // 定义公共参数
        let data = {
          pageIndex: this.pageData.currentPage, //当前页
          pageSize: this.pageData.pageSize, //页面大小
          value: this.queryData.searchValue ? this.queryData.searchValue : '', //输入框的值
        }

        // 判断其他查询条件
        if (type == 0) { //查询全部
          let classifyArr = [];
          //循环获取全部客户分类
          this.CustomerClassfiy.forEach((item, index) => {
            classifyArr.push(item.id)
          })
          data.classify_ids = classifyArr.join(',');
        } else { //分类查询
          data.classify_ids = this.queryData.selectedClassify;
        }

        //判断是否有erp客户分类数据
        if (data.classify_ids == "") {
          this.$message({
            type: 'warning',
            message: '您的企业未设置erp客户分类,请联系管理员!',
            duration: this.elDuration
          })
          return
        }

        //判断当前选择用户
        if (this.queryData.selectedUserId != -1) {
          data.upIds = this.queryData.selectedUserId;
        }
        //加载loadign框
        this.loading = this.commonJsExtend.customLoading("#customerListBox", 4, '客户列表信息获取中,请稍候...');
        //发送请求
        sellApi.getClassifyDataById(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取当前页面客户数据
            this.currentCustomerData = res.data.rows;
            //获取分页总条数
            this.pageData.totalPage = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取当前选择的用户 */
      getSelUserData(data) {
        if(data == -1){
          //获取当前选择的用户id
          this.queryData.selectedUserId = "";
        }else{
          //获取当前选择的用户id
          this.queryData.selectedUserId = data.user_id;
        }
        //查询客户
        this.getCustomerDataCondition(this.queryData.selectedClassify);
      },

      /* 获取页面大小*/
      getCustomerPageSize(value) {
        //获取页面大小
        this.pageData.pageSize = value;
        //查询客户
        this.getCustomerDataCondition(this.queryData.selectedClassify);
      },

      /* 获取当前页*/
      getCustomerCurrentPage(value) {
        //获取当前页
        this.pageData.currentPage = value;
        //查询客户
        this.getCustomerDataCondition(this.queryData.selectedClassify);
      },

      /* 获取已选客户*/
      getSelectedCustomer(index, selection) {
        // 获取radio绑定值
        this.selectedCustomerIndex = index;
        //获取已选客户数据
        this.selectedCustomer = selection;
      },

      // 取消客户
      cancel_customer() {
        this.SHOW_CUSTOMERBOX(false);
      },

      // 提交客户
      commit_customer() {
        // 将已选客户传出
        this.$emit("SelectedData", this.selectedCustomer);
        //关闭弹框
        this.SHOW_CUSTOMERBOX(false);
      },
    }
  }
</script>

<style>
  .selLi {
    color: @theme;
  }
</style>
