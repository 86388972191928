<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：单据收货人信息组件
	开始时间：2021-04-27
	开发人员：刘巍骏
	最后修改：2021-04-27
	备注说明：如需修改请联系开发人员
-->
<template>
  <div id="consigneeBox">
    <ul class="form_ul">
      <li>
        <div class="form_title">收货人:</div>
        <div class="form_date">
          <input type="text" v-model="curConsigneeData.fullName" @focus="getOldConsigneeData" @change="updateLinkmanData" placeholder="联系人名称">
          <span>
            <i class="el-icon-arrow-down" @click="showLikmanMenu"></i>
          </span>
          <ul class="pulldown_menu" v-show="show_consignee">
            <li v-for="(item,index) in linkManData" :key="index" @click="getSelConsigneeData(item)">{{item.fullName}}</li>
          </ul>
        </div>
      </li>
      <li>
        <div class="form_title">联系电话:</div>
        <div class="form_date">
          <input type="text" v-model="curConsigneeData.phone" @focus="getOldConsigneeData" @change="updateLinkmanData" placeholder="联系电话">
        </div>
      </li>
      <li>
        <div class="form_title">收货地址:</div>
        <div class="form_date">
          <input type="text" v-model="curConsigneeData.address" @focus="getOldConsigneeData" @change="updateLinkmanData" placeholder="联系地址">
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入请求接口
  import crmApi from '@/network/crm/crm.js';//联系人api

  export default{
    name:"nodeForm_consignee",
    props: {
      //是否提交当前填写的表单信息
      isCommitFormData: {
        type: Boolean,
        default: true
      },
      //是否清空当前已填写表单数据
      isClearFormData:{
        type: Boolean,
        default: true
      },
      // 操作类型(0:新增 1:修改  2:查看)
      operateType: {
        type: Number,
        default: -1
      },
      //数据校验步骤(根据步骤校验数据合法性给出提示信息)
      commitFormStep:{
        type: Number,
        default: -1
      },
      //回显单据数据
      originalBillData: {
        type: Object,
        default () {
          return {}
        }
      }
    },
    data(){
      return{
        //联系人列表数据
        linkManData:[],

        //收货人信息
        oldConsigneeData:{},//原本联系人信息
        curConsigneeData:{},//当前选择的联系人信息

        //控制开关
        show_consignee:false,//控制显示联系下拉

        //辅助数据
        loading:'',//loading框
      }
    },
    computed:{
      ...mapState({
        customer_data: state => state.commComponent.customer_data,//单据所选客户信息
      })
    },
    created() {
      // 初始化收货人信息
      this.intiConsigneeData()
    },
    watch:{
      // 监听已选客户数据是否发生改变
      customer_data(newVal){
        //获取联系人信息
        this.getAllLinkManData();
      },
      //监听是否提交当前组件所填信息
      isCommitFormData(newVal) {
        //调用提交信息方法
        this.commitNodeFormMsg();
        // if(this.commitFormStep == 2){
        //   //调用提交信息方法
        //   this.commitNodeFormMsg();
        // }
      },
      //监听是否清空当前子组件数据
      isClearFormData(){
        //调用清除信息方法
        this.clearNodeFormMsg();
      }
    },
    methods:{
      /* 初始化收货人信息 */
      intiConsigneeData(){
        this.getAllLinkManData();
      },

      /* 清除当前组件信息 */
      clearNodeFormMsg(){
        // 循环清空对象
        this.curConsigneeData = {};
      },

      /* 控制显示联系人菜单 */
      showLikmanMenu(){
        // 判断是否已选客户
        if(JSON.stringify(this.customer_data) == "{}"){
          this.$message({
            type:'warning',
            message:'请先选择客户',
            duration:this.elDuration
          })
          return
        }
        //显示或关闭下拉菜单
        this.show_consignee = !this.show_consignee;
      },

      /* 获取所有联系人信息 */
      getAllLinkManData(){
        //判断当前界面状态
        console.log(this.operateType);
        if(this.operateType == 0){//新增界面
          // 判断是否已选客户
          if(JSON.stringify(this.customer_data) == "{}"){
            return
          }
          // 定义传入后端的对象
          let data = {
            customerid:this.customer_data.customer_id,//客户id
          }
          // 发送请求
          crmApi.findLinkManByCustomerid(data).then(res=>{
            if(res.code==200){
              // 更新联系人数组
              this.linkManData = res.data;
              //设置默认联系人
              if(this.linkManData.length>0){
                //获取主联系人
                let mainLinkmanList = this.linkManData.filter(item=>item.isMainLinkman == 1);
                //判断无联系人时加载第一个
                if(mainLinkmanList.length>0){
                  this.getSelConsigneeData(mainLinkmanList[0]);
                }else{
                  this.getSelConsigneeData(this.linkManData[0]);
                }
              }
            }
          })
        }else{//修改界面
        this.curConsigneeData = this.originalBillData.linkman;
        }
      },

      /* 获取旧的联系人信息(修改前的数据)*/
      getOldConsigneeData(){
        this.oldConsigneeData = this.curConsigneeData;
      },

      /* 修改联系人信息 */
      updateLinkmanData(){
        let updateDate = {
          crmLinkman:JSON.stringify(this.curConsigneeData),
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#consigneeBox", 4, '联系人信息修改中,请稍候...');
        //发送修改请求
        crmApi.updateLinkManByLinkmanId(updateDate).then(res=>{
          //关闭loading框
          this.loading.close();
          //验证后端结果
          if(res.code == 200){
            this.$message({
              type:'success',
              message:"联系人信息已修改!",
              duration:this.elDuration
            })
          }else{
            this.$message({
              type:'error',
              message:res.message,
              duration:this.elDuration
            })
          }
        })
      },

      /* 获取已选联系人信息 */
      getSelConsigneeData(data){
        // 将所需信息存入data
        this.curConsigneeData = data;
        // 关闭下拉框
        this.show_consignee = false;
      },

      /* 提交当前组件所填信息*/
      commitNodeFormMsg(){
        //初始化组件提交步骤
        // this.$parent.initCommitFormStep();
        //发送数据到父组件
        this.$emit("getNodeFormData", this.curConsigneeData);
      },
    }
  }
</script>

<style>
</style>
