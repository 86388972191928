<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：仓库选择组件
	开始时间：2021-01-15
	开发人员：刘巍骏,康泽鑫
	最后修改：2021-07-06
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="List">
    <el-dialog title="仓库选择" :visible.sync="show_warehouesBox" top="10vh" :before-close="cancel_warehouse" width="55%"
      append-to-body>
      <div class="billing_dialog_table">
        <!-- 左边分类 -->
        <div class="list_left">
          <!-- 分类标题 -->
          <div class="classfi_title">
            <i class="el-icon-menu"></i>
            <span>全部分类</span>
          </div>
          <!-- 分类列表 -->
          <div class="classfi_list">
            <ul>
              <li>未分类</li>
            </ul>
          </div>
        </div>
        <!-- 可拉伸框 -->
        <div class="list_handel"></div>
        <!-- 右边数据 -->
        <div class="list_right">
          <!-- 头部搜索框 -->
          <div class="right_top">
            <!-- 搜索 -->
            <div class="search">
              <input type="text" placeholder="请输入查询内容">
              <i class="el-icon-search"></i>
            </div>
            <div class="operateBtn">
              <el-button type="primary" @click="getWarehouseListData()" size="small">刷新</el-button>
            </div>
          </div>

          <!-- 列表 -->
          <div class="right_list scollStyle">
            <!-- 表格 -->
            <div class="right_list_table billing_table">
              <el-table :data="warehouseData" style="width: 100%;margin-bottom: 20px;" row-key="id" border
                default-expand-all :tree-props="defaultProps">
                <!-- 索引 -->
                <el-table-column type="index" fixed width="50"></el-table-column>
                <!-- 选择 -->
                <el-table-column fixed width="60">
                  <template slot-scope="scope">
                    <el-radio :label="scope.$index" v-model="selectedWarehouseIndex"
                      @change.native="getSelectedWarehouse(scope.$index,scope.row)">{{''}}</el-radio>
                  </template>
                </el-table-column>
                <el-table-column prop="encoding" label="仓库编码" sortable width="180">
                </el-table-column>
                <el-table-column prop="name" label="仓库名称" sortable width="180">
                </el-table-column>
                <el-table-column prop="address" label="仓库地址">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="commit_warehouse">确认</el-button>
        <el-button size="mini" @click="cancel_warehouse">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入后端接口
  import api from '@/network/system/dictionary.js'; //数据字典接口

  export default {
    name: 'list_warehouse',
    computed: {
      ...mapState({
        show_warehouesBox: state => state.commComponent.show_warehouesBox, //控制显示仓库选择弹框组件
        WarehouseConfig: state => state.system.WarehouseConfig, //仓库数据
      }),
    },
    data() {
      return {
        warehouseData: [], //当前列表页仓库数据
        //仓库数据
        selectedWarehouseIndex: '', //选中仓库下标
        selectedWarehouse: {}, //选中仓库信息

        //辅助数据
        defaultProps: { //表格子级匹配字段
          children: 'children',
          hasChildren: 'hasChildren'
        },
      }
    },
    created() {
      //判断vuex是否存在数据
      this.judgeWarehouse();
    },
    watch: {},
    methods: {
      ...mapMutations([
        'SHOW_WAREHOUSEBOX', //修改仓库是否显示
      ]),
      ...mapActions([
        'getWarehouseConfig', //获取仓库数据
      ]),
      /* 判断仓库数据是否存在 */
      async judgeWarehouse() {
        if (this.WarehouseConfig.length == 0) {
          await this.getWarehouseConfig();
        }
        //获取列表显示的仓库数据(显示主仓,普通仓,临时仓)
        this.warehouseData = this.WarehouseConfig.filter(item => [0, 1, 3].includes(item.is_main));
      },

      /* 刷新获取仓库数据 */
      getWarehouseListData() {
        //调用方法获取数据
        this.getWarehouseConfig();
      },

      /* 获取已选仓库配置 */
      getSelectedWarehouse(index, selection) {
        // 获取radio绑定值
        this.selectedWarehouseIndex = index;
        //获取已选仓库数据
        this.selectedWarehouse = selection;
      },

      /* 提交已选仓库 */
      commit_warehouse() {
        this.$emit("SelectedData", this.selectedWarehouse);
        this.SHOW_WAREHOUSEBOX(false)
      },
      /* 取消仓库选择 */
      cancel_warehouse() {
        this.SHOW_WAREHOUSEBOX(false)
      }
    },
  }
</script>

<style>
</style>
