<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：退货明细信息组件
	开始时间：2021-04-27
	开发人员：刘巍骏
	最后修改：2021-04-27
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="nodeFormItemBox returnProBox">
    <!-- 有数据时显示  -->
    <div class="returnPro_table billing_table" v-if="returnProBillData.length>0">
      <el-table :data="returnProBillData" row-key="id" height="100%" border show-summary style="width: 100%">
        <!-- 数据内容 -->
        <el-table-column prop="originBillData.billsNo" label="订单编号" sortable width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="originBillData.billsDate" label="订单日期" sortable width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="billData.billsNo" label="退货单号" sortable width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="billData.billsDate" label="退货日期" sortable width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="proData.productCode" label="商品编号" width="180"></el-table-column>
        <el-table-column prop="proData.name" label="商品名称" width="160" show-overflow-tooltip></el-table-column>
        <el-table-column prop="proData.specifications" label="规格型号" width="240" show-overflow-tooltip></el-table-column>
        <el-table-column prop="proData.number" label="退货数量" width="80" show-overflow-tooltip></el-table-column>
        <el-table-column prop="proData.priceTotal" label="退货金额" width="80" show-overflow-tooltip></el-table-column>
        <el-table-column prop="proData.remark" label="备注说明" min-width="150" show-overflow-tooltip></el-table-column>
      </el-table>
    </div>

    <!-- 无数据时显示 -->
    <div v-if="returnProBillData.length==0">
      <span class="noReturnTip">本周期暂无退货</span>
    </div>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入接口
  import depotApi from '@/network/stock/stock.js'; //库存模块

  export default {
    name: 'nodeFormReturnPro',
    props: {
      //单据分类(根据nodeTypeCode区分)
      billsClassify: {
        type: Number,
        default: 0
      },
      //节点分类标志
      billsTypeSign: {
        type: Number,
        default: -1
      },
    },
    data() {
      return {
        //组件数据
        returnProBillData: [], //退货单明细列表

        //辅助数据
        loading: '', //loading框
      }
    },
    mounted() {
      // 获取退货明细数据
      this.getDepotReturnData();
    },
    computed: {
      ...mapState({
        bills_base_data: state => state.commComponent.bills_base_data, //表单填写的基本信息数据
        relvance_billid_arr: state => state.commComponent.relvance_billid_arr, //对账单单据明细id数组
        relvance_billid_sign: state => state.commComponent.relvance_billid_sign, //对账单单据明细模块分类
      })
    },
    watch: {},
    methods: {
      /* 获取退货明细数据 */
      getDepotReturnData() {
        //定义公共查询条件数据
        let conditionData = {
          pageIndex: 1, //当前页
          pageSize: 1000, //页面大小
          deleteFlag: 0, //删除标识
          isSubmit: 4, //已审核的单据
        };
        //判断是否有客户条件
        if (this.bills_base_data.hasOwnProperty("customer_id")) {
          conditionData.customerId = this.bills_base_data.customer_id;
        }
        //判断是否有供应商条件
        if (this.bills_base_data.hasOwnProperty("supplier_id")) {
          conditionData.supplierId = this.bills_base_data.supplier_id;
        }
        console.log(this.bills_base_data);
        //判断是否有周期开始时间
        if (this.bills_base_data.hasOwnProperty("period_start_time") && this.bills_base_data.hasOwnProperty(
            "return_surplus_type") && this.bills_base_data.return_surplus_type == 0) {
          conditionData.startTime = this.bills_base_data.period_start_time;
        }
        //判断是否有周期结束时间
        if (this.bills_base_data.hasOwnProperty("period_end_time") && this.bills_base_data.hasOwnProperty(
            "return_surplus_type") && this.bills_base_data.return_surplus_type == 0) {
          conditionData.endTime = this.bills_base_data.period_end_time;
        }

        //判断是否为对账单模块下
        if (this.billsTypeSign == 9) {
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '退货明细数据加载中,请稍候...');
          //判断对账单类型
          conditionData.isOriginSubItem = 1; //设置需要源单信息
          conditionData.isDepotSubitem = 1; //设置需要货品明细
          conditionData.depotClassify = 9; //设置查询退货单
          //根据不同对账分类查询信息
          if (this.billsClassify == 5) { //客户对账单
            conditionData.relevanceBillsSign = 0;
          } else if (this.billsClassify == 6) { //供应商对账单
            conditionData.relevanceBillsSign = 3;
          }else if(this.billsClassify == 7){//生产对账单
              conditionData.relevanceBillsSign = 1;
          }
          //发送请求
          depotApi.findDepotMainByCondition(conditionData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //清空先
              this.returnProBillData = [];
              //循环获取界面显示数据
              res.data.rows.forEach((item, index) => {
                item.depotMainProNumList.forEach((itemJ, indexJ) => {
                  let itemObj = {
                    proData: itemJ, //出库单货品明细
                    billData: item.depotMain, //出库单主表信息
                  }
                  //判断对账模块分类
                  if (this.billsClassify == 5) { //客户对账单
                    itemObj.originBillData = item.saleOrderMain; //源销售单
                  } else if (this.billsClassify == 6) { //供应商对账单
                    itemObj.originBillData = item.purchaseOrderMain; //源采购单
                  }
                  //获取界面数据
                  this.returnProBillData.push(itemObj);
                })
              })
            } else {
              this.$message({
                type: 'error',
                message: res.data,
                duration: this.elDuration
              })
            }
          })
        }
      },
    }
  }
</script>

<style lang="less">
  // 明细总框
  .returnProBox {

    // 明细内容
    .returnPro_table {
      height: 300px;
    }

    // 暂无退货提示信息
    .noReturnTip {
      width: 100%;
      display: block;
      font-size: 28px;
      font-weight: bold;
      color: @theme;
      padding: 10px 0;
      text-align: center;
      // border: 1px solid black;
    }
  }
</style>
