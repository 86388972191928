<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：货品选择组件
	开始时间：2021-01-15
	开发人员：刘巍骏
	最后修改：2021-01-15
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="List">
    <el-dialog title="货品选择" :visible.sync="show_productBox" :before-close="colse_createBox" width="85%" top="8vh"
      append-to-body :modal-append-to-body="false" v-if="colse_createBox">
      <!-- 列表总框 -->
      <div class="billing_dialog_table billing_dialog_product" id="wrapper_productTable">
        <!-- 左边分类 -->
        <div class="list_left">
          <!-- 分类标题 -->
          <div class="classfi_title" :class="{'classfi_title_sel':conditionType==0}" @click="getProductListData(0)">
            <i class="el-icon-menu"></i>
            <span>全部分类</span>
          </div>
          <!-- 分类列表 -->
          <div>
            <el-tree :data="ProductClassfiy" :props="defaultProps" node-key="id" ref="tree" default-expand-all
              :expand-on-click-node="false" @node-click="getCurrClassify">
              <span class="custom-tree-node" slot-scope="{ node, ProductClassfiy}">
                <span>{{ node.label }}</span>
              </span>
            </el-tree>
          </div>
        </div>

        <!-- 可拉伸框 -->
        <div class="list_handel"></div>

        <!-- 右边数据 -->
        <div class="list_right">
          <!-- 头部搜索框 -->
          <div class="right_top">
            <!-- 搜索 -->
            <div class="search">
              <input type="text" v-model="currSearchVal" placeholder="请输入编号/名称/规格">
              <i class="el-icon-search" @click="getProductListData(conditionType)"></i>
            </div>
            <!-- 中间其他查询条件 -->
            <div class="content">
              <el-checkbox v-model="searchCondition.is_set_bom" @change="getProductListData(conditionType)"
                v-if="bomSetConditon==-1">仅显示已设bom
              </el-checkbox>
            </div>
            <!-- 刷新 -->
            <div class="operateBtn">
              <el-button type="primary" @click="getProductListData(0)" size="small">刷新</el-button>
            </div>
          </div>
          <!-- 列表 -->
          <div class="right_list">
            <!-- 表格 -->
            <div class="right_list_table billing_table">
              <el-table :data="prodoctInfo" height="100%" border style="width: 100%" @select="getselectedProduct"
                @select-all="getSelectedCurrentProduct" ref="productSelected">
                <!-- 索引 -->
                <el-table-column type="index" fixed width="40"></el-table-column>
                <!-- 全选 -->
                <el-table-column type="selection" fixed width="45"></el-table-column>
                <!-- 数据内容 -->
                <el-table-column prop="product_code" label="货品编号" sortable width="150">
                  <template slot-scope="scope">
                    <div class="span_pointer span_color_blue" @click="showProDetail(scope.row.id)">
                      <span :class="{'isSetBom':scope.row.bom_is_set == 1}">{{scope.row.product_code}}</span>
                      <!-- 显示审核状态 -->
                      <span class="color_info" v-if="scope.row.bomAuditState == 0">(未提交)</span>
                      <span class="color_primary"  v-if="scope.row.bomAuditState == 1">(审核中)</span>
                      <span class="color_danger"  v-if="scope.row.bomAuditState == 2">(已驳回)</span>
                      <span class="color_primary"  v-if="scope.row.bomAuditState == 3">(复审中)</span>
                      <span class="color_success"  v-if="scope.row.bomAuditState == 4">(已审核)</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="product_old_code" label="旧货品编码" v-if="show_productOldCode" width="150">
                </el-table-column>
                <el-table-column prop="name" label="货品名称" width="220">
                  <template slot-scope="scope">
                    <span :title="scope.row.supplier_name">{{scope.row.name}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="specifications" label="货品规格" width="220"></el-table-column>
                <el-table-column label="计量单位" width="80">
                  <template slot-scope="scope">
                    <span>{{scope.row.productPrice | getPurchaseUnit}}</span>
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="supplier_name" label="供应商" width="220"></el-table-column> -->
                <!-- 库存信息 -->
                <el-table-column v-for="(itemI,indexI) in warehouseDataNoTree" :label="itemI.name" width="120" v-if="[0,1].includes(itemI.is_main)">
                  <!-- 各仓库对应数量数据 -->
                  <el-table-column label="库存量" width="80">
                    <template slot-scope="scope">
                      <!-- 使用v-if是因为数量是新定义的 -->
                      <span v-for="(itemK,indexK) in scope.row.proWarehouseNum"
                        v-if="itemK.houseId == itemI.id && itemK.depotNum">{{itemK.depotNum.warehouseNum | getPurchaseChangeNum(scope.row)}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="在途量" width="80">
                    <template slot-scope="scope">
                      <span v-for="(itemK,indexK) in scope.row.proWarehouseNum"
                        v-if="itemK.houseId == itemI.id && itemK.depotNum">{{itemK.depotNum.onwayNum | getPurchaseChangeNum(scope.row)}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="占用量" width="80">
                    <template slot-scope="scope">
                      <span v-for="(itemK,indexK) in scope.row.proWarehouseNum"
                        v-if="itemK.houseId == itemI.id && itemK.depotNum">{{itemK.depotNum.occupyNum | getPurchaseChangeNum(scope.row)}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="生产量" width="80">
                    <template slot-scope="scope">
                      <span v-for="(itemK,indexK) in scope.row.proWarehouseNum"
                        v-if="itemK.houseId == itemI.id && itemK.depotNum">{{itemK.depotNum.productNum | getPurchaseChangeNum(scope.row)}}</span>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column prop="bz" label="备注" width="150"></el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <div class="right_list_page">
              <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page.sync="currentPage" :page-sizes="[20,50,100]" :page-size.sync="currentPageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="totalNum">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="commit_product">确认</el-button>
        <el-button size="mini" @click="colse_createBox">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 货品详情组件 -->
    <productDetail :productId="detailProductId" :proDetailBillTab="proDetailBillTab" v-if="show_productDetailBox"></productDetail>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入接口
  import api from '@/network/system/dictionary'; //数据字典接口
  import req from '@/network/datum/product'; //货品接口
  import stockApi from '@/network/stock/stock.js'; //库存模块接口

  // 导入组件
  import productDetail from '@/components/commComponent/dialog/product_detail.vue';//获取详情组件

  export default {
    name: 'list_product',
    props: {
      //单选还是多选(0:单选 1:多选)
      isMultiple: {
        type: Number,
        default: 1
      },
      // 货品相关单据详情tab页
      proDetailBillTab:{
        type: String,
        default: ""
      },
      //是否选择bom(需判断未审核的不能选择)
      isSelBom: {
        type: Number,
        default: -1
      },
      // 默认选中的数据(货品id数组)
      defaultSelected: {
        type: Array,
        default () {
          return []
        }
      },
      //bom配料表可见(是否只显示物料)
      bomCondition: {
        type: Number,
        default: -1
      },
      //销售报价可见
      sellCondition: {
        type: Number,
        default: -1
      },
      // 是否只显示成品
      visibleFinished: {
        type: Number,
        default: -1
      },
      //是否只显示可拆卸
      disassemblyCondition: {
        type: Number,
        default: -1
      },
      //是否只显示可组装
      groupCondition: {
        type: Number,
        default: -1
      },
      //是否只显示已设置bom(0:否 1:是)
      bomSetConditon: {
        type: Number,
        default: -1
      },
      // 是否为销售模块下查询(0:否  1:是)
      isOrderFind: {
        type: Number,
        default: -1
      },
      //是否生产自由添加
      visibleProduct: {
        type: Number,
        default: -1
      },
      //是否参与核算
      isDepot: {
        type: Number,
        default: -1
      },
      //是否参与核算
      isCostCheck: {
        type: Number,
        default: -1
      },
    },
    data() {
      return {
        //货品数据
        prodoctInfo: [], //货品列表数据
        conditionType: 0, //0:全部  1:分类
        currClassifyData: {}, //当前选择的货品分类
        currSearchVal: '', //当前输入框数据
        selectedProductData: [], //选中货品的数据

        //el-tree配置
        defaultProps: {
          children: 'children',
          label: 'name'
        },

        //控制开关
        show_productOldCode: false, //控制是否显示旧货品编码
        show_productDetailBox:false,//控制是否显示货品详情弹框
        
        //仓库数据
        warehouseDataNoTree:[],//仓库数据(非树形结构)

        //辅助数据
        searchCondition: { //其它查询条件
          is_set_bom: false, //是否仅显示已设bom
        },

        //货品详情子组件数据
        detailProductId:'',//货品详情id

        //分页相关数据
        currentPage: 1,
        currentPageSize: 20,
        totalNum: 0, //总条数

        //loadign框
        loading: '',
      }
    },
    created() {
      //初始化数据字典数据
      this.initDictoryData();
      //判断界面显示数据
      this.judgeShowPowerData();
    },
    mounted() {
      this.$nextTick(() => {
        //获取货品列表数据
        this.getProductListData(0);
      })
    },
    computed: {
      ...mapState({
        show_productBox: state => state.commComponent.show_productBox, //控制显示货品选择弹框组件
        ProductClassfiy: state => state.system.ProductClassfiy, //货品分类数据
        WarehouseConfig: state => state.system.WarehouseConfig, //仓库数据
        companyparamlist: state => state.companyparamlist, //获取企业级参数
      }),
    },
    watch: {},
    methods: {
      ...mapMutations([
        'SHOW_PRODUCTBOX', //控制货品选择弹框是否显示
      ]),
      ...mapActions([
        'getProductClassfiy', //获取货品分类数据
        'getWarehouseConfig', //获取仓库数据
      ]),

      /* 初始化数据字典数据 */
      async initDictoryData() {
        // 判断vuex是否有货品分类数据
        if (this.ProductClassfiy.length == 0) {
          await this.getProductClassfiy();
        }
        //判断是否有仓库数据
        if (this.WarehouseConfig.length == 0) {
          await this.getWarehouseConfig();
        }
        //获取仓库非树形结构数据
        this.warehouseDataNoTree = this.commonJsExtend.toNoTree(this.WarehouseConfig, []);
      },

      /* 判断界面显示数据 */
      judgeShowPowerData() {
        //判断是否显示旧物料编码
        if (this.companyparamlist.param345 == "0") { //启用
          this.show_productOldCode = true;
        }
      },

      /* 获取货品列表信息type((0:全部  1:分类)) */
      getProductListData(type) {
        //将查询类型存入data
        this.conditionType = type;
        //定义传入后端的参数
        let data = { //公共条件
          pageIndex: this.currentPage, //当前页
          pageSize: this.currentPageSize, //页面大小
          condition: this.conditionType, //查询条件
          isDown: 0, //是否下架(0:否  1:是)
          value: this.currSearchVal, //搜索框值
        }
        // 分类查询
        if (this.conditionType == 1) { //分类查询
          data.classfiyId = this.currClassifyData.id;
        }
        //控制是否BOM配料可见
        if (this.bomCondition != -1) {
          data.visibleMaterial = this.bomCondition;
        }
        //控制是否销售报价可见
        if (this.sellCondition != -1) {
          data.visibleSell = this.sellCondition;
        }
        //控制是否只显示成品
        if (this.visibleFinished != -1) {
          data.visibleFinished = this.visibleFinished;
        }
        // //控制显示是否可拆卸
        // if (this.disassemblyCondition != -1) {
        //   data.isDisassembly = this.disassemblyCondition;
        // }
        // //控制显示是否可组装
        // if (this.groupCondition != -1) {
        //   data.isGroupProduct = this.groupCondition;
        // }

        //控制是否显示已设置bom
        if (this.searchCondition.is_set_bom) { //复选框控制
          data.bomIsSet = 1;
        }
        if (this.bomSetConditon != -1) { //父组件条件
          data.bomIsSet = this.bomSetConditon;
        }

        //控制是否为销售模块下查询
        if (this.isOrderFind != -1) {
          data.isOrderFind = this.isOrderFind;
        }
        //控制是否生产模块自由添加
        if (this.visibleProduct != -1) {
          data.visibleProduct = this.visibleProduct;
        }
        //控制是否参与库存核算
        if (this.isDepot != -1) {
          data.isDepot = this.isDepot;
        }
        //控制是否参与成本核算
        if (this.isCostCheck != -1) {
          data.isCostCheck = this.isCostCheck;
        }

        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#wrapper_productTable", 4, '货品列表信息获取中,请稍候...');
        //发送请求
        req.searchCommodityByClassfiyId(data).then(res => {
          //关闭loading框
          this.loading.close();
          //判断接口返回数据
          if (res.code == 200) {
            //获取货品数据
            this.prodoctInfo = res.data.rows;
            //获取bom审核信息
            this.prodoctInfo.forEach((item,index)=>{
              //获取bom审核状态
              if(item.productBomVersionList && item.productBomVersionList.length>0){
                item.bomAuditState = item.productBomVersionList[0].bomAuditState;
              }else{
                item.bomAuditState = -1;
              }
            })
            //获取总记录数
            this.totalNum = res.data.total;
            //获取库存信息
            this.getWarehouseNumber();
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取默认选中的货品数据(该方法还没调用) */
      getDefaultSelProduct(data) {
        //获取默认数据
        if (this.defaultSelected.length > 0) {
          let defaultIntIdArr = []
          //转换为int类型数组
          this.defaultSelected.forEach((item, index) => {
            defaultIntIdArr.push(parseInt(item))
          })
          //获取默认选中数据
          data.forEach((item, index) => {
            if (defaultIntIdArr.includes(item.id)) {
              this.selectedProductData.push(item)
            }
          })
          //获取默认选中
          this.getDefaultSelData();
        }
      },

      /* 获取默认选中的货品数据 */
      getDefaultSelData() {
        //获取已选数据id数组
        let selIdArr = [];
        this.selectedProductData.forEach((item, index) => {
          selIdArr.push(item.id)
        })
        //默认选中已选数组
        this.$nextTick(() => {
          this.prodoctInfo.forEach((item, index) => {
            if (selIdArr.includes(item.id)) {
              this.$refs.productSelected.toggleRowSelection(item, true);
            }
          })
        })
      },

      /* 获取货品库存信息 */
      async getWarehouseNumber() {
        //定义接受id的数组
        let productIdArr = [];
        //获取id数组
        this.prodoctInfo.forEach((item, index) => {
          productIdArr.push(item.id); //获取id
          this.$set(item, "proWarehouseNum", []); //设置一个库存数量值
        })
        //将数组转换成字符串
        let priductIdStr = productIdArr.join(",");
        if (!priductIdStr) {
          return
        }
        //根据不同仓库id
        this.warehouseDataNoTree.forEach((itemI, indexI) => {
          //判断只显示主仓和普通仓
          if([0,1].includes(itemI.is_main)){
            this.prodoctInfo.forEach((itemJ, indexJ) => {
              let warehouseIdObj = {
                houseId: itemI.id,
                depotNum: {},
              }
              itemJ.proWarehouseNum.push(warehouseIdObj);
            })
            this.getItemWarehouseNum(priductIdStr, itemI.id, itemI.name);
          }
        })
      },

      /* 发送获取货品库存请求 */
      getItemWarehouseNum(idStr, id, name) {
        //定义后端接受参数
        let numData = {
          ids: idStr, //货品id
          warehouseId: id, //仓库id
        }
        // 加载loading框
        // this.loading = this.commonJsExtend.customLoading("#wrapper_productTable", 4, '正在获取'+name+'的货品信息,请稍候...');
        //发送请求
        stockApi.findProNumByHouseIdAndProId(numData).then(res => {
          // this.loading.close();
          if (res.code == 200) {
            //循环获取每个货品库存信息
            this.prodoctInfo.forEach((itemJ, indexJ) => {
              //若无库存信息则为-,有库存信息无库存为0,其他正常显示
              let proNumbObj = {
                warehouseNum: '-', //库存量
                occupyNum: '-', //占用量
                onwayNum: '-', //在途量
                productNum: '-', //生产量
              }
              if (res.data.length > 0) {
                // 循环获取库存数据
                res.data.forEach((itemZ, indexZ) => {
                  //根据货品id获取库存信息
                  if (itemJ.id == itemZ.productInfoId) {
                    //获取库存信息
                    if (!!itemZ) {
                      proNumbObj = {
                        warehouseNum: itemZ.warehouseTotalNumber, //库存总量
                        occupyNum: itemZ.occupyTotalNumber, //占用总量
                        onwayNum: itemZ.onwayTotalNumber, //在途总量
                        productNum: itemZ.productTotalNumber, //生产总量
                      }
                    } else {
                      proNumbObj = {
                        warehouseNum: 0, //库存总量
                        occupyNum: 0, //占用总量
                        onwayNum: 0, //在途总量
                        productNum: 0, //生产总量
                      }
                    }
                  }
                  //将库存信息存入(根据id匹配库存信息)
                  itemJ.proWarehouseNum.forEach((itemK, indexK) => {
                    if (itemK.houseId == id) {
                      itemK.depotNum = proNumbObj;
                    }
                  })
                })
              } else { //没有库存信息时默认加载 -
                itemJ.proWarehouseNum.forEach((itemK, indexK) => {
                  if (itemK.houseId == id) {
                    itemK.depotNum = proNumbObj;
                  }
                })
              }
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取当前选中货品分类 */
      getCurrClassify(data) {
        //将所选货品分类数据存入data
        this.currClassifyData = data;
        //查询货品列表数据
        this.getProductListData(1);
      },

      /* 获取已选货品(selection:已选数组  row:当前勾选数据) */
      getselectedProduct(selection, row) {
        //判断是单选还是多选
        if (this.isMultiple == 0) { //单选
          //获取单选数据
          this.selectedProductData = [];
          this.selectedProductData.push(row);
          //设置单选
          this.$refs.productSelected.clearSelection();
          this.$refs.productSelected.toggleRowSelection(row);
        } else if (this.isMultiple == 1) { //多选
          let hasRowIndex = -1;
          //根据选中的数据获取已选下标
          this.selectedProductData.forEach((item, index) => {
            if (item.id == row.id) {
              hasRowIndex = index;
            }
          })
          //判断是选中还是取消
          if (hasRowIndex != -1) { //取消
            this.selectedProductData.splice(hasRowIndex, 1);
          } else { //选中
            this.selectedProductData.push(row);
          }
        }
      },

      /* 全选当前页货品*/
      getSelectedCurrentProduct(selection) {
        //判断是单选还是多选
        if (this.isMultiple == 0) { //单选
          //直接清除,不可全选
          this.$refs.productSelected.clearSelection();
        } else if (this.isMultiple == 1) { //多选
          let curDataIdArr = []
          // 获取当前界面全部数据用户id数组
          this.prodoctInfo.forEach((item, index) => {
            curDataIdArr.push(item.id);
          })
          // 判断是取消全选还是全选
          if (selection.length == 0) { //取消全选
            this.selectedProductData.forEach((item, index) => {
              if (curDataIdArr.includes(item.id)) {
                this.selectedProductData.splice(index, 1)
              }
            })
          } else { //全选
            this.prodoctInfo.forEach((item, index) => {
              this.selectedProductData.push(item)
            })
          }
        }
      },

      /* 显示货品详情弹框 */
      showProDetail(id){
        //显示弹框
        this.show_productDetailBox = true;
        //获取货品id
        this.detailProductId = id;
      },

      /* 关闭货品详情弹框 */
      closeProDetail(){
        this.show_productDetailBox = false;
      },

      /* 页面数据总量发生变化触发 */
      handleSizeChange(val) {
        //将数据存入列表
        this.currentPageSize = val;
        //获取货品列表数据
        this.getProductListData(this.conditionType);
      },

      /* 分页页码发生变化触发 */
      handleCurrentChange(val) {
        //将数据存入列表
        this.currentPage = val;
        //获取货品列表数据
        this.getProductListData(this.conditionType);
      },

      /* 关闭弹框 */
      colse_createBox() {
        this.SHOW_PRODUCTBOX(false);
      },

      // 提交货品
      commit_product() {
        //bom选择
        if(this.isSelBom == 1){
          //判断第0条即可
          if(this.selectedProductData[0].bomAuditState == -1){
            this.$message({
              type:'warning',
              message:'该产品尚未设置bom，暂不能被添加生产',
              duration:this.elDuration
            })
            return
          }else if(this.selectedProductData[0].bomAuditState != 4){
            if(this.companyparamlist.param264==0){
              this.$message({
                type:'warning',
                message:'该产品的BOM尚未审核，暂不能被添加生产',
                duration:this.elDuration
              })
              return
            }
          }
        }

        this.$emit('SelectedData', this.selectedProductData)
        //清空复选框
        this.$refs.productSelected.clearSelection();
        this.SHOW_PRODUCTBOX(false);
      },
    },
    components:{
      productDetail
    },
    filters: {
      /* 获取基础计量单位 */
      getBaseUnit(value) {
        let result = "";
        //判断是否有值
        if (value && value.length > 0) {
          value.forEach((item, index) => {
            if (item.is_base == 1) {
              result = item.calculateName;
            }
          })
          //若没有基础计量单位则默认选中第一个
          if (result == "") {
            result = value[0].calculateName;
          }
        }
        return result;
      },
      /* 获取采购计量单位 */
      getPurchaseUnit(value) {
        let result = "";
        //判断是否有值
        if (value && value.length > 0) {
          value.forEach((item, index) => {
            if (item.is_purchase_default == 1) {
              result = item.calculateName;
            }
          })
          //若没有基础计量单位则默认选中第一个
          if (result == "") {
            result = value[0].calculateName;
          }
        }
        return result;
      },
      /* 多计量换算 */
      getPurchaseChangeNum(number, row) {
        //定义返回结果
        let resnum = !!number ? number : 0;

        // 获取多计量数据
        let productPriceList = row.productPrice;

        // 获取基础计量单位
        let curCalculate = null;
        let curCalculateArr = productPriceList.filter(item => item.is_base == 1);
        if (curCalculateArr.length > 0) {
          curCalculate = curCalculateArr[0].exchange_base;
        }

        //获取采购计量单位
        let purchaseCalculate = null;
        let purchaseCalculateArr = productPriceList.filter(item => item.is_purchase_default == 1);
        if (purchaseCalculateArr.length > 0) {
          purchaseCalculate = purchaseCalculateArr[0].exchange_base;
        }

        //转换计量单位
        if (curCalculate != null && purchaseCalculate != null) {
          let curcxchaangebase = 1;
          //判断转换关系
          if (curCalculate > purchaseCalculate) { //大转小
            //获取换算计量
            curcxchaangebase = (curCalculate / purchaseCalculate);
          } else if (curCalculate < purchaseCalculate) { //小转大
            //获取换算计量
            curcxchaangebase = (purchaseCalculate / curCalculate);
          }

          //计算数据
          resnum = resnum / curcxchaangebase;
        }

        if(isNaN(resnum)){
          resnum = 0;
        }
        return resnum;
      },
    }
  }
</script>

<style lang="less" scoped>
  //自定义该组件弹框高度
  .billing_dialog_table {
    height: 60vh !important;
  }

  //已设bom样式
  .isSetBom {
    color: red;
  }

  .span_pointer{
    cursor: pointer;
  }

  .span_color_blue:hover{
    color: blue;
  }
</style>
