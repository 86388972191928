<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：单据基本信息组件
	开始时间：2021-04-27
	开发人员：刘巍骏
	最后修改：2021-04-27
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="nodeFormItemBox billingcreate_form" id="nodeBaseFormBox">
    <ul class="form_ul">
      <!-- 循环出的系统数据 -->
      <li v-for="(elemI,indexI) in nodeSysFormData" v-show="elemI.isShowItem" class="baseFormItemLi">
        <!-- 左边标题名称 -->
        <div class="form_title" @click="closeOtherMenu(-1,1)">
          <span class="mustHint" v-show="elemI.is_must==1">*</span>
          {{elemI.show_name}}:
        </div>

        <!-- 右边填写内容 -->
        <!-- 输入框类型 -->
        <div class="form_date" v-if="elemI.system.type == 0">
          <input type="text" v-model="nodeSysFormVal[elemI.system.filed_name]" @focus="judgeRelatedData(elemI,indexI)"
            @change="judgeChangeData()" :disabled="!is_edit" placeholder="请输入">
        </div>

        <!-- 下拉框类型 -->
        <div class="form_date" v-if="elemI.system.type == 1" @click="judgeRelatedData(elemI,indexI)">
          <input type="text" v-model="nodeSysFormVal[elemI.system.filed_name]" disabled="disabled" placeholder="请选择...">
          <span>
            <i class="el-icon-arrow-down"></i>
          </span>
          <el-tooltip placement="bottom-start" v-if="judgeShowIsRateBox(elemI.system.filed_name)">
            <div slot="content">
              <ul>
                <li>价格是否含税”为是时，订单总价不算税额，只为财务做成本核算时用；为否时，则会自动根据税率在总价的基础上增加税额</li>
              </ul>
            </div>
            <i class="el-icon-question"></i>
          </el-tooltip>
          <!-- 下拉菜单 -->
          <ul class="pulldown_menu scollStyle" v-show="elemI.show_selectData">
            <li v-for="(elemJ,indexJ) in elemI.selectData" :key="indexJ"
              v-if="isShowOption(elemI.system.filed_name,elemJ)"
              @click="getSelectVal(elemI.system.filed_name,elemJ,elemI)">
              {{elemJ.name}}
              <span class="blue_sign" v-if="judgeShowOutInSign(0,elemI.system.filed_name,elemJ)">(出)</span>
              <span class="red_sign" v-if="judgeShowOutInSign(1,elemI.system.filed_name,elemJ)">(入)</span>
              <span v-if="judgeShowOutInSign(2,elemI.system.filed_name,elemJ)">(无)</span>
            </li>
          </ul>
        </div>

        <!-- 日期框类型 -->
        <div class="form_date" v-if="elemI.system.type == 2" @click="judgeRelatedData(elemI,indexI)">
          <el-date-picker type="date" v-model="nodeSysFormVal[elemI.system.filed_name]" placeholder="选择日期"
            :disabled="!is_edit" :prefix-icon="''" @input="getSelectData($event,elemI.system.filed_name)"
            :picker-options="pickerBeginDateBefore" @change="judgeChangeData()" />
          <span>
            <i class="el-icon-date"></i>
          </span>
        </div>

        <!-- 数量框类型 -->
        <div class="form_date" v-if="elemI.system.type == 3">
          <input type="number" v-model="nodeSysFormVal[elemI.system.filed_name]" @focus="judgeRelatedData(elemI,indexI)"
            @change="judgeChangeData()" :disabled="!is_edit" placeholder="请输入整数">
        </div>

        <!-- 弹框类型 -->
        <div class="form_date" v-if="elemI.system.type == 4" @click="judgeRelatedData(elemI,indexI)">
          <input type="text" v-model="nodeSysFormVal[elemI.system.filed_name]" placeholder="选择..." disabled="disabled">
          <span>
            <i class="el-icon-more"></i>
          </span>
        </div>

        <!-- 复选框类型 -->
        <div class="form_data_filed" v-if="elemI.system.type == 5" @click="judgeRelatedData(elemI,indexI)">
          <!-- <el-checkbox class="input_checkbox" :value="" v-model="nodeSysFormVal[elemI.system.filed_name]"></el-checkbox> -->
          <el-checkbox class="input_checkbox" @change="changeCheckBox(elemI.system.filed_name,elemI)"
            :disabled="!is_edit" :value="nodeSysFormValId[elemI.system.filed_name] == 1"></el-checkbox>
        </div>

        <!-- 小数框类型 -->
        <div class="form_date" v-if="elemI.system.type == 6">
          <input type="text" v-model="nodeSysFormVal[elemI.system.filed_name]" @focus="judgeRelatedData(elemI,indexI)"
            @change="judgeChangeData()" :disabled="!is_edit" oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"
            placeholder="请输入小数">
        </div>

        <!-- 百分比类型 -->
        <div class="percent_filed" v-if="elemI.system.type == 7">
          <input type="text" class="percent_input" v-model="nodeSysFormVal[elemI.system.filed_name]"
            @focus="judgeRelatedData(elemI,indexI)" @change="judgeChangeData()" :disabled="!is_edit"
            oninput="this.value=this.value.replace(/[^0-9\.]/g,'')" placeholder="请输入小数">
          <span class="percent_span">%</span>
        </div>

        <!-- 文本类型 -->
        <div class="form_date" v-if="elemI.system.type == 8" @click="judgeRelatedData(elemI,indexI)">
          <input type="text" v-model="nodeSysFormVal[elemI.system.filed_name]" :disabled="true" placeholder="暂无数据">
        </div>
      </li>

      <!-- 循环获取自定义基本数据 -->
      <li v-if="nodeCusFormVal.length>0 && elemI.custom_data.type != 6" v-for="(elemI,indexI) in nodeCusFormVal">
        <!-- 左边标题名称 -->
        <div class="form_title">
          <span class="mustHint" v-show="elemI.is_must==1">*</span>
          {{elemI.custom_data.name}}:
        </div>

        <!-- 右边填写内容 -->
        <!-- 输入框类型 -->
        <div class="form_date">
          <input type="text" v-model="elemI.custom_form_value" :disabled="!is_edit" placeholder="请输入内容"
            @focus="judgeRelatedFiledData(elemI,indexI)" @change="judgeChangeFiledData(elemI)">
        </div>
      </li>

      <!-- 工时信息(只有当节点状态为0(员工填写)时需要填写) -->
      <li v-if="baseNodeInfo.working_type == 0">
        <!-- 左边标题名称 -->
        <div class="form_title">
          <span class="mustHint">*</span>
          预计工时:
        </div>
        <div class="form_data_filed">
          <el-input class="input_text" v-model="nodeHours" size="mini" placeholder="请输入工时"></el-input>
          <span class="itemSpan" v-if="baseNodeInfo.working_hours_type == 1">天</span>
          <span class="itemSpan" v-if="baseNodeInfo.working_hours_type == 2">小时</span>
        </div>
      </li>
    </ul>

    <!-- 对账单相关 -->
    <div v-if="billsTypeSign == 9 && operateType == 0" class="checkFormBox">
      <span class="loadbtn" @click="loadConditionBills(0)">立即加载</span>
    </div>

    <!-- 客户选择弹框 -->
    <customerList :nodeInfoId="baseNodeInfo.id" @SelectedData="getCustomerData" v-if="show_coustomerComponent">
    </customerList>

    <!-- 仓库选择弹框 -->
    <warehouseList @SelectedData="getWarehouseData" v-if="show_warehouseComponent"></warehouseList>

    <!-- 供应商选择弹框 -->
    <supplierList @SelectedData="getSupplierData" v-if="show_supplierComponent"></supplierList>

    <!-- 部门选择弹框 -->
    <departmentTree @SelectedData="getDepartmentData" v-if="show_departmentComponent"></departmentTree>

    <!-- 员工选择组件 -->
    <personnelList @SelectedData="getPersonnelData" v-if="show_personnelComponent"></personnelList>

    <!-- 货品选择弹框 -->
    <productList @SelectedData="getProductData" :groupCondition="groupCondition" :isMultiple="isMultiple"
      :disassemblyCondition="disassemblyCondition" :visibleFinished="visibleFinished" :isSelBom="1"
      v-if="show_productComponent">
    </productList>

    <!-- 单据选择弹框 -->
    <billingList @SelectedData="getBillsData" :nodeTypeCode="nodeTypeCode" :conditionObj="conditionObj"
      :processBillIsMerge="processBillIsMerge" :showSignArr="showSignArr" :disableBillIdArr="disableBillIdArr"
      :checkedBillIdArr="checkedBillIdArr" :openBillsTypeSign="billsTypeSign" v-if="show_billsComponent"></billingList>

    <!-- 费用分类弹框 -->
    <costTypeTree @SelectedData="getCostTypeData" v-if="show_costTypeComponent" :conditionObj="costTypeConditionObj">
    </costTypeTree>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入后端接口
  import api from '@/network/system/workflowSetup.js'; //工作流程相关接口请求
  import sellApi from '@/network/sell/bills.js'; //销售模块
  import depotApi from '@/network/stock/stock.js'; //库存模块
  import purchaseApi from '@/network/stock/purchase.js'; //采购模块
  import productApi from '@/network/production/production.js'; //生产模块
  import costApi from '@/network/cost/account.js'; //资金相关接口
  import proApi from '@/network/datum/product'; //货品接口

  //导入公共组件
  import customerList from '@/components/commComponent/list/list_customer'; //客户列表组件
  // import productList from '@/components/commComponent/list/list_product'; //货品列表组件
  import warehouseList from '@/components/commComponent/list/list_warehouse'; //仓库列表组件
  import billingList from '@/components/commComponent/list/list_billing'; //单据列表组件
  import supplierList from '@/components/commComponent/list/list_supplier'; //供应商列表组件
  import departmentTree from '@/components/commComponent/tree/tree_department'; //部门选择组件
  import personnelList from '@/components/commComponent/list/list_personnel' //部门员工组件
  import costTypeTree from '@/components/commComponent/tree/tree_costType.vue'; //费用分类组件

  export default {
    name: 'nodeFormBase',
    props: {
      // 节点分类code
      nodeTypeCode: {
        type: String,
        default: ''
      },
      //单据分类(根据nodeTypeCode区分)
      billsClassify: {
        type: Number,
        default: 0
      },
      //基本信息所有表单信息
      baseFormData: {
        type: Array,
        default: []
      },
      //自定义基本信息
      baseCusFormData: {
        type: Array,
        default: []
      },
      // 节点基本信息
      baseNodeInfo: {
        type: Object,
        default () {
          return {}
        }
      },
      //是否提交当前填写的表单信息
      isCommitFormData: {
        type: Boolean,
        default: true
      },
      //是否清空当前已填写表单数据
      isClearFormData: {
        type: Boolean,
        default: true
      },
      // 操作类型(0:新增 1:修改  2:查看)
      operateType: {
        type: Number,
        default: -1
      },
      //是否为生成单据操作
      isGenerateOperateType: {
        type: Number,
        default: -1
      },
      //数据校验步骤(根据步骤校验数据合法性给出提示信息)
      commitFormStep: {
        type: Number,
        default: -1
      },
      //回显单据数据
      originalBillData: {
        type: Object,
        default () {
          return {}
        }
      },
      //节点分类标志(0:销售 1:生产 2:库存  3:采购)
      billsTypeSign: {
        type: Number,
        default: -1
      },
      //回显单据原id(-1:新增 其他:生成)
      generateBillsId: {
        type: Number,
        default: -1
      },
      //默认单据类型code值
      billsTypeDefaultCode: {
        type: String,
        default: ''
      },
      //节点自定义文本类型信息
      nodeCusTextFormData: {
        type: Array,
        default () {
          return []
        }
      },
      // 数据字典默认数据
      dictionariesDefaultData: {
        type: Object,
        default () {
          return {
            purchaseTypeCode: '', //采购类型
          }
        }
      },
    },
    data() {
      return {
        // 基本信息
        nodeSysFormData: [], //节点系统表单数据
        nodeSysFormVal: {}, //节点系统表单填写的数据(基本信息)
        nodeSysFormValId: {}, //节点系统表单填写的数据id(基本信息下拉框和弹框用到)
        nodeCusFormVal: [], //节点自定义表单填写的数据(基本信息)
        nodeMustFileName: [], //节点必填字段数组
        nodeLengthAstrict: [], //节点需要限制内容长度的字段数组
        nodeHandFullFileName: [], //节点需要手动填写的字段(输入框,数量框,日期框,小数框)
        nodeSelDownTypeData: [], //当前下拉框类型数据(当前单据需要的数据字典类型)
        nodeHours: "2", //工时时长
        nodeCurrentTempData: [], //当前模板单据类型数据

        //单据修改时用到的数据
        nodeSysFormOldVal: {}, //修改前的名称格式基本信息
        nodeSysFormOldIdVal: {}, //修改前的id格式基本信息
        curNodeBaseData: {}, //当前操作的节点基本信息字段数据
        nodeCusFormOldVal: {}, //当前修改前的自定义信息

        // 弹框数据
        currentDialogFileName: '', //当前弹框选择操作字段

        //控制开关(用于控制子组件是否加载,若没有会和当前页面其他地方冲突)
        show_coustomerComponent: false, //控制加载客户组件
        show_warehouseComponent: false, //控制加载仓库组件
        show_supplierComponent: false, //控制加载供应商组件
        show_departmentComponent: false, //控制加载部门组件
        show_personnelComponent: false, //控制加载员工选择组件
        show_productComponent: false, //控制加载货品选择组件
        show_billsComponent: false, //控制加载单据选择弹框组件
        show_costTypeComponent: false, //控制加载费用分类选择弹框组件

        //控制开关(控制所有数据不可编辑)
        is_edit: true, //控制页面字段是否可编辑,默认可编辑

        //传入产品列表子组件的数据
        groupCondition: -1, //控制是否只显示组装产品(-1:不控制  1:控制)
        disassemblyCondition: -1, //控制是否只显示拆卸产品(-1:不控制  1:控制)
        isMultiple: 0, //控制货品组件是单选还是多选
        visibleFinished: -1, //是否只显示成品

        //传入单据列表子组件的数据
        conditionObj: {}, //单据查询条件
        costTypeConditionObj: {}, //费用分类查询条件
        showSignArr: [], //可以引入的单据标识数组
        processBillIsMerge: 1, //工序单显示方式
        disableBillIdArr: [], //不可选单据id数组
        checkedBillIdArr: [], //已选单据id数组

        //辅助功能数据
        curOperateFormLi: -1, //
        pickerBeginDateBefore: {},
      }
    },
    created() {
      //初始化数据字典相关数据
      this.initDictionaryData();
      //初始化日期相关控制
      this.initPickerController();
    },
    mounted() {
      // 添加单据弹框监听事件
      this.addDialogListener();
    },
    computed: {
      ...mapState({
        //订单相关
        OrderType: state => state.system.OrderType, //报价类型数据
        OrderDocumentType: state => state.system.OrderDocumentType, //单据类型数据(弃用)
        OrderSettlement: state => state.system.OrderSettlement, //结算方式
        PurchaseType: state => state.system.PurchaseType, //采购方式
        PriceClause: state => state.system.PriceClause, //价格条款
        PaymentMode: state => state.system.PaymentMode, //付汇方式
        orderSourse: state => state.system.orderSourse, //报价方式
        offerWayData: state => state.system.offerWayData, //订单来源
        saleBillType: state => state.system.saleBillType, //销售单据分类(已废弃)
        isRate: state => state.system.isRate, //是否含税

        //采购相关
        purchaseBillType: state => state.system.purchaseBillType, //采购单据分类

        //仓储相关
        WarehouseConfig: state => state.system.WarehouseConfig, //仓库数据
        LogisticsCompany: state => state.system.LogisticsCompany, //物流公司数据
        LogisticsMode: state => state.system.LogisticsMode, //物流方式数据
        DeliveryType: state => state.system.DeliveryType, //送货方式数据
        QualityType: state => state.system.QualityType, //质检类型数据
        TestWay: state => state.system.TestWay, //检验方式数据
        depotBillType: state => state.system.depotBillType, //库存单据分类

        //生产相关
        ProductionBomType: state => state.system.ProductionBomType, //BOM类
        CostType: state => state.system.CostType, //计费方式
        ManufactureType: state => state.system.ManufactureType, //生产类型
        productBillType: state => state.system.productBillType, //生产单据类型
        originBillType: state => state.system.originBillType, //源单类型

        //财务相关
        FinanceCurrency: state => state.system.FinanceCurrency, //交易币种数据
        PaymentType: state => state.system.PaymentType, //收款类型数据
        // CostType: state => state.system.CostType, //费用方式数据(改为弹框类型)
        SysCostBankInfo: state => state.system.SysCostBankInfo, //收款账号数据
        // CostInvoiceType: state => state.system.CostInvoiceType, //发票类型数据(弃用)
        invoiceTypeData: state => state.system.invoiceTypeData, //发票类型数据
        invoicePropertyData: state => state.system.invoicePropertyData, //发票属性数据
        costBillType: state => state.system.costBillType, //资金单据类型数据
        accountCheckType: state => state.system.accountCheckType, //对账类型数据
        periodTypeData: state => state.system.periodTypeData, //周期类型数据
        costSurplusData: state => state.system.costSurplusData, //收付款结余类型
        returnSurplusData: state => state.system.returnSurplusData, //退货结余类型

        //货品相关
        ProductUnit: state => state.system.ProductUnit, //计量单位数据



        //其他数据
        billTemplateData: state => state.system.billTemplateData, //所有单据类型数据
        bills_type_data: state => state.commComponent.bills_type_data, //当前单据选择的单据类型数据
        bills_sel_detail: state => state.commComponent.bills_sel_detail, //关联单据详情数据
        bills_relvence_sign: state => state.commComponent.bills_relvence_sign, //关联单据(可弃用)
        customer_data: state => state.commComponent.customer_data, //单据所选客户信息
        supplier_data: state => state.commComponent.supplier_data, //单据所选供应商信息
        is_load_condition_bills: state => state.commComponent.is_load_condition_bills, //是否立即加载指定条件单据(对账单使用)
        is_load_checkbill_data: state => state.commComponent.is_load_checkbill_data, //是否加载对账单信息
        depot_sel_id_arr: state => state.commComponent.depot_sel_id_arr, //对账单出入库单明细id

        //权限相关
        companyparamlist: state => state.companyparamlist, //获取企业级参数

        //登录信息相关
        loginType: state => state.user.loginType, //当前系统登录方式
        LoginCustomerInfo: state => state.user.LoginCustomerInfo, //当前系统登录的客户信息
        LoginSupplierInfo: state => state.user.LoginSupplierInfo, //当前系统登录供应商信息

        // 单据选择相关信息
        product_data: state => state.commComponent.product_data, //节点基本信息所选产品对象

        //bom总成本价
        totalCostPrice:state=>state.commComponent.totalCostPrice,//bom总成本
      })
    },
    watch: {
      // 监听操作类型发生改变
      operateType: {
        handler(newVal) {
          if (newVal == 2) { //预览
            this.is_edit = false;
          } else { //新增修改
            this.is_edit = true;
          }
        }
      },
      //监听基本信息数据(表单模板)
      baseFormData: {
        handler(newVal) {
          //初始化基本信息数据
          this.initBaseForm(newVal);
        },
        immediate: true
      },
      // 监听基本信息自定义(表单模板)
      baseCusFormData: {
        handler(newVal) {
          //初始化基本信息数据
          this.initCusBaseForm(newVal);
        },
        immediate: true
      },

      //监听bom总成本发生变化的时候
      totalCostPrice:{
        handler(newVal) {
          //初始化基本信息数据
          this.totalCostchange(newVal);
        },
        immediate: true
      },
      //监听是否提交当前组件所填信息
      isCommitFormData(newVal) {
        this.commitNodeFormMsg();
        // console.log(newVal);
        // if(this.commitFormStep == 0){
        //   //调用提交信息方法
        //   this.commitNodeFormMsg();
        // }
      },
      //监听是否清空当前子组件数据
      isClearFormData() {
        //调用初始化信息方法
        this.initNodeFormMsg();
      },
      // 监听基本信息发生变化
      nodeSysFormVal: {
        handler(newVal) {
          //处理基本信息发生变化的业务
          this.handlerBaseInfoChange(newVal);
        },
        deep: true
      },
      //监听节点自定义信息变化
      nodeCusTextFormData: {
        handler(newVal) {
          //处理节点自定义文本信息
          this.handlerBaseCusTextearData(newVal);
        },
        deep: true
      },
      //监听客户选项发生变化
      'nodeSysFormVal.customer_id': {
        handler(newVal) {
          //处理节点自定义文本信息
          this.handlerCustomerChange(newVal);
        },
      },
      //监听供应商选项发生变化
      'nodeSysFormVal.supplier_id': {
        handler(newVal) {
          //处理节点自定义文本信息
          this.handlerSupplierChange(newVal);
        },
      },

      //监听计划数量发生发生变化
      'nodeSysFormVal.plan_num': {
        handler(newVal) {
          //处理节点自定义文本信息
          this.handlerplanNumChange(newVal);
        },
      },

      //监听bom总成本价发生改变的时候
      'nodeSysFormVal.costPrice': {
        handler(newVal) {
          //处理节点自定义文本信息
          this.handlercostPriceChange(newVal);
        },
      },

      //监听报价方式选项发生变化
      'nodeSysFormVal.offer_way_id': {
        handler(newVal) {
          this.$parent.$parent.clearChildComponent();
        },
      },
      //监听基本信息某个属性发生变化
      // 监听回显单据数据(修改/预览)
      originalBillData(newVal) {
        this.getDefaultBaseData(newVal, this.billsTypeSign, 1);
      },
      //监听关联单据数据发生变化(引用/生成)
      bills_sel_detail(newVal) {
        this.getDefaultBaseData(newVal, this.bills_relvence_sign, 0);
      },
    },
    methods: {
      ...mapMutations([
        'SHOW_CUSTOMERBOX', //控制客户选择弹框是否显示
        'SHOW_WAREHOUSEBOX', //控制仓库是否显示
        'SHOW_BILLINGBOX', //控制单据选择弹框是否显示
        'SHOW_SUPPLIERBOX', //控制供应商弹框是否显示
        'SHOW_DEPARTMENTBOX', //控制部门弹框是否显示
        'SHOW_PERSONNELBOX', //控制部门员工弹框是否显示
        'SHOW_PRODUCTBOX', //控制产品选择弹框是否显示
        'SHOW_COSTTYPEBOX', //控制费用分类选择弹框是否显示
        'CUSTOMER_DATA', //将所选客户存入
        'SUPPLIER_DATA', //将所选供应商存入
        'PRODUCT_DATA', //将所选产品信息存入
        'COST_TYPE', //将费用分类信息存入
        'BILLS_TYPE_DATA', //当前所选单据类型
        'BILLS_BASE_DATA', //当前填写的表单数据
        'BILLS_SEL_DETAIL', //当前所选单据详情信息
        'BILLS_RELVENCE_SIGN', //当前操作的关联单据标签
        'IS_LOAD_CONDITION_BILLS', //是否立即加载指定条件单据
        'IS_LOAD_CHECKBILL_DATA', //是否加载对账单信息
      ]),
      ...mapActions([
        //订单相关
        'getAllOrderDocumentType', //单据类型
        'getAllOrderType', //报价类型
        'getAllSupplierSettlement', //结算方式
        'getPriceClause', //价格条款
        'getPaymentMode', //付汇方式
        'getPurchaseType', //采购类型
        //仓库相关
        'getWarehouseConfig', //获取仓库数据
        'getAllCompany', //物流公司
        'getLogisticsMode', //物流方式
        'getDeliveryType', //送货方式
        'getQualityType', //质检类型
        'getTestWay', //检验方式
        //生产相关
        'getAllBomType', //bom类型
        'getAllMesCostType', //计费方式
        'getAllManufactureType', //生产类型
        //财务相关
        'getFinanceCurrency', //交易币种
        'getSysCostBankInfo', //收款账号
        'getAllCostInvoiceType', //发票类型
        'getAllCostType', //费用分类
        'getAllPaymentType', //收款类型
        //货品相关
        'getProductUnit', //计量单位
        //其他数据
        'getelectronicTemplate', //获取所有单据类型
      ]),

      /* 初始化数据字典相关数据 */
      initDictionaryData() {
        //判断仓库数据是否存在(可修改为循环获取数据格式)
        if (this.WarehouseConfig.length == 0) {
          this.getWarehouseConfig();
        }
        //判断单据类型数据是否存在
        if (this.billTemplateData.length == 0) {
          this.getelectronicTemplate();
        }
      },

      /* 初始化日期相关控制 */
      initPickerController() {
        //对账模块下的日期控制
        if (this.billsTypeSign == 9) {
          //禁用当天级当天之后的日期
          this.pickerBeginDateBefore = {
            disabledDate(time) {
              return time.getTime() > Date.now() - 86400000;
            }
          }
        }
      },

      /* 获取基本信息默认数据(回显时用到)data:回显的数据 sign:单据标识  type(0:引用 1:修改) */
      getDefaultBaseData(data, sign, type) {
        //判断当前模式
        if (this.operateType == 2) { //预览模式下不可编辑
          this.is_edit = false;
        }
        //定义不需要引入的字段数据
        let noSetData = [];
        //判断type类型,引用时需要设置不引用数据,修改时不需要
        if (type == 0) { //引用/生成
          noSetData = ['billing_type_id', 'relevance_bills']; //(单据类型,关联单据)
          //判断不为空的字段(有数据的不赋值)
          // for (let i in this.nodeSysFormValId) {
          //   if (!!this.nodeSysFormValId[i]) {
          //     noSetData.push(i);
          //   }
          // }
        }
        //判断引入的是哪一种单据(稍做处理)(未将公共js提取是因为各模块需要独立处理)
        if (sign == 0) { //销售
          let saleIdData = this.disposeHumpData(this.nodeSysFormValId, data.saleOrderMain);
          let saleNameData = this.disposeHumpData(this.nodeSysFormVal, data.salesOrderMainRelevance);
          //获取默认id数据
          for (let i in this.nodeSysFormValId) {
            //不需要赋值的字段不要赋值
            if (!noSetData.includes(i)) {
              //获取基本信息所需id所需数据
              this.nodeSysFormValId[i] = saleIdData[i];
              //获取基本信息名称所需数据
              this.nodeSysFormVal[i] = saleNameData[i];
            }
          }
          //判断是否为生成操作
          if (this.isGenerateOperateType == 1) {
            //获取关联单据编号
            this.nodeSysFormValId.relevance_bills = data.saleOrderMain.id;
            this.nodeSysFormVal.relevance_bills = data.saleOrderMain.billsNo;
          }
        } else if (sign == 1) { //生产
          let productData = this.disposeHumpData(this.nodeSysFormValId, data);
          //生产名称对应关系
          let productRelationObj = {
            product_info_id: 'productInfo.name', //生产货品
            billing_type_id: 'billingTypeInfo.bills_type', //单据类型
            house_id: 'house_name', //仓库
            manufacture_type: 'manufacture_type_name', //生产类型
            principal_id: 'principal_name', //负责人
            supplier_id: 'supplier_name', //供应商
            customer_id: 'customerName', //客户
            dept_id: 'dept_name', //部门
            calculate_id: 'calculate_name', //计量单位
            relevance_bills: 'relevance_bills_no', //关联单据号
          }

          //处理字段中含有多参的情况
          for (let i in this.nodeSysFormValId) {
            //不需要赋值的字段不要赋值
            if (!noSetData.includes(i)) {
              //获取基本信息所需id所需数据
              this.nodeSysFormValId[i] = productData[i];
              //获取基本信息名称所需数据
              if (productRelationObj.hasOwnProperty(i)) {
                // 判断是否为多个key值
                if (productRelationObj[i].indexOf(".") != -1) {
                  let corresKeyArr = productRelationObj[i].split(".");
                  this.nodeSysFormVal[i] = data[corresKeyArr[0]][corresKeyArr[1]];
                } else {
                  this.nodeSysFormVal[i] = data[productRelationObj[i]];
                }
              } else {
                this.nodeSysFormVal[i] = productData[i];
              }
            }
          }
          //判断是否为生成操作
          if (this.isGenerateOperateType == 1) {
            //获取关联单据编号
            this.nodeSysFormValId.relevance_bills = data.id;
            this.nodeSysFormVal.relevance_bills = data.bills_no;
          }
        } else if (sign == 2) { //库存
          let depotIdData = this.disposeHumpData(this.nodeSysFormValId, data.depotMain);
          let depotNameData = this.disposeHumpData(this.nodeSysFormVal, data.depotMainRelevance);
          //获取默认id数据
          for (let i in this.nodeSysFormValId) {
            //不需要赋值的字段不要赋值
            if (!noSetData.includes(i)) {
              //获取基本信息所需id所需数据
              this.nodeSysFormValId[i] = depotIdData[i];
              //获取基本信息名称所需数据
              this.nodeSysFormVal[i] = depotNameData[i];
            }
          }
          //判断是否为生成操作
          if (this.isGenerateOperateType == 1) {
            //获取关联单据编号
            this.nodeSysFormValId.relevance_bills = data.depotMain.id;
            this.nodeSysFormVal.relevance_bills = data.depotMain.billsNo;
          }
        } else if (sign == 3) { //采购
          let purchaseIdData = this.disposeHumpData(this.nodeSysFormValId, data.purchaseOrderMain);
          let purchaseNameData = this.disposeHumpData(this.nodeSysFormVal, data.purchaseMainRelevance);
          //获取默认id数据
          for (let i in this.nodeSysFormValId) {
            //不需要赋值的字段不要赋值
            if (!noSetData.includes(i)) {
              //获取基本信息所需id所需数据
              this.nodeSysFormValId[i] = purchaseIdData[i];
              //获取基本信息名称所需数据
              this.nodeSysFormVal[i] = purchaseNameData[i];
            }
          }
          // console.log(this.nodeSysFormVal);
          //判断是否为生成操作
          if (this.isGenerateOperateType == 1) {
            //获取关联单据编号
            this.nodeSysFormValId.relevance_bills = data.purchaseOrderMain.id;
            this.nodeSysFormVal.relevance_bills = data.purchaseOrderMain.billsNo;
          }
        } else if (sign == 5) { //收付款
          let costIdData = this.disposeHumpData(this.nodeSysFormValId, data.costBillsMain);
          let costNameData = this.disposeHumpData(this.nodeSysFormVal, data.costBillsMainRelevance);
          //获取默认id数据
          for (let i in this.nodeSysFormValId) {
            //不需要赋值的字段不要赋值
            if (!noSetData.includes(i)) {
              //获取基本信息所需id所需数据
              this.nodeSysFormValId[i] = costIdData[i];
              //获取基本信息名称所需数据
              this.nodeSysFormVal[i] = costNameData[i];
            }
          }
          //判断是否为生成操作
          if (this.isGenerateOperateType == 1) {
            //获取关联单据编号
            this.nodeSysFormValId.relevance_bills = data.costBillsMain.id;
            this.nodeSysFormVal.relevance_bills = data.costBillsMain.billsNo;
          }
        } else if (sign == 6) { //报销
          let costIdData = this.disposeHumpData(this.nodeSysFormValId, data.costBillsExpense);
          let costNameData = this.disposeHumpData(this.nodeSysFormVal, data.costBillsExpenseRelevance);
          //获取默认id数据
          for (let i in this.nodeSysFormValId) {
            //不需要赋值的字段不要赋值
            if (!noSetData.includes(i)) {
              //获取基本信息所需id所需数据
              this.nodeSysFormValId[i] = costIdData[i];
              //获取基本信息名称所需数据
              this.nodeSysFormVal[i] = costNameData[i];
            }
          }
          //判断是否为生成操作
          if (this.isGenerateOperateType == 1) {
            //获取关联单据编号
            this.nodeSysFormValId.relevance_bills = data.costBillsExpense.id;
            this.nodeSysFormVal.relevance_bills = data.costBillsExpense.billsNo;
          }
        } else if (sign == 8) { //发票
          let costIdData = this.disposeHumpData(this.nodeSysFormValId, data.costBillsInvoice);
          let costNameData = this.disposeHumpData(this.nodeSysFormVal, data.costBillsInvoiceRelevance);
          //获取默认id数据
          for (let i in this.nodeSysFormValId) {
            //不需要赋值的字段不要赋值
            if (!noSetData.includes(i)) {
              //获取基本信息所需id所需数据
              this.nodeSysFormValId[i] = costIdData[i];
              //获取基本信息名称所需数据
              this.nodeSysFormVal[i] = costNameData[i];
            }
          }
          //判断是否为生成操作
          if (this.isGenerateOperateType == 1) {
            //获取关联单据编号
            this.nodeSysFormValId.relevance_bills = data.costBillsInvoice.id;
            this.nodeSysFormVal.relevance_bills = data.costBillsInvoice.billsNo;
          }
        } else if (sign == 9) { //对账
          let costIdData = this.disposeHumpData(this.nodeSysFormValId, data.costAccountCheck);
          let costNameData = this.disposeHumpData(this.nodeSysFormVal, data.costAccountRelevance);
          //获取默认id数据
          for (let i in this.nodeSysFormValId) {
            //不需要赋值的字段不要赋值
            if (!noSetData.includes(i)) {
              //获取基本信息所需id所需数据
              this.nodeSysFormValId[i] = costIdData[i];
              //获取基本信息名称所需数据
              this.nodeSysFormVal[i] = costNameData[i];
            }
          }
          //判断是否为生成操作
          if (this.isGenerateOperateType == 1) {
            //获取关联单据编号
            this.nodeSysFormValId.relevance_bills = data.costAccountCheck.id;
            this.nodeSysFormVal.relevance_bills = data.costAccountRelevance.billsNo;
          }

          // 加载明细信息
          this.loadConditionBills(1);
        }

        //获取默认未存数据库的字段信息
        this.getDefaultDistionaryData(sign, data);

        //获取自定义信息
        if (data.nodeCustomFormAndValues) {
          data.nodeCustomFormAndValues.forEach((itemI, inexI) => {
            this.nodeCusFormVal.forEach((itemJ, indexJ) => {
              //根据key值id匹配
              if (itemI.formId == itemJ.custom_form_id) {
                itemJ.id = itemI.id,
                  itemJ.custom_form_value = itemI.customFormValue;
              }
            })
          })
        }
        this.$forceUpdate();
      },

      /* 获取默认未存数据库的字段信息 */
      getDefaultDistionaryData(sign, data) {
        let needObj = {}
        //判断单据类型
        if (sign == 0) { //销售
          needObj = {
            type: data.saleOrderMain.type, //订单来源
            receiptType: data.saleOrderMain.receiptType, //发票类型
            offerWayId: data.saleOrderMain.offerWayId, //报价方式
            isRate: data.saleOrderMain.isRate, //价格是否含税
          }
        } else if (sign == 3) { //采购
          needObj = {
            isRate: data.purchaseOrderMain.isRate, //价格是否含税
          }
        } else if (sign == 8) { //发票
          needObj = {
            receiptType: data.costBillsInvoice.receiptType, //发票类型
            invoiceProperty: data.costBillsInvoice.invoiceProperty, //发票属性
          }
        } else if (sign == 9) { //对账
          needObj = {
            periodType: data.costAccountCheck.periodType, //周期类型
            accountCheckTypeId: data.costAccountCheck.accountCheckTypeId, //对账类型
            costSurplusType: data.costAccountCheck.costSurplusType, //对账收付款结余类型
            returnSurplusType: data.costAccountCheck.returnSurplusType, //对账退货结余类型
          }
        }
        //处理订单来源(未存数据库)
        if (this.nodeSysFormValId.hasOwnProperty('type')) {
          let typeObj = this.commonJsExtend.getDictMatchingData(needObj.type, this.orderSourse);
          this.nodeSysFormVal.type = typeObj.name;
        }
        //处理发票类型(未存数据库)
        if (this.nodeSysFormValId.hasOwnProperty('receipt_type')) {
          let receiptTypeObj = this.commonJsExtend.getDictMatchingData(needObj.receiptType, this
            .invoiceTypeData);
          this.nodeSysFormVal.receipt_type = receiptTypeObj.name;
        }
        //处理报价方式(未存数据库)
        if (this.nodeSysFormValId.hasOwnProperty('offer_way_id')) {
          let offerWayIdObj = this.commonJsExtend.getDictMatchingData(needObj.offerWayId, this
            .offerWayData);
          this.nodeSysFormVal.offer_way_id = offerWayIdObj.name;
        }
        //处理价格是否含税(未存数据库)
        if (this.nodeSysFormValId.hasOwnProperty('is_rate')) {
          let isRateObj = this.commonJsExtend.getDictMatchingData(needObj.isRate, this
            .isRate);
          this.nodeSysFormVal.is_rate = isRateObj.name;
        }
        //处理发票属性(未存数据库)
        if (this.nodeSysFormValId.hasOwnProperty('invoice_property')) {
          let receiptPropertyObj = this.commonJsExtend.getDictMatchingData(needObj.invoiceProperty, this
            .invoicePropertyData);
          this.nodeSysFormVal.invoice_property = receiptPropertyObj.name;
        }
        //处理周期类型(未存数据库)
        if (this.nodeSysFormValId.hasOwnProperty('period_type')) {
          let periodTypeObj = this.commonJsExtend.getDictMatchingData(needObj.periodType, this
            .periodTypeData);
          this.nodeSysFormVal.period_type = periodTypeObj.name;
        }
        //处理对账类型(未存数据库)
        if (this.nodeSysFormValId.hasOwnProperty('account_check_type_id')) {
          let accountCheckTypeObj = this.commonJsExtend.getDictMatchingData(needObj.accountCheckTypeId, this
            .accountCheckType);
          this.nodeSysFormVal.account_check_type_id = accountCheckTypeObj.name;
        }
        //处理收付款结余类型(未存数据库)
        if (this.nodeSysFormValId.hasOwnProperty('cost_surplus_type')) {
          let costSurplusTypeObj = this.commonJsExtend.getDictMatchingData(needObj.costSurplusType, this
            .costSurplusData);
          this.nodeSysFormVal.cost_surplus_type = costSurplusTypeObj.name;
        }
        //处理退货结余类型(未存数据库)
        if (this.nodeSysFormValId.hasOwnProperty('return_surplus_type')) {
          let returnSurplusTypeObj = this.commonJsExtend.getDictMatchingData(needObj.returnSurplusType, this
            .returnSurplusData);
          this.nodeSysFormVal.return_surplus_type = returnSurplusTypeObj.name;
        }

        //处理特殊数据字典字段(显示格式个性化)
        if (this.nodeSysFormVal.hasOwnProperty('order_bank_id')) { //收款账号
          let bankObj = JSON.parse(JSON.stringify(this.nodeSysFormVal.order_bank_id));
          let curOrderBankArr = this.SysCostBankInfo.filter(item => item.id == bankObj.id);
          if (curOrderBankArr.length > 0) {
            this.$set(this.nodeSysFormVal, 'order_bank_id', curOrderBankArr[0].name);
          }
        }
      },

      /* 处理驼峰转换数据curObj:目标数据  replaceData:需要取的数据源 */
      disposeHumpData(curObj, replaceData) {
        //定义一个返回值
        let resultData = JSON.parse(JSON.stringify(curObj));
        //将需要获取数据的数据源进行一次转换,以免发生浅拷贝问题
        let replaceDataNew = JSON.parse(JSON.stringify(replaceData));
        //定义一个接受含有驼峰和非驼峰都有的key值
        let disposeReplaceData = {};
        //循环获取两种形式的数据
        for (let i in replaceDataNew) {
          //获取驼峰格式
          let curToHump = this.commonJsExtend.toHump(i);
          disposeReplaceData[curToHump] = replaceDataNew[i];
          //获取非驼峰格式
          let curToLine = this.commonJsExtend.toLine(i);
          disposeReplaceData[curToLine] = replaceDataNew[i];
        }

        //循环获取当前界面所需值
        for (let i in resultData) {
          if (disposeReplaceData.hasOwnProperty(i)) {
            resultData[i] = disposeReplaceData[i];
          } else {
            resultData[i] = "";
          }
        }
        return resultData;
      },

      // 当bom总成本发生变化的时候


      /* 初始化基本信息表单数据(data为传入的所有基本信息)*/
      initBaseForm(data) {
        // 赋值前需清空一次
        this.nodeSysFormData = []; //清空界面所需对应字段数据
        this.nodeHandFullFileName = []; //清空id和名称相同值字段数组
        this.nodeSelDownTypeData = []; //清空下拉数组类型
        this.nodeSysFormVal = {}; //清空基本信息名称格式
        this.nodeSysFormValId = {}; //清空基本信息id格式
        // 将获取值存入当前组件,因为子组件不能直接修改父组件的值
        this.nodeSysFormData = JSON.parse(JSON.stringify(data));
        //循环添加界面所需数据
        this.nodeSysFormData.forEach(item => {
          //添加下拉绑定数据
          item.selectData = [];
          //添加下拉数据是否显示
          item.show_selectData = false;
          //添加列是否显示
          item.isShowItem = true;
          //初始化当前节点表单数据(赋值时要使用set赋值,不然无法监听到动态赋值的对象)
          if (item.system.type == 0) { //输入框
            this.nodeHandFullFileName.push(item.system.filed_name); //id和名称相同值
            this.nodeLengthAstrict.push(item.system.filed_name); //需要限制长度内容的字段
            this.$set(this.nodeSysFormVal, item.system.filed_name, "")
            this.$set(this.nodeSysFormValId, item.system.filed_name, "")
          } else if (item.system.type == 1) { //下拉框
            //设置默认值
            this.$set(this.nodeSysFormVal, item.system.filed_name, "")
            this.$set(this.nodeSysFormValId, item.system.filed_name, "")
            //获取当前组件所需数据字典数据(未写,可优化)
            this.nodeSelDownTypeData.push(item.system);
          } else if (item.system.type == 2) { //日期格式
            this.nodeHandFullFileName.push(item.system.filed_name) //id和名称相同值
            this.$set(this.nodeSysFormVal, item.system.filed_name, this.commonJsExtend.getDateTime(new Date(), 0))
            this.$set(this.nodeSysFormValId, item.system.filed_name, this.commonJsExtend.getDateTime(new Date(), 0))
          } else if (item.system.type == 3) { //数量框
            this.nodeHandFullFileName.push(item.system.filed_name) //id和名称相同值
            this.$set(this.nodeSysFormVal, item.system.filed_name, 0)
            this.$set(this.nodeSysFormValId, item.system.filed_name, 0)
          } else if (item.system.type == 4) { //弹框
            this.$set(this.nodeSysFormVal, item.system.filed_name, "")
            this.$set(this.nodeSysFormValId, item.system.filed_name, "")
          } else if (item.system.type == 5) { //复选框
            this.$set(this.nodeSysFormVal, item.system.filed_name, '否')
            this.$set(this.nodeSysFormValId, item.system.filed_name, 0)
          } else if (item.system.type == 6) { //小数框
            this.nodeHandFullFileName.push(item.system.filed_name) //id和名称相同值
            this.$set(this.nodeSysFormVal, item.system.filed_name, '')
            this.$set(this.nodeSysFormValId, item.system.filed_name, '')
          } else if (item.system.type == 7) { //百分比
            this.nodeHandFullFileName.push(item.system.filed_name) //id和名称相同值
            this.$set(this.nodeSysFormVal, item.system.filed_name, '')
            this.$set(this.nodeSysFormValId, item.system.filed_name, '')
          } else if (item.system.type == 8) { //文本框
            this.nodeHandFullFileName.push(item.system.filed_name) //id和名称相同值
            this.$set(this.nodeSysFormVal, item.system.filed_name, '')
            this.$set(this.nodeSysFormValId, item.system.filed_name, '')
          }
          //获取节点当前必填字段
          if (item.is_must == 1) {
            this.nodeMustFileName.push(item);
          }
        })
        //默认触发一次基本信息改变方法
        this.handlerBaseInfoChange();
        //获取默认单据类型数据
        this.setDefaultBillsTypeData();
        //设置默认数据
        this.setDefaultBaseData();
      },

      /* 赋值默认基本数据方法 */
      async setDefaultBaseData(data, sign) {
        //设置弹框默认数据
        if (this.nodeSysFormValId.hasOwnProperty('house_id')) { //设置默认仓库
          this.WarehouseConfig.forEach((item, index) => {
            if (item.is_main == 0) {
              this.nodeSysFormValId.house_id = item.id; //设置id
              this.nodeSysFormVal.house_id = item.name; //设置名称
            }
          })
        }

        //判断当前登录模块
        if (this.loginType == 1) { //客户登录模块下
          //处理客户登录相关
          this.disposeCustomerLogin();
        } else if (this.loginType == 2) { //供应商登录模块下
          //处理供应商登录相关
          this.disposeSupplierLogin();
        }

        //设置下拉框默认数据
        for (let i = 0; i < this.nodeSysFormData.length; i++) {
          let systemData = this.nodeSysFormData[i];
          if (systemData.system.type == 1) {
            switch (this.nodeSysFormData[i].system.type_id) {
              // 订单相关(数据库分类 0)
              case '0.1': //报价类型
                //判断报价类型是否存在
                if (this.OrderType.length == 0) { //不存在则请求数据
                  await this.getAllOrderType();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.OrderType; //报价类型数据
                // this.nodeSysFormVal[systemData.system.filed_name] = systemData.selectData[0].name;
                break;
              case '0.2': //单据类型
                //判断单据类型是否存在
                if (this.OrderDocumentType.length == 0) { //不存在则请求数据
                  await this.getAllOrderDocumentType();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.OrderDocumentType; //单据类型数据
                break;
              case '0.3': //结算方式
                //判断结算方式是否存在
                if (this.OrderSettlement.length == 0) { //不存在则请求数据
                  await this.getAllSupplierSettlement();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.OrderSettlement; //结算方式数据
                break;
              case '0.4': //采购方式
                //判断采购方式是否存在
                if (this.PurchaseType.length == 0) { //不存在则请求数据
                  await this.getPurchaseType();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.PurchaseType; //采购方式数据
                break;
              case '0.5': //价格条款
                //判断价格条款是否存在
                if (this.PriceClause.length == 0) { //不存在则请求数据
                  await this.getPriceClause();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.PriceClause; //价格条款数据
                break;
              case '0.6': //付汇方式
                //判断付汇方式是否存在
                if (this.PaymentMode.length == 0) { //不存在则请求数据
                  await this.getPaymentMode();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.PaymentMode; //付汇方式数据
                break;
              case '0.7': //单据类型
                // 将下拉框数据赋值
                for (let i = 0; i < this.saleBillType.length; i++) {
                  this.saleBillType[i].name = this.saleBillType[i].bills_type; //存一个name用于显示
                  this.saleBillType[i].saveSign = 0; //存一个存入标识(0:单据模板)
                }
                systemData.selectData = this.saleBillType; //单据类型数据
                break;
              case '0.8': //订单来源
                // 将下拉框数据赋值
                systemData.selectData = this.orderSourse; //订单来源数据
                break;
              case '0.9': //报价方式
                // 将下拉框数据赋值
                systemData.selectData = this.offerWayData; //订单来源数据
                break;
              case '0.10': //是否含税
                // 将下拉框数据赋值
                systemData.selectData = this.isRate; //是否含税
                break;

                //仓储相关(数据库分类 1)
              case '1.1': //物流公司
                //判断物流公司是否存在
                if (this.LogisticsCompany.length == 0) { //不存在则请求数据
                  await this.getAllCompany();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.LogisticsCompany; //物流公司数据
                break;
              case '1.2': //物流方式
                //判断物流方式是否存在
                if (this.LogisticsMode.length == 0) { //不存在则请求数据
                  await this.getLogisticsMode();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.LogisticsMode; //物流方式数据
                break;
              case '1.3': //送货方式
                //判断送货方式是否存在
                if (this.DeliveryType.length == 0) { //不存在则请求数据
                  await this.getDeliveryType();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.DeliveryType; //送货方式数据
                break;
              case '1.4': //单据类型
                // 将下拉框数据赋值
                for (let i = 0; i < this.depotBillType.length; i++) {
                  this.depotBillType[i].name = this.depotBillType[i].bills_type; //存一个name用于显示
                  this.depotBillType[i].saveSign = 0; //存一个存入标识(0:单据模板)
                }
                // 将下拉框数据赋值
                systemData.selectData = this.depotBillType; //单据类型数据
                break;
              case '1.5': //质检类型
                //判断质检类型是否存在
                if (this.QualityType.length == 0) { //不存在则请求数据
                  await this.getQualityType();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.QualityType; //质检类型数据
                break;
              case '1.6': //检验方式
                //判断检验方式是否存在
                if (this.TestWay.length == 0) { //不存在则请求数据
                  await this.getTestWay();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.TestWay; //检验方式数据
                break;

                //生产相关(数据库分类 2)
              case '2.1': //bom类型
                //判断bom类型是否存在
                if (this.ProductionBomType.length == 0) { //不存在则请求数据
                  await this.getAllBomType();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.ProductionBomType; //bom类型数据
                break;
              case '2.2': //计费方式
                //判断计费方式是否存在
                if (this.CostType.length == 0) { //不存在则请求数据
                  await this.getAllMesCostType();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.CostType; //计费方式数据
                break;
              case '2.3': //生产类型
                //判断生产类型是否存在
                if (this.ManufactureType.length == 0) { //不存在则请求数据
                  await this.getAllManufactureType();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.ManufactureType; //生产类型数据
                break;
              case '2.4': //生产单据类型
                // 将下拉框数据赋值
                for (let i = 0; i < this.productBillType.length; i++) {
                  this.productBillType[i].name = this.productBillType[i].bills_type; //存一个name用于显示
                  this.productBillType[i].saveSign = 0; //存一个存入标识(0:单据模板)
                }
                systemData.selectData = this.productBillType; //单据类型数据
                break;
              case '2.5': //源单类型(生产对账单用)
                // 将下拉框数据赋值
                systemData.selectData = this.originBillType; //源单类型数据
                break;

                //财务相关(数据库分类 3)
              case '3.1': //收付款类型
                //判断收付款类型是否存在
                if (this.PaymentType.length == 0) { //不存在则请求数据
                  await this.getAllPaymentType();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.PaymentType; //收付款类型数据
                break;
              case "3.2": //收款账号
                //判断收款账号是否存在
                if (this.SysCostBankInfo.length == 0 || !this.SysCostBankInfo[0].hasOwnProperty('name')) { //不存在则请求数据
                  await this.getSysCostBankInfo();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.SysCostBankInfo; //收款账号数据
                break;
              case '3.3': //交易币种
                //判断交易币种是否存在
                if (this.FinanceCurrency.length == 0) { //不存在则请求数据
                  await this.getFinanceCurrency();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.FinanceCurrency; //交易币种数据
                break;
              case '3.4': //费用分类
                //判断费用分类是否存在
                if (this.CostType.length == 0) { //不存在则请求数据
                  await this.getAllCostType();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.CostType; //费用分类数据
                break;
              case '3.5': //发票类型
                //判断发票类型是否存在(弃用,数据字典写死)
                // if (this.CostInvoiceType.length == 0) { //不存在则请求数据
                //   await this.getAllCostInvoiceType();
                // }
                // 将下拉框数据赋值
                systemData.selectData = this.invoiceTypeData; //发票类型数据
                break;
              case '3.6': //资金单据类型
                // 将下拉框数据赋值
                for (let i = 0; i < this.costBillType.length; i++) {
                  this.costBillType[i].name = this.costBillType[i].bills_type; //存一个name用于显示
                  this.costBillType[i].saveSign = 0; //存一个存入标识(0:单据模板)
                }
                systemData.selectData = this.costBillType; //单据类型数据
                break;
              case '3.7': //发票属性
                // 将下拉框数据赋值
                systemData.selectData = this.invoicePropertyData; //发票属性数据
                break;
              case '3.8': //对账类型
                // 将下拉框数据赋值
                systemData.selectData = this.accountCheckType.filter(item => item.classify == this
                  .billsClassify); //对账类型数据
                break;
              case '3.9': //周期类型
                let periodTypeData = this.periodTypeData;
                let date = new Date();

                //获取今天是周几
                let weekDay = date.getDay(); //本周几
                if (weekDay == 1) { //周一不显示本周
                  periodTypeData = periodTypeData.filter(item => item.id != 0);
                }

                //判断今天是否为1号,
                let day = date.getDate();
                if (day == 1) { //1号不显示本月
                  periodTypeData = periodTypeData.filter(item => item.id != 3);
                }

                //判断今天是今年第几天
                let year = date.getFullYear().toString(); //本年
                let oneday = 1000 * 60 * 60 * 24; //一天的时间
                let daynum = Math.ceil((new Date() - new Date(year)) / oneday); //当天在本年的天数
                if (daynum == 1) { //第一天不显示本年
                  periodTypeData = periodTypeData.filter(item => item.id != 7);
                }

                // 将下拉框数据赋值
                systemData.selectData = periodTypeData; //周期类型数据
                break;
              case '3.10': //收付款结余类型
                // 将下拉框数据赋值
                systemData.selectData = this.costSurplusData; //收付款结余类型
                break;
              case '3.11': //退货结余类型
                // 将下拉框数据赋值
                systemData.selectData = this.returnSurplusData; //退货结余类型
                break;

                //采购相关
              case '4.1': //单据类型(可删)
                // 将下拉框数据赋值
                for (let i = 0; i < this.purchaseBillType.length; i++) {
                  this.purchaseBillType[i].name = this.purchaseBillType[i].bills_type; //存一个name用于显示
                  this.purchaseBillType[i].saveSign = 0; //存一个存入标识(0:单据模板)
                }
                systemData.selectData = this.purchaseBillType; //单据类型数据
                break;

                //货品相关
              case '5.1': //计量单位
                //判断交易币种是否存在
                if (this.ProductUnit.length == 0) { //不存在则请求数据
                  await this.getProductUnit();
                }
                // 将下拉框数据赋值
                systemData.selectData = this.ProductUnit; //交易币种数据
                break;

                //公共相关
              case '6.1': //单据类型
                this.nodeCurrentTempData.forEach((item, index) => {
                  item.name = item.bills_type; //存一个name用于显示
                  item.saveSign = 0; //存一个存入标识(0:单据模板)
                })
                systemData.selectData = this.nodeCurrentTempData; //单据类型数据
                break;
            }
            // 调用设置默认数据字典数据方法
            if (this.operateType == 0) { //新增才赋值
              this.setDefaultDictionariesData(systemData, i);
            }
          }
        }
      },

      /* 处理客户登录模块下的默认数据 */
      disposeCustomerLogin() {
        //设置默认数据
        if (this.nodeSysFormValId.hasOwnProperty('customer_id')) { //设置默认客户
          this.nodeSysFormValId.customer_id = this.LoginCustomerInfo.customer_id; //设置id
          this.nodeSysFormVal.customer_id = this.LoginCustomerInfo.customer_name; //设置名称
        }
      },

      /* 处理供应商登录模块下的默认数据 */
      disposeSupplierLogin() {
        //设置默认数据
        if (this.nodeSysFormValId.hasOwnProperty('supplier_id')) { //设置默认供应商
          this.nodeSysFormValId.supplier_id = this.LoginSupplierInfo.id; //设置id
          this.nodeSysFormVal.supplier_id = this.LoginSupplierInfo.fullName; //设置名称
        }
      },

      /* 设置默认数据字典数据 */
      setDefaultDictionariesData(systemData, i) {
        //定义不需要设置默认值的下拉类型
        let notInitFileName = ['6.1'];
        //获取默认下拉数据(默认获取第一条)
        let selectData = [];
        if (systemData.selectData.length > 0 && !notInitFileName.includes(this.nodeSysFormData[i].system
            .type_id)) {
          //获取已启用的数据
          if (!!systemData.selectData) {
            selectData = systemData.selectData.filter(item => item.is_enable == 1);
          }
          //有数据时默认获取第一个
          if (selectData.length > 0) {
            //设置默认获取的id
            this.nodeSysFormValId[systemData.system.filed_name] = selectData[0].id;
            //设置默认获取的名称
            this.nodeSysFormVal[systemData.system.filed_name] = selectData[0].name;
          }
        }

        // 判断子组件是否有传入默认数据字典数据
        if (JSON.stringify(this.dictionariesDefaultData) != "{}") {
          //判断有设置默认值的数据
          if (this.nodeSysFormData[i].system.type_id == '0.4' && this.dictionariesDefaultData.purchaseTypeCode !=
            '') { //采购方式
            //获取默认设置的采购方式数据
            let defaultPurchaseTypeData = this.commonJsExtend.getDictMatchingDataByCode(this.dictionariesDefaultData
              .purchaseTypeCode, selectData);
            //设置默认获取的id
            this.nodeSysFormValId[systemData.system.filed_name] = defaultPurchaseTypeData.id;
            //设置默认获取的名称
            this.nodeSysFormVal[systemData.system.filed_name] = defaultPurchaseTypeData.name;
          }
        }
      },

      /* 设置默认单据类型信息 */
      setDefaultBillsTypeData() {
        let defaultArr = []
        //循环前清空当前单据模板数据
        this.nodeCurrentTempData = []
        //获取当前模板数据
        if (this.billTemplateData.length > 0) {
          //循环获取分类
          this.billTemplateData.forEach((itemI, indexI) => {
            //根据code值获取分类数据
            if (itemI.temp_code == this.nodeTypeCode) {
              //循环子分类获取默认类型数据
              itemI.children.forEach((itemJ, indexJ) => {
                if (itemJ.type_classify == this.billsClassify) {
                  this.nodeCurrentTempData.push(itemJ)
                }
              })
            }
          })
        }

        //判断是否有传入默认值
        if (this.billsTypeDefaultCode) { //有默认
          this.nodeCurrentTempData.forEach((item, index) => {
            if (item.code == this.billsTypeDefaultCode) {
              this.BILLS_TYPE_DATA(item);
              this.nodeSysFormVal.billing_type_id = item.bills_type;
              this.nodeSysFormValId.billing_type_id = item.id;
            }
          })
        } else { //无默认
          //设置默认信息
          if (!!this.nodeCurrentTempData[0]) {
            this.BILLS_TYPE_DATA(this.nodeCurrentTempData[0]);
            this.nodeSysFormVal.billing_type_id = this.nodeCurrentTempData[0].bills_type;
            this.nodeSysFormValId.billing_type_id = this.nodeCurrentTempData[0].id;
          }
        }
      },
      //当bom总成本改变的时候给予相应的改变
      totalCostchange(){
        this.nodeSysFormVal.costPrice=this.totalCostPrice
        this.nodeSysFormValId.costPrice=this.totalCostPrice
      },
      /* 初始化自定义信息(data为传入的所有自定义信息)*/
      initCusBaseForm(data) {
        //循环前清空数组
        this.nodeCusFormVal = []
        // 判断是否有数据
        if (data.length > 0) {
          data.forEach(item => {
            //定义接收自定义值的对象
            let cusdata = {
              custom_data: item,
              custom_form_id: item.id,
              custom_form_value: ''
            }
            this.nodeCusFormVal.push(cusdata)
          })
        }
      },

      /* 处理节点自定义文本信息变化业务 */
      handlerBaseCusTextearData(data) {
        data.forEach((itemI, indexI) => {
          let num = 0;
          this.nodeCusFormVal.forEach((itemJ, indexJ) => {
            if (itemI.custom_form_id == itemJ.custom_form_id) {
              itemJ.custom_form_value = itemI.custom_form_value;
              num++
            }
          })
          //判断是否有值
          if (num == 0) {
            this.nodeCusFormVal.push(itemI);
          }
        })
      },

      /* 处理基本信息改变时的业务 */
      handlerBaseInfoChange() {
        //根据不同模块判断是否需要处理业务
        if (this.nodeTypeCode == "SYS-NODE-TYPE-003") { //生产模块(需要获取库存数据)
          //处理选择货品后获取货品库存数据
          if (this.nodeSysFormValId.hasOwnProperty('product_info_id') && this.nodeSysFormValId.hasOwnProperty(
              'house_id')) {
            //判断当前操作的字段(货品字段和仓库子段都可以触发)
            if (this.currentDialogFileName == 'product_info_id' || this.currentDialogFileName == 'house_id') {
              //获取计量单位
              if (this.nodeSysFormValId.hasOwnProperty('calculate_id')) {
                //获取销售计量单位
                if (this.product_data[0].productPrice && this.product_data[0].productPrice.length > 0) {
                  //过滤出单位
                  let orderPrice = this.product_data[0].productPrice.filter(item => item.is_order_default == 1);
                  if (orderPrice.length > 0) {
                    this.nodeSysFormValId.calculate_id = orderPrice[0].calculate_type;
                    this.nodeSysFormVal.calculate_id = orderPrice[0].calculateName;
                  }
                }
              }

              //判断字段数据不为空
              if (this.nodeSysFormValId.product_info_id != "" && this.nodeSysFormValId.house_id != "") {
                //获取该货品库存信息
                this.getProWarehouseNumber(this.nodeSysFormValId.product_info_id, this.nodeSysFormValId.house_id);
              }
            }
          }
        }

        //判断数据字典相互影响的字段
        if (this.billsTypeSign == 1) { //生产模块下
          if (this.nodeSysFormValId.hasOwnProperty('manufacture_type')) { //生产类型(生产类型为委外时显示供应商,其他不显示)
            //获取当前选择的生产类型数据
            let manufactureData = this.commonJsExtend.getDictMatchingData(this.nodeSysFormValId.manufacture_type, this
              .ManufactureType);
            // 判断是否有供应商
            if (this.nodeSysFormValId.hasOwnProperty('supplier_id')) {
              //获取供应商下标
              let supplierIndex = -1;
              this.nodeSysFormData.forEach((itemI, indexI) => {
                if (itemI.system.filed_name == 'supplier_id') {
                  supplierIndex = indexI;
                }
              })
              //判断生产类型
              if (manufactureData.code == "DICT-SMM-002") { //委外生产类型
                this.$set(this.nodeSysFormData[supplierIndex], 'isShowItem', true)
              } else {
                this.$set(this.nodeSysFormData[supplierIndex], 'isShowItem', false)
              }
            }
          }
        } else if (this.billsTypeSign == 8) { //发票模块下
          if (this.nodeSysFormValId.hasOwnProperty('receipt_type')) { //发票类型
            //获取当前选择的发票类型数据
            let invoiceData = this.commonJsExtend.getDictMatchingData(this.nodeSysFormValId.receipt_type, this
              .invoiceTypeData);
            //有影响的字段纳税人辨别号,地址,电话,开户名称,开户银行,开户账号
            let showKeyArr = ['receipt_code', 'address', 'telephone', 'account_name', 'account_bank', 'account_number'];
            //循环处理字段
            showKeyArr.forEach((itemI, indexI) => {
              //判断是否有该属性
              if (this.nodeSysFormValId.hasOwnProperty(itemI)) {
                //获取对应字段下标
                let itemFileIndex = -1;
                this.nodeSysFormData.forEach((itemJ, indexJ) => {
                  if (itemJ.system.filed_name == itemI) {
                    itemFileIndex = indexJ;
                  }
                })
                //判断类型
                if (invoiceData.id == 2) {
                  this.$set(this.nodeSysFormData[itemFileIndex], 'isShowItem', true)
                } else {
                  this.$set(this.nodeSysFormData[itemFileIndex], 'isShowItem', false)
                }
              }
            })
          }
        }

        // 处理周期
        if (this.nodeSysFormValId.hasOwnProperty('period_type')) { //周期类型
          //判断是否有周期起止日期
          if (!this.nodeSysFormValId.hasOwnProperty('period_start_time')) {
            this.$message({
              type: 'warning',
              message: '该节点未配置周期开始日期字段,请联系管理员配置!',
              duration: this.elDuration
            })
            return
          }
          if (!this.nodeSysFormValId.hasOwnProperty('period_end_time')) {
            this.$message({
              type: 'warning',
              message: '该节点未配置周期结束日期字段,请联系管理员配置!',
              duration: this.elDuration
            })
            return
          }
          //根据类型获取周期时间
          let date = this.commonJsExtend.getDateByPeriodType(this.nodeSysFormValId.period_type);

          // //判断是否为自定义
          if (this.nodeSysFormValId.period_type != 9) {
            //获取日期(值格式)
            this.nodeSysFormVal.period_start_time = date.startDate;
            this.nodeSysFormVal.period_end_time = date.endDate;
            //获取日期(id格式)
            this.nodeSysFormValId.period_start_time = date.startDate;
            this.nodeSysFormValId.period_end_time = date.endDate;
          }
        }

        //将id格式数据存入名称格式
        for (let i in this.nodeSysFormValId) {
          if (this.nodeHandFullFileName.includes(i) && i != 'calculate_id') {
            this.nodeSysFormValId[i] = this.nodeSysFormVal[i];
          }
        }
        //将表单填写数据存入vuex
        this.BILLS_BASE_DATA(this.nodeSysFormValId);
      },

      /* 处理所选客户发生变化业务 */
      handlerCustomerChange(value) {
        //判断新增下才需要
        if (this.operateType != 0) {
          return
        }
        // 设置客户有关信息
        //税率
        if (this.nodeSysFormValId.hasOwnProperty('tax_rage')) {
          this.nodeSysFormValId.tax_rage = this.customer_data.customerDefaultCosts == null ? this.nodeSysFormValId
            .tax_rage : this.customer_data
            .customerDefaultCosts.tax_rage;
          this.nodeSysFormVal.tax_rage = this.customer_data.customerDefaultCosts == null ? this.nodeSysFormVal
            .tax_rage : this.customer_data
            .customerDefaultCosts.tax_rage;
        }
        //价格是否含税
        if (this.nodeSysFormValId.hasOwnProperty('is_rate')) {
          this.nodeSysFormValId.is_rate = this.customer_data.customerDefaultCosts == null ? this.nodeSysFormValId
            .is_rate : this.customer_data
            .customerDefaultCosts.is_rate;
          let defaultRate = this.isRate.filter(item => item.id == this.nodeSysFormValId.is_rate);
          if (defaultRate.length > 0) {
            this.nodeSysFormVal.is_rate = defaultRate[0].name;
          }
        }
        //汇率
        if (this.nodeSysFormValId.hasOwnProperty('exchange_rage')) {
          this.nodeSysFormValId.exchange_rage = this.customer_data.customerDefaultCosts == null ? this.nodeSysFormValId
            .exchange_rage : this
            .customer_data.customerDefaultCosts.exchange_rage;
          this.nodeSysFormVal.exchange_rage = this.customer_data.customerDefaultCosts == null ? this.nodeSysFormVal
            .exchange_rage : this.customer_data
            .customerDefaultCosts.exchange_rage;
        }
        //发票抬头
        if (this.nodeSysFormValId.hasOwnProperty('receipt_title')) {
          this.nodeSysFormValId.receipt_title = this.customer_data.customer_name;
          this.nodeSysFormVal.receipt_title = this.customer_data.customer_name;
        }
        //增值税票相关
        if (this.nodeSysFormValId.hasOwnProperty('receipt_type') && this.nodeSysFormValId.receipt_type == 2) {
          // 联系地址
          if (this.nodeSysFormValId.hasOwnProperty('address')) {
            this.nodeSysFormValId.address = this.customer_data.address;
            this.nodeSysFormVal.address = this.customer_data.address;
          }
          //联系电话
          if (this.nodeSysFormValId.hasOwnProperty('telephone')) {
            this.nodeSysFormValId.telephone = this.customer_data.phone;
            this.nodeSysFormVal.telephone = this.customer_data.phone;
          }
          //纳税人识别号
          if (this.nodeSysFormValId.hasOwnProperty('receipt_code')) {
            this.nodeSysFormValId.receipt_code = this.customer_data.customerDefaultCosts == null ? this.nodeSysFormValId
              .receipt_code : this.customer_data.customerDefaultCosts.receipt_code;
            this.nodeSysFormVal.receipt_code = this.customer_data.customerDefaultCosts == null ? this.nodeSysFormVal
              .receipt_code : this.customer_data.customerDefaultCosts.receipt_code;
          }
          //开户人名称
          if (this.nodeSysFormValId.hasOwnProperty('account_name')) {
            this.nodeSysFormValId.account_name = this.customer_data.customerDefaultCosts == null ? this.nodeSysFormValId
              .account_name : this.customer_data.customerDefaultCosts.account_name;
            this.nodeSysFormVal.account_name = this.customer_data.customerDefaultCosts == null ? this.nodeSysFormVal
              .account_name : this.customer_data.customerDefaultCosts.account_name;
          }
          // 开户银行
          if (this.nodeSysFormValId.hasOwnProperty('account_bank')) {
            this.nodeSysFormValId.account_bank = this.customer_data.customerDefaultCosts == null ? this.nodeSysFormValId
              .account_bank : this.customer_data.customerDefaultCosts.account_bank;
            this.nodeSysFormVal.account_bank = this.customer_data.customerDefaultCosts == null ? this.nodeSysFormVal
              .account_bank : this.customer_data.customerDefaultCosts.account_bank;
          }
          //开户账号
          if (this.nodeSysFormValId.hasOwnProperty('account_number')) {
            this.nodeSysFormValId.account_number = this.customer_data.customerDefaultCosts == null ? this
              .nodeSysFormValId.account_number : this.customer_data.customerDefaultCosts.account_number;
            this.nodeSysFormVal.account_number = this.customer_data.customerDefaultCosts == null ? this.nodeSysFormVal
              .account_number : this.customer_data.customerDefaultCosts.account_number;
          }
        }
        //客户对账单结存时间
        if (this.nodeSysFormValId.hasOwnProperty('period_type') && this.nodeSysFormValId.hasOwnProperty(
            'period_start_time') && this.nodeSysFormValId.hasOwnProperty('period_end_time')) {
          //定义日期相关参数
          let date = new Date();
          let year = date.getFullYear().toString(); //本年
          let mon = date.getMonth() + 1; //本月
          let day = date.getDate(); //当天(多少号)
          let nowtime = date.getTime(); //现在的时间
          let lastmonnum = new Date(year, mon - 1, 0).getDate(); //上月天数
          let oneday = 1000 * 60 * 60 * 24; //一天的时间
          let lastMonOneDate = nowtime - (day + lastmonnum - 1) * oneday; //(上月1号)今天号数+上月天数
          lastMonOneDate = this.commonJsExtend.getDateTime(new Date(lastMonOneDate), 0); //将时间戳转换下
          //判断是否设置客户结存时间
          if (this.customer_data.customerDefaultCosts && this.customer_data.customerDefaultCosts.balance_time && this
            .customer_data.customerDefaultCosts.balance_time != 0) { //有设置
            //设置周期类型为自定义
            this.nodeSysFormValId.period_type = 9;
            this.nodeSysFormVal.period_type = "自定义";
            //设置开始时间为上月1号
            this.nodeSysFormValId.period_start_time = lastMonOneDate;
            this.nodeSysFormVal.period_start_time = lastMonOneDate;
            //设置结束时间为设置时间
            if (this.customer_data.customerDefaultCosts.balance_time > lastmonnum) {
              this.nodeSysFormValId.period_end_time = lastmonnum;
              this.nodeSysFormVal.period_end_time = lastmonnum;
            } else {
              let balance_time = "";
              //处理日
              let curBalanceTime = this.customer_data.customerDefaultCosts.balance_time;
              if (curBalanceTime < 10) {
                curBalanceTime = "0" + curBalanceTime;
              }
              //判断是否为1月
              if (mon == 1) {
                balance_time = (year - 1) + "-" + 12 + "-" + curBalanceTime;
              } else {
                //获取月份
                let lastMon = mon - 1;
                if (lastMon < 10) {
                  lastMon = "0" + lastMon;
                }
                balance_time = year + "-" + lastMon + "-" + curBalanceTime;
              }
              //设置日期
              this.nodeSysFormValId.period_end_time = balance_time;
              this.nodeSysFormVal.period_end_time = balance_time;
            }
          } else { //未设置
            //判断系统是否设置客户结算时间
            if (this.companyparamlist.param363 != "0") {
              //设置周期类型为自定义
              this.nodeSysFormValId.period_type = 9;
              this.nodeSysFormVal.period_type = "自定义";
              //设置开始时间为上月1号
              this.nodeSysFormValId.period_start_time = lastMonOneDate;
              this.nodeSysFormVal.period_start_time = lastMonOneDate;
              //设置结束时间为设置时间
              if (parseInt(this.companyparamlist.param363) > lastmonnum) {
                this.nodeSysFormValId.period_end_time = lastmonnum;
                this.nodeSysFormVal.period_end_time = lastmonnum;
              } else {
                let balance_time = "";
                //处理日
                let curBalanceTime = this.companyparamlist.param363;
                if (curBalanceTime < 10) {
                  curBalanceTime = "0" + curBalanceTime;
                }
                //判断是否为1月
                if (mon == 1) {
                  balance_time = (year - 1) + "-" + 12 + "-" + curBalanceTime;
                } else {
                  //获取月份
                  let lastMon = mon - 1;
                  if (lastMon < 10) {
                    lastMon = "0" + lastMon;
                  }
                  balance_time = year + "-" + lastMon + "-" + curBalanceTime;
                }
                //设置日期
                this.nodeSysFormValId.period_end_time = balance_time;
                this.nodeSysFormVal.period_end_time = balance_time;
              }
            }
          }
        }
      },

      /*当bom总成本发生改变的时候*/
      handlercostPriceChange(){
        this.nodeSysFormValId.total_amount =Number((this.nodeSysFormValId.plan_num*this.nodeSysFormValId.costPrice).toFixed(3)) ;
        this.nodeSysFormVal.total_amount =Number((this.nodeSysFormValId.plan_num*this.nodeSysFormValId.costPrice).toFixed(3));
      },

      /*处理计划数量发生改变的时候*/
      handlerplanNumChange(){
       this.nodeSysFormValId.total_amount =Number((this.nodeSysFormValId.plan_num*this.nodeSysFormValId.costPrice).toFixed(3)) ;
       this.nodeSysFormVal.total_amount =Number((this.nodeSysFormValId.plan_num*this.nodeSysFormValId.costPrice).toFixed(3));
      },

      /* 处理所选供应商发生变化业务 */
      handlerSupplierChange() {
        //判断新增下才需要
        if (this.operateType != 0) {
          return
        }
        // 设置供应商有关信息
        //税率
        if (this.nodeSysFormValId.hasOwnProperty('tax_rage')) {
          this.nodeSysFormValId.tax_rage = this.supplier_data.supplierCustomerDefaultCost == null ? this
            .nodeSysFormValId.tax_rage : this.supplier_data.supplierCustomerDefaultCost.taxRage;
          this.nodeSysFormVal.tax_rage = this.supplier_data.supplierCustomerDefaultCost == null ? this.nodeSysFormVal
            .tax_rage : this.supplier_data.supplierCustomerDefaultCost.taxRage;
        }
        //价格是否含税
        if (this.nodeSysFormValId.hasOwnProperty('is_rate')) {
          this.nodeSysFormValId.is_rate = this.supplier_data.supplierCustomerDefaultCost == null ? this.nodeSysFormValId
            .is_rate : this.supplier_data.supplierCustomerDefaultCost.isRate;
          let defaultRate = this.isRate.filter(item => item.id == this.nodeSysFormValId.is_rate);
          if (defaultRate.length > 0) {
            this.nodeSysFormVal.is_rate = defaultRate[0].name;
          }
        }
        //汇率
        if (this.nodeSysFormValId.hasOwnProperty('exchange_rage')) {
          this.nodeSysFormValId.exchange_rage = this.supplier_data.supplierCustomerDefaultCost == null ? this
            .nodeSysFormValId.exchange_rage : this.supplier_data.supplierCustomerDefaultCost.exchangeRage;
          this.nodeSysFormVal.exchange_rage = this.supplier_data.supplierCustomerDefaultCost == null ? this
            .nodeSysFormVal.exchange_rage : this.supplier_data.supplierCustomerDefaultCost.exchangeRage;
        }
        //结算方式
        if (this.nodeSysFormValId.hasOwnProperty('setlement_id') && !!this.supplier_data.settlementId) {
          this.nodeSysFormValId.setlement_id = this.supplier_data == null ? this
            .nodeSysFormValId.setlement_id : this.supplier_data.settlementId;
          this.nodeSysFormVal.setlement_id = this.supplier_data == null ? this
            .nodeSysFormVal.setlement_id : this.supplier_data.settlementName;
        }
        //发票抬头
        if (this.nodeSysFormValId.hasOwnProperty('receipt_title')) {
          this.nodeSysFormValId.receipt_title = this.supplier_data.fullName;
          this.nodeSysFormVal.receipt_title = this.supplier_data.fullName;
        }
        //增值税票相关
        if (this.nodeSysFormValId.hasOwnProperty('receipt_type') && this.nodeSysFormValId.receipt_type == 2) {
          // 联系地址
          if (this.nodeSysFormValId.hasOwnProperty('address')) {
            this.nodeSysFormValId.address = this.supplier_data.address;
            this.nodeSysFormVal.address = this.supplier_data.address;
          }
          //联系电话
          if (this.nodeSysFormValId.hasOwnProperty('telephone')) {
            this.nodeSysFormValId.telephone = this.supplier_data.phone;
            this.nodeSysFormVal.telephone = this.supplier_data.phone;
          }
          //纳税人识别号
          if (this.nodeSysFormValId.hasOwnProperty('receipt_code')) {
            this.nodeSysFormValId.receipt_code = this.supplier_data.taxCode;
            this.nodeSysFormVal.receipt_code = this.supplier_data.taxCode;
          }
          //开户人名称
          if (this.nodeSysFormValId.hasOwnProperty('account_name')) {
            this.nodeSysFormValId.account_name = this.supplier_data.linkmanName;
            this.nodeSysFormVal.account_name = this.supplier_data.linkmanName;
          }

          // 开户银行
          if (this.nodeSysFormValId.hasOwnProperty('account_bank')) {
            this.nodeSysFormValId.account_bank = this.supplier_data.openingBank;
            this.nodeSysFormVal.account_bank = this.supplier_data.openingBank;
          }
          //开户账号
          if (this.nodeSysFormValId.hasOwnProperty('account_number')) {
            this.nodeSysFormValId.account_number = this.supplier_data.bankAccount;
            this.nodeSysFormVal.account_number = this.supplier_data.bankAccount;
          }
        }
        //供应商对账单结存时间
        if (this.nodeSysFormValId.hasOwnProperty('period_type') && this.nodeSysFormValId.hasOwnProperty(
            'period_start_time') && this.nodeSysFormValId.hasOwnProperty('period_end_time')) {
          //定义日期相关参数
          let date = new Date();
          let year = date.getFullYear().toString(); //本年
          let mon = date.getMonth() + 1; //本月
          let day = date.getDate(); //当天(多少号)
          let nowtime = date.getTime(); //现在的时间
          let lastmonnum = new Date(year, mon - 1, 0).getDate(); //上月天数
          let oneday = 1000 * 60 * 60 * 24; //一天的时间
          let lastMonOneDate = nowtime - (day + lastmonnum - 1) * oneday; //(上月1号)今天号数+上月天数
          lastMonOneDate = this.commonJsExtend.getDateTime(new Date(lastMonOneDate), 0); //将时间戳转换下
          //判断是否设置供应商结存时间
          if (this.supplier_data.supplierCustomerDefaultCost && this.supplier_data.supplierCustomerDefaultCost
            .balanceTime && this.supplier_data.supplierCustomerDefaultCost
            .balanceTime != 0) { //有设置
            //设置周期类型为自定义
            this.nodeSysFormValId.period_type = 9;
            this.nodeSysFormVal.period_type = "自定义";
            //设置开始时间为上月1号
            this.nodeSysFormValId.period_start_time = lastMonOneDate;
            this.nodeSysFormVal.period_start_time = lastMonOneDate;
            //设置结束时间为设置时间
            if (this.supplier_data.supplierCustomerDefaultCost.balanceTime > lastmonnum) {
              this.nodeSysFormValId.period_end_time = lastmonnum;
              this.nodeSysFormVal.period_end_time = lastmonnum;
            } else {
              let balance_time = "";
              //处理日
              let curBalanceTime = this.supplier_data.supplierCustomerDefaultCost.balanceTime;
              if (curBalanceTime < 10) {
                curBalanceTime = "0" + curBalanceTime;
              }
              //判断是否为1月
              if (mon == 1) {
                balance_time = (year - 1) + "-" + 12 + "-" + curBalanceTime;
              } else {
                //获取月份
                let lastMon = mon - 1;
                if (lastMon < 10) {
                  lastMon = "0" + lastMon;
                }
                balance_time = year + "-" + lastMon + "-" + curBalanceTime;
              }
              //设置日期
              this.nodeSysFormValId.period_end_time = balance_time;
              this.nodeSysFormVal.period_end_time = balance_time;
            }
          } else { //未设置
            //判断系统是否设置供应商结算时间
            if (this.companyparamlist.param364 != "0") {
              //设置周期类型为自定义
              this.nodeSysFormValId.period_type = 9;
              this.nodeSysFormVal.period_type = "自定义";
              //设置开始时间为上月1号
              this.nodeSysFormValId.period_start_time = lastMonOneDate;
              this.nodeSysFormVal.period_start_time = lastMonOneDate;
              //设置结束时间为设置时间
              if (parseInt(this.companyparamlist.param364) > lastmonnum) {
                this.nodeSysFormValId.period_end_time = lastmonnum;
                this.nodeSysFormVal.period_end_time = lastmonnum;
              } else {
                let balance_time = "";
                //处理日
                let curBalanceTime = this.companyparamlist.param364;
                if (curBalanceTime < 10) {
                  curBalanceTime = "0" + curBalanceTime;
                }
                //判断是否为1月
                if (mon == 1) {
                  balance_time = (year - 1) + "-" + 12 + "-" + curBalanceTime;
                } else {
                  //获取月份
                  let lastMon = mon - 1;
                  if (lastMon < 10) {
                    lastMon = "0" + lastMon;
                  }
                  balance_time = year + "-" + lastMon + "-" + curBalanceTime;
                }
                //设置日期
                this.nodeSysFormValId.period_end_time = balance_time;
                this.nodeSysFormVal.period_end_time = balance_time;
              }
            }
          }
        }
      },

      /* 获取货品库存信息 */
      getProWarehouseNumber(proDataIdStr, houseId) {
        //定义接口接受参数
        let data = {
          ids: proDataIdStr, //货品id
          warehouseId: houseId, //仓库id
        }
        //加载loading框
        // this.loading = this.commonJsExtend.customLoading("#nodeSysTableBox", 4, '库存信息获取中,请稍后...');
        depotApi.findProNumByHouseIdAndProId(data).then(res => {
          if (res.code == 200) {
            if (res.data[0]) {
              //获取当前货品库存信息
              if (this.nodeSysFormVal.hasOwnProperty('origin_inventory')) { //获取库存
                this.nodeSysFormVal.origin_inventory = this.commonJsExtend.getProductPurchaseNumber(res.data[0]
                  .warehouseTotalNumber, this.product_data[0].productPrice);
              }
              //获取当前货品批次信息
              if (res.data[0].depotStockNumber && res.data[0].depotStockNumber.length > 0) {
                let defaultStock = res.data[0].depotStockNumber[0];
                //循环获取对应数据(用val格式接受就行,id格式不需要)
                for (let i in defaultStock) {
                  //转换驼峰方式
                  let humpFiled = this.commonJsExtend.toHump(i);
                  //判断有key值的才赋值(驼峰)
                  if (this.nodeSysFormVal.hasOwnProperty(humpFiled)) {
                    this.nodeSysFormVal[i] = defaultStock[humpFiled];
                  }
                  //判断有key值的才赋值(非驼峰)
                  if (this.nodeSysFormVal.hasOwnProperty(i)) {
                    this.nodeSysFormVal[i] = defaultStock[i];
                  }
                }
              }
              //清空当前操作弹框数据
              this.currentDialogFileName = "";
            } else {
              this.$message({
                type: 'warning',
                message: '该货品在当前仓库暂无库存信息!',
                duration: this.elDuration
              })
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 判断基本信息发生改变时的数据(修改单据) */
      judgeChangeData() {
        //判断是否为修改界面(其他界面不执行此方法)
        if (this.operateType != 1) {
          return
        }
        //定义系统数据
        let systemData = this.curNodeBaseData.system;

        //定义一个日志相关内容字段
        let oldName = this.nodeSysFormOldVal[systemData.filed_name]; //修改前数据
        let newName = this.nodeSysFormVal[systemData.filed_name]; //修改后数据
        let logContent = "更改了【" + this.curNodeBaseData.show_name + "】信息,更改前:" + oldName + ",更改后:" + newName;

        //定义需要修改的主表参数
        let updateMain = {
          id: this.generateBillsId, //需要修改的单据id
        }
        //获取当前修改的字段
        updateMain[systemData.filed_name] = this.nodeSysFormValId[systemData.filed_name];

        //定义请求参数
        let updateData = {
          logContent: logContent, //日志内容
        }
        let baseUpdataData = this.commonJsExtend.getBaseNameData(updateMain, this.nodeSysFormVal);
        // 发送请求前加载loading框
        // this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '单据信息修改中,请稍候...');
        //判断是哪种单据
        if (this.nodeTypeCode == 'SYS-NODE-TYPE-001') { //销售模块单据
          //获取主表信息
          updateData.saleOrderMain = JSON.stringify(baseUpdataData);
          //发送请求
          sellApi.updateSaleOrderMain(updateData).then(res => {
            // this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '【' + this.curNodeBaseData.show_name + '】信息修改成功!',
                duration: this.elDuration
              })
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.nodeTypeCode == 'SYS-NODE-TYPE-002') { //采购模块单据
          //获取主表信息
          updateData.purchaseOrderMain = JSON.stringify(baseUpdataData);
          //发送请求
          purchaseApi.updatePurchaseMain(updateData).then(res => {
            // this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '【' + this.curNodeBaseData.show_name + '】信息修改成功!',
                duration: this.elDuration
              })
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.nodeTypeCode == 'SYS-NODE-TYPE-003') { //生产模块单据
          //获取主表信息
          updateData.mesMain = JSON.stringify(baseUpdataData);
          //发送请求
          productApi.updateMesMain(updateData).then(res => {
            // this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '【' + this.curNodeBaseData.show_name + '】信息修改成功!',
                duration: this.elDuration
              })
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.nodeTypeCode == 'SYS-NODE-TYPE-004') { //库存模块单据
          //获取主表信息
          updateData.depotMain = JSON.stringify(baseUpdataData);
          //发送请求
          depotApi.updateDepotMain(updateData).then(res => {
            // this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '【' + this.curNodeBaseData.show_name + '】信息修改成功!',
                duration: this.elDuration
              })
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.nodeTypeCode == 'SYS-NODE-TYPE-005') { //资金模块单据
          if (this.billsTypeSign == 5) { //收付款模块
            //获取主表信息
            updateData.costBillsMain = JSON.stringify(baseUpdataData);
            //发送请求
            costApi.updateCostBillsMain(updateData).then(res => {
              // this.loading.close();
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '【' + this.curNodeBaseData.show_name + '】信息修改成功!',
                  duration: this.elDuration
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          } else if (this.billsTypeSign == 6) { //报销模块
            //获取主表信息
            updateData.costBillsExpense = JSON.stringify(baseUpdataData);
            //发送请求
            costApi.updateBillsExpense(updateData).then(res => {
              // this.loading.close();
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '【' + this.curNodeBaseData.show_name + '】信息修改成功!',
                  duration: this.elDuration
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          } else if (this.billsTypeSign == 8) { //发票模块
            //获取主表信息
            updateData.costBillsInvoice = JSON.stringify(baseUpdataData);
            //发送请求
            costApi.updateCostBillsInvoice(updateData).then(res => {
              // this.loading.close();
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '【' + this.curNodeBaseData.show_name + '】信息修改成功!',
                  duration: this.elDuration
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          } else if (this.billsTypeSign == 9) { //对账模块
            //获取主表信息
            updateData.costAccountCheck = JSON.stringify(baseUpdataData);
            //发送请求
            costApi.updateBillsCheck(updateData).then(res => {
              // this.loading.close();
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '【' + this.curNodeBaseData.show_name + '】信息修改成功!',
                  duration: this.elDuration
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }
        }
      },

      judgeChangeFiledData(data) {
        //修改后的值
        let newVal = data.custom_form_value;
        //修改前的值
        let oldVal = this.nodeCusFormOldVal.custom_form_value;
        //定义日志信息
        let logContent = "更改了【" + data.custom_data.name + "】信息,更改前:" + oldVal + ",更改后:" + newVal;
        //定义修改信息
        let updateDate = {
          id: data.id,
          custom_form_value: data.custom_form_value,
          logContent
        }
        sellApi.updateSysNodeCustomValue(updateDate).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '【' + data.custom_data.name + '】信息修改成功!',
              duration: this.elDuration
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 判断点击表单信息时获取哪些关联数据*/
      async judgeRelatedData(data, index) {
        //定义系统数据
        let systemData = data.system;
        // 判断当前操作方式
        if (this.operateType == 1) { //修改
          //定义不可修改的字段
          let noEidtArr = ['billing_type_id', 'relevance_bills', 'product_info_id']; //单据类型,关联单据,生产货品
          if (noEidtArr.includes(systemData.filed_name)) {
            this.$message({
              type: 'warning',
              message: "【" + data.show_name + "】信息不可更改!",
              duration: this.elDuration
            })
            return
          }
        } else if (this.operateType == 2) { //预览(不可操作)
          return
        }

        this.curOperateFormLi = index;

        //触发获取修改前数据方法
        this.getCurOperateData(data, 0);

        //判断是否可编辑
        // if (this.is_edit) {
        //   let noEidtArr = ['billing_type_id', 'relevance_bills']
        //   if (!noEidtArr.includes(systemData.filed_name)) {
        //     return
        //   }
        // }

        // 属于下拉框展现形式
        if (systemData.type == 1) { //下拉框数据
          //调用方法判断是否显示其他菜单
          this.closeOtherMenu(index, 0);
          this.$set(this.nodeSysFormData, index, data)
        } else {
          //关闭所有下拉框
          this.closeOtherMenu(index, 1);
        }

        //日期框展现形式
        if (systemData.type == 2) {
          // console.log(systemData.filed_name);
        }

        //属于弹框展现形式
        if (systemData.type == 4) { //弹框数据
          //将当前操作的弹框字段存入data
          this.currentDialogFileName = systemData.filed_name;
          // 判断弹框类型
          //客户选择弹框
          if (systemData.type_id == 0) {
            // 判断登录类型
            if (this.loginType == 0) { //用户登录下才可以选择
              this.show_coustomerComponent = true;
              this.SHOW_CUSTOMERBOX(true);
            }
          }
          //仓库选择弹框
          else if (systemData.type_id == 1) {
            this.show_warehouseComponent = true;
            this.SHOW_WAREHOUSEBOX(true);
          } else if (systemData.type_id == 2) { //单据选择弹框
            //公共查询条件
            //判断有无客户查询条件
            if (this.nodeSysFormValId.hasOwnProperty('customer_id') && this.nodeSysFormValId.customer_id != '') {
              this.conditionObj.customer_id = this.nodeSysFormValId.customer_id;
            }
            //判断有无供应商查询条件
            if (this.nodeSysFormValId.hasOwnProperty('supplier_id') && this.nodeSysFormValId.supplier_id != '') {
              this.conditionObj.supplierId = this.nodeSysFormValId.supplier_id;
            }
            //判断不同模块下的条件
            if (this.billsTypeSign == 0) { //销售模块
              //设置可以引入哪些模块单据
              this.showSignArr = [0]; //销售单据
              //设置单据查询条件
              this.conditionObj.is_order = 0; //销售模块下只能引入报价单
              this.conditionObj.is_sale_submit = "4"; //销售模块下引入已审核单据
              this.conditionObj.order_state = "0,1,3"; //未生成,生成中,待生成
            } else if (this.billsTypeSign == 1) { //生产计划模块
              //设置可以引入哪些模块单据
              this.showSignArr = [0]; //销售单据
              //设置单据查询条件
              this.conditionObj.is_order = 1; //只能引入报价单
              this.conditionObj.is_sale_submit = "4"; //引入已审核单据
              this.conditionObj.order_mes_state = "0,1,4"; //未生产,生产中,待生产
            } else if (this.billsTypeSign == 3) { //采购模块
              if (this.billsClassify == 0) { //采购申请
                this.showSignArr = [0, 1, 7]; //销售单据,生产单据,生产执行单
              } else if (this.billsClassify == 1) { //采购订单
                this.showSignArr = [0, 1, 3]; //销售单据,生产单据,采购单据
              } else if (this.billsClassify == 2) { //采购询价
                this.showSignArr = [3]; //采购单据
              }
              //销售相关
              this.conditionObj.is_order = 1; //采购模块下只能引入销售订单
              this.conditionObj.is_sale_submit = "4"; //已审核的订单
              this.conditionObj.order_purchase_state = "0,1,4"; //未采购和采购中,待采购的订单
              //采购相关
              if (this.billsClassify == 1) { //采购订单
                this.conditionObj.purchaseClassify = "0,2"; //采购模块下订单引入采购申请单和采购询价单
                this.conditionObj.purchase_state = "0,1,3"; //未生成和生成中,待生成
              } else if (this.billsClassify == 2) { //采购询价
                this.conditionObj.purchaseClassify = "0"; //采购模块下询价引入采购申请单
              }
              this.conditionObj.is_purchase_submit = "4"; //采购模块下引入已审核
              //生产计划相关
              this.conditionObj.mes_purchase_state = "0,1,4"; //未采购和采购中,待采购
              //生产执行单相关
              this.conditionObj.mes_process_purchase_state = "0,1,4"; //未采购和采购中,待采购
            } else if (this.billsTypeSign == 2) { //库存模块
              //设置可以引入哪些模块单据
              if (this.billsClassify == 0) { //入库单类型
                this.showSignArr = [3, 2, 1, 7] //采购单据,出入库单据,生产计划单据,生产执行单
              } else if (this.billsClassify == 1) { //出库单类型
                this.showSignArr = [0, 2, 1]; //销售单据,出入库单据,生产计划单据
              } else if (this.billsClassify == 9) { //退货单类型
                this.showSignArr = [0, 3]; //销售单据,采购单据
              } else if (this.billsClassify == 10) { //质检单类型
                this.showSignArr = [2]; //出入库单
              } else if (this.billsClassify == 12) { //补货单类型
                this.showSignArr = [0, 3]; //出入库单
              }
              //设置单据查询条件
              // ***********销售单相关***********
              this.conditionObj.is_order = 1; //库存模块下只能引入销售订单
              //判断退货单只能引入已出库的
              if (this.billsClassify == 9 || this.billsClassify == 12) { //退货单,补货单
                this.conditionObj.out_depot_state = "1,2"; //库存模块下引入出库中和已出库的销售订单
              } else {
                this.conditionObj.out_depot_state = "0,1,3"; //库存模块下引入未出库和出库中,待出库的销售订单
              }
              this.conditionObj.is_sale_submit = "4"; //库存模块下引入已审核销售单
              // ***********采购单相关***********
              this.conditionObj.purchaseClassify = 1; //库存模块下只能引入采购订单
              //判断退货单只能引入已入库的
              if (this.billsClassify == 9) {
                this.conditionObj.in_depot_state = "1,2"; //库存模块下引入出库中和已入库的采购订单
              } else {
                this.conditionObj.in_depot_state = "0,1,3"; //库存模块下引入未入库和入库中,待入库的采购订单
              }
              this.conditionObj.is_purchase_submit = "4"; //库存模块下引入已审核采购订单
              // ***********出入库单相关***********(质检单可以引入的)
              if (this.billsClassify == 0 || this.billsClassify == 1) { //出库入库(引入退货单,借用单)
                this.conditionObj.depot_is_submit = "4"; //已审核的出入库单
                this.conditionObj.depot_classify = "9,11"; //退货单
                this.conditionObj.depot_depot_state = "0,1,3"; //未出入库和出入库中,待出入库
              } else if (this.billsClassify == 10) { //质检单(引入出库单,入库单)
                this.conditionObj.depot_classify = "0,1"; //引入入库单,出库单
              }

              // 判断引入的单据类型需要特殊处理的
              if (this.bills_type_data.code == 'DOC-M-015') { //生产成品入库单
                this.conditionObj.mes_is_submit = "4";//已审核的计划单
                this.conditionObj.mes_in_depot_state = "0,1,3"; //只能引入未入库和入库中,待入库的生产计划单
              } else if (this.bills_type_data.code == 'DOC-M-014' || this.bills_type_data.code ==
                'DOC-M-011') { //生产领料出库单/生产退料入库单
                // this.showSignArr = [7, 1, 2]; //生产领料出库单才可引入生产计划单,生产工序单,领料出库单
                this.showSignArr = [1, 7, 2]; //生产领料出库单才可引入生产计划单,生产工序单,领料出库单
                //生产单相关
                this.conditionObj.mes_is_submit = "4"; //已审核
                this.conditionObj.mes_material_state = "0,1,3"; //未领料和领料中,待领料
                //生产执行单条件
                this.conditionObj.mes_process_is_submit = "4"; //已审核
                this.conditionObj.material_state = "0,1,3"; //生产执行单领料状态(只显示未领料和领料中,待领料)
                // this.conditionObj.is_merge = 0; //只显示未被合并的
                // this.conditionObj.process_type = 1; //只显示工序主表
                //领料出库单相关
                this.conditionObj.depot_classify = "1"; //出库单
                this.conditionObj.depot_is_submit = 4; //已审核
                this.conditionObj.depot_bills_type = this.commonJsExtend.getBillsDataByType(2, 'DOC-M-014', this
                  .billTemplateData).id; //生产领料出库单
              }
            } else if (this.billsTypeSign == 5) { //收付款模块
              this.showSignArr = [9] //对账单据
              //对账单条件
              if (this.billsClassify == 0) { //收款单
                this.conditionObj.check_classify = 5; //销售对账单据
              } else if (this.billsClassify == 1) { //付款单
                this.conditionObj.check_classify = 6; //采购对账单据
              }
              //设置操作类型
              this.conditionObj.check_operate_type = 0; //设置为收付款
            } else if (this.billsTypeSign == 8) { //发票
              this.showSignArr = [9] //对账单据
              //对账单条件
              if (this.billsClassify == 3) { //销售发票
                this.conditionObj.check_classify = 5; //销售对账单据
              } else if (this.billsClassify == 4) { //采购发票
                this.conditionObj.check_classify = 6; //采购对账单据
              }
              //设置操作类型
              this.conditionObj.check_operate_type = 1; //设置为发票
              //设置发票状态
              this.conditionObj.check_receipt_state = "0,1,4"; //开票中,未开票.待开票
            } else if (this.billsTypeSign == 9) { //对账单模块
              if (this.billsClassify == 5) { //客户对账单
                this.showSignArr = [2] //出入库单据
                //出入库单据条件
                this.conditionObj.depot_classify = 1; //出库单
                this.conditionObj.depot_is_submit = "4"; //已审核的
                this.conditionObj.depot_is_originbill = "1"; //有源单的
                this.conditionObj.depot_origin_sale_pay_state = "0,1,2"; //源单未收款或收款中的
                //设置不可选单据id数组
                this.disableBillIdArr = this.depot_sel_id_arr;
                //设置已选单据id数组
                this.checkedBillIdArr = this.nodeSysFormValId.relevance_bills.split(",");
              } else if (this.billsClassify == 6) { //供应商对账单
                this.showSignArr = [2] //出入库单据
                //出入库单据条件
                this.conditionObj.depot_classify = 0; //入库单
                this.conditionObj.depot_is_submit = "4"; //已审核的采购单
                this.conditionObj.depot_is_originbill = "1"; //有源单的
                this.conditionObj.depot_origin_purchase_pay_state = "0,1,2"; //源单未付款或付款中的
                //设置不可选单据id数组
                this.disableBillIdArr = this.depot_sel_id_arr;
                //设置已选单据id数组
                this.checkedBillIdArr = this.nodeSysFormValId.relevance_bills.split(",");
              } else if (this.billsClassify == 7) { //生产对账单
                this.showSignArr = [1, 7] //生产计划,生产执行单据
                //生产计划条件
                this.conditionObj.mes_is_submit = 1; //已审核的计划单
                //生产执行条件
                this.conditionObj.mes_process_is_submit = 1; //已审核的执行单
              }
            }
            this.show_billsComponent = true;
            this.SHOW_BILLINGBOX(true);
          }
          //供应商选择弹框
          else if (systemData.type_id == 3) {
            // 判断登录类型
            if (this.loginType == 0) { //用户登录下才可以选择
              this.show_supplierComponent = true;
              this.SHOW_SUPPLIERBOX(true);
            }
          }
          //部门选择弹框
          else if (systemData.type_id == 4) {
            this.show_departmentComponent = true;
            this.SHOW_DEPARTMENTBOX(true);
          }
          //员工选择弹框
          else if (systemData.type_id == 5) {
            this.show_personnelComponent = true;
            this.SHOW_PERSONNELBOX(true);
          }
          //产品选择弹框
          else if (systemData.type_id == 6) {
            //判断不同模块下显示不同产品数据
            if (this.billsTypeSign == 1) { //生产计划
              this.visibleFinished = 1; //只显示成品
            } else if (this.billsTypeSign == 2) { //出入库模块下控制业务
              if (this.billsClassify == 3) { //组装单(只显示可组装产品)
                this.groupCondition = 1;
              } else if (this.billsClassify == 4) { //拆卸单(只显示可拆卸产品)
                this.disassemblyCondition = 1;
              }
            }
            this.isMultiple = 0; //设置单选
            this.show_productComponent = true;
            this.SHOW_PRODUCTBOX(true);
          }
          //费用分类选择弹框
          else if (systemData.type_id == 7) {
            //判断费用分类查询条件是否为收付款单
            if (this.billsTypeSign == 5) { //收付款单
              this.costTypeConditionObj.isCostMain = 1;
            } else if (this.billsTypeSign == 6) { //报销
              this.costTypeConditionObj.billsTypeId = this.bills_type_data.id;
            }
            //显示分类弹框
            this.show_costTypeComponent = true;
            this.SHOW_COSTTYPEBOX(true);
          }
        }

        //复选框展现形式
        if (systemData.type == 5) { //复选框
          // console.log(this.nodeSysFormValId);
        }

        // 小数框展现形式
        if (systemData.type == 6) { //小数

        }

        // 百分比展现形式
        if (systemData.type == 7) { //小数

        }
      },

      /* 判断是否显示是否含税提示信息 */
      judgeShowIsRateBox(filedName) {
        let result = false;
        if (filedName == "is_rate") {
          result = true;
        }
        return result;
      },

      /* 获取当前操作的数据(修改时使用的旧数据)  type(0:普通类型修改  1:复选框类型修改) fileName(对应字段名)*/
      getCurOperateData(data, type, fileName) {
        //获取编辑前的数据
        this.nodeSysFormOldVal = JSON.parse(JSON.stringify(this.nodeSysFormVal)); //名称格式信息
        this.nodeSysFormOldIdVal = JSON.parse(JSON.stringify(this.nodeSysFormValId)); //id格式信息
        //获取当前操作的基本信息数据
        this.curNodeBaseData = JSON.parse(JSON.stringify(data));
        //判断修改类型
        if (type == 1) { //复选框类型修改
          if (this.nodeSysFormOldVal[fileName] == "是") {
            this.nodeSysFormOldVal[fileName] = "否";
            this.nodeSysFormOldIdVal = 0;
          } else if (this.nodeSysFormOldVal[fileName] == "否") {
            this.nodeSysFormOldVal[fileName] = "是";
            this.nodeSysFormOldIdVal = 1;
          }
        }
      },

      /* 判断自定义信息操作类型 */
      judgeRelatedFiledData(data, index) {
        //获取修改前的数据
        this.nodeCusFormOldVal = data;
      },

      /* 获取下拉框选中值 */
      getSelectVal(formKey, data, nodeBaseData) {
        //获取下拉框值存入
        this.nodeSysFormVal[formKey] = data.name;
        //获取下拉框id存入
        this.nodeSysFormValId[formKey] = data.id;
        //将需要存入vuex的数据存入
        if (data.hasOwnProperty('saveSign')) {
          this.BILLS_TYPE_DATA(data);
        }
        //触发修改方法
        this.judgeChangeData();
      },

      /* 关闭其他下拉框(下拉框互斥功能) type(0:互斥功能 1:关闭所有)*/
      closeOtherMenu(dataIndex, type) {
        //判断处理方式
        if (type == 0) { //互斥
          // 循环判断当前显示哪个下拉框
          this.nodeSysFormData.forEach((item, index) => {
            if (dataIndex == index) {
              this.nodeSysFormData[dataIndex].show_selectData = !this.nodeSysFormData[dataIndex].show_selectData;
            } else {
              this.nodeSysFormData[index].show_selectData = false;
            }
          })
        } else if (type == 1) { //关闭所有
          this.nodeSysFormData.forEach((item, index) => {
            item.show_selectData = false;
          })
          this.$forceUpdate();
        }
      },

      /* 添加单据弹框监听事件 */
      addDialogListener() {
        var _this = this;
        //获取单据公共弹框dom
        let billsDialog = document.getElementById("nodeBills");
        //获取基本信息结构dom
        let nodeBaseFormBox = document.getElementById("nodeBaseFormBox");
        //添加事件监听
        billsDialog.addEventListener('click', function(e) {
          //判断是否在基本信息框内点击
          if (nodeBaseFormBox.contains(e.target)) {
            // //获取表单li的集合
            // let baseFormItemLi = document.getElementsByClassName("baseFormItemLi");
            // let aaa = baseFormItemLi[0]
            // console.log(baseFormItemLi.contains(e));
            // // if(baseFormItemLi.contains(e)){}
            // let isContains = false;
            // // baseFormItemLi.forEach((item, index) => {
            // //   console.log(item);
            // //   if (item.contains(e)) {
            // //     isContains = true;
            // //   }
            // // })
            // //判断是否包含
            // // if (!isContains) { //不包含
            // //   _this.nodeSysFormData.forEach((item, index) => {
            // //     item.show_selectData = false;
            // //   })
            // // }
          } else {
            _this.nodeSysFormData.forEach((item, index) => {
              item.show_selectData = false;
            })
          }
          _this.$forceUpdate();
        })
      },

      /* 判断是否显示收支标识type(0:收 1:支 2:无) */
      judgeShowOutInSign(type, filedName, data) {
        let result = false;
        //判断需要显示收支标识的字段
        //单据类型
        if (filedName == "billing_type_id") {
          // 判断标识
          if (type == data.outin_sign) {
            result = true;
          } else {
            result = false;
          }
        }
        return result
      },

      /* 判断下拉框选项数据是否显示 */
      isShowOption(fileName, data) {
        //判断是否启用
        if (data.is_enable == 0) { //未启用
          return false;
        } else if (data.is_enable == 1) { //启用
          // 判断哪种下拉框数据需要进行判断
          if (fileName == "billing_type_id") { //单据类型
            if (data.type_classify == this.billsClassify) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      },

      /* 获取复选框改变后的数据 */
      changeCheckBox(fileName, data) {
        if (this.nodeSysFormValId[fileName] == 1) {
          this.nodeSysFormVal[fileName] = '否';
          this.nodeSysFormValId[fileName] = 0;
        } else {
          this.nodeSysFormVal[fileName] = '是';
          this.nodeSysFormValId[fileName] = 1;
        }

        //触发获取修改前数据方法
        this.getCurOperateData(data, 1, fileName);

        //触发修改方法
        this.judgeChangeData();
      },

      /* 获取客户选择弹框数据*/
      getCustomerData(data) {
        //获取选择的id数据
        this.nodeSysFormValId[this.currentDialogFileName] = data.customer_id;
        //获取选择的数据
        this.nodeSysFormVal[this.currentDialogFileName] = data.customer_name;
        //将所选客户存入vuex
        this.CUSTOMER_DATA(data);
        // 关闭加载客户组件
        this.show_coustomerComponent = false;
        //触发修改方法
        this.judgeChangeData();
        //强制刷新数据($set不能解决,暂用这种方式)
        this.$forceUpdate();
        //重新加载客户信息
        this.handlerCustomerChange();
      },

      /* 获取仓库弹框选择数据 */
      getWarehouseData(data) {
        //获取选择的id数据
        this.nodeSysFormValId[this.currentDialogFileName] = data.id;
        //获取选择的数据
        this.nodeSysFormVal[this.currentDialogFileName] = data.name;
        // 关闭加载仓库组件
        this.show_warehouseComponent = false;
        //触发修改方法
        this.judgeChangeData();
        //强制刷新数据($set不能解决,暂用这种方式)
        this.$forceUpdate();
      },

      /* 获取供应商弹框选择数据*/
      getSupplierData(data) {
        //获取选择的id数据
        this.nodeSysFormValId[this.currentDialogFileName] = data.id;
        //获取选择的数据
        this.nodeSysFormVal[this.currentDialogFileName] = data.fullName;
        //将供应商存入vuex
        this.SUPPLIER_DATA(data);
        // 关闭加载供应商组件
        this.show_supplierComponent = false;
        //触发修改方法
        this.judgeChangeData(data);
        //强制刷新数据($set不能解决,暂用这种方式)
        this.$forceUpdate();
        //重新加载供应商信息
        this.handlerSupplierChange();
      },

      /* 获取部门弹框选择数据*/
      getDepartmentData(data) {
        let result = this.commonJsExtend.getDeptMsg(data);
        //获取选择的id数据
        this.nodeSysFormValId[this.currentDialogFileName] = result.idStr;
        //获取选择的数据
        this.nodeSysFormVal[this.currentDialogFileName] = result.nameStr;
        // 关闭加载部门组件
        this.show_departmentComponent = false;
        //触发修改方法
        this.judgeChangeData();
        //强制刷新数据($set不能解决,暂用这种方式)
        this.$forceUpdate();
      },

      /* 获取员工弹框选择数据*/
      getPersonnelData(data) {
        let result = this.commonJsExtend.getPersonnelMsg(data);
        //获取选择的id数据
        this.nodeSysFormValId[this.currentDialogFileName] = result.idStr;
        //获取选择的数据
        this.nodeSysFormVal[this.currentDialogFileName] = result.nameStr;
        // 关闭加载员工组件
        this.show_personnelComponent = false;
        //触发修改方法
        this.judgeChangeData();
        //强制刷新数据($set不能解决,暂用这种方式)
        this.$forceUpdate();
      },

      /* 获取产品弹框选择数据*/
      getProductData(data) {
        //获取选择的id数据
        this.nodeSysFormValId[this.currentDialogFileName] = data[0].id;
        //获取选择的数据
        this.nodeSysFormVal[this.currentDialogFileName] = data[0].name;
        //将产品信息存入data
        this.PRODUCT_DATA(data);
        // 关闭加载产品组件
        this.show_productComponent = false;
        //触发修改方法
        this.judgeChangeData();
        //强制刷新数据($set不能解决,暂用这种方式)
        this.$forceUpdate();
      },

      /* 获取单据弹框选择数据 */
      getBillsData(data, sign) {
        //将当前操作的sign存入vuex
        this.BILLS_RELVENCE_SIGN(sign);

        //判断是否需要引入源单数据
        let isGetOriginData = false;
        //判断满足条件的单据
        if(this.billsTypeSign == 1){//生产计划
          isGetOriginData = true;
        }if (this.billsTypeSign == 2 && this.billsClassify == 12) { //出入库下的补货单(补货单时不加载货品信息,自己选)
          isGetOriginData = true;
        } else if (this.billsTypeSign == 9) { //对账单
          isGetOriginData = true;
        }

        // 判断哪种单据
        if (sign == 0) { //销售单据
          //获取选择的id数据
          this.nodeSysFormValId[this.currentDialogFileName] = data[0].saleOrderMain.id;
          //获取选择的数据
          this.nodeSysFormVal[this.currentDialogFileName] = data[0].saleOrderMain.billsNo;

          //判断是否为生产计划下
          if(this.billsTypeSign == 1){//生产计划
            let prodata = {
              productId:data[0].salesProductInfoList[0].productId,//货品id
            }
            //加载loading框
            this.loading = this.commonJsExtend.customLoading("#nodeSysTableBox", 4, '单据货品信息获取中,请稍候...');
            proApi.findProductByProductId(prodata).then(res => {
              //关闭loading框
              this.loading.close();
              if (res.code == 200) {
                //转换成数组
                let data1 = [];
                data1.push(res.data);

                //设置当前操作字段未货品
                this.currentDialogFileName = "product_info_id";

                //调用获取货品信息方法
                this.getProductData(data1);
                return
              }else{
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }

          //判断是否需要获取源单信息
          if (isGetOriginData) {
            // 关闭加载单据组件
            this.show_billsComponent = false;
            return
          }
          //根据所选单据id获取单据详情
          let sellData = {
            saleOrderMainId: data[0].saleOrderMain.id
          }
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeSysTableBox", 4, '单据信息获取中,请稍候...');
          //获取单据详情
          sellApi.findSalesOrderMainDetail(sellData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //将数据存入vuex
              this.BILLS_SEL_DETAIL(res.data);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (sign == 1) { //生产单据
          //获取选择的id数据
          this.nodeSysFormValId[this.currentDialogFileName] = data[0].id;
          //获取选择的数据
          this.nodeSysFormVal[this.currentDialogFileName] = data[0].bills_no;
          //判断是否需要获取源单信息
          if (isGetOriginData) {
            // 关闭加载单据组件
            this.show_billsComponent = false;
            return
          }
          //根据所选单据id获取单据详情
          let mesData = {
            id: data[0].id
          }
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeSysTableBox", 4, '单据信息获取中,请稍候...');
          //获取单据详情
          productApi.findMesMainDetail(mesData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //将数据存入vuex
              this.BILLS_SEL_DETAIL(res.data);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (sign == 2) { //出入库单据
          //判断当前模块
          if (this.billsTypeSign == 9) { //对账单模块
            //获取选择的id数据
            this.nodeSysFormValId[this.currentDialogFileName] = data[0].depotMain.id + "";
            //获取选择的数据
            this.nodeSysFormVal[this.currentDialogFileName] = data[0].depotMain.billsNo + "";
          } else { //其它模块
            //获取选择的id数据
            this.nodeSysFormValId[this.currentDialogFileName] = data[0].depotMain.id;
            //获取选择的数据
            this.nodeSysFormVal[this.currentDialogFileName] = data[0].depotMain.billsNo;
          }
          //判断是否需要获取源单信息
          if (isGetOriginData) {
            // 关闭加载单据组件
            this.show_billsComponent = false;
            return
          }
          //根据所选单据id获取单据详情
          let depotData = {
            depotId: data[0].depotMain.id
          }
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeSysTableBox", 4, '单据信息获取中,请稍候...');
          //获取单据详情
          depotApi.findDepotMainDetail(depotData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //将数据存入vuex
              this.BILLS_SEL_DETAIL(res.data);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (sign == 3) { //采购单据
          //获取选择的id数据
          this.nodeSysFormValId[this.currentDialogFileName] = data[0].purchaseOrderMain.id;
          //获取选择的数据
          this.nodeSysFormVal[this.currentDialogFileName] = data[0].purchaseOrderMain.billsNo;
          //判断是否需要获取源单信息
          if (isGetOriginData) {
            // 关闭加载单据组件
            this.show_billsComponent = false;
            return
          }
          //根据所选单据id获取单据详情
          let purchaseData = {
            purchaseMainId: data[0].purchaseOrderMain.id
          }
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeSysTableBox", 4, '单据信息获取中,请稍候...');
          //获取单据详情
          purchaseApi.findPurchaseMainDetail(purchaseData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //将数据存入vuex
              this.BILLS_SEL_DETAIL(res.data);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (sign == 7) { //生产执行单据
          let idArr = [];
          let billsArr = [];
          data.forEach((item, index) => {
            idArr.push(item.id);
            billsArr.push(item.billsNo);
          })
          //获取选择的id数据
          this.nodeSysFormValId[this.currentDialogFileName] = idArr.join(",");
          //获取选择的数据
          this.nodeSysFormVal[this.currentDialogFileName] = billsArr.join(",");
          //判断是否需要获取源单信息
          if (isGetOriginData) {
            // 关闭加载单据组件
            this.show_billsComponent = false;
            return
          }
          //定义传入后端的数据
          let processData = {
            ids: idArr.join(","),
          }
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeSysTableBox", 4, '单据信息获取中,请稍候...');
          productApi.findMesProcessByIds(processData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //将数据存入vuex
              this.BILLS_SEL_DETAIL(res.data);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (sign == 9) { //对账单据
          //获取选择的id数据
          this.nodeSysFormValId[this.currentDialogFileName] = data[0].id;
          //获取选择的数据
          this.nodeSysFormVal[this.currentDialogFileName] = data[0].billsNo;
          //判断是否需要获取源单信息
          if (isGetOriginData) {
            // 关闭加载单据组件
            this.show_billsComponent = false;
            return
          }
          //定义传入后端的数据
          let checkData = {
            costCheckId: data[0].id,
          }
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeSysTableBox", 4, '单据信息获取中,请稍候...');
          costApi.findCostCheckBillsDetail(checkData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //将数据存入vuex
              this.BILLS_SEL_DETAIL(res.data);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
          //触发加载对账单明细
          // this.IS_LOAD_CHECKBILL_DATA(!this.is_load_checkbill_data);
        }
        // 关闭加载单据组件
        this.show_billsComponent = false;
        //触发修改方法
        this.judgeChangeData();
        //强制刷新数据($set不能解决,暂用这种方式)
        this.$forceUpdate();
      },

      /* 获取费用弹框选择数据 */
      getCostTypeData(data) {
        if (data.length > 1) {
          this.$message({
            type: 'warning',
            message: '只能选择一个费用',
            duration: this.elDuration
          })
          return
        }
        //获取选择的id数据
        this.nodeSysFormValId[this.currentDialogFileName] = data[0].id;
        //获取选择的数据
        this.nodeSysFormVal[this.currentDialogFileName] = data[0].name;
        //将费用分类信息存入
        this.COST_TYPE(data);
        // 关闭加载费用分类组件
        this.show_costTypeComponent = false;
        //触发修改方法
        this.judgeChangeData();
        //强制刷新数据($set不能解决,暂用这种方式)
        this.$forceUpdate();
      },

      /* 获取日期选择弹框数据*/
      getSelectData(date, filed) {
        //将选择后的日期格式进行转换,不转换存的是国际标准时间
        this.nodeSysFormVal[filed] = this.commonJsExtend.getDateTime(date, 0);
        //强制刷新数据($set不能解决,暂用这种方式)
        this.$forceUpdate();
      },

      /* 触发立即加载单据方法 type(0:手动  1:预览)*/
      loadConditionBills(type) {
        //触发数据加载方法
        this.IS_LOAD_CONDITION_BILLS(!this.is_load_condition_bills);
        //关闭明细查询
        if (type == 0) {
          this.$emit("closeCostCheckCheckbox");
        }
      },

      /* 初始化当前组件数据 */
      initNodeFormMsg() {
        //初始化基本信息数据
        this.initBaseForm(this.baseFormData);
        //初始化自定义信息
        this.initCusBaseForm(this.baseCusFormData)
        //初始化其他信息
        this.nodeMustFileName = []; //清空必填数组
        this.nodeLengthAstrict = []; //清空字符长度验证
        //可编辑
        // this.is_edit = true;

        // ****************清空单据查询条件****************
        //清空供应商条件
        this.conditionObj.supplierId = -1;
        //清空客户条件
        this.conditionObj.customer_id = -1;
      },

      /* 判断企业设置的单据相关权限 */
      judgeBillsCommitPower() {
        let result = true;
        //判断模块
        if (this.billsTypeSign == 2) { //库存模块
          //判断分类
          if (this.billsClassify == 0) { //入库单
            //判断入库单是否必须引入订单
            if (this.companyparamlist.param283 == "0") {
              //判断是否仅指定采购入库单
              if (this.companyparamlist.param344 == "0") {
                //判断单据类型
                if (this.bills_type_data.code == 'DOC-P-003') { //采购入库单
                  if (this.nodeSysFormValId.hasOwnProperty('relevance_bills') && this.nodeSysFormValId
                    .relevance_bills == "") {
                    this.$message({
                      type: 'warning',
                      message: '管理员已设置采购入库单必须引入单据,如需修改,请联系管理员!',
                      duration: this.elDuration
                    })
                    result = false;
                  }
                }
              } else {
                if (this.nodeSysFormValId.relevance_bills == "") {
                  this.$message({
                    type: 'warning',
                    message: '管理员已设置入库单必须引入单据,如需修改,请联系管理员!',
                    duration: this.elDuration
                  })
                  result = false;
                } else {
                  result = true;
                }
              }
            }
          } else if (this.billsClassify == 1) { //出库单
            //判断出库单是否必须引入订单
            if (this.companyparamlist.param357 == "0") {
              //判断是否仅指定销售出库单
              if (this.companyparamlist.param358 == "0") {
                //判断单据类型
                if (this.bills_type_data.code == 'DOC-S-007') { //销售出库单
                  if (this.nodeSysFormValId.hasOwnProperty('relevance_bills') && this.nodeSysFormValId
                    .relevance_bills == "") {
                    this.$message({
                      type: 'warning',
                      message: '管理员已设置销售出库单必须引入单据,如需修改,请联系管理员!',
                      duration: this.elDuration
                    })
                    result = false;
                  }
                }
              } else {
                if (this.nodeSysFormValId.relevance_bills == "") {
                  this.$message({
                    type: 'warning',
                    message: '管理员已设置出库单必须引入单据,如需修改,请联系管理员!',
                    duration: this.elDuration
                  })
                  result = false;
                } else {
                  result = true;
                }
              }
            }
          }
        }
        return result;
      },

      /* 判断当前系统级别必填业务字段 */
      judgeSystemMustFiled() {
        let result = true;
        if (this.nodeSysFormValId.hasOwnProperty("is_rate") && this.nodeSysFormValId.is_rate == 0) {
          if (this.nodeSysFormValId.hasOwnProperty("tax_rage")) {
            if (!this.nodeSysFormValId.tax_rage) {
              this.$message({
                type: 'warning',
                message: '当价格是否含税未勾选时,税率为必填项!',
                duration: 3000
              })
              result = false;
            }
          }
        }
        return result;
      },

      /* 判断输入的内容数据长度是否合法 */
      judgeContentLengthAstrict() {
        let result = true;
        for (let i in this.nodeSysFormVal) {
          if (this.nodeLengthAstrict.includes(i)) {
            if (!!this.nodeSysFormVal[i] && this.nodeSysFormVal[i].toString().trim().length > 150) {
              this.$message({
                type: 'warning',
                message: '基本信息存在字符内容过长信息,只能输入0~150个字符,请确认!',
                duration: this.elDuration
              })
              result = false;
              return;
            }
          }
        }
        return result;
      },

      /* 取消弹框触发 */
      cancleDialogOperate() {
        this.show_coustomerComponent = false; //控制加载客户组件
        this.show_warehouseComponent = false; //控制加载仓库组件
        this.show_supplierComponent = false; //控制加载供应商组件
        this.show_departmentComponent = false; //控制加载部门组件
        this.show_personnelComponent = false; //控制加载员工选择组件
        this.show_productComponent = false; //控制加载货品选择组件
        this.show_billsComponent = false; //控制加载单据选择弹框组件
        this.show_costTypeComponent = false; //控制加载费用分类选择弹框组件
      },

      /* 提交表单填写信息 */
      commitNodeFormMsg() {
        // 获取必填信息长度
        let len = this.nodeMustFileName.length;
        // for循环前需判断被循环数组长度大于0
        if (this.nodeMustFileName.length > 0) {
          // 循环判断是否有必填信息未填
          for (let i = 0; i < len; i++) {
            let itemVal = this.nodeSysFormVal[this.nodeMustFileName[i].system.filed_name];
            // 判断处理特殊字段
            if (this.nodeMustFileName[i].system.filed_name == "is_rate") { //价格是否含税
              if (itemVal == "请选择") {
                this.$message({
                  type: 'warning',
                  message: "【" + this.nodeMustFileName[i].show_name + "】字段不能为空!",
                  duration: this.elDuration
                })
                return;
              }
            } else {
              if (!itemVal) {
                this.$message({
                  type: 'warning',
                  message: "【" + this.nodeMustFileName[i].show_name + "】字段不能为空!",
                  duration: this.elDuration
                })
                return;
              }
            }
          }
        }

        //获取id格式所填数据
        // 判断是否有下拉或弹框格式数据
        if (JSON.stringify(this.nodeSysFormValId) != "{}") { //有下拉框,弹框类型
          // 循环获取id对象值
          for (let i in this.nodeSysFormVal) {
            for (let j in this.nodeSysFormValId) {
              if (!this.nodeSysFormValId.hasOwnProperty(i)) {
                this.nodeSysFormValId[i] = this.nodeSysFormVal[i]
              }
            }
          }
        } else { //没有下拉框,弹框类型
          for (let i in this.nodeSysFormVal) {
            this.nodeSysFormValId[i] = this.nodeSysFormVal[i];
          }
        }

        //判断系统业务数据必填
        if (!this.judgeSystemMustFiled()) return;

        //判断企业设置的单据相关权限
        if (!this.judgeBillsCommitPower()) return;

        //判断内容长度是否合法
        if (!this.judgeContentLengthAstrict()) return;

        //获取id格式所有数据(name格式有,id格式没有的,如输入框,数量框等未预设的数据)
        // for (let i in this.nodeSysFormValId) {
        //   if (this.nodeHandFullFileName.includes(i)) {
        //     this.nodeSysFormValId[i] = this.nodeSysFormVal[i];
        //   }
        // }
        // debugger

        //获取自定义信息
        let cusData = []
        if (this.nodeCusFormVal.length > 0) {
          this.nodeCusFormVal.forEach(item => {
            cusData.push({
              custom_form_id: item.custom_form_id, //左值id
              custom_form_value: item.custom_form_value, //填写值
            })
          })
        }

        // 判断工时新是否已填(只有员工填写时需要)
        if (this.baseNodeInfo.working_type == 0) {
          if (this.nodeHours.trim() == "") {
            this.$message({
              type: 'warning',
              message: '请填写工时时长',
              duration: this.elDuration
            })
            return
          }
        }
        //获取工时信息
        let noodeHourse = {
          working_type: this.baseNodeInfo.working_type, //工时类型(工时类型,0:员工填写,1:公司规定,2:系统计算)
          working_hours_type: this.baseNodeInfo.working_hours_type, //工时时长单位,1:天数,2:小时
          working_hours: this.nodeHours, //工时时长
        }

        // 定义传入父组件的值
        let data = {
          nodeFormNameData: this.nodeSysFormVal, //名称形式
          nodeFormIdData: this.nodeSysFormValId, //id形式
          nodeCusFormData: cusData, //自定义信息
          nodeHourse: noodeHourse, //工时时长
        }
        // 发送到父组件
        this.$emit("getNodeFormData", data);
      }
    },
    components: {
      customerList,
      // productList,
      warehouseList,
      billingList,
      supplierList,
      departmentTree,
      personnelList,
      costTypeTree,
      productList: () => import('@/components/commComponent/list/list_product')
    }
  }
</script>

<style lang="less">
  // 对账单表单操作框
  .checkFormBox {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: @theme;

    // border: 1px solid black;
    // 立即加载按钮
    .loadbtn {
      cursor: pointer;
    }
  }
</style>
