<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：单据物流信息组件
	开始时间：2021-04-27
	开发人员：刘巍骏
	最后修改：2021-04-27
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="nodeFormItemBox">
    <ul class="form_ul">
      <li>
        <div class="form_title">
          物流单号:
        </div>
        <div class="form_date">
          <input type="text" v-model="logisticsData.logisticsNum" @focus="getCurOperateData" :disabled="!is_edit"
            @change="getChangeData('logisticsNum',1)" placeholder="选择...">
        </div>
      </li>
      <li>
        <div class="form_title">
          物流日期:
        </div>
        <div class="form_date" @click="showPullMenu('show_logisticsDate')">
          <input type="text" v-model="logisticsData.logisticsDate.name" disabled="disabled" placeholder="选择...">
          <span>
            <i class="el-icon-arrow-down"></i>
          </span>
          <ul class="pulldown_menu" v-show="show_pullMenu.show_logisticsDate">
            <li v-for="(item,index) in logisticsDate" :key="index" @click="getSelLogisticsData(2,item)">{{item.name}}
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div class="form_title">
          物流方式:
        </div>
        <div class="form_date" @click="showPullMenu('show_logisticsWay')">
          <input type="text" v-model="logisticsData.logisticsWayData.name" disabled="disabled" placeholder="选择...">
          <span>
            <i class="el-icon-arrow-down"></i>
          </span>
          <ul class="pulldown_menu" v-show="show_pullMenu.show_logisticsWay">
            <li v-for="(item,index) in LogisticsMode" :key="index" @click="getSelLogisticsData(0,item)">{{item.name}}
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div class="form_title">
          物流公司:
        </div>
        <div class="form_date" @click="showPullMenu('show_logisticsCompany')">
          <input type="text" v-model="logisticsData.logisticsCompanyData.name" disabled="disabled" placeholder="选择...">
          <span>
            <i class="el-icon-arrow-down"></i>
          </span>
          <ul class="pulldown_menu" v-show="show_pullMenu.show_logisticsCompany">
            <li v-for="(item,index) in LogisticsCompany" :key="index" @click="getSelLogisticsData(1,item)">{{item.name}}
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div class="form_title">
          物流费用:
        </div>
        <div class="form_date">
          <input type="text" @focus="getCurOperateData" @change="getChangeData('logisticsAmount',1)" :disabled="!is_edit"
            v-model="logisticsData.logisticsAmount" placeholder="选择...">
        </div>
      </li>
      <!-- <li>
        <div class="form_title">
          发货仓库:
        </div>
        <div class="form_date" @click="SHOW_WAREHOUSEBOX(true)">
          <input type="text" v-model="logisticsData.logisticsOutWarehouse.name" disabled="disabled" placeholder="选择...">
          <span>
            <i class="el-icon-more"></i>
          </span>
        </div>
      </li> -->
    </ul>

    <!-- 仓库选择组件 -->
    <warehouseList @SelectedData="getWarehouseData"></warehouseList>
  </div>
</template>

<script>
  // 导入api
  import sellApi from '@/network/sell/bills.js'; //销售模块(包含公共接口)

  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入组件
  import warehouseList from '@/components/commComponent/list/list_warehouse'; //仓库列表组件

  export default {
    name: 'nodeform_logistics',
    props: {
      // 节点基本信息
      baseNodeInfo: {
        type: Object,
        default () {
          return {}
        }
      },
      //是否提交当前填写的表单信息
      isCommitFormData: {
        type: Boolean,
        default: true
      },
      //是否清空当前已填写表单数据
      isClearFormData: {
        type: Boolean,
        default: true
      },
      //是否触发保存单据新增的相关信息
      saveNewBillsData: {
        type: Boolean,
        default: false
      },
      // 操作类型(0:新增 1:修改  2:查看)
      operateType: {
        type: Number,
        default: -1
      },
      //数据校验步骤(根据步骤校验数据合法性给出提示信息)
      commitFormStep:{
        type: Number,
        default: -1
      },
      //回显单据数据
      originalBillData: {
        type: Object,
        default () {
          return {}
        }
      },
      //单据修改时关联信息的操作类型(0:新增 2:删除)
      operateReselveType:{
        type:Number,
        default:-1
      },
      //生成单据原id(-1:新增 其他:生成)
      generateBillsId: {
        type: Number,
        default: -1
      },
      //操作的是哪个关联信息
      deleteReserveType:{
        type: Number,
        default: -1
      },
      //节点分类标志(0:销售 1:生产 2:库存  3:采购)
      billsTypeSign: {
        type: Number,
        default: -1
      },
    },
    data() {
      return {
        // 物流信息数据
        logisticsData: {
          logisticsNum: '', //物流单号
          logisticsDate: {}, //物流日期
          logisticsWayData: {}, //物流方式数据
          logisticsCompanyData: {}, //物流公司数据
          logisticsAmount: '', //物流费用
          logisticsOutWarehouse: {}, //发货仓库
        },
        is_edit:true,//是否可编辑(预览模式下不可编辑)

        //修改时的数据
        logisticsOldData: {}, //修改之前的旧数据
        isEchoData:0,//是否有回显数据(删除再次添加没有)

        // 控制开关
        show_pullMenu: { //下拉菜单显示
          show_logisticsWay: false, //显示物流方式
          show_logisticsCompany: false, //显示物流公司
          show_logisticsDate: false, //显示物流日期
        }
      }
    },
    created() {
      //初始化物流信息数据
      this.initLogistics();
    },
    computed: {
      ...mapState({
        //仓储相关
        LogisticsCompany: state => state.system.LogisticsCompany, //物流公司数据
        LogisticsMode: state => state.system.LogisticsMode, //物流方式数据
        logisticsDate: state => state.system.logisticsDate, //物流日期数据
        bills_base_data: state => state.commComponent.bills_base_data, //表单填写的基本信息数据
      })
    },
    watch: {
      // 监听是否提交当前组件填写信息
      isCommitFormData(newVal) {
        this.commitLogisticsData();
        // if(this.commitFormStep == 3){
        //   this.commitLogisticsData();
        // }
      },
      //监听是否清空当前子组件数据
      isClearFormData() {
        //调用清除信息方法
        this.clearNodeFormMsg();
      },
      //监听是否提交新增的数据(保存新数据)(修改时用)
      saveNewBillsData(newVal){
        //调用修改数据方法(operate只是占位符,不起任何业务作用)
        this.getChangeData('operate',this.operateReselveType);
      },
    },
    methods: {
      ...mapMutations([
        'SHOW_WAREHOUSEBOX', //控制仓库是否显示
      ]),
      ...mapActions([
        'getAllCompany', //物流公司
        'getLogisticsMode', //物流方式
      ]),

      /* 初始化物流信息数据*/
      async initLogistics() {
        // 判断是否可编辑(预览下不可编辑)
        if(this.operateType == 2){
          this.is_edit = false;
        }
        // 判断物流公司是否存在数据
        if (this.LogisticsCompany.length == 0) {
          await this.getAllCompany();
        }
        // 判断是否需要默认设置物流公司
        if (this.bills_base_data.hasOwnProperty('logistics_company_id') && this.bills_base_data
          .logistics_company_id != "") {
          this.logisticsData.logisticsCompanyData = this.commonJsExtend.getDictMatchingData(this.bills_base_data
            .logistics_company_id, this.LogisticsCompany);
        }

        //判断物流方式是否存在数据
        if (this.LogisticsMode.length == 0) {
          await this.getLogisticsMode();
        }
        // 判断是否需要默认设置物流方式
        if (this.bills_base_data.hasOwnProperty('logistics_way_id') && this.bills_base_data.logistics_way_id != "") {
          this.logisticsData.logisticsWayData = this.commonJsExtend.getDictMatchingData(this.bills_base_data
            .logistics_way_id, this.LogisticsMode);
        }

        //设置默认物流日期
        this.logisticsData.logisticsDate = this.logisticsDate[0]

        //判断是否需要回显数据(修改和预览需要回显)
        if (this.operateType == 1 || this.operateType == 2) {
          //获取默认物流信息(回显时用)
          this.getDefaultLogisticsData(this.originalBillData);
        }
      },

      /* 获取默认物流信息数据(回显) */
      getDefaultLogisticsData(data) {
        // 判断当没有数据时不回显
        if(!data.orderTransport){
          return
        }
        //获取物流方式
        let resWayData = this.commonJsExtend.getDictMatchingData(data.orderTransport.logisticsWayId, this
          .LogisticsMode);
        //获取物流公司
        let resCompanyData = this.commonJsExtend.getDictMatchingData(data.orderTransport.logisticsCompanyId, this
          .LogisticsCompany);
        //获取物流日期
        let resDateData = this.commonJsExtend.getDictMatchingData(data.orderTransport.logisticsDate, this
          .logisticsDate);
        //获取默认数据
        this.logisticsData = {
          logisticsId: data.orderTransport.id, //物流id
          logisticsNum: data.orderTransport.logisticsNum, //物流单号
          logisticsDate: resDateData, //物流日期
          logisticsWayData: resWayData, //物流方式
          logisticsCompanyData: resCompanyData, //物流公司
          logisticsAmount: data.orderTransport.logisticsAmount, //物流费用
        }
        //触发一次获取旧数据方法
        this.getCurOperateData();
        //刷新界面数据显示
        this.$forceUpdate()
      },

      /* 获取当前操作的数据(修改时使用的旧数据) */
      getCurOperateData() {
        //存一份旧数据
        this.logisticsOldData = JSON.parse(JSON.stringify(this.logisticsData))
      },

      /* 处理数据修改后的业务 fileName:字段名,只在修改时起作用 type(0:新增 1:修改 2:删除)*/
      getChangeData(fileName, type) {
        console.log("物流信息操作类型:"+type);
        //判断是否为修改界面(其他界面不执行此方法)
        if (this.operateType != 1) {
          return
        }
        let logContent = "";//日志信息
        let updateData = {};//修改所需数据
        // 判断操作类型
        if (type == 0) { //添加
          // 判断数据是数据库的还是新增的(删除后再加的)(有id的就不新增)
          if(this.logisticsOldData.logisticsId){
            //设置本组件已处理新增
            this.$emit("getOperateLogisticsResult",1)
            return
          }
          //获取日志信息
          logContent = "新增了一条物流信息;"
          //获取修改信息
          updateData = {
            orderId:this.generateBillsId,//单据id
            sign:this.billsTypeSign,//单据标识
            statusId:this.baseNodeInfo.id,//节点id
            type: type, //操作类型
            logContent: logContent, //日志信息
            logisticsNum: this.logisticsData.logisticsNum, //物流单号
            logisticsCompanyId: this.logisticsData.logisticsCompanyData.id, //物流公司
            logisticsWayId: this.logisticsData.logisticsWayData.id, //物流方式
            logisticsAmount: this.logisticsData.logisticsAmount, //物流费用
            logisticsDate: this.logisticsData.logisticsDate.id, //物流日期
            remark: '',
          }
        } else if (type == 1) { //修改
          // 判断数据是数据库的还是新增的(删除后再加的)
          if(!this.logisticsOldData.logisticsId){
            return
          }
          //定义日志相关信息
          let oldVal = ""; //旧数据
          let newVal = ""; //新数据
          let showName = ""; //显示名称
          if (fileName == 'logisticsNum') {
            showName = "物流单号"
            oldVal = this.logisticsOldData[fileName];
            newVal = this.logisticsData[fileName];
          } else if (fileName == 'logisticsDate') {
            showName = "物流日期"
            oldVal = this.logisticsOldData[fileName].name;
            newVal = this.logisticsData[fileName].name;
          } else if (fileName == 'logisticsWayData') {
            showName = "物流方式"
            oldVal = this.logisticsOldData[fileName].name;
            newVal = this.logisticsData[fileName].name;
          } else if (fileName == 'logisticsCompanyData') {
            showName = "物流公司"
            oldVal = this.logisticsOldData[fileName].name;
            newVal = this.logisticsData[fileName].name;
          } else if (fileName == 'logisticsAmount') {
            showName = "物流费用"
            oldVal = this.logisticsOldData[fileName];
            newVal = this.logisticsData[fileName];
          }
          //获取日志信息
          logContent = "更改了【物流信息】中的【" + showName + "】,更改前:" + oldVal + ",更改后:" + newVal; //日志内容
          //获取修改信息
          updateData = {
            id: this.logisticsOldData.logisticsId, //被修改id
            type: type, //操作类型
            statusId:this.baseNodeInfo.id,//节点id
            logContent: logContent, //日志信息
            logisticsNum: this.logisticsData.logisticsNum, //物流单号
            logisticsCompanyId: this.logisticsData.logisticsCompanyData.id, //物流公司
            logisticsWayId: this.logisticsData.logisticsWayData.id, //物流方式
            logisticsAmount: this.logisticsData.logisticsAmount, //物流费用
            logisticsDate: this.logisticsData.logisticsDate.id, //物流日期
            remark: '',
          }
        } else if (type == 2) { //删除
          //判断当前是否控制的是物流信息
          if(this.deleteReserveType!=1){
            return
          }
          //定义日志内容
          logContent = "删除了一条物流信息;" //日志内容
          updateData = {
            id: this.logisticsOldData.logisticsId, //被修改id
            logContent: logContent, //日志信息
            statusId:46,//节点id
            type: type, //操作类型
          }
        }
        //发送请求
        sellApi.updateOrderTransport(updateData).then(res => {
          if (res.code == 200) {
            if(type == 0){//新增
              this.$emit("getOperateLogisticsResult",1)
            }else if(type == 1){//修改
              this.$message({
                type: 'success',
                message: '物流信息修改成功!',
                duration: this.elDuration
              })
            }else if(type == 2){//删除(删除后需清空物流数据)
              this.$emit("getOperateLogisticsResult",2)
            }
            //判断操作类型
            // if(type == 2){//删除成功后清除物流信息数据
            //   Object.assign({},this.$options.data().logisticsData)
            //   console.log(this.logisticsData);
            // }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 显示下拉菜单key:控制显示下拉菜单对象的key值 */
      showPullMenu(key) {
        // 判断当前操作模式
        if(this.operateType == 2){
          return
        }
        // 循环只显示当前点击的下拉框
        for (let i in this.show_pullMenu) {
          //当前传入的key值
          if (i == key) {
            this.show_pullMenu[i] = !this.show_pullMenu[i];
          } else {
            this.show_pullMenu[i] = false;
          }
        }

        //存一份旧数据
        this.getCurOperateData();
      },

      /* 获取下拉选中数据type(0:物流方式  1:物流公司) */
      getSelLogisticsData(type, data) {
        // 判断下拉框形式
        if (type == 0) { //物流方式
          this.logisticsData.logisticsWayData = data;
          //触发修改方法
          this.getChangeData('logisticsWayData',1)
        } else if (type == 1) { //物流公司
          this.logisticsData.logisticsCompanyData = data;
          //触发修改方法
          this.getChangeData('logisticsCompanyData',1)
        } else if (type == 2) { //物流日期
          this.logisticsData.logisticsDate = data;
          //触发修改方法
          this.getChangeData('logisticsDate',1)
        }
      },

      /* 获取仓库弹框选择数据 */
      getWarehouseData(data) {
        this.logisticsData.logisticsOutWarehouse = data;
      },

      /* 清除当前组件信息 */
      clearNodeFormMsg() {
        // 循环清空对象
        for (let i in this.logisticsData) {
          this.logisticsData[i] = "";
        }
      },

      /* 提交物流信息数据 */
      commitLogisticsData() {
        // 定义一个传入后端的对象
        let data = {
          logistics_date: this.logisticsData.logisticsDate.id, //物流日期id
          logistics_date_name: this.logisticsData.logisticsDate.name, //物流日期名称
          logistics_num: this.logisticsData.logisticsNum, //物流单号
          logistics_way_id: this.logisticsData.logisticsWayData.id, //物流方式id
          logistics_way_name: this.logisticsData.logisticsWayData.name, //物流方式名称
          logistics_company_id: this.logisticsData.logisticsCompanyData.id, //物流公司id
          logistics_company_name: this.logisticsData.logisticsCompanyData.name, //物流公司名称
          logistics_amount: this.logisticsData.logisticsAmount, //物流费用
        }
        this.$emit("getNodeFormData", data)
      },
    },
    components: {
      warehouseList
    }
  }
</script>

<style>
</style>
