<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：单据基本信息组件
	开始时间：2021-04-27
	开发人员：刘巍骏
	最后修改：2021-04-27
	备注说明：如需修改请联系开发人员
-->
<template>
  <div>
    <ul class="form_ul">
      <li>
        <div class="form_title">经办人:</div>
        <div class="form_date">
          <input type="text" v-model="operatorUser" :disabled="!is_edit" placeholder="选择...">
        </div>
      </li>
      <li>
        <div class="form_title">所属人:</div>
        <div class="form_date">
          <input type="text" v-model="affiliationUser" :disabled="!is_edit" placeholder="选择...">
        </div>
      </li>
      <li>
        <div class="form_title">审批人:</div>
        <div class="form_date">
          <input type="text" v-model="checkUser" :disabled="!is_edit" placeholder="选择...">
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  export default{
    name:'nodeform_auditor',
    props:{
      //是否提交当前填写的表单信息
      isCommitFormData: {
        type: Boolean,
        default: true
      },
      //当前节点基本信息
      nodeBaseInfo:{
        type:Object,
        default(){
          return {}
        }
      },
      // 操作类型(0:新增 1:修改  2:查看)
      operateType: {
        type: Number,
        default: -1
      },
      //回显单据原id(-1:新增 其他:生成)
      generateBillsId: {
        type: Number,
        default: -1
      },
      //回显单据数据
      originalBillData: {
        type: Object,
        default () {
          return {}
        }
      },
      //节点分类标志(0:销售 1:生产 2:库存  3:采购 4:bom 5:资金  5:报销  7:生产执行单  8:发票)
      billsTypeSign: {
        type: Number,
        default: -1
      },
    },
    data(){
      return{
        // 人员信息
        operatorUser:'',//经办人
        affiliationUser:'',//所属人
        checkUser:'',//审核人

        //控制开关
        is_edit:false,//是否可编辑
      }
    },
    computed:{
      ...mapState({
        UserInfo:state=>state.user.UserInfo,//当前登录用户信息
        customer_data: state => state.commComponent.customer_data,//单据所选客户信息
        bills_type_data: state => state.commComponent.bills_type_data, //单据类型数据
      }),
    },
    watch:{
      //监听是否提交当前组件所填信息
      isCommitFormData(newVal) {
        //调用提交信息方法
        this.commitNodeFormMsg();
      },
      //监听节点信息发生变化
      nodeBaseInfo(newVal){
        // 初始化审核人相关信息
        this.initAuditorData();
      },
    },
    methods:{
      /* 初始化当前组件相关信息 */
      initAuditorData(){
        // 判断当前操作界面
        if(this.operateType == 0 || this.operateType == 1){//新增界面或修改界面
          //获取经办人(当前登录用户)
          this.operatorUser = this.UserInfo.user_name;
          //获取所属人(客户所属人)
          this.affiliationUser = this.customer_data.user_name;
          //获取审核人
          this.checkUser = this.getAllCheckUser();
        }else if(this.operateType == 2){//预览界面
          //获取经办人(当前登录用户)
          this.operatorUser = this.UserInfo.user_name;
          //获取所属人(客户所属人)
          this.affiliationUser = this.customer_data.user_name;
          //获取审核人
          this.checkUser = this.getAllCheckUser();
        }
      },

      /* 获取单据相关人员信息 */
      getAllCheckUser(){
        let nodeCheckUser = [];//节点审核人数组
        let billsTypeCheckUser = [];//单据类型审核人数组
        //获取单据类型审核人数组
        if(this.bills_type_data.check_user_name){
          billsTypeCheckUser = this.bills_type_data.check_user_name.split(",");
        }
        //获取节点审核人数组
        if(this.nodeBaseInfo.chargeUser){
          nodeCheckUser  = this.nodeBaseInfo.chargeUser.split(",");
        }
        //获取两个数组审核人合集
        let checkUserArr = billsTypeCheckUser;//默认获取单据类型审核人
        //去重并合并单据类型
        nodeCheckUser.forEach((item,index)=>{
          if(!checkUserArr.includes(item)){
            checkUserArr.push(item)
          }
        })
        return checkUserArr.join(",");
      },

      /* 获取当前单据的审核人(预览时显示的审核人) */
      getBillsCheckUser(){
        console.log(this.originalBillData);
      },

      /* 提交当前组件信息*/
      commitNodeFormMsg(){
        let data = {
          create_user_id:this.UserInfo.user_id,//制单人id
          create_user_name:this.UserInfo.user_name,//制单人名称
          customer_user_id:this.customer_data.user_id,//客户所属人
          check_user_id:this.nodeBaseInfo.charge_userids,//审核人id
          // check_user_name:this.nodeBaseInfo.charge_userids,//审核人名称
        };
        this.$emit("getNodeFormData",data);
      },
    }
  }
</script>

<style>
</style>
