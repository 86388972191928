<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：单据公共组件
	开始时间：2021-04-27
	开发人员：刘巍骏
	最后修改：2021-04-27
	备注说明：如需修改请联系开发人员(生成其他单据是必须传入generateBillsId)
-->
<template>
  <div>
    <el-dialog :visible.sync="show_nodeBills" :before-close="colse_createBox" :fullscreen="true" :show-close="false"
      id="nodeBills" append-to-body class="padding_top_0">
      <!-- 上一行备份 -->
      <!-- id="nodeBills" append-to-body :modal-append-to-body="false" class="padding_top_0"> -->
      <!-- dialog头部自定义 -->
      <div slot="title" class="dialogCustomHead">
        <!-- 左边提示信息 -->
        <div class="headLeft">
          <!-- 操作方式 -->
          <div class="headTitle">
            <span v-if="operateType == 0">新增单据</span>
            <span v-if="operateType == 1">修改单据</span>
            <span v-if="operateType == 2">预览单据</span>
          </div>
          <!-- 单据状态 -->
          <div class="headTitle" v-if="curBillsState!=-1 && [0,1,2,3,5,6,7,8].includes(billsTypeSign)">
            <span class="color_info" v-show="curBillsState == 0">(未提交)</span>
            <span class="color_warning" v-show="curBillsState == 1">(审核中)</span>
            <span class="color_danger" v-show="curBillsState == 2">(已驳回)</span>
            <span class="color_primary" v-show="curBillsState == 3">(复审中)</span>
            <span class="color_success" v-show="curBillsState == 4">(已审核)</span>
          </div>
          <!-- 单据状态(对账) -->
          <div class="headTitle" v-if="curBillsState!=-1 && [9].includes(billsTypeSign)">
            <span class="color_info" v-show="curBillsState == 0">(未提交)</span>
            <span class="color_warning" v-show="curBillsState == 1">(待对账)</span>
            <span class="color_danger" v-show="curBillsState == 2">(已驳回)</span>
            <span class="color_primary" v-show="curBillsState == 3">(复审中)</span>
            <span class="color_warning" v-show="curBillsState == 4">(对账中)</span>
            <span class="color_success" v-show="curBillsState == 5">(已对账)</span>
            <span class="color_primary" v-show="curBillsState == 6">(结款中)</span>
            <span class="color_success" v-show="curBillsState == 7">(已结款)</span>
          </div>
        </div>

        <!-- 右边流程选择 -->
        <div class="headRight">
          <!-- 单据生成 -->
          <div class="headRigntGenerate" v-if="curBillsState == 4">
            <el-dropdown trigger="click" placement="bottom" @command="generateOtherBill">
              <span class="el-dropdown-link">
                生成<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <!-- 销售模块 -->
                <el-dropdown-item command="0.1" v-if="billsTypeSign == 0 && billsClassify == 0">销售订单</el-dropdown-item>
                <el-dropdown-item command="0.2" v-if="billsTypeSign == 0 && billsClassify == 1">采购申请单</el-dropdown-item>
                <el-dropdown-item command="0.3" v-if="billsTypeSign == 0 && billsClassify == 1">采购订单</el-dropdown-item>
                <el-dropdown-item command="0.4" v-if="billsTypeSign == 0 && billsClassify == 1">销售出库单</el-dropdown-item>
                <!-- 采购模块 -->
                <el-dropdown-item command="1.1" v-if="billsTypeSign == 3 && billsClassify == 0">采购订单</el-dropdown-item>
                <el-dropdown-item command="1.2" v-if="billsTypeSign == 3 && billsClassify == 0">采购询价单</el-dropdown-item>
                <el-dropdown-item command="1.3" v-if="billsTypeSign == 3 && billsClassify == 1">采购入库单</el-dropdown-item>
                <!-- 库存模块 -->
                <!-- 生产计划模块-->
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- 单据流程 -->
          <div>
            <span class="headSpan">单据流程:</span>
            <el-select v-model="currWorkFlowName" placeholder="请选择订单流程" :disabled="operateType!=0" size="small">
              <el-option v-for="(item,index) in workFlowData" :key="item.id" :label="item.name" :value="item.name"
                @click.native="getSelWorkFlow(item)">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>

      <!-- dialog内容 -->
      <div class="billingcreate billingcreate_form scollStyle">
        <!-- 单据头部 -->
        <div class="billingcreate_head">
          <!-- 头部左边单据日期 -->
          <div class="head_left">
            <!-- 单据日期 -->
            <div class="title">
              <span>单据编号:</span>
            </div>
            <div class="date">
              <input type="text" v-model="billsNumber" disabled="disabled" placeholder="请输入编号">
            </div>
          </div>
          <!-- 中间公司名 -->
          <div class="head_content">
            <span class="billing_Company">
              {{bills_type_data.bills_type}}
            </span>
          </div>
          <!-- 头部右边单据操作 -->
          <div class="head_right">
            <!-- 单据日期 -->
            <div class="title">
              <span>日期:</span>
            </div>
            <div class="date">
              <!-- 预览时不可修改 -->
              <el-date-picker v-model="billsDate" :disabled="operateType == 2" type="date" placeholder="选择日期">
              </el-date-picker>
            </div>
          </div>
        </div>

        <!-- 基本信息 -->
        <div>
          <!-- 分类 -->
          <div class="billingcreate_formType">
            <h5>基本信息</h5>
          </div>
          <!-- 表单信息 -->
          <div>
            <nodeFormBase :isCommitFormData="isCommitFormData" :isClearFormData="isClearFormData"
              :nodeTypeCode="nodeTypeCode" :baseFormData="nodeFormData.baseFormData"
              :baseCusFormData="nodeFormData.baseCusFormData" :baseNodeInfo="nodeBaseInfo"
              :originalBillData="originalBillData" :generateBillsId="generateBillsId" :operateType="operateType"
              :billsClassify="billsClassify" :billsTypeSign="billsTypeSign" :billsTypeDefaultCode="billsTypeDefaultCode"
              :dictionariesDefaultData="dictionariesDefaultData" :nodeCusTextFormData="nodeCusTextFormData"
              :commitFormStep="commitFormStep" :isGenerateOperateType="isGenerateOperateType"
              @getNodeFormData="getNodeBaseData" @closeCostCheckCheckbox="closeCostCheckCheckbox" ref="nodeFormBase">
            </nodeFormBase>
          </div>
        </div>

        <!-- 货品信息 -->
        <div v-if="![1,9].includes(billsTypeSign)">
          <!-- 分类 -->
          <div class="billingcreate_formType" style="margin-bottom: 10px;">
            <!-- 销售,库存,采购显示货品信息 -->
            <h5 v-if="['SYS-NODE-TYPE-001','SYS-NODE-TYPE-002','SYS-NODE-TYPE-004'].includes(nodeTypeCode)">货品信息</h5>
            <!-- 资金显示单据信息 -->
            <h5 v-if="['SYS-NODE-TYPE-005'].includes(nodeTypeCode)">单据信息</h5>
          </div>
          <!-- 表单信息 -->
          <div>
            <nodeTableData :isCommitFormData="isCommitFormData" :isClearFormData="isClearFormData"
              :baseToalData="nodeTableData.sysTotalData" :cusTableData="nodeTableData.cusTableData"
              :sysTableData="nodeTableData.sysTableData" :billsTypeSign="billsTypeSign"
              :generateBillsId="generateBillsId" :batchNumber="batchNumber" :originalBillData="originalBillData"
              :nodeTypeCode="nodeTypeCode" :billsClassify="billsClassify" :operateType="operateType"
              :saveNewBillsData="saveNewBillsData" :commitFormStep="commitFormStep" @getNodeTableData="getNodeTableData"
              @getOperateTableData="getOperateTableData" v-if="shouChildComponent">
            </nodeTableData>
          </div>
        </div>

        <!-- 生产工艺报价信息 -->
        <div v-if="showProcessOfferBox">
          <!-- 分类 -->
          <div class="billingcreate_formType">
            <h5>生产工艺明细</h5>
          </div>
          <!-- 表单信息 -->
          <div>
            <nodeCraftOfferTableData :isCommitFormData="isCommitFormData" :originalBillData="originalBillData"
              :operateType="operateType" :isClearFormData="isClearFormData" @getCraftTableMsg="getCraftTableMsg">
            </nodeCraftOfferTableData>
          </div>
        </div>

        <!-- 生产物料信息 -->
        <div v-if="billsTypeSign == 1">
          <div>
            <nodeMesTableData :isCommitFormData="isCommitFormData" :isClearFormData="isClearFormData"
              :cusTableData="nodeTableData.cusTableData" :sysTableData="nodeTableData.sysTableData"
              :generateBillsId="generateBillsId" :operateType="operateType" :originalBillData="originalBillData"
              :commitFormStep="commitFormStep" :nodeTypeCode="nodeTypeCode" @getNodeTableData="getNodeMesTableData">
            </nodeMesTableData>
          </div>
        </div>

        <!-- 资金对账单信息 -->
        <div v-if="billsTypeSign == 9">
          <!-- 销售采购对账 -->
          <div>
            <nodeCheckTableData :isCommitFormData="isCommitFormData" :isClearFormData="isClearFormData"
              :billsTypeSign="billsTypeSign" :billsClassify="billsClassify" :generateBillsId="generateBillsId"
              :operateType="operateType" :originalBillData="originalBillData" :commitFormStep="commitFormStep"
              :nodeTypeCode="nodeTypeCode" @getNodeTableData="getNodeCheckTableData"></nodeCheckTableData>
          </div>

          <!-- 生产对账 -->
          <!-- <div v-if="billsClassify == 6">
            <nodeCheckMesTableData></nodeCheckMesTableData>
          </div> -->
        </div>

        <!-- 可选的信息 -->
        <div class="billingcreate_classify" v-if="nodeFormTypeArr.length>1">
          <ul>
            <li v-if="nodeFormTypeArr.includes(2)">
              <el-checkbox :value="show_relevance.show_consignee" :disabled="operateType == 2"
                @change="changeReserveData('show_consignee',2)">收货人信息
              </el-checkbox>
            </li>
            <li v-if="nodeFormTypeArr.includes(1)">
              <el-checkbox :value="show_relevance.show_logistics" :disabled="operateType == 2"
                @change="changeReserveData('show_logistics',1)">物流信息
              </el-checkbox>
            </li>
            <li v-if="nodeFormTypeArr.includes(3)">
              <el-checkbox :value="show_relevance.show_invoice" :disabled="operateType == 2"
                @change="changeReserveData('show_invoice',3)">发票信息
              </el-checkbox>
            </li>
            <li v-if="nodeFormTypeArr.includes(4)">
              <el-checkbox :value="show_relevance.show_payment" :disabled="operateType == 2"
                @change="changeReserveData('show_payment',4)">付款信息
              </el-checkbox>
            </li>
            <li v-if="nodeFormTypeArr.includes(5)">
              <el-checkbox :value="show_relevance.show_expense" :disabled="operateType == 2"
                @change="changeReserveData('show_expense',5)">其他费用
              </el-checkbox>
            </li>
          </ul>
        </div>

        <!-- 收货人信息 -->
        <div v-if="show_relevance.show_consignee">
          <!-- 分类 -->
          <div class="billingcreate_formType">
            <h5>收货人信息</h5>
          </div>
          <!-- 表单信息 -->
          <div>
            <nodeFormConsignee :isCommitFormData="isCommitFormData" :isClearFormData="isClearFormData"
              :originalBillData="originalBillData" :operateType="operateType" :generateBillsId="generateBillsId"
              :commitFormStep="commitFormStep" @getNodeFormData="getNodeConsigneeData">
            </nodeFormConsignee>
          </div>
        </div>

        <!-- 物流信息 -->
        <div v-if="show_relevance.show_logistics">
          <!-- 分类 -->
          <div class="billingcreate_formType">
            <h5>物流信息</h5>
          </div>
          <!-- 表单信息 -->
          <div>
            <nodeFormLogistics :isCommitFormData="isCommitFormData" :isClearFormData="isClearFormData"
              :deleteReserveType="deleteReserveType" :baseNodeInfo="nodeBaseInfo" :originalBillData="originalBillData"
              :generateBillsId="generateBillsId" :operateType="operateType" :billsTypeSign="billsTypeSign"
              :operateReselveType="operateReselveType" :saveNewBillsData="saveNewBillsData"
              :commitFormStep="commitFormStep" @getNodeFormData="getNodeLogisticsData"
              @getOperateLogisticsResult="getOperateLogisticsResult">
            </nodeFormLogistics>
          </div>
        </div>

        <!-- 发票信息 -->
        <div v-if="show_relevance.show_invoice">
          <!-- 分类 -->
          <div class="billingcreate_formType">
            <h5>发票信息</h5>
          </div>
          <!-- 表单信息 -->
          <div>
            <nodeFormInvoice :isCommitFormData="isCommitFormData" :isClearFormData="isClearFormData"
              :baseNodeInfo="nodeBaseInfo" :originalBillData="originalBillData" :generateBillsId="generateBillsId"
              :operateReselveType="operateReselveType" :operateType="operateType" :billsTypeSign="billsTypeSign"
              :saveNewBillsData="saveNewBillsData" :commitFormStep="commitFormStep"
              @getNodeFormData="getNodeInvoiceData" @getOperateInvoiceResult="getOperateInvoiceResult">
            </nodeFormInvoice>
          </div>
        </div>

        <!-- 付款信息 -->
        <div v-if="show_relevance.show_payment">
          <!-- 分类 -->
          <div class="billingcreate_formType">
            <h5>付款信息</h5>
          </div>
          <!-- 表单信息 -->
          <div>
            <nodeFormPayment :isCommitFormData="isCommitFormData" :isClearFormData="isClearFormData"
              :originalBillData="originalBillData" :generateBillsId="generateBillsId"
              @getNodeFormData="getNodePaymentData">
            </nodeFormPayment>
          </div>
        </div>

        <!-- 其他费用信息 -->
        <div v-if="show_relevance.show_expense">
          <!-- 分类 -->
          <div class="billingcreate_formType">
            <h5>其他费用</h5>
          </div>
          <!-- 表单信息 -->
          <div>
            <nodeFormExpense :isCommitFormData="isCommitFormData" :isClearFormData="isClearFormData"
              :baseNodeInfo="nodeBaseInfo" :originalBillData="originalBillData" :generateBillsId="generateBillsId"
              :operateReselveType="operateReselveType" :operateType="operateType" :billsTypeSign="billsTypeSign"
              :saveNewBillsData="saveNewBillsData" :commitFormStep="commitFormStep"
              @getNodeFormData="getNodeExpenseData">
            </nodeFormExpense>
          </div>
        </div>

        <!-- 附件信息(销售,采购,库存显示) -->
        <div v-if="nodeHasFileNodeType.includes(billsTypeSign)">
          <!-- 分类 -->
          <div class="billingcreate_formType">
            <h5>附件信息</h5>
          </div>
          <!-- 表单信息 -->
          <div>
            <nodeFormFile :isCommitFormData="isCommitFormData" :isClearFormData="isClearFormData"
              :billsTypeSign="billsTypeSign" :originalBillData="originalBillData" :generateBillsId="generateBillsId"
              :commitFormStep="commitFormStep" :operateType="operateType" @getNodeFormData="getNodeFileData">
            </nodeFormFile>
          </div>
        </div>

        <!-- 备注信息(销售,采购,库存显示) -->
        <div v-if="nodeHasRemarkNodeType.includes(billsTypeSign)">
          <!-- 分类 -->
          <div class="billingcreate_formType">
            <h5>备注信息</h5>
          </div>
          <!-- 表单信息 -->
          <div>
            <nodeFormRemark :isCommitFormData="isCommitFormData" :isClearFormData="isClearFormData"
              :originalBillData="originalBillData" :generateBillsId="generateBillsId" :operateType="operateType"
              :commitFormStep="commitFormStep" :billsTypeSign="billsTypeSign" @getNodeFormData="getNodeRemarkData"></nodeFormRemark>
          </div>
        </div>

        <!-- 可选的信息 -->
        <div class="billingcreate_classify" v-if="nodeFormTypeArr.length>1">
          <ul>
            <li v-if="nodeFormTypeArr.includes(7)">
              <el-checkbox :value="show_relevance.show_returnpro" @change="changeReserveData('show_returnpro',7)"
                :disabled="operateType == 2">退货明细
              </el-checkbox>
            </li>
            <li v-if="nodeFormTypeArr.includes(8)">
              <el-checkbox :value="show_relevance.show_returncost" @change="changeReserveData('show_returncost',8)"
                :disabled="operateType == 2">退款明细
              </el-checkbox>
            </li>
            <li v-if="nodeFormTypeArr.includes(9)">
              <el-checkbox :value="show_relevance.show_getcost" @change="changeReserveData('show_getcost',9)"
                :disabled="operateType == 2">收款明细
              </el-checkbox>
            </li>
            <!-- <li v-if="nodeFormTypeArr.includes(10)">
              <el-checkbox :value="show_relevance.show_detailcost"
                @change="changeReserveData('show_detailcost',10)">详细应收/付款
              </el-checkbox>
            </li> -->
          </ul>
        </div>

        <!-- 退货明细 -->
        <div v-if="show_relevance.show_returnpro">
          <!-- 分类 -->
          <div class="billingcreate_formType">
            <h5>退货明细</h5>
          </div>
          <!-- 内容信息 -->
          <div>
            <nodeFormReturnPro :billsTypeSign="billsTypeSign" :billsClassify="billsClassify"></nodeFormReturnPro>
          </div>
        </div>

        <!-- 退款明细 -->
        <div v-if="show_relevance.show_returncost">
          <!-- 分类 -->
          <div class="billingcreate_formType">
            <h5>退款明细</h5>
          </div>
          <!-- 内容信息 -->
          <div>
            <nodeFormReturnCost></nodeFormReturnCost>
          </div>
        </div>

        <!-- 收款明细 -->
        <div v-if="show_relevance.show_getcost">
          <!-- 分类 -->
          <div class="billingcreate_formType costGetBox">
            <h5>收款明细</h5>
            <el-checkbox v-if="show_periodCostCheckbox" class="checkbox" v-model="show_periodCostBills"
              :disabled="operateType == 2">显示周期内收付款单
            </el-checkbox>
          </div>
          <!-- 内容信息 -->
          <div>
            <nodeFormGetCost :billsTypeSign="billsTypeSign" :billsClassify="billsClassify"
              :show_periodCostBills="show_periodCostBills" :operateType="operateType"></nodeFormGetCost>
          </div>
        </div>

        <!-- 详细应收/付款 -->
        <div v-if="show_relevance.show_detailcost">
          <!-- 分类 -->
          <div class="billingcreate_formType">
            <h5>详细应收/付款</h5>
          </div>
          <!-- 内容信息 -->
          <div>
            <nodeFormDetailCost></nodeFormDetailCost>
          </div>
        </div>

        <!-- 自定义文本信息 -->
        <div v-if="nodeCusTextFormData.length>0" v-for="elem in nodeCusTextFormData">
          <!-- 自定义名称 -->
          <div class="billingcreate_formType">
            <h5>{{elem.custom_data.name}}</h5>
          </div>
          <div class="filedTextearBox">
            <el-col :span="24">
              <el-input type="textarea" v-model="elem.custom_form_value" :rows="3" :span="12" placeholder="请输入内容"
                :autosize="{ minRows: 3, maxRows: 20}"></el-input>
            </el-col>
          </div>
        </div>

        <!-- 审核信息 -->
        <div>
          <!-- 分类 -->
          <div class="billingcreate_formType">
            <h5>其他信息</h5>
          </div>
          <div>
            <nodeFormAuditor :isCommitFormData="isCommitFormData" :nodeBaseInfo="nodeBaseInfo"
              :billsTypeSign="billsTypeSign" :operateType="operateType" :originalBillData="originalBillData"
              :generateBillsId="generateBillsId" @getNodeFormData="getNodeAuditorData">
            </nodeFormAuditor>
          </div>
        </div>

      </div>
      <!-- 提交按钮 -->
      <span slot="footer" class="dialog-footer">
        <div class="nodeBillFootBox">
          <!-- 总计信息 -->
          <div class="footBoxLeft">
            <nodeFormTotalAmount :billsTypeSign="billsTypeSign" :billsClassify="billsClassify"
              :isClearFormData="isClearFormData" v-if="billsTypeSign == 0 && billsClassify == 0"></nodeFormTotalAmount>
            <!-- 单据总金额 -->
          </div>
          <!-- 操作按钮 -->
          <div class="footBoxRight">
            <!-- 新增时的按钮 -->
            <!-- <el-button type="primary" v-if="judgeShowOperateBtn(0)" @click="getCommitOperate(0)">生成单据</el-button> -->
            <el-button type="success" v-if="judgeShowOperateBtn(1)" @click="getCommitOperate(1)">提交审批</el-button>
            <el-button type="success" v-if="judgeShowOperateBtn(2)" @click="getCommitOperate(2)">保存并审核</el-button>
            <el-button type="warning" v-if="judgeShowOperateBtn(3)" @click="getCommitOperate(3)">清空</el-button>
            <!-- 修改时的按钮 -->
            <el-button type="success" v-if="judgeShowOperateBtn(6)" @click="getCommitOperate(6)">审批</el-button>
            <el-button type="success" v-if="judgeShowOperateBtn(10)" @click="getCommitOperate(10)">对账确认</el-button>
            <el-button type="danger" v-if="judgeShowOperateBtn(7)" @click="getCommitOperate(7)">驳回</el-button>
            <el-button type="warning" v-if="judgeShowOperateBtn(8)" @click="getCommitOperate(8)">作废</el-button>
            <el-button type="success" v-if="judgeShowOperateBtn(5)" @click="getCommitOperate(5)">保存</el-button>
            <!-- 预览时的按钮 -->
            <el-button type="primary" v-if="judgeShowOperateBtn(9)" @click="getCommitOperate(9)">修改</el-button>
            <!-- 公共按钮 -->
            <el-button type="primary" v-if="judgeShowOperateBtn(4)" @click="getCommitOperate(4)">取消</el-button>
          </div>
        </div>
      </span>
    </el-dialog>

    <!-- 工时信息弹框(下一节点工时信息为员工填写时用到) -->
    <el-dialog title="预计工时" :visible.sync="show_hourseBox" width="400px" :before-close="closeHourseBox" append-to-body>
      <!-- 工时信息弹框 -->
      <div class="billing_dialog_form">
        <ul class="single_ul">
          <li>
            <div class="title">下节点预计工时:</div>
            <div class="mulData">
              <el-input size="small" class="mulInput" type="number" v-model.number="nextHourse" placeholder="请输入工时时长">
              </el-input>
              <span class="mulSpan" v-if="nextNodeBaseInfo.working_hours_type == 1">天</span>
              <span class="mulSpan" v-if="nextNodeBaseInfo.working_hours_type == 2">小时</span>
            </div>
          </li>
        </ul>
      </div>

      <!-- 提交按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="commitNodeData(nodeTargetObj,1)">确认</el-button>
        <el-button size="small" @click="closeHourseBox">取消</el-button>
      </span>
    </el-dialog>

    <!-- 驳回条件弹框 -->
    <el-dialog title="驳回原因" :visible.sync="show_reject" width="500px" append-to-body>
      <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="rejectData">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show_reject = false">取 消</el-button>
        <el-button size="small" type="primary" @click="rejectBills">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 作废条件弹框 -->
    <el-dialog title="作废原因" :visible.sync="show_delete" width="500px" append-to-body>
      <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="deleteData">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show_delete = false">取 消</el-button>
        <el-button size="small" type="primary" @click="deleteBillsData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入网络请求
  import workApi from '@/network/system/workflowSetup.js'; //工作流程相关接口请求
  import purchaseApi from '@/network/stock/purchase.js'; //采购模块相关接口
  import stockApi from '@/network/stock/stock.js'; //库存相关接口
  import sellApi from '@/network/sell/bills.js'; //销售相关接口
  import productApi from '@/network/production/production.js'; //生产相关接口
  import costApi from '@/network/cost/account.js'; //资金相关接口

  // 导入组件
  import nodeFormBase from './nodeFormItem/nodeForm_base.vue'; //基本信息组件
  import nodeFormConsignee from './nodeFormItem/nodeForm_consignee.vue'; //收货人信息组件
  import nodeFormLogistics from './nodeFormItem/nodeForm_logistics.vue'; //物流信息组件
  import nodeFormInvoice from './nodeFormItem/nodeForm_invoice.vue'; //发票信息组件
  import nodeFormPayment from './nodeFormItem/nodeForm_payment.vue'; //付款信息组件
  import nodeFormExpense from './nodeFormItem/nodeForm_expense.vue'; //其他费用信息组件
  import nodeFormFile from './nodeFormItem/nodeForm_file.vue'; //附件信息组件
  import nodeFormRemark from './nodeFormItem/nodeForm_remark.vue'; //备注信息组件
  import nodeFormAuditor from './nodeFormItem/nodeForm_auditor.vue'; //审核人相关信息
  import nodeFormGetCost from './nodeFormItem/nodeForm_getcost.vue'; //收款明细
  import nodeFormReturnCost from './nodeFormItem/nodeForm_returncost.vue'; //退款明细
  import nodeFormReturnPro from './nodeFormItem/nodeForm_returnpro.vue'; //退货明细
  import nodeFormDetailCost from './nodeFormItem/nodeForm_detailcost.vue'; //详细应收应付
  import nodeFormTotalAmount from './nodeFormItem/nodeForm_totalAmount.vue'; //单据总计信息
  import nodeTableData from './nodeTableItem/nodeTable_data.vue'; //产品表格信息组件
  import nodeMesTableData from './nodeTableItem/nodeMesTable_data.vue'; //生产单物料表格信息
  import nodeCheckTableData from './nodeTableItem/nodeCheckTable_data.vue'; //资金对账单表格信息
  import nodeCheckMesTableData from './nodeTableItem/nodeCheckMesTable_data.vue'; //资金生产对账单表格信息
  import nodeCraftOfferTableData from './nodeTableItem/nodeCraftOfferTable_data.vue'; //生产工艺报价表格信息
  // import nodeCostTableData from './nodeTableItem/nodeCostTable_data.vue';//收付款单关联单据表格信息

  export default {
    name: 'nodeBills',
    props: {
      //打开公共单据方式(0:正常打开  1:在自身上层打开)
      loadNodeBillsType: {
        type: Number,
        default: 0
      },
      // 操作类型(0:新增 1:修改  2:查看)
      operateType: {
        type: Number,
        default: -1
      },
      //是否为生成单据操作
      isGenerateOperateType: {
        type: Number,
        default: -1
      },
      // 节点分类编码
      nodeTypeCode: {
        type: String,
        default: ''
      },
      //节点分类标志(0:销售 1:生产 2:库存  3:采购  4:bom  5.收付款  6:资金 7:工序单 8:发票)
      billsTypeSign: {
        type: Number,
        default: -1
      },
      //单据分类(父组件为销售时(0:报价  1:订单) 资金时(0:收款单  1:付款单))
      billsClassify: {
        type: Number,
        default: 0
      },
      //回显单据原id(-1:新增 其他:生成)
      generateBillsId: {
        type: Number,
        default: -1
      },
      //生成其他单据的数据源
      generateBillsOriginData: {
        type: Object,
        default () {
          return {}
        }
      },
      // 生成其他单据的模块标识
      generateBillsSign: {
        type: Number,
        default: -1
      },
      //引入的工序id字符串(生成领料用)
      generateProcessId: {
        type: String,
        default: ''
      },
      //回显的相关节点和流程信息
      updateDefaultData: {
        type: Object,
        default () {
          return {}
        }
      },
      //默认单据类型code值
      billsTypeDefaultCode: {
        type: String,
        default: ''
      },
      // 数据字典默认数据
      dictionariesDefaultData: {
        type: Object,
        default () {
          return {}
        }
      },
    },
    data() {
      return {
        // 当前组件数据
        commitOperate: -1, //数据操作类型(0:生成单据 1:提交审批  2:保存并审核  3:清空  4:取消)
        originalBillData: {}, //生成单据原单据数据
        nodeCusTextFormData: [], //节点自定义文本类型数据
        is_edit: false, //是否可编辑

        //修改时用的数据信息
        curBillsState: -1, //当前回显的单据状态(单据修改/预览时用)
        saveNewBillsData: false, //控制是否提交新增的单据信息数据
        deleteReserveType: -1, //控制触发哪个子组件的删除操作[对应关联信息标识,物流信息等]
        addUpdateState: { //修改界面时添加新数据的状态
          add_table: false, //表格信息状态(货品和单据)
          add_logistics: false, //物流信息状态
          add_invoice: false, //发票信息状态
        },

        //流程相关
        workFlowData: [], //流程数据
        currWorkFlowName: '', //当前所选流程名称
        currWorkFlow: {}, //当前所选流程对象

        //节点相关
        currNodeId: -1, //当前节点id
        nodeTypeId: -1, //当前节点分类id
        nodeBaseInfo: {}, //当前节点基本信息
        nextNodeId: -1, //下一节点id
        nextHourse: 2, //下一节点预计工时
        nextNodeBaseInfo: {}, //下一节点基本信息

        //传入后端数据
        billsDate: "", //单据日期
        billsNumber: '', //单据编号
        batchNumber: '', //批次号
        nodeValData: { //节点填写的数据
          baseData: {}, //基本信息
          baseNameData: {}, //基本信息名称数据
          baseCusData: {}, //基本信息自定义数据
          baseHourse: {}, //基本信息工时时长
          consigneeData: {}, //收货人信息
          logisticsData: {}, //物流信息
          invoiceData: {}, //发票信息
          paymentData: {}, //付款信息
          expenseData: {}, //其他费用信息(包括表格数据和总费用金额)
          fileData: {}, //附件信息
          remarkData: {}, //备注信息
          tableData: [], //产品信息
          tableAllData: {}, //表格组件所有数据(包括总计)
          auditorData: {}, //单据制作人信息
          craftOfferData: {}, //生产工艺报价明细数据
        },

        //控制开关
        show_nodeBills: false, //控制显示当前组件弹框
        show_relevance: { //控制显示哪些关联信息
          show_consignee: false, //收货人信息
          show_invoice: false, //发票信息
          show_payment: false, //付款信息
          show_logistics: false, //物流信息
          show_expense: false, //其他费用
          show_returnpro: false, //退货信息
          show_returncost: false, //退款信息
          show_getcost: false, //收款信息
          show_detailcost: false, //详细应收/付
        },
        show_hourseBox: false, //控制显示工时信息弹框
        show_reject: false, //控制显示驳回弹框
        show_delete: false, //控制显示作废弹框
        shouChildComponent: false, //控制是否加载当前组件所有子组件
        showProcessOfferBox: false, //控制是否显示生产工艺报价表格
        show_periodCostBills: false, //控制是否显示周期内收付款单(对账单收付款明细用)(复选框值)
        show_periodCostCheckbox: false, //控制是否显示周期内收付款单复选框(控制显示复选框)

        //传入子组件数据
        nodeFormData: { //节点表单信息
          baseFormData: [], //基本信息
          baseCusFormData: [], //自定义基本信息
        },
        nodeTableData: { //节点表格信息
          sysTableData: [], //系统预设表格信息
          cusTableData: [], //自定义预设表体信息
          sysTotalData: [], //系统基本信息控制的总计信息
        },
        isCommitFormData: false, //是否提交已填信息
        commitFormStep: -1, //组件提交步骤(0:基本信息  1:表格信息  2:收货人信息  3:物流信息 4:发票信息  5:其他费用 6:附件  7:备注  8:完成)
        isClearFormData: false, //是否清除已填数据
        operateReselveType: -1, //关联信息操作类型(0:新增 2:删除)

        //loading框
        loading: '', //控制显示loading框

        // 辅助数据
        nodeHasFileNodeType: [0, 2, 3, 5, 8], //有附件组件的单据类型数组
        nodeHasRemarkNodeType: [0, 1,2, 3, 5, 8], //有备注组件的单据类型数组
        nodeFormTypeArr: [], //节点表单分类数组(收货人信息,物流信息,发票信息,其他费用等)
        nodeTargetObj: { //节点目标对象
          nodeBase: false, //节点基本信息组件信息是否合法
          nodeConsignee: false, //节点收货人组件信息是否合法
          nodeInvoice: false, //节点发票信息组件信息是否合法
          nodeLogistics: false, //节点物流信息组件信息是否合法
          nodePayment: false, //节点付款信息组件信息是否合法
          nodeExpense: false, //节点其他费用信息组件信息是否合法
          nodeFile: false, //节点附件信息组件信息是否合法
          nodeRemark: false, //节点备注信息组件信息是否合法
          nodeTable: false, //节点表格信息组件信息是否合法
          nodeCraftTable: false, //节点生产工艺报价组件信息是否合法
          nodeAuditor: false, //节点单据制作人组件信息是否合法
        },
        is_updateLikenMan: false, //是否需要修改联系人信息,相同则不修改,不相同则修改(false:否 true:是)
        rejectData: '', //驳回原因
        deleteData: '', //作废原因
      }
    },
    computed: {
      ...mapState({
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
        customer_data: state => state.commComponent.customer_data, //单据所选客户信息
        NodeClassfiy: state => state.system.NodeClassfiy, //节点分类所有数据
        billTemplateData: state => state.system.billTemplateData, //所有单据类型数据
        bills_type_data: state => state.commComponent.bills_type_data, //单据类型数据
        bills_relvence_sign: state => state.commComponent.bills_relvence_sign, //关联单据标识
        bills_base_data: state => state.commComponent.bills_base_data, //表单填写的基本信息数据
        //权限相关
        companyparamlist: state => state.companyparamlist, //获取企业级参数
      }),
    },
    created() {
      //设置默认单据日期
      this.billsDate = this.commonJsExtend.getDateTime(new Date(), 0); // 获取当前日期
    },
    mounted() {
      //获取节点分类所有数据(修改为监听)
      // this.judgeNodeTypeData();
      //显示当前组件弹框
      this.show_nodeBills = true;
    },
    watch: {
      // 监听模块分类编码发生改变
      nodeTypeCode: {
        handler(newVal) {
          //获取节点分类所有数据
          this.judgeNodeTypeData();
        },
        immediate: true,
      },
      //监听节点当前处理已填数据数
      nodeTargetObj: {
        handler(newVal) {
          //提交所有数据
          this.commitNodeData(newVal, 0);
        },
        deep: true
      },
      // 监听单据模板数据发生变化
      bills_type_data: {
        handler(newVal) {
          if (JSON.stringify(newVal) != "{}") {
            //设置单据编号
            this.getBillsNumber(newVal);
            //获取批次号
            this.getBatchNumber(newVal);
          }
        },
        immediate: true,
      },
      // 监听表单基本信息选择的数据
      bills_base_data: {
        handler(newVal) {
          //调用处理基本信息改变时的业务
          this.disposeBillsBaseDataChange(newVal);
        },
        deep: true
      },
      //监听修改界面添加的新数据是否处理完毕
      addUpdateState: {
        handler(newVal) {
          console.log(newVal);
          //调用处理方法
          this.judgeAddUpdateState(newVal);
        },
        deep: true,
      },
    },
    methods: {
      ...mapMutations([
        'BILLS_TYPE_DATA', //单据类型数据
        'BILLS_SEL_DETAIL', //引入单据详情
        'BILLS_RELVENCE_SIGN', //当前操作的关联单据标签
        'INIT_BILLS_RELEVNCE_DATA', //清空当前关联单据信息
        'RESTORE_BILLS_MSG', //恢复备份的单据信息
        'BILLS_OPERATE_BTN', //当前操作按钮类型
      ]),
      ...mapActions([
        'getAllNodeType', //节点分类所有数据
        'getelectronicTemplate', //所有单据类型数据
      ]),

      /* 获取当前企业所有节点分类数据 */
      async judgeNodeTypeData() {
        //获取所有节点分类
        if (this.NodeClassfiy.length == 0) {
          await this.getAllNodeType();
        }
        //获取所有单据类型数据
        if (this.billTemplateData.length == 0) {
          await this.getelectronicTemplate();
        }
        // 根据code获取节点分类id
        this.NodeClassfiy.forEach(item => {
          if (item.code == this.nodeTypeCode) {
            this.nodeTypeId = item.id;
          }
        })
        //获取流程数据
        this.getWorkflowSetupData();
      },

      /* 获取单据编号*/
      getBillsNumber(data) {
        //判断是否在新增模式下(修改和查看不需要生成)
        if (this.operateType != 0) {
          return
        }
        //设置传入后端的参数
        let numData = {
          templateId: data.id, //单据类型id
          sign: this.billsTypeSign, //类型分类id
        }
        // 发送请求
        sellApi.findCodeByFormatStr(numData).then(res => {
          if (res.code == 200) {
            this.billsNumber = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取批次号 */
      getBatchNumber(data) {
        //判断是否启用批次号
        if (data.is_enable_batch == 1) {
          let batchData = {
            templateId: data.id
          }
          // 发送请求
          sellApi.findBatchCodeByFormatStr(batchData).then(res => {
            if (res.code == 200) {
              this.batchNumber = res.data;
            }
          })
        }
      },

      /* 根据流程分类id获取当前分类所有流程数据*/
      getWorkflowSetupData() {
        //发送请求(不需要添加loadign框)
        workApi.getWorkflowSetupById({
          type_id: this.nodeTypeId
        }).then(res => {
          if (res.code == 200) {
            //清空数据
            this.workFlowData = [];
            //循环获取单据分类流程
            res.data.forEach((item, index) => {
              //根据当前模块分类获取流程
              if (item.type_classify == this.billsClassify) {
                this.workFlowData.push(item);
              }
            })
            //获取默认流程
            this.getDefaultProcessData();
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取默认流程(新增时使用默认,修改时使用单据所选)*/
      getDefaultProcessData() {
        // 判断当前操作界面模块
        if (this.operateType == 0) { //新增界面
          //获取默认设置的流程
          if (this.workFlowData.length > 0) {
            let billsTempData = {}; //定义接受单据类型对象
            let workFlowObj = {}; //定义接受流程数据对象
            let workFlowArr = []; //定义接受满足多个条件的流程数据
            //判断有默认值才执行
            if (this.billsTypeDefaultCode) {
              billsTempData = this.commonJsExtend.getBillsDataByType(2, this.billsTypeDefaultCode, this
                .billTemplateData);
            }
            // 循环获取默认流程
            this.workFlowData.forEach((item, index) => {
              
              let billingList=[];
              let intArr = [];
              if(item.billing_type_id!=null){
                billingList=item.billing_type_id.split(",");
                //循环处理未int数组
                billingList.forEach(item => {
                  intArr.push(parseInt(item));
                })
              }

              // 根据code值判断或根据默认值判断
              if (JSON.stringify(billsTempData) != "{}" && intArr.includes(billsTempData.id)) { //根据编码获取
                //获取满足条件的流程数据
                workFlowArr.push(item);
              } else if (item == 1) { //根据默认值获取
                //获取流程数据
                workFlowObj = item;
              }
            })
            //获取满足条件的流程数据(取第一个)
            if (workFlowArr.length > 0) {
              workFlowObj = workFlowArr[0];
            }
            // 判断未设置默认流程
            if (JSON.stringify(workFlowObj) == "{}") {
              //默认选择第一个
              workFlowObj = this.workFlowData[0];
            }
            //获取默认节点
            this.getSelWorkFlow(workFlowObj);
          }
        } else { //修改和预览界面
          //获取默认设置的流程
          if (this.workFlowData.length > 0) {
            // 循环获取默认流程
            for (let i = 0; i < this.workFlowData.length; i++) {
              // 判断是否为默认流程
              if (this.workFlowData[i].id == this.updateDefaultData.processId) {
                //获取默认节点
                this.getSelWorkFlowUpdata(this.workFlowData[i]);
              }
            }
          }
        }
      },

      /* 当所选流程发生改变时获取所选流程(新增时才有) */
      getSelWorkFlow(data) {
        //将所选流程存入data
        this.currWorkFlow = data;
        //设置默认流程名称
        this.currWorkFlowName = data.name;
        //切换流程时触发一次清空数据操作
        this.isClearFormData = !this.isClearFormData;
        //获取所选流程节点信息
        this.currNodeId = parseInt(data.status_ids.split(",")[0]); //当前节点
        this.nextNodeId = parseInt(data.status_ids.split(",")[1]); //下一节点
        //获取节点基本信息
        this.getNodeInfo();
        //获取节点动态数据
        this.getDynamicData();
      },

      /* 获取当前单据使用的流程(修改,预览时才有) */
      getSelWorkFlowUpdata(data) {
        //将所选流程存入data
        this.currWorkFlow = data;
        //设置默认流程名称
        this.currWorkFlowName = data.name;
        //获取节点信息
        this.currNodeId = this.updateDefaultData.nodeId;
        //获取节点基本信息
        this.getNodeInfo();
        //获取节点动态数据
        this.getDynamicData();
      },

      /* 获取节点基本信息 */
      getNodeInfo() {
        //1.获取当前节点数据
        if (this.currNodeId != -1) {
          let nodeData = {
            id: this.currNodeId
          }
          // 发送请求获取当前节点信息(不需要加载loading框)
          workApi.getWorkflowSetupNodeById(nodeData).then(res => {
            if (res.code == 200) {
              //获取节点基本信息
              this.nodeBaseInfo = res.data.node;
            } else {
              this.$message({
                type: 'error',
                message: '节点信息获取失败,请确认...',
                duration: this.elDuration
              })
            }
          })
        }
        //2.获取下一节点数据
        if (this.nextNodeId != -1) {
          let nodeNextData = {
            id: this.nextNodeId
          }
          // 发送请求获取当前节点信息(不需要加载loading框)
          workApi.getWorkflowSetupNodeById(nodeNextData).then(res => {
            if (res.code == 200) {
              //获取节点基本信息
              this.nextNodeBaseInfo = res.data.node;
            } else {
              this.$message({
                type: 'error',
                message: '节点信息获取失败,请确认...',
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 获取节点动态数据 */
      getDynamicData() {
        //定义传入后端的数据
        let nodeData = {
          status_id: this.currNodeId
        }
        // 发送请求前加载loading框(加载的loading为不存在的值,利用找不到默认加载整个界面处理)
        this.loading = this.commonJsExtend.customLoading("#nodeBillsTwo", 4, '单据配置信息更新中,请稍候...');
        // 根据节点id获取表单信息
        workApi.getAllNodeFromAndTable(nodeData).then(res => {
          if (res.code == 200) { //请求成功
            //加载所有子组件
            this.shouChildComponent = true;
            // 关闭loading框
            this.loading.close();
            //获取节点基本信息
            if (res.data.nodeSysForm.length > 0) {
              // 循环前需清空分类数组
              this.nodeFormData.baseFormData = [];
              // 定义一个接受基本信息类型数组(基本信息对象数)
              this.nodeFormTypeArr = [];
              // 循环获取分类信息
              res.data.nodeSysForm.forEach(item => {
                // 获取需要判断是否启用
                if (item.is_enable == 1 && item.system.classify == 0) { // 获取基本信息
                  this.nodeFormData.baseFormData.push(item);
                }
                //获取总计信息
                if (item.is_enable == 1 && item.system.classify == 6) {
                  this.nodeTableData.sysTotalData.push(item);
                }
                //获取分类对象数
                if (!this.nodeFormTypeArr.includes(item.system.classify)) {
                  this.nodeFormTypeArr.push(item.system.classify)
                }
              })
            }
            // 获取节点自定义基本信息
            if (res.data.nodeCustomForm.length > 0) {
              // 循环前需清空分类数组
              this.nodeFormData.baseCusFormData = [];
              // 循环获取所需信息
              res.data.nodeCustomForm.forEach(item => {
                // 获取需要判断是否启用
                if (item.is_enable == 1) {
                  // 判断类型
                  if (item.type == 6) { //文本框类型
                    //定义参数
                    let cusdata = {
                      custom_data: item,
                      custom_form_id: item.id,
                      custom_form_value: ''
                    }
                    //获取数据
                    this.nodeCusTextFormData.push(cusdata);
                  } else { //其他类型
                    this.nodeFormData.baseCusFormData.push(item);
                  }
                }
              })
            }
            //获取节点表格信息
            if (res.data.nodeSysTable.length > 0) {
              // 循环前需清空数组
              this.nodeTableData.sysTableData = [];
              //循环获取所需信息
              res.data.nodeSysTable.forEach(item => {
                this.nodeTableData.sysTableData.push(item);
                //判断是否启用(启用修改为是否显示)
                // if (item.is_enable == 1) {
                //   this.nodeTableData.sysTableData.push(item);
                // }
              })
            }
            // console.log(this.nodeTableData);
            //获取节点表格自定义信息
            if (res.data.nodeCustomTable.length > 0) {
              // 循环前需清空数组
              this.nodeTableData.cusTableData = [];
              //循环获取所需信息
              res.data.nodeCustomTable.forEach(item => {
                //判断是否启用
                if (item.is_enable == 1) {
                  this.nodeTableData.cusTableData.push(item);
                }
              })
            }

            //判断若需要回显获取回显信息(新增时不需要回显信息)
            if (this.operateType == 0) { //新增(生成等)
              //判断是否为引入工序(生成领料单)
              if (this.generateProcessId != "") {
                this.getOrderProcessMaterial();
              }
              //判断是否为生成其他单据
              if (this.generateBillsId != -1) {
                let originBillsArr = [this.generateBillsOriginData];
                this.$refs.nodeFormBase.getBillsData(originBillsArr, this.generateBillsSign);
              }
            } else if (this.operateType == 1 || this.operateType == 2) { //修改或预览
              //获取需要回显的数据
              this.getOrderBillsData();
            }
          } else { //请求失败
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取单据回显数据 */
      getOrderBillsData() {
        if (this.billsTypeSign == 0) { //获取销售模块单据数据
          //定义传入后端的数据
          let data = {
            saleOrderMainId: this.generateBillsId
          }
          // 发送请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '单据信息获取中,请稍候...');
          //发送请求
          sellApi.findSalesOrderMainDetail(data).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //获取详情数据
              this.originalBillData = res.data;
              //获取单据状态
              this.curBillsState = res.data.saleOrderMain.isSubmit;
              //获取单据编号和单据日期
              this.billsNumber = res.data.saleOrderMain.billsNo; //单据编号
              this.billsDate = res.data.saleOrderMain.billsDate; //单据日期
              //判断是否存在关联数据(发票,物流等信息)
              this.judgeRelevanceData(res.data);
              //修改时判断单据状态
              if (this.operateType == 1) {
                //判断单据状态(防止多台电脑同时操作同一张单据,)
                this.judgeBillsState(this.billsTypeSign, res.data);
              }
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 1) { //获取生产单模块数据
          //定义传入后端的数据
          let data = {
            id: this.generateBillsId
          }
          productApi.findMesMainDetail(data).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //获取详情数据
              this.originalBillData = res.data;
              //获取单据状态
              this.curBillsState = res.data.is_submit;
              //获取单据编号和单据日期
              this.billsNumber = res.data.bills_no; //单据编号
              this.billsDate = res.data.bills_date; //单据日期
              //判断是否存在关联数据(发票,物流等信息)
              this.judgeRelevanceData(res.data);
              //修改时判断单据状态
              if (this.operateType == 1) {
                //判断单据状态(防止多台电脑同时操作同一张单据,)
                this.judgeBillsState(this.billsTypeSign, res.data);
              }
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 2) { //获取库存单模块数据
          //定义传入后端的数据
          let data = {
            depotId: this.generateBillsId
          }
          stockApi.findDepotMainDetail(data).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //获取详情数据
              this.originalBillData = res.data;
              //获取单据状态
              this.curBillsState = res.data.depotMain.isSubmit;
              //获取单据编号和单据日期
              this.billsNumber = res.data.depotMain.billsNo; //单据编号
              this.billsDate = res.data.depotMain.billsDate; //单据日期
              //判断是否存在关联数据(发票,物流等信息)
              this.judgeRelevanceData(res.data);
              //修改时判断单据状态
              if (this.operateType == 1) {
                //判断单据状态(防止多台电脑同时操作同一张单据,)
                this.judgeBillsState(this.billsTypeSign, res.data);
              }
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 3) { //获取采购单模块数据
          //定义传入后端的数据
          let data = {
            purchaseMainId: this.generateBillsId
          }
          // 发送请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '单据信息获取中,请稍候...');
          //发送请求
          purchaseApi.findPurchaseMainDetail(data).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //获取详情数据
              this.originalBillData = res.data;
              //获取单据状态
              this.curBillsState = res.data.purchaseOrderMain.isSubmit;
              //获取单据编号和单据日期
              this.billsNumber = res.data.purchaseOrderMain.billsNo; //单据编号
              this.billsDate = res.data.purchaseOrderMain.billsDate; //单据日期
              //判断是否存在关联数据
              this.judgeRelevanceData(res.data);
              //修改时判断单据状态
              if (this.operateType == 1) {
                //判断单据状态(防止多台电脑同时操作同一张单据,)
                this.judgeBillsState(this.billsTypeSign, res.data);
              }
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 5) { //获取收付款单模块数据
          //定义传入后端的数据
          let data = {
            costBillsId: this.generateBillsId
          }
          // 发送请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '单据信息获取中,请稍候...');
          //发送请求
          costApi.findCostBillsMainDetail(data).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //获取详情数据
              this.originalBillData = res.data;
              //获取单据状态
              this.curBillsState = res.data.costBillsMain.isSubmit;
              //获取单据编号和单据日期
              this.billsNumber = res.data.costBillsMain.billsNo; //单据编号
              this.billsDate = res.data.costBillsMain.billsDate; //单据日期
              //判断是否存在关联数据
              this.judgeRelevanceData(res.data);
              //修改时判断单据状态
              if (this.operateType == 1) {
                //判断单据状态(防止多台电脑同时操作同一张单据,)
                this.judgeBillsState(this.billsTypeSign, res.data);
              }
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 6) { //获取报销单模块数据
          //定义传入后端的数据
          let data = {
            billsExpenseId: this.generateBillsId
          }
          // 发送请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '单据信息获取中,请稍候...');
          //发送请求
          costApi.findBillsExpenseDetail(data).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //获取详情数据
              this.originalBillData = res.data;
              //获取单据状态
              this.curBillsState = res.data.costBillsExpense.isSubmit;
              //获取单据编号和单据日期
              this.billsNumber = res.data.costBillsExpense.billsNo; //单据编号
              this.billsDate = res.data.costBillsExpense.billsDate; //单据日期
              //判断是否存在关联数据
              this.judgeRelevanceData(res.data);
              //修改时判断单据状态
              if (this.operateType == 1) {
                //判断单据状态(防止多台电脑同时操作同一张单据,)
                this.judgeBillsState(this.billsTypeSign, res.data);
              }
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 8) { //获取发票单模块数据
          //定义传入后端的数据
          let data = {
            id: this.generateBillsId
          }
          // 发送请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '单据信息获取中,请稍候...');
          //发送请求
          costApi.findCostBillsInvoiceDetail(data).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //获取详情数据
              this.originalBillData = res.data;
              //获取单据状态
              this.curBillsState = res.data.costBillsInvoice.isSubmit;
              //获取单据编号和单据日期
              this.billsNumber = res.data.costBillsInvoice.billsNo; //单据编号
              this.billsDate = res.data.costBillsInvoice.billsDate; //单据日期
              //判断是否存在关联数据
              this.judgeRelevanceData(res.data);
              //修改时判断单据状态
              if (this.operateType == 1) {
                //判断单据状态(防止多台电脑同时操作同一张单据,)
                this.judgeBillsState(this.billsTypeSign, res.data);
              }
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 9) { //获取对账单模块数据
          //定义传入后端的数据
          let data = {
            costCheckId: this.generateBillsId
          }
          // 发送请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '单据信息获取中,请稍候...');
          //发送请求
          costApi.findCostCheckBillsDetail(data).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //获取详情数据
              this.originalBillData = res.data;
              //获取单据状态
              this.curBillsState = res.data.costAccountCheck.isSubmit;
              //获取单据编号和单据日期
              this.billsNumber = res.data.costAccountCheck.billsNo; //单据编号
              this.billsDate = res.data.costAccountCheck.billsDate; //单据日期
              //判断是否存在关联数据
              this.judgeRelevanceData(res.data);
              //修改时判断单据状态
              if (this.operateType == 1) {
                //判断单据状态(防止多台电脑同时操作同一张单据,)
                this.judgeBillsState(this.billsTypeSign, res.data);
              }
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 获取工序关联物料信息 */
      getOrderProcessMaterial() {
        let getData = {
          processIds: this.generateProcessId
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '工序信息获取中,请稍候...');
        //发送请求
        productApi.findByMesProcessIds(getData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //将数据存入vuex
            this.BILLS_SEL_DETAIL(res.data);
            //设置引入类型标签(设置为生产单)
            this.BILLS_RELVENCE_SIGN(7);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 判断是否存在关联数据(关联信息是否默认展开) */
      judgeRelevanceData(data) {
        if (this.nodeFormTypeArr.includes(1) && !!data.orderTransport) { //物流信息
          this.show_relevance.show_logistics = true;
        }
        if (this.nodeFormTypeArr.includes(2) && !!data.linkman) { //收货人信息
          this.show_relevance.show_consignee = true;
        }
        if (this.nodeFormTypeArr.includes(3) && !!data.costBillsInvoice) { //发票信息
          this.show_relevance.show_invoice = true;
        }
        if (this.nodeFormTypeArr.includes(4) && !!data.gatherRecorder) { //付款信息
          this.show_relevance.show_payment = true;
        }
        if (this.nodeFormTypeArr.includes(5) && !!data.expenseSubitem && data.expenseSubitem.length > 0) { //其他费用
          this.show_relevance.show_expense = true;
        }
        if (this.nodeFormTypeArr.includes(7) && data.costAccountCheck.isReturnDetail == 1) { //退货明细
          this.show_relevance.show_returnpro = true;
        }
        if (this.nodeFormTypeArr.includes(8)) { //退款明细
          this.show_relevance.show_returncost = false;
        }
        if (this.nodeFormTypeArr.includes(9) && data.costAccountCheck.isCostDetail == 1) { //收款明细
          this.show_relevance.show_getcost = true;
          //判断是否显示周期内收付款单复选框
          if (data.costSurplusType == 1) {
            this.show_periodCostCheckbox = true;
            //判断是否勾选
            if (data.isPeriodCostBill == 1) {
              this.show_periodCostBills = true;
            }
          }
        }
        if (this.nodeFormTypeArr.includes(10)) { //详细应收应付
          this.show_relevance.show_detailcost = false;
        }
      },

      /* 判断单据状态(防止多条电脑同时操作导致修改已审核的单据) */
      judgeBillsState(sign, data) {
        let state = 0;
        //判断模块
        if (sign == 0) { //销售
          state = data.saleOrderMain.isSubmit;
        } else if (sign == 1) { //生产计划
          state = data.is_submit;
        } else if (sign == 2) { //库存
          state = data.depotMain.isSubmit;
        } else if (sign == 3) { //采购
          state = data.purchaseOrderMain.isSubmit;
        } else if (sign == 5) { //收付款
          state = data.costBillsMain.isSubmit;
        } else if (sign == 6) { //报销
          state = data.costBillsExpense.isSubmit;
        } else if (sign == 8) { //发票
          state = data.costBillsInvoice.isSubmit;
        } else if (sign == 9) { //对账
          state = data.costAccountCheck.isSubmit;
        }
        //判断状态
        if (sign == 9) { //对账模块

        } else { //其它模块
          //判断是否已审核
          if (state == 4) { //已审核
            this.$message({
              type: 'warning',
              message: '该单据已被审核,无法修改,请与审核人员确认!',
              duration: 3000
            })
            this.$parent.setOperateTypeIsView();
          }
        }
      },

      /* 判断修改界面新增数据的处理进度 */
      judgeAddUpdateState(data) {
        //只要有一个未处理完都不执行其他方法
        for (let i in data) {
          if (!data[i]) {
            return
          }
        }
        //都执行完后关闭弹框
        this.colse_createBox();
      },

      /* 调用处理基本信息改变时的业务 */
      disposeBillsBaseDataChange(data) {
        //判断单据所属模块
        if (this.billsTypeSign == 0) { //销售
          //判断是否有选择报价方式且报价方式为生产工艺报价
          if (this.bills_base_data.hasOwnProperty("offer_way_id") && this.bills_base_data.offer_way_id == 1) {
            //显示生产工艺报价表格
            this.showProcessOfferBox = true;
          } else {
            //不显示生产工艺报价表格
            this.showProcessOfferBox = false;
          }
        } else if (this.billsTypeSign == 9) { //对账模块
          //判断是否有自动结余收付款单类型
          if (this.bills_base_data.hasOwnProperty("cost_surplus_type") && this.bills_base_data.cost_surplus_type == 1) {
            //显示复选框
            this.show_periodCostCheckbox = true;
          } else {
            //不显复选框
            this.show_periodCostCheckbox = false;
            //去掉已选的复选
            this.show_periodCostBills = false;
          }
        }
      },

      /* 关联信息改变时触发 */
      changeReserveData(fileName, type) {
        //判断是新增还是修改
        if (this.operateType == 0) { //新增
          //判断是否选择联系人信息
          if (fileName == "show_consignee") { //联系人信息需先选择客户
            if (JSON.stringify(this.customer_data) == "{}") {
              this.$message({
                type: 'warning',
                message: '请先选择客户',
                duration: this.elDuration
              })
              return
            }
          }
          this.show_relevance[fileName] = !this.show_relevance[fileName];
        } else if (this.operateType == 1) { //修改
          //判断是哪个关联信息
          if (type == 1) { //物流信息
            //判断是勾选还是取消勾选
            if (this.show_relevance.show_logistics) { //取消勾选
              // 判断数据库是否有数据
              if (this.originalBillData.orderTransport) { //有数据
                //显示提示框
                this.$confirm('该操作会删除物流信息,删除的数据不可恢复, 是否继续?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  //触发子组件修改方法(删除)
                  this.saveNewBillsData = !this.saveNewBillsData;
                  //设置触发的关联组件为物流信息
                  this.deleteReserveType = type;
                  //设置操作为删除
                  this.operateReselveType = 2;
                }).catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消删除',
                    duration: this.elDuration
                  });
                });
              } else { //无数据
                this.show_relevance.show_logistics = false;
              }
            } else {
              //勾选弹框
              this.show_relevance.show_logistics = true;
            }
          } else if (type == 3) { //发票信息
            //判断是勾选还是取消勾选
            if (this.show_relevance.show_invoice) { //取消勾选
              // 判断数据库是否有数据
              if (this.originalBillData.costBillsInvoice) { //有数据
                //显示提示框
                this.$confirm('该操作会删除发票信息,删除的数据不可恢复, 是否继续?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  //触发子组件修改方法(删除)
                  this.saveNewBillsData = !this.saveNewBillsData;
                  //设置触发的关联组件为发票信息
                  this.deleteReserveType = type;
                  //设置操作为删除
                  this.operateReselveType = 2;
                }).catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消删除',
                    duration: this.elDuration
                  });
                });
              } else { //无数据
                this.show_relevance.show_invoice = false;
              }
            } else { //勾选弹框
              this.show_relevance.show_invoice = true;
            }
          } else if (type == 5) { //其他费用
            //判断是勾选还是取消勾选
            if (this.show_relevance.show_expense) { //取消勾选

            } else { //勾选弹框
              this.show_relevance.show_expense = true;
            }
          }
        } else if (this.operateType == 2) { //预览
          this.show_relevance[fileName] = !this.show_relevance[fileName];
        }
      },

      /* 处理关联信息关闭时的业务(修改时用) */
      disposeCloseReserve() {
        if (this.operateType == 1) {
          //判断关闭的那种弹框
          if (!this.show_relevance.show_logistics) {
            this.$confirm('该操作会删除物流信息,删除的数据不可恢复, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              //触发子组件修改方法
              this.saveNewBillsData = !this.saveNewBillsData;
              //设置操作为删除
              this.operateReselveType = 2;
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消清除'
              });
            });
          }
        }
      },

      /* 获取操作类型(type:操作类型)*/
      getCommitOperate(type) {
        //存入操作按钮类型
        this.BILLS_OPERATE_BTN(type);
        //判断操作方式
        if (type == 3) { //清空
          this.$confirm('清空后的数据不可恢复, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            //控制子组件清空数据
            this.isClearFormData = !this.isClearFormData;
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消清除'
            });
          });
        } else if (type == 4) { //取消
          //判断当前操作类型
          if (this.operateType == 0) { //新增
            this.$confirm('退出后将清空数据, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              //关闭弹框
              this.colse_createBox();
            }).catch(() => {});
          } else if (this.operateType == 1 || this.operateType == 2) { //预览/修改
            //关闭弹框
            this.colse_createBox();
          }
        } else if (type == 5) { //保存
          //设置子组件数据新增数据状态设置为默认
          this.addUpdateState.add_logistics = !this.show_relevance.show_logistics;//物流信息
          this.addUpdateState.add_invoice = !this.show_relevance.show_invoice;//发票信息信息
          //判断是否为生产模块(生产模块表格数据不修改)
          if (this.billsTypeSign == 1) {
            this.addUpdateState.add_table = true;
          } else {
            this.addUpdateState.add_table = false;
          }
          //触发保存新增的单据信息数据(只处理新增的)
          this.saveNewBillsData = !this.saveNewBillsData;
          //设置操作类型为新增
          this.operateReselveType = 0;
        } else if (type == 6) { //审核
          // 审核确认提示框
          this.$confirm('您确认审核该单据吗,审核后的单据无法修改和作废?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            // 调用处理审核单据方法
            this.disposeCheckBills();
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消审核',
              duration: this.elDuration
            });
          });
        } else if (type == 7) { //驳回
          //显示驳回弹框
          this.show_reject = true;
        } else if (type == 8) { //作废
          //显示作废弹框
          this.show_delete = true;
        } else if (type == 9) { //修改
          this.$parent.updateBillData(this.originalBillData, 1, 1, this.nodeTypeCode);
        } else if (type == 10) { //对账确认
          //调用对账单对账确认方法
          this.updateBillSubmitCheck();
        } else { //生成单据  提交审核 保存并审核
          //设置子组件数据提交状态设置为默认
          this.nodeTargetObj = {
            nodeBase: false, //基本信息
            nodeConsignee: !this.show_relevance.show_consignee, //收货人信息
            nodeInvoice: !this.show_relevance.show_invoice, //发票信息信息
            nodeLogistics: !this.show_relevance.show_logistics, //物流信息
            nodePayment: !this.show_relevance.show_payment, //付款信息
            nodeExpense: !this.show_relevance.show_expense, //其他费用
            nodeFile: this.nodeHasFileNodeType.includes(this.billsTypeSign) ? false : true, //附件信息
            nodeRemark: this.nodeHasRemarkNodeType.includes(this.billsTypeSign) ? false : true, //备注信息
            nodeCraftTable: true, //生产工艺报价明细信息
            nodeAuditor: false, //单据制作人信息
            nodeTable: false, //表格信息
          }
          // 设置触发子组件提交方法
          this.isCommitFormData = !this.isCommitFormData;
        }
        //将操作类型存入data
        this.commitOperate = type;
      },

      /* 处理单据审核方法 */
      disposeCheckBills() {
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '单据审核通过中,请稍候...');
        //判断不同模块
        if (this.billsTypeSign == 0) { //销售
          //定义传入后端的值
          let submitData = {
            //基本数据
            billingName: this.originalBillData.salesOrderMainRelevance.billingTypeId, //	单据类型名称
            relevanceId: this.originalBillData.saleOrderMain.id, //单据id
            statusId: this.originalBillData.saleOrderMain.statusId, //节点id
            processId: this.originalBillData.saleOrderMain.processId, //流程id
            //通过信息
            workingHours: 0, //工时时长
            workingHoursType: 0, //工时单位
            workingType: 0, //工时类型
            isFinish: 0, //是否完成
          }
          // 发送提交审核请求
          sellApi.updateOrderStatusYes(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            }
          })
        } else if (this.billsTypeSign == 1) { //生产计划
          //定义传入后端的值
          let submitData = {
            //基本数据
            billingName: this.originalBillData.billingTypeInfo.bills_type, //	单据类型名称
            relevanceId: this.originalBillData.id, //单据id
            statusId: this.originalBillData.status_id, //节点id
            processId: this.originalBillData.process_id, //流程id
            //通过信息
            workingHours: 0, //工时时长
            workingHoursType: 0, //工时单位
            workingType: 0, //工时类型
            isFinish: 0, //是否完成
          }
          // 发送提交审核请求
          productApi.updateMesMainStatusYes(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            }
          })
        } else if (this.billsTypeSign == 2) { //库存
          //定义传入后端的值
          let submitData = {
            //基本数据
            billingName: this.originalBillData.depotMainRelevance.billingTypeId, //	单据类型名称
            relevanceId: this.originalBillData.depotMain.id, //单据id
            statusId: this.originalBillData.depotMain.statusId, //节点id
            processId: this.originalBillData.depotMain.processId, //流程id
            //通过信息
            workingHours: 0, //工时时长
            workingHoursType: 0, //工时单位
            workingType: 0, //工时类型
            isFinish: 0, //是否完成
          }
          // 发送提交审核请求
          stockApi.updateStatusYes(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            }
          })
        } else if (this.billsTypeSign == 3) { //采购
          //定义传入后端的值
          let submitData = {
            //基本数据
            billingName: this.originalBillData.purchaseMainRelevance.billingTypeId, //	单据类型名称
            relevanceId: this.originalBillData.purchaseOrderMain.id, //单据id
            statusId: this.originalBillData.purchaseOrderMain.statusId, //节点id
            processId: this.originalBillData.purchaseOrderMain.processId, //流程id
            //通过信息
            workingHours: 0, //工时时长
            workingHoursType: 0, //工时单位
            workingType: 0, //工时类型
            isFinish: 0, //是否完成
          }
          // 发送提交审核请求
          purchaseApi.updatePurchaseMainStatusYes(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            }
          })
        } else if (this.billsTypeSign == 5) { //收付款
          //定义传入后端的值
          let submitData = {
            //基本数据
            billingName: this.originalBillData.costBillsMainRelevance.billingTypeId, //	单据类型名称
            relevanceId: this.originalBillData.costBillsMain.id, //单据id
            statusId: this.originalBillData.costBillsMain.statusId, //节点id
            processId: this.originalBillData.costBillsMain.processId, //流程id
            //通过信息
            workingHours: 0, //工时时长
            workingHoursType: 0, //工时单位
            workingType: 0, //工时类型
            isFinish: 0, //是否完成
          }
          // 发送提交审核请求
          costApi.updateCostBillsMainStatusYes(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            }
          })
        } else if (this.billsTypeSign == 6) { //报销
          //定义传入后端的值
          let submitData = {
            //基本数据
            billingName: this.originalBillData.costBillsExpenseRelevance.billingTypeId, //	单据类型名称
            relevanceId: this.originalBillData.costBillsExpense.id, //单据id
            statusId: this.originalBillData.costBillsExpense.statusId, //节点id
            processId: this.originalBillData.costBillsExpense.processId, //流程id
            //通过信息
            workingHours: 0, //工时时长
            workingHoursType: 0, //工时单位
            workingType: 0, //工时类型
            isFinish: 0, //是否完成
          }
          // 发送提交审核请求
          costApi.updateBillsExpenseStatusYes(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 7) { //生产执行

        } else if (this.billsTypeSign == 8) { //发票
          //定义传入后端的值
          let submitData = {
            //基本数据
            billingName: this.originalBillData.costBillsInvoiceRelevance.billingTypeId, //	单据类型名称
            relevanceId: this.originalBillData.costBillsInvoice.id, //单据id
            statusId: this.originalBillData.costBillsInvoice.statusId, //节点id
            processId: this.originalBillData.costBillsInvoice.processId, //流程id
            //通过信息
            workingHours: 0, //工时时长
            workingHoursType: 0, //工时单位
            workingType: 0, //工时类型
            isFinish: 0, //是否完成
          }
          // 发送提交审核请求
          costApi.updateInvoiceStatusYes(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 9) { //对账
          //定义传入后端的值
          let submitData = {
            //基本数据
            billingName: this.originalBillData.costAccountRelevance.billingTypeId, //	单据类型名称
            relevanceId: this.originalBillData.costAccountRelevance.id, //单据id
            statusId: this.originalBillData.costAccountRelevance.statusId, //节点id
            processId: this.originalBillData.costAccountRelevance.processId, //流程id
            //通过信息
            workingHours: 0, //工时时长
            workingHoursType: 0, //工时单位
            workingType: 0, //工时类型
            isFinish: 0, //是否完成
          }
          // 发送提交审核请求
          costApi.updateCheckSubmitYes(submitData).then(res => {
            if (res.code == 200) {
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 驳回单据 */
      rejectBills() {
        // 判断驳回原因是否已填
        if (this.rejectData.trim() == "") {
          this.$message({
            type: 'warning',
            message: '请填写驳回原因!',
            duration: this.elDuration
          })
          return
        }
        //关闭弹框
        this.show_reject = false;
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '单据驳回中,请稍候...');
        //判断模块
        if (this.billsTypeSign == 0) { //销售
          //定义传入后端的值
          let submitData = {
            //基本数据
            billingName: this.originalBillData.salesOrderMainRelevance.billingTypeId, //	单据类型名称
            relevanceId: this.originalBillData.saleOrderMain.id, //单据id
            statusId: this.originalBillData.saleOrderMain.statusId, //节点id
            processId: this.originalBillData.saleOrderMain.processId, //流程id
            isSubmit: 2, //订单状态
            //驳回信息
            rejectReason: this.rejectData, //驳回原因
          }
          // 发送驳回请求
          sellApi.updateOrderStatusNo(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '单据驳回成功!',
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 1) { //生产计划
          //定义传入后端的值
          let submitData = {
            //基本数据
            billingName: this.originalBillData.billingTypeInfo.bills_type, //	单据类型名称
            relevanceId: this.originalBillData.id, //单据id
            statusId: this.originalBillData.status_id, //节点id
            processId: this.originalBillData.process_id, //流程id
            isSubmit: 2, //订单状态
            isFinish: 2, //是否完成
            workingType: 0, //工时类型
            //驳回信息
            rejectReason: this.rejectData, //驳回原因
          }
          // 发送驳回请求
          productApi.updateMesMainStatusNo(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '单据驳回成功!',
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 2) { //库存
          //定义传入后端的值
          let submitData = {
            //基本数据
            billingName: this.originalBillData.depotMainRelevance.billingTypeId, //	单据类型名称
            relevanceId: this.originalBillData.depotMain.id, //单据id
            statusId: this.originalBillData.depotMain.statusId, //节点id
            processId: this.originalBillData.depotMain.processId, //流程id
            isSubmit: 2, //单据状态
            //驳回信息
            rejectReason: this.rejectData, //驳回原因
          }
          // 请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#inInventory_tableBox", 4, '单据驳回中,请稍候...');
          // 发送驳回请求
          stockApi.updateStatusNo(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '单据驳回成功!',
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 3) { //采购
          //定义传入后端的值
          let submitData = {
            //基本数据
            billingName: this.originalBillData.purchaseMainRelevance.billingTypeId, //	单据类型名称
            relevanceId: this.originalBillData.purchaseOrderMain.id, //单据id
            statusId: this.originalBillData.purchaseOrderMain.statusId, //节点id
            processId: this.originalBillData.purchaseOrderMain.processId, //流程id
            isSubmit: 2, //订单状态
            //驳回信息
            rejectReason: this.rejectData, //驳回原因
          }
          // 请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#purchase_tableBox", 4, '单据驳回中,请稍候...');
          // 发送驳回请求
          purchaseApi.updatePurchaseMainStatusNo(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '单据驳回成功!',
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 5) { //收付款
          //定义传入后端的值
          let submitData = {
            //基本数据
            billingName: this.originalBillData.costBillsMainRelevance.billingTypeId, //	单据类型名称
            relevanceId: this.originalBillData.costBillsMain.id, //单据id
            statusId: this.originalBillData.costBillsMain.statusId, //节点id
            processId: this.originalBillData.costBillsMain.processId, //流程id
            isSubmit: 2, //订单状态
            //驳回信息
            rejectReason: this.rejectData, //驳回原因
          }
          // 发送驳回请求
          costApi.updateCostBillsMainStatusNo(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '单据驳回成功!',
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 6) { //报销
          //定义传入后端的值
          let submitData = {
            //基本数据
            billingName: this.originalBillData.costBillsExpenseRelevance.billingTypeId, //	单据类型名称
            relevanceId: this.originalBillData.costBillsExpense.id, //单据id
            statusId: this.originalBillData.costBillsExpense.statusId, //节点id
            processId: this.originalBillData.costBillsExpense.processId, //流程id
            isSubmit: 2, //订单状态
            //驳回信息
            rejectReason: this.rejectData, //驳回原因
            workingType: 0, //工时类型
          }
          // 发送驳回请求
          costApi.updateBillsExpenseStatusNo(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '单据驳回成功!',
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 7) { //生产执行

        } else if (this.billsTypeSign == 8) { //发票
          //定义传入后端的值
          let submitData = {
            //基本数据
            billingName: this.originalBillData.costBillsInvoiceRelevance.billingTypeId, //	单据类型名称
            relevanceId: this.originalBillData.costBillsInvoice.id, //单据id
            statusId: this.originalBillData.costBillsInvoice.statusId, //节点id
            processId: this.originalBillData.costBillsInvoice.processId, //流程id
            isSubmit: 2, //订单状态
            //驳回信息
            rejectReason: this.rejectData, //驳回原因
          }
          // 发送驳回请求
          costApi.updateInvoiceStatusNo(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '单据驳回成功!',
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 9) { //对账
          //定义传入后端的值
          let submitData = {
            //基本数据
            billingName: this.originalBillData.costAccountRelevance.billingTypeId, //	单据类型名称
            relevanceId: this.originalBillData.costAccountCheck.id, //单据id
            statusId: this.originalBillData.costAccountCheck.statusId, //节点id
            processId: this.originalBillData.costAccountCheck.processId, //流程id
            isSubmit: 2, //订单状态
            //驳回信息
            rejectReason: this.rejectData, //驳回原因
          }
          // 发送驳回请求
          costApi.updateCheckSubmitNo(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '单据驳回成功!',
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 作废单据 */
      deleteBillsData() {
        // 判断作废原因是否已填
        if (this.deleteData.trim() == "") {
          this.$message({
            type: 'warning',
            message: '请填写作废原因!',
            duration: this.elDuration
          })
          return
        }
        //关闭弹框
        this.show_reject = false;
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '单据作废中,请稍候...');
        //判断模块
        if (this.billsTypeSign == 0) { //销售
          //定义传入后端的数据
          let deleteData = {
            orderId: this.originalBillData.saleOrderMain.id, //单据id,
            statusId: this.originalBillData.saleOrderMain.statusId, //节点id
            reason: this.deleteData, //作废原因
          }
          // 发送请求
          sellApi.deleteSaleOrderMain(deleteData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '单据作废成功!',
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        } else if (this.billsTypeSign == 1) { //生产计划
          //定义传入后端的数据
          let deleteData = {
            mesMainId: this.originalBillData.id, //生产单id
            mesMainName: this.originalBillData.billingTypeInfo.bills_type, //单据类型名称
            reason: this.deleteData, //作废原因
            statusId: this.originalBillData.status_id, //节点id
          }
          // 发送请求
          productApi.modifyMesMainDisable(deleteData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '单据作废成功!',
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        } else if (this.billsTypeSign == 2) { //库存
          //定义传入后端的数据
          let deleteData = {
            depotMainId: this.originalBillData.depotMain.id, //出入库单id
            depotMainName: this.originalBillData.depotMainRelevance.billingTypeId, //	单据类型名称
            reason: this.deleteData, //作废原因
            statusId: this.originalBillData.depotMain.statusId //节点id
          }
          // 发送请求
          stockApi.deleteDepotMain(deleteData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '单据作废成功!',
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        } else if (this.billsTypeSign == 3) { //采购
          //定义传入后端的数据
          let deleteData = {
            purchaseMainId: this.originalBillData.purchaseOrderMain.id,
            reason: this.deleteData,
            statusId: this.originalBillData.purchaseOrderMain.statusId
          }
          // 发送请求
          purchaseApi.deletePurchaseMain(deleteData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '单据作废成功!',
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        } else if (this.billsTypeSign == 5) { //收付款
          //定义传入后端的数据
          let deleteData = {
            costBillsMainId: this.originalBillData.costBillsMain.id,
            reason: this.deleteData,
            statusId: this.originalBillData.costBillsMain.statusId,
            costBillsMainName: this.originalBillData.costBillsMainRelevance.billingTypeId, //	单据类型名称
          }
          // 发送请求
          costApi.deleteCostBillsMain(deleteData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '单据作废成功!',
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        } else if (this.billsTypeSign == 6) { //报销
          //定义传入后端的数据
          let deleteData = {
            billsExpenseId: this.originalBillData.costBillsExpense.id,
            billsExpenseName: this.originalBillData.costBillsExpenseRelevance.billingTypeId, //	单据类型名称
            reason: this.deleteData,
            statusId: this.originalBillData.costBillsExpense.statusId
          }
          // 发送请求
          costApi.deleteBillsExpense(deleteData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '单据作废成功!',
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        } else if (this.billsTypeSign == 7) { //生产执行

        } else if (this.billsTypeSign == 8) { //发票
          //定义传入后端的数据
          let deleteData = {
            id: this.originalBillData.costBillsInvoice.id,
            reason: this.deleteData,
            statusId: this.originalBillData.costBillsInvoice.statusId
          }
          // 发送请求
          costApi.deleteInvoice(deleteData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '单据作废成功!',
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        } else if (this.billsTypeSign == 9) { //对账
          //定义传入后端的数据
          let deleteData = {
            id: this.originalBillData.costAccountCheck.id,
            reason: this.deleteData,
            statusId: this.originalBillData.costAccountCheck.statusId,
            costBillsMainName: this.originalBillData.costAccountRelevance.billingTypeId
          }
          // 发送请求
          costApi.deleteCostCheckBillsMain(deleteData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '单据作废成功!',
                duration: this.elDuration
              })
              //关闭弹框
              this.colse_createBox();;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        }
      },

      /* 对账确认方法 */
      updateBillSubmitCheck() {
        let checkData = {
          billsCheckId: this.originalBillData.costAccountCheck.id, //对账单id
        }
        // 请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '对账确认中,请稍候...');
        costApi.updateBillSubmitCheck(checkData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '单据对账成功!',
              duration: this.elDuration
            })
            //关闭弹框
            this.colse_createBox();;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 判断操作按钮是否显示(0:生成单据 1:提交审批 2:保存并审核  3:清空  4:取消  5:保存 6:审批  7:驳回  8:作废  9:修改  10:对账确认) */
      judgeShowOperateBtn(type) {
        let result = true;
        if (type == 0 || type == 1 || type == 3) { //生成单据,提交审批,清空(新增时显示)
          if (this.operateType != 0) {
            result = false;
          }
        } else if (type == 2) { //保存并审核(新增界面且当前登录用户有审核权限)
          if (JSON.stringify(this.nodeBaseInfo) != "{}") {
            // 判断当前界面
            if (this.operateType == 0) { //新增界面
              result = this.judgeUserHasCheckPower();
            } else { //修改,预览界面
              result = false;
            }
          }
        } else if (type == 4) { //取消(都会显示)
        } else if (type == 5) { //保存(修改时显示)
          if (this.operateType != 1) {
            result = false;
          }
        } else if (type == 6) { //审批
          // 判断当前界面
          if (this.operateType == 0) { //新增界面
            result = false;
          } else { //修改,预览界面
            //判断是否有审核权限
            if (this.judgeUserHasCheckPower()) { //有审核权限
              // 判断单据是否已审核
              if ([1, 3].includes(this.curBillsState)) { //(审核中,复审中)显示
                result = true;
              } else {
                result = false;
              }
            } else { //无审核权限
              result = false;
            }
          }
        } else if (type == 7) { //驳回
          // 判断当前界面
          if (this.operateType == 0) { //新增界面
            result = false;
          } else { //修改,预览界面
            // 判断单据是否已审核
            if ([1, 3].includes(this.curBillsState)) { //(审核中,复审中)显示
              result = true;
            } else {
              result = false;
            }
          }
        } else if (type == 8) { //作废
          // 判断当前界面
          if (this.operateType == 0) { //新增界面
            result = false;
          } else { //修改,预览界面
            // 判断单据是否已审核
            if (this.curBillsState < 4) { //(未审核)显示
              result = true;
            } else {
              result = false;
            }
          }
        } else if (type == 9) { //修改
          // 判断当前界面
          if (this.operateType == 0 || this.operateType == 1) { //新增,修改界面
            result = false;
          } else { //预览界面
            //判断单据模块
            if (this.billsTypeSign == 9) { //对账单模块不显示
              result = false;
            } else {
              // 判断单据是否已审核
              if (this.curBillsState < 4) { //(未审核)显示
                result = true;
              } else {
                result = false;
              }
            }
          }
        } else if (type == 10) { //对账确认
          //判断单据模块
          if (this.billsTypeSign == 9) { //对账模块
            //判断当前界面
            if (this.operateType == 0 || this.operateType == 1) { //新增,修改界面
              result = false;
            } else { //预览界面
              // 判断单据是否已审核
              if (this.curBillsState == 4) { //(已对账)显示
                result = true;
              } else {
                result = false;
              }
            }
          } else { //其它模块
            result = false;
          }
        }
        return result;
      },

      /* 判断当前登录用户是否有审核权限 */
      judgeUserHasCheckPower() {
        //定义返回值
        let result = true;
        //判断权限
        let hasPower = 0;
        //判断节点中最高审核人权限
        if (this.nodeBaseInfo.is_recheck == 1 && this.nodeBaseInfo.highLevelUser && this.nodeBaseInfo
          .highLevelUser.indexOf(this.UserInfo.user_name) != -1) {
          hasPower = hasPower + 1;
        }
        // 判断节点中是否有权限
        if (this.nodeBaseInfo.chargeUser && this.nodeBaseInfo.chargeUser.indexOf(this.UserInfo.user_name) != -1) {
          hasPower = hasPower + 1;
        }
        //判断单据类型中是否有权限
        if (this.bills_type_data.check_user_name && this.bills_type_data.check_user_name.indexOf(this.UserInfo
            .user_name) != -1) {
          hasPower = hasPower + 1;
        }
        //判断是否显示按钮
        if (hasPower == 0) {
          result = false;
        }
        return result;
      },

      /* 提交当前节点已填数据(发送请求) type(0:直接提交（需要验证工时信息)  1:填写工时后提交(不需要验证工时信息)*/
      commitNodeData(data, type) {
        // 判断所有数据是否都合法
        for (let i in data) {
          if (!this.nodeTargetObj[i]) {
            return
          }
        }
        //根据不同单据类型处理不同业务
        if (!this.disposeBillsTypeBusiness()) {
          return
        }
        //当提交为保存并审核时判断工时类型(若为员工填写则需填写下节点时长)
        if (this.commitOperate == 2 && this.nextNodeBaseInfo.working_type == 0 && type == 0) { //保存并审核
          this.show_hourseBox = true;
          return
        } else {
          this.show_hourseBox = false; //关闭弹框
        }
        //判断处理哪种业务
        if (this.nodeTypeCode == "SYS-NODE-TYPE-001") { //销售模块处理业务
          this.saleModelBusiness();
        } else if (this.nodeTypeCode == "SYS-NODE-TYPE-002") { //采购模块处理业务
          this.purchaseModelBusiness();
        } else if (this.nodeTypeCode == "SYS-NODE-TYPE-003") { //生产模块处理业务
          this.productModelBusiness();
        } else if (this.nodeTypeCode == "SYS-NODE-TYPE-004") { //库存模块处理业务
          this.depotModelBusiness();
        } else if (this.nodeTypeCode == "SYS-NODE-TYPE-005") { //资金模块处理业务
          if (this.billsTypeSign == 5) { //收付款模块
            this.costModelBusiness();
          } else if (this.billsTypeSign == 6) { //报销模块
            this.costExpenceBusiness();
          } else if (this.billsTypeSign == 8) { //发票模块
            this.costInvoiceBusiness();
          } else if (this.billsTypeSign == 9) { //对账单模块
            this.costCheckBusiness();
          }
        }
      },

      /* 根据不同单据类型处理不同业务 */
      disposeBillsTypeBusiness() {
        let result = true;
        if (this.billsTypeSign == 2) { //库存模块
          // 判断单据类型
          if (this.bills_type_data.code == "DOC-M-015") { //成品入库单
            // 判断企业权限设置是否必须关联单据
            if (this.companyparamlist.param260 == "0") { //可直接入库
              // console.log("不需要处理");
            } else if (this.companyparamlist.param260 == "1") { //必须引入生产单
              //判断是否有关联单据字段
              if (this.nodeValData.baseData.hasOwnProperty('relevance_bills')) {
                //判断是否引入生产单
                if (this.bills_relvence_sign != 1 && this.nodeValData.baseData.relevance_bills == "") {
                  this.$message({
                    type: 'warning',
                    message: '系统已设置当前单据类型必须引入生产单据!',
                    duration: this.elDuration
                  })
                  result = false;
                }
              }
            }
          } else if (this.bills_type_data.code == "DOC-P-003") { //采购入库单
            // if (this.companyparamlist.param283 == "1") { //必须引入生成单
            //   //判断是否有关联单据字段
            //   if (this.nodeValData.baseData.hasOwnProperty('relevance_bills')) {
            //     //判断是否引入采购订单
            //     if (this.bills_relvence_sign != 3 && this.nodeValData.baseData.relevance_bills == "") {
            //       this.$message({
            //         type: 'warning',
            //         message: '系统已设置当前单据类型必须引入采购订单!',
            //         duration: this.elDuration
            //       })
            //       result = false;
            //     }
            //   }
            // }
          }
        }
        return result;
      },

      /* 销售模块业务 */
      saleModelBusiness() { //生成单据,提交审核,保存并审核业务
        //定义销售模块特有单据字段
        let speSaleData = {
          sale_user_id: '', //业务用户id(客户所属员工id)
          sale_user_name: '', //业务用户名称(客户所属员工名称)
          sign: this.bills_relvence_sign, //单据关联标识(用于区分关联单据)
          linkman_id: this.nodeValData.consigneeData.linkmanId, //联系人id
          is_order: this.billsClassify, //是否为订单(0:否 1:是)
          money: this.nodeValData.tableAllData.totalData, //应收金额
          discount: this.nodeValData.tableAllData.specialAmount, //优惠金额
          create_user_id: this.nodeValData.auditorData.create_user_id, //制单用户id
          create_user_name: this.nodeValData.auditorData.create_user_name, //制单用户名称
        }
        // 将speSaleData数据字段基本信息对象
        for (let i in speSaleData) {
          this.nodeValData.baseData[i] = speSaleData[i]; //id形式
        }
        //获取需要名称的信息字段
        let baseData = this.commonJsExtend.getBaseNameData(this.nodeValData.baseData, this.nodeValData.baseNameData);
        // 定义新增销售单据对象
        let saleData = {
          billingName: this.nodeValData.baseNameData.billing_type_id, //单据类型名称
          saleOrderMain: JSON.stringify(baseData), //基本信息
          nodeCustomValue: JSON.stringify(this.nodeValData.baseCusData), //表单自定义基本信息
          productInfos: JSON.stringify(this.nodeValData.nodeTable), //产品信息
          saleMaterial: JSON.stringify(this.nodeValData.craftOfferData.saleMaterial), //生产工艺物料明细
          saleProcess: JSON.stringify(this.nodeValData.craftOfferData.saleProcess), //生产工艺工序明细
          files: JSON.stringify(this.nodeValData.fileData), //附件信息
          remark: JSON.stringify(this.nodeValData.remarkData), //备注信息
          processId: this.currWorkFlow.id, //流程id
          nodeHours: JSON.stringify(this.nodeValData.baseHourse), //工时信息
          addType: this.generateBillsId, //添加类型(-1:新增)
        }
        // 判断动态信息是否需要传入后端
        let judgeData = this.judgeGetOtherData();
        // 将所需关联信息字段存入后端数据
        for (let i in judgeData) {
          saleData[i] = judgeData[i]
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '新增数据中,请稍候...');
        //发送请求
        sellApi.addSaleOrderMain(saleData).then(res => {
          this.loading.close(); //关闭loading框
          if (res.code == 200) { //添加数据成功
            // 判断是哪种操作类型
            if (this.commitOperate == 0) { //生成单据
              let exportData = {
                id: res.data, //单据id
                mould_id: this.bills_type_data.electronic_template_id, //模板id
              }
              // 发送导出请求
              sellApi.generateOrderExport(exportData).then(res => {
                if (res.code == 200) {
                  //关闭弹框
                  this.colse_createBox();;
                  window.open(baseUrl + res.data)
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else if (this.commitOperate == 1) { //提交审核
              //定义传入后端的值
              let submitData = {
                billingName: this.nodeValData.baseNameData.billing_type_id, //单据类型名称
                isSubmit: 1, //状态
                order_id: res.data, //销售模块单据id
                processId: this.currWorkFlow.id,
                status_id: this.currNodeId, //节点id
              }
              // 发送提交请求
              sellApi.updateOrderSubmit(submitData).then(res => {
                if (res.code == 200) {
                  //关闭弹框
                  this.colse_createBox();;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else if (this.commitOperate == 2) { //保存并审核
              //定义传入后端的值
              let submitData = {
                //基本数据
                billingName: this.nodeValData.baseNameData.billing_type_id, //	单据类型名称
                relevanceId: res.data, //单据id
                statusId: this.currNodeId, //节点id
                processId: this.currWorkFlow.id, //流程id
                //通过信息
                workingHours: 0, //工时时长
                workingHoursType: 0, //工时单位
                workingType: 0, //工时类型
                isFinish: 0, //是否完成
              }
              // 发送提交审核请求
              sellApi.updateOrderStatusYes(submitData).then(res => {
                if (res.code == 200) {
                  //关闭弹框
                  this.colse_createBox();;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                  //关闭弹框
                  this.colse_createBox();;
                }
              })
            }
            //判断是否需要添加其他费用信息
            this.judgeAddExpenseBillsData(res.data);
          } else { //数据添加失败
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 采购模块业务 */
      purchaseModelBusiness() {
        //采购模块主表特有字段
        let spePurchaseData = {
          purchase_classify: this.billsClassify, //采购单分类
          sign: this.bills_relvence_sign, //单据关联标识(用于区分关联单据)
          create_user_id: this.nodeValData.auditorData.create_user_id, //制单用户id
          create_user_name: this.nodeValData.auditorData.create_user_name, //制单用户名称
          money: this.nodeValData.tableAllData.totalAmount, //采购总金额
        }
        // 将speDepotData数据字段基本信息对象
        for (let i in spePurchaseData) {
          this.nodeValData.baseData[i] = spePurchaseData[i]; //id形式
        }
        //获取需要名称的信息字段
        let baseData = this.commonJsExtend.getBaseNameData(this.nodeValData.baseData, this.nodeValData.baseNameData);
        // 定义新增采购单据对象
        let purchaseData = {
          billingName: this.nodeValData.baseNameData.billing_type_id, //单据类型名称
          purchaseMain: JSON.stringify(baseData), //	基本信息
          nodeCustomValue: JSON.stringify(this.nodeValData.baseCusData), //系统节点自定义表单值
          productInfos: JSON.stringify(this.nodeValData.nodeTable), //采购相关-产品关联
          nodeHours: JSON.stringify(this.nodeValData.baseHourse), //工时信息
          processId: this.currWorkFlow.id, //流程id
          files: JSON.stringify(this.nodeValData.fileData), //附件
          remark: JSON.stringify(this.nodeValData.remarkData), //备注信息
        }
        // 判断动态关联信息是否需要传入后端
        let judgeData = this.judgeGetOtherData();
        // 将所需关联信息字段存入后端数据
        for (let i in judgeData) {
          purchaseData[i] = judgeData[i]
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '新增数据中,请稍候...');
        //发送请求
        purchaseApi.addPurchaseOrderMain(purchaseData).then(res => {
          this.loading.close(); //关闭loading框
          if (res.code == 200) { //添加数据成功
            if (this.commitOperate == 0) { //生成单据
              let exportData = {
                id: res.data, //单据id
                mould_id: this.bills_type_data.electronic_template_id, //模板id
              }
              //关闭弹框
              this.colse_createBox();;
              // 发送导出请求
              purchaseApi.addPurchaseMainExport(exportData).then(res => {
                if (res.code == 200) {
                  //关闭弹框
                  this.colse_createBox();;
                  window.open(baseUrl + res.data)
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else if (this.commitOperate == 1) { //提交审核
              //定义传入后端的值
              let submitData = {
                billingName: this.nodeValData.baseNameData.billing_type_id, //单据类型名称
                isSubmit: 1, //状态
                purchaseMainId: res.data, //采购模块单据id
                processId: this.currWorkFlow.id, //当前流程id
                statusId: this.currNodeId, //节点id
              }
              // 发送提交请求
              purchaseApi.updatePurchaseMainSubmit(submitData).then(res => {
                if (res.code == 200) {
                  this.$message({
                    type: 'success',
                    message: "数据添加成功",
                    duration: this.elDuration
                  })
                  //关闭弹框
                  this.colse_createBox();;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else if (this.commitOperate == 2) { //保存并审核
              //定义传入后端的值
              let submitData = {
                //基本数据
                billingName: this.nodeValData.baseNameData.billing_type_id, //	单据类型名称
                relevanceId: res.data, //单据id
                statusId: this.currNodeId, //节点id
                processId: this.currWorkFlow.id, //流程id
                //通过信息
                workingHours: 0, //工时时长
                workingHoursType: 0, //工时单位
                workingType: 0, //工时类型
                isFinish: 0, //是否完成
              }
              // 发送提交审核请求
              purchaseApi.updatePurchaseMainStatusYes(submitData).then(res => {
                if (res.code == 200) {
                  //关闭弹框
                  this.colse_createBox();;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                  //关闭弹框
                  this.colse_createBox();;
                }
              })
            }
          } else { //数据添加失败
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 生产模块处理业务 */
      productModelBusiness() { //生成单据,提交审核,保存并审核业务
        //定义生产模块特有单据字段
        let speProductData = {
          create_user_id: this.nodeValData.auditorData.create_user_id, //制单用户id
          create_user_name: this.nodeValData.auditorData.create_user_name, //制单用户名称
        }
        // 将speDepotData数据字段基本信息对象
        for (let i in speProductData) {
          this.nodeValData.baseData[i] = speProductData[i]; //id形式
        }
        //获取需要名称的信息字段
        let baseData = this.commonJsExtend.getBaseNameData(this.nodeValData.baseData, this.nodeValData.baseNameData);
        // 定义新增生产单据对象
        let productData = {
          billingName: this.nodeValData.baseNameData.billing_type_id, //单据类型名称
          mesMain: JSON.stringify(baseData), //	基本信息
          nodeCustomValue: JSON.stringify(this.nodeValData.baseCusData), //系统节点自定义表单值
          mesMaterial: JSON.stringify(this.nodeValData.nodeTable), //物料信息
          mesProcess: JSON.stringify(this.nodeValData.tableAllData.mesProcessData), //工序信息
          nodeHours: JSON.stringify(this.nodeValData.baseHourse), //工时信息
          processId: this.currWorkFlow.id, //流程id
          // files:JSON.stringify(this.nodeValData.fileData),//附件
          // remark:JSON.stringify(this.nodeValData.remarkData),//备注信息
        }
        // 判断动态关联信息是否需要传入后端
        let judgeData = this.judgeGetOtherData();
        // 将所需关联信息字段存入后端数据
        for (let i in judgeData) {
          productData[i] = judgeData[i]
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '新增数据中,请稍候...');
        //发送请求
        productApi.addMesMain(productData).then(res => {
          this.loading.close(); //关闭loading框
          if (res.code == 200) { //添加数据成功
            if (this.commitOperate == 0) { //生成单据
              let exportData = {
                id: res.data, //单据id
                mould_id: this.bills_type_data.electronic_template_id, //模板id
              }
              //关闭弹框
              this.colse_createBox();;
              // 发送导出请求
              // productApi.generateOrderExport(exportData).then(res=>{
              //   if(res.code == 200){
              //     //关闭弹框
              this.colse_createBox();;
              //     window.open(baseUrl+res.data)
              //   }else{
              //     this.$message({
              //       type:'error',
              //       message:res.message,
              //       duration:this.elDuration
              //     })
              //   }
              // })
            } else if (this.commitOperate == 1) { //提交审核
              //定义传入后端的值
              let submitData = {
                billingName: this.nodeValData.baseNameData.billing_type_id, //单据类型名称
                isSubmit: 1, //状态
                relevanceId: res.data, //生产模块单据id
                processId: this.currWorkFlow.id, //当前流程id
                statusId: this.currNodeId, //节点id
              }
              // 发送提交请求
              productApi.updateMesMainStatusIsSubmit(submitData).then(res => {
                if (res.code == 200) {
                  this.$message({
                    type: 'success',
                    message: "数据添加成功",
                    duration: this.elDuration
                  })
                  //关闭弹框
                  this.colse_createBox();;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else if (this.commitOperate == 2) { //保存并审核
              //定义传入后端的值
              let submitData = {
                //基本数据
                billingName: this.nodeValData.baseNameData.billing_type_id, //	单据类型名称
                relevanceId: res.data, //单据id
                statusId: this.currNodeId, //节点id
                processId: this.currWorkFlow.id, //流程id
                //通过信息
                workingHours: 0, //工时时长
                workingHoursType: 0, //工时单位
                workingType: 0, //工时类型
                isFinish: 0, //是否完成
              }
              // 发送提交审核请求
              productApi.updateMesMainStatusYes(submitData).then(res => {
                if (res.code == 200) {
                  //关闭弹框
                  this.colse_createBox();;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                  //关闭弹框
                  this.colse_createBox();;
                }
              })
            }
          } else { //数据添加失败
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 库存模块处理业务 */
      depotModelBusiness() { //生成单据,提交审核,保存并审核业务
        //定义库存模块特有单据字段
        let speDepotData = {
          depot_classify: this.billsClassify, //出入库单据分类
          outin_depot_sign: this.bills_type_data.outin_sign, //出入库标识
          sign: this.bills_relvence_sign, //单据关联标识(用于区分关联单据)
          create_user_id: this.nodeValData.auditorData.create_user_id, //制单用户id
          create_user_name: this.nodeValData.auditorData.create_user_name, //制单用户名称
          total_money: this.nodeValData.tableAllData.totalAmount, //单据成本总金额
          total_price_money: this.nodeValData.tableAllData.totalPriceAmount, //单据销售总金额
        }
        // 将speDepotData数据字段基本信息对象
        for (let i in speDepotData) {
          this.nodeValData.baseData[i] = speDepotData[i]; //id形式
        }
        //获取需要名称的信息字段
        let baseData = this.commonJsExtend.getBaseNameData(this.nodeValData.baseData, this.nodeValData.baseNameData);
        // 定义新增库存单据对象
        let depotData = {
          billingName: this.nodeValData.baseNameData.billing_type_id, //单据类型名称
          depotMain: JSON.stringify(baseData), //	基本信息
          nodeCustomValue: JSON.stringify(this.nodeValData.baseCusData), //系统节点自定义表单值
          depotMainProNum: JSON.stringify(this.nodeValData.nodeTable), //出入库单-产品关联
          nodeHours: JSON.stringify(this.nodeValData.baseHourse), //工时信息
          processId: this.currWorkFlow.id, //流程id
          files: JSON.stringify(this.nodeValData.fileData), //附件
          remark: JSON.stringify(this.nodeValData.remarkData), //备注信息
        }
        // 判断动态关联信息是否需要传入后端
        let judgeData = this.judgeGetOtherData();
        // 将所需关联信息字段存入后端数据
        for (let i in judgeData) {
          depotData[i] = judgeData[i]
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '新增数据中,请稍候...');
        //发送请求
        stockApi.addDepotMain(depotData).then(res => {
          this.loading.close(); //关闭loading框
          if (res.code == 200) { //添加数据成功
            if (this.commitOperate == 0) { //生成单据
              let exportData = {
                id: res.data, //单据id
                mould_id: this.bills_type_data.electronic_template_id, //模板id
              }
              //关闭弹框
              this.colse_createBox();;
              // 发送导出请求
              stockApi.addDepotMainExport(exportData).then(res => {
                if (res.code == 200) {
                  //关闭弹框
                  this.colse_createBox();;
                  window.open(baseUrl + res.data)
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else if (this.commitOperate == 1) { //提交审核
              //定义传入后端的值
              let submitData = {
                billingName: this.nodeValData.baseNameData.billing_type_id, //单据类型名称
                isSubmit: 1, //状态
                depotMainId: res.data, //库存模块单据id
                processId: this.currWorkFlow.id, //当前流程id
                statusId: this.currNodeId, //节点id
              }
              // 发送提交请求
              stockApi.updateDepotMainSubmit(submitData).then(res => {
                if (res.code == 200) {
                  this.$message({
                    type: 'success',
                    message: "数据添加成功",
                    duration: this.elDuration
                  })
                  //关闭弹框
                  this.colse_createBox();;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else if (this.commitOperate == 2) { //保存并审核
              //定义传入后端的值
              let submitData = {
                //基本数据
                billingName: this.nodeValData.baseNameData.billing_type_id, //	单据类型名称
                relevanceId: res.data, //单据id
                statusId: this.currNodeId, //节点id
                processId: this.currWorkFlow.id, //流程id
                //通过信息
                workingHours: 0, //工时时长
                workingHoursType: 0, //工时单位
                workingType: 0, //工时类型
                isFinish: 0, //是否完成
              }
              // 发送提交审核请求
              stockApi.updateStatusYes(submitData).then(res => {
                if (res.code == 200) {
                  //关闭弹框
                  this.colse_createBox();;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                  //关闭弹框
                  this.colse_createBox();;
                }
              })
            }
          } else { //数据添加失败
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 收付款单处理业务 */
      costModelBusiness() { //生成单据,提交审核,保存并审核业务
        console.log(this.bills_relvence_sign);
        //定义收付款模块特有单据字段
        let speCostData = {
          create_user_id: this.nodeValData.auditorData.create_user_id, //制单用户id
          create_user_name: this.nodeValData.auditorData.create_user_name, //制单用户名称
          cost_classify: this.billsClassify, //资金单据分类
          outin_sign: this.bills_type_data.outin_sign, //收支标识
          sign: this.bills_relvence_sign, //单据关联标识(用于区分关联单据)
          should_amount: this.nodeValData.tableAllData.totalShould, //本次应收总金额
          reality_amount: this.nodeValData.tableAllData.totalReality, //本次实收总金额
          discount_amount: this.nodeValData.tableAllData.totalDiscount, //本次折扣总金额
        }
        // 将speCostData数据字段基本信息对象
        for (let i in speCostData) {
          this.nodeValData.baseData[i] = speCostData[i]; //id形式
        }
        //获取需要名称的信息字段
        let baseData = this.commonJsExtend.getBaseNameData(this.nodeValData.baseData, this.nodeValData.baseNameData);
        // 定义新增库存单据对象
        let costData = {
          billingName: this.nodeValData.baseNameData.billing_type_id, //单据类型名称
          costBillsMain: JSON.stringify(baseData), //	基本信息
          nodeCustomValue: JSON.stringify(this.nodeValData.baseCusData), //系统节点自定义表单值
          costBillsSubItem: JSON.stringify(this.nodeValData.nodeTable), //收付款单-单据
          nodeHours: JSON.stringify(this.nodeValData.baseHourse), //工时信息
          processId: this.currWorkFlow.id, //流程id
          files: JSON.stringify(this.nodeValData.fileData), //附件
          remark: JSON.stringify(this.nodeValData.remarkData), //备注信息
        }
        // 判断动态关联信息是否需要传入后端
        let judgeData = this.judgeGetOtherData();
        // 将所需关联信息字段存入后端数据
        for (let i in judgeData) {
          costData[i] = judgeData[i]
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '新增数据中,请稍候...');
        //发送请求
        costApi.addCostBillsMain(costData).then(res => {
          this.loading.close(); //关闭loading框
          if (res.code == 200) { //添加数据成功
            if (this.commitOperate == 0) { //生成单据
              let exportData = {
                id: res.data, //单据id
                mould_id: this.bills_type_data.electronic_template_id, //模板id
              }
              //关闭弹框
              this.colse_createBox();;
              // 发送导出请求
              costApi.addCostBillsMainExport(exportData).then(res => {
                if (res.code == 200) {
                  //关闭弹框
                  this.colse_createBox();;
                  window.open(baseUrl + res.data)
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else if (this.commitOperate == 1) { //提交审核
              //定义传入后端的值
              let submitData = {
                billingName: this.nodeValData.baseNameData.billing_type_id, //单据类型名称
                isSubmit: 1, //状态
                costBillsMainId: res.data, //收付款模块单据id
                processId: this.currWorkFlow.id, //当前流程id
                statusId: this.currNodeId, //节点id
              }
              // 发送提交请求
              costApi.updateCostBillsMainSubmit(submitData).then(res => {
                if (res.code == 200) {
                  this.$message({
                    type: 'success',
                    message: "数据添加成功",
                    duration: this.elDuration
                  })
                  //关闭弹框
                  this.colse_createBox();;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else if (this.commitOperate == 2) { //保存并审核
              //定义传入后端的值
              let submitData = {
                //基本数据
                billingName: this.nodeValData.baseNameData.billing_type_id, //	单据类型名称
                relevanceId: res.data, //单据id
                statusId: this.currNodeId, //节点id
                processId: this.currWorkFlow.id, //流程id
                //通过信息
                workingHours: 0, //工时时长
                workingHoursType: 0, //工时单位
                workingType: 0, //工时类型
                isFinish: 0, //是否完成
              }
              // 发送提交审核请求
              costApi.updateCostBillsMainStatusYes(submitData).then(res => {
                if (res.code == 200) {
                  //关闭弹框
                  this.colse_createBox();;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                  //关闭弹框
                  this.colse_createBox();;
                }
              })
            }
          } else { //数据添加失败
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 报销单处理业务 */
      costExpenceBusiness() { //生成单据,提交审核,保存并审核业务
        //定义收付款模块特有单据字段
        let speCostData = {
          cost_classify: this.billsClassify, //报销单据分类
          create_user_id: this.nodeValData.auditorData.create_user_id, //制单用户id
          create_user_name: this.nodeValData.auditorData.create_user_name, //制单用户名称
          total_amount: this.nodeValData.tableAllData.totalAmount, //报销总金额
        }
        // 将speCostData数据字段基本信息对象
        for (let i in speCostData) {
          this.nodeValData.baseData[i] = speCostData[i]; //id形式
        }
        //获取需要名称的信息字段
        let baseData = this.commonJsExtend.getBaseNameData(this.nodeValData.baseData, this.nodeValData.baseNameData);
        // 定义新增报销单据对象
        let costData = {
          billingName: this.nodeValData.baseNameData.billing_type_id, //单据类型名称
          costBillsExpense: JSON.stringify(baseData), //	基本信息
          nodeCustomValue: JSON.stringify(this.nodeValData.baseCusData), //系统节点自定义表单值
          costBillsExpenseSubItem: JSON.stringify(this.nodeValData.nodeTable), //报销单明细
          nodeHours: JSON.stringify(this.nodeValData.baseHourse), //工时信息
          processId: this.currWorkFlow.id, //流程id
        }
        // 判断动态关联信息是否需要传入后端
        let judgeData = this.judgeGetOtherData();
        // 将所需关联信息字段存入后端数据
        for (let i in judgeData) {
          costData[i] = judgeData[i]
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '新增数据中,请稍候...');
        //发送请求
        costApi.addBillsExpense(costData).then(res => {
          this.loading.close(); //关闭loading框
          if (res.code == 200) { //添加数据成功
            if (this.commitOperate == 0) { //生成单据
              let exportData = {
                id: res.data, //单据id
                mould_id: this.bills_type_data.electronic_template_id, //模板id
              }
              //关闭弹框
              this.colse_createBox();;
              // 发送导出请求
              // costApi.generateOrderExport(exportData).then(res=>{
              //   if(res.code == 200){
              //     //关闭弹框
              this.colse_createBox();;
              //     window.open(baseUrl+res.data)
              //   }else{
              //     this.$message({
              //       type:'error',
              //       message:res.message,
              //       duration:this.elDuration
              //     })
              //   }
              // })
            } else if (this.commitOperate == 1) { //提交审核
              //定义传入后端的值
              let submitData = {
                billingName: this.nodeValData.baseNameData.billing_type_id, //单据类型名称
                isSubmit: 1, //状态
                billsExpenseId: res.data, //报销单模块单据id
                processId: this.currWorkFlow.id, //当前流程id
                statusId: this.currNodeId, //节点id
              }
              // 发送提交请求
              costApi.updateBillsExpenseSubmit(submitData).then(res => {
                if (res.code == 200) {
                  this.$message({
                    type: 'success',
                    message: "数据添加成功",
                    duration: this.elDuration
                  })
                  //关闭弹框
                  this.colse_createBox();;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else if (this.commitOperate == 2) { //保存并审核
              //定义传入后端的值
              let submitData = {
                //基本数据
                billingName: this.nodeValData.baseNameData.billing_type_id, //	单据类型名称
                relevanceId: res.data, //单据id
                statusId: this.currNodeId, //节点id
                processId: this.currWorkFlow.id, //流程id
                //通过信息
                workingHours: 0, //工时时长
                workingHoursType: 0, //工时单位
                workingType: 0, //工时类型
                isFinish: 0, //是否完成
              }
              // 发送提交审核请求
              costApi.updateBillsExpenseStatusYes(submitData).then(res => {
                if (res.code == 200) {
                  //关闭弹框
                  this.colse_createBox();;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            }
          } else { //数据添加失败
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 发票单处理业务 */
      costInvoiceBusiness() { //生成单据,提交审核,保存并审核业务
        //定义发票模块特有单据字段
        let speCostData = {
          invoice_classify: this.billsClassify, //发票单据分类
          sign: this.bills_relvence_sign, //单据关联标识(用于区分关联单据)
          create_user_id: this.nodeValData.auditorData.create_user_id, //制单用户id
          create_user_name: this.nodeValData.auditorData.create_user_name, //制单用户名称
          total_amount: this.nodeValData.tableAllData.totalAmount, //发票总金额
        }
        // 将speCostData数据字段基本信息对象
        for (let i in speCostData) {
          this.nodeValData.baseData[i] = speCostData[i]; //id形式
        }
        //获取需要名称的信息字段
        let baseData = this.commonJsExtend.getBaseNameData(this.nodeValData.baseData, this.nodeValData.baseNameData);
        // 定义新增报销单据对象
        let costData = {
          billingName: this.nodeValData.baseNameData.billing_type_id, //单据类型名称
          costBillsInvoice: JSON.stringify(baseData), //	基本信息
          nodeCustomValue: JSON.stringify(this.nodeValData.baseCusData), //系统节点自定义表单值
          costBillsInvoiceSubitem: JSON.stringify(this.nodeValData.nodeTable), //报销单明细
          nodeHours: JSON.stringify(this.nodeValData.baseHourse), //工时信息
          processId: this.currWorkFlow.id, //流程id
          files: JSON.stringify(this.nodeValData.fileData), //附件
          remark: JSON.stringify(this.nodeValData.remarkData), //备注信息
        }
        // 判断动态关联信息是否需要传入后端
        let judgeData = this.judgeGetOtherData();
        // 将所需关联信息字段存入后端数据
        for (let i in judgeData) {
          costData[i] = judgeData[i]
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '新增数据中,请稍候...');
        //发送请求
        costApi.addCostBillsInvoice(costData).then(res => {
          this.loading.close(); //关闭loading框
          if (res.code == 200) { //添加数据成功
            if (this.commitOperate == 0) { //生成单据
              let exportData = {
                id: res.data, //单据id
                mould_id: this.bills_type_data.electronic_template_id, //模板id
              }
              //关闭弹框
              this.colse_createBox();;
              // 发送导出请求
            } else if (this.commitOperate == 1) { //提交审核
              //定义传入后端的值
              let submitData = {
                billingName: this.nodeValData.baseNameData.billing_type_id, //单据类型名称
                isSubmit: 1, //状态
                invoiceId: res.data, //报销单模块单据id
                statusId: this.currNodeId, //节点id
              }
              // 发送提交请求
              costApi.updateInvoiceSubmit(submitData).then(res => {
                if (res.code == 200) {
                  this.$message({
                    type: 'success',
                    message: "数据添加成功",
                    duration: this.elDuration
                  })
                  //关闭弹框
                  this.colse_createBox();;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else if (this.commitOperate == 2) { //保存并审核
              //定义传入后端的值
              let submitData = {
                //基本数据
                billingName: this.nodeValData.baseNameData.billing_type_id, //	单据类型名称
                relevanceId: res.data, //单据id
                statusId: this.currNodeId, //节点id
                processId: this.currWorkFlow.id, //流程id
                //通过信息
                workingHours: 0, //工时时长
                workingHoursType: 0, //工时单位
                workingType: 0, //工时类型
                isFinish: 0, //是否完成
              }
              // 发送提交审核请求
              costApi.updateInvoiceStatusYes(submitData).then(res => {
                if (res.code == 200) {
                  //关闭弹框
                  this.colse_createBox();;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            }
          } else { //数据添加失败
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 对账单处理业务 */
      costCheckBusiness() {
        //定义对账模块特有单据字段
        let speCostData = {
          check_classify: this.billsClassify, //对账单分类
          create_user_id: this.nodeValData.auditorData.create_user_id, //制单用户id
          create_user_name: this.nodeValData.auditorData.create_user_name, //制单用户名称
          is_return_detail: this.show_relevance.show_getcost ? 1 : 0, //是否显示退货明细
          is_cost_detail: this.show_relevance.show_returnpro ? 1 : 0, //是否显示收付款明细
          is_period_cost_bill: this.show_periodCostBills ? 1 : 0, //是否显示周期内收付款明细
          total_amount: this.nodeValData.tableAllData.totalAmount, //对账总金额
          total_tax_amount: this.nodeValData.tableAllData.totalTaxAmount, //对账总金额(含税)
        }
        // 将speCostData数据字段基本信息对象
        for (let i in speCostData) {
          this.nodeValData.baseData[i] = speCostData[i]; //id形式
        }
        //获取需要名称的信息字段
        let baseData = this.commonJsExtend.getBaseNameData(this.nodeValData.baseData, this.nodeValData.baseNameData);
        // 定义新增对账单据对象
        let costData = {
          billingName: this.nodeValData.baseNameData.billing_type_id, //单据类型名称
          costBillsAccountCheck: JSON.stringify(baseData), //	基本信息
          nodeCustomValue: JSON.stringify(this.nodeValData.baseCusData), //系统节点自定义表单值
          costBillsAccountCheckSubItem: JSON.stringify(this.nodeValData.nodeTable), //对账单明细(明细已经后端查询,该处传不传无所谓)
          costAccountCheckAmount:JSON.stringify(this.nodeValData.tableAllData.costAccountCheckAmount),//对账实际金额
          nodeHours: JSON.stringify(this.nodeValData.baseHourse), //工时信息
          processId: this.currWorkFlow.id, //流程id
          files: JSON.stringify(this.nodeValData.fileData), //附件
          remark: JSON.stringify(this.nodeValData.remarkData), //备注信息
        }
        // 判断动态关联信息是否需要传入后端
        let judgeData = this.judgeGetOtherData();
        // 将所需关联信息字段存入后端数据
        for (let i in judgeData) {
          costData[i] = judgeData[i]
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#nodeBills", 4, '新增数据中,请稍候...');
        //发送请求
        costApi.addAccountCheckBillsMain(costData).then(res => {
          this.loading.close(); //关闭loading框
          if (res.code == 200) { //添加数据成功
            //判断操作类型
            if (this.commitOperate == 0) { //生成单据
              let exportData = {
                id: res.data, //单据id
                mould_id: this.bills_type_data.electronic_template_id, //模板id
              }
              //关闭弹框
              this.colse_createBox();;
              // 发送导出请求
            } else if (this.commitOperate == 1) { //提交审核
              //定义传入后端的值
              let submitData = {
                billingName: this.nodeValData.baseNameData.billing_type_id, //单据类型名称
                billsCheckId: res.data, //报销单模块单据id
                statusId: this.currNodeId, //节点id
                processId: this.currWorkFlow.id, //流程id
              }
              // 发送提交请求
              costApi.updateCheckSubmit(submitData).then(res => {
                if (res.code == 200) {
                  this.$message({
                    type: 'success',
                    message: "数据添加成功",
                    duration: this.elDuration
                  })
                  //关闭弹框
                  this.colse_createBox();;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else if (this.commitOperate == 2) { //保存并审核
              //定义传入后端的值
              let submitData = {
                //基本数据
                billingName: this.nodeValData.baseNameData.billing_type_id, //	单据类型名称
                relevanceId: res.data, //单据id
                statusId: this.currNodeId, //节点id
                processId: this.currWorkFlow.id, //流程id
                //通过信息
                workingHours: 0, //工时时长
                workingHoursType: 0, //工时单位
                workingType: 0, //工时类型
                isFinish: 0, //是否完成
              }
              // 发送提交审核请求
              costApi.updateCheckSubmitYes(submitData).then(res => {
                if (res.code == 200) {
                  //关闭弹框
                  this.colse_createBox();;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            }
            //修改合作伙伴物料编号
            stockApi.updateDepotMainProNumPartner(this.nodeValData.tableAllData.updatePartnerCodeObj).then(res1 => {
              if (res1.code != 200) {
                this.$message({
                  type: 'error',
                  message: res1.message,
                  duration: this.elDuration
                })
              }
            })
            // //修改交易金额
            // if(this.billsClassify == 5){//销售对账单
            //   stockApi.updateDepotMainProNumRealityTotalPrice(this.nodeValData.tableAllData.realityAmountObj).then(res1 => {
            //     if (res1.code != 200) {
            //       this.$message({
            //         type: 'error',
            //         message: res1.message,
            //         duration: this.elDuration
            //       })
            //     }
            //   })
            // }else if(this.billsClassify == 6){//采购对账
            //   stockApi.updateDepotMainProNumRealityTotalCostPrice(this.nodeValData.tableAllData.realityAmountObj).then(res1 => {
            //     if (res1.code != 200) {
            //       this.$message({
            //         type: 'error',
            //         message: res1.message,
            //         duration: this.elDuration
            //       })
            //     }
            //   })
            // }
          } else { //数据添加失败
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 判断是否需要添加单据其他费用信息 relvanceBillsId:单据的关联id */
      judgeAddExpenseBillsData(relvanceBillsId) {
        //判断其他费用是否启用
        if (!this.show_relevance.show_expense) { //未启用不做任何操作
          return
        }
        //定义报销单模块特有单据字段
        let speCostData = {
          create_user_id: this.nodeValData.auditorData.create_user_id, //制单用户id
          create_user_name: this.nodeValData.auditorData.create_user_name, //制单用户名称
          total_amount: this.nodeValData.expenseData.totalAmount, //单据总金额
        }
        // 将speCostData数据字段基本信息对象
        for (let i in speCostData) {
          this.nodeValData.expenseData.expenseBaseData[i] = speCostData[i]; //id形式
        }
        //获取需要名称的信息字段
        let baseData = this.commonJsExtend.getBaseNameData(this.nodeValData.expenseData.expenseBaseData, this
          .nodeValData.baseNameData);
        //关联信息
        let subClassItemData = [];
        //循环获取单据关联关系
        this.nodeValData.expenseData.tableData.forEach((item, index) => {
          item.relevance_bills = relvanceBillsId;
          item.sign = this.billsTypeSign;
          subClassItemData.push(item);
        })
        //后端接受信息
        let expenseData = {
          billingName: "销售支出单", //单据类型名称
          costBillsExpense: JSON.stringify(baseData), //	基本信息
          costBillsExpenseSubItem: JSON.stringify(subClassItemData), //报销单明细
          // nodeHours: JSON.stringify(this.nodeValData.baseHourse), //工时信息
          processId: 273, //流程id
        }
        costApi.addBillsExpense(expenseData).then(res => {
          if (res.code == 200) {

          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 关闭对账单相关明细查询 */
      closeCostCheckCheckbox() {
        this.show_relevance.show_returnpro = false;
        this.show_relevance.show_getcost = false;
        this.show_relevance.show_detailcost = false;
      },

      /* 生成其他单据 */
      generateOtherBill(type) {
        //重新加载组件,不然数据位置会穿掉
        this.shouChildComponent = false;
        //处理单据转换
        let data = {};
        //判断生成的单据类型
        // 销售模块
        if (type == "0.1") { //生成销售订单
          data.type = "0";
        } else if (type == "0.2") { //生成采购申请单
          data.type = "1";
        } else if (type == "0.3") { //生成采购订单
          data.type = "4";
        } else if (type == "0.4") { //生成销售入库单
          data.type = "3";
        } else if (type == "0.5") { //生成生产计划单
          data.type = "2";
        }
        // 采购模块
        else if (type == "1.1") { //生成采购订单
          data.type = "0";
        } else if (type == "1.2") { //生成采购询价单
          data.type = "1";
        } else if (type == "1.2") { //生成采购入库单
          data.type = "2";
        }
        //获取数据源
        data.data = [this.originalBillData];
        //调用父组件方法
        this.$parent.generateOtherBill(data);
        //重新加载组件数据
        // this.judgeNodeTypeData();
      },

      /* 判断关联信息是否已选 */
      judgeGetOtherData() {
        let data = {};
        //判断是否有物流信息
        if (this.show_relevance.show_logistics) {
          data.orderTransport = JSON.stringify(this.nodeValData.logisticsData)
        }
        //判断是否有签收人信息
        if (this.show_relevance.show_consignee) {
          // data.orderSignfor = JSON.stringify(this.nodeValData.consigneeData)
          data.orderSignfor = "";
        }
        //判断是否有发票信息
        if (this.show_relevance.show_invoice) {
          data.costBillsInvoice = JSON.stringify(this.nodeValData.invoiceData)
        }
        //判断是否有付款信息
        if (this.show_relevance.show_payment) {
          data.gatherRecorder = JSON.stringify(this.nodeValData.paymentData)
        }
        //返回数据
        return data;
      },

      /* 获取物流信息组件操作后返回的状态(修改时用) data:操作后返回的数据(1:新增 2:删除)*/
      getOperateLogisticsResult(data) {
        //判断返回的操作类型
        if (data == 1) {
          //设置物流信息添加成功
          this.addUpdateState.add_logistics = true;
        } else if (data == 2) {
          //取消勾选物流弹框
          this.show_relevance.show_logistics = false;
          //删除详情物流数据
          this.originalBillData.orderTransport = null;
        }
      },

      /* 获取发票信息组件操作后返回的状态(修改时用) data:操作后返回的数据(1:新增 2:删除)*/
      getOperateInvoiceResult(data) {
        //判断返回的操作类型
        if (data == 1) {
          //设置发票信息添加成功
          this.addUpdateState.add_invoice = true;
        } else if (data == 2) {
          //取消勾选发票弹框
          this.show_relevance.show_invoice = false;
          //删除详情物流数据
          this.originalBillData.costBillsInvoice = null;
        }
      },

      /* 获取货品信息组件操作后返回的状态(修改时用) data:操作后返回的数据(1:新增 2:删除)*/
      getOperateTableData(data) {
        console.log(data);
        //判断返回的操作类型
        if (data == 1) {//新增
          //设置货品信息添加成功
          this.addUpdateState.add_table = true;
        } else if (data == 2) {
          //删除详情物流数据
          // this.originalBillData.costBillsInvoice = null;
        }
        console.log(this.addUpdateState);
      },

      /* 获取节点基本信息*/
      getNodeBaseData(data) {
        // console.log(data);
        // 获取已填基本信息数据
        this.nodeValData.baseData = data.nodeFormIdData;
        //获取已填的名称基本信息
        this.nodeValData.baseNameData = data.nodeFormNameData;
        //获取已填自定义基本信息
        this.nodeValData.baseCusData = data.nodeCusFormData;
        //获取已填工时信息
        this.nodeValData.baseHourse = data.noodeHourse;
        //设置传入后端的单据公共数据
        let defaultData = {
          bills_date: this.billsDate, //单据日期
          status_id: this.currNodeId, //当前节点id
          process_id: this.currWorkFlow.id, //当前流程id
        }
        // 将公共数据字段插入对象
        for (let i in defaultData) {
          this.nodeValData.baseData[i] = defaultData[i]; //id形式
        }
        //设置信息数据合法
        this.nodeTargetObj.nodeBase = true;
      },

      /* 获取节点收货人信息 */
      getNodeConsigneeData(data) {
        // 获取已填收货人信息数据
        this.nodeValData.consigneeData = data;
        //设置信息数据合法
        this.nodeTargetObj.nodeConsignee = true;
      },

      /* 获取节点发票信息*/
      getNodeInvoiceData(data) {
        // 获取已填发票信息数据
        this.nodeValData.invoiceData = data;
        //设置信息数据合法
        this.nodeTargetObj.nodeInvoice = true;
      },

      /* 获取节点物流信息*/
      getNodeLogisticsData(data) {
        // 获取已填物流信息数据
        this.nodeValData.logisticsData = data;
        //设置信息数据合法
        this.nodeTargetObj.nodeLogistics = true;
      },

      /* 获取节点付款信息 */
      getNodePaymentData(data) {
        // 获取已填付款信息数据
        this.nodeValData.paymentData = data;
        //设置信息数据合法
        this.nodeTargetObj.nodePayment = true;
      },

      /* 获取节点其他费用信息 */
      getNodeExpenseData(data) {
        // 获取已填其他费用数据
        this.nodeValData.expenseData = data;
        //设置信息数据合法
        this.nodeTargetObj.nodeExpense = true;
      },

      /* 获取节点附件信息 */
      getNodeFileData(data) {
        // 获取已填附件信息数据
        this.nodeValData.fileData = data;
        //设置信息数据合法
        this.nodeTargetObj.nodeFile = true;
      },

      /* 获取节点备注信息 */
      getNodeRemarkData(data) {
        // 获取已填备注信息数据
        this.nodeValData.remarkData = data;
        //设置信息数据合法
        this.nodeTargetObj.nodeRemark = true;
      },

      /* 获取节点货品表格信息*/
      getNodeTableData(data) {
        // 获取已填表格信息数据
        this.nodeValData.nodeTable = data.tableData;
        //将所有信息存入data
        this.nodeValData.tableAllData = data;
        //设置信息数据合法
        this.nodeTargetObj.nodeTable = true;
      },

      /* 获取生产模块货品表格信息 */
      getNodeMesTableData(data) {
        //将获取数据存入data
        this.nodeValData.tableAllData = data;
        // 获取已填表格信息数据
        this.nodeValData.nodeTable = data.materialData;
        //设置信息数据合法
        this.nodeTargetObj.nodeTable = true;
      },

      /* 获取对账模块对账明细表格信息 */
      getNodeCheckTableData(data) {
        //将获取数据存入data
        this.nodeValData.tableAllData = data;
        // 获取已填表格信息数据
        this.nodeValData.nodeTable = data.checkSubItem;
        //设置信息数据合法
        this.nodeTargetObj.nodeTable = true;
      },

      /* 获取生产工艺报价明细数据 */
      getCraftTableMsg(data) {
        // 获取已填表格信息数据
        this.nodeValData.craftOfferData = data;
        //设置信息数据合法
        this.nodeTargetObj.nodeCraftTable = true;
      },

      /* 初始化提交步骤 */
      initCommitFormStep() {
        console.log("进入了初始化方法");
        this.commitFormStep = -1;
      },

      /* 获取节点单据制作人信息*/
      getNodeAuditorData(data) {
        // 获取已填单据制作人信息数据
        this.nodeValData.auditorData = data;
        //设置信息数据合法
        this.nodeTargetObj.nodeAuditor = true;
      },

      /* 清空子组件数据 */
      clearChildComponent() {
        this.isClearFormData = !this.isClearFormData;
      },

      /* 关闭弹框 */
      colse_createBox() {
        this.$parent.closeNodeBills();
      },

      /* 取消工时弹框 */
      closeHourseBox() {
        this.show_hourseBox = false;
      },
    },
    destroyed() {
      //判断加载公共单据方式
      if (this.loadNodeBillsType == 0) { //正常打开
        //清空vuex单据关联数据
        this.INIT_BILLS_RELEVNCE_DATA();
      } else if (this.loadNodeBillsType == 1) { //自身上层打开
        //恢复备份数据
        this.RESTORE_BILLS_MSG();
      }
    },
    components: {
      nodeFormBase,
      nodeFormConsignee,
      nodeFormLogistics,
      nodeFormInvoice,
      nodeFormPayment,
      nodeFormExpense,
      nodeFormFile,
      nodeFormRemark,
      nodeFormAuditor,
      nodeFormGetCost,
      nodeFormReturnCost,
      nodeFormReturnPro,
      nodeFormDetailCost,
      nodeFormTotalAmount,
      nodeTableData,
      nodeMesTableData,
      nodeCheckTableData,
      nodeCheckMesTableData,
      nodeCraftOfferTableData
    }
  }
</script>

<!-- 导入单据公共样式 -->
<style lang="less" src="@/less/Comm/comm_billing.less"></style>
<style lang="less" src="@/less/commBills/nodeCheckTotal.less"></style>
<style lang="less">
  // 单据底部样式
  .nodeBillFootBox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    // border:1px solid black;
    //左边总金额信息
    .footBoxLeft {
      padding: 0 20px;
      font-size: 16px;
      font-weight: bold;
      // border:1px solid black;
    }

    //右边操作按钮
    .footBoxRight {
      // border:1px solid black;
    }
  }

  // 收付款明细框
  .costGetBox {
    display: flex;

    .checkbox {
      margin-left: 20px;
    }
  }
</style>
