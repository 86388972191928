<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：单据附件信息组件
	开始时间：2021-04-27
	开发人员：刘巍骏
	最后修改：2021-04-27
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="nodeFormItemBox">
    <div class="nodeFile">
      <el-button size="mini" :disabled="!is_edit" type="primary" @click="fileUpload = true">附件上传</el-button>
    </div>

    <!-- 附件上传弹框 -->
    <el-dialog title="附件上传" :visible.sync="fileUpload" width="500px" append-to-body>
      <div class="drag">
        <el-upload class="upload-demo" drag :action="fileUrl" :headers="headers" :before-upload="beforeUpload"
          :data="uploadData" :on-success="uploadFile" :show-file-list="false" :disabled="!is_edit">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-upload class="uploadBtn" :action="fileUrl" :headers="headers" :before-upload="beforeUpload"
          :data="uploadData" :on-success="uploadFile" :show-file-list="false" :disabled="!is_edit">
          <el-button type="primary" :disabled="!is_edit" size="small" class="fileUploadBtn">选 择 文 件</el-button>
        </el-upload>
        <el-button type="primary" @click="cloudUpload" size="small" class="fileUploadBtn">云 盘 选 择</el-button>
      </span>
    </el-dialog>

    <!-- 附件预览列表 -->
    <div class="nodeFileList">
      <div class="flieItem" v-for="(item,index) in fileData" :key="item.id">
        <div :class="'flieIcon'+ ' ' + item.file_type_icon"></div>
        <div class="flieInfo">
          <div class="name">{{item.file_name}}</div>
          <div class="fileAttr"><span class="flieSize">{{item.size}}KB</span><span
              class="process">{{item.file_status}}</span>
          </div>
        </div>
        <div class="fileOperation">
          <div class="option"><i class="el-icon-view" title="预览" @click="previewFile(item)"></i></div>
          <el-link class="option" target="_blank" :href="baseUrl+item.file_path" :underline="false"
            :download="item.file_name">
            <i class="el-icon-download" title="下载"></i>
          </el-link>
          <div class="option" v-if="operateType!=2"><i class="el-icon-delete" title="删除"
              @click="deleteFileSpan(index,item)"></i></div>
        </div>
      </div>
    </div>

    <!-- 附件预览组件 -->
    <filePreview @SelectedData="getPreviewData" :previewFilePath="previewFilePath" v-if="show_previewComponents">
    </filePreview>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入组件
  import sellApi from '@/network/sell/bills.js'; //销售模块
  import filePreview from '@/components/commComponent/dialog/file_preview.vue'; //附件预览组件

  export default {
    name: 'nodeForm_file',
    props: {
      //是否提交当前填写的表单信息
      isCommitFormData: {
        type: Boolean,
        default: true
      },
      //是否清空当前已填写表单数据
      isClearFormData: {
        type: Boolean,
        default: true
      },
      // 操作类型(0:新增 1:修改  2:查看)
      operateType: {
        type: Number,
        default: -1
      },
      //数据校验步骤(根据步骤校验数据合法性给出提示信息)
      commitFormStep:{
        type: Number,
        default: -1
      },
      //节点分类标志(0:销售 1:生产 2:库存  3:采购 4:bom 5:资金  5:报销  7:生产执行单  8:发票)
      billsTypeSign: {
        type: Number,
        default: -1
      },
      //回显单据原id(-1:新增 其他:生成)
      generateBillsId: {
        type: Number,
        default: -1
      },
      //回显单据数据
      originalBillData: {
        type: Object,
        default () {
          return {}
        }
      },
    },
    data() {
      return {
        //附件上传弹框相关
        fileUpload: false, //控制弹框开关
        baseUrl: '', //端口

        // 附件上传数据
        fileUrl: '', //附件上传路径
        headers: { //请求头参数
          token: '',
        },
        uploadData: { //请求数据
          sign: '',
        },

        // 附件组件填写值
        fileData: [],
        is_edit: true, //是否可编辑

        //传入子组件相关
        previewFilePath: '', //需要查看的附件地址
        show_previewComponents: false, //控制加载附件预览组件
      }
    },
    watch: {
      //监听是否提交当前组件所填信息
      isCommitFormData(newVal) {
        //调用提交信息方法
        this.commitNodeFormMsg();
        // if(this.commitFormStep == 6){
        //   //调用提交信息方法
        //   this.commitNodeFormMsg();
        // }
      },
      //监听是否清空当前子组件数据
      isClearFormData() {
        //调用清除信息方法
        this.clearNodeFormMsg();
      },
      // 监听回显单据数据
      originalBillData(newVal) {
        this.getDefaultFileData(newVal);
      },
    },
    created() {
      // 初始化附件信息
      this.initFileData();
    },
    computed: {
      ...mapState({
        companyparamlists: state => state.companyparamlists, //获取企业级参数
      })
    },
    mounted() {
      //加载企业级参数
      if (this.companyparamlists == "") {
        this.getCompanyParamLists();
      }
    },
    methods: {
      ...mapMutations([
        'SHOW_FILEPREVIEW', //控制显示附件预览弹框
      ]),
      ...mapMutations({
        getCompanyParamLists: 'getCompanyParamLists',
      }),
      /* 初始化上传附件信息*/
      initFileData() {
        //设置请求路径
        this.fileUrl = baseUrl + '/orderCommon/uploadAccessory';
        this.baseUrl = baseUrl
        //设置请求头
        this.headers.token = getCookie('token');
        //设置请求内容
        this.uploadData.sign = 0;
      },

      /* 限制图片上传 */
      beforeUpload(file) {
        // console.log(this.companyparamlists)
        this.fileUpload = false
        let fileType = this.commonJsExtend.getFileType(file.name)
        // 定义接受数据
        let data = {
          uid: file.uid, //文件id
          file_type: 0, //本地上传
          file_name: file.name, //附件名称
          file_path: '', //附件路径
          size:(file.size / 1024).toFixed(2),//未转化的附件大小
          file_size: file.size, //附件大小
          file_type_icon: this.getFileTypeIcon(fileType), //图标
          file_status: '传输中...', //传输状态
        }
        let img = this.commonJsExtend.beforeUpload(file, this.companyparamlists)
        if (img) {
          //将数据存入data
          this.fileData.push(data);
        }
        return img
      },

      /* 云盘上传 */
      cloudUpload() {
        this.$message({
          type: 'warning',
          message: '功能暂未开放',
          duration: this.elDuration
        })
      },

      /* 获取默认文件数据(回显) */
      getDefaultFileData(data) {
        //判断当前界面类型(预览不可删除)
        if (this.operateType == 2) {
          this.is_edit = false;
        }
        //循环前清空
        this.fileData = [];
        //获取附件信息数据
        data.files.forEach((item, index) => {
          //定义接受非驼峰对象
          let itemObj = {}
          //处理驼峰
          for (let i in item) {
            console.log(item)
            let toLine = this.commonJsExtend.toLine(i);
            itemObj[toLine] = item[i];
            let fileType = this.commonJsExtend.getFileType(item.fileName)
            itemObj.file_type_icon = this.getFileTypeIcon(fileType) //图标
            itemObj.size = (item.fileSize / 1024).toFixed(2) //附件大小
            itemObj.file_status = "传输完成" //传输状态
          }
          //获取处理后的数据
          this.fileData.push(itemObj);
          // console.log(this.fileData)
        })
      },

      // 文件上传
      uploadFile(response, file, fileList) {
        if (response.code == 200) {
          this.fileData.forEach(item => {
            if (item.uid == file.uid) {
              item.file_path = response.data //附件路径
              item.file_status = "传输完成" //传输状态
            }
          })
          // // 定义接受数据
          // let data = {
          //   file_type: 0, //本地上传
          //   file_name: file.name, //附件名称
          //   file_path: response.data, //附件路径
          //   file_size: (file.size / 1024).toFixed(2), //附件大小
          //   file_type_icon: this.getFileTypeIcon(fileType), //图标
          //   file_status: '传输完成', //传输状态
          // }
          //判断当前界面操作类型
          if (this.operateType == 1) { //修改界面
            //定义需要修改的数据
            let updateData = {
              type: 0, //类型(0:添加  1:删除)
              fileName: file.name, //附件名称
              filePath: response.data, //附件路径
              fileType: 0, //文件类型(0:本地上传)
              fileSize: file.size, //文件大小
              relevanceId: this.generateBillsId, //单据id
              sign: this.billsTypeSign, //单据标识
            };
            sellApi.updateOrderFile(updateData).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '附件新增成功!',
                  duration: this.elDuration
                });
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                });
              }
            })
          }
        } else {
          this.fileData.forEach((item, index) => {
            if (item.uid == file.uid) {
              this.fileData.splice(index, 1);
            }
          })
          this.$message({
            type: 'error',
            message: response.message,
            duration: this.elDuration
          })
        }
      },

      /* 删除附件 */
      deleteFileSpan(index, data) {
        if (this.operateType == 0) { //新增界面直接删
          this.fileData.splice(index, 1);
        } else if (this.operateType == 1) {
          let updateData = {
            type: 1, //操作类型(1标识删除)
            id: data.id, //删除的id
            fileName: data.file_name,
            filePath: data.file_path,
            relevanceId: data.relevance_id,
            sign: data.sign
          };
          sellApi.updateOrderFile(updateData).then(res => {
            if (res.code == 200) {
              //删除附件
              this.fileData.splice(index, 1);
              this.$message({
                type: 'success',
                message: '附件删除成功!',
                duration: this.elDuration
              });
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        }
      },

      /* 获取附件预览弹框数据 */
      getPreviewData(data) {
        this.show_previewComponents = false;
      },

      /* 预览附件 */
      previewFile(data) {
        //获取需要预览的路径
        this.previewFilePath = data.file_name + "@@@" + data.file_path;
        //加载附件预览弹框
        this.show_previewComponents = true;
        //显示弹框
        this.SHOW_FILEPREVIEW(true);
      },

      /* 清除当前组件数据*/
      clearNodeFormMsg() {
        //清空上传列表
        this.fileData = [];
      },

      /* 提交当前组件信息*/
      commitNodeFormMsg() {
        // 发送附件信息到父组件
        this.$emit("getNodeFormData", this.fileData);
      },


      /* 获取文件类型图标 */
      getFileTypeIcon(type) {
        let nui_pic = ""
        switch (type) {
          case "ppt":
            nui_pic = "nui_file3_26";
            break;
          case "pptx":
            nui_pic = "nui_file3_26";
            break;
          case "pptm":
            nui_pic = "nui_file3_26";
            break;
          case "ppsx":
            nui_pic = "nui_file3_26";
            break;
          case "ppsm":
            nui_pic = "nui_file3_26";
            break;
          case "potx":
            nui_pic = "nui_file3_26";
            break;
          case "potm":
            nui_pic = "nui_file3_26";
            break;
          case "pps":
            nui_pic = "nui_file3_26";
            break;
          case "xlsm":
            nui_pic = "nui_file3_2";
            break;
          case "xltx":
            nui_pic = "nui_file3_2";
            break;
          case "xltm":
            nui_pic = "nui_file3_2";
            break;
          case "xlsb":
            nui_pic = "nui_file3_2";
            break;
          case "xlam":
            nui_pic = "nui_file3_2";
            break;
          case "csv":
            nui_pic = "nui_file3_2";
            break;
          case "docm":
            nui_pic = "nui_file3_3";
            break;
          case "dot":
            nui_pic = "nui_file3_3";
            break;
          case "rtf":
            nui_pic = "nui_file3_3";
            break;
          case "dotx":
            nui_pic = "nui_file3_3";
            break;
          case "dotm":
            nui_pic = "nui_file3_3";
            break;
          case "wps":
            nui_pic = "nui_file3_3";
            break;
          case "wpt":
            nui_pic = "nui_file3_3";
            break;
          case "jpeg":
            nui_pic = "nui_file3_11";
            break;
          case "txt":
            nui_pic = "nui_file3_1";
            break;
          case "xls":
            nui_pic = "nui_file3_2";
            break;
          case "xlsx":
            nui_pic = "nui_file3_2";
            break;
          case "doc":
            nui_pic = "nui_file3_3";
            break;
          case "docx":
            nui_pic = "nui_file3_3";
            break;
          case "pptx":
            nui_pic = "nui_file3_4";
            break;
          case "7z":
            nui_pic = "nui_file3_5";
            break;
          case "js":
            nui_pic = "nui_file3_7";
            break;
          case "css":
            nui_pic = "nui_file3_8";
            break;
          case "rmvb":
            nui_pic = "nui_file3_9";
            break;
          case "mp3":
            nui_pic = "nui_file3_10";
            break;
          case "jpg":
            nui_pic = "nui_file3_11";
            break;
          case "JPG":
            nui_pic = "nui_file3_11";
            break;
          case "gif":
            nui_pic = "nui_file3_12";
            break;
          case "png":
            nui_pic = "nui_file3_13";
            break;
          case "bmp":
            nui_pic = "nui_file3_14";
            break;
          case "tif":
            nui_pic = "nui_file3_15";
            break;
          case "ico":
            nui_pic = "nui_file3_18";
            break;
          case "pdf":
            nui_pic = "nui_file3_19";
            break;
          case "psd":
            nui_pic = "nui_file3_20";
            break;
          case "ai":
            nui_pic = "nui_file3_21";
            break;
          case "fi":
            nui_pic = "nui_file3_22";
            break;
          case "swf":
            nui_pic = "nui_file3_23";
            break;
          case "rar":
            nui_pic = "nui_file3_24";
            break;
          case "zip":
            nui_pic = "nui_file3_24";
            break;
          case "exe":
            nui_pic = "nui_file3_25";
            break;
          default:
            nui_pic = "nui_file3_6";
        }
        return nui_pic
      },

    },
    components: {
      filePreview,
    }
  }
</script>


<style>
  /* 标签间距 */
  .nodeFileList .el-tag+.el-tag {
    cursor: pointer;
    margin-left: 5px;
  }
</style>
<style lang="less" scoped>
  // 文件上传框
  .nodeFile {
    padding: 5px 10px;
    width: 600px;
    // border: 1px solid black;
  }

  //文件传输列表
  .nodeFileList {
    font-size: 12px;
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;

    .flieItem {
      display: flex;
      background-color: #e6ecf5;
      width: 220px;
      padding: 5px;
      justify-content: space-between;
      border-radius: 5px;
      margin: 0 10px 10px 0;

      .flieIcon {
        height: 32px;
        width: 32px;
        display: inline-block;
        vertical-align: middle;
        margin: 7px 0 0 4px;
        background: url(../../../../assets/img/common/fileico_v4.png) no-repeat;
      }

      .flieInfo {
        .name {
          padding: 5px 5px 0 5px;
          width: 115px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .fileAttr {
          padding: 5px;

          .flieSize {
            color: #b0b0b0;
            padding-right: 5px;
          }

          .process {
            color: #3d882d;
          }
        }
      }

      .fileOperation {
        display: flex;

        .option:not(:last-child) {
          padding: 0 10px 0 0;
        }

        .el-link {
          align-items: flex-start;
        }

        .option:hover {
          cursor: pointer;
          color: #409EFF;
        }
      }
    }
  }

  .drag {
    text-align: center;
    width: 100%;

    /deep/.el-upload-dragger {
      width: 450px;
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: space-around;

    .fileUploadBtn {
      width: 150px;
    }
  }
</style>
<style>
  /* icon */
  .nui_file3_1 {
    background-position: -672px -64px !important;
  }

  .nui_file3_2 {
    background-position: -32px -64px !important;
  }

  .nui_file3_3 {
    background-position: -64px -64px !important;
  }

  .nui_file3_4 {
    background-position: -96px -64px !important;
  }

  .nui_file3_5 {
    background-position: -128px -64px !important;
  }

  .nui_file3_6 {
    background-position: -256px -64px !important;
  }

  .nui_file3_7 {
    background-position: -320px -64px !important;
  }

  .nui_file3_8 {
    background-position: -352px -64px !important;
  }

  .nui_file3_9 {
    background-position: -800px -64px !important;
  }

  .nui_file3_10 {
    background-position: -896px -64px !important;
  }

  .nui_file3_11 {
    background-position: 0 -96px !important;
  }

  .nui_file3_12 {
    background-position: -32px -96px !important;
  }

  .nui_file3_13 {
    background-position: -64px -96px !important;
  }

  .nui_file3_14 {
    background-position: -96px -96px !important;
  }

  .nui_file3_15 {
    background-position: -128px -96px !important;
  }

  .nui_file3_16 {
    background-position: -160px -96px !important;
  }

  .nui_file3_17 {
    background-position: -192px -96px !important;
  }

  .nui_file3_18 {
    background-position: -224px -96px !important;
  }

  .nui_file3_19 {
    background-position: -512px -64px !important;
  }

  .nui_file3_20 {
    background-position: -544px -64px !important;
  }

  .nui_file3_21 {
    background-position: -576px -64px !important;
  }

  .nui_file3_22 {
    background-position: -608px -64px !important;
  }

  .nui_file3_23 {
    background-position: -640px -64px !important;
  }

  .nui_file3_24 {
    background-position: -160px -64px !important;
  }

  .nui_file3_25 {
    background-position: -480px -96px !important;
  }

  .nui_file3_26 {
    background-position: -96px -64px !important;
  }
</style>
