<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：新增工艺表单弹框界面
	开始时间：2021-01-15
	开发人员：刘巍骏
	最后修改：2021-01-15
	备注说明：如需修改请联系开发人员
-->
<template>
  <!-- 表单总框 -->
  <div class="formAll">
    <el-dialog title="新增工艺" :visible.sync="show_craftAddBox" width="30%" append-to-body :before-close="cancleCraft">
      <div>
        <el-form ref="form" :model="craftData" label-width="100px" size="mini">
          <el-form-item label="备注:">
            <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="craftData.remark"></el-input>
          </el-form-item>
          <el-form-item label="上传文件:">
            <el-upload
              class="upload-demo"
              :action="fileUrl"
              :headers="headers"
              :on-success="uploadFile"
              :file-list="fileList">
              <el-button size="mini" type="primary">附件上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传2M以内的文件</div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancleCraft">取 消</el-button>
        <el-button type="primary" @click="commitFile">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'craftAdd',
    props:{
      //控制显示添加工艺弹框
      show_craftAddBox:{
        type:Boolean,
        default:false
      },
    },
    data() {
      return {
        // 附件上传数据
        fileUrl:'',//附件上传路径
        headers:{//请求头参数
          token:'',
        },
        fileList:[],//已上传文件路径

        craftData:{
          remark:'',//备注
          fileName:'',//文件名
          filePath:'',//文件路径
        },//工艺组件数据
      };
    },
    created() {
      // 初始化附件信息
      this.initFileData();
    },
    methods: {
      /* 初始化上传附件信息*/
      initFileData(){
        //设置请求路径
        this.fileUrl = baseUrl+'/mes/addMesMainTechFile';
        //设置请求头
        this.headers.token = getCookie('token');
      },

      /* 上传成功后回调的方法 */
      uploadFile(response,file,fileList){
        this.craftData.fileName =file.name;
        this.craftData.filePath = response.data;
      },
      
      /* 提交工艺组件数据 */
      commitFile(){
        this.$emit("getSelCraftData",this.craftData)
      },

      /* 取消添加工艺 */
      cancleCraft(){
        this.$parent.closeCraftBox();
      },
    }
  };
</script>

<style>
</style>
