<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：单据发票信息组件
	开始时间：2021-04-27
	开发人员：刘巍骏
	最后修改：2021-04-27
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="nodeFormItemBox">
    <ul class="form_ul">
      <li>
        <div class="form_title">
          发票类型:
        </div>
        <div class="form_date" @click="showPullMenu('show_invoiceType')">
          <input type="text" disabled="disabled" v-model="invoiceData.invoiceType.name" placeholder="选择...">
          <span>
            <i class="el-icon-arrow-down"></i>
          </span>
          <ul class="pulldown_menu" v-show="show_pullMenu.show_invoiceType">
            <li v-for="elem in invoiceTypeData" :key="elem.id" @click="getSelInvoiceData(0,elem)">{{elem.name}}</li>
          </ul>
        </div>
      </li>
      <li>
        <div class="form_title">
          发票抬头:
        </div>
        <div class="form_date">
          <input type="text" @focus="getCurOperateData" @change="getChangeData('invoiceHead',1)" :disabled="!is_edit"
            v-model="invoiceData.invoiceHead" placeholder="选择...">
        </div>
      </li>
      <li>
        <div class="form_title">
          发票内容:
        </div>
        <div class="form_date">
          <input type="text" @focus="getCurOperateData" @change="getChangeData('invoiceContent',1)" :disabled="!is_edit"
            v-model="invoiceData.invoiceContent" placeholder="选择...">
        </div>
      </li>
      <li v-show="invoiceData.invoiceType.id == 2">
        <div class="form_title">
          纳税人辨别号:
        </div>
        <div class="form_date">
          <input type="text" @focus="getCurOperateData" @change="getChangeData('taxpayerNum',1)" :disabled="!is_edit"
            v-model="invoiceData.taxpayerNum" placeholder="选择...">
        </div>
      </li>
      <li v-show="invoiceData.invoiceType.id == 2">
        <div class="form_title">
          联系地址:
        </div>
        <div class="form_date">
          <input type="text" @focus="getCurOperateData" @change="getChangeData('address',1)" v-model="invoiceData.address" :disabled="!is_edit"
            placeholder="选择...">
        </div>
      </li>
      <li v-show="invoiceData.invoiceType.id == 2">
        <div class="form_title">
          联系电话:
        </div>
        <div class="form_date">
          <input type="text" @focus="getCurOperateData" @change="getChangeData('phone',1)" v-model="invoiceData.phone" :disabled="!is_edit"
            placeholder="选择...">
        </div>
      </li>
      <li v-show="invoiceData.invoiceType.id == 2">
        <div class="form_title">
          开户人名称:
        </div>
        <div class="form_date">
          <input type="text" @focus="getCurOperateData" @change="getChangeData('accountHolder',1)" :disabled="!is_edit"
            v-model="invoiceData.accountHolder" placeholder="选择...">
        </div>
      </li>
      <li v-show="invoiceData.invoiceType.id == 2">
        <div class="form_title">
          开户银行:
        </div>
        <div class="form_date">
          <input type="text" @focus="getCurOperateData" @change="getChangeData('bankOfDeposit',1)" :disabled="!is_edit"
            v-model="invoiceData.bankOfDeposit" placeholder="选择...">
        </div>
      </li>
      <li v-show="invoiceData.invoiceType.id == 2">
        <div class="form_title">
          开户账号:
        </div>
        <div class="form_date">
          <input type="text" @focus="getCurOperateData" @change="getChangeData('bankinfo',1)" :disabled="!is_edit"
            v-model="invoiceData.bankinfo" placeholder="选择...">
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  // 导入api
  import sellApi from '@/network/sell/bills.js'; //销售模块(包含公共接口)

  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  export default {
    name: 'nodeform_invoice',
    props: {
      // 节点基本信息
      baseNodeInfo: {
        type: Object,
        default () {
          return {}
        }
      },
      //是否提交当前填写的表单信息
      isCommitFormData: {
        type: Boolean,
        default: true
      },
      //是否清空当前已填写表单数据
      isClearFormData: {
        type: Boolean,
        default: true
      },
      //是否触发保存单据新增的相关信息
      saveNewBillsData:{
        type:Boolean,
        default:false
      },
      // 操作类型(0:新增 1:修改  2:查看)
      operateType: {
        type: Number,
        default: -1
      },
      //数据校验步骤(根据步骤校验数据合法性给出提示信息)
      commitFormStep:{
        type: Number,
        default: -1
      },
      //回显单据数据
      originalBillData: {
        type: Object,
        default () {
          return {}
        }
      },
      //单据修改时关联信息的操作类型(0:新增 2:删除)
      operateReselveType:{
        type:Number,
        default:-1
      },
      //生成单据原id(-1:新增 其他:生成)
      generateBillsId: {
        type: Number,
        default: -1
      },
      //节点分类标志(0:销售 1:生产 2:库存  3:采购)
      billsTypeSign: {
        type: Number,
        default: -1
      },
    },
    data() {
      return {
        // 发票信息数据
        invoiceData: {
          invoiceType: {}, //发票类型
          invoiceHead: '', //发票抬头
          invoiceContent: '', //发票内容
          taxpayerNum: '', //纳税人识别号
          address: '', //联系地址
          phone: '', //联系电话
          accountHolder: '', //开户人
          bankOfDeposit: '', //开户银行
          bankinfo: '', //银行账号
        },

        //修改时用到
        invoiceOldData: {}, //修改之前的旧发票信息数据
        is_edit:true,//是否可编辑(预览模式下不可编辑)

        //控制开关
        show_pullMenu: { //控制显示下拉菜单
          show_invoiceType: false, //控制显示发票类型
        }
      }
    },
    computed: {
      ...mapState({
        bills_base_data: state => state.commComponent.bills_base_data, //表单填写的基本信息数据
        invoiceTypeData: state => state.system.invoiceTypeData, //发票类型数据(写死)
        customer_data: state => state.commComponent.customer_data, //单据所选客户信息
      })
    },
    created() {
      //初始化发票信息数据
      this.initInvoiceData();
    },
    watch: {
      // 监听是否提交当前组件填写信息
      isCommitFormData(newVal) {
        this.commitInvoiceData();
        // if(this.commitFormStep == 4){
        //   this.commitInvoiceData();
        // }
      },
      //监听是否清空当前子组件数据
      isClearFormData() {
        //调用清除信息方法
        this.clearNodeFormMsg();
      },
      //监听是否提交新增的数据(保存新数据)(修改时用)
      saveNewBillsData(newVal){
        //调用修改数据方法(operate只是占位符,不起任何业务作用)
        this.getChangeData('operate',this.operateReselveType);
      }
    },
    methods: {
      /* 初始化发票信息数据 */
      initInvoiceData() {
        // 判断是否可编辑(预览下不可编辑)
        if(this.operateType == 2){
          this.is_edit = false;
        }
        // 判断是否需要默认设置发票类型
        if (this.bills_base_data.hasOwnProperty('receipt_type') && this.bills_base_data.receipt_type != "") {
          this.invoiceData.invoiceType = this.commonJsExtend.getDictMatchingData(this.bills_base_data
            .receipt_type, this.invoiceTypeData);
        } else {
          //若基本信未选择则设置默认发票类型为收据
          this.invoiceData.invoiceType = this.invoiceTypeData[0];
        }

        // 判断已选客户默认设置发票抬头
        if (JSON.stringify(this.customer_data) != "{}") {
          this.invoiceData.invoiceHead = this.customer_data.customer_name;
        }

        //判断是否需要回显数据(修改和预览需要回显)
        if (this.operateType == 1 || this.operateType == 2) {
          //获取默认物流信息(回显时用)
          this.getDefaultInvoiceData(this.originalBillData);
        }
      },

      /* 获取默认发票信息数据(回显数据) */
      getDefaultInvoiceData(data) {
        // 判断当没有数据时不回显
        if(!data.costBillsInvoice){
          return
        }
        //获取发票类型
        let resTypeData = this.commonJsExtend.getDictMatchingData(data.costBillsInvoice.invoiceType, this
          .invoiceTypeData);
        //获取发票数据
        this.invoiceData = {
          id:data.costBillsInvoice.id,//id
          invoiceType: resTypeData, //发票类型
          invoiceHead: data.costBillsInvoice.receiptTitle, //发票抬头
          invoiceContent: data.costBillsInvoice.receiptContent, //发票内容
          taxpayerNum: data.costBillsInvoice.receiptCode, //纳税人识别号
          address: data.costBillsInvoice.address, //联系地址
          phone: data.costBillsInvoice.telephone, //联系电话
          accountHolder: data.costBillsInvoice.accountName, //开户人
          bankOfDeposit: data.costBillsInvoice.accountBank, //开户银行
          bankinfo: data.costBillsInvoice.accountNumber, //银行账号
        }
        //触发一次获取旧数据方法
        this.getCurOperateData();
        this.$forceUpdate()
      },

      /* 获取当前操作的数据(修改时使用的旧数据) */
      getCurOperateData() {
        //存一份旧数据
        this.invoiceOldData = JSON.parse(JSON.stringify(this.invoiceData))
      },

      /* 处理数据修改后的业务 */
      getChangeData(fileName,type) {
        //判断是否为修改界面(其他界面不执行此方法)
        if (this.operateType != 1) {
          return
        }
        let logContent = "";//日志信息
        let updateData = {};//修改所需数据
        // 判断操作类型
        if (type == 0) { //添加
          // 判断数据是数据库的还是新增的(删除后再加的)(有id的就不新增)
          if(this.invoiceOldData.id){
            //设置已处理新增
            this.$emit("getOperateInvoiceResult",1)
            return
          }
          //获取日志信息
          logContent = "新增了一条发票信息;"
          //获取修改信息
          updateData = {
            orderId:this.generateBillsId,//单据id
            sign:this.billsTypeSign,//单据标识
            statusId:this.baseNodeInfo.id,//节点id
            type: type, //操作类型
            logContent: logContent, //日志信息
            invoiceType: this.invoiceData.invoiceType.id, //发票类型id
            receiptTitle: this.invoiceData.invoiceHead, //发票抬头
            receiptContent: this.invoiceData.invoiceContent, //发票内容
            receiptCode: this.invoiceData.taxpayerNum, //纳税人识别码
            address: this.invoiceData.address, //地址
            telephone: this.invoiceData.phone, //电话
            accountName: this.invoiceData.accountHolder, //开户名称
            accountBank: this.invoiceData.bankOfDeposit, //开户银行
            accountNumber: this.invoiceData.bankinfo, //银行帐号
          }
        }else if(type == 1){//修改
          // 判断数据是数据库的还是新增的
          if(!this.invoiceOldData.id){
            return
          }
          //定义日志相关信息
          let oldVal = ""; //旧数据
          let newVal = ""; //新数据
          let showName = ""; //显示名称
          if (fileName == 'invoiceType') {
            showName = "发票类型"
            oldVal = this.invoiceOldData[fileName].name;
            newVal = this.invoiceData[fileName].name;
          } else if (fileName == 'invoiceHead') {
            showName = "发票抬头"
            oldVal = this.invoiceOldData[fileName];
            newVal = this.invoiceData[fileName];
          } else if (fileName == 'invoiceContent') {
            showName = "发票内容"
            oldVal = this.invoiceOldData[fileName];
            newVal = this.invoiceData[fileName];
          } else if (fileName == 'taxpayerNum') {
            showName = "纳税人识别号"
            oldVal = this.invoiceOldData[fileName];
            newVal = this.invoiceData[fileName];
          } else if (fileName == 'address') {
            showName = "联系地址"
            oldVal = this.invoiceOldData[fileName];
            newVal = this.invoiceData[fileName];
          }else if (fileName == 'phone') {
            showName = "联系电话"
            oldVal = this.invoiceOldData[fileName];
            newVal = this.invoiceData[fileName];
          }else if (fileName == 'accountHolder') {
            showName = "开户人"
            oldVal = this.invoiceOldData[fileName];
            newVal = this.invoiceData[fileName];
          }else if (fileName == 'bankOfDeposit') {
            showName = "开户银行"
            oldVal = this.invoiceOldData[fileName];
            newVal = this.invoiceData[fileName];
          }else if (fileName == 'bankinfo') {
            showName = "银行账号"
            oldVal = this.invoiceOldData[fileName];
            newVal = this.invoiceData[fileName];
          }
          //获取日志信息
          logContent = "更改了【发票信息】中的【" + showName + "】,更改前:" + oldVal + ",更改后:" + newVal; //日志内容
          //获取修改信息
          updateData = {
            id: this.invoiceOldData.id, //被修改id
            statusId:this.baseNodeInfo.id,//节点id
            type: type, //操作类型
            logContent: logContent, //日志信息
            invoiceType: this.invoiceData.invoiceType.id, //发票类型id
            receiptTitle: this.invoiceData.invoiceHead, //发票抬头
            receiptContent: this.invoiceData.invoiceContent, //发票内容
            receiptCode: this.invoiceData.taxpayerNum, //纳税人识别码
            address: this.invoiceData.address, //地址
            telephone: this.invoiceData.phone, //电话
            accountName: this.invoiceData.accountHolder, //开户名称
            accountBank: this.invoiceData.bankOfDeposit, //开户银行
            accountNumber: this.invoiceData.bankinfo, //银行帐号
          }
        }else if(type == 2){
          //定义日志内容
          logContent = "删除了一条发票信息;"; //日志内容
          updateData = {
            id: this.invoiceOldData.id, //被修改id
            logContent: logContent, //日志信息
            statusId:46,//节点id
            type: type, //操作类型
          }
          console.log(updateData);
        }
        //发送请求
        sellApi.updateCostBillsInvoice(updateData).then(res => {
          if (res.code == 200) {
            if(type == 0){//新增
              this.$emit("getOperateInvoiceResult",1)
            }else if(type == 1){//修改
             this.$message({
               type: 'success',
               message: '发票信息修改成功!',
               duration: this.elDuration
             })
            }else if(type == 2){//删除(删除后需清空物流数据)
              this.$emit("getOperateInvoiceResult",2)
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取发票类型下拉选的数据type(0:发票类型) */
      getSelInvoiceData(type, data) {
        //判断下拉框操作类型
        if (type == 0) { //发票类型
          this.invoiceData.invoiceType = data;
          //触发修改方法
          this.getChangeData('invoiceType',1);
        }
      },

      /* 显示下拉菜单key:控制显示下拉菜单对象的key值 */
      showPullMenu(key) {
        // 判断是否可编辑(预览下不可编辑)
        if(this.operateType == 2){
          this.is_edit = false;
        }
        // 循环只显示当前点击的下拉框
        for (let i in this.show_pullMenu) {
          //当前传入的key值
          if (i == key) {
            this.show_pullMenu[i] = !this.show_pullMenu[i];
          } else {
            this.show_pullMenu[i] = false;
          }
        }
        //存一份旧数据
        this.getCurOperateData();
      },

      /* 清除当前组件信息 */
      clearNodeFormMsg() {
        // 循环清空对象
        for (let i in this.invoiceData) {
          this.invoiceData[i] = "";
        }
      },

      /* 提价发票信息数据*/
      commitInvoiceData() {
        //判断数据合法性(判断类型+111)

        // 定义传入后端的数据
        let data = {
          invoice_type: this.invoiceData.invoiceType.id, //发票类型id
          receipt_title: this.invoiceData.invoiceHead, //发票抬头
          receipt_content: this.invoiceData.invoiceContent, //发票内容
          receipt_code: this.invoiceData.taxpayerNum, //纳税人识别码
          address: this.invoiceData.address, //地址
          telephone: this.invoiceData.phone, //电话
          account_name: this.invoiceData.accountHolder, //开户名称
          account_bank: this.invoiceData.bankOfDeposit, //开户银行
          account_number: this.invoiceData.bankinfo, //银行帐号
        }
        // console.log(data);
        //发送数据到父组件
        this.$emit("getNodeFormData", data)
      },
    }
  }
</script>

<style>
</style>
