// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：单据模块相关接口
// 开始时间：2021-03-13
// 开发人员：刘巍骏
// 最后修改：2021-03-13
// 备注说明：如需修改请联系开发人员

import {request} from "../request.js";
import qs from 'qs';

export default{
  // 单据公共接口相关
  findBillsCodeByTempBillsCode(data){//根据单据编模板code生成单据编码
    return request({
      method:'POST',
      url:'/orderCommon/findBillsCodeByTempBillsCode',
      data
    })
  },
  transitionNumberToText(data){//将数值转换为文字格式
    return request({
      method:'POST',
      url:'/orderCommon/transitionNumberToText',
      data
    })
  },


  //日志相关
  findBillLogByOperateType(data){//根据操作方式获取日志信息
    return request({
      method:'POST',
      url:'/orderCommon/findBillLogByOperateType',
      data
    })
  },
}
