<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
  技术支持：Tech@21gmail.com
  单元名称：费用分类选择弹框组件
  开始时间：2021-05-28
  开发人员：刘巍骏
  最后修改：2021-05-28
  备注说明：如需修改请联系开发人员
  -->
<template>
  <div class="">
    <el-dialog title="会计科目" :visible.sync="show_costTypeBox" :before-close="cancel_costType" width="400px"
      append-to-body>
      <div class="costType_Box">
        <el-tree :data="costTypeData" :props="defaultProps" default-expand-all show-checkbox :check-strictly="true"
          @check-change="SelectCostType">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span class="spanIncome" v-if="data.outin_sign == 0">(收)</span>
            <span class="spanExpend" v-else-if="data.outin_sign == 1">(支)</span>
            <span v-else>(无)</span>
          </span>
        </el-tree>
      </div>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="commit_costType">确认</el-button>
        <el-button size="mini" @click="cancel_costType">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入方法
  import api from '@/network/system/dictionary'; //数据字典相关接口

  export default {
    name: 'tree_costType',
    props: {
      //需要显示的费用分类标识数组(0:收 1:支 2:无)
      showCostTypeArr: {
        type: Array,
        default () {
          return [0, 1, 2]; //默认都显示
        }
      },
      //费用分类查询条件
      conditionObj: {
        type: Object,
        default () {
          return {
            isCostMain: -1, //是否是收付款单(0:否 1:是)
            billsTypeId: -1, //指定单据类型显示
            parentId:-1,//父级id
          }
        }
      }
    },
    data() {
      return {
        // Element ui相关
        defaultProps: { //匹配接口字段信息
          children: 'children',
          label: 'name',
          disabled: this.judgeDisableCostType
        },
        costTypeData: [], //费用分类数据
        SelectData: [], //选中的数据
      };
    },
    mounted() {
      this.getCostDataByCondition();
    },
    computed: {
      ...mapState({
        show_costTypeBox: state => state.commComponent.show_costTypeBox, //控制显示费用分类选择弹框组件
        FinanceCostType: state => state.system.FinanceCostType, //费用分类数据
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
      }),
    },
    watch:{
    },
    methods: {
      ...mapMutations([
        'SHOW_COSTTYPEBOX', //控制费用分类选择弹框是否显示
      ]),
      ...mapActions([
      ]),

      /* 条件查询费用分类 */
      getCostDataByCondition() {
        //定义部门id
        let deptId = "";
        //判断当前用户级别
        if (this.UserInfo.user_role == 1) { //系统管理员
          deptId = "";
        } else if (this.UserInfo.user_role == 2 || this.UserInfo.user_role == 3) { //部门管理员,普通员工
          deptId = this.UserInfo.dept_id
        }

        //定义公共查询条件
        let data = {
          deptId: deptId,
        }

        //判断查询条件
        if (this.conditionObj.isCostMain != -1) {
          data.isCostMain = this.conditionObj.isCostMain;
        }
        //判断指定单据类型
        if (this.conditionObj.billsTypeId != -1) {
          data.billsTypeId = this.conditionObj.billsTypeId;
        }
        //判断父级id
        if (this.conditionObj.parentId != -1) {
          data.parentId = this.conditionObj.parentId;
        }

        //发送请求
        api.financeDict.findCostTypeByCondition(data).then(res => {
          if (res.code == 200) {
            this.costTypeData = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取选中的费用分类数据 */
      SelectCostType(row, is_check, is_child) {
        if (is_check) {
          this.SelectData.push(row)
        } else {
          this.SelectData.forEach((item, index) => {
            if (item.id == row.id) {
              this.SelectData.splice(index, 1)
            }
          })
        }
      },

      /* 判断是否禁用费用分类 */
      judgeDisableCostType(data, node) {
        if (this.showCostTypeArr.includes(data.outin_sign)) {
          return false;
        } else {
          return true;
        }
      },

      // 提交选择费用分类
      commit_costType() {
        //判断是否有选分类
        if(this.SelectData.length == 0){
          this.$message({
            type:'warning',
            message:'请选择科目数据',
            duration:this.elDuration
          })
          return
        }
        this.$emit('SelectedData', this.SelectData)
        this.SHOW_COSTTYPEBOX(false);
      },
      // 取消选择费用分类
      cancel_costType() {
        this.SHOW_COSTTYPEBOX(false);
        this.$parent.cancleDialogOperate();
      },
    }
  };
</script>

<style lang="less" scoped="scoped">
  .costType_Box {
    width: 100%;
    height: 300px;
    overflow: auto;

    // border: 1px solid black;
    //收入标识样式
    .spanIncome {
      color: @theme;
    }

    //支出标识样式
    .spanExpend {
      color: red;
    }
  }
</style>
